import { useAcceptFamilyInvitation } from 'features/familyLinkage/hooks/useAcceptFamilyInvitation';
import { useDeclineFamilyInvitation } from 'features/familyLinkage/hooks/useDeclineFamilyInvitation';
import { Text } from 'features/familyLinkage/styles';
import { useEffect } from 'react';
import { useGetFamilyPendingInvitations } from '../../hooks/useGetFamilyPendingInvitations';
import { RECEIVE_INVITE_MODAL } from 'features/familyLinkage/constants';
import { OverlayModal } from 'components/Modal';

export function FamilyLinkageInvitationModal({
  onAccept,
  onClose,
  onDecline,
  onError,
  open,
}) {
  const { data } = useGetFamilyPendingInvitations();

  const acceptFamilyInvitation = useAcceptFamilyInvitation();
  const declineFamilyInvitation = useDeclineFamilyInvitation();

  useEffect(() => {
    if (acceptFamilyInvitation.mutation.isSuccess) {
      onAccept();
    }
  }, [acceptFamilyInvitation.mutation.isSuccess, onAccept]);

  useEffect(() => {
    if (declineFamilyInvitation.mutation.isSuccess) {
      onDecline();
    }
  }, [declineFamilyInvitation.mutation.isSuccess, onDecline]);

  useEffect(() => {
    if (
      acceptFamilyInvitation.mutation.isError ||
      declineFamilyInvitation.mutation.isError
    ) {
      onError();
    }
  }, [
    acceptFamilyInvitation.mutation.isError,
    declineFamilyInvitation.mutation.isError,
    onError,
  ]);

  const isLoading =
    acceptFamilyInvitation.mutation.isLoading ||
    declineFamilyInvitation.mutation.isLoading;

  const invitation = data.at(-1);

  const modalContent = (
    <Text textAlign='left'>
      <b>
        {invitation?.inviter_first_name} {invitation?.inviter_last_name}
      </b>{' '}
      {RECEIVE_INVITE_MODAL.description}
    </Text>
  );

  const modalButtonsConfig = [
    {
      variant: '',
      text: RECEIVE_INVITE_MODAL.buttonText.accept,
      handleClick: () =>
        acceptFamilyInvitation.submit({
          familyInvitationId: invitation.id,
        }),
      disabled: isLoading,
      loading: acceptFamilyInvitation.mutation.isLoading,
    },
    {
      variant: 'link',
      text: RECEIVE_INVITE_MODAL.buttonText.decline,
      handleClick: () =>
        declineFamilyInvitation.submit({
          familyInvitationId: invitation.id,
        }),
      disabled: isLoading,
      loading: declineFamilyInvitation.mutation.isLoading,
    },
  ];

  return (
    <OverlayModal
      open={open}
      modalTitle={RECEIVE_INVITE_MODAL.title}
      modalContent={modalContent}
      modalButtonsConfig={modalButtonsConfig}
      onClose={!isLoading && onClose}
    />
  );
}
