import styled from "@emotion/styled";
import CancelIcon from '@mui/icons-material/Cancel';

import { Box, Button, Grid, InputLabel, Stack } from "@mui/material";
import { LinkButton } from "components/Buttons";

export const LnkButton = styled(LinkButton)(({ theme }) => ({
  color: theme.palette.primary.darkBlue70,
  height: '50px',
  minWidth: 'fit-content',
}));

export const fieldContainer = {
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
};
export const InterestWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.lightGray100}`,
  borderRadius: "8px",
  paddingLeft: "8px",
  paddingTop: "8px",
  paddingBottom: "8px"
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '8px',
  color: theme.palette.primary.gray75,
  textTransform: 'uppercase',
  marginBottom: "-9px",
}));

export const checkboxContainer = {
  backgroundColor: 'transparent',
  border: '0px',
  '& .MuiFormControlLabel-root': {
    alignItems: 'start',
    '& .MuiCheckbox-root': {
      padding: '0px',
      '& span': {
        width: '20px',
        height: '20px',
      },
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
};

export const SearchWrapper = styled(Box)(({ theme }) => ({
  padding: '0',
  border: `1px solid ${theme.palette.primary.gray25}`,
  borderRadius: '8px',
}));

export const StackOverfollowHidden = styled(Stack)(({ theme }) => ({
  overflowY: "scroll",
  maxHeight: "190px"
}));

export const Keyword = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  background: theme.palette.secondary.main,
}));

export const KeywordItem = styled(Grid)(({ theme }) => ({
  paddingRight: "10px",
  paddingBottom: "10px"
}));

export const CancelIconWrapper = styled(CancelIcon)(({ theme }) => ({
  color: "white",
  width: 16,
  height: 16
}));

export const KeyworkLabel = styled('span')(({ theme }) => ({
  fontSize: '13px',
  color: "white",
  textTransform: "capitalize",
  background: theme.palette.secondary.main,
  marginRight: "10px",
  paddingLeft: 0,
}));
