import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { convertDateStringToUTC } from 'utils/date';

export function useDeceasedStatus() {
  const [updatePersonStudyArm, { isLoading: isLoadingUpdatePersonStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const [
    createPersonStudyArmAttribute,
    { isLoading: isLoadingCreatePersonStudyArmAttribute },
  ] = useCreatePersonStudyArmAttributeMutation();
  const [
    updatePersonAttributes,
    { isLoading: isLoadingSubmitPersonAttribute },
  ] = useEditPersonAttributesMutation();

  const updatePersonStudyArmRecord = async (id, studyArmStatusId) => {
    const payload = {
      data: {
        type: 'person-study-arms',
        id: String(id),
        attributes: {
          study_arm_status_id: studyArmStatusId,
        },
      },
    };

    return updatePersonStudyArm({
      personStudyArmId: id,
      payload,
    });
  };

  const createPersonStudyArmAttributeRecord = async (
    personStudyArmId,
    dateOfDeath
  ) => {
    const payload = {
      data: [
        {
          type: 'person-study-arm-attributes',
          attributes: {
            attribute: 'participant_deceased',
            value: 'true',
          },
        },
      ],
    };

    if (dateOfDeath) {
      payload.data.push({
        type: 'person-study-arm-attributes',
        attributes: {
          attribute: 'date_of_death',
          value: convertDateStringToUTC(dateOfDeath),
        },
      });
    }

    return createPersonStudyArmAttribute({
      personStudyArmId: personStudyArmId,
      payload,
    });
  };

  const updatePersonAttribute = async (
    id,
    shareAutopsyReport,
    provideAdditionalInformation
  ) => {
    const payload = [
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'share_autopsy_report',
            value: String(shareAutopsyReport),
          },
        },
      },
    ];
    if (provideAdditionalInformation !== null) {
      payload.push({
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'provide_additional_information',
            value: String(provideAdditionalInformation),
          },
        },
      });
    }

    return updatePersonAttributes({ personId: id, payload });
  };

  return {
    updatePersonAttribute,
    updatePersonStudyArmRecord,
    createPersonStudyArmAttributeRecord,
    isLoading:
      isLoadingUpdatePersonStudyArm ||
      isLoadingSubmitPersonAttribute ||
      isLoadingCreatePersonStudyArmAttribute,
  };
}
