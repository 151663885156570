import { parseISO, format } from 'date-fns';

import CustomRadioInput from 'ihp-components/components/Input/CustomRadioInput';
import { RadioGroupInput } from 'components/Input';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import {
  STUDY_ARM_CODE,
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_ROLE_NAME,
  STUDY_ARM_CONFIG_VARIABLES,
} from 'constants/global';

import InCompleteProfile from './IncompleteProfile/index';
import { HelperText, LeadText, StudyText } from './styles';

import { useConfiguration } from 'pages/Onboarding/ResearchEligibilityRole/hooks/useConfiguration';

const getYear = (dob) => {
  if (dob) {
    const parseDate = parseISO(dob);
    const year = format(parseDate, 'yyyy');
    return year;
  }
  return '';
};

const width = {
  width: '100%',
};

const mapping = [
  {
    roles: [STUDY_ARM_ROLE_NAME.AdultSV, STUDY_ARM_ROLE_NAME.AdultNonSV],
    leadText: 'Myself',
    studyText: 'SOURCE: Research',
  },
  {
    roles: [
      STUDY_ARM_ROLE_NAME.LARSV,
      STUDY_ARM_ROLE_NAME.LARSVChildUnder7,
      STUDY_ARM_ROLE_NAME.LARSVChild7ToMajority,
      STUDY_ARM_ROLE_NAME.LARNonSV,
      STUDY_ARM_ROLE_NAME.LARNonSVChildUnder7,
      STUDY_ARM_ROLE_NAME.LARNonSVChild7ToMajority,
    ],
    leadText: 'Legal Authorized Representative',
    helperText: (profile) => {
      const { first_name, last_name, date_of_birth } =
        profile?.attributes || {};
      return `${first_name} ${last_name} born in ${getYear(date_of_birth)}`;
    },
    studyText: 'SOURCE: Research',
  },
  {
    roles: [
      STUDY_ARM_ROLE_NAME.ParentSVChildUnder7,
      STUDY_ARM_ROLE_NAME.ParentSVChild7ToMajority,
      STUDY_ARM_ROLE_NAME.ParentNonSVChildUnder7,
      STUDY_ARM_ROLE_NAME.ParentNonSVChild7ToMajority,
    ],
    leadText: 'Parent/Guardian',
    helperText: (profile) => {
      const { first_name, last_name, date_of_birth } =
        profile?.attributes || {};
      return `${first_name} ${last_name} born in ${getYear(date_of_birth)}`;
    },
    studyText: 'SOURCE: Research',
  },
  {
    roles: [STUDY_ARM_ROLE_NAME.Participant],
    leadText: 'Myself',
    studyText: 'SOURCE: Information',
  },
];

// Helper function to get text based on role
const getProfileText = (profile) => {
  const role = profile?.attributes?.study_arm_role_name;
  const roleMapping = mapping.find((item) => item.roles.includes(role));

  return roleMapping
    ? {
        leadText: roleMapping.leadText,
        helperText: roleMapping.helperText?.(profile) ?? null,
        studyText: roleMapping.studyText,
      }
    : {};
};

function ProfileList({ profileList, selectedOption, setSelectedOption }) {
  const { configuration } = useSelector((state) => state.configuration);

  const contactEnrolledStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_ENROLLED
    ]?.id;
  const researchEnrolledStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED
    ]?.id;
  const allFTDStudyArmId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARMS][STUDY_ARM_CODE.ALLFTD]
      ?.id;

  const contactDeceasedStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_DECEASED
    ]?.id;
  const researchDeceasedStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_DECEASED
    ]?.id;
  const allftdDeceasedStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.ALLFTD_DECEASED
    ]?.id;

  /**
   * We have to treat deceased status like enrolled because only enrolled user can be marked as deceased
   */
  const enrolledStatuses = [
    contactEnrolledStatusId,
    researchEnrolledStatusId,
    contactDeceasedStatusId,
    researchDeceasedStatusId,
    allftdDeceasedStatusId,
  ];

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const renderProfileList = () => {
    const mappedList = profileList.map((profile, index) => {
      const { leadText, helperText, studyText } = getProfileText(profile);
      const studyArmStatusId =
        profile?.attributes?.profile?.study_arm_status_id;
      const isInCompleteProfile = !enrolledStatuses.includes(studyArmStatusId);
      return {
        label: (
          <div key={index}>
            {leadText && <LeadText>{leadText}</LeadText>}
            {helperText && <HelperText>{helperText}</HelperText>}
            <StudyText>{studyText}</StudyText>
            {isInCompleteProfile && <InCompleteProfile />}
          </div>
        ),
        key: profile?.attributes?.id,
        value: profile?.attributes?.id,
      };
    });

    return (
      <RadioGroupInput
        value={selectedOption}
        onChange={(event) => handleRadioChange(parseInt(event.target.value))}
        sx={width}
      >
        {mappedList.map((item) => (
          <CustomRadioInput
            selectedOption={item?.value === selectedOption}
            {...item}
          />
        ))}
      </RadioGroupInput>
    );
  };

  return <>{renderProfileList()}</>;
}

export default ProfileList;
