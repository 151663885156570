import { formatISO } from 'date-fns';

/* eslint-disable array-callback-return */
const possibleFields = [
  'date_of_birth',
  'maidan_name',
  'sex_at_birth',
  'former_name',
  'ethnicity',
  'postal_code',
  'race',
  'recruitment_source',
];

export const getPayload = (data, status) => {
  if (data?.date_of_birth) {
    let date = new Date(data.date_of_birth);
    date.setHours(0, 0, 0, 0);
    data.date_of_birth = formatISO(date, {
      representation: 'complete',
    });
  }
  const payload = possibleFields.map((field) => {
    if (data?.[field] !== '') {
      return {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: field,
            value: data?.[field],
          },
        },
      };
    }
  });
  //filter out undefined items
  return payload.filter((item) => item);
};
