import {
  BrowserRouter,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { AppRoutes } from 'routes';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Auth0ProviderWithRedirectCallback } from 'authentication/Auth0ProviderWithRedirectCallback';
import AutoLogoutWrapper from 'authentication/AutoLogoutWrapper';
import { getAppDomain, getAuth0ClientId } from 'config/API';
import { store } from 'ihp-bloom-redux/app/store';
import { Provider } from 'ihp-bloom-redux/app/redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import HelpWidget from 'components/HelpWidget';
import StudyConfigProvider from 'providers/StudyConfigProvider';

const APP_DOMAIN = getAppDomain();
const AUTH0_CLIENT_ID = getAuth0ClientId();

const generatedStorage = store(storage);
export let persistor = persistStore(generatedStorage);

function Root() {
  return (
    <Auth0ProviderWithRedirectCallback
      domain={APP_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}/auth0-callback`}
    >
      <Provider store={generatedStorage}>
        <PersistGate loading={null} persistor={persistor}>
          <AutoLogoutWrapper>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StudyConfigProvider>
                  <AppRoutes />
                  <HelpWidget />
                </StudyConfigProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </AutoLogoutWrapper>
        </PersistGate>
      </Provider>
    </Auth0ProviderWithRedirectCallback>
  );
}

const router = createBrowserRouter([{ path: '*', Component: Root }]);

export default function App() {
  return <RouterProvider router={router} />;
}
