import Button from 'ihp-components/components/v2/Button';
import { Container } from './styles';

export const ButtonsBar = ({
  selectedDollar,
  selectedPoints,
  onClaim,
  onCancel,
  isLoading,
  threshold,
}) => (
  <Container>
    <Button
      size='lg'
      fullWidth
      loading={isLoading}
      disabled={selectedPoints === 0 || isLoading || threshold > selectedPoints}
      onClick={onClaim}
    >
      Redeem
    </Button>
    <Button variant='link' onClick={onCancel} disabled={isLoading}>
      Cancel
    </Button>
  </Container>
);
