import { useEffect, useReducer, useState } from 'react';
import {
  Autocomplete,
  Grid,
  InputAdornment,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import { useDebounce } from 'hooks/useDebounce';

import { useGetAllResourceKeywordsQuery } from 'ihp-bloom-redux/features/resource/resourceKeywordsApiSlice';
import Drawer from 'components/Drawer';
import { FiltersLayout } from 'components/FiltersLayout';
import { RadioInput } from 'components/Input';
import { interests } from 'constants/interests';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';

import {
  checkboxContainer,
  StyledInputLabel,
  SearchWrapper,
  Keyword,
  KeyworkLabel,
  InterestWrapper,
  StackOverfollowHidden,
  KeywordItem,
  CancelIconWrapper,
} from './style';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetYourInterestsQuery } from 'ihp-bloom-redux/features/educationCategories/educationCategoriesApiSlice';

const allFilters = {
  interests: interests,
  ratings: [
    { id: 1, label: 'All', value: 'all', checked: false },
    { id: 2, label: 'Helpful', value: 'helpful', checked: false },
    { id: 3, label: 'Read', value: 'read', checked: false },
  ],
};

function reducer(state, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'interests': {
      return {
        ...state,
        interests: state.interests.map((item) => {
          if (action.data.code === item.code) {
            item.checked = !item.checked;
          }
          return item;
        }),
      };
    }
    case 'setYourInterests': {
      return {
        ...state,
        interests: state.interests.map((item) => {
          item.checked = action?.data?.includes(item.code);
          return item;
        }),
      };
    }
    case 'ratings': {
      return {
        ...state,
        ratings: state.ratings.map((item) => {
          if (action.data.value === item.value) {
            item.checked = true;
          } else {
            item.checked = false;
          }
          return item;
        }),
      };
    }
    case 'keyword-add': {
      if (state.keywords.find((item) => item?.id === action?.data?.id)) {
        return state;
      } else {
        return {
          ...state,
          keywords: [...state.keywords, action.data],
        };
      }
    }
    case 'keyword-delete': {
      return {
        ...state,
        keywords: state.keywords.filter(
          (item) => action?.data?.id !== item?.id
        ),
      };
    }
    case 'reset': {
      return {
        ...state,
        interests: state.interests.map((item) => {
          item.checked = false;
          return item;
        }),
        ratings: state.ratings.map((item) => {
          item.checked = false;
          return item;
        }),
        keywords: [],
      };
    }
    case 'cancel': {
      return {
        ...state,
        interests: state.interests.map((item) => {
          item.checked = action?.data?.includes(item.code);
          return item;
        }),
        ratings: state.ratings.map((item) => {
          item.checked = false;
          return item;
        }),
        keywords: [],
      };
    }
  }
}

const EducationalMaterialFilterDrawer = ({ open, onClose, anchor, myInterests, setIsReset }) => {
  const [filters, dispatch] = useReducer(reducer, {
    interests: [...allFilters.interests],
    ratings: [...allFilters.ratings],
    keywords: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 500);
  const [yourInterests, setYourInterests] = useState([]);

  // const { isLoading, items } = useGetAllResourceKeywordsQuery(
  //   {
  //     'filter[keyword]': debouncedSearch,
  //     'page[limit]': 5,
  //   },
  //   {
  //     skip: debouncedSearch.length < 3,
  //     selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => {
  //       return {
  //         isLoading: isLoading || isUninitialized || isFetching,
  //         items: data?.data?.map((item) => {
  //           const id = item.id;
  //           return {
  //             id,
  //             keyword: item?.attributes['keyword'],
  //           };
  //         }),
  //       };
  //     },
  //   }
  // );

  useEffect(() => {
    if (myInterests.length > 0) {
      setYourInterests(myInterests);
    }
  }, [myInterests]);

  useEffect( () => {
    async function setData() {
      if (yourInterests.length > 0) {
        await dispatch({ type: 'setYourInterests', data: yourInterests });
        onApply();
      }
    }
    setData();
  }, [yourInterests]);

  const handleOnSearchTermUpdate = (item) => {
    if (item && item !== '') {
      setSearchTerm(item);
    }
  };

  const handleOnRatingChange = (selectedItem) => {
    dispatch({ type: 'ratings', data: selectedItem });
  };

  const handleOnInterestsChange = (selectedItem) => {
    dispatch({ type: 'interests', data: selectedItem });
  };

  const handleRemoveKeyword = (selectedItem) => {
    dispatch({ type: 'keyword-delete', data: selectedItem });
  };

  const handleAddKeyword = (selectedItem) => {
    if (selectedItem && selectedItem !== '') {
      dispatch({ type: 'keyword-add', data: selectedItem });
      setSearchTerm('');
    }
  };

  const clearAll = () => {
    setYourInterests([]);
    setIsReset(true);
    dispatch({ type: 'reset', data: {} });
  };

  const onApply = () => {
    const appliedFilters = {};
    const ratings = filters.ratings.filter((item) => item.checked);
    const interests = filters.interests.filter((item) => item.checked);
    const keywords = filters.keywords.map((item) => item.id);
    if (ratings.length && ratings[0].value !== 'all') {
      if (ratings[0].value === 'read') {
        appliedFilters['is-read'] = true;
      } else {
        appliedFilters['status'] = ratings[0].value;
      }
    }
    if (keywords.length) {
      appliedFilters['keyword'] = keywords.join(',');
    }
    if (interests.length) {
      appliedFilters['educational-category'] = interests
        .map((item) => item.code)
        .join(',');
    }
    onClose(appliedFilters);
  };

  const onCloseDrawer = () => {
    dispatch({ type: 'cancel', data: yourInterests });
    onClose(null);
  };

  useEffect(() => {
    dispatch({ type: 'cancel', data: yourInterests });
  }, []);

  const children = () => {
    return (
      <Stack spacing={2}>
        {/* <StyledInputLabel>Interests</StyledInputLabel> */}
        {/* <InterestWrapper>
          <StackOverfollowHidden>
            {filters.interests.map((item) => (
              <FormCheckbox
                checked={item.checked}
                key={item.category}
                value={item.category}
                containerStyle={checkboxContainer}
                label={item.label}
                onChange={() => handleOnInterestsChange(item)}
              />
            ))}
          </StackOverfollowHidden>
        </InterestWrapper> */}
        <StyledInputLabel>Rating</StyledInputLabel>
        <RadioGroup>
          <Stack spacing={1}>
            {filters.ratings.map((option) => (
              <RadioInput
                checked={option.checked}
                hideRadioIcon={false}
                value={option.value}
                label={option.label}
                key={option.id}
                onChange={() => handleOnRatingChange(option)}
              />
            ))}
          </Stack>
        </RadioGroup>

        {/* <StyledInputLabel>Keyword</StyledInputLabel>
        <SearchWrapper>
          <Autocomplete
            freeSolo
            disableCloseOnSelect={false}
            value={null}
            blurOnSelect={true}
            clearOnBlur={true}
            options={(debouncedSearch.length > 2 && items) || []}
            getOptionLabel={(option) => option.keyword || ''}
            style={{}}
            onChange={(event, newValue) => {
              handleAddKeyword(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                onChange={(e) => handleOnSearchTermUpdate(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='end' size='large'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: null,
                  placeholder: 'Search',
                }}
              />
            )}
          />
        </SearchWrapper> */}
        <Grid container spacing={0}>
          {filters.keywords.map((option) => (
            <KeywordItem item>
              <Keyword key={option?.id} variant='contained'>
                <KeyworkLabel>{option?.keyword}</KeyworkLabel>
                <CancelIconWrapper
                  onClick={() => handleRemoveKeyword(option)}
                />
              </Keyword>
            </KeywordItem>
          ))}
        </Grid>
      </Stack>
    );
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={onCloseDrawer}>
      <FiltersLayout
        title='Filters'
        onClose={onCloseDrawer}
        clearAll={clearAll}
        onApply={onApply}
      >
        {children()}
      </FiltersLayout>
    </Drawer>
  );
};

export default EducationalMaterialFilterDrawer;
