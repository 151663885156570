import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { STUDY_ARM_ROLE_NAME } from '../../constants/global';
import {
  StyledAvatar,
  StyledCard,
  StyledCardBottom,
  StyledCardContentMobile,
  LockIconStyle,
  TaskGroupInfo,
} from './BasicCard.styles';
import { ReactComponent as GrayCircleIcon } from 'images/Task/gray-circle.svg';
import { ReactComponent as LockIcon } from 'images/activity/types/lock.svg';

export const BasicCardMobile = ({
  children,
  icon,
  iconBgColor,
  title,
  description,
  sx,
  currentIndex,
  hideNumbers,
  status,
  statusColor,
  color,
  details,
  isTaskGroup,
  subtasksLength,
  completedSubtasks,
  label,
  points,
  ...rest
}) => {
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const firstName = activeAccountProfile?.attributes?.profile?.first_name;
  const lastName = activeAccountProfile?.attributes?.profile?.last_name;
  const isDifferentPerson = person?.id !== details?.activityPersonId;

  const updatedChildren = children?.map((child, index) => {
    if (index === 0 && child?.props?.metaData) {
      const updatedMetaData = child?.props?.metaData.filter(
        (e) => e.label !== '0 day left to complete'
      );

      // Create a new copy of the child with updated metaData
      return React.cloneElement(child, { metaData: updatedMetaData });
    }
    return child;
  });

  let typeMessage = '';
  if (isDifferentPerson) {
    const roleName =
      activeAccountProfile?.attributes?.profile?.study_arm_role_name;

    const isLar = roleName === STUDY_ARM_ROLE_NAME.LAR;
    if (isLar) {
      typeMessage = `| On behalf of ${firstName} ${lastName}`;
    }

    const isReporter = roleName === STUDY_ARM_ROLE_NAME.REPORTER;
    if (isReporter) {
      typeMessage = `| Related to ${firstName} ${lastName}`;
    }
  }

  return (
    <StyledCard variant='outlined' sx={sx} {...rest}>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {details?.isLocked && (
              <Box sx={LockIconStyle}>
                <LockIcon />
              </Box>
            )}
            <StyledAvatar iconBgColor={iconBgColor} aria-label='recipe'>
              {icon}
            </StyledAvatar>
            <Stack gap='5px' alignItems={'flex-end'}>
              {!isTaskGroup && (
                <Typography variant='pn7'>
                  {`ESTIMATED TIME: ${label !== '' ? label : '0 MINS'}`}
                </Typography>
              )}
            </Stack>
          </Box>
          <StyledCardContentMobile>
            <Typography variant='h4' color={sx?.color || 'primary.darkGray70'}>
              {title}
            </Typography>
            <Typography
              variant='pn4'
              fontStyle={'Italic'}
              color={'primary.darkGray70'}
            >
              <Box sx={{ display: 'flex' }}>
                <Box>{details?.type?.label}</Box>
                {isDifferentPerson && (
                  <Box sx={{ fontStyle: 'normal', marginLeft: '5px' }}>
                    {typeMessage}
                  </Box>
                )}
              </Box>
            </Typography>
            <Typography
              variant='pn4'
              color={sx?.color || 'primary.gray75'}
              sx={{ maxWidth: '95%' }}
            >
              {description}
            </Typography>
            {isTaskGroup && (
              <TaskGroupInfo>
                {completedSubtasks}/{subtasksLength}
                <GrayCircleIcon width={15} style={{ margin: '3' }} />
                Task group
              </TaskGroupInfo>
            )}
          </StyledCardContentMobile>
        </Box>
      </Box>
      <StyledCardBottom>{updatedChildren}</StyledCardBottom>
    </StyledCard>
  );
};

BasicCardMobile.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  iconBgColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  currentIndex: PropTypes.number,
  hideNumbers: PropTypes.bool,
  isTaskGroup: PropTypes.bool,
  subtasksLength: PropTypes.number,
  completedSubtasks: PropTypes.number,
};
