import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { setOnboardingCompleted } from 'ihp-bloom-redux/features/authentication/authenticationSlice';

import { NotEligible } from './NotEligible';
import { Questions } from './Questions';
import { healthSteps } from 'mocks/health';
import { getAuth0LoginPageConfig } from 'utils/auth0';

export default function HealthCheck() {
  document.title = 'Single Ventricle SOURCE Health check';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();

  const stepValue = 100 / healthSteps.content.length;
  const [questionNr, setQuestionNr] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [progress, setProgress] = useState(stepValue);
  const [step, setStep] = useState('questions');
  const addAnswer = (value) => {
    const newAnswers = [...answers];
    const answer = healthSteps.content[questionNr].items.find(
      (item) => item.value === value
    );
    newAnswers[questionNr] = answer;
    setAnswers(newAnswers);
  };

  const openSignupPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig({ context: 'signup' }));
  };

  const onNextClick = () => {
    if (progress === 100) {
      const checkValid = answers.find((answer) => answer?.valid !== true);
      if (!checkValid) {
        dispatch(setOnboardingCompleted());
        openSignupPage();
      } else {
        setStep('result');
      }
      return;
    }
    setQuestionNr(questionNr + 1);
    setProgress(progress + stepValue);
  };

  const onBackClick = () => {
    if (questionNr === 0) {
      navigate('/onboarding/welcome-to-study');
    }
    setQuestionNr(questionNr - 1);
    setProgress(progress - stepValue);
  };

  switch (step) {
    case 'result':
      return <NotEligible />;
    case 'questions':
    default:
      return (
        <Questions
          question={healthSteps.content[questionNr].questionTitle}
          options={healthSteps.content[questionNr].items}
          progress={progress}
          inputValue={answers[questionNr]}
          onAddAnswer={addAnswer}
          prevStep={onBackClick}
          nextStep={onNextClick}
        />
      );
  }
}
