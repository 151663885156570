import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const researchOpportunitiesApiSlice = createApi({
  reducerPath: 'researchOpportunitiesApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['ResearchOpportunties'],
  endpoints: (build) => ({
    getAllResearchOpportunities: build.query({
      query: ({ participantId, ...params }) => ({
        url: `/people/${participantId}/research-opportunities`,
        method: 'GET',
        params,
      }),
      providesTags: ['ResearchOpportunties'],
    }),
    changeResearchOpportunitiesStatus: build.mutation({
      query: ({ personId, researchOpportunityId, payload }) => ({
        url: `/people/${personId}/research-opportunities/${researchOpportunityId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      // invalidatesTags: ['ResearchOpportunties'], dont uncomment as it break pagination in research opporuntities listing
    }),
    getFilteredResearchOpportunities: build.query({
      query: ({ ...params }) => ({
        url: `/research-opportunities`,
        method: 'GET',
        params,
      }),
      providesTags: ['ResearchOpportunties'],
    }),

    getResearchOpportunityDetails: build.query({
      query: ({ researchOpportunityId, ...params }) => ({
        url: `/research-opportunities/${researchOpportunityId}`,
        method: 'GET',
        params,
      }),
      providesTags: ['ResearchOpportunties'],
    }),

    updatePersonStudyArmResearchOpportunity: build.mutation({
      query: ({ personStudyArmId, personStudyArmResearchOpportunityId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}/research-opportunities/${personStudyArmResearchOpportunityId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
    }),

    createPersonStudyArmResearchOpportunity: build.mutation({
      query: ({ personStudyArmId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}/research-opportunities`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
  }),
});

export const {
  useGetAllResearchOpportunitiesQuery,
  useChangeResearchOpportunitiesStatusMutation,
  useGetFilteredResearchOpportunitiesQuery,
  useGetResearchOpportunityDetailsQuery,
  useUpdatePersonStudyArmResearchOpportunityMutation,
  useCreatePersonStudyArmResearchOpportunityMutation
} = researchOpportunitiesApiSlice;
