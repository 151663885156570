import { useLocation } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { LinkButton } from 'components/Buttons';
import Button from 'ihp-components/components/v2/Button';
import { useLogout } from 'utils/useLogout';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-3.svg';
import { ReactComponent as AcmeLogo } from 'images/av-logo-vertical.svg';
import { Wrapper, Container, Badge, SignIn } from './styles';

function Rejected() {
  document.title = 'Single Ventricle SOURCE';
  const logout = useLogout('welcome?open-auth0=signup');
  const theme = useTheme();
  const location = useLocation();
  const isOriginProfileConsents = location.state === 'profile-consents';

  return (
    <Wrapper>
      <Container>
        <AcmeLogo width={100} height={69} />
        <Stack spacing='20px'>
          <Stack spacing='10px'>
            <Typography variant='h2' color='#606B71' align='center'>
              {isOriginProfileConsents
                ? 'Thank you for your participation'
                : 'Sorry to see you go'}
            </Typography>
            <Typography
              textAlign='center'
              variant='body2'
              mt={2}
              color='#7C7C7C'
            >
              {isOriginProfileConsents
                ? 'You have been withdrawn from consideration for this study. If you have any questions about your withdrawal, please contact our support line at:'
                : 'If you have any further questions, please contact our support line at:'}
            </Typography>
          </Stack>
          <Badge>
            <PhoneIcon width={20} /> 866-267-4479
          </Badge>
          <Button onClick={(e) => logout(e, 'welcome')} width='100%'>
            Done
          </Button>
          {!isOriginProfileConsents && (
            <Button variant='link' onClick={logout} width='100%'>
              Continue enrolling
            </Button>
          )}
        </Stack>
      </Container>
    </Wrapper>
  );
}

export default Rejected;
