import {
  Box,
  Grid,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as ArrowLeft } from 'images/shared/arrow-left-1.svg';
import { ReactComponent as Times } from 'images/shared/times-3.svg';

export const MyProfilePageWrapper = styled('div')(({ theme }) => ({
  padding: '50px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

export const StyledGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '25px',
  },
}));

export const MyProfilePageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '15px',
  padding: '0px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.white,
    padding: '25px',
  },
}));

export const StyledBackArrow = styled(ArrowLeft)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '18px',
    height: '20px',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
  },
}));

export const ProfileTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: '0 25px',
  },
  '& .MuiTabs-scroller': {
    overflow: 'scroll !important',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  '& .MuiTabs-indicator': {
    height: '5px',
    borderRadius: '2px',
    backgroundColor: theme.palette.primary.lightBlue100
  },
}));

export const ProfileTab = styled((props) => <Tab {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '140%',
    color: theme.palette.primary.gray50,
    padding: 0,
    margin: '10px 30px 10px 0px',
    minWidth: 'unset',
    minHeight: '30px',
    '&:hover': {
      color: theme.palette.primary.gray100,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.gray100,
      fontWeight: 600,
    },
  })
);

export const ItemWrapper = styled(Box)({
  background: '#EAECEC',
  padding: isMobile() ? '15px' : '20px',
  borderRadius: '5px',
  marginBottom: '10px',
});

export const NameHeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
});

export const FullNameContainer = styled(Box)({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '140%',
  marginBottom: '10px',
});

export const RelationContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginLeft: '8px',
}));

export const FlexedBox = styled(Box)({
  display: 'flex',

  svg: {
    cursor: 'pointer',
  },
});

export const PhoneNumberContainer = styled(Box)({
  display: 'flex',
  marginTop: '10px',
});

export const EmailContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '9px',
  marginLeft: '8px',
}));

export const PhoneContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '10px',
  marginLeft: '8px',
}));

export const ModalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(isMobile()
    ? {
        height: '100%',
        borderRadius: 0,
      }
    : {
        maxWidth: '680px',
        maxHeight: '90%',
        borderRadius: '15px',
        boxShadow: '0px 1px 20px rgba(0, 0, 0, 0.05)',
      }),
});

export const ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  padding: '40px 45px 40px',
  justifyContent: 'center',
});

export const TimesIcon = styled(Times)({
  position: 'absolute',
  right: 45,
  top: 48,
  cursor: 'pointer',
  '& path': {
    fill: '#888888',
  },
});

export const Loader = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    height='200px'
  >
    <CircularProgress />
  </Box>
);

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};
