import { styled } from '@mui/system';
import { Modal } from '@mui/material';

const getStyles = ({ palette, breakpoints }) => ({
  title: {
    color: '#2B3A41',
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },

  fieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
  },

  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflowY: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '30px 40px',
    borderBottom: `1px solid ${palette.primary.pattensBlue}`,
    [breakpoints.down('sm')]: {
      padding: '24px 24px 10px 24px',
      borderBottom: '0px',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
    },
  },
  iconWrapper: {
    marginTop: '3px',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '15px 40px 40px',
    [breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 24px 24px',
    },
  },

  formWrapper: {
    padding: '15px 40px 40px',
    [breakpoints.down('sm')]: {
      padding: '0px 24px 24px',
    },
  },
});

export const DtpModal = styled(Modal)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: theme.palette.primary.gray5,
    opacity: '0.9 !important',
  },
}));

export default getStyles;
