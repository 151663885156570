const getStyles = (theme) => ({
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: 200,
    padding: '35px',
  },
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  mainBox: {
    backgroundColor: '#FFFFFF',
    padding: '20px 30px 120px 30px',
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '60px',
    },
  },
  sectionBox: {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkboxContainer: {
    width: '250px',
    border: 'none',
    background: 'transparent',
  },
  checkbox: {
    paddingLeft: 0,
  },
});

export default getStyles;
