import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';
import { Loader } from 'components/Loader';
import {
  ModalContainer,
  ModalContent,
  SectionContainer,
  ModalFooter,
  RadioInputGroup,
} from './components';
import { getMyProfileReasonsForStudyArm } from '../MyProfile/utils';
import { ReactComponent as Logo } from 'images/svs-logo.svg';
import { getStyles } from './styles';
import { findStudyAttribute } from 'utils/study';
import { useRejectConsent } from './useRejectConsent';
import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_CODES,
  STUDY_ARM_STATUS_CODES,
  VIRTUAL_SITE,
} from 'constants/global';
import getRoute from 'utils/navigator';
import { useCreatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';

const RejectDocusignModal = ({
  isOpen,
  backToConsent,
  onClose,
  isSubmitting,
  onSubmit,
}) => {
  const [isLoadingAccountProfile, setIsLoadingAccountProfile] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const consentData = useSelector((state) => state.consentSlice);
  const user = useSelector((state) => state.user);
  const { activeAccountProfile } = user;
  const { configuration, studyConfiguration } = useSelector(
    (state) => state.configuration
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const {
    createPersonStudyArmAttributes,
    updatePersonStudyArmRecord,
    updateAccountProfileRecord,
    isLoading,
  } = useRejectConsent();

  const [createPersonStudyArm] = useCreatePersonStudyArmMutation();

  const myProfileReasons = useMemo(
    () =>
      getMyProfileReasonsForStudyArm(
        findStudyAttribute(studyConfiguration || [], 'withdrawal_reasons'),
        'reject-consent',
        activeAccountProfile?.attributes?.profile?.study_arm_code
      ),
    [
      activeAccountProfile?.attributes?.profile?.study_arm_code,
      studyConfiguration,
    ]
  );

  if (!myProfileReasons?.length > 0) {
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Loader />
      </Modal>
    );
  }

  const handleOnChange = (label) => {
    setOptions((prevData) => {
      return prevData.map((option) => {
        if (option.label === label) {
          return { ...option, checked: !option.checked };
        }
        return option;
      });
    });
  };

  const refetchAccountProfileData = async (profileId) => {
    const id = Number(profileId);
    try {
      setIsLoadingAccountProfile(true);
      const accountProfileResponse = await dispatch(
        accountProfileApiSlice.endpoints.getAccountProfile.initiate(id, {
          forceRefetch: true,
        })
      );
      setIsLoadingAccountProfile(false);
      if (accountProfileResponse?.data?.data) {
        const activeAccountProfilePayload = {
          ...user,
          activeAccountProfile: accountProfileResponse?.data?.data,
        };
        await dispatch(setValues(activeAccountProfilePayload));
        navigate(getRoute(activeAccountProfilePayload));
      }
    } catch (error) {
      setIsLoadingAccountProfile(false);
      console.log('Error on fetching new profile');
    }
  };

  const goToEnrollment = async () => {
    const reasons = options.filter((item) => item.checked);
    const reasonValues = reasons.map((item) => item.value).join(',');

    await createPersonStudyArmAttributes(
      activeAccountProfile?.attributes?.profile?.account_person_study_arm_id,
      reasonValues
    );

    const contactEnrollmentConfirmed =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_CONFIRMED
      ];

    const participantStudyArmRole =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
        STUDY_ARM_ROLE_CODES.Participant
      ];

    const response = await createPersonStudyArm({
      personId: user?.person?.id,
      payload: {
        data: {
          type: 'person-study-arm',
          attributes: {
            person_id: Number(user?.person?.id),
            study_arm_id: contactEnrollmentConfirmed?.study_arm_id,
            study_arm_status_id: contactEnrollmentConfirmed?.id,
            site_id: VIRTUAL_SITE.id,
            study_arm_role_id: participantStudyArmRole?.id,
          },
        },
      },
    });

    const researchEnrollmentDeclined =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_DECLINED
      ];

    await updatePersonStudyArmRecord(
      activeAccountProfile?.attributes?.profile?.account_person_study_arm_id,
      researchEnrollmentDeclined?.id
    );

    //LAR
    if (
      activeAccountProfile?.attributes?.profile?.account_person_study_arm_id !==
      activeAccountProfile?.attributes?.profile
        ?.subject_primary_person_study_arm_id
    ) {
      await updatePersonStudyArmRecord(
        activeAccountProfile?.attributes?.profile
          ?.subject_primary_person_study_arm_id,
        researchEnrollmentDeclined?.id
      );
    }

    const personStudyArmId = Number(response?.data?.data?.id);

    await updateAccountProfileRecord(
      activeAccountProfile?.id,
      personStudyArmId,
      personStudyArmId,
      null
    );

    refetchAccountProfileData(activeAccountProfile?.id);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (myProfileReasons?.length > 0) {
      setOptions(myProfileReasons);
    }
  }, [myProfileReasons]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContainer
        sx={{
          backgroundColor: (theme) => theme.palette.primary.white,
        }}
      >
        <>
          <Box display='flex' justifyContent='center' pt={8}>
            <Logo />
          </Box>
          <ModalContent>
            <Typography
              textAlign='center'
              variant='h2'
              color='primary.darkGray100'
              sx={{ marginTop: '-20px' }}
            >
              We are sorry to see you go
            </Typography>
            <Typography
              textAlign='center'
              variant='body2'
              mt={2}
              color='primary.darkGray100'
            >
              If you have a moment, can you please tell us why you are declining
              consent? This will help us address any potential barriers to
              participation. Thank you.
            </Typography>
            <SectionContainer>
              <RadioInputGroup
                id='reason'
                name='reason'
                value={selectedReason}
                onChange={(e) => {
                  setSelectedReason(e.target.value);
                }}
              >
                {myProfileReasons.map((option) => (
                  <FormCheckbox
                    containerStyle={styles.formStyle}
                    key={option.value}
                    value={option.label}
                    label={option.label}
                    checked={option.checked}
                    onChange={() => handleOnChange(option.label)}
                  />
                ))}
              </RadioInputGroup>
            </SectionContainer>
          </ModalContent>

          <ModalFooter>
            <Button
              onClick={() => backToConsent(consentData?.lastOpenedConsent)}
              fullWidth
            >
              Back to consent
            </Button>
            <Button
              fullWidth
              onClick={goToEnrollment}
              size='sm'
              variant='outlined'
              sx={styles.outlineButton}
              disabled={options.every((key) =>
                key?.checked ? key?.checked === false : true
              )}
              loading={isLoading || isLoadingAccountProfile}
            >
              I prefer to receive information only
            </Button>
          </ModalFooter>
        </>
      </ModalContainer>
    </Modal>
  );
};

RejectDocusignModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  backToConsent: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default RejectDocusignModal;
