import React from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import { StyledPaper } from './styles';

export const CardItem = ({
  header,
  subHeader,
  children,
  ...rest
}) => (
  <StyledPaper elevation={0} {...rest}>
    <Stack spacing='25px'>
      <Stack spacing='20px'>
        {header &&
          <Typography variant='h5' align='center' color='secondary.gray4'>
            {header}
          </Typography>
        }
        {subHeader &&
          <Typography variant='pl4' align='center' color='primary.gray75'>
            {subHeader}
          </Typography>
        }
      </Stack>
      <Box>{children}</Box>
    </Stack>
  </StyledPaper>
);

CardItem.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  children: PropTypes.node.isRequired,
};
