import DatePicker from '@mui/lab/DatePicker';
import { StyledFormControl } from './CustomDatePicker.styles';
import { ReactComponent as CalendarIcon } from '../assets/svg/calendar.svg';
import { TextField } from './TextField';
import { useState } from 'react';
import { ClickAwayListener } from '@mui/material';

export const CustomDatePicker = ({
  label,
  fullWidth,
  inputStyles,
  onChange,
  error,
  errorLabel,
  datePickerIcon,
  ...rest
}) => {
  const customOnChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    setValue(value);
    if (value?.getFullYear()?.toString()?.length === 4) {
      setOpen(false);
    }
  };
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();

  const handleClick = (event) => {
    // selecting a year
    if (event?.target?.innerText?.length === 4) {
      setOpen(true);
    }
    const newDay = event?.target?.innerText;
    var selectedDay = new Date(value).getDate();
    if (parseInt(selectedDay) === parseInt(newDay)) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <StyledFormControl onClick={handleClick} fullWidth={fullWidth}>
        <DatePicker
          fullWidth
          open={open}
          value={value}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          components={{
            OpenPickerIcon: datePickerIcon ?? CalendarIcon,
          }}
          renderInput={(params) => {
            return (
              <TextField
                fullWidth={fullWidth}
                endAdornment={params.InputProps?.endAdornment}
                placeholder={rest.placeholder || params.inputProps.placeholder}
                sx={inputStyles}
                onChange={onChange ? onChange : () => { }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder:
                    rest.placeholder || params.inputProps.placeholder,
                }}
                label={label}
                error={error}
                errorLabel={errorLabel}
                ref={rest.inputRef}
              />
            );
          }}
          onChange={customOnChange}
          {...rest}
        />
      </StyledFormControl>
    </ClickAwayListener>
  );
};
