import { memo, useCallback, useEffect, useState, useRef } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useUpdateParticipantTaskMutation,
  useUploadFileMutation,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { participantTaskApiSlice } from 'ihp-bloom-redux/features/tasks/participantTaskApiSlice';
import { Formio } from '@formio/react';
import { FinalStep } from './FinalStep';
import { Loader } from 'components/Loader';
import FormIO from './FormIO';
import { message } from './FormIO/message';
import { multiValueRow } from './FormIO/multiValueRow';
import { multiValueTable } from './FormIO/multiValueTable';
import { wizard } from './FormIO/wizard';
import { useGetProfileParticipantTask } from '../hooks';
import { convertUTCStringToLocalDateObject, dateFormatter } from 'utils/date';
import { Typography, useMediaQuery } from '@mui/material';
import { TimeLimitPopup } from './styles';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock-filled.svg';
import ActivityInstructions from 'components/Activities/Instructions';
import {
  taskTypeLabelMapping,
  getUploadFields,
  surveyDocumentTypesMap,
} from '../utils';
import { CenteredFullPageWrapper } from 'components/CenteredFullPageWrapper';
import { ReactComponent as InfoIcon } from 'images/activity/types/Info.svg';
import ActivityHeader from 'components/Activities/Header';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';
import { debounce } from 'hooks/useDebounce';
import { CancelModal } from './CancelModel';
import { useGetDocumentTypesQuery } from 'ihp-bloom-redux/features/documentTypes/documentTypeApiSlice';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { useGetSalivaKitNumber } from 'features/activities/salivaKit/hooks/useGetSalivaKitNumber';
import {
  DUPLICATE_CHILD_EMAIL,
  REGISTER_DNA_KIT_ERROR_MESSAGE,
} from './constants';

const SURVEY_TIMES_UP_ALERT = 60000;

const MemoizedForm = memo(
  ({
    form,
    attributes,
    participantTaskDetails,
    resetForm,
    handleSubmit,
    handleCancel,
    handleSave,
    handleNext,
    isMobile,
    handleChange,
    // SING-675
    firstName,
    lastName,
    isChildToAdulthoodSurvey,
    setIsChildToAdulthoodSurvey,
    hasDuplicateChildEmailError,
    initialChildFormData,
  }) => {
    const data = participantTaskDetails?.participantTaskEntry
      ? { data: participantTaskDetails?.participantTaskEntry }
      : {
          data: {
            role: '',
          },
        };

    // SING-513 Users needs to add the first physician without clicking on the edit button
    if (participantTaskDetails?.title === "Medical Record Share Selection") {
      data.data = {
        ...data.data,
        physician: [],
      };
    }

    // SING-112 Saliva kit activity
    const isSalivaKitActivity =
      // attributes?.title === 'Register DNA Collection Kit';
      attributes?.title?.includes('Register DNA Collection Kit');

    const { salivaKitNumber } = useGetSalivaKitNumber({
      skip: !isSalivaKitActivity,
    });

    if (isSalivaKitActivity) {
      const formWithSalivaKitValidation = JSON.parse(JSON.stringify(form));

      const salivaKitComponent =
        formWithSalivaKitValidation?.components?.[0]?.components?.find(
          (c) => c.key === 'salivaKitNumber'
        );

      salivaKitComponent.validate.custom = `valid = input.toLowerCase() === '${salivaKitNumber.toLowerCase()}'`;

      salivaKitComponent.validate.customMessage = REGISTER_DNA_KIT_ERROR_MESSAGE;

      form = formWithSalivaKitValidation;
    }

    // SING-939: Child to Adulthood Transitioning Duplicate Email Error
    if (participantTaskDetails?.code === 'ADULTHOOD') {
      setIsChildToAdulthoodSurvey(true);
      const childToAdulthoodForm = JSON.parse(JSON.stringify(form));

      const childEmailComponent =
        childToAdulthoodForm?.components?.[0]?.components?.find(
          (c) => c.key === 'childEmail'
        );
      // Add a custom class to catch this component later for toggling error
      if (childEmailComponent) {
        childEmailComponent.customClass = DUPLICATE_CHILD_EMAIL.CUSTOM_CLASS;
      }
      
      if (initialChildFormData?.current && Object.keys(initialChildFormData.current).length !== 0) {
        childToAdulthoodForm?.components?.[0]?.components?.forEach((c) => {
          if (c.key === 'childFirstName' && initialChildFormData.current?.childFirstName !== '') {
            c.defaultValue = initialChildFormData.current?.childFirstName;
          }
          if (c.key === 'childPhoneNumber' && initialChildFormData.current?.childPhoneNumber !== '') {
            c.defaultValue = initialChildFormData.current?.childPhoneNumber;
          }
          if (c.key === 'childEmail' && initialChildFormData.current?.childEmail !== '') {
            c.defaultValue = initialChildFormData.current?.childEmail;
          }
        });
      }
      form = childToAdulthoodForm;
    }

    return (
      <>
        <FormIO
          isMobile={isMobile}
          form={form}
          submission={data}
          attributes={{
            ...attributes,
            // SING-675
            title: attributes.title + ` - ${firstName} ${lastName}`,
            type: participantTaskDetails?.type,
            compensationValue: participantTaskDetails?.compensationValue,
            estimatedTime: participantTaskDetails?.estimatedTime,
            timeLimit: participantTaskDetails?.timeLimit,
          }}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          onSave={handleSave}
          onNext={handleNext}
          onChange={handleChange}
          isChildToAdulthoodSurvey={isChildToAdulthoodSurvey}
          hasDuplicateChildEmailError={hasDuplicateChildEmailError}
        />
      </>
    );
  }
);

const Survey = () => {
  document.title = 'Single Ventricle SOURCE Survey';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const navigateBack = () => navigate('/my-activities');
  const [showInstructions, setShowInstructions] = useState(true);

  const [form, setForm] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [typeMessage, setTypeMessage] = useState('');

  const [showCelebrationScreen, setShowCelebrationScreen] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [benefitAttributes, setBenefitAttributes] = useState({});
  const [showTimeLimitPopup, setShowTimeLimitPopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [type, setType] = useState();
  const [resetForm, setResetForm] = useState(false);
  const [participantTaskDetails, setParticipantTaskDetails] = useState({});
  const participantTaskId = parseInt(useParams().id);
  const [uploadFile] = useUploadFileMutation();
  const { data: documentTypes, isFetching: documentTypeFetching } =
    useGetDocumentTypesQuery();
  const [value, setValue] = useState('');
  const [isChildToAdulthoodSurvey, setIsChildToAdulthoodSurvey] =
    useState(false);
  const [hasDuplicateChildEmailError, setHasDuplicateChildEmailError] =
    useState(false);
  const initialChildFormData = useRef({
    childFirstName: '',
    childPhoneNumber: '',
    childEmail: ''
  });

  Formio.Templates.current = {
    ...Formio.Templates.current,
    message,
    multiValueRow,
    multiValueTable,
    wizard: {
      ...Formio.Templates.current.wizard,
      form: wizard.form,
    },
  };

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return (
        !isOpenModal &&
        value !== '' &&
        !participantTaskDetails?.completed &&
        !showCelebrationScreen &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [
      value,
      isOpenModal,
      participantTaskDetails?.completed,
      showCelebrationScreen,
    ]
  );
  const blocker = useBlocker(shouldBlock);

  const firstName = activeAccountProfile?.attributes?.profile?.first_name;
  const lastName = activeAccountProfile?.attributes?.profile?.last_name;

  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;
  const response = useGetProfileParticipantTask(
    participantTaskId,
    accountProfileId
  );
  const isDifferentPerson =
    accountPersonStudyArmId !== response?.personStudyArmId;

  useEffect(() => {
    if (isDifferentPerson) {
      const roleName =
        activeAccountProfile?.attributes?.profile?.study_arm_role_name;

      const isLar = roleName === STUDY_ARM_ROLE_NAME.LAR;
      const isReporter = roleName === STUDY_ARM_ROLE_NAME.REPORTER;
      let suffix = '';
      if (isLar) {
        suffix = `On behalf of`;
      }
      if (isReporter) {
        suffix = `Related to`;
      }
      if (isLar || isReporter) {
        setTypeMessage(
          <p>
            {suffix} <br />
            <Typography
              variant='h5'
              fontSize={'16px'}
              color='primary.darkGray100'
            >
              {firstName} {lastName}
            </Typography>
          </p>
        );
      }
    }
  }, []);

  if (
    form == null &&
    JSON.stringify(response?.attributes?.form) !== JSON.stringify(form) &&
    response.type !== type
  ) {
    setForm(response?.attributes?.form);
    setAttributes({
      ...response?.attributes,
      title: response.title,
      description: response.description,
    });
    setType(response?.type);
    setParticipantTaskDetails(response);
  }

  const [
    updateParticipantTask,
    { isLoading: createParticipantTaskEntryLoading },
  ] = useUpdateParticipantTaskMutation();

  useEffect(() => {
    let timeLimitPopupTimer = null;
    let timeLimitTimer = null;
    if (
      !showInstructions &&
      attributes?.availability_window !== null &&
      attributes?.availability_window !== undefined
    ) {
      timeLimitPopupTimer = setTimeout(() => {
        setShowTimeLimitPopup(true);
      }, (attributes?.availability_window - 1) * SURVEY_TIMES_UP_ALERT);
      timeLimitTimer = setTimeout(() => {
        if (attributes?.howTo?.display) {
          setShowTimeLimitPopup(false);
          setShowInstructions(true);
          setResetForm(true);
        }
      }, attributes?.availability_window * SURVEY_TIMES_UP_ALERT);
    }

    return () => {
      clearTimeout(timeLimitPopupTimer);
      clearTimeout(timeLimitTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInstructions]);

  const debounceSubmit = debounce(async ({ data, isSave }) => {
    const uploadFields = getUploadFields(data);
    initialChildFormData.current = { ...data };
    for (const uploadField of uploadFields) {
      if (data[uploadField].length === 1) {
        let fileInfo = data[uploadField][0];

        const docType = surveyDocumentTypesMap(participantTaskDetails.code);
        const documentType = documentTypes?.data?.filter(
          (document) => docType === document?.attributes?.type
        )?.[0];

        let uploadFilePayload = {
          data: {
            type: 'participant-task-documents',
            attributes: {
              storage: fileInfo.storage,
              name: fileInfo.name,
              url: fileInfo.url,
              size: fileInfo.size,
              mime_type: fileInfo.type,
              original_name: fileInfo.originalName,
              hash: fileInfo.hash,
              document_type_id: parseInt(documentType?.id || 1),
            },
          },
        };

        await uploadFile({
          participantTaskId,
          payload: uploadFilePayload,
        });
      }
    }

    // convert UTC to local date
    // if task has start date then send that else send current date
    const localStartDate = participantTaskDetails?.startDate
      ? convertUTCStringToLocalDateObject(participantTaskDetails?.startDate)
      : new Date();
    const start_time = dateFormatter(localStartDate);
    const end_time = dateFormatter(new Date());

    /* This payload will be sent to API */
    let entryObj = {};

    Object.keys(data).map((key) => {
      if (uploadFields.indexOf(key) === -1) {
        entryObj[key] = data[key];
      }
    });
    const requestBody = {
      participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participantTaskId),
          attributes: {
            status: isSave ? 'in_progress' : 'completed',
            start_time,
            end_time,
            entry: entryObj,
          },
        },
      },
    };

    if (!isSave) {
      setShowLoader(true);
      setValue('');
    }

    // TODO: in case of error on this api call define a user experience
    return updateParticipantTask(requestBody)
      .then((response) => {
        // SING-939: Child to Adulthood Transitioning Duplicate Email Error
        const errorObj = response?.error?.data?.errors?.[0];
        if (
          errorObj?.status === DUPLICATE_CHILD_EMAIL.API_CODE &&
          errorObj?.detail === DUPLICATE_CHILD_EMAIL.API_MESSAGE
        ) {
          setHasDuplicateChildEmailError(true);
          setShowLoader(false);
          return;
        }
        setHasDuplicateChildEmailError(false);

        if (!isSave) {
          setShowLoader(false);
        }
        if (!isSave && attributes?.celebrationScreen?.display) {
          setBenefitAttributes(response?.data?.data?.attributes);
          setShowCelebrationScreen(true);
        } else if (!isSave && !attributes?.celebrationScreen?.display) {
          navigateBack();
        }

        // Remove this when fully shifted to api v3
        dispatch({
          type: `${participantTaskApiSlice.reducerPath}/invalidateTags`,
          payload: ['ParticipantTaskDetails'],
        });
      })
      .catch(console.error);
  }, 600);

  const getHandleSubmit =
    (isSave) =>
    async ({ data }) => {
      debounceSubmit({ data, isSave });
    };

  const handleSubmit = useCallback(getHandleSubmit(false), [
    participantTaskDetails?.id,
  ]);
  const handleSave = useCallback(getHandleSubmit(true), [
    participantTaskDetails?.id,
  ]);
  const handleCancel = useCallback(
    () => setIsOpenModal(true),
    [participantTaskDetails?.id]
  );
  const handleChange = useCallback(
    (change) => {
      if (change) {
        setValue(`yes`);
      }
    },
    [participantTaskDetails?.id]
  );

  const onCloseModal = () => setIsOpenModal(false);

  // We will trigger this to mark survey task status=in-progress in DB
  const startSurvey = () => {
    if (participantTaskDetails?.timeLimit) {
      handleSave({ data: {} });
    }

    setShowInstructions(false);
  };

  useEffect(() => {
    // If how to screen is not configured set task status=in_progress immidiately
    if (attributes && !attributes.howTo?.display) {
      startSurvey();
    }

    return () => {
      if (attributes && !attributes.howTo?.display) {
        startSurvey();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes]);

  if (
    participantTaskDetails.loadingParticipantTask ||
    form == null ||
    showLoader
  ) {
    return <Loader />;
  }

  if (participantTaskDetails?.completed || showCelebrationScreen) {
    return (
      <FinalStep
        attributes={{
          points: participantTaskDetails?.compensationValue,
          thankyouMsg: attributes?.celebrationScreen?.title,
          description: attributes?.celebrationScreen?.description,
          ...benefitAttributes,
        }}
      />
    );
  }

  if (showInstructions) {
    let estimatedTime = 'N/A';
    if (participantTaskDetails?.estimatedTime) {
      estimatedTime = `${participantTaskDetails?.estimatedTime} min`;
    }

    let timeLimit = undefined;
    if (participantTaskDetails?.timeLimit) {
      timeLimit = `${participantTaskDetails?.timeLimit} min`;
    }

    return (
      <>
        <ActivityHeader
          title={participantTaskDetails?.title}
          points={participantTaskDetails?.compensationValue}
          type={taskTypeLabelMapping[participantTaskDetails?.type]}
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          showStepCounter={false}
          subtitle={
            <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              Survey - <ClockIcon fill='grey' /> {estimatedTime}
            </p>
          }
        />
        <CenteredFullPageWrapper>
          <ActivityInstructions
            hideMetaData={isMobile}
            hideInstructionsTitle={false}
            title={participantTaskDetails?.title}
            type={taskTypeLabelMapping[participantTaskDetails?.type]}
            estimatedTime={estimatedTime}
            timeLimit={timeLimit}
            points={participantTaskDetails?.compensationValue}
            participantInfo={typeMessage}
            description={
              attributes?.howTo?.display === true
                ? attributes?.howTo?.instructions
                : participantTaskDetails?.description
            }
            startButtonText='Continue'
            onClickContinue={startSurvey}
            onCancel={() => navigate('/my-activities')}
          />
        </CenteredFullPageWrapper>
      </>
    );
  }

  return (
    <>
      {blocker?.state === 'blocked' || isOpenModal ? (
        <CancelModal
          isOpenModal={true}
          cancelCallback={() => {
            !isOpenModal ? blocker?.reset?.() : onCloseModal();
          }}
          proceedCallback={() => {
            if (isOpenModal) {
              setValue('');
            }
            blocker?.proceed?.();
            navigateBack();
          }}
        />
      ) : null}
      <MemoizedForm
        isMobile={isMobile}
        form={form}
        attributes={attributes}
        participantTaskDetails={participantTaskDetails}
        resetForm={resetForm}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        handleNext={handleSave}
        handleCancel={handleCancel}
        handleChange={handleChange}
        // SING-675
        firstName={firstName}
        lastName={lastName}
        isChildToAdulthoodSurvey={isChildToAdulthoodSurvey}
        setIsChildToAdulthoodSurvey={setIsChildToAdulthoodSurvey}
        hasDuplicateChildEmailError={hasDuplicateChildEmailError}
        initialChildFormData={initialChildFormData}
      />
      {showTimeLimitPopup && (
        <TimeLimitPopup>
          <InfoIcon />
          <div>
            <Typography variant='pn3' color='secondary.gray4'>
              Task time limit approaching
            </Typography>
            <br />
            <Typography variant='pl5' color='primary.gray90'>
              Your survey will be submitted in 60 seconds
            </Typography>
          </div>
        </TimeLimitPopup>
      )}
    </>
  );
};

export default withPerson(withSelectedProfile(Survey));
