import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  configuration: {
    'study-arms': {},
    'study-arm-roles': {},
    'study-arm-sub-roles': {},
    'study-arm-statuses': {},
  },
  /**
   * Study attributes configuration
   */
  studyConfiguration: [],
  /**
   * Study arm role permission configuration
   */
  participantProfileConfig: {},
  showWarningModal: true,
  studyEnumerations: {},
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    /**
     * To set multiple values in single dispatch
     */
    setValues: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    },
    setStudyArmsConfiguration: (state, action) => {
      state.configuration = { ...action.payload };
    },
    updateShowWarningModal: (state, action) => {
      state.showWarningModal = action.payload;
    },
    setParticipantProfileConfig: (state, action) => {
      state.participantProfileConfig = { ...action.payload };
    },
    setStudyConfiguration: (state, action) => {
      state.studyConfiguration = action.payload;
    },
    setStudyEnumerations: (state, action) => {
      state.studyEnumerations = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setValues,
  setStudyArmsConfiguration,
  updateShowWarningModal,
  setParticipantProfileConfig,
  setStudyConfiguration,
  setStudyEnumerations,
} = configurationSlice.actions;

export default configurationSlice.reducer;
