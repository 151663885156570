import React from 'react';

import { ReactComponent as FollowupBadge } from 'images/profile/achievements/followup.svg';
import { ReactComponent as FollowupFilledBadge } from 'images/profile/achievements/followup-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';

import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
} from '../styles.mobile.js';

export const MobileScheduleFollowUpCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#E2F0F8' : '#ffffff'}>
      {!inProgress ? <FollowupBadge /> : <FollowupFilledBadge />}
      <MobileCardInfoBox>
        <Title>Schedule a Follow-up</Title>
        <StyledSlider
          value={100}
          isCompleted={isCompleted}
          backgroundColor='#50BAF5'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Dolor sit amet'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
