import { Accordion, AccordionSummary, styled } from "@mui/material";

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    background: theme.palette.primary.white,
    borderRadius: '8px !important',
    marginBottom: '10px',
    width: '100%',
    border: '1px solid',
    borderColor: theme.palette.primary.gray15,
    boxShadow: 'none',
    textAlign: 'left',
    '&.MuiAccordion-root:before': {
        content: 'none'
    }
}));

export const StyledAccordionSummary = styled(AccordionSummary)(({ theme, expanded }) => ({
    transition: 'none',
    borderBottom: expanded === 'true' ? 'solid 1px' : 'none',
    borderColor: theme.palette.primary.gray15,
    '& .Mui-expanded': {
        margin: '0px !important'
    },
    '& .MuiAccordionSummary-content': {
        transition: 'none'
    }
}));