import { Box, styled } from '@mui/material';

export const ContributorsWrapper = styled(Box)(() => ({
  maxWidth: '600px',
}));

export const Line = styled('hr')(({ theme }) => ({
  border: '0',
  borderTop: `1px solid ${theme.palette.primary.lightGray100}`,
  margin: '20px 0',
}));
