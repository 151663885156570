import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

export const Container = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.navy,
  color: theme.palette.primary.white,
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 'min(1440px, 80%)',
  margin: '0 auto',
  padding: '30px 0',

  [theme.breakpoints.between('md', 'lg')]: {
    maxWidth: '700px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));

export const PageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.white,
  textDecoration: 'none',
  fontSize: '12px',
  padding: '10px',
  margin: '-10px',
}));

export const Divider = styled(Box)(({ theme }) => ({
  background: '#AAB0B3',
  height: '1px',
  width: '100%',
  margin: '30px 0',
}));
