import { Stack, Typography } from '@mui/material';
// import { GetStarted } from 'pages/Welcome/GetStarted';
import { resourcesContent } from 'pages/Welcome/Resources/constants';
import { Card } from 'pages/Welcome/Resources/styles';
import { WelcomePageWrapper } from 'pages/Welcome/styles';
import { Link } from 'react-router-dom';

export function Resources() {
  const { pageTitle, subTitle, resources, readMore } = resourcesContent;
  document.title='Single Ventricle SOURCE';
  
  return (
    <>
      <WelcomePageWrapper>
        <Typography display='block' color='primary.navy' variant='h1'>
          {pageTitle}
        </Typography>

        <Typography mb='32px'>
          {subTitle}
        </Typography>

        <Card>
          {resources.map(({ alt, cardImage, title, description }, index) => (
            <Stack
              border='1px solid'
              borderColor='primary.darkGray20'
              borderRadius='8px'
              direction='column'
              flexBasis='345px'
              maxWidth='345px'
              overflow='hidden'
              width='100%'
            >
              <img alt={alt} height='160px' src={cardImage} width='100%' />

              <Stack padding='30px' spacing='16px'>
                <Typography
                  color='primary.navy'
                  height='72px'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  variant='h5'
                >
                  {title}
                </Typography>

                <Typography
                  display='-webkit-box'
                  color='primary.navy'
                  maxHeight='100px'
                  overflow='hidden'
                  textOverflow='ellipsis'
                >
                  {description}
                </Typography>

                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/welcome/resources/${index}`}
                >
                  <Typography
                    color='primary.green'
                    fontWeight={700}
                    sx={{ textDecoration: 'underline' }}
                  >
                    {readMore}
                  </Typography>
                </Link>
              </Stack>
            </Stack>
          ))}
        </Card>
      </WelcomePageWrapper>

      {/* <GetStarted /> */}
    </>
  );
}
