export const healthSteps = {
  id: '2',
  language: 'en-us',
  code: '<TBD>',
  taskType: 'survey',
  priority: 'routine',
  order: '2',
  title: 'Screener',
  description: '',
  //Trigger
  timingDuration: '<TBD>',
  daysForCompletition: '<TBD>',
  requiredBehavior: 'must',
  content: [
    {
      id: '2_0',
      type: 'question',
      questionTitle: 'Have you been diagnosed with Multiple sclerosis?',
      order: '0',
      questionType: 'selection',
      items: [
        {
          value: 'Yes',
          order: '0',
          id: '2_0_0',
          valid: true,
        },
        {
          value: 'No',
          order: '1',
          id: '2_0_1',
        },
      ],
    },
    {
      id: '2_1',
      type: 'question',
      questionTitle: 'Are you between ages of 21 and 32?',
      order: '1',
      questionType: 'selection',
      items: [
        {
          value: 'Yes',
          order: '0',
          id: '2_1_0',
          valid: true,
        },
        {
          value: 'No',
          order: '1',
          id: '2_1_1',
        },
      ],
    },
    {
      id: '2_2',
      type: 'question',
      questionTitle: 'Are you pregnant?',
      order: '2',
      questionType: 'selection',
      items: [
        {
          value: 'Yes',
          order: '0',
          id: '2_2_0',
          valid: true,
        },
        {
          value: 'No',
          order: '1',
          id: '2_2_1',
        },
      ],
    },
  ],
};
