import React from 'react';

import { ReactComponent as OnboardingBadge } from 'images/profile/achievements/onboarding.svg';
import { ReactComponent as OnboardingFilledBadge } from 'images/profile/achievements/onboarding-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobileOnboardingCompleteCard } from './index.mobile';

import { isMobile } from 'utils/isMobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  IsCompletedMark,
} from '../styles';

export const OnboardingCompleteCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobileOnboardingCompleteCard
        inProgress={inProgress}
        isCompleted={isCompleted}
      />
    );
  }

  return (
    <CardWrapper backgroundColor={isCompleted ? '#F3E7F0' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#9B51E0' />}
      {!inProgress ? <OnboardingBadge /> : <OnboardingFilledBadge />}
      <Info>
        <Title>Onboarding complete</Title>
        <StyledSlider
          value={0}
          isCompleted={isCompleted}
          backgroundColor='#9B51E0'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Enthusiast'
            isCompleted={isCompleted}
          />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
