import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';

export const medicationApiSlice = createApi({
  reducerPath: 'searchMedicationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://clinicaltables.nlm.nih.gov/',
  }),
  endpoints: (builder) => ({
    searchMedication: builder.query({
      query: (searchStr) =>
        `fhir/STU3/ValueSet/rxterms/$expand?_format=json&count=10&filter=${searchStr}`,
    }),
  }),
});

const initialState = {
  selectedMedication: null,
};

const setMedicationAction = (state, { payload }) => {
  state.selectedMedication = payload;
};

const resetMedicationAction = (state) => {
 state.selectedMedication = null;
};

export const medicationSlice = createSlice({
  name: 'medication',
  initialState,
  reducers: {
    setMedication: setMedicationAction,
    resetMedication: resetMedicationAction,
  },
});


export const { useSearchMedicationQuery } = medicationApiSlice;
export const { setMedication, resetMedication } = medicationSlice.actions;
