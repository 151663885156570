import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Chip from 'ihp-components/components/v2/Chip';
import { ReactComponent as Clock } from 'images/profile/my-consents/clock.svg';
import { ReactComponent as Tick } from 'images/profile/my-consents/tick.svg';
import {
  AlertIcon,
  CircleDot,
  ConsentInfo,
  TextWrapper,
  getStyles,
} from './styles';

export const Tags = ({
  isSigned,
  isMandatory,
  isArchived,
  isRejected,
  statusDate,
  showRemainingLabel,
  remaining,
  rendererPage,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  let status = null;

  if (isSigned) {
    status = 'SIGNED';
  }

  if (isRejected) {
    status = 'DECLINED';
  }

  if (isArchived) {
    status = 'ARCHIVED';
  }

  const tagList = [];

  if (showRemainingLabel && rendererPage !== 'onboarding-consents') {
    tagList.push(
      <Chip key={1} icon={<Clock />} variant='filled' type='primary'>
        {remaining}
      </Chip>
    );
  }

  if (
    status === 'SIGNED' ||
    status === 'DECLINED' ||
    (status && statusDate && rendererPage !== 'onboarding-consents')
  ) {
    tagList.push(
      <Chip
        key={2}
        icon={<Tick />}
        variant='outlined'
        type='info'
        // SING-780
        // sx={styles.declineButton}
      >
        {`${status} ${statusDate}`}
      </Chip>
    );
  }

  if (isMandatory === 'REQUIRED' && !isSigned) {
    if (isSmallDevice) {
      tagList.push(
        <TextWrapper>
          <ConsentInfo>
            Required <CircleDot /> Consent
          </ConsentInfo>
        </TextWrapper>
      );
    } else {
      tagList.push(
        <Chip
          key={4}
          icon={<AlertIcon />}
          variant='outlined'
          type='primary'
          sx={styles.chipButton}
        >
          REQUIRED
        </Chip>
      );
    }
  } else {
    // tagList.push(
    //   <Chip key={4} variant='outlined' type='primary' sx={styles.chipButton}>
    //     OPTIONAL
    //   </Chip>
    // );
  }

  if (isMandatory === 'REQUIRED') tagList.push();

  if (isArchived && rendererPage !== 'onboarding-consents')
    tagList.push(
      <Chip key={6} variant='outlined' type='info'>
        ARCHIVED
      </Chip>
    );

  if (!!tagList.length)
    return (
      <Stack
        direction='row'
        alignItems='center'
        spacing='15px'
        marginRight='auto'
      >
        {tagList}
      </Stack>
    );
  return null;
};
