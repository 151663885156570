import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useEditPersonAttributesMutation,
  useEditPersonMutation,
  useParticipantGoalMutation,
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { setOnboardingReward } from 'ihp-bloom-redux/features/user/userSlice';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

import { FormLabel } from 'components/Login';
import { Logo } from 'components/shared';
import { Loader } from 'components/Loader';
import { useValidatePhone } from '../hooks';
import { areAllFieldsEmpty, getEmptyFields } from '../utils';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { ReactComponent as PhoneIcon } from 'images/profile/alternativecontacts/phone.svg';
import { ReactComponent as PinIcon } from 'images/profile/alternativecontacts/pin.svg';
import { ReactComponent as TrashIcon } from 'images/profile/alternativecontacts/trash-bin.svg';
import { ReactComponent as PenIcon } from 'images/shared/pen-3.svg';
import { isMobile } from 'utils/isMobile';
import { USStates } from '../AlternativeContact/mocks/us-states';
import {
  ContactContainer,
  ContactsContainer,
  DeleteIcon,
  DoctorPhoneContainer,
  FlexedBox,
  FullNameContainer,
  NameHeaderContainer,
  PhoneNumberContainer,
  RelationContainer,
} from '../AlternativeContact/styles';
import DoctorsMobile from '../Doctors/index.mobile';
import { EditDoctorsModal } from '../EditModal';
import getStyles from '../styles/index.styles';
import { withPerson } from 'authentication/withPerson';

function Doctors({ person }) {
  document.title = 'Single Ventricle SOURCE Healthcare providers';
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [doctorId, setDoctorId] = useState(1);
  const [doctors, setDoctors] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [currentDoctor, setCurrentDoctor] = useState({
    provider_name: '',
    phone_number: '',
    city: '',
    state: '',
  });

  const skipValidation = doctors && doctors.length > 0;

  const [editPersonAttributes, { isLoading }] =
    useEditPersonAttributesMutation();
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const [triggerReward, { isLoading: isProcessingReward }] =
    useParticipantGoalMutation();

  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      provider_name: '',
      phone_number: '',
      city: '',
      state: '',
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        provider_name: '',
        phone_number: '',
        city: '',
        state: '',
      });
    }
  }, [doctorId, isSubmitSuccessful]);

  const setErrorsForFields = (emptyFields) => {
    emptyFields.forEach((field) => setError(field, 'This field is required!'));
  };

  const onAddDoctor = (data) => {
    const emptyFields = getEmptyFields(data);
    if (emptyFields.length !== 0) {
      setErrorsForFields(emptyFields);
      return;
    }
    setDoctors([...doctors, { id: doctorId, ...data }]);
    setCurrentDoctor({
      provider_name: '',
      phone_number: '',
      city: '',
      state: '',
    });
    setDoctorId(doctorId + 1);
    reset();
  };

  const onUpdateDoctor = (data) => {
    let updatedDoctors = doctors.map((doctor) => {
      if (doctor.id === data.id) {
        return data;
      }
      return doctor;
    });
    setDoctors(updatedDoctors);
  };

  const onSubmit = async (data) => {
    let newDoctors = [...doctors];
    const emptyFields = getEmptyFields(data);
    const allFieldsEmpty = areAllFieldsEmpty(data);

    if (allFieldsEmpty && newDoctors.length === 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length > 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length === 0) {
      newDoctors = [...newDoctors, data];
    }

    const personAttributesPayload = [
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'doctor',
            value: JSON.stringify(newDoctors),
          },
        },
      },
    ];

    const editPersonPayload = {
      data: {
        type: 'people',
        id: `${person?.id}`,
        attributes: {
          state: 'enrollment',
          status: 'onboarded',
          status_reason: 'onboarded',
        },
      },
    };
    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person?.id,
        payload: personAttributesPayload,
      });

      if (!personAttributesResponse.error) {
        const personResponse = await editPerson({
          personId: person?.id,
          payload: editPersonPayload,
        });
        const rewardResponse = await triggerReward({
          personId: person?.id,
          payload: {
            data: {
              process: 'onboarding',
            },
          },
        });
        if (!rewardResponse?.error && rewardResponse?.data) {
          dispatch(setOnboardingReward(rewardResponse.data?.data || []));
        }
        if (!personResponse.error) {
          navigate('/onboarding/create-profile');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  if (isMobile()) {
    return <DoctorsMobile person={person} />;
  }

  const handleDelete = (id) => {
    const newProvider = doctors.filter((c) => c.id !== id);
    setDoctors(newProvider);
  };

  const handleEdit = (id) => {
    const selectedProvider = doctors.filter((c) => c.id === id)[0];
    setSelectedProvider(selectedProvider);
    return setIsOpenModal(true);
  };

  return (
    <Grid container sx={{ ...styles.container, margin: '0 auto' }}>
      <Box sx={styles.formContainer}>
        <Box sx={styles.formHeader}>
          <Logo />
          <FormLabel fontSize='24px' marginTop='15px'>
            Provide details of your healthcare provider
          </FormLabel>
          <Typography
            color='primary.gray75'
            fontSize='14px'
            textAlign='center'
            width='295px'
          >
            As part of the study, we will need to access your medical records
          </Typography>
        </Box>
        <ContactsContainer>
          <ul>
            {doctors.map((doctor, index) => {
              return (
                <ContactContainer key={`${doctor.provider_name}-${index}`}>
                  <Stack>
                    <NameHeaderContainer>
                      <FullNameContainer>
                        {doctor.provider_name}
                      </FullNameContainer>
                      <FlexedBox>
                        <Box
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleEdit(doctor.id)}
                        >
                          <PenIcon width='14px' height='14px' />
                        </Box>
                        <DeleteIcon onClick={() => handleDelete(doctor.id)}>
                          <TrashIcon width='14px' height='14px' />
                        </DeleteIcon>
                      </FlexedBox>
                    </NameHeaderContainer>
                    <FlexedBox>
                      <PinIcon width='14px' height='14px' />
                      <RelationContainer>
                        {doctor.city}, {doctor.state}
                      </RelationContainer>
                    </FlexedBox>
                    <PhoneNumberContainer>
                      <Box>
                        <PhoneIcon width='14px' height='14px' color='#606B71' />
                      </Box>
                      <DoctorPhoneContainer>
                        {doctor.phone_number}
                      </DoctorPhoneContainer>
                    </PhoneNumberContainer>
                  </Stack>
                </ContactContainer>
              );
            })}
          </ul>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='provider_name'
                control={control}
                rules={!skipValidation && { required: false }}
                haserrors={errors.provider_name}
                render={({ field }) => (
                  <Input
                    label="Healthcare provider's name"
                    name='provider_name'
                    error={errors.provider_name}
                    errorLabel={errors?.provider_name?.message}
                    value={currentDoctor.provider_name}
                    fullWidth={true}
                    placeholder='Enter healthcare provider’s name'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phone_number'
                control={control}
                rules={
                  !skipValidation && {
                    validate: validatePhone,
                    required: false,
                  }
                }
                render={({ field }) => (
                  <MaskedInput
                    placeholder='(___)___-___'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        label='Phone number'
                        name='phone_number'
                        error={errors.phone_number}
                        errorLabel={
                          errors?.phone_number?.message ||
                          'This field is required'
                        }
                        fullWidth
                        sx={styles.input}
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='city'
                control={control}
                rules={!skipValidation && { required: false }}
                haserrors={errors.city}
                render={({ field }) => (
                  <Input
                    error={errors.city}
                    errorLabel={errors.city?.message}
                    label='City'
                    name='citu'
                    value={currentDoctor.city}
                    fullWidth={true}
                    placeholder='Enter city'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='state'
                control={control}
                rules={!skipValidation && { required: false }}
                haserrors={errors.state}
                render={({ field }) => (
                  <Input
                    error={errors.state}
                    errorLabel={errors.state?.message}
                    type='select'
                    label='Select state'
                    fieldLabel='State'
                    placeholder='Select state'
                    fullWidth
                    sx={styles.selectInput}
                    {...field}
                  >
                    {USStates?.map(({ name, abbreviation }) => (
                      <MenuItem key={abbreviation} value={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Button
                fullWidth
                onClick={handleSubmit(onSubmit)}
                loading={isLoading || isEditingPerson || isProcessingReward}
              >
                Continue
              </Button>
            </Box>
            <Box sx={styles.fieldContainer}>
              <Button
                variant='outlined'
                text='Add new doctor'
                fullWidth
                onClick={handleSubmit(onAddDoctor)}
                startIcon={<PlusIcon width={14} height={14} />}
              >
                Add new doctor
              </Button>
            </Box>
          </form>
        </ContactsContainer>
      </Box>
      {selectedProvider != null && (
        <EditDoctorsModal
          open={isOpenModal}
          onClose={() => {
            setSelectedProvider(null);
            return setIsOpenModal(false);
          }}
          provider={{
            id: selectedProvider.id,
            provider_name: selectedProvider.provider_name,
            phone_number: selectedProvider.phone_number,
            city: selectedProvider.city,
            state: selectedProvider.state,
          }}
          onUpdateProvider={onUpdateDoctor}
        />
      )}
    </Grid>
  );
}
export default withPerson(Doctors);
