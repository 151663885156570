import { produce } from 'immer';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { updatePersonAttributePayload } from './payloads';
import { useConfiguration } from './useConfiguration';
import {
  useCreateNewAccountProfile,
  useCreatePersonAndUpdateStudyArm,
  useCreatePersonStudyArm,
  useUpdateAccountProfile,
} from './utils';
import { DEPENDENT_MAPPING, STUDY_ARM_STATUS_NAMES } from 'constants/global';

// Helper function to find study arm status ID
const findStudyArmStatusId = (studyArmStatuses, studyArmId) => {
  return studyArmStatuses.find(
    (status) =>
      status.title === STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-SELECTED'] &&
      status.study_arm_id === studyArmId
  )?.id;
};

// Helper function to find study arm role ID
const findStudyArmRoleId = (studyArmRoles, studyArmRoleCode) => {
  return studyArmRoles.find((sar) => sar.code === studyArmRoleCode)?.id;
};

const getDependentStudyArmRoleCode = (selectedRole) => {
  return DEPENDENT_MAPPING[selectedRole];
};

export const useEnrollLar = () => {
  const [createPersonStudyArm] = useCreatePersonStudyArm();
  const [updateAccountProfile] = useUpdateAccountProfile();
  const [createAccountProfile] = useCreateNewAccountProfile();
  const [lazyFetchAccountProfile] =
    accountProfileApiSlice.endpoints.getAccountProfile.useLazyQuery();
  const [editPersonAttributes] = useEditPersonAttributesMutation();
  const [createPersonAndUpdateStudyArm] = useCreatePersonAndUpdateStudyArm();

  const dispatch = useDispatch();

  const { studyArmStatuses, studyArmRoles, getResearchStudyArm } =
    useConfiguration();

  const { person, isEnrollingNewPerson } = useSelector((s) => s.user);

  const enrollParticipant = async ({
    user,
    studyArmRoleCode,
    subjectPersonAttributes,
  }) => {
    const { activeAccountProfile } = user;
    const {
      //   study_arm_id: studyArmId,
      account_person_study_arm_id: accountPersonStudyArmId,
    } = activeAccountProfile.attributes.profile;

    const studyArmResearch = getResearchStudyArm();
    const studyArmId = studyArmResearch.id;
    console.log(studyArmStatuses, studyArmRoles);

    const roleSelectedStatusId = findStudyArmStatusId(
      studyArmStatuses,
      studyArmId
    );
    const selectedStudyArmRoleId = findStudyArmRoleId(
      studyArmRoles,
      studyArmRoleCode
    );

    console.log({
      activeAccountProfile,
      studyArmId,
      studyArmResearch,
      studyArmRoleCode,
      roleSelectedStatusId,
      selectedStudyArmRoleId,
      studyArmRoles,
    });

    if (!roleSelectedStatusId || !selectedStudyArmRoleId) {
      throw new Error(
        'Unable to find study arm status ID or study arm role ID'
      );
    }

    const dependentStudyArmRoleCode =
      getDependentStudyArmRoleCode(studyArmRoleCode);

    console.log(studyArmRoleCode);
    console.log(dependentStudyArmRoleCode, DEPENDENT_MAPPING);
    const dependentPersonStudyArmRoleId = studyArmRoles.find(
      (s) => s.code.toLowerCase() === dependentStudyArmRoleCode.toLowerCase()
    ).id;

    const studyArmStatusEligibleId = studyArmStatuses.find(
      (status) =>
        status.title.toLowerCase() ===
          STUDY_ARM_STATUS_NAMES['ENROLLMENT-ELIGIBLE'].toLocaleLowerCase() &&
        status.study_arm_id === studyArmId
    ).id;

    try {
      let researchPersonStudyArmId = accountPersonStudyArmId;
      if (isEnrollingNewPerson) {
        const createPersonStudyArmResult = await createPersonStudyArm({
          personId: person.id,
          studyArmId: studyArmResearch.id,
          studyArmRoleId: selectedStudyArmRoleId,
          studyArmStatusId: roleSelectedStatusId,
        });
        researchPersonStudyArmId = createPersonStudyArmResult.data?.data?.id;
      }

      // Create a new person record
      const representeePersonId = await createPersonAndUpdateStudyArm({
        personStudyArmId: researchPersonStudyArmId,
        studyArmRoleId: selectedStudyArmRoleId,
        studyArmStatusId: roleSelectedStatusId,
      });
      const representeePersonStudyArm = await createPersonStudyArm({
        personId: representeePersonId,
        studyArmId: studyArmResearch.id,
        studyArmRoleId: dependentPersonStudyArmRoleId,
        studyArmStatusId: studyArmStatusEligibleId,
      });

      const personAttributesResponse = await editPersonAttributes({
        personId: representeePersonId,
        payload: updatePersonAttributePayload(subjectPersonAttributes),
      }).unwrap();
      let updatedProfile;
      let updatedUser;

      if (isEnrollingNewPerson) {
        const createAccountProfileResponse = await createAccountProfile({
          accountPersonStudyArmId: researchPersonStudyArmId,
          subjectPrimaryPersonStudyArmId:
            representeePersonStudyArm?.data?.data?.id,
        });

        updatedProfile = await lazyFetchAccountProfile(
          createAccountProfileResponse.data.id
        ).unwrap();

        updatedUser = produce(user, (draft) => {
          draft.activeAccountProfile = updatedProfile.data;
        });
      } else {
        const updateAccountProfileResponse = await updateAccountProfile({
          accountProfileId: activeAccountProfile?.id,
          subjectPrimaryPersonStudyArmId:
            representeePersonStudyArm?.data?.data?.id,
        });

        updatedProfile = await lazyFetchAccountProfile(
          activeAccountProfile.id
        ).unwrap();

        updatedUser = produce(user, (draft) => {
          draft.activeAccountProfile = updatedProfile.data;
        });
      }

      dispatch(setValues(updatedUser));
      return updatedUser;
    } catch (err) {
      console.error(err);
      throw err; // Propagate the error so it can be handled by the caller
    }
  };

  return [enrollParticipant];
};
