import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { CancelButton } from 'configUI/pages/StaticPages/index.styles';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';
import { ReactComponent as BellIcon } from 'images/activity/types/bell.svg';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock-filled.svg';
import { ReactComponent as ClipboardMedicalIcon } from 'images/activity/types/clipboard-medical.svg';

import {
  ButtonRow,
  StyledArrowRIght,
  StyledInstructions,
  Wrapper,
  MetaData,
  TaskType,
  EstimatedTime,
  AllowedTime,
  Reward,
  Header,
  Content,
  HeaderWrapper,
} from './styles';
import Button from 'ihp-components/components/v2/Button';
import { isMobile } from 'utils/isMobile';

const ActivityInstructions = ({
  title,
  type,
  estimatedTime,
  timeLimit,
  description,
  participantInfo,
  points,
  onClickContinue,
  onCancel,
  startButtonText,
  video,
  hideInstructionsTitle,
  hideMetaData,
  ...props
}) => {
  return (
    <Wrapper>
      <StyledInstructions {...props}>
        <HeaderWrapper sx={{ borderBottom: 'none' }}>
          <Header>INSTRUCTIONS</Header>

          <Typography my={1} variant='h3' color='primary.darkGray100' mt={0}>
            {title}
          </Typography>
          {isMobile() && (
            <Typography variant='pl5' color='primary.darkGray70'>
              {participantInfo}
            </Typography>
          )}
        </HeaderWrapper>
        <Content>
          {!hideMetaData && (
            <MetaData>
              <Stack direction='column' spacing='7px' flex={1}>
                <Typography variant='pl5' color='primary.darkGray70'>
                  Task type
                </Typography>
                <TaskType>
                  <ClipboardMedicalIcon />
                  <span>{type}</span>
                </TaskType>
              </Stack>
              <Stack direction='column' spacing='7px' flex={1}>
                <Typography variant='pl5' color='primary.darkGray70'>
                  Estimated time
                </Typography>
                <EstimatedTime>
                  <ClockIcon />
                  <span>{estimatedTime || 'N/A'}</span>
                </EstimatedTime>
              </Stack>
              {timeLimit && (
                <Stack direction='column' spacing='7px' flex={1}>
                  <Typography variant='pl5' color='primary.darkGray70'>
                    Time limit
                  </Typography>
                  <AllowedTime>
                    <BellIcon />
                    <span>{timeLimit}</span>
                  </AllowedTime>
                </Stack>
              )}
              <Stack direction='column' spacing='7px' flex={1}>
                <Typography variant='pl5' color='primary.darkGray70'>
                  {participantInfo}
                </Typography>
              </Stack>
            </MetaData>
          )}

          <Typography variant='pn3' color='primary.darkGray70'>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </Typography>
          {video}
          <ButtonRow>
            <CancelButton variant='link' onClick={onCancel}>
              Cancel
            </CancelButton>
            <Button onClick={onClickContinue}>
              {startButtonText} <StyledArrowRIght />
            </Button>
          </ButtonRow>
        </Content>
      </StyledInstructions>
    </Wrapper>
  );
};

ActivityInstructions.defaultProps = {
  startButtonText: 'Continue',
  onClickContinue: () => {},
  onCancel: () => {},
};

ActivityInstructions.propTypes = {
  hideInstructionsTitle: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.node,
  estimatedTime: PropTypes.string,
  timeLimit: PropTypes.string,
  points: PropTypes.number,
  startButtonText: PropTypes.string,
  onClickContinue: PropTypes.func,
  onCancel: PropTypes.func,
  hideMetaData: PropTypes.bool,
};

export default ActivityInstructions;
