import { Component } from 'react';

class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    this.props.onError(error.message);
  }

  render() {
    if (this.state.hasError) return 'Error';
    return this.props.children;
  }
}

export default ErrorBoundary;
