import { useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { authenticate } from 'ihp-bloom-redux/features/authentication/authenticationSlice';
import { Loader } from '../components/Loader';
import { getAuth0LoginPageConfig } from 'utils/auth0';

const ProtectedRoute = ({ component, ...args }) => {
  const dispatch = useDispatch();

  const {
    getAccessTokenSilently,
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const openLoginPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig());
  };

  useEffect(() => {
    /* This statement is required to avoid infinite loop */
    if (!isAuthenticated) return;
    const doAuthenticate = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(authenticate(accessToken)); // this needs to be checked or removed...
      } catch (error) {
        if (error.error === 'login_required') {
          openLoginPage();
        }
        console.log(error);
      }
    };
    doAuthenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated && user?.email_verified) {
    window.localStorage.removeItem('refreshed');
  }
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export default ProtectedRoute;
