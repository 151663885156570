import adnBannerImage from 'pages/Welcome/Resources/images/1-banner.png';
import adnCardImage from 'pages/Welcome/Resources/images/1-card.png';
import familyBannerImage from 'pages/Welcome/Resources/images/2-banner.png';
import familyCardImage from 'pages/Welcome/Resources/images/2-card.png';
import heartContentImage from 'pages/Welcome/Resources/images/2-content.png';
import { Link } from 'react-router-dom';
import VideoPlayer from 'components/VideoPlayer';
import WelcomeImage from 'images/onboarding/welcome.jpg';
import VideoThumbnail from 'images/welcome/Video_thumbnail.jpg'
import { getAssetDomain } from 'config/API';

const videoBaseUrl = getAssetDomain();

const videoElement = (
  <VideoPlayer
    src={`${videoBaseUrl}/av-svs/DrNewburger_Causes.mp4`}
    height={'auto'}
    width={'100%'}
    poster={`${WelcomeImage}`}
    controls
    containerStyle={{
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      marginTop: '20px',
    }}
  />
);

const videoElement2 = (
  <VideoPlayer
    src={`${videoBaseUrl}/av-svs/FINAL1428_AdditionalVentures_ProjectSource_DrSrivastavaWIP_v03_SC.mp4`}
    height={'auto'}
    width={'100%'}
    poster={`${VideoThumbnail}`}
    controls
    containerStyle={{
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      marginTop: '20px',
    }}
  />
);

export const resourcesContent = {
  pageTitle: 'Resources',
  subTitle: 'Discover a selection of resources and links tailored for you.',
  readMore: 'Read more',
  resources: [
  {
    alt: 'adn',
    bannerImage: adnBannerImage,
    cardImage: adnCardImage,
    title:
      'What do we know about the cause of single ventricle and why is it important?',
    description:
      'We don’t fully know why some people are born with single ventricle heart defects – and that’s why we are launching Single Ventricle SOURCE...',
    content: (
      <>
        We don’t fully know why some people are born with single ventricle heart
        defects – and that’s why we are launching{' '}
        <a href='http://www.svsource.org' rel='noreferrer' target='_blank'>
          Single Ventricle SOURCE
        </a>{' '}
        , the world’s largest genetic database of single ventricle patients and
        their families.
        <br />
        <br />
        Here’s what we know:
        <br />
        <br />
        <ul>
          <li>
            <b>There is more than one cause.</b> Single ventricle defects
            include many types of heart problems, affecting both the left and
            right sides of the heart, valves, and other cardiovascular
            structures. Because these develop and function differently, the
            causes of each unique defect are likely to be different.
          </li>
          <li>
            <b>Genetics play a role.</b> Scientists have already found several
            genes that are linked to single ventricle heart defects, but we
            don’t have a clear picture of what role they play in the development
            or severity of the disease.
          </li>
          <li>
            <b>Genetics may not tell the whole story.</b> Sometimes, a genetic
            difference or environmental factor on its own may not result in a
            problem. It is likely that some single ventricle heart defects come
            from a mix of genetic and environmental factors. Environmental
            factors could include things like nutritional deficiencies,
            geography, pollutants, maternal fever, drug exposure, and paternal
            age.
          </li>
        </ul>
        {videoElement}
        <br />
        <br />
        Through SOURCE, participants can share their genetic data (through a
        cheek swab) and medical histories to help researchers across the world
        make new discoveries about the causes of single ventricle. This is
        extremely important to single ventricle patient care and outcomes.&nbsp;
        <b>
          Understanding causes will help pave the way so we can prevent single
          ventricle heart defects from occurring and develop treatments to
          improve patient’s lives.
        </b>
        <ul>
          <li>
            <b>Prevent.</b> By finding out which genes or environmental factors
            cause single ventricle heart defects, we may be able to develop new
            tools or treatments to stop the defect from forming or reduce its
            severity. We also may be able to predict which families are at risk,
            helping doctors give better advice about future pregnancies.
          </li>
          <li>
            <b>Treat.</b> Knowing the causes will help develop and personalize
            treatments for each patient with their unique background and defect.
            This can also help identify who is more likely to develop other
            health problems like liver disease or plastic bronchitis, which can
            lead to improved care and treatment options.
          </li>
        </ul>
        <br />
        <br />
        There isn’t a simple solution for single ventricle. But understanding
        why these heart defects happen and how the disease progresses over time
        will help create better treatments and understand what treatments work
        best at each stage.
        <br />
        <br />
        It&#39;s hard to say exactly how long it will take to find a real cure
        for single ventricle – which might look like preventing the defect from
        happening or creating new care options. Finding a cure usually takes a
        lot of trial and error and may take many years, even decades. In the
        meantime, researchers are working hard to find treatments that make life
        better for patients now as they work towards a cure, and by joining
        studies like SOURCE, you can help.
        <br />
        <br />
        With programs like SOURCE, we hope to create a future with personalized
        treatment plans that prevent or reduce complications, manage symptoms
        better, and improve quality of life. By participating in research
        studies, single ventricle patients and their families can give
        scientists the data they need to make the next big discovery.
        <br />
        <br />
        Find out how you can{' '}
        <a href='http://www.svsource.org' rel='noreferrer' target='_blank'>
          Be the SOURCE
        </a>{' '}
        of new discoveries in single ventricle heart defect research.
        <br />
        <br />
        Looking for more information?{' '}
        <Link to='/welcome/resources/1'>
          Learn what it will take to cure single ventricle.
        </Link>
      </>
    ),
  },
  {
    alt: 'family',
    bannerImage: familyBannerImage,
    cardImage: familyCardImage,
    title: 'What would it mean to cure single ventricle heart defects?',
    description:
      'Thanks to major advances in surgery, most people born with single ventricle heart defects now survive into adulthood. However, they often face...',
    content: (
      <>
        Thanks to major advances in surgery, most people born with single
        ventricle heart defects now survive into adulthood. However, they often
        face serious complications later in life, such as heart failure and
        liver disease. Currently, there are no treatments that can prevent these
        complications or enable patients to have a normal quality and length of
        their lives.
        <br />
        <br />
        {videoElement2}
        <br />
        <br />
        <b>What will it take to find a cure?</b>
        <br />
        <br />
        A cure for single ventricle heart defects, whether it&#39;s a
        medication, a device, or a combination of both, would allow patients to
        live normal, healthy lives. Right now, there are thousands of
        researchers studying single ventricle heart defects. They each focus on
        different aspects, like why single ventricle heart defects happen and
        why some patients develop certain complications, to better inform
        current care and future treatments. To succeed, we need more research,
        more funding, a lot of patient data (both genetic and clinical), and a
        strong commitment to work together and share our discoveries along the
        way.
        <br />
        <br />
        <b>
          What questions are single ventricle researchers trying to answer to
          find a cure?
        </b>
        <br />
        <br />
        <img
          alt='A heart puzzle with four pieces labeled understand single ventricle causes, understand complications and outcomes, predict and prevent health issues, and develop real cures.'
          src={heartContentImage}
          width='100%'
        />
        <br />
        There is still a lot we don't know about single ventricle, but with
        every new research study that is funded, every journal article that is
        published, and every new tool that is developed, we are one step closer
        to making better treatments available for patients and ultimately
        finding a cure.
        <br />
        <br />
        <b>Where does SOURCE fit in? </b>
        <br />
        <br />
        Single Ventricle SOURCE is a project designed to answer what causes
        single ventricle heart defects and how your unique genetics may increase
        the risk of certain complications. To help answer these questions,
        SOURCE is creating the world’s largest database of single ventricle
        patients and their families. This database includes genetic and medical
        information that can be shared with researchers in a safe, protected way
        that doesn’t identify the participant. That way, the data provided by
        patients and families will be used in many different research studies
        around the world, leading to more discoveries.
        <br />
        <br />
        While SOURCE aims to learn more about what causes single ventricle heart
        defects, this information will also lead to more effective,
        longer-lasting, personalized solutions that improve quality of life for
        patients. This knowledge could be essential to finding cures for single
        ventricle.
        <br />
        <br />
        <b>Ready to get involved?</b> Find out how you can{' '}
        <a href='http://www.svsource.org' rel='noreferrer' target='_blank'>
          Be the SOURCE
        </a>{' '}
        of new discoveries in single ventricle heart defect research.
        <br />
        <br />
        Looking for more information? Learn more about{' '}
        <Link to='/welcome/resources/0'>
          what causes single ventricle and why is it important.
        </Link>
      </>
    ),
  },
  ]
};
