import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Header } from './Header';
import { StyledBaseLayout, StyledProgressButtons } from './styles';
import { ReactComponent as ArrowRightIcon } from 'images/shared/arrow-right-2.svg';
import { ReactComponent as CheckIcon } from 'images/shared/check-2.svg';

export const StepperContainer = ({
  header,
  steps,
  progressButtonsLabels: {
    start = 'Start',
    next = 'Next',
    finish = 'Finish',
    done = 'Done',
    close = 'Close',
    back = 'Back',
    onFinish,
    onSkip, // If we want to do some action on click back on first step
  } = {},
  hideBack = false,
  hideProgressBar = false,
  ...rest
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;

  /*
   * When we have just the first, last and a single form screen,
   * we don't show progress bar. We, also, never show for the
   * first screen.
   */
  const showProgressBar = !isLastStep && !hideProgressBar;

  /*
   * If this became a performance issue, we can use memo.
   */
  const stepsLength = steps.length - 1;

  const forward = () => {
    if (isLastStep) navigate('/');
    else {
      if (currentStep === stepsLength - 1 && typeof onFinish === 'function')
        onFinish();
      setCurrentStep(currentStep + 1);
    }
  };

  const backward = () => {
    if (isFirstStep && !onSkip) {
      navigate('/');
    } else if (isFirstStep && onSkip) {
      onSkip();
    } else setCurrentStep(currentStep - 1);
  };

  const getButtonLabel = () => {
    if (isLastStep) {
      return done;
    }
    if (isFirstStep) {
      return start;
    }
    // This is for last step before celebration screen so in order this would be second last screen
    if (currentStep === stepsLength - 1) {
      return finish;
    }
    return next;
  };

  const getStartIcon = () => {
    if (currentStep === stepsLength - 1) {
      return <CheckIcon />;
    }
    return null;
  };

  const getEndIcon = () => {
    if (currentStep < stepsLength - 1) {
      return <ArrowRightIcon />;
    }
    return null;
  };

  const linkLabel = isFirstStep ? close : back;

  return (
    <StyledBaseLayout
      lastStep={isLastStep}
      header={
        <Header
          subHeader={header}
          currentStep={currentStep}
          stepsLength={stepsLength}
          showProgressBar={showProgressBar}
        />
      }
      content={
        steps instanceof Function
          ? steps(currentStep)
          : Array.isArray(steps)
          ? steps[currentStep]
          : steps
      }
      footer={
        <StyledProgressButtons
          isLastStep={isLastStep}
          buttonProps={{
            startIcon: getStartIcon(),
            endIcon: getEndIcon(),
            label: getButtonLabel(),
            onClick: forward,
          }}
          linkProps={{
            label: linkLabel,
            disabled: isLastStep,
            onClick: backward,
            hide: currentStep === stepsLength,
          }}
        />
      }
      {...rest}
    />
  );
};
