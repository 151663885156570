import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useAcceptFamilyInvitationMutation } from 'ihp-bloom-redux/features/family/familyApiSlice';

export function useAcceptFamilyInvitation() {
  const { person } = useSelector((state) => state.user);

  const [acceptFamilyInvitation, mutation] =
    useAcceptFamilyInvitationMutation();

  function submit({ familyInvitationId }) {
    acceptFamilyInvitation({
      familyInvitationId,
      personId: person.id,
    });
  }

  return {
    mutation,
    submit,
  };
}
