import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const documentTypeApiSlice = createApi({
  reducerPath: 'documentTypeApiV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['DocumentTypes'],
  endpoints: (build) => ({
    getDocumentTypes: build.query({
      query: () => ({
        url: `/document-types`,
        method: 'GET',
      }),
      providesTags: ['DocumentTypes'],
    }),
  }),
});

export const { useGetDocumentTypesQuery } = documentTypeApiSlice;
