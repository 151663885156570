export const data = [
  {
    id: 0,
    section: '',
    body: {
      __html: `Study Name: SOURCE <br/>
      Sponsor Consent Version Number (Study/Country/Site): &lt;V# DATE> <b>/ /</b> <br/>
      [Institution] Study Number: <br/>
      Sponsor Study Number: <br/>
      Name of Company Sponsoring the Study: SV Source <br/>
      Name of Principal Investigator (Study Doctor): <br/> <br/> 
      <b>Site Contact Information: </b> <br/>
      Contact Person:  <br/>
      Address:  <br/>
      Phone Number (Normal Business Hours):  <br/>
      Phone Number (Off-Hours): <br/> 
      [Complete the following entries for the site-level ICD as appropriate.] <br/><br/>  
      <b>[Institutional Review Board or Independent Ethics Committee] Contact Information: </b><br/> 
      Contact Person: <br/> 
      Address: <br/> 
      Phone Number: <br/> <br/> 
      <b>Patient Rights Advocate:</b> <br/> 
      Contact Person: <b>CONSENT TO TAKE PART IN STUDY</b> <br/> 
      Informed Consent Document Template Sponsor Consent Version Number (Study) : VN.N &lt;Date><br/> 
      Protocol No.: <br/>
      Address: <br/>
      Phone Number: <br/><br/>
      <b>Bioethicist:</b> <br/>
      Contact Person: <br/>
      Address: <br/>
      Phone Number: <br/><br/><br/>
      `,
    },
  },
  {
    id: 1,
    section: '1. WELCOME (letter/intro page)',
    body: {
      __html: `Thank you for taking the time to consider joining this study. This consent document can help you make your decision by explaining what you can expect to happen during this study, also known as a research study. <br/>
      Your participation in this study is completely voluntary (your choice). Take as long as you need to make your decision. You also can choose to take part in the study now, and then change your mind later at any time.<br/>
      We encourage you to have conversations with your family, caregivers, doctors, and study team about taking part in this study and whether it is right for you. The study team will work with you to answer any questions that you may have about the study. The study team includes the study doctor, nurses, and others who work with the study doctor.<br/>
      If you choose to participate in this study, you will be asked to sign this consent document prior to the study to let the study team know your decision.<br/>
      You will receive a signed copy of this consent document for your records. Please keep this consent document for your reference.<br/>
      We appreciate that you are thinking of taking part in this study.<br/><br/>
      `,
    },
  },
  {
    id: 2,
    section: '2. Brief Summary of this Study',
    body: {
      __html: `<i>ICD required elements to be covered in this summary: (1) this is a research study; (2) SV Source is compensating the investigator; (3) participation is voluntary; (4) there is time to decide and ask questions; (5) a copy of the signed ICD is given to the participant prior to participation; and (6) a concise, focused presentation of key information that is most likely to assist a prospective participant or legally authorized representative in understanding the reasons why the participant may or may not want to take part in the study. </i><br/>
      You are being asked to take part in a study that is sponsored by SV Source (the <b>“Sponsor”</b>). The Sponsor is providing funding to [the study doctor/institution] to conduct the study. 
      You are being asked to take part in this study because you have been diagnosed with MS, are pregnant, and are being treated with Alduretin as part of your regular care agreed with your doctor. This study will explore how safe and effective Alduretin treatments are in the long term. This study is “non-interventional” because it collects information only. Your doctor will manage your care no differently than if you were not taking part in this study. <br/>
      You will be asked to take part in the study for the duration of your treatment for MS, as long as the study is active. The end of the study is currently planned for May 2033.  <br/>
      This study will involve collecting information about your medical treatment and health status when you visit your doctor, a pregnancy symptoms diary, and completion of three questionnaires during the course of the study. You will also be asked to provide information about your child’s birth, and to provide their pediatric medical records every six months during the course of the study. <br/>
      This study does not involve any change in your current treatment and is for research purposes only, and is being done to better understand Alduretin treatments. There is no direct benefit to you from taking part, but information learned from the study may help other people in the future.  <br/>
      Taking part in this study is voluntary (your choice). There is no penalty or change to your regular medical care if you decide not to participate. You can choose to take part in the study now, and then change your mind later at any time without losing any benefits or medical care to which you are entitled. We encourage you to have conversations with your family, caregivers, doctors, and the study team about taking part in this study and whether it is right for you. The study team will work with you to answer any questions that you may have about the study.  <br/>
      You will receive a signed copy of this consent document for your records. Please keep this consent document for your reference.  <br/><br/>

      <b>3. What is the purpose of this study? </b> <br/>
      <i>ICD required element to be covered in this section: The purpose of the study, including relevant background information and (as applicable) a description of the drug or disease/condition under study.</i> <br/>
      You are being asked to take part in this study because you have been prescribed Alduretin treatment as part of your regular care agreed with your doctor prior to the start of your pregnancy. The purpose of this study is to learn more about the safety and effectiveness of multiple sclerosis treatments in routine practice. For this reason, the Sponsor is conducting this study to collect additional information about the good or bad effects of multiple sclerosis treatment including but not limited to Alduretin which is a treatment from the Sponsor of the study, SV Source. Any treatment you may take follows your normal routine and has been prescribed to you by your doctor. This study will not alter or interfere with the normal care you receive in any way.  <br/><br/>
      
      <b>4. How long will I participate in this study?  </b> <br/>
      <i>ICD required element to be covered in this section: duration of individual’s participation in the study, including any follow-up. </i><br/>
      You will be in this study as long as the study is active, unless you decide to withdraw your consent earlier. You will visit your care provider site as per routine clinical practice agreed with your doctor during the study. You may change providers without affecting your participation in the study.<br/><br/>

      <b>5. How many people will take part in this study? </b> <br/>
      <i>ICD required element to be covered in this section: the approximate number of study participants. </i><br/>
      There will be about 200 people taking part in this study. The study is being done in the general population of patients in the United States of America. <br/><br/>

      <b>6. What will happen during this study? </b> <br/>
      <i>ICD required elements to be covered in this section: a description of study visits and information that will be collected at each visit. </i><br/>
      Before any study activities begin, you will be asked to read and sign this consent document. 
      No additional visits are required as part of this study and information will be collected during your routine care visits scheduled with your doctor.  <br/>
      During the first televisit, the following information will be collected: <br/>
      <ul>
        <li>Demographic data, including age, gender, race/ethnicity (US only) and country of residence </li>
        <li>Medical history and ongoing medical conditions </li>
        <li>Relevant family history </li>
        <li>Diagnosis that needs Alduretin treatment </li>
      </ul>
      The following information will be collected through providing your medical records from your specialist and any other routine care visits: 
      <ul>
        <li>Information about other medications you are taking or you have taken </li>
        <li>Information related to your pregnancy, birth, and postpartum recovery </li>
        <li>Information about your baby’s health and development, including length, weight, head circumference, developmental milestones, and other measures if available  </li>
        <li>Information about the Alduretin treatment you have taken, current care plan, and plan for future management </li>
        <li>Measures related to your general health status and cognitive function </li> 
      </ul>
      At any point during the study, the study team will collect information about any adverse event you might experience. <br/><br/>

      You will be asked to complete a diary entry weekly for your pregnancy symptoms. This diary detail is yours to keep at the end of your participation in the study.<br/><br/>

      You will be asked to complete one questionnaire about your child’s birth. <br/><br/>

      You will be asked to complete a short series of questionnaires every few months during the course of the study:
      <ul>
        <li>one questionnaire about your quality of life, with questions related to how you feel about yourself, your body, your strengths and difficulties in everyday life, as well as your work and activity related to MS</li>
        <li>one questionnaire about your MS symptoms</li>
        <li>one questionnaire about your health care utilization</li>
      </ul>
      All questionnaires will be completed by survey in the study application on your phone or through a web page online. Surveys will occur at 3 month intervals throughout the course of the study.  <br/>
      You will be sent electronic reminders via phone notification, SMS, or email to complete the questionnaires at pre-defined times.  <br/>
      It will take approximately 20 minutes to complete each of the questionnaires. <br/><br/>

      <b>7. What are the possible risks and discomforts of this study? </b><br/>
      <i>ICD required elements to be covered in this section: any reasonably foreseeable risks or discomforts to the participant.</i><br/>
      This study is non-interventional and does not include ongoing treatments with Alduretin. 
      There may be non-physical risks associated with taking part in this study, such as the risk of accidental disclosure of your personal data (including your medical information). <br/><br/>

      <b>8. What are possible benefits of this study? </b><br/>
      <i>ICD required element to be covered in this section: Description of any reasonably expected benefits to the participant or to others from the study, including (as applicable) whether there is no intended or likely clinical benefit to participants.</i><br/>
      This study is for research purposes only. There is not a direct benefit to you from taking part in the study because you will continue to receive your regular medical care. However, the information learned from the study may help other people in the future.<br/><br/>

      <b>9. What other choices do I have if I do not join this study?</b><br/>
      <i>ICD required element to be covered in this section: Disclosure of appropriate alternative procedures or courses of treatment, if any, that might be advantageous to the participant.</i><br/>
      This study is for research purposes only. Your alternative is to not take part in this study. You may decide not to take part in the study and continue with your regular medical care.<br/><br/>

      <b>10. What happens if I am injured during this study? </b><br/>
      <i>ICD required element to be covered in this section: Description of any compensation and medical treatments available to participants if injury occurs and who to contact. </i><br/>
      This study collects information only, so it is unlikely that you will have a study-related injury. You have previously received or are receiving treatment with Alduretin as part of your standard medical care. Any negative reaction you may experience while taking part in this study will not be considered a study-related injury. <br/><br/>

      <b>11. What if I join this study and then change my mind? </b><br/>
      <i>ICD required elements to be covered in this section: (1) participant may withdraw without penalty; (2) participant will be informed of any significant new information that could affect participation; (3) explanation of the consequences of withdrawal and procedures for orderly termination; and (4) reasons for termination of an individual’s participation in the study. </i><br/>
      If you agree to participate and then change your mind for any reason, you are free to stop participating at any time. Your decision will not affect your regular medical care or any benefits to which you are entitled. You can contact the support center if you are thinking about stopping or decide to stop taking part. You can withdraw from the study using the application or web page at any time. <br/><br/>
      Sometimes your doctor or the Sponsor may decide to take you out of the study if: </br>
      <ul>
        <li>The study is stopped by the Sponsor, the institutional review board (IRB) or independent ethics committee (IEC) (a group of people who review the study to protect your rights), or by a government or regulatory agency. </li>
      </ul>
      Please read the Privacy Supplement (link), which is considered part of this consent document. It describes what happens to your personal information and how it may be used if you withdraw from the study. <br/><br/>

      <b>12. What will I have to pay for if I take part in this study? </b><br/>
      <i>ICD required element to be covered in this section: Description of any costs to the participant from taking part in the study. </i><br/>
      There is no additional cost to you for being in this study. <br/>
      This study is collecting information only and there is no change to your regular medical care. So, the Sponsor will not pay for any treatments or procedures that you may receive during your participation in this study.<br/><br/>

      <b>13. Will I be paid for taking part in this study? </b><br/>
      <i>ICD required element to be covered in this section: Description of any prorated payment and/or reimbursement to participants for any expenses for participating in the study. </i><br/>
      You will be reimbursed for taking part in this study, including being rewarded for activities such as survey completion and updating medical records.<br/>
      The Sponsor may use information resulting from the study to develop products or processes from which it may make a profit. There are no plans to pay you or provide you with any products developed from this study. The Sponsor will own all products or processes that are developed using information from the study. <br/><br/>

      <b>14. What will happen to my personal information? </b><br/>
      <i>ICD required element to be covered in this section: Confidentiality of records and that records can be accessed for obtaining or verification of data. </i><br/>
      Your personal information will be protected as required by law. The study sponsor will not have access to or retain any of your personal information. Personal information will be retained for contact purposes, such as support using the study application, and for study communications such as emails and SMS messages. Please review the Privacy Supplement that is part of this consent document &lt;link>. <br/><br/>

      <b>15. Where can I find additional information about this study or the study results?</b><br/> 
      <i>ICD required element to be covered in this section: Where information about the study can be found. </i><br/>
      A description of this study will be available on the post-authorization study (PAS) register &lt;link>. <br/>
      This website will not include information that can identify you. At most, the website will include a summary of the results. You can search this website at any time. It may be many years, however, before research results are posted. <br/><br/>

      <b>16. Signatures</b><br/> 
      <b>Agreement to Participate and to Process Data (checkboxes for each)</b>
      <ol>
        <li>I confirm I have read and understand this consent document for the study described above and have had the opportunity to ask questions by contacting the support center. I  have had enough time to review this consent document. I also have had an opportunity to ask the support center about the details of the study and to decide whether or not to participate.</li><br/>
        <li>I have read and understand the Privacy Supplement. I understand that taking part in the study will require the processing (including collection, use, transfer, storage, analysis, and reporting) of my personal information, as explained in the Privacy Supplement. I understand and agree to the processing of my personal information within and outside my country of residence for health care, medical research and/or regulatory purposes. </li><br/>
        <li>I understand that taking part is voluntary and that I am free to stop taking part in this study or to withdraw my consent to the processing of my personal information at any time. I do not need to give any reason and my regular medical care and legal rights will not be affected. However, even if I withdraw my consent to processing, my personal information held at that time may be kept to comply with laws and regulations and to maintain the integrity of the study.</li><br/>
        <li>I agree to the study team accessing my medical history, including information from medical records and test results and any medical treatment I receive during the course of the study, and if necessary, contacting my doctor or any other health care providers treating me for access to such information. I understand that my medical records and data collection will not be used after the date of my withdrawal, but some data processing may occur up to 48 hours following my withdrawal.</li><br/>
        <li>I understand that the Sponsor and/or others working with or on behalf of the Sponsor, institutional review boards (IRBs) or independent ethics committees (IECs), and regulatory agencies may need access to personal information about me generated at the site or collected by the study team for the study and any other research. I agree that they may have access to my personal information.</li><br/>
        <li> I do not give up any of my legal rights by signing this consent document. I have been told that I will receive a signed and dated copy of this document. </li><br/>
        <li>I agree to take part in the study described in this document. </li>
      </ol>
      <br/><br/>

      _________________________________________ <br/>
      Name of participant 
      <br/><br/>
      ________________________________________________ ______________ <br/>
      Signature of participant Date of signature <br/>
      (If no legally acceptable representative is used) <br/>
      In the section below, the term “legally acceptable representative” should be replaced with the term required per local regulation (country-level). <br/>
      ______________________________________________ _______________ <br/></br/>

      &lt;privacy statement>

      `,
    },
  },
];
