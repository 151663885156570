import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllPersonStudyArmAttributesQuery } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

export function useGetSalivaKitNumber({ skip }) {
  const {
    activeAccountProfile: {
      attributes: {
        profile: { subject_primary_person_study_arm_id },
      },
    },
  } = useSelector((state) => state.user);

  const { data } = useGetAllPersonStudyArmAttributesQuery(
    {
      'page[limit]': 10000,
      personStudyArmId: subject_primary_person_study_arm_id,
    },
    {
      skip: typeof subject_primary_person_study_arm_id !== 'number' || skip,
    }
  );

  // const salivaKitNumber =
  //   data?.data?.find((attr) => attr.attributes?.attribute === 'kit_number')
  //     ?.attributes?.value ?? '';

  // SING-1103: Find Kit Number with latest "createdAt" date
  if (!data?.data) {
    return {
      salivaKitNumber: ''
    };
  }

  const { salivaKitNumber } = data?.data?.reduce(
    (acc, attr) => {
      console.log("acc, attr", acc, attr);
      if (attr?.attributes?.attribute === 'kit_number') {
        const createdAt = new Date(attr.attributes?.createdAt);
        if (!acc.latestCurrentDate || createdAt > new Date(acc.latestCurrentDate)) {
          return {
            salivaKitNumber: attr.attributes?.value,
            latestCurrentDate: attr.attributes?.createdAt,
          };
        }
      }
      return acc;
    }, { salivaKitNumber: '', latestCurrentDate: null }
  );

  return {
    salivaKitNumber,
  };
}
