import { Link } from 'react-router-dom';
import {
  styled,
  Box,
  Typography,
  List as MuiList,
  ListItem as MuiListItem,
} from '@mui/material';

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Section = styled(Box)(({ theme }) => ({
  padding: '40px 0px',
}));

export const TextWrapper = styled(Box)(({ theme }) => ({
  padding: '40px 100px',
  [theme.breakpoints.down('sm')]: {
    padding: '40px 20px',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  padding: 0,
  fontWeight: 900,
  marginBottom: '20px',
  color: theme.palette.primary.navy,
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: '2vw',
  // },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  padding: 0,
  fontWeight: 600,
  lineHeight: '150%',
  color: theme.palette.primary.navy,
  // [theme.breakpoints.up('lg')]: {
  //   fontSize: '1vw',
  // },
}));

export const NetworksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '80px',
  alignItems: 'center',
  '& img, & svg': {
    maxWidth: '100%',
    height: '50px',
    objectFit: 'contain',
  },
  [theme.breakpoints.down('sm')]: {
    gap: '60px',
  },
}));

export const List = styled(MuiList)(({ theme }) => ({
  marginLeft: theme.spacing(3),
  padding: 0,
  '& .MuiListItem-root': {
    listStyleType: 'disc',
  },
}));

export const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'list-item',
  padding: 0,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.navy,
  textDecoration: 'underline',
  fontWeight: 900,
}));

export const AboutContainer = styled(Box)(({ theme, columns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
}));

export const StyledImg = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
