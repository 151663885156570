import { Box, styled, Typography } from '@mui/material';

export const ModalBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  width: '100%',
  maxWidth: 'min(400px, 90%)',
  height: 'fit-content',
  borderRadius: '10px',
  boxShadow: 24,
  backgroundColor: theme.palette.primary.white,
  alignItems: 'center',
  gap: '20px',
  padding: '40px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.secondary.gray4,
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign: 'center',
}));

export const ModalAction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '8px'
}));
