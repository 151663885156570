import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useCompletePatientTaskMutation,
  useCreatePatientTaskMutation,
  useGetPatientTasksQuery,
  useGetTaskQuery,
} from 'ihp-bloom-redux/features/tasks/taskApiSlice';

export const useExtractDefaultValues = (task) => () => {
  const content = task?.temp?.content;
  return !!content
    ? Object.fromEntries(
        new Map(
          content.map(({ id, questionType }) =>
            questionType === 'datePicker' || questionType === 'timePicker'
              ? [id, null]
              : [id, '']
          )
        )
      )
    : {};
};

const formatPayload = (data, values) => {
  const content = data?.task?.temp?.content
    ?.map(({ id, questionType, category }) =>
      values[id]
        ? {
            id,
            dataType: questionType,
            values: [{ value: values[id] }],
            category,
          }
        : null
    )
    .filter((item) => item != null);

  const rawData = {
    task_id: data?.task?.id,
    study_version_id: data?.task?.study_arm?.study_version_id,
    status: 'completed',
    content,
  };

  return {
    user_id: data?.patient?.user?.id,
    patient_task_id: data?.id,
    participant_id: data?.patient?.id,
    task_id: data?.task?.id,
    study_version_id: data?.task?.study_arm?.study_version_id,
    status: 'completed',
    data: JSON.stringify(rawData),
  };
};

export const usePregnancyJournal = () => {
  const taskId = 562;
  const participantId = useSelector((state) => state.user.patientId);

  // Get all tasks (completed or not)
  const {
    data: patientTasks,
    isSuccess: isPatientTasksSuccess,
    isLoading: isPatientTaskLoading,
    isError: isPatientTaskError,
  } = useGetPatientTasksQuery({
    pagination: false,
  });

  // Find the Pregnancy Journal patient task
  const patientTask = patientTasks?.data?.find?.(
    ({ task: { id } }) => id === taskId
  );

  // Get the Pregnancy Journal task
  const {
    isLoading: isGetTaskLoading,
    isError: isGetTaskError,
    data: task,
  } = useGetTaskQuery(taskId, {
    skip: !isPatientTasksSuccess,
  });

  // Create complete task logic
  const [createPatientTask] = useCreatePatientTaskMutation();
  const [completePatientTask] = useCompletePatientTaskMutation();

  const createAndCompletePatientTask = (values) =>
    createPatientTask({
      participant_id: participantId,
      task_id: taskId,
    })
      .unwrap()
      .then((task) =>
        completePatientTask(formatPayload(task, values)).unwrap()
      );

  const completePregnancyJournal = (values) =>
    !!patientTask
      ? completePatientTask(formatPayload(patientTask, values)).unwrap()
      : createAndCompletePatientTask(values);

  return {
    isLoading: isPatientTaskLoading || isGetTaskLoading,
    isError: isPatientTaskError || isGetTaskError,
    isSuccess: !!task,
    questions: task?.temp?.content,
    compensationValue: task?.temp?.compensation?.compensationValue,
    completePregnancyJournal,
  };
};
