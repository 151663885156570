import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setParticipantTaskCategory } from 'ihp-bloom-redux/features/tasks/participantTaskSlice';

import Button from 'ihp-components/components/v2/Button';
import {
  MainHeaderButtonsContainer,
  MainHeaderContainer,
  Divider,
} from './styles';
import { isFilterApplied } from '../utils';
import { FiltersToggleButton } from 'components/FiltersToggleButton';

const taskCategoryTitleMap = {
  CURRENT: 'TO DO',
  PAST: 'COMPLETED',
};

export const Header = ({
  selectedTaskCategory,
  setSelectedTaskCategory,
  onFilters,
  ...rest
}) => {
  const dispatch = useDispatch();
  const filtersConfig = useSelector((state) => state.filters);

  useEffect(() => {
    dispatch(setParticipantTaskCategory('CURRENT'));
  }, []);

  useEffect(() => {
    return () => dispatch(setParticipantTaskCategory('CURRENT'));
  }, [dispatch]);

  const taskCategory = (category) => (
    <Button
      {...rest}
      size='xs'
      type={selectedTaskCategory === category ? 'primary' : 'secondary'}
      variant={selectedTaskCategory === category ? 'contained' : 'link'}
      onClick={() => setSelectedTaskCategory(category)}
    >
      {taskCategoryTitleMap[category] ?? category}
    </Button>
  );

  return (
    <Stack display='flex' width='100%'>
      <MainHeaderContainer>
        <Box display='flex'>
          <Typography variant='h1'>My Activities</Typography>
        </Box>
        <MainHeaderButtonsContainer>
          {taskCategory('CURRENT')}
          {taskCategory('PAST')}
          <Divider />

          <FiltersToggleButton
            label={'FILTERS'}
            handleOnClick={onFilters}
            hasAppliedFilters={isFilterApplied(filtersConfig.activities)}
          />
        </MainHeaderButtonsContainer>
      </MainHeaderContainer>
    </Stack>
  );
};

Header.propTypes = {
  selectedTaskCategory: PropTypes.string,
  setSelectedTaskCategory: PropTypes.func,
  onFilters: PropTypes.func,
};
