import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const personStudyArmSubRoleApiSlice = createApi({
  reducerPath: 'personStudyArmSubRoleApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['PersonStudyArmSubRole'],
  endpoints: (build) => ({
    createPersonStudyArmSubRole: build.mutation({
      query: (payload) => ({
        url: `/person-study-arm-sub-roles`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonStudyArmSubRole'],
    }),
    getPersonStudyArmSubRole: build.query({
      query: (personStudyArm) => ({
        url: `/person-study-arms/${personStudyArm}/study-arm-sub-roles`,
        method: 'GET',
      }),
      providesTags: ['PersonStudyArmSubRole'],
    }),
    deletePersonStudyArmSubRole: build.mutation({
      query: ({ personStudyArmId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}/study-arm-sub-roles`,
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonStudyArmSubRole'],
    }),
  }),
});

export const {
  useCreatePersonStudyArmSubRoleMutation,
  useGetPersonStudyArmSubRoleQuery,
  useDeletePersonStudyArmSubRoleMutation,
} = personStudyArmSubRoleApiSlice;
