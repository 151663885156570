import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const InCompleteProfileWrapper = styled(Typography)(({ theme }) => ({
  width: 'max-content',
  display: 'flex',
  padding: '7px 10px',
  alignItems: 'center',
  gap: '7px',
  borderRadius: '50px',
  border: '1px solid' + theme.palette.primary.magenta100,
  background: theme.palette.primary.white,
  marginTop: '7px',
}));

export const InCompleteProfileText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.magenta100,
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  letterSpacing: '0.1px',
  textTransform: 'uppercase',
}));
