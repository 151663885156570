import { styled, Typography } from '@mui/material';

export const styles = {
  consentItem: {
    gap: '10px',
  },
};

export const ParticipantConsentsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.palette.primary.white,
  backgroundSize: 'cover',
  height: '100vh',
  padding: '40px',
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    padding: '0px',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  padding: '15px 0px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  p: {
    width: '100%',
    // maxWidth: '377px',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 0px',
  },
}));

export const Header = styled('div')({
  width: '100%',
  maxWidth: '400px',
  margin: 'auto',
  marginBottom: '20px',
});

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));
