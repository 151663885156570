import {
  RadioInput,
  RadioInputGroup,
} from 'ihp-components/components/v2/Input/RadioInput';

const StyledRadioTypeButton = (fieldData) => {
  const { label, radioButtonProps, error, errorLabel, disabled, ...rest } =
    fieldData;
  const radioButtonstyle = {
    display: 'flex',
    'flex-direction': 'row',
    gap: '20px',
    width: '100%',
    '& span': {
      width: '100%',
      margin: '0px !important',
    },
  };

  return (
    <RadioInputGroup
      optionsStyles={radioButtonstyle}
      {...rest}
      label={label}
      error={error}
      errorLabel={errorLabel}
    >
      {radioButtonProps.map((prop) => (
        <RadioInput
          key={prop.value}
          value={prop.value}
          label={prop.label}
          disabled={disabled}
        />
      ))}
    </RadioInputGroup>
  );
};

export default StyledRadioTypeButton;
