import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import Button from 'ihp-components/components/v2/Button';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { selectSelectedParticipanttaskAttributes } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'utils/isMobile';
import FinalPageMobile from './index.mobile';
import { buttonTextStyle, StyledCheckIcon } from './style';
import { setParticipantTask } from 'ihp-bloom-redux/features/tasks/tasksSlice';

function FinalPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedParticipantTaskAttributes = useSelector(
    selectSelectedParticipanttaskAttributes()
  );

  const celebrationScreen = {
    ...selectedParticipantTaskAttributes,
  };

  if (!celebrationScreen?.display) {
    navigate(`/my-activities`);
  }

  if (isMobile()) {
    return <FinalPageMobile celebrationScreen={celebrationScreen} />;
  }

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.gray20,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CelebrationScreen
          attributes={{ ...celebrationScreen }}
          maxHeight={false}
        />
        <Button
          type='submit'
          size='large'
          sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
          onClick={() => {
            dispatch(setParticipantTask(null));
            return navigate('/my-activities');
          }}
        >
          <>
            <StyledCheckIcon />
            <span style={buttonTextStyle}>Done</span>
          </>
        </Button>
      </Box>
    </Box>
  );
}

export default FinalPage;
