const getStyles = (theme) => {
  return {
    leftBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: 200,
      padding: '35px',
    },
    leftContainer: {
      width: 200,
      height: 150,
      textAlign: 'center',
      alignItems: 'center',
    },
    mainContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '50px',
    },
    mainContainerMob: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sectionBox: {
      paddingTop: '15px',
      paddingBottom: '15px',
      width: '560px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    stickyFooter: {
      bottom: 0,
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '120px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '45px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    footerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: '25px',
    },
    buttonContainer: {
      minWidth: '240px',
      width: 'auto'
    },
  };
};
export default getStyles;
