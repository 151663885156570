export const REPORTER_RELATIONSHIP = {
  BIOLOGICAL_FAMILY_MEMBER: 'biological_family_member',
  CAREGIVER: 'caregiver',
  HEALTHCARE_PROVIDER: 'healthcare_provider',
  FRIEND: 'friend',
};

export const relationships = [
  {
    id: 1,
    name: 'Biological relative',
    key: REPORTER_RELATIONSHIP.BIOLOGICAL_FAMILY_MEMBER,
  },
  {
    id: 2,
    name: 'Caregiver',
    key: REPORTER_RELATIONSHIP.CAREGIVER,
  },
  {
    id: 3,
    name: 'Healthcare provider',
    key: REPORTER_RELATIONSHIP.HEALTHCARE_PROVIDER,
  },
  {
    id: 4,
    name: 'Friend',
    key: REPORTER_RELATIONSHIP.FRIEND,
  },
];

export const RELATIONSHIP_NAME_MAP = {
  [REPORTER_RELATIONSHIP.BIOLOGICAL_FAMILY_MEMBER]: 'Biological relative',
  [REPORTER_RELATIONSHIP.CAREGIVER]: 'Caregiver',
  [REPORTER_RELATIONSHIP.HEALTHCARE_PROVIDER]: 'Healthcare provider',
  [REPORTER_RELATIONSHIP.FRIEND]: 'Friend',
};
