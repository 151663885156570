import { Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import { Container, ContentWrapper, PageLink, Divider } from './styles';
import { ReactComponent as LogoTagBottom } from 'images/logo-tag-bottom.svg';
import { ReactComponent as FacebookIcon } from 'images/footer/facebook-logo.svg';
import { ReactComponent as InstagramIcon } from 'images/footer/instagram-logo.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth0LoginPageConfig } from 'utils/auth0';

export const Footer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Container>
      <ContentWrapper>
        <Grid container spacing={3} sx={{ m: { lg: 0, md: 0, sm: 0 }, columnGap: {md: 10, sm: 0}, justifyContent: 'space-between', width: 1 }}>
          <Grid item xs={12} sm={12} md={4} sx={{ maxWidth: { lg: 230 }, p: { lg: '0px !important', md: '0px !important' }, display: 'flex', justifyContent: 'center', pb: { xs: 2, sm: 0 } }}>
            <LogoTagBottom
              fill='white'
              width={'230px'}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} sx={{ maxWidth: { lg: 230, md: 73 }, p: { lg: '0px !important', md: '0px !important' }, display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center' } }}>
            <Links isMobile={isMobile} />
          </Grid>
          <Grid item xs={6} sm={6} md={4} sx={{ maxWidth: { lg: 230 }, p: { lg: '0px !important', md: '0px !important' } }}>
            <Social />
          </Grid>
        </Grid>
        <Divider />
        <Typography
          variant='body2'
          color='white'
          sx={{ textAlign: isMobile ? 'center' : 'end' }}
        >
          Copyright © 2024 Additional Ventures. All rights reserved.
        </Typography>
      </ContentWrapper>
    </Container>
  );
};

const Links = () => {
  const { loginWithRedirect } = useAuth0();

  const openLoginPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig({ context: 'signin' }));
  };

  const props = {
    onClick: () => {
      window.scrollTo(0, 0);
    },
  };

  return (
    <Stack gap={'14px'} alignItems={'flex-start'}>
      <PageLink to='/welcome/about' {...props}>
        About
      </PageLink>

      <PageLink to='/welcome/faq' {...props}>
        FAQs
      </PageLink>

      <PageLink to='/welcome/contact-us' {...props}>
        Contact Us
      </PageLink>

      {/* <PageLink to='#' onClick={openLoginPage}>
        Log In
      </PageLink> */}
    </Stack>
  );
};

const Social = ({ isMobile }) => {
  return (
    <>
      <Typography variant='body2' color='white' marginBottom={'10px'} sx={{ textAlign: { md: 'end', lg: 'center' } }}>
        Connect with us on:
      </Typography>
      <Stack direction={isMobile ? 'column' : 'row'} gap='20px' sx={{ justifyContent: { md: 'center', lg: 'flex-start' }, pl: { md: '47px', lg: '53px' } }}>
        <Link
          to='https://www.instagram.com/singleventriclesource'
          target='_blank'
        >
          <InstagramIcon />
        </Link>
        <Link
          to='https://www.facebook.com/single.ventricle.source/'
          target='_blank'
        >
          <FacebookIcon />
        </Link>
      </Stack>
    </>
  );
};
