import { styled } from '@mui/system';
import { Box, Stack, Typography } from '@mui/material';
import { SvgImageMap } from 'utils';
import { isMobile } from 'utils/isMobile';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12.5px',
  alignContent: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  border: `${isMobile() ? 1 : 0} px solid ${theme.palette.primary.gray10}`,
  borderRadius: isMobile() ? 0 : 10,
  maxWidth: 150,
  height: 150,
  backgroundColor: theme.palette.primary.white,
  margin: isMobile() ? 0 : 5,
}));

const IconWrapper = styled(Box)({
  width: '50px',
  height: '50px',
  alignSelf: 'center',
});

const Label = ({ name, points, totalPoints }) => (
  <Stack spacing='5px'>
    <Typography variant='pb3' color='primary.gray100' align='center'>
      {name}
    </Typography>
    <Typography variant='pl3' color='primary.gray75' align='center'>
      {points} / {totalPoints}
    </Typography>
  </Stack>
);

export default function AchievementCard({
  imageName,
  name,
  points,
  totalPoints,
}) {
  const Icon = SvgImageMap[imageName];

  return (
    <Container>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      <Label name={name} points={points} totalPoints={totalPoints} />
    </Container>
  );
}
