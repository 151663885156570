import { Box, Modal } from '@mui/material';
import { ReactComponent as CloseModal } from 'images/profile/closeModal.svg';
import {
  StyledIconButton,
  Line,
  Title,
  MainContainerStyle,
} from './index.styles';

export const HyperwalletModal = ({ isOpen, onClose, content, title }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={MainContainerStyle}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Title>{title}</Title>
          <StyledIconButton onClick={() => onClose()}>
            <CloseModal height='21px' />
          </StyledIconButton>
        </Box>
        <Line />
        {content}
      </Box>
    </Modal>
  );
};
