export const data = [
  {
    id: 0,
    section: 'Study Description',
    body: {
      __html: `This study is conducted in the United States. The aim of this study is to evaluate the safety and effectiveness of Blexine in subjects with type 2 diabetes mellitus.`,
    },
  },
  {
    id: 1,
    section: 'Background',
    body: {
      __html: `This 12-month observational study assessed the incidence and type of adverse events (AEs) associated with Blexine use according to the standard clinical practice settings and the local label in the United States.`,
    },
  },
];
