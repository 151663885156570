import { Box, Typography, useMediaQuery } from '@mui/material';
import { Container } from './styles';

import { AccordionList } from 'ihp-components/components/Accordion';
// import { GetStarted } from 'pages/Welcome/GetStarted';
// import { useNavigate } from 'react-router-dom';
import { mockGetInvolved } from './mock';
// import AnyQuestions from 'pages/any-questions';
import { WelcomePageWrapper } from 'pages/Welcome/styles';
import { getInvolvedContent } from './mock';
import { ACCORDION_ICONS } from 'utils/configParseUtils';

const GetInvolved = () => {
  const { documentTitle, pageTitle, communityJoinMessageTitle, communityJoinMessageDescription } = getInvolvedContent;
  document.title = documentTitle;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const navigate = useNavigate();

  // const navigateToWelcomeToStudy = () => {
  //   navigate('/onboarding/welcome-to-study');
  // };

  const getAccordionItems = (category) => {
    return category.items.map((item, i) => {
      return {
        ...item,
        title: <Typography variant="pb2" fontWeight={800} color='primary.navy'>{item.title}</Typography>,
        description: <Typography variant="pl1" color='primary.navy' dangerouslySetInnerHTML={{ __html: item.description }}></Typography>
      };
    });
  };
  
  return (
    <Container isMobile={isMobile}>
      <WelcomePageWrapper>
        <Typography color='primary.navy' variant='h1'>
          {pageTitle}
        </Typography>
        <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'}>
          <Box display={'flex'} flexDirection={'column'}>
            {mockGetInvolved.map((category, index) => {
              return (
                <Box key={index}>
                  <Typography variant='h5' color='primary.navy' py={2}>
                    {category.header}
                  </Typography>
                  {category.subheader && (
                    <Typography
                      variant='pl1'
                      display={'block'}
                      mb={2}
                      color='primary.navy'
                    >
                      {category.subheader}
                    </Typography>
                  )}
                  <AccordionList accordionItems={getAccordionItems(category)} accordionIcons={ACCORDION_ICONS} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Typography
          color='primary.navy'
          variant='h3'
          fontWeight='900'
          gutterBottom
          marginTop='20px'
        >
          {communityJoinMessageTitle}
        </Typography>
        <Typography color='primary.navy' variant='pl1'>
          {communityJoinMessageDescription}
        </Typography>
      </ WelcomePageWrapper>
      {/* <GetStarted onGetStarted={navigateToWelcomeToStudy} />
      <AnyQuestions /> */}
    </Container>
  );
};
export default GetInvolved;
