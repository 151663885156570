import { FormProvider } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import { Divider } from 'configUI/components/Shared';
import { MedicationForm } from './MedicationForm';
import { AddReminderForm } from './AddReminderForm';
import {
  CloseButton,
  Content,
  Header,
  Layout,
} from './desktop.styles';
import { ReactComponent as CheckIcon } from 'images/shared/check-2.svg';
import { ReactComponent as Close } from 'images/shared/times.svg';

import { useCreateMedicationTracker } from './hooks';
import {
  getMedicationTrackerRemindersPath,
  getMedicationTrackerAllPath,
} from 'pages/NotificationCenter/paths';

const CreateMedicationTrackerDesktop = () => {
  const { id } = useParams();
  const edit = !!id;

  const header = edit ? 'Edit medication' : 'Add medication';
  const parentURL = edit
    ? getMedicationTrackerRemindersPath(id)
    : getMedicationTrackerAllPath();

  const navigate = useNavigate();

  const [formProviderProps, reminderFormProps, onSave] =
    useCreateMedicationTracker({
      id,
      onCreate: () => navigate(parentURL),
      onUpdate: () => navigate(parentURL),
    });

  return (
    <Layout>
      <Content>
        <Header>
          <Typography variant='h4' color='primary.gray100'>
            {header}
          </Typography>
          <CloseButton component={Link} to={parentURL}>
            <Close width='12px' height='12px' />
          </CloseButton>
        </Header>
        <Divider />
        <Stack divider={<Divider />}>
          <FormProvider {...formProviderProps}>
            <MedicationForm />
          </FormProvider>
          <AddReminderForm {...reminderFormProps} />
        </Stack>
        <Divider />
        <Stack
          direction='row'
          justifyContent='flex-end'
          spacing='5px'
          py='15px'
          px='20px'
        >
          <Button variant='link' size='lg' width='120px' component={Link} to={parentURL}>
            Cancel
          </Button>
          <Button size='lg' width='220px' startIcon={<CheckIcon />} onClick={onSave}>
            Save medication
          </Button>
        </Stack>
      </Content>
    </Layout>
  );
};

export default CreateMedicationTrackerDesktop;
