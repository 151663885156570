import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, PickersDay } from '@mui/lab';
import DateIOAdapter from '@mui/lab/AdapterDateFns';
import StaticDatePicker from '@mui/lab/StaticDatePicker';

const CustomPickersDay = styled(PickersDay)(() => ({
  borderRadius: 0,
}));

const renderWeekPickerDay = (...[, , pickersDayProps]) => (
  <CustomPickersDay {...pickersDayProps} />
);

const getWeekAdapter = (customWeekdays) =>
  function WeekAdapter(options) {
    const adapter = new DateIOAdapter(options);
    const constructDayObject = (day) => ({ charAt: () => day });
    return {
      ...adapter,
      getWeekdays: () => customWeekdays.map(constructDayObject),
    };
  };

export const DatepickerInput = ({
  defaultDate,
  customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  ...rest
}) => {
  const [date, setDate] = useState(defaultDate);
  const onChangeHandler = (value) => {
    setDate(value);
  };

  return (
    <LocalizationProvider dateAdapter={getWeekAdapter(customWeekdays)}>
      <StaticDatePicker
        onChange={onChangeHandler}
        displayStaticWrapperAs='desktop'
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
        {...rest}
        value={date}
      />
    </LocalizationProvider>
  );
};
