import { Link } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';

import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';

export const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '60px 40px 20px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const AcmeLogo = styled(Logo)({
  width: '102px',
  height: '70px',
});

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.secondary.gray4,
}));

export const FormContainer = styled('div')({
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const BackLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.fuchsia75,
  textDecoration: 'none',
}));

export const RewardBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.green100,
  backgroundColor: theme.palette.primary.green5,
  border: `1px solid ${theme.palette.primary.green25}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '63px',
  width: '100%',
  fontSize: '16px',
  fontWeight: '500',
}));

export const RewardPoints = styled(Typography)({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '20px',
});

export const PointsContainer = styled('div')(
  ({ theme, noPadding, svgWidth, svgHeight }) => ({
    padding: `${noPadding ? '0px' : '8px 10px'}`,
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    backgroundColor: `${
      noPadding
        ? theme.palette.primary.transparent
        : theme.palette.primary.white
    }`,
    borderRadius: '50px',
    svg: {
      width: svgWidth,
      height: svgHeight,
    },
  })
);
