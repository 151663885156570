import { useEffect, useState } from 'react';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { getParticipantProfileTabInfo } from 'utils/configParseUtils';
import ReportersList from './Reporters';
import Relationships from './relationships';

import { ContributorsWrapper, Line } from './styles';

const StudyContributors = () => {
  const { participantProfileConfig } = useSelector(
    (state) => state.configuration
  );
  const { fieldsMap } = getParticipantProfileTabInfo(
    participantProfileConfig,
    'Study Contributors'
  );
  const [displayReporter, setDisplayReporter] = useState();

  useEffect(() => {
    if (fieldsMap) {
      fieldsMap.forEach((field) => {
        if (field?.name == 'reporters') {
          setDisplayReporter(field?.show);
        }
      });
    }
  }, [fieldsMap]);

  return (
    <ContributorsWrapper>
      <Relationships />
      {displayReporter && (
        <>
          <Line />
          <ReportersList />
        </>
      )}
    </ContributorsWrapper>
  );
};

export default StudyContributors;
