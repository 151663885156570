import { useCallback, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { isMobile } from 'utils/isMobile';
import { parseHealthProviderAddress } from 'services/oneUpService';
import {
  ProviderAddress,
  ProviderCardContainer,
  IconWrapper,
  ProviderName,
  TitleContainer,
  NameAddressContainer,
  CircleWrapper,
  IconContainer,
} from './styles';
import { ReactComponent as GreenCheck } from 'images/shared/green-check.svg';
import { ReactComponent as AddedSuccessfullyCheck } from 'images/data-sync/added-successfully.svg';
import { ReactComponent as AddedPreviouslyCheck } from 'images/data-sync/added-previously.svg';
import { ReactComponent as ClockIcon } from 'images/data-sync/clock.svg';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { updateLastConnectedProvider } from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useConnectHandler } from '../hooks';
import ManualProviderIcon from 'images/shared/error-provider.svg';
import { ReactComponent as Pin } from 'images/emr/pin.svg';
import { ReactComponent as User } from 'images/emr/user.svg';
import { ReactComponent as Circle } from 'images/emr/circle.svg';

export const ProviderCardV2 = ({
  provider,
  navigate,
  onConnectedProviderSelect,
  onManualProviderSelect,
  connected,
  setLoadingProviders,
  setIsRedirecting,
  setConnectingProvider,
  addManualProvider,
  isProfile = false,
  hasSearchTerm,
  noRadius,
  isLast,
  taskPersonId,
  patientName,
  personName,
  childName,
  isChildTask,
}) => {
  const dispatch = useDispatch();
  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { inProgressProviders } = oneUpHealthData;
  const inProgress = inProgressProviders
    .map((p) => p?.id)
    .includes(provider.id);
  const { name, locations } = provider;

  const { activeAccountProfile } = useSelector((state) => state.user);
  const personId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id ||
    taskPersonId;

  const idVerificationInProgress =
    provider?.attributes?.status === 'success' ||
    provider?.attributes?.status == null;

  const connectHandler = useConnectHandler(
    provider,
    setLoadingProviders,
    setIsRedirecting,
    setConnectingProvider,
    personId,
    false
  );

  const onImageError = (e) => {
    e.target.src = ManualProviderIcon;
  };

  const logo = (
    <img
      src={provider.logo}
      alt='health center'
      width={50}
      height={50}
      onError={onImageError}
    />
  );
  const theme = useTheme();

  const pointerCSS = {
    cursor:
      (!hasSearchTerm && !isProfile) || (hasSearchTerm && connected)
        ? 'unset'
        : 'pointer',
    pointerEvents:
      (!hasSearchTerm && !isProfile) || (hasSearchTerm && connected)
        ? 'none'
        : 'auto',
  };
  let paddingTop = '0';
  let paddingRight = '0';
  const hasLastConnectedTransitioned =
    oneUpHealthData?.lastConnectedProvider?.isCheckStateTransitioned;

  const [addedIcon, setAddedIcon] = useState(null);

  const clickHandler = useCallback(() => {
    if (hasSearchTerm && !connected) {
      return connectHandler;
    } else {
      return onConnectedProviderSelect;
    }
  }, [
    hasSearchTerm,
    isProfile,
    connected,
    onConnectedProviderSelect,
    connectHandler,
  ]);

  useEffect(() => {
    if (connected && hasSearchTerm) {
      setAddedIcon(
        <AddedPreviouslyCheck width={isMobile ? 130 : 141} height={24} />
      );
    } else if (
      !hasSearchTerm &&
      oneUpHealthData?.lastConnectedProvider?.id != null &&
      oneUpHealthData?.lastConnectedProvider?.id ==
        provider?.attributes?.health_system_id &&
      !hasLastConnectedTransitioned &&
      !isProfile
    ) {
      setAddedIcon(
        <AddedSuccessfullyCheck width={isMobile ? 90 : 141} height={24} />
      );
    } else if (!hasSearchTerm && connected) {
      setAddedIcon(<GreenCheck width={20} height={20} />);
    }
  }, [connected, oneUpHealthData?.lastConnectedProvider, hasSearchTerm]);

  useEffect(() => {
    if (
      oneUpHealthData?.lastConnectedProvider?.id != null &&
      oneUpHealthData?.lastConnectedProvider?.id ==
        provider?.attributes?.health_system_id
    ) {
      const intervalID = setTimeout(() => {
        if (!hasLastConnectedTransitioned) {
          setAddedIcon(<GreenCheck width={20} height={20} />);
          dispatch(
            updateLastConnectedProvider({
              ...provider,
              id: oneUpHealthData?.lastConnectedProvider?.id,
              connectedAt: oneUpHealthData?.lastConnectedProvider?.connectedAt,
              isCheckStateTransitioned: true,
            })
          );
        }
      }, 3000);

      return () => clearTimeout(intervalID);
    }
  }, [dispatch, oneUpHealthData?.lastConnectedProvider?.connectedAt, provider]);

  return (
    <ProviderCardContainer
      sx={{ bgcolor: theme.palette.primary.white, ...pointerCSS }}
      borderRadius={noRadius ? '0px' : '8px'}
      onClick={clickHandler()}
    >
      <Box sx={{ display: 'flex', maxWidth: isMobile() ? '315px' : '600px' }}>
        <IconWrapper>{logo}</IconWrapper>
        <TitleContainer>
          <ProviderName>{name}</ProviderName>
          <NameAddressContainer>
            <IconContainer>
              <Pin width={16} height={16} />
            </IconContainer>
            <ProviderAddress>
              {locations?.length === 1
                ? parseHealthProviderAddress(locations[0]?.address)
                : ''}
            </ProviderAddress>
          </NameAddressContainer>
        </TitleContainer>
      </Box>
      <Box
        sx={{
          paddingTop,
          paddingRight,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {addedIcon}
      </Box>
    </ProviderCardContainer>
  );
};
