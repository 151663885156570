import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ListItemWrapper = styled(Box)(({ theme }) => ({
  height: '70px',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.white,
  padding: '0 15px',
  display: 'flex',
  alignItems: 'center',
  boxShadow: `0px 0px 9px 0px #0000000A`,
  boxSizing: 'border-box',
}));

export const ListItemTitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.primary.gray100,
  fontWeight: '600',
}));

export const ListItemSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  lineHeight: '16px',
  color: theme.palette.primary.gray50,
  fontWeight: '400',
}));
