export const pronouns = Object.freeze([
    'She/Her',
    'He/Him',
    'They/Them',
    'Other',
    'Prefer not to say',
]);

export const firstNameLabel = 'First Name';
export const lastNameLabel = 'Last Name';
export const dobLabel = 'Date of Birth';