import {styled} from "@mui/system";
import {Typography} from "@mui/material";

export const StyledDescription = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray50,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16.8px',
    textAlign: 'center',
    width: '261px'
}));