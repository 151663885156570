import { BaseHeader } from 'components/BaseLayout';
import { Header, StyledBaseLayout } from './styles';
import { CardList } from './CardList';

const PregnancyJournalDetailedDesktop = ({
  data: { header, forms },
  isLoading,
}) => (
  <StyledBaseLayout
    header={
      <Header>
        <BaseHeader to='/tools/pregnancy-journal'>
          {header}
        </BaseHeader>
      </Header>
    }
    content={<CardList forms={forms} isLoading={isLoading} />}
  />
);

export default PregnancyJournalDetailedDesktop;
