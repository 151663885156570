import { theme } from 'theme';
import { Typography, styled } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '140%',
  fontWeight: '600',
  color: theme.palette.primary.grey75,
  margin: '20px 0',
}));

export const Line = styled('hr')(({ theme }) => ({
  border: '0',
  borderTop: `1px solid ${theme.palette.primary.lightGray100}`,
  margin: '20px 0',
}));
