import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { FilledButton } from 'components/Buttons';

export const MyContactInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '600px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
}));

export const ContactTitle = styled('text')(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '300%',
  color: '#6B757A',
}));

export const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  marginBottom: '40px',
  gap: '20px',
  left: '8px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '50px',
  },

  '& button': {
    height: '56px',
    padding: '17px 15px',
    margin: '10px',
  },
}));

export const StyledFilledButton = styled(FilledButton)(({ theme }) => ({
  minWidth: '102px',
  gap: '7px',
  fontSize: '14px',
}));

export const saveIcon = {
  marginBottom: '-9px',
  marginRight: '5px',
  fontSize: '30px',
};
