import { styled } from '@mui/system';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

export const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.gray10,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.primary.fuchsia75,
  },
}));

export const ProgressBar = ({ currentStep, stepsLength }) => {

  const value = currentStep < stepsLength
    ? (currentStep * 100) / stepsLength
    : 100;

  const backgroundColor = currentStep > stepsLength
    ? theme => theme.palette.primary.green100
    : theme => theme.palette.primary.fuchsia75;

  const styles = {
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: backgroundColor,
    },
  };

  return (
    <StyledProgressBar
      variant="determinate"
      value={value}
      sx={styles}
    />
  );
};
