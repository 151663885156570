import { Container, StyledFilledButton, StyledLinkButton } from './Footer.styles';
import { ReactComponent as CheckIcon } from 'images/shared/check-2.svg';

export const Footer = ({
  currentStep,
  lastStep,
  onForward,
  onBackward,
  disabled,
}) => {
  const filledButtonText = currentStep === lastStep
    ? 'Save and finish'
    : 'Save and continue';
  return (
    <Container>
      <StyledLinkButton variant='link' onClick={onBackward} disabled={disabled}>
        Cancel
      </StyledLinkButton>
      <StyledFilledButton onClick={onForward} disabled={disabled}>
        <CheckIcon width={15} height={15} /> {filledButtonText}
      </StyledFilledButton>
    </Container>
  );
};