import { useAuth0 } from '@auth0/auth0-react';

export const useAuth0Extended = () => {
  const { user, isAuthenticated, isLoading, ...rest } = useAuth0();

  /**
   * The route is authenticated if the user has valid auth and there are no
   * JWT claim mismatches.
   */
  const routeIsAuthenticated =
    !isLoading && isAuthenticated && user !== undefined;

  return {
    routeIsAuthenticated,
    user,
    isAuthenticated,
    isLoading,
    ...rest,
  };
};