import { STUDY_ARM_CODE } from 'constants/global';
import { useSelector } from 'ihp-bloom-redux/app/redux';

export function useIsActiveProfileContactRole() {
  const { activeAccountProfile } = useSelector((state) => state.user);

  return {
    isActiveProfileContactRole:
      activeAccountProfile?.attributes?.profile?.study_arm_code ===
      STUDY_ARM_CODE.CONTACT,
  };
}
