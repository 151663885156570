import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { Typography, Box } from '@mui/material';

import { FormCheckbox } from 'components/Checkbox';
import Button from 'ihp-components/components/v2/Button';
import { RejectConsentConfirmationModal } from 'components/Profile/RejectConsentConfirmationModal';
import { SignButton } from 'pages/Profile/Consents/List/styles';
import { mapTaskRequiredStatus } from 'utils/tasks';
import { ReactComponent as ArrowBackwardIcon } from 'images/activity/arrow-backward-icon.svg';
import { ReactComponent as Clock } from 'images/profile/clock.svg';
import { ReactComponent as VersionIcon } from 'images/profile/version.svg';
import { ReactComponent as CheckIcon } from 'images/profile/check-circle-solid-green.svg';
import { ReactComponent as ExclamationIcon } from 'images/profile/exclamation-circle.svg';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import {
  ConsentDetailsContainer,
  Content,
  Header,
  Body,
  AcmeLogo,
  Row,
  PageTitle,
  PageSubTitle,
  Stats,
  StatusChip,
  SignDate,
  Version,
  ConsentCheckMark,
  Footer,
  MobileBackArrow,
} from './styles';

function ParticipantConsentDetailsMobile({
  details,
  included,
  isSigned,
  consentVersionId,
  handleSign,
}) {
  const navigate = useNavigate();

  const [consentAgreed, setConsentAgreed] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const onToggle = () => setConsentAgreed(!consentAgreed);
  const handleCancel = () => navigate('/onboarding/participant-consents');

  const handleReject = () => {
    if (!details?.mandatory) {
      handleCancel();
      return;
    }
    setShowRejectConfirmation(true);
  };
  const handleConfirmRejection = (confirm) => {
    setShowRejectConfirmation(false);
    if (confirm) {
      handleCancel();
    }
  };

  return (
    <ConsentDetailsContainer>
      <MobileBackArrow onClick={handleCancel}>
        <ArrowBackwardIcon />
      </MobileBackArrow>
      <Row marginBottom='20px'>
        <AcmeLogo />
      </Row>
      <Content>
        <Header>
          <Row marginBottom='10px'>
            <PageSubTitle variant='h4'>INFORMED CONSENT</PageSubTitle>
          </Row>
          <Row marginBottom='10px'>
            <PageTitle variant='h2'>{included?.title}</PageTitle>
          </Row>
          <Stats>
            <StatusChip
              isRequired={
                mapTaskRequiredStatus(included?.required_behavior) ===
                'REQUIRED'
                  ? true
                  : false
              }
            >
              <ExclamationIcon />
              <span>
                {mapTaskRequiredStatus(included?.required_behavior) ===
                'REQUIRED'
                  ? 'REQUIRED'
                  : 'OPTIONAL'}
              </span>
            </StatusChip>
            <SignDate>
              {isSigned ? <CheckIcon /> : <Clock />}
              <span>
                {!!details &&
                  format(
                    new Date(details?.start_date ?? Date.now()),
                    'yyy-MM-dd'
                  )}
              </span>
            </SignDate>
            <Version>
              <VersionIcon />
              <span>{consentVersionId}</span>
            </Version>
          </Stats>
        </Header>
        <Body>
          <Box>
            <Box>
              <Typography variant='pl3' color='primary.gray75'>
                {included?.content}
              </Typography>
            </Box>
          </Box>

          {!isSigned && (
            <ConsentCheckMark>
              <FormCheckbox
                label='I agree to sign this consent'
                checked={consentAgreed}
                onChange={onToggle}
              />
            </ConsentCheckMark>
          )}
          <Footer>
            <Button size='lg' variant='link' onClick={handleReject}>Reject</Button>
            <SignButton
              variant='contained'
              size='large'
              disabled={!consentAgreed}
              startIcon={<Sign width={19} height={15} />}
              onClick={handleSign}
            >
              Sign consent
            </SignButton>
          </Footer>
        </Body>
      </Content>
      <RejectConsentConfirmationModal
        open={showRejectConfirmation}
        onClose={() => setShowRejectConfirmation(false)}
        onConfirmation={handleConfirmRejection}
      />
    </ConsentDetailsContainer>
  );
}

export default ParticipantConsentDetailsMobile;
