import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  // useGetCurrentUserQuery,
  useGetStatusMutation,
} from 'ihp-bloom-redux/features/user/userApiSlice';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-2.svg';
import { ReactComponent as EnvelopeIcon } from 'images/shared/envelope-solid.svg';
import { ReactComponent as Logo } from 'images/svs-logo.svg';
import OnboardingLayout from '../components/Layout';
import { Title } from 'pages/Onboarding/components/Text';
import { ErrorPage } from 'components/ErrorPage';
// import getRoute from 'utils/navigator';
import { findStudyAttribute } from 'utils/study';
import {
  STUDY_ARM_CODE,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_CODES,
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_STATUS_NAMES,
} from '../../../constants/global';

import { Row, ContactBadge, ContactNumber, ButtonContainer } from './styles';
import { SVSOURCE_CONTACT } from 'utils/constants';

function CreateProfile() {
  document.title = 'Single Ventricle SOURCE Creating Profile';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState('active');
  const [counter, setCounter] = useState(0);
  const [isLoadingAccountProfile, setIsLoadingAccountProfile] = useState(false);

  const [getStatus, { error }] = useGetStatusMutation();

  const user = useSelector((state) => state.user);
  const { studyConfiguration } = useSelector((state) => state.configuration);
  const { activeAccountProfile, accountProfiles, isEnrollingNewPerson } =
    useSelector((state) => state.user);
  const [updatePersonStudyArm, { isLoading: isUpdatingStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const { configuration } = useSelector((state) => state.configuration);

  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;
  const studyArmId = activeAccountProfile?.attributes?.profile?.study_arm_id;
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;
  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;
  const subjectPrimaryStudyArmStatusTitle =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_study_arm_status_title;
  const subjectSecondaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_secondary_person_study_arm_id;
  const subjectSecondaryStudyArmStatusTitle =
    activeAccountProfile?.attributes?.profile
      ?.subject_secondary_study_arm_status_title;
  const contactEnrolledStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_ENROLLED
    ]?.id;
  const researchEnrolledStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED
    ]?.id;
  const allFTDEnrolledStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.ALLFTD_ENROLLED
    ]?.id;
  // const studyArmRoleId =
  //   configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
  //     STUDY_ARM_ROLE_CODES.R_REPORTER
  //   ].id;
  // const studyArmRoleIdForLar =
  //   configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
  //     STUDY_ARM_ROLE_CODES.R_LAR
  //   ].id;
  const allFTDEnrolledSiteStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.ALLFTD_ENROLLED_SITE
    ]?.id;

  const participantStudyArmRole =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
      STUDY_ARM_ROLE_CODES.Participant
    ];

  const subjectPrimaryPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;

  const callCenterPhone =
    findStudyAttribute(studyConfiguration || [], 'call_centre_phone') ||
    '(xxx) xxx-xxxx';

  useEffect(() => {
    const backgroundOp = async () => {
      const backgroundOperationSuccess = await backgroundOperations();
      console.log(
        'Finished background operation: ',
        backgroundOperationSuccess
      );
      if (backgroundOperationSuccess) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setIsLoading(false);
        return;
      }
    };
    backgroundOp();
    // await performGetStatus();
  }, []);

  const onContinue = () => {
    if (data !== 'active') {
      setData('');
      setIsLoading(true);
      // performGetStatus();
    } else {
      dispatch(setValues({ isEnrollingNewPerson: false }));
      sessionStorage.setItem('isFirstTimeLogin', 'true')
      sessionStorage.removeItem('isRequiredActivitiesCompleted');
      navigate('/my-activities');
      /*if (
        (subjectSecondaryPersonStudyArmId !== null || studyArmCode === STUDY_ARM_CODE.RESEARCH)
        && user?.accountProfiles?.length <= 1
      ) {
        navigate('/onboarding/enrollment-feedback');
      } else {
        navigate('/home');
      }*/
    }
  };

  if (error && counter >= 3) {
    return (
      <ErrorPage errorCode={error.status} errorMessage={error.data?.error} />
    );
  }

  const getPageTitle = () => {
    if (!isLoading && data !== 'active') {
      return 'Oops!';
    }
    return 'Thank you for joining SOURCE!';
  };

  const getHelperText = () => {
    if (!isLoading && data !== 'active') {
      if (callCenterPhone) {
        return (
          <>
            Something went wrong, please try again.
            <br />
            <br />
            If the problem persists, contact support at:
          </>
        );
      } else {
        return <>Something went wrong, please try again.</>;
      }
    }
    return 'Please wait a moment while we create your personalized dashboard';
  };

  const goBack = () => {
    navigate(-1);
  };

  const refetchAccountProfileData = async (profileId) => {
    const id = Number(profileId);
    try {
      setIsLoadingAccountProfile(false);
      const accountProfileResponse = await dispatch(
        accountProfileApiSlice.endpoints.getAccountProfile.initiate(id, {
          forceRefetch: true,
        })
      );
      setIsLoadingAccountProfile(false);
      if (accountProfileResponse?.data?.data) {
        const activeAccountProfilePayload = {
          ...user,
          activeAccountProfile: accountProfileResponse?.data?.data,
        };
        if (isEnrollingNewPerson) {
          const targetIndex =
            activeAccountProfilePayload?.accountProfiles?.findIndex(
              (profile) =>
                Number(profile.id) ===
                Number(accountProfileResponse?.data?.data?.id)
            );
          if (targetIndex < 0) {
            activeAccountProfilePayload.accountProfiles = [
              ...activeAccountProfilePayload.accountProfiles,
              {
                id: `${accountProfileResponse?.data?.data?.attributes?.profile?.id}`,
                type: 'Profiles',
                attributes: {
                  ...accountProfileResponse?.data?.data?.attributes?.profile,
                },
              },
            ];
          } else {
            activeAccountProfilePayload.accountProfiles =
              activeAccountProfilePayload.accountProfiles?.map((profile) => {
                if (
                  Number(profile.id) ===
                  Number(accountProfileResponse?.data?.data?.id)
                ) {
                  return {
                    id: accountProfileResponse?.data?.data?.id,
                    type: 'Profiles',
                    attributes: {
                      ...accountProfileResponse?.data?.data?.attributes
                        ?.profile,
                    },
                  };
                }
                return profile;
              });
          }
        } else {
          const targetIndex =
            activeAccountProfilePayload?.accountProfiles?.findIndex(
              (profile) =>
                Number(profile.id) ===
                Number(accountProfileResponse?.data?.data?.id)
            );
          if (targetIndex < 0) {
            activeAccountProfilePayload.accountProfiles = [
              ...activeAccountProfilePayload.accountProfiles,
              {
                id: `${accountProfileResponse?.data?.data?.attributes?.profile?.id}`,
                type: 'Profiles',
                attributes: {
                  ...accountProfileResponse?.data?.data?.attributes?.profile,
                },
              },
            ];
          } else {
            activeAccountProfilePayload.accountProfiles =
              activeAccountProfilePayload.accountProfiles?.map((profile) => {
                if (
                  Number(profile.id) ===
                  Number(accountProfileResponse?.data?.data?.id)
                ) {
                  return {
                    id: accountProfileResponse?.data?.data?.id,
                    type: 'Profiles',
                    attributes: {
                      ...accountProfileResponse?.data?.data?.attributes
                        ?.profile,
                    },
                  };
                }
                return profile;
              });
          }
        }
        await dispatch(setValues(activeAccountProfilePayload));
      }
    } catch (error) {
      setIsLoadingAccountProfile(false);
      console.log('Error on fetching new profile');
    }
  };

  const backgroundOperations = async () => {
    const apiCalls = [];

    if (studyArmCode === 'contact') {
      apiCalls.push(
        updatePersonStudyArm({
          personStudyArmId: accountPersonStudyArmId,
          payload: getStudyArmPayload(
            accountPersonStudyArmId,
            contactEnrolledStatusId,
            {
              study_arm_role_id: participantStudyArmRole?.id,
            }
          ),
        })
      );
    }

    if (studyArmCode === 'research') {
      apiCalls.push(
        updatePersonStudyArm({
          personStudyArmId: accountPersonStudyArmId,
          payload: getStudyArmPayload(
            accountPersonStudyArmId,
            researchEnrolledStatusId
          ),
        })
      );

      // This is for LAR
      if (
        subjectPrimaryPersonStudyArmId !== accountPersonStudyArmId &&
        subjectPrimaryStudyArmStatusTitle !== STUDY_ARM_STATUS_NAMES['ENROLLED']
      ) {
        apiCalls.push(
          updatePersonStudyArm({
            personStudyArmId: subjectPrimaryPersonStudyArmId,
            payload: getStudyArmPayload(
              subjectPrimaryPersonStudyArmId,
              researchEnrolledStatusId
            ),
          })
        );
      }

      if (subjectSecondaryPersonStudyArmId) {
        apiCalls.push(
          updatePersonStudyArm({
            personStudyArmId: subjectSecondaryPersonStudyArmId,
            payload: getStudyArmPayload(
              subjectSecondaryPersonStudyArmId,
              contactEnrolledStatusId
            ),
          })
        );
      }
    }

    const allFtDProfile = accountProfiles.find((profile) => {
      return (
        profile.attributes.study_arm_code === STUDY_ARM_CODE.ALLFTD &&
        profile.attributes.study_arm_status_id === allFTDEnrolledSiteStatusId
      );
    });

    if (allFtDProfile) {
      apiCalls.push(
        updatePersonStudyArm({
          personStudyArmId:
            allFtDProfile?.attributes?.account_person_study_arm_id,
          payload: getStudyArmPayload(
            allFtDProfile?.attributes?.account_person_study_arm_id,
            allFTDEnrolledStatusId
          ),
        })
      );
    }

    try {
      console.log(apiCalls);
      await Promise.all(apiCalls);
      // Once all status updates are done refetch active account profile
      await refetchAccountProfileData(activeAccountProfile?.id);
      console.log(activeAccountProfile);
      return true;
    } catch (error) {
      console.error('Error during background operations:', error);
      return false;
    }
  };

  const performGetStatus = async () => {
    const retryAttempts = 3;
    for (let attempt = 1; attempt <= retryAttempts; attempt++) {
      const res = await getStatus(subjectPrimaryPersonId);

      if (res.error || res?.data?.status !== 'active') {
        console.log('Try again');
        if (attempt === retryAttempts) {
          setIsLoading(false);
          return;
        }
      } else {
        setIsLoading(false);
        setData(res.data?.status);
        break;
      }
    }
  };

  const getStudyArmPayload = (id, studyArmStatusId, extraAttributes = {}) => {
    return {
      data: {
        type: 'person-study-arms',
        id: `${id}`,
        attributes: {
          study_arm_status_id: studyArmStatusId,
          ...extraAttributes,
        },
      },
    };
  };

  return (
    <OnboardingLayout maxWidth='375px' backHandler={goBack}>
      <Row>
        <Logo />
      </Row>
      <Row marginTop='10px' marginBottom='5px'>
        <Title marginTop='0px'>{getPageTitle()}</Title>
      </Row>
      <Row marginBottom='15px'>
        <Typography
          color='secondary.gray4'
          fontSize='14px'
          align='center'
          fontStyle='normal'
          fontWeight='400'
          lineHeight='140%'
        >
          {getHelperText()}
        </Typography>
      </Row>
      {!isLoading && data !== 'active' && callCenterPhone && (
        <>
          <Row>
            <ContactBadge>
              <EnvelopeIcon width={20} />
              <ContactNumber>{SVSOURCE_CONTACT.EMAIL}</ContactNumber>
            </ContactBadge>
          </Row>
          <Row>
            <ContactBadge>
              <PhoneIcon width={20} />
              <ContactNumber>{callCenterPhone}</ContactNumber>
            </ContactBadge>
          </Row>
        </>
      )}
      <Row>
        {isLoading ? (
          <ButtonContainer>
            <Button fullWidth loading='true'></Button>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <Button fullWidth onClick={onContinue}>
              Continue
            </Button>
          </ButtonContainer>
        )}
      </Row>
    </OnboardingLayout>
  );
}

export default CreateProfile;
