import { forwardRef, useState } from 'react';
import { format } from 'date-fns';

import { ModalDatePicker } from 'components/DatePicker';
import { TextInput } from 'components/Input';
import { Card } from '../Common/Card';

const DateCardImpl = forwardRef(({
  title,
  subtitle,
  onChange,
  value,
  placeholder,
  ...rest
}, ref) => (
  <Card
    title={title}
    subtitle={subtitle}
    value={value ? format(value, 'eeee, MM.dd.yyyy') : ''}
    {...rest}
  >
    <ModalDatePicker
      ref={ref}
      value={value}
      onChange={onChange}
      renderInput={({ inputProps, inputRef }) => (
        <TextInput
          ref={inputRef}
          placeholder={placeholder}
          sx={{ width: '100%' }}
          {...inputProps}
        />
      )}
    />
  </Card>
));

export const DateCard = forwardRef(({
  title,
  subtitle,
  value,
  onChange,
  defaultValue,
  placeholder,
  ...rest
}, ref) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  const handleChange = (_, value) => onChange
    ? onChange(_, value)
    : setInternalValue(value);

  return <DateCardImpl
    ref={ref}
    title={title}
    subtitle={subtitle}
    onChange={handleChange}
    value={onChange ? value : internalValue}
    placeholder={placeholder}
    {...rest}
  />;
});
