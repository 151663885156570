import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

export function useGeneralInfo() {
  const [
    createPersonStudyArmAttribute,
    { isLoading: isLoadingCreatePersonStudyArmAttribute },
  ] = useCreatePersonStudyArmAttributeMutation();

  const createPersonStudyArmAttributeRecord = (studyArmId, options) => {
    const payload = {
      data: options.map((option) => ({
        type: 'person-study-arm-attributes',
        attributes: {
          attribute: option.attribute,
          value: option.value,
        },
      })),
    };

    return createPersonStudyArmAttribute({
      personStudyArmId: studyArmId,
      payload,
    });
  };

  return {
    createPersonStudyArmAttributeRecord,
    isLoading: isLoadingCreatePersonStudyArmAttribute,
  };
}
