import { useEffect } from 'react';
import { Stack, Typography } from '@mui/material';

import { EditToolbarContainer, MenuButton, TextArea } from './styles';
import {
  getSyntaxError,
  formatJson,
  useHandleCopy,
  useHandleSaveJson,
} from './utils';

const EditToolbar = ({
  handleSave,
  handleCopy,
  handlePaste,
  handleClear,
  handleFormat,
  error,
}) => (
  <EditToolbarContainer>
    <MenuButton onClick={handleSave}>Save</MenuButton>
    <MenuButton onClick={handleCopy}>Copy</MenuButton>
    <MenuButton onClick={handlePaste}>Paste</MenuButton>
    <MenuButton onClick={handleClear}>Clear</MenuButton>
    <MenuButton onClick={handleFormat} disabled={error}>
      Format
    </MenuButton>
  </EditToolbarContainer>
);

const Error = ({ error }) => (
  <Typography color='primary.red' variant='pn5'>
    {error && `Error: ${error}`}
  </Typography>
);

const Edit = ({ name, placeholder, value, onChange, error, onError }) => {
  useEffect(
    () => onError({ target: { name, value: getSyntaxError(value) } }),
    [name, onError, value]
  );

  const handleChange = ({ target: { value } }) =>
    onChange({ target: { name, value } });

  const handleSave = useHandleSaveJson(name, value);
  const handleCopy = useHandleCopy(value);
  const handlePaste = () =>
    navigator.clipboard
      .readText()
      .then((value) => handleChange({ target: { name, value } }));
  const handleClear = () => onChange({ target: { name, value: '' } });
  const handleFormat = () => {
    try {
      onChange({ target: { name, value: formatJson(value) } });
    } catch {}
  };

  return (
    <Stack>
      <EditToolbar
        handleSave={handleSave}
        handleCopy={handleCopy}
        handlePaste={handlePaste}
        handleClear={handleClear}
        handleFormat={handleFormat}
        error={error}
      />
      <Error error={error} />
      <TextArea
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
      <Error error={error} />
      <EditToolbar
        handleCopy={handleCopy}
        handlePaste={handlePaste}
        handleClear={handleClear}
        handleFormat={handleFormat}
        error={error}
      />
    </Stack>
  );
};

export default Edit;
