import { useOutlet } from 'react-router-dom';
import { Modal, useMediaQuery, useTheme } from '@mui/material';

import { useSearchParamDate } from './hooks';
import MedicationTrackerDesktop from './index.desktop';
import MedicationTrackerMobile from './index.mobile';

const MedicationTracker = () => {
  document.title = 'Single Ventricle SOURCE Medication Tracker';
  const [date, setDate] = useSearchParamDate('yyyy-MM-dd');
  const outlet = useOutlet();
  if (useMediaQuery(useTheme().breakpoints.up('sm')))
    return (
      <>
        <MedicationTrackerDesktop date={date} onDateChange={setDate} />
        <Modal open={!!outlet.props.children}>{outlet}</Modal>
      </>
    );
  if (!!outlet.props.children) return outlet;
  return <MedicationTrackerMobile date={date} onDateChange={setDate} />;
};

export default MedicationTracker;
