import { Stack } from '@mui/material';

import { mapTaskRequiredStatus } from 'utils/tasks';
import { Main } from './Main';
import { Card } from './styles';

export const ConsentItem = ({
  consent,
  setConsent,
  onRejectConsent,
  rendererPage,
  isLar,
  name,
}) => {
  const {
    title,
    description,
    // consentStatus,
    consentVersion,
    consentVersionDate,
    status,
    isOverdue,
    remaining,
    required,
    type,
    ...rest
  } = consent;

  const isSigned = status === 'completed';
  const isRejected = status === 'rejected';
  const isArchived = status === 'archived';
  const isExpired = status === 'expired';
  const isCompleted = status === 'completed';
  const showRemainingLabel = !isOverdue && !isCompleted;
  const isMandatory = mapTaskRequiredStatus(required);

  // const isDocusign = submission_type === 'docusign';
  // const handleRejectConsent = (e) => {
  //   e.stopPropagation();
  //   onRejectConsent(consent_version_id);
  // };

  const handleSignConsent = () => {
    setConsent(consent);
  };

  return (
    <Card showBorder='true'>
      <Stack>
        <Main
          title={title}
          description={description}
          isSigned={isSigned}
          isMandatory={isMandatory}
          isArchived={isArchived}
          isRejected={isRejected}
          isExpired={isExpired}
          consentVersion={consentVersion}
          consentVersionDate={consentVersionDate}
          showRemainingLabel={showRemainingLabel}
          remaining={remaining?.label}
          handleSignConsent={handleSignConsent}
          rendererPage={rendererPage}
          type={type}
          isLar={isLar}
          name={name}
          {...rest}
        />
      </Stack>
    </Card>
  );
};
