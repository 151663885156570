import { styled } from '@mui/system';
import { Box, Menu } from '@mui/material';

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: '220px',
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.gray15}`,
    boxSizing: 'border-box',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px'
  }
}));

export const MenuIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18px',
  height: '18px',
  color: theme.palette.primary.gray50,
  padding: 0,
}));
