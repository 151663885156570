import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import { SectionTitle } from 'components/InformedConsent';
import { Logo } from 'components/shared/Logo';
import { useTheme } from '@mui/material/styles';
import getStyles from './LeftContainer.styles';

function LeftContainer({
  size,
  title,
  description,
  hideGreet,
  hideDescription,
}) {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <Grid item xl={size ? size : 6} lg={6} md={6} sx={styles.leftBox}>
      <Logo style={styles.logo} />
      <Box sx={styles.leftContainer} component='div'>
        {hideGreet ? null : <SectionTitle>Hello!</SectionTitle>}
        <Typography variant='h2' margin='15px 0px'>
          {title || 'Log in'}
        </Typography>
        <Typography variant='pl1' color='primary.gray75'>
          {hideDescription ? null : (
            <>
              {description ||
                'Please use your google email to log in to your account'}
            </>
          )}
        </Typography>
      </Box>
    </Grid>
  );
}

LeftContainer.propTypes = {
  size: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  hideGreet: PropTypes.bool,
};

export default LeftContainer;
