import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/system';

export const Description = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.secondary.gray4,
}));

export const Reward = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.primary.gray100,
  textAlign: 'right',
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.palette.primary.darkGray50,
  fontSize: 32,
  fontWeight: 600,
}));

export const Username = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '145%',
  color: theme.palette.primary.gray100,
}));

export const HeartBeatCount = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '145%',
  color: theme.palette.primary.fuchsia75,
}));

export const ProfileHeaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '25px',
});

export const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '15px',
});
export const DescriptionWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '5px',
});

export const LeadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '140%',
  textAlign: 'left',
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  textAlign: 'left',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
}));

export const StudyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  textAlign: 'left',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
}));

export const Switchprofile = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '24px',
  color: theme.palette.primary.main,
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const HorizontalDivider = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.lightGray100}`,
}));
