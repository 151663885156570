import { Box, styled } from '@mui/material';
import { isMobile } from 'utils/isMobile';

export const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '16px',
  padding: isMobile() ? '20px' : '40px',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'center',
  },
}));
