import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Content, StyledNavigator } from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useDispatch, useSelector} from "ihp-bloom-redux/app/redux";
import {setNavbarExpanded} from "ihp-bloom-redux/features/uiComponents/navbarSlice";

const OnlyDesktop = () => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const navbar = useSelector((state) => state.navbar);

  useEffect(() => {
    setIsExpanded(navbar.isNavbarExpanded);
  }, [navbar.isNavbarExpanded]);

  return (
    <Container>
      <Content>
        <Outlet />
      </Content>
      {isDesktop && <StyledNavigator
        isExpanded={isExpanded}
        toggleMenu={() => dispatch(setNavbarExpanded(!isExpanded))}
      />
      }
    </Container>
  );
};

export default OnlyDesktop;
