import {
  useUpdatePersonStudyArmMutation,
} from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import {
  useUpdateAccountProfileMutation,
} from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

export function useRejectConsent() {
  const [updatePersonStudyArm, { isLoading: isLoadingUpdatePersonStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const [createPersonStudyArmAttribute, { isLoading: isLoadingCreatePersonStudyArmAttribute }] =
    useCreatePersonStudyArmAttributeMutation();
  const [updateAccountProfile, { isLoading: isLoadingUpdateAccountProfile }] =
    useUpdateAccountProfileMutation();

  const createPersonStudyArmAttributes = (
    accountPersonStudyArmId,
    values
  ) => {
    const payload = {
      data: {
        type: 'person-study-arm-attributes',
        attributes: {
          attribute: 'consent_denial_reason',
          value: values,
        },
      },
    };
    return createPersonStudyArmAttribute({
      personStudyArmId: accountPersonStudyArmId,
      payload,
    });
  };

  const updatePersonStudyArmRecord = (
    personStudyArmId,
    studyArmStatusId,
  ) => {
    const payload = {
      data: {
        type: 'person-study-arms',
        id: String(personStudyArmId),
        attributes: {
          study_arm_status_id: studyArmStatusId,
        },
      },
    };

    return updatePersonStudyArm({
      personStudyArmId: personStudyArmId,
      payload,
    });
  };


  const updateAccountProfileRecord = (
    accountProfileId,
    accountPersonStudyArmId,
    subjectPrimaryPersonStudyArmId,
    subjectSecondaryPersonStudyArmId,
  ) => {
    const payload = {
      data: {
        type: 'account-profiles',
        id: `${accountProfileId}`,
        attributes: {
          account_person_study_arm_id: accountPersonStudyArmId,
          subject_primary_person_study_arm_id: subjectPrimaryPersonStudyArmId,
          subject_secondary_person_study_arm_id: subjectSecondaryPersonStudyArmId,

        },
      },
    };
    return updateAccountProfile({
      accountProfileId,
      payload,
    });
  };


  return {
    createPersonStudyArmAttributes,
    updatePersonStudyArmRecord,
    updateAccountProfileRecord,
    isLoading:
      isLoadingCreatePersonStudyArmAttribute ||
      isLoadingUpdatePersonStudyArm ||
      isLoadingUpdateAccountProfile
  };
}
