import { useCallback, useMemo } from 'react';

import { useSelector } from 'ihp-bloom-redux/app/redux';

const createArrayWithCode = (object) => {
  const result = [];
  for (const [code, item] of Object.entries(object)) {
    result.push({
      ...item,
      code,
    });
  }
  return result;
};

export const useConfiguration = () => {
  const { configuration } = useSelector((state) => state.configuration);

  const {
    'study-arms': studyArms,
    'study-arm-statuses': studyArmStatuses,
    'study-arm-roles': studyArmRoles,
  } = configuration;

  const studyArmsArray = useMemo(
    () => createArrayWithCode(studyArms),
    [studyArms]
  );
  const studyArmStatusesArray = useMemo(
    () => createArrayWithCode(studyArmStatuses),
    [studyArmStatuses]
  );
  const studyArmRolesArray = useMemo(
    () => createArrayWithCode(studyArmRoles),
    [studyArmRoles]
  );

  const getResearchStudyArm = useCallback(
    () => studyArms['research'],
    [studyArms]
  );
  const getContactStudyArm = useCallback(
    () => studyArms['contact'],
    [studyArms]
  );

  const getStudyArmRoleByCode = useCallback(
    (studyArmRoleCode) =>
      studyArmRolesArray.find((role) => role.code === studyArmRoleCode),
    [studyArmRolesArray]
  );

  const getStudyArmStatusByCode = useCallback(
    (studyArmStatusCode) =>
      studyArmStatusesArray.find(
        (status) => status.code === studyArmStatusCode
      ),
    [studyArmStatusesArray]
  );

  return {
    studyArms: studyArmsArray,
    studyArmStatuses: studyArmStatusesArray,
    studyArmRoles: studyArmRolesArray,
    getResearchStudyArm,
    getContactStudyArm,
    getStudyArmRoleByCode,
    getStudyArmStatusByCode,
  };
};
