import { Box, Typography, styled } from '@mui/material';
import { RadioLabel } from 'ihp-components/components/Survey/radio-label';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { isCaregiver } from './helper';

const OptionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0;
  flex: 1;
`;

export const Question = ({ components, onAnswer, answer, isRow }) => {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const roleName =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;

  return (
    <Box flex sx={{ flexDirection: 'column', flex: 1, textAlign: 'left' }}>
      <Typography variant='h6'>{components.label}</Typography>
      <OptionsWrapper sx={isRow ? { flexDirection: 'row' } : {}}>
        {components.values.map((option, i) => (
          <RadioLabel
            isReverse={true}
            key={i}
            onClick={(e) => {
              onAnswer({ [components.id]: option.value });
            }}
            label={
              isCaregiver(roleName) && option.caregiverLabel
                ? option.caregiverLabel
                : option.label
            }
            value={option.value}
            checked={answer === option.value}
          />
        ))}
      </OptionsWrapper>
    </Box>
  );
};
