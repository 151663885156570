import React from 'react';
import PropTypes from 'prop-types';

import { CardItem } from '.';
import { ParsedInput } from './utils';
import { ControllerForm } from 'configUI/components/Shared/ControllerForm';

export const FormCardItem = (props) => {
  const { formDataItem, ...rest } = props;
  const { header, subHeader, type, content } = formDataItem;
  return (
    <CardItem header={header} subHeader={subHeader} {...rest}>
      <ControllerForm name={content.name} required={false}>
        <ParsedInput type={type} content={content} />
      </ControllerForm>
    </CardItem>
  );
};

export const formDataItemShape = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  header: PropTypes.string,
  subHeader: PropTypes.string,
  type: PropTypes.oneOf(['text', 'textArea', 'select', 'radio', 'datePicker'])
    .isRequired,
  content: PropTypes.object.isRequired,
};

FormCardItem.propTypes = {
  formDataItem: PropTypes.exact(formDataItemShape),
};
