import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  activities: {
    startDate: '',
    endDate: '',
    selectedTypes: ['show_all']
  },
  rewards: {
    startDate: '',
    endDate: '',
    balances: ['show_all'],
    selectedTypes: ['show_all']
  },
  externalResearchOpportunities: {
    sponsors: ['show_all'],
    followUpStatuses: ['show_all'],
    researchOpportunities: {
      personStudyArm: null,
      suggestedToMe: true,
    }
  }
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setActivities: (state, action) => {
      const { payload } = action;
      Object.assign(state,  { activities: payload });
    },
    setRewards: (state, action) => {
      const { payload } = action;
      Object.assign(state,  { rewards: payload });
    },
    setExternalResearchOpportunities: (state, action) => {
      const { payload } = action;
      Object.assign(state,  { externalResearchOpportunities: payload });
    },
  },
});

export const {
  setActivities,
  setRewards,
  setExternalResearchOpportunities
} = filtersSlice.actions;

export default filtersSlice.reducer;
