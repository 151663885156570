import React from 'react';
import { Typography } from '@mui/material';

export const Title = (props) => (
  <Typography
    color='secondary.gray4'
    fontWeight={600}
    lineHeight='33.6px'
    marginTop='15px'
    fontSize={24}
    fontFamily={'Inter'}
    align='center'
    {...props}
  />
);

export const Text = (props) => (
  <Typography
    color='primary.darkGray70'
    fontSize='14px'
    textAlign='center'
    {...props}
  />
);
