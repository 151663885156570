import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const FilledButton = styled(Button)(
  ({
    theme,
    background,
    fontFamily,
    height,
    width,
    disabled,
    fontColor,
    borderColor,
  }) => {
    const optionalWidth = width ? { width } : {};
    let border = {};
    if (borderColor != null) {
      border = '1px solid ' + borderColor;
    }
    return {
      background: background ?? theme.palette.primary.fuchsia75,
      color: fontColor ?? theme.palette.primary.white,
      border: border,
      borderRadius: '8px',
      height: height ?? '56px',
      ...optionalWidth,
      fontFamily: fontFamily ?? 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      textTransform: 'none',
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed',
      },
      '&: hover': {
        background: background ?? theme.palette.primary.fuchsia75,
      },
      '&: disabled': {
        background: background ?? theme.palette.primary.fuchsia50,
        color: theme.palette.primary.white,
      },
    };
  }
);
