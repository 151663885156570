import Drawer from 'components/Drawer';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckIcon } from 'images/shared/check-white-2.svg';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as XIcon } from 'images/shared/x.svg';

import { checkboxContainer, FilterContainer, ScrolledBox, StyledInputLabel, getStyles } from './style';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  initialState,
} from 'ihp-bloom-redux/features/filters/filtersSlice';
import { format, isValid } from 'date-fns';
import Button from 'ihp-components/components/v2/Button';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';
import { useGetStudySponsorsQuery } from 'ihp-bloom-redux/features/study/studyApiV3';
import researchOpportunityStatuses from '../../constants/researchOpportunityStatus';
import { Loader } from '../../configUI/components/Shared/Loader';
import { PAGE_LIMIT } from '../../utils/constants';
import { useTheme } from '@mui/material/styles';

const Filter = ({ open, onClose, anchor, type, onSubmit }) => {
  const pageLimit = PAGE_LIMIT;
  const filters = useSelector((state) => state.filters);
  const actionData = filters[type];
  const theme = useTheme();
  const styles = getStyles(theme);

  const [filtersData, setFiltersData] = useState({ ...actionData });
  const {activeAccountProfile} = useSelector((state) => state.user);
  const personStudyArm = activeAccountProfile?.attributes?.profile?.subject_primary_person_study_arm_id;

  const {data: sponsors, isLoading: isLoadingSponsors} = useGetStudySponsorsQuery({
    'page[limit]': pageLimit,
  });
  useEffect(() => {
    setFiltersData({
      ...filtersData,
      researchOpportunities: {
        personStudyArm: `${personStudyArm}`,
        suggestedToMe: true
      },
    });
  }, [personStudyArm]);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: { ...filtersData },
  });
  const handleApplyFilter = () => {
    onSubmit(filtersData);
    reset();
    onClose();
  };

  const handleOnFiltersChange =
    (field, multipleSelect = true) =>
      (e) => {
        const value = e.target.value;
        if (field === 'researchOpportunities') {
          if (value === 'show_all') {
            setFiltersData({
              ...filtersData,
              [field]: {
                personStudyArm: `${personStudyArm}`,
                suggestedToMe: false
              },
            });
          } else if (value === `${personStudyArm}`) {
            setFiltersData({
              ...filtersData,
              [field]: {
                personStudyArm: `${personStudyArm}`,
                suggestedToMe: true
              },
            });
          }
          return;
        }
        let selected = [...filtersData[field]];
        if (value === 'show_all') {
          selected = ['show_all'];

          setFiltersData({
            ...filtersData,
            [field]: selected,
          });
          return;
        }

        if (!multipleSelect) {
          selected = selected.filter((item) => item === value);
          setFiltersData({
            ...filtersData,
            [field]: !selected.length ? [value] : ['show_all'],
          });
          return;
        }

        // remove show_all from array as one of other options is selected
        selected = selected.filter((item) => item !== 'show_all');

        if (selected.indexOf(value) !== -1) {
          selected = selected.filter((item) => item !== value);
          setFiltersData({
            ...filtersData,
            [field]: selected.length ? selected : ['show_all'],
          });
        } else {
          setFiltersData({
            ...filtersData,
            [field]: [value, ...selected],
          });
        }
      };

  const clearAll = () => {
    setFiltersData({
      ...initialState[type],
      researchOpportunities: {
        personStudyArm: `${personStudyArm}`,
        suggestedToMe: true
      }
    });
  };

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <form style={{ height: '100%' }}>
        <Box sx={styles.filterContainer}>
          <Box sx={styles.header}>
            <Typography variant='h2'>Filters</Typography>
            <IconButton onClick={onClose}>
              <XIcon color='#959CA0' />
            </IconButton>
          </Box>
          <Stack sx={{ flex: 1}}>
            <StyledInputLabel>SPONSOR</StyledInputLabel>
            {
              isLoadingSponsors &&
              <Loader infoMessage='Loading Sponsors' />
            }
            {
              !isLoadingSponsors &&
              <ScrolledBox sx={styles.marginBottom30}>
                <FormCheckbox
                  containerStyle={checkboxContainer}
                  value='show_all'
                  label='All'
                  checked={filtersData?.sponsors?.indexOf('show_all') !== -1}
                  onChange={handleOnFiltersChange('sponsors')}
                />
                {
                  sponsors?.data?.map(({ id, attributes }) => (
                    <FormCheckbox
                      key={id+'_'+attributes?.name}
                      value={attributes?.name}
                      containerStyle={checkboxContainer}
                      label={attributes?.name}
                      checked={filtersData?.sponsors?.indexOf(attributes?.name) !== -1}
                      onChange={handleOnFiltersChange('sponsors')}
                    />
                  ))
                }
              </ScrolledBox>
            }

            <StyledInputLabel>FOLLOW-UP STATUS</StyledInputLabel>
            <Box sx={styles.marginBottom30}>
              <FormCheckbox
                containerStyle={checkboxContainer}
                value='show_all'
                label='All'
                checked={filtersData?.followUpStatuses?.indexOf('show_all') !== -1}
                onChange={handleOnFiltersChange('followUpStatuses')}
              />
              {
                Object.keys(researchOpportunityStatuses).map((key) => {
                  const status = researchOpportunityStatuses[key];
                  if (!status?.display) return null;
                  return (
                    <FormCheckbox
                      key={status?.key}
                      value={status?.key}
                      containerStyle={checkboxContainer}
                      label={status?.title}
                      checked={filtersData?.followUpStatuses?.indexOf(status?.key) !== -1}
                      onChange={handleOnFiltersChange('followUpStatuses')}
                    />
                  );
                })
              }
            </Box>
            <StyledInputLabel>RESEARCH OPPORTUNITIES</StyledInputLabel>
              <FormCheckbox
                containerStyle={checkboxContainer}
                value='show_all'
                label='All'
                checked={filtersData?.researchOpportunities?.personStudyArm === `${personStudyArm}` &&
                  filtersData?.researchOpportunities?.suggestedToMe === false}
                onChange={handleOnFiltersChange('researchOpportunities')}
              />
            <FormCheckbox
              containerStyle={checkboxContainer}
              value={`${personStudyArm}`}
              label='Suggested for me'
              checked={filtersData?.researchOpportunities?.suggestedToMe === true}
              onChange={handleOnFiltersChange('researchOpportunities')}
            />
          </Stack>

          <Box
            sx={styles.footerStyle}
          >
            <Button sx={styles.px0} variant='link' onClick={clearAll}>
              Clear all
            </Button>
            <Button sx={styles.px0} variant='link' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              height='50px'
              startIcon={<CheckIcon />}
              onClick={handleSubmit(handleApplyFilter)}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  type: PropTypes.oneOf(['activities', 'rewards', 'externalResearchOpportunities']),
};

export default Filter;
