import { Stack } from '@mui/material';
import {
  OnboardingContainer,
  Logo,
  RadioButtonsContainer,
  ContentWrapper,
  HealthProgressBar,
  Question,
  BottomContainer,
  ContinueButton,
  SkipLink,
} from 'components/Onboarding';
import { RadioGroupInput, RadioInput } from 'components/Input';
import { ReactComponent as ArrowForwardIcon } from 'images/shared/arrow-right-2.svg';

export const Questions = ({
  question,
  options,
  progress,
  inputValue,
  onAddAnswer,
  nextStep,
  prevStep,
}) => (
  <OnboardingContainer>
    <Logo />
    <ContentWrapper>
      <Stack>
        <HealthProgressBar variant='determinate' value={progress} />
        <Question>{question}</Question>
        <RadioButtonsContainer>
          <RadioGroupInput
            value={inputValue?.value || ''}
            onChange={(event) => onAddAnswer(event.target.value)}
          >
            {options.map((option) => (
              <RadioInput
                key={option.value}
                label={option.value}
                value={option.value}
              />
            ))}
          </RadioGroupInput>
        </RadioButtonsContainer>
      </Stack>
      <BottomContainer>
        <ContinueButton
          disabled={!inputValue}
          variant='contained'
          onClick={nextStep}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </ContinueButton>
        <SkipLink component='button' underline='none' onClick={prevStep}>
          Back
        </SkipLink>
      </BottomContainer>
    </ContentWrapper>
  </OnboardingContainer>
);
