import PropTypes from 'prop-types';
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import getStyles from './File.styles';
import { formatBytes, isImage } from "./utils";
import { ReactComponent as TimesIcon } from 'images/shared/times.svg';
import { ReactComponent as FileIcon } from 'images/components/file.svg';

export const File = ({ file, index, onRemove }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const handleRemove = () => onRemove(index);

  return (
    <Box sx={styles.fileContainer} component="div">
      <Box sx={styles.img}>
        {
          isImage(file) ? (
            <img
              src={URL.createObjectURL(file)}
              alt="uploaded-img"
              accept="image/png, image/gif, image/jpeg, image/svg+xml, image/webp"
            />
          ) : (
            <FileIcon />
          )
        }
      </Box>
      <Box sx={styles.details}>
        <div>{file.name}</div>
        <Typography variant="pl5" sx={styles.uploadInfoText}>
          {formatBytes(file.size)}
        </Typography>
      </Box>
      <Box sx={styles.removeBtn} onClick={handleRemove}>
        <TimesIcon width={10} height={15} />
      </Box>
    </Box>
  );
};

File.defaultProps = {
  onRemove: () => {}
};

File.propTypes = {
  file: PropTypes.object,
  index: PropTypes.number,
  onRemove: PropTypes.func
};