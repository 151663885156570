import { createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

const initialState = [];

export const slice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    updatePayment: (state, { payload }) => {
      const date = format(new Date(), 'dd / MM / yyyy');
      let paymentToUpdate = state.find((payment) => payment.id === payload);
      if (paymentToUpdate) {
        paymentToUpdate.type = 'done';
        paymentToUpdate.date = date;
      }
    },
  },
});

export const { updatePayment } = slice.actions;

export const selectDonePayments = (state) =>
  Object.entries(state[slice.name])
    .filter(([_, { type }]) => type === 'done')
    // Collect value from each payment
    .map(([_, { value }]) => value)
    // Accumulate value [1, 2, 3, 4] => [1, 3, 6, 10]
    .map(
      (
        (sum) => (value) =>
          (sum += value)
      )(0)
    );

export default slice;
