import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';

export const ActivityDoneContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '50px',
  flex: '0 1 325px',
});

export const RewardBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.green100,
  backgroundColor: theme.palette.primary.green5,
  border: `1px solid ${theme.palette.primary.green25}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '63px',
  width: '100%',
}));

export const RewardPoints = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '700',
  color: theme.palette.primary.green100,
}));

export const StyledFilledButton = styled(Button)({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  fontWeight: 900,
  marginTop: '30px',
});
