import { Box, styled } from '@mui/material';
import { theme } from 'theme';

export const Header = styled(Box)(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: isMobile
    ? theme.palette.primary.white
    : theme.palette.primary.gray20,
}));

export const Content = styled(Box)(({ theme, isMobile }) => ({
  height: '100%',
  backgroundColor: theme.palette.primary.white,
  border: `1px solid ${theme.palette.primary.fuchsia5}`,
  borderRadius: '7px',
  borderTopRightRadius: isMobile ? '7px' : '0px',
  borderTopLeftRadius: isMobile ? '7px' : '0px',
  '& .body': {
    padding: isMobile ? '25px' : '30px 50px 30px',
  },
}));

export const Footer = styled(Box)(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.white,
  padding: isMobile ? '15px 20px' : '0px 50px 30px',
  '> *': {
    flex: '1',
  },
}));

export const ConsentCheckMark = styled(Box)(({ theme, isMobile }) => ({
  borderTop: `1px solid ${theme.palette.primary.pattensBlue}`,
  padding: isMobile ? '20px 0px 25px' : '30px 50px',
  textAlign: 'center',
}));

export const getStyles = () => ({
  checkboxContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '0px',
    '& .MuiCheckbox-root': {
      '& span': {
        borderRadius: '50px',
      },
      '& svg': {
        borderRadius: '50px',
      },
    },
  },
});

export const Stats = styled('div')(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '15px',
  marginLeft: isMobile ? '53px' : '0px',
  paddingBottom: isMobile ? '19px' : '0px',
  backgroundColor: isMobile ? theme.palette.primary.white : 'transparent',
}));

export const StatusChip = styled('div')(({ theme, isRequired, isMobile }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: isMobile ? 500 : 600,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.01em',
  textTransform: 'uppercase',
  color: isRequired
    ? theme.palette.primary.fuchsia75
    : theme.palette.primary.gray75,
  padding: '4px 8px',
  backgroundColor: isRequired
    ? !isMobile
      ? theme.palette.primary.white
      : theme.palette.primary.fuchsia5
    : !isMobile
    ? theme.palette.primary.white
    : theme.palette.primary.gray10,
  border: `${isMobile ? '0px' : '1px'} solid ${
    isRequired ? theme.palette.primary.fuchsia75 : theme.palette.primary.gray75
  }`,
  borderRadius: '4px',

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const SignDate = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '140%',
  color: theme.palette.primary.gray75,

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const Version = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '140%',
  color: theme.palette.primary.gray75,

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const DesktopContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray20,
  paddingBottom: '20px',
}));

export const DesktopPageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 25px 23px 0px',
  h2: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.palette.secondary.gray4,
  },
}));

export const DesktopConsentText = styled('div')(({ theme }) => ({
  width: '700px',
  margin: 'auto',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  marginBottom: '50px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const DesktopConsentTextHeader = styled('div')(({ theme }) => ({
  padding: '50px 50px 30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '10px',
  h2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '135%',
    color: theme.palette.primary.gray100,
    margin: '0px',
  },
  h3: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    color: theme.palette.primary.fuchsia75,
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
    margin: '0px',
  },
}));

export const ContentWrap = styled('pre')(({ theme }) => ({
  fontFamily: 'Inter',
  whiteSpace: 'pre-wrap',
  lineHeight: '140%',
  fontSize: '16px',
}));
