export const getPersonPayloadWithoutDuplicates = (user, studyRoleId) => {
  return {
    data: {
      attributes: {
        sex_at_birth: user.gender || '',
        region: user.postal_code || '',
        gender: user.gender || '',
        state: 'enrollment',
        status: 'onboarded',
        postal_code: user.postal_code || '',
      },
      relationships: {
        study_role: {
          id: studyRoleId,
          type: 'study_role',
        },
        person_attribute: [
          {
            type: 'person_attribute',
            attributes: {
              attribute: 'email',
              value: user.email,
            },
          },
          {
            type: 'person_attribute',
            attributes: {
              attribute: 'first_name',
              value: user.firstName,
            },
          },
          {
            type: 'person_attribute',
            attributes: {
              attribute: 'last_name',
              value: user.lastName,
            },
          },
          {
            type: 'person_attribute',
            attributes: {
              attribute: 'postal_code',
              value: user.postal_code || '',
            },
          },
          {
            type: 'person_attribute',
            attributes: {
              attribute: 'date_of_birth',
              value: user.dob,
            },
          },
        ],
      },
    },
  };
};
