import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const studyApiSliceV3 = createApi({
  reducerPath: 'studyApiV3',
  baseQuery: baseQuery('v3'),
  endpoints: (build) => ({
    getStudyRoles: build.query({
      query: () => ({
        url: `/study-roles?filter[name]=participant`,
        method: 'GET',
        headers: {
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
    getStudyRolesNoFilter: build.query({
      query: () => ({
        url: `/study-roles`,
        method: 'GET',
      }),
      providesTags: ['StudyRoles'],
    }),
    getStudySponsors: build.query({
      query: ({ ...params }) => ({
        url: `/study-sponsors`,
        method: 'GET',
        params
      }),
      providesTags: ['StudySponsors'],
    }),
    getStudyEnumerations: build.query({
      query: (params) => ({
        url: '/attribute-enumerations',
        method: 'GET',
        params,
        headers: {
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
  }),
});

export const {
  useGetStudyRolesQuery,
  useGetStudyRolesNoFilterQuery,
  useGetStudyEnumerationsQuery,
  useGetStudySponsorsQuery,
} = studyApiSliceV3;
