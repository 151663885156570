import { Box, IconButton, styled } from '@mui/material';

export const getStyles = (theme) => {
  return {
    button: {
      width: 171,
      textWrap: 'nowrap',
      height: 46,
      background: theme.palette.primary.white,
      borderRadius: '8px',
      textTransform: 'none',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      border: '1px solid #D9D9D9',
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: '2%',
      lineHeight: '20px',
      color: theme.palette.primary.helpBorderBlue,
      marginLeft: 'auto',
      '&:hover': {
        background: theme.palette.primary.gray3,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '14px',
      },
    },
    desktopButton: {
      height: 46,
      maxWidth: '220px',
      border: `1px solid ${theme.palette.primary.lightGray100}`,
      background: theme.palette.primary.white,
      color: theme.palette.primary.helpBorderBlue,
      marginLeft: 'auto',
      fontSize: '14px',
      bottom: 0,
    },
    mobileButton: {
      marginTop: '10px',
      background: theme.palette.primary.white,
      borderColor: theme.palette.primary.lightGray100,
      height: 46,
      bottom: 0,
    },
    pastConsentBtn: {
      display: 'flex',
      marginTop: '10%',
      padding: '15px 25px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '15px',
    },
    icon: {
      lineHeight: 20,
      marginLeft: 7,
    },
  };
};

export const Card = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme, disabled, showBorder }) => ({
  backgroundColor: showBorder ? '#FCF5FB' : theme.palette.primary.white,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'stretch',
  // maxHeight: '293px',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
  },
  ...(showBorder && { border: `1px solid ${theme.palette.primary.magenta70}` }),
}));

export const Divider = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inverted',
})(({ theme, inverted }) => {
  const border = `1px solid ${theme.palette.primary.gray10}`;
  return {
    [theme.breakpoints.up('sm')]: {
      ...(inverted ? { borderLeft: border } : { borderTop: border }),
    },
    [theme.breakpoints.down('sm')]: {
      ...(inverted ? { borderTop: border } : { borderLeft: border }),
    },
  };
});

export const GoToSignedConsent = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.fuchsia50,
  margin: 'auto 20px auto 65px',
  [theme.breakpoints.down('sm')]: {
    margin: 'auto 15px auto 30px',
  },
}));
