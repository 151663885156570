import * as React from 'react';
import Box from '@mui/material/Box';
import { StudyTabs, StudyTab, StudyTabPanel } from 'components/Study';
import Progress from './progress';
import Timeline from './timeline';
import Overview from './overview';

export default function StudyMobile({
  selectedPanel,
  taskOccurrencies,
  totalTasks,
  tasks,
  isLoadingTasks,
}) {
  const [tab, setTab] = React.useState(selectedPanel);

  const handleChange = (event, newSelectedTab) => {
    setTab(newSelectedTab);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <StudyTabs value={tab} onChange={handleChange} aria-label='study tabs'>
        <StudyTab label='Progress' />
        <StudyTab label='Timeline' />
        <StudyTab label='Overview' />
      </StudyTabs>
      <StudyTabPanel value={tab} index={0}>
        <Progress taskOccurrencies={taskOccurrencies} totalTasks={totalTasks} />
      </StudyTabPanel>
      <StudyTabPanel value={tab} index={1}>
        <Timeline tasks={tasks} isLoadingTasks={isLoadingTasks} />
      </StudyTabPanel>
      <StudyTabPanel value={tab} index={2}>
        <Overview />
      </StudyTabPanel>
    </Box>
  );
}
