import React from 'react';

import { ReactComponent as SurveyBadge } from 'images/profile/achievements/survey.svg';
import { ReactComponent as SurveyFilledBadge } from 'images/profile/achievements/survey-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobileSurveyCompletedCard } from './index.mobile';
import { isMobile } from 'utils/isMobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  MyPoints,
  IsCompletedMark,
} from '../styles';

export const SurveyCompletedCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobileSurveyCompletedCard
        inProgress={inProgress}
        isCompleted={isCompleted}
      />
    );
  }
  return (
    <CardWrapper backgroundColor={isCompleted ? '#F7E4E4' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#EB5757' />}
      {!inProgress ? <SurveyBadge /> : <SurveyFilledBadge />}
      <Info>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 3 Surveys completed
        </Title>
        <StyledSlider
          value={50}
          isCompleted={isCompleted}
          backgroundColor='#EB5757'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Wellness champion'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
