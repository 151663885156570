import { forwardRef } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { useSearchMedicationQuery } from 'ihp-bloom-redux/features/medicationSlice/medicationSlice';

import { StyledPopper } from 'configUI/components/SelectAutoComplete/styles';
import { useDebounce } from 'hooks/useDebounce';
import { ControllerForm } from 'configUI/components/Shared';
import { RecurrenceSelector } from './RecurrenceSelector';
import {
  useDosageUnits,
  useFilterOptions,
} from 'pages/NotificationCenter/MedicationTracker/hooks.js';
import { Input } from 'ihp-components/components/v2/Input';

const MedicationInput = forwardRef(({ onChange, ...rest }, ref) => {
  const { extractDosageUnit } = useDosageUnits();
  const { control, setValue } = useFormContext();
  const drugName = useWatch({ name: 'drug.name', control });

  const debouncedDrugName = useDebounce(drugName, 500);
  const { data } = useSearchMedicationQuery(debouncedDrugName);

  const handleChange = (_, value) => {
    const dosageUnit = extractDosageUnit(value);
    setValue('drug.dosageUnit', dosageUnit);
    onChange(value);
  };

  return (
    <Autocomplete
      ref={ref}
      freeSolo
      onInputChange={handleChange}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.display
      }
      options={data?.expansion.contains || []}
      filterOptions={(x) => x}
      includeInputInList
      renderInput={({ InputProps, inputProps }) => (
        <Box ref={InputProps.ref}>
          <Input
            label='Medication'
            placeholder='Enter medication'
            fullWidth={true}
            {...inputProps}
          />
        </Box>
      )}
      PopperComponent={StyledPopper}
      {...rest}
    />
  );
});

const DosageInput = forwardRef(({ onChange, value, ...rest }, ref) => {
  const { dosageUnits } = useDosageUnits();
  const filterOptions = useFilterOptions();

  const handleChange = (_, value) => onChange(value);

  return (
    <Autocomplete
      ref={ref}
      freeSolo
      filterOptions={(x) => x}
      onInputChange={handleChange}
      // do not use this for filterOptions, it does not work with updated value
      options={filterOptions(dosageUnits, value)}
      includeInputInList
      renderInput={({ InputProps, inputProps }) => (
        <Box ref={InputProps.ref}>
          <Input
            label='Dosage form'
            placeholder='Enter dosage form'
            fullWidth={true}
            {...inputProps}
          />
        </Box>
      )}
      PopperComponent={StyledPopper}
      value={value}
      {...rest}
    />
  );
});

export const MedicationForm = () => (
  <Grid
    container
    p='20px'
    columnSpacing='15px'
    rowSpacing='20px'
    alignItems='flex-end'
  >
    <Grid item xs={12}>
      <ControllerForm name='drug.name' required={true}>
        <MedicationInput />
      </ControllerForm>
    </Grid>
    <Grid item xs={12}>
      <ControllerForm name='drug.dosageUnit' required={true}>
        <DosageInput />
      </ControllerForm>
    </Grid>
    <Grid item xs={12}>
      <ControllerForm name='repeat' required={true}>
        <RecurrenceSelector />
      </ControllerForm>
    </Grid>
  </Grid>
);
