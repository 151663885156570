import { forwardRef, useState } from 'react';
import { StyledFormControl, StyledFormLabel } from '../TextField.styles';
import { StyledTextArea } from './styles';
import { ErrorWrapper } from '../ErrorWrapper';

export const Textarea = forwardRef(
  (
    {
      label,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      placeholder,
      wrapperStyle,
      fullWidth,
      size,
      id,
      error,
      errorLabel,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [isMouseIn, setIsMouseIn] = useState(false);

    return (
      <StyledFormControl fullWidth={fullWidth} size={size}>
        <ErrorWrapper error={error} errorLabel={errorLabel}>
          {label && <StyledFormLabel>{label}</StyledFormLabel>}
          <StyledTextArea
            ref={props.inputRef ?? ref}
            {...props}
            isActive={isActive}
            isMouseIn={isMouseIn}
            hasError={!!error}
            onFocus={() => {
              setIsActive(true);
              onFocus?.(); //for external use same for the rest below
            }}
            onBlur={() => {
              setIsActive(false);
              onBlur?.();
            }}
            onMouseEnter={() => {
              setIsMouseIn(true);
              onMouseEnter?.();
            }}
            onMouseLeave={() => {
              setIsMouseIn(false);
              onMouseLeave?.();
            }}
            minRows={6}
            placeholder={placeholder || 'Enter text...'}
          />
        </ErrorWrapper>
      </StyledFormControl>
    );
  }
);
