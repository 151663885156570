import { Box, styled, Typography } from '@mui/material';
import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';
import { LinkButton } from 'components/Buttons';

export const ConsentDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: 'calc(100vh - 80px)',
  padding: '40px',
  overflow: 'hidden',
  boxSizing: 'borderBox',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '20px',
    height: 'calc(100vh - 40px)',
  },
}));

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '700px',
  height: '100%',
  overflow: 'auto',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {},
}));

export const Header = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.primary.pattensBlue}`,
  padding: '60px 40px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 22px',
  },
}));

export const Body = styled('div')(({ theme }) => ({
  padding: '30px 40px 40px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px 22px',
  },
}));

export const AcmeLogo = styled(Logo)(({ theme }) => ({
  width: '102px',
  height: '70px',
  [theme.breakpoints.down('sm')]: {
    width: '84px',
    height: '58px',
  },
}));

export const Row = styled('div')(({ theme, marginBottom }) => ({
  display: 'block',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    fontWeight: '600',
  },
}));

export const PageSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.fuchsia75,
  fontSize: '14px',
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray75,
}));

export const Stats = styled('div')(({ theme, isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  marginLeft: isMobile ? '53px' : '0px',
  paddingBottom: isMobile ? '19px' : '0px',
  backgroundColor: isMobile ? theme.palette.primary.white : 'transparent',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  },
}));

export const StatusChip = styled('div')(({ theme, isRequired, isMobile }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: isMobile ? 500 : 600,
  fontSize: '10px',
  lineHeight: '140%',
  letterSpacing: '0.01em',
  textTransform: 'uppercase',
  color: isRequired
    ? theme.palette.primary.fuchsia75
    : theme.palette.primary.gray75,
  padding: '4px 8px',
  backgroundColor: isRequired
    ? !isMobile
      ? theme.palette.primary.white
      : theme.palette.primary.fuchsia5
    : !isMobile
    ? theme.palette.primary.white
    : theme.palette.primary.gray10,
  border: `${isMobile ? '0px' : '1px'} solid ${
    isRequired ? theme.palette.primary.fuchsia75 : theme.palette.primary.gray75
  }`,
  borderRadius: '4px',

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const SignDate = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '140%',
  color: theme.palette.primary.gray75,

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const Version = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '140%',
  color: theme.palette.primary.gray75,

  display: 'flex',
  alignItems: 'center',

  '& svg': {
    marginRight: '5px',
  },
}));

export const ConsentCheckMark = styled(Box)(({ theme, isMobile }) => ({
  borderTop: `1px solid ${theme.palette.primary.pattensBlue}`,
  padding: isMobile ? '20px 0px 25px' : '30px 50px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '30px 0px',
    marginBottom: '60px',
    marginTop: '15px',
  },
}));

export const Footer = styled(Box)(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.white,
  padding: isMobile ? '15px 20px' : '0px 50px 30px',
  '> *': {
    flex: '1',
  },
  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '15px 20px',
  },
}));

export const MobileBackArrow = styled(LinkButton)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '20px',
  svg: {
    width: '18px',
    height: '20px',
    color: theme.palette.primary.fuchsia75,
  },
}));
