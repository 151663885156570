import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';
import { queryString } from '../utils/objectToQueryParams';

export const participantTaskApiSlice = createApi({
  reducerPath: 'participantTaskApiV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['ParticipantTasks'],
  endpoints: (build) => ({
    getAllProfileParticipantTasks: build.query({
      query: ({ accountProfileId, query }) => ({
        url: `/app/profiles/${accountProfileId}/participant-tasks?${queryString(
          query
        )}`,
        method: 'GET',
      }),
      providesTags: ['ParticipantTasks'],
    }),
    getAllParticipantTasks: build.query({
      query: ({ participantId, query }) => ({
        url: `/app/people/${participantId}/participant-tasks?${queryString(
          query
        )}`,
        method: 'GET',
      }),
      providesTags: ['ParticipantTasks'],
    }),
    getParticipantTask: build.query({
      query: ({ participantId, participantTaskId, ...params }) => ({
        url: `/people/${participantId}/participant-tasks/${participantTaskId}?include=task,last-participant-task-entry`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { participantTaskId }) => [
        { type: 'ParticipantTasks', id: participantTaskId },
      ],
    }),
    getProfileParticipantTasks: build.query({
      query: ({ accountProfileId, params }) => ({
        url: `app/profiles/${accountProfileId}/participant-tasks`,
        method: 'GET',
        params,
      }),
    }),
    getProfileParticipantTask: build.query({
      query: ({ accountProfileId, participantTaskId, params }) => ({
        url: `app/profiles/${accountProfileId}/participant-tasks/${participantTaskId}`,
        method: 'GET',
        params,
      }),
    }),
    getAllTasks: build.query({
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const { personId, query } = params;

        const current = await fetchWithBQ(
          `/app/people/${personId}/participant-tasks?${queryString(query)}`,
          {
            method: 'GET',
          }
        );

        const upcoming = await fetchWithBQ(
          `/app/people/${personId}/participant-tasks?${queryString({
            assignee: query.studyRoleId,
            display_status: 'upcoming',
          })}`,
          {
            method: 'GET',
          }
        );

        const past = await fetchWithBQ(
          `/app/people/${personId}/participant-tasks?${queryString({
            assignee: query.studyRoleId,
            display_status: 'past',
          })}`,
          {
            method: 'GET',
          }
        );

        if (current.error) return { error: current.error };
        if (upcoming.error) return { error: upcoming.error };
        if (past.error) return { error: past.error };

        let sortedCurrent = Object.assign([], current.data);
        const currentTypes = [
          'data_sharing',
          'survey',
          'call',
          'call_schedule',
          'profile',
        ];

        let currentTasks = [];

        sortedCurrent.data.filter((task) => {
          if (currentTypes.includes(task.data.task_type)) {
            currentTasks.push(task);
          }
        });

        currentTasks = currentTasks.sort((a, b) => {
          currentTasks.type = 'current';
          const dueDateA = new Date(a.data.due_date);
          const dueDateB = new Date(b.data.due_date);
          return dueDateA - dueDateB;
        });

        return {
          data: {
            current: currentTasks,
            upcoming: upcoming.data,
            past: past.data,
          },
        };
      },
    }),
    updateParticipantTask: build.mutation({
      query: ({ participantTaskId, payload }) => ({
        url: `/participant-tasks/${participantTaskId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
    getParticipantTaskWithRelationship: build.query({
      query: ({ participantId, participantTaskId, ...params }) => ({
        url: `/people/${participantId}/participant-tasks/${participantTaskId}`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, { participantTaskId }) => [
        { type: 'ParticipantTasks', id: participantTaskId },
      ],
    }),
    uploadFile: build.mutation({
      query: ({ participantTaskId, payload }) => ({
        url: `/participant-tasks/${participantTaskId}/documents`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ParticipantTasks'],
    }),
  }),
});

export const {
  useGetAllParticipantTasksQuery,
  useGetAllProfileParticipantTasksQuery,
  useGetAllTasksQuery,
  useGetParticipantTaskQuery,
  useGetProfileParticipantTasksQuery,
  useGetProfileParticipantTaskQuery,
  useGetParticipantTaskWithRelationshipQuery,
  useUpdateParticipantTaskMutation,
  useUploadFileMutation,
} = participantTaskApiSlice;
