import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const educationCategoriesApiSlice = createApi({
  reducerPath: 'educationCategoriesApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['YourInterestsList'],
  endpoints: (build) => ({
    createYourInterests: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/people/${personId}/education-categories`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['YourInterestsList'],
    }),
    getYourInterests: build.query({
      query: ({ personId, params }) => ({
        url: `/people/${personId}/education-categories`,
        method: 'GET',
        params,
      }),
      providesTags: ['YourInterestsList'],
    }),
  }),
});

export const { useCreateYourInterestsMutation, useGetYourInterestsQuery } =
  educationCategoriesApiSlice;
