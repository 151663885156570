import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import { LinkButton } from 'components/Buttons';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { getRewardsInfo } from 'components/Payment/Rewards/util';
import { getMyProfileReasons } from 'pages/Profile/MyProfile/utils';
import { findStudyAttribute } from 'utils/study';
import {
  ModalContainer,
  ModalContent,
  ReasonsContainer,
  GoalsWrapper,
  ModalFooter,
  Divider,
  RadioInputGroup,
  SubmitBtn,
  Loader,
} from './styles';
import { ReactComponent as AcmeLogo } from 'images/av-logo-vertical.svg';
import { ReactComponent as QuestionMark } from 'images/shared/question-mark-sm.svg';
import { StyledTooltip } from 'components/Payment/Score.styles';

const RejectConsentModal = ({
  person,
  isOpen,
  backToConsent,
  onClose,
  isSubmitting,
  onSubmit,
}) => {
  const [selectedReason, setSelectedReason] = useState(null);
  const consentData = useSelector((state) => state.consentSlice);
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const [step, setStep] = useState(1);
  const theme = useTheme();

  const payoutMethod =
    findStudyAttribute(studyConfiguration || [], 'payout_method') || [];
  const totalRewardAmount =
    findStudyAttribute(studyConfiguration || [], 'total_reward_amount') || 1000;
  const withdrawalReasons = findStudyAttribute(
    studyConfiguration || [],
    'withdrawal_reasons'
  );
  const rejectConsentReasons = getMyProfileReasons(
    withdrawalReasons,
    'reject-consent'
  );

  const { data: goalData, isLoading: loadingGoalStats } =
    useGetParticipantGoalStatsQuery(person?.id, {
      skip: person?.id ? false : true,
    });

  const thresholdMinAmount = payoutMethod?.[0]?.threshold?.min || 0;
  const thresholdIncrement = payoutMethod?.[0]?.threshold?.increment || 1;
  const rewardsData = getRewardsInfo(goalData?.data);
  const availablePoints = rewardsData?.balance || 0;
  let redeemablePoints =
    Math.floor(availablePoints / thresholdIncrement) * thresholdIncrement;
  if (redeemablePoints - thresholdMinAmount < 0) {
    redeemablePoints = 0;
  }

  const goalsContent = [
    {
      name: 'Points Available',
      value: availablePoints,
      showInfo: false,
      description: '',
    },
    {
      name: 'Points Available to Redeeem',
      value: redeemablePoints,
      showInfo: true,
      description:
        'The amount of unredeemed points that you still have available to redeem.',
    },

    {
      name: 'Total Points Earned-to-Date',
      value: rewardsData?.total_no_points,
      showInfo: true,
      description:
        'The amount of points that you have earned by completing activities in the study.',
    },
    {
      name: 'Future Rewards Opportunity',
      value: `$${totalRewardAmount - (rewardsData?.converted_amout || 0)}`,
      showInfo: true,
      description:
        'The rewards value of points you could earn by participating in the entire study.',
    },
  ];

  const onDecline = async () => {
    if (step === 1) {
      setStep((prev) => prev + 1);
    } else {
      await onSubmit(selectedReason);
      onClose();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContainer
        sx={{
          backgroundColor: (theme) => theme.palette.primary.white,
          maxWidth: step === 1 ? '600px' : '380px',
        }}
      >
        <>
          {step === 1 && (
            <ModalContent>
              <Typography
                textAlign='center'
                variant='h2'
                color='#444444'
                mt={4}
              >
                Are you sure you want to decline this consent?
              </Typography>
              <Typography
                textAlign='center'
                variant='body2'
                mt={2}
                color='#7C7C7C'
              >
                If the participant leaves now, they will forfeit any points that
                are not redeemable yet and any future rewards opportunities.
              </Typography>
              {loadingGoalStats ? (
                <Box display='flex' justifyContent='center' my={3}>
                  <Loader />
                </Box>
              ) : (
                <GoalsWrapper>
                  {goalsContent.map((goal) => (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Typography
                        variant='body2'
                        display='flex'
                        alignItems='center'
                        gap='5px'
                      >
                        {goal.name}{' '}
                        {goal.showInfo && (
                          <StyledTooltip title={goal.description} arrow={true}>
                            <QuestionMark />
                          </StyledTooltip>
                        )}
                      </Typography>
                      <Typography variant='h3'>{goal.value}</Typography>
                    </Box>
                  ))}
                  <Divider backgroundColor='#E5EEF3' />
                  <Typography variant='body2' color='#7C7C7C' fontSize='12px'>
                    The participant will receive an email with instructions to
                    claim the rewards later and another email to download your
                    journal entries.
                  </Typography>
                </GoalsWrapper>
              )}
              <Typography
                textAlign='center'
                variant='body2'
                mt={2}
                color='#7C7C7C'
              >
                Your participation is helping to move healthcare forward <br />
                <br />
                If you would like to decline this consent, click the button
                below
              </Typography>
            </ModalContent>
          )}

          {step === 2 && (
            <>
              <Box justifyContent='center' pt={6}>
                <AcmeLogo width={100} height={69} />
              </Box>
              <ModalContent>
                <Typography textAlign='center' variant='h2' color='#444444'>
                  Provide a reason for declining consent
                </Typography>
                <Typography
                  textAlign='center'
                  variant='body2'
                  mt={2}
                  color='#7C7C7C'
                >
                  You have been withdrawn from consideration for this study. If
                  you have any questions about your withdrawal, please contact
                  our support line at:
                </Typography>
                <ReasonsContainer>
                  <RadioInputGroup
                    id='reason'
                    name='reason'
                    value={selectedReason}
                    onChange={(e) => setSelectedReason(e.target.value)}
                  >
                    {rejectConsentReasons.map((item) => (
                      <RadioInput
                        hideRadioIcon={false}
                        value={item.label}
                        label={item.label}
                        key={item.value}
                      />
                    ))}
                  </RadioInputGroup>
                </ReasonsContainer>
              </ModalContent>
            </>
          )}

          <ModalFooter>
            <SubmitBtn
              onClick={() => backToConsent(consentData?.lastOpenedConsent)}
              disabled={loadingGoalStats}
              width='100%'
            >
              {step < 3 ? 'Back to consent' : 'Sign in'}
            </SubmitBtn>
            {step < 3 && (
              <LinkButton
                onClick={onDecline}
                disabled={isSubmitting || loadingGoalStats}
                color={theme.palette.primary.deepBlue}
                width='100%'
              >
                Decline, I do not wish to participate
              </LinkButton>
            )}
          </ModalFooter>
        </>
      </ModalContainer>
    </Modal>
  );
};

RejectConsentModal.propTypes = {
  person: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  backToConsent: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default RejectConsentModal;
