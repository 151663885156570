import { TextButton } from './TextButton';

export const FilledButton = ({ children, ...rest }) => {
  return <TextButton variant='contained' {...rest}>{children}</TextButton>;
};

/*
  Example Usage

  <Button
    color="primary"
    size="large"
  >
    Button
  </Button>

  <Button
    color="primary"
    size="large"
    endIcon={<ArrowForwardIcon />}
  >
    Button
  </Button>

  <Button
    color="primary"
    size="large"
    startIcon={<ArrowForwardIcon />}
  >
    Button
  </Button>

  color: primary | secondary | tertiary
*/