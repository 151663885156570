import React, { useCallback, useState, useEffect } from 'react';
import { InputAdornment, useTheme } from '@mui/material';

import { useGetFilteredResearchOpportunitiesQuery } from 'ihp-bloom-redux/features/researchOpportunities/researchOpportunitiesApiSlice';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import ExternalResearchOpportunityList from 'components/ResearchOpportunity/List';
import Filter from './Filter';
import { Loader } from 'components/Loader';
import { useDebounce } from '../../hooks/useDebounce';
import { EmptyList } from './Empty';
import {
  Container,
  Header,
  Title,
  SearchWrapper,
  SearchInput,
  LoadMoreButton,
  FilterButton,
  FilterMobileButton,
} from './style';
import { FiltersToggleButton } from '../../components/FiltersToggleButton';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';

export const ResearchOpportunities = () => {
  const theme = useTheme();
  const [openFilters, setOpenFilters] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [opportunities, setOpportunites] = useState([]);

  const toggleFilters = useCallback(() => {
    setOpenFilters(!openFilters);
  }, [openFilters]);

  const { activeAccountProfile } = useSelector((state) => state.user);
  const personStudyArm =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_study_arm_id;
  const [filterData, setFilterData] = useState({});
  const [skip, setSkip] = useState(true);
  const debouncedSearch = useDebounce(searchTerm, 500);

  const loadMore = useCallback(() => {
    setPageNum((prev) => prev + 1);
  }, [setPageNum]);

  useEffect(() => {
    if (debouncedSearch && debouncedSearch.length < 3) {
      return;
    }
    setPageNum(1);
    const params = {
      ...filterData,
      sort: '-createdAt',
      'page[limit]': 10,
      'filter[person-study-arm]': personStudyArm,
      include: 'study_sponsor,person_study_arm_research_opportunities',
    };
    params['filter[suggested-to-me]'] =
      filterData?.['filter[suggested-to-me]'] !== false;
    if (!debouncedSearch) {
      delete params['filter[title]'];
      setFilterData({
        ...params,
      });
      setSkip(false);
      return;
    }
    setFilterData({
      ...params,
      ...(debouncedSearch && { 'filter[title]': debouncedSearch }),
    });
    setSkip(false);
  }, [personStudyArm, debouncedSearch]);

  const handleSearch = (value) => {
    if (value.length > 2) {
      setPageNum(1);
    }
    setSearchTerm(value);
  };

  const { data, isLoading, items, totalPages, perPage } =
    useGetFilteredResearchOpportunitiesQuery(
      {
        ...filterData,
        'page[page]': pageNum,
      },
      {
        skip: skip,
        refetchOnMountOrArgChange: true,
        selectFromResult: ({
          data,
          isLoading,
          isUninitialized,
          isFetching,
          error,
        }) => {
          return {
            data: data,
            totalPages: data?.meta?.page?.lastPage,
            perPage: data?.meta?.page?.perPage,
            error: error,
            isLoading: isLoading || isUninitialized || isFetching,
            items: data?.data?.map((item) => {
              return {
                id: item?.id,
                title: item?.attributes['title'],
                subtitle: item?.attributes['summary'],
                description: item?.attributes['description'],
                status: item?.attributes['status'],
                study_sponsor_id: item?.attributes['study_sponsor_id'],
                research_opportunity_id:
                  item?.attributes['research_opportunity_id'],
                ...(() => {
                  let includes = {
                    study_sponsors_name: '',
                    person_study_arm_research_opportunity: null,
                  };
                  for (let i = 0; i < data?.included?.length; i++) {
                    const element = data?.included[i];
                    if (+element?.id === item?.attributes['study_sponsor_id']) {
                      includes['study_sponsors_name'] =
                        element?.attributes?.name;
                    }
                    if (
                      element?.attributes['research_opportunity_id'] ===
                      +item?.id
                    ) {
                      includes['person_study_arm_research_opportunity'] =
                        +element?.id;
                    }
                  }
                  return includes;
                })(),
              };
            }),
          };
        },
      }
    );

  useEffect(() => {
    if (data?.data?.length > 0) {
      if (pageNum > 1) {
        setOpportunites((prevData) => [...prevData, ...items]);
      } else {
        setOpportunites(items);
      }
    } else {
      setOpportunites([]);
    }
  }, [data]);

  const handleFilterSubmit = (data) => {
    const params = {
      sort: '-createdAt',
      'page[limit]': 10,
      ...(debouncedSearch &&
        debouncedSearch.length > 2 && { 'filter[title]': debouncedSearch }),
    };
    params['filter[person-study-arm]'] =
      data?.researchOpportunities?.personStudyArm;
    params['filter[suggested-to-me]'] =
      data?.researchOpportunities?.suggestedToMe;
    if (data?.sponsors?.[0] !== 'show_all') {
      params['filter[study-sponsor]'] = data?.sponsors?.join();
    }

    if (data?.followUpStatuses?.[0] !== 'show_all') {
      params['filter[status]'] = data?.followUpStatuses?.join();
    }
    setPageNum(1);
    setFilterData({ ...params });
  };

  function isFilterApplied() {
    return (
      filterData.hasOwnProperty('filter[status]') ||
      filterData.hasOwnProperty('filter[study-sponsor]') ||
      (filterData.hasOwnProperty('filter[suggested-to-me]') &&
        filterData['filter[suggested-to-me]'] === false)
    );
  }

  const hasMoreData =
    pageNum < totalPages &&
    !isLoading &&
    opportunities &&
    opportunities.length !== 0 &&
    items?.length === perPage;

  return (
    <Container>
      <Header>
        <Title>External Research</Title>
        <FiltersToggleButton
          label={'FILTERS'}
          handleOnClick={toggleFilters}
          hasAppliedFilters={isFilterApplied()}
        />
      </Header>
      <Filter
        open={openFilters}
        anchor='right'
        onClose={toggleFilters}
        onSubmit={handleFilterSubmit}
        type='externalResearchOpportunities'
      />
      <SearchWrapper>
        <SearchInput
          fullWidth
          placeholder='Search'
          border='none'
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          startAdornment={
            <InputAdornment position='end' size='large'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </SearchWrapper>
      {pageNum === 1 && opportunities.length === 0 ? (
        <EmptyList isLoading={isLoading} />
      ) : isLoading && pageNum === 1 && opportunities.length > 0 ? (
        <Loader sx={{ height: '250px !important' }} />
      ) : null}
      {!(isLoading && pageNum === 1) &&
        opportunities &&
        opportunities.length > 0 && (
          <ExternalResearchOpportunityList opportunities={opportunities} />
        )}
      {pageNum > 1 && isLoading ? (
        <Loader />
      ) : (
        hasMoreData && (
          <LoadMoreButton variant='text' color='primary' onClick={loadMore}>
            Load more
          </LoadMoreButton>
        )
      )}
    </Container>
  );
};

export default withPerson(withSelectedProfile(ResearchOpportunities));