import { useCallback } from 'react';
import { produce } from 'immer';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { useConfiguration } from './useConfiguration';
import {
  useCreatePersonStudyArm,
  useUpdatePersonStudyArm,
  useUpdateAccountProfile,
} from './utils';
import { STUDY_ARM_ROLE_CODES, STUDY_ARM_STATUS_CODES } from 'constants/global';

export const useEnrollContact = () => {
  const createPersonStudyArm = useCreatePersonStudyArm()[0];
  const updatePersonStudyArm = useUpdatePersonStudyArm()[0];
  const updateAccountProfile = useUpdateAccountProfile()[0];
  const lazyFetchAccountProfile =
    accountProfileApiSlice.endpoints.getAccountProfile.useLazyQuery()[0];

  const { getStudyArmStatusByCode, getStudyArmRoleByCode, getContactStudyArm } =
    useConfiguration();
  const dispatch = useDispatch();

  const enrollContact = useCallback(
    async (user) => {
      const { person, activeAccountProfile } = user;
      const activeProfile = activeAccountProfile.attributes.profile;

      const contactStudyArm = getContactStudyArm();
      const studyArmRole = getStudyArmRoleByCode(
        STUDY_ARM_ROLE_CODES.Participant
      );
      const studyArmStatus = getStudyArmStatusByCode(
        STUDY_ARM_STATUS_CODES['CONTACT_ENROLLED']
      );
      const declinedStudyArmStatus = getStudyArmStatusByCode(
        STUDY_ARM_STATUS_CODES['RESEARCH_ENROLLMENT_DECLINED']
      );

      try {
        const createPersonStudyArmResult = await createPersonStudyArm({
          personId: person.id,
          studyArmId: contactStudyArm.id,
          studyArmRoleId: studyArmRole.id,
          studyArmStatusId: studyArmStatus.id,
        });

        const contactPersonStudyArmId =
          createPersonStudyArmResult.data?.data?.id;

        await updateAccountProfile({
          accountProfileId: activeAccountProfile.id,
          subjectPrimaryPersonStudyArmId: contactPersonStudyArmId,
          accountPersonStudyArmId: contactPersonStudyArmId,
          subjectSecondaryPersonStudyArmId:
            activeProfile.subject_primary_person_study_arm_id,
        });

        // Trigger person_study_arm update to set the enrollment_date of the person_study_arm_attribute #SING-441
        const [updatedProfile] = await Promise.all([
          lazyFetchAccountProfile(activeAccountProfile.id).unwrap(),
          updatePersonStudyArm({
            personStudyArmId: contactPersonStudyArmId,
            studyArmRoleId: studyArmRole.id,
            studyArmStatusId: studyArmStatus.id,
          }),
          updatePersonStudyArm({
            personStudyArmId: activeProfile.account_person_study_arm_id,
            studyArmRoleId: activeProfile.study_arm_role_id,
            studyArmStatusId: declinedStudyArmStatus.id,
          }),
        ]);

        const updatedUser = produce(user, (draft) => {
          draft.activeAccountProfile = updatedProfile.data;
        });

        dispatch(setValues(updatedUser));
        return updatedUser;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    [
      createPersonStudyArm,
      updatePersonStudyArm,
      updateAccountProfile,
      lazyFetchAccountProfile,
      getStudyArmStatusByCode,
      getStudyArmRoleByCode,
      getContactStudyArm,
      dispatch,
    ]
  );

  return [enrollContact];
};
