import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: '',
};

const setWelcomePageContentReducer = (state, action) => {
  const { content } = action.payload;
  state.content = content;
};

export const slice = createSlice({
  name: 'welcomePage',
  initialState,
  reducers: {
    setWelcomePageContent: setWelcomePageContentReducer
  },
});

export const { setWelcomePageContent } = slice.actions;

export default slice;
