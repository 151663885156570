import './styles.css'; // Import your CSS file

export const enrollmentFeedbackTemplate = {
  form: `
    <div class="form-wrapper">
      <div class="wizard-page" ref="{{ wizardKey }}">
          {{ components }}
      </div>
      <div class="button-container">
          <div id="skip-enrollment-feedback" class="skip-button">Skip</div>
          <button id="submit-enrollment-feedback" ref="{{ wizardKey }}-submit" class="submit-button">Send feedback</button>
      </div>
    </div>
  `,
};
