import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import { getStyles } from './RejectConsentConfirmationModal.styles';
import Button from 'ihp-components/components/v2/Button';

export const RejectConsentConfirmationModal = (props) => {
  const { open, onClose, onConfirmation } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalContainer}>
        <Box sx={styles.infoBox}>
          <Typography variant='h4' sx={styles.info}>
            Are you sure you want to reject the consent?
          </Typography>
          <Typography variant='pl3' sx={styles.subInfo}>
            By rejecting this consent you are not eligible to participate in the
            study.
          </Typography>
        </Box>
        <Button
          variant='contained'
          fullWidth
          onClick={() => onConfirmation(false)}
        >
          Review consent
        </Button>
        <Button size='lg' variant='link' onClick={() => onConfirmation(true)}>
          I do not wish to participate
        </Button>
      </Box>
    </Modal>
  );
};

RejectConsentConfirmationModal.defaultProps = {
  onClose: () => {
    // this is intentional
  },
  onConfirmation: () => {
    // this is intentional
  },
};

RejectConsentConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onConfirmation: PropTypes.func,
};
