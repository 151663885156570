import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllProfileParticipantTasksQuery } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { setParticipantTasks } from 'ihp-bloom-redux/features/tasks/tasksSlice';

import { filterParticipantTasks, formatParticipantTasks } from 'utils/tasks';
import { EmptyList } from '../Empty';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import Button from 'ihp-components/components/v2/Button';
import { isFilterApplied } from 'pages/Activities/utils';

function PastActivities({
  personId,
  studyRoleId,
  renderActivities,
  filtersConfig,
  maxVisible,
  isMobile,
  onViewMore,
  disableLoadMore,
}) {
  const dispatch = useDispatch();
  const { selectedTypes = [], startDate, endDate } = filtersConfig;
  const taskTypes =
    selectedTypes[0] === 'show_all' ? null : selectedTypes.join(',');
  const [tasks, setTasks] = useState([]);
  const [prevFilters, setPrevFilters] = useState({ ...filtersConfig });
  const [pageNum, setPageNum] = useState(1);
  const isFiltersUpdated =
    JSON.stringify(filtersConfig) !== JSON.stringify(prevFilters);

  const { activeAccountProfile } = useSelector((state) => state.user);
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const query = {
    display_status: 'past',
    task_type: taskTypes,
    'status_date[from]': startDate,
    'status_date[to]': endDate,
    page: isFiltersUpdated ? 1 : pageNum,
  };

  const {
    data: response,
    isFetching,
    isLoading,
    refetch,
  } = useGetAllProfileParticipantTasksQuery(
    {
      accountProfileId,
      query: { ...query },
    },
    {
      skip: personId === undefined || studyRoleId === undefined,
    }
  );

  useEffect(() => {
    let tsks = filterParticipantTasks(response?.data || [], 'past');
    tsks = formatParticipantTasks(tsks);
    tsks = tsks.filter((task) => task.type !== 'consent');
    tsks = tsks?.sort(
      (a, b) =>
        (b?.statusDate > a?.statusDate) - (b?.statusDate < a?.statusDate)
    );
    dispatch(setParticipantTasks(tsks));
    setTasks(pageNum === 1 ? [...tsks] : [...tasks, ...tsks]);
  }, [response?.data]);

  useEffect(() => {
    if (!isFetching && !isLoading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (JSON.stringify(filtersConfig) !== JSON.stringify(prevFilters)) {
      setPageNum(1);
      setPrevFilters(filtersConfig);
    }
  }, [filtersConfig]);

  function isScrollAtBottom(element) {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  }

  function handleScroll() {
    if (disableLoadMore) {
      return;
    }

    const totalPages = Math.ceil(
      response?.meta?.total / response?.meta?.per_page
    );
    const elem = document.getElementById('page-main-content');

    if (isScrollAtBottom(elem) && pageNum + 1 <= totalPages) {
      setPageNum(pageNum + 1);
    }
  }

  useEffect(() => {
    const elem = document.getElementById('page-main-content');
    elem.addEventListener('scroll', handleScroll);
    return () => elem.removeEventListener('scroll', handleScroll);
  }, [tasks]);

  if (tasks?.length === 0 || response === undefined) {
    return (
      <>
        {isMobile && !isFilterApplied(filtersConfig) ? null : (
          <>
            <EmptyList
              isLoading={isFetching || response === undefined}
              filterApplied={isFilterApplied(filtersConfig)}
              past={true}
            />
          </>
        )}
      </>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {isMobile && maxVisible && (
        <Typography mb={'20px'} variant='h4'>
          Past Activities
        </Typography>
      )}
      {renderActivities(tasks, isFetching && pageNum === 1, maxVisible)}
      {/* {isMobile && maxVisible && (
        <Box width={'100%'}>
          <Button
            onClick={() => onViewMore('PAST')}
            fullWidth
            endIcon={<ArrowForwardIcon width='14px' height='14px' />}
          >
            View All Past
          </Button>
        </Box>
      )} */}
      {/* This loader is for pagination section only*/}
      {isFetching && pageNum > 1 && (
        <CircularProgress sx={{ margin: '50px auto' }} color='primary' />
      )}
    </Box>
  );
}

PastActivities.propTypes = {
  personId: PropTypes.number,
  studyRoleId: PropTypes.number,
  renderActivities: PropTypes.node,
};

export default PastActivities;
