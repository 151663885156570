import { useState } from 'react';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { RadioButtonsContainer } from 'components/Onboarding';
import { RadioGroupInput, RadioInput } from 'components/Input';
import { Loader } from 'components/Loader';
import { isMobile } from 'utils/isMobile';

import {
  useUpdatePersonResourceMutation,
  useCreatePersonResourceMutation,
} from 'ihp-bloom-redux/features/personResource/personResourceApiSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

import {
  ButtonContainer,
  CancelButton,
  ContinueButton,
  Description,
  IconWrapper,
  LineBorder,
  ModalContainer,
  Title,
} from './styles';

export const FeedbackModal = ({
  isOpen,
  setIsOpenModal,
  currentResourceId,
  status,
  personResources,
  updateResource,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [newlyCreated, setNewlyCreated] = useState([]);
  const onCloseModal = () => {
    setSelectedOption(null);
    return setIsOpenModal(false);
  };

  const [createPersonResource, { isLoadingOnCreate }] =
    useCreatePersonResourceMutation();
  const [updatePersonResource, { isLoadingOnEdit }] =
    useUpdatePersonResourceMutation();

  const valuesMap = {
    Helpful: 'helpful',
    'Not Helpful': 'not_helpful',
  };
  const theme = useTheme();
  const options = [
    { key: 'helpful', value: 'Helpful' },
    { key: 'not_helpful', value: 'Not Helpful' },
  ];
  const getValueByKey = (key) => {
    const option = options.find((option) => option.key === key);
    return option ? option.value : null;
  };

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const onSaveModal = async () => {
    const status = valuesMap[selectedOption.value];
    setIsLoading(true);

    const foundResource = personResources?.filter(
      (pr) => parseInt(pr?.id) === parseInt(currentResourceId)
    );

    const foundPersonResource =
      foundResource?.length > 0 &&
      foundResource[0]?.relationships['person-resource']?.data;

    const fromNewlyCreated = newlyCreated.filter(
      (n) => n.resourceId === parseInt(currentResourceId)
    );

    if (foundPersonResource?.length > 0 || fromNewlyCreated?.length > 0) {
      const personResourceId =
        foundPersonResource[0]?.id || fromNewlyCreated[0]?.personResourceId;

      const payload = {
        data: {
          type: 'person-resources',
          id: personResourceId?.toString(),
          attributes: { status },
        },
      };
      await updatePersonResource({
        participantId: person?.id,
        resourceId: personResourceId,
        payload,
      }).catch(console.error);
    } else {
      const payload = {
        data: {
          type: 'person-resources',
          attributes: { resource_id: parseInt(currentResourceId), status },
        },
      };
      const response = await createPersonResource({
        participantId: person?.id,
        payload,
      });
      setNewlyCreated([
        ...newlyCreated,
        {
          resourceId: parseInt(currentResourceId),
          personResourceId: parseInt(response?.data?.data?.id),
        },
      ]);
    }
    setIsLoading(false);
    setSelectedOption(null);
    updateResource(currentResourceId, status);
    setIsOpenModal(false);
  };

  if (isOpen && !currentResourceId && !personResources) {
    return <Loader />;
  }

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ zIndex: 9999999 }}
    >
      <ModalContainer
        sx={{ backgroundColor: (theme) => theme.palette.primary.white }}
      >
        {isLoading && <Loader />}
        {!isLoading && (
          <>
            <Box sx={{ display: 'flex' }}>
              <Title>Educational Resource Feedback</Title>
              {!isMobile() && (
                <IconWrapper>
                  <CloseIcon
                    onClick={onCloseModal}
                    style={{ color: theme.palette.primary.darkGray50 }}
                  />
                </IconWrapper>
              )}
            </Box>
            {!isMobile() && <LineBorder />}
            <Description>How helpful was this article to you?</Description>
            <RadioButtonsContainer>
              <RadioGroupInput
                value={selectedOption?.value || getValueByKey(status)}
                onChange={(event) =>
                  setSelectedOption({ value: event.target.value })
                }
              >
                {options.map((option) => (
                  <RadioInput
                    key={option.value}
                    label={option.value}
                    value={option.value}
                  />
                ))}
              </RadioGroupInput>
            </RadioButtonsContainer>
            <ButtonContainer>
              {!isMobile() && (
                <CancelButton variant='link' onClick={onCloseModal}>
                  Cancel
                </CancelButton>
              )}
              <ContinueButton
                variant='contained'
                onClick={onSaveModal}
                disabled={selectedOption == null}
              >
                Save
              </ContinueButton>
              {isMobile() && (
                <CancelButton variant='link' onClick={onCloseModal}>
                  Cancel
                </CancelButton>
              )}
            </ButtonContainer>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};
