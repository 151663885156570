import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as ConsentIcon } from 'images/profile/my-consents/consent.svg';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}));

export const TopRow = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '30px',
  marginBottom: '24px',
  alignSelf: 'stretch',
});

export const TagsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '30px',
  height: '35px',
  alignSelf: 'stretch',
});

export const DescriptionWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '10px',
  alignSelf: 'stretch',
});

export const ConsentTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontSize: '18px',
  fontWeight: 600,
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  minWidth: '150px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
  },
}));

export const ConsentDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  alignSelf: 'stretch',
  fontSize: '13px',
  whiteSpace: 'pre-line',
}));

export const DurationText = styled(Typography)({
  color: '#696969',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: 500,
  marginTop: '20px',
  letterSpacing: '0.1px',
  textTransform: 'uppercase',
});

export const StyledConsentIcon = styled(ConsentIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '45px',
    height: '45px',
  },
}));
