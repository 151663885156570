import { createSlice } from '@reduxjs/toolkit';

/* Mock data */
const callDetails = {
  createdAt: "2021-03-10T13:14:32.677Z",
  cronofy_event_id: "ce31e2e3-2763-4706-8669-22ba9bba6a6e",
  event_start_datetime_utc: "2021-06-11T06:00:00.000Z",
  event_end_datetime_utc: "2021-06-10T06:30:00.000Z",
  event_id: "da41b577-caf3-4457-bcb9-8725365e4f56",
  event_room_id: "e1acf809-cbe1-4544-a894-75d0b37ddab3",
  event_scheduled_by: "12345",
  event_status: "string",
  event_type: "VirtualVisit",
  id: "6048c638a5f4d700136da71e",
  link: "https://nam04.safelinks.protection.outlook.com/?url=https%3A%2F%2Fiqvia-staging-video.encounterservices.com%2F%3Ftoken%3D53616c7465645f5f5b087fc2ea89ab06454835892e8934ba2c4e7643d9ac05309c14b8b089d5e86effbf1b9bdc8a5fb16d78923f046eb7f91ffbdbe8121f1dc22c38a828c18576a73e8155f586cafb09b8b931c037904c9d9f118b0e070ac59f&data=04%7C01%7Cdarko.kjurchievski%40quintiles.com%7Cfaf3a968b4cd4adeaf3308da068f0a0e%7C5989ece0f90e40bf9c791a7beccdb861%7C0%7C0%7C637829508659911506%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000&sdata=ql68pDqX1%2FAFjk9Fguy5QVK2EsEfNF8CVNH8MPwqNe4%3D&reserved=0",
  patient_profile: "60424b2ba5f4d700136da70a",
  patient_profile_id: "12345",
  provider_profile: "600ebd27f6d935001a01d9d6",
  provider_profile_id: "3",
  updatedAt: "2021-03-15T17:12:12.629Z"
}

const availableSlots = [
  {
    "profile_id": "1",
    "weekly_periods": [
      {
        "day": "thursday", // thursday
        "start_time": "14:00",
      },
      {
        "day": "friday", // friday
        "start_time": "15:00",
      },
      {
        "day": "monday", // monday
        "start_time": "17:00",
      },
      {
        "day": "tuesday", // tuesday
        "start_time": "18:00",
      },
      {
        "day": "wednesday", // wednesday
        "start_time": "19:00",
      },
      {
        "day": "friday", // friday
        "start_time": "20:00",
      }
    ]
  },
  {
    "profile_id": "2",
    "weekly_periods": [
     {
        "day": "thursday", // thursday
        "start_time": "18:00",
      },
      {
        "day": "friday", // friday
        "start_time": "08:00",
      },
      {
        "day": "monday", // monday
        "start_time": "12:00",
      },
      {
        "day": "tuesday", // tuesday
        "start_time": "19:00",
      },
      {
        "day": "wednesday", // wednesday
        "start_time": "20:00",
      },
      {
        "day": "thursday", // thursday
        "start_time": "20:00",
      }
    ]
  },
  {
    "profile_id": "3",
    "weekly_periods": [
     {
        "day": "thursday", // thursday
        "start_time": "11:00",
      },
      {
        "day": "monday", // monday
        "start_time": "11:00",
      },
      {
        "day": "tuesday", // tuesday
        "start_time": "19:00",
      },
      {
        "day": "friday", // friday
        "start_time": "14:00",
      }
    ]
  }
]

const initialState = {
  scheduleCall: {
    date: undefined,
    profileId: undefined,
    time: undefined
  },
  callDetails,
  availableSlots
};
const followupSlice = createSlice({
  name: 'followup',
  initialState,
  reducers: {
    setScheduleCallValues: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        scheduleCall: {
          ...state.scheduleCall,
          [name]: value
        }
      }
    },
  },
});

export const { setScheduleCallValues } =
followupSlice.actions;

export const selectAvailableSlots = () => state => state[followupSlice.name].availableSlots;

export default followupSlice.reducer;
