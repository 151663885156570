import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import MaskedInput from 'react-input-mask';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import OnboardingLayout from 'pages/Onboarding/components/Layout';
import {
  LANG,
  getPersonInformation,
  generatePersonInformationFieldsMap,
  getAccountHolderProfileInfo,
  FIELD_TYPE_RENDERERS_MAP,
} from 'utils/configParseUtils';
import { FTDDRLogo } from 'pages/Onboarding/components/FTDDRLogo';
import { Title, Text } from 'pages/Onboarding/components/Text';
import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';

const defaultValues = {
  first_name: 'Muhammad',
  middle_name: 'Mughal',
  last_name: 'Umer',
};

function ConfigParser() {
  const navigate = useNavigate();
  const { participantProfileConfig } = useSelector(
    (state) => state.configuration
  );

  const { meta, fields } = getPersonInformation(participantProfileConfig);
  const fieldsMap = generatePersonInformationFieldsMap(fields);

  const {
    control,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = (data) => {};

  return (
    <OnboardingLayout maxWidth='1200px' backHandler={goBack}>
      <div>
        <FTDDRLogo />
      </div>
      <Title>{meta?.label?.[LANG]}</Title>
      <Text
        color='primary.darkGray70'
        fontWeight={400}
        fontSize='14px'
        marginTop='5px'
      >
        You indicated you are a caregiver or biological family member, answering
        for yourself. Please enter YOUR information below, not the information
        for the person diagnosed.
      </Text>
      <form>
        <Grid container rowSpacing={3} columnSpacing={3}>
          {fieldsMap?.map((mappedField) => {
            const {
              type,
              name,
              label,
              placeholder,
              Component: Field,
              disabled,
              colSpan,
              validation,
              show,
              ...rest
            } = mappedField;

            const customRenderer = FIELD_TYPE_RENDERERS_MAP[mappedField.name];

            if (!show) return null;

            return (
              <Grid item xs={12} key={name}>
                <Controller
                  name={name}
                  control={control}
                  rules={validation}
                  render={({ field }) =>
                    customRenderer ? (
                      customRenderer({
                        label,
                        placeholder,
                        name,
                        ...rest,
                        ...field,
                      })
                    ) : (
                      <Field
                        name={name}
                        error={errors?.[name]}
                        errorLabel={
                          errors?.[name]?.message || 'This field is required'
                        }
                        fullWidth={true}
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...rest}
                        {...field}
                      />
                    )
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </form>
      <Button fullWidth onClick={handleSubmit(onSubmit)}>
        Save and Continue
      </Button>
    </OnboardingLayout>
  );
}

export default ConfigParser;
