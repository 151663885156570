export const StatusLists = [
  {
    label: 'Contacted the study',
    key: 'contacted',
  },
  {
    label: 'Contacted the study but was ineligible',
    key: 'ineligible',
  },

  {
    label: 'Enrolled',
    key: 'enrolled',
  },
  {
    label: 'Decided that this study is not of interest',
    key: 'declined',
  },
  {
    label: 'Unsubscribe from communication about this study',
    key: 'unsubscribe',
  },
];
