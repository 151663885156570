import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { ReactComponent as SearchIcon } from 'images/shared/search.svg';

const EmptyState = ({ title }) => {
  const theme = useTheme();
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      height='80vh'
      flexDirection='column'
    >
      <SearchIcon
        height='40px'
        width='40px'
        color={theme.palette.primary.gray25}
      />
      <Typography color={theme.palette.primary.gray25} marginTop='16px'>
        {title}
      </Typography>
    </Box>
  );
};

export default EmptyState;
