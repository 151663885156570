import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const StyledIframe = styled('iframe')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  boxSizing: 'border-box',
  [theme.breakpoints.up('sm')]: {
    width: '425px',
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingTop: '15px',
  borderTop: '1px solid ' + theme.palette.primary.gray10,
}));

export const SpinnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '80vh',
}));

export const TransferContainer = styled('div')(({ theme }) => ({
  padding: '30px 50px 20px 50px',
  maxWidth: '600px',
  height: '80vh',
  overflow: 'auto',
}));
