import { styled } from '@mui/material/styles';
import { FormControlLabel } from '@mui/material';

export const getStyles = () => {
  return {
    transparentRadioButton: {
      opacity: '0',
      height: '15px',
      width: '15px',
    },
    radioCheckButton: {
      height: '15px',
      width: '15px',
    },
    containerStyle: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '9px',
    },
    formGroup: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '9px',
    },
  };
};

export const CustomFormControlLabel = styled(FormControlLabel)(
  ({ theme, value }) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '15px 20px 15px 15px',
    alignItems: 'center',
    borderRadius: '5px',
    border: `2px solid ${
      value ? theme.palette.primary.magenta70 : 'transparent'
    }`,
    background: theme.palette.primary.white,
    boxShadow: '0px 0px 9px 0px rgba(0, 0, 0, 0.04)',
    justifyContent: 'space-between',
    margin: 0,
  })
);
