import React from 'react';
import ViewConsentMobile from './index.mobile';
import { Grid, Box } from '@mui/material';
import {
  EmailContainer,
  Title,
  Description,
  TermsOfUseContainer,
} from 'components/Login';
import LeftContainer from 'components/Onboarding/LeftContainer';
import Button from 'ihp-components/components/v2/Button';
import { Logo, FooterNote } from 'components/shared';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'utils/isMobile';

const styles = {
  formContainer: {
    paddingTop: '25px',
    width: '95%',
  },
};

function ViewConsent() {
  document.title = 'Single Ventricle SOURCE Consent';
  let navigate = useNavigate();
  const navigateToInformedConsent = () => {
    navigate('/informedconsent');
  };

  if (isMobile()) {
    return <ViewConsentMobile />;
  }

  return (
    <Grid container sx={styles.container}>
      <LeftContainer />
      <Grid item xl={6}>
        <EmailContainer>
          <Logo />
          <Title>You’re almost finished</Title>
          <Description>
            Now we will ask you to review and sign the consent to participate in
            the study. This includes an explanation of the data that will be
            collected in this study.
          </Description>
          <Box sx={styles.formContainer} component='div'>
            <Button fullWidth={true} onClick={navigateToInformedConsent}>
              View Consent
            </Button>
          </Box>
          <TermsOfUseContainer>
            By signing in you accept our
            <FooterNote text=' Terms of Use' /> and
            <FooterNote text=' Privacy Policy' />
          </TermsOfUseContainer>
        </EmailContainer>
      </Grid>
    </Grid>
  );
}
export default ViewConsent;
