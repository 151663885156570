import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';

import { ReactComponent as ArrowBackwardIcon } from 'images/activity/arrow-backward-icon.svg';
import { ReactComponent as CogIcon } from 'images/shared/cog.svg';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '17px',
  height: '60px',
  padding: '0 20px 0 20px',
  backgroundColor: theme.palette.primary.white,
  borderBottom: `1px solid ${theme.palette.primary.gray10}`,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const Button = styled(IconButton)({
  width: '24px',
  height: '24px',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
});

const StyledBackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.gray100,
}));

const StyledConfButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.gray100,
}));

export const BackButton = (props) => (
  <StyledBackButton {...props}>
    <ArrowBackwardIcon width='18px' height='20px' />
  </StyledBackButton>
);

export const ConfButton = (props) => (
  <StyledConfButton {...props}>
    <CogIcon width='19px' height='19px' />
  </StyledConfButton>
);
