import { parse, parseISO, format, formatISO, isDate } from 'date-fns';
import { UPCOMING, COMPLETED, AVAILABLE } from 'mocks/study';

export const weekday = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 *
 * @param {number} num
 * @returns Time stamp based on the number passed in e.g input 1 will return 01:00 PM
 */
export const numberToTime = (num) => {
  const date = new Date();
  date.setHours(num, 0, 0);
  return format(date, 'p');
};

export const minutesBetweenDates = (startDate, endDate) => {
  var diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
};

// HyperWallet expects YYYY-MM-DD date format
export const toHyperWalletTimeFormat = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};
// REMOVE ME; this is not being used anymore
export const getTimelineDateTitles = (dateString) => {
  // TODO: remove this when the date are fixed from backend
  if (dateString === UPCOMING) {
    return UPCOMING;
  }
  if (dateString === AVAILABLE) {
    return AVAILABLE;
  }
  if (dateString === COMPLETED) {
    return COMPLETED;
  }

  const date = new Date(dateString);
  const dayOfWeek = weekday[date.getDay()]?.toUpperCase();
  const month = months[date.getMonth()]?.toUpperCase();
  return `${dayOfWeek}, ${date.getDay()} ${month}, ${date.getFullYear()}`;
};

export const daysBetweenDates = (startDate, endDate) =>
  Math.floor(
    (Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      )) /
      (1000 * 60 * 60 * 24)
  );

/* This helper method will format date like dd-mm-yyyy */
export const dateFormatter = (date, separatorSymbol = '-') => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join(separatorSymbol);
};

// This method is used to convert utc date to local (provided timezone) date
export function convertUTCDateToLocalDate(utcDateTimeString, timezone) {
  let nullSafeTimezone = timezone;
  if (timezone == null || timezone === '') {
    nullSafeTimezone = 'US/Eastern';
  }

  /*
  1. First convert utc slot coming from api to local time (local system) by new date('utc_string_coming UTC')
  2. Convert local time to users selected timezone using js
  */
  const locaDateTime = convertUTCStringToLocalDateObject(utcDateTimeString);
  // the exported format will be MM/DD/YYYY HH:MM:SS
  const localDateTimeString = locaDateTime?.toLocaleString('en-US', {
    timeZone: nullSafeTimezone,
    hour12: false,
  });
  /*
   Convert date to "YYYY-MM-DD" format in above generated local date time string
  */
  const [localDate, localTime] = localDateTimeString.split(',');
  const [month, date, year] = localDate?.split('/');
  const localDateTime = `${year}-${month < 10 ? 0 : ''}${month}-${
    date < 10 ? 0 : ''
  }${date} ${localTime?.trim()}`;

  return localDateTime;
}

export function generateDatabaseDateTime(date) {
  return date.toISOString().replace('T', ' ').substring(0, 19);
}

// Applying this approach to support old safari versions
/*
  input: 2023-07-26 01:00:00 in utc
 */
export function convertUTCStringToLocalDateObject(utcString) {
  return new Date(`${utcString} UTC`.replace(' ', 'T').replace(' UTC', 'Z'));
}

// Applying this approach to support old safari versions
/*
    input: 2023-07-26 01:00:00 not in utc
   */
export function convertDateStringToLocalDateObject(dateString) {
  return new Date(dateString?.replace(/ /g, 'T'));
}

/*
  input: 2023-07-26
  This method will convert date string to date object without considering time effect
*/

export function convertDateStringToObject(dateString) {
  const [year, month, date] = dateString?.split('-');
  const dateObj = new Date(year, parseInt(month) - 1, date);
  return dateObj;
}

export const getDateStringFromUTC = (date) => {
  if (date) {
    const parseDate = parseISO(date);
    const dateString = format(parseDate, 'MM/dd/yyyy');
    return dateString;
  }
  return '';
};

export const convertDateStringToUTC = (date) => {
  if (date) {
    let parsedDate = new Date(date);
    parsedDate.setHours(0, 0, 0, 0);
    const utcDateString = formatISO(parsedDate, { representation: 'complete' });
    return utcDateString;
  }
  return '';
};

export const parseDate = (date) => {
  if (isDate(date)) {
    return date;
  }

  if (typeof date === 'string') {
    if (date.includes('T')) {
      return parse(date.split('T')[0], 'yyyy-MM-dd', new Date());
    } else {
      return parse(date, 'yyyy-MM-dd', new Date());
    }
  }

  throw new Error('Invalid date format');
};

export const formatDate = (date) => {
  const parsedDate = parseDate(date);
  return format(parsedDate, 'MM/dd/yyyy');
};

export const formatDateToDB = (date) => {
  const parsedDate = parseDate(date);
  return format(parsedDate, 'yyyy-MM-dd');
};
