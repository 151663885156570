import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { StyledBaseLayout } from 'pages/Profile/styles';
import {
  Header,
  Content,
  Footer,
  ConsentCheckMark,
  getStyles,
  Stats,
  StatusChip,
  SignDate,
  Version,
} from './styles';
import { SignButton } from '../List/styles';
import { BaseHeader } from 'components/BaseLayout';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { isMobile } from 'utils/isMobile';
import { Box } from '@mui/system';
import { SectionTitle } from 'components/InformedConsent';
import { FormCheckbox } from 'components/Checkbox';
import { useState } from 'react';
import { ReactComponent as Clock } from 'images/profile/clock.svg';
import { ReactComponent as VersionIcon } from 'images/profile/version.svg';
import { ReactComponent as CheckIcon } from 'images/profile/check-circle-solid-green.svg';
import { RejectConsentConfirmationModal } from 'components/Profile/RejectConsentConfirmationModal';
import { format } from 'date-fns';
import Button from 'ihp-components/components/v2/Button';

export const MobileDetails = ({ details, isSigned, handleSign }) => {
  const navigate = useNavigate();
  const [consentAgreed, setConsentAgreed] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const onToggle = () => setConsentAgreed(!consentAgreed);
  const handleCancel = () => navigate('/profile/consents');

  const handleReject = () => {
    if (!details?.mandatory) {
      handleCancel();
      return;
    }
    setShowRejectConfirmation(true);
  };
  const handleConfirmRejection = (confirm) => {
    setShowRejectConfirmation(false);
    if (confirm) {
      handleCancel();
    }
  };

  const hasFooter = !isSigned;
  const isMobileScreen = isMobile();

  const styles = getStyles();
  return (
    <StyledBaseLayout
      fixedFooter={true}
      header={
        <Header
          sx={!isMobileScreen ? { marginLeft: '25px' } : {}}
          isMobile={isMobileScreen}
        >
          <BaseHeader
            forceShowClickBack={!isMobileScreen}
            to='/profile/consents'
          >
            {details?.title}
          </BaseHeader>
          <Stats isMobile={isMobileScreen}>
            <StatusChip
              isRequired={details?.mandatory}
              isMobile={isMobileScreen}
            >
              {details?.mandatory ? 'required' : 'optional'}
            </StatusChip>
            <SignDate>
              {isSigned ? <CheckIcon /> : <Clock />}
              <span>
                {!!details &&
                  format(
                    new Date(details?.participant_consent_date ?? Date.now()),
                    'yyy-MM-dd'
                  )}
              </span>
            </SignDate>
            <Version>
              <VersionIcon />
              <span>{details?.consent_version_id}</span>
            </Version>
          </Stats>
        </Header>
      }
      content={
        <Content isMobile={isMobileScreen}>
          <div className='body'>
            <Box>
              <SectionTitle>{details.title}</SectionTitle>
              <Box sx={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Typography variant='pl3' color='primary.gray75'>
                  {details.content}
                </Typography>
              </Box>
            </Box>
          </div>
          {!isSigned && (
            <ConsentCheckMark isMobile={isMobileScreen}>
              <FormCheckbox
                containerStyle={styles.checkboxContainer}
                label='I agree to sign this consent'
                checked={consentAgreed}
                onChange={onToggle}
              />
            </ConsentCheckMark>
          )}
          <RejectConsentConfirmationModal
            open={showRejectConfirmation}
            onClose={() => setShowRejectConfirmation(false)}
            onConfirmation={handleConfirmRejection}
          />
        </Content>
      }
      footer={
        hasFooter && (
          <Footer isMobile={isMobileScreen}>
            <Button size='lg' variant='link' onClick={handleReject}>Reject</Button>
            <SignButton
              variant='contained'
              size='large'
              disabled={!consentAgreed}
              startIcon={<Sign width={19} height={15} />}
              onClick={handleSign}
            >
              Sign consent
            </SignButton>
          </Footer>
        )
      }
    />
  );
};
