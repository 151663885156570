import { forwardRef, useState } from 'react';
import { Menu, MenuItem, Stack } from '@mui/material';

import { StyledFormLabel } from 'components/Input/TextField.styles';
import { WeekDay } from './styles';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-1.svg';

const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const WeekDaySelector = ({ onChange, value }) => (
  <Stack direction='row' justifyContent='space-between'>
    {weekDays.map((weekDay, index) => {
      const checked = value?.includes(index) || false;
      const handleClick = () => {
        if (value && onChange) {
          const newValue = checked
            ? value.filter(weekDay => weekDay !== index)
            : [...value, index].sort((lhs, rhs) => lhs - rhs);
          onChange(newValue);
        }
      };

      return (
        <WeekDay
          key={index}
          onClick={handleClick}
          checked={checked}
        >
          {weekDay}
        </WeekDay>
      );
    })}
  </Stack>
);

const recurrenceTypeMapping = {
  'weekly': 'Weekly',
  'daily': 'Daily',
};

const DropDownItem = ({ onSelect, value }) =>
  <MenuItem onClick={() => onSelect(value)} >
    {recurrenceTypeMapping[value]}
  </MenuItem>;

const DropDown = ({ value, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSelect = value => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <StyledFormLabel onClick={handleOpen}>
        {recurrenceTypeMapping[value]} <ChevronDown width='11px' height='7px' />
      </StyledFormLabel>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <DropDownItem onSelect={handleSelect} value='weekly' />
        <DropDownItem onSelect={handleSelect} value='daily' />
      </Menu>
    </>
  );
};

export const RecurrenceSelector = forwardRef(({ onChange, value }, ref) => {
  const {
    type,
    when = []
  } = value;
  const handleTypeChange = type => {
    switch (type) {
      case 'weekly': return onChange({ type: 'weekly', when });
      case 'daily': return onChange({ type: 'daily' });
      default: { }
    }
  };
  const handleWhenChange = when => {
    return onChange({ type: 'weekly', when });
  };
  return (
    <Stack ref={ref}>
      <DropDown value={type} onChange={handleTypeChange} />
      {type === 'weekly' &&
        <WeekDaySelector value={when} onChange={handleWhenChange} />
      }
    </Stack>
  );
});
