import { FamilyLinkageInvitation } from 'pages/Profile/FamilyLinkage/components/FamilyLinkageInvitation';
import { Buttons } from 'features/familyLinkage/styles';
import Button from 'ihp-components/components/v2/Button';

export function FamilyLinkagePendingInvitation({
  inviter_first_name,
  inviter_last_name,
  isAccepting,
  isDeclining,
  isLoading,
  onAccept,
  onDecline,
}) {
  return (
    <FamilyLinkageInvitation
      firstName={inviter_first_name}
      lastName={inviter_last_name}
    >
      <Buttons ml='auto'>
        <Button
          disabled={isAccepting || isDeclining || isLoading}
          loading={isAccepting}
          onClick={onAccept}
          size='xs'
        >
          Accept
        </Button>

        <Button
          disabled={isAccepting || isDeclining || isLoading}
          loading={isDeclining}
          onClick={onDecline}
          size='xs'
          variant='outlined'
        >
          Decline
        </Button>
      </Buttons>
    </FamilyLinkageInvitation>
  );
}
