import { createSlice } from '@reduxjs/toolkit';

const dataProviders = [
  {
    id: '001',
    name: '1up Health',
    clientID: '132131213',
    clientSecret: '6765756',
    redirectURL: 'http://www.1uphealth.com',
    isActive: true,
  },
  {
    id: '002',
    name: 'Data provider 2',
    clientID: '12313',
    clientSecret: '45646',
    redirectURL: 'http://www.dataprovider2.com',
    isActive: true,
  },
  {
    id: '003',
    name: 'Data provider 3',
    clientID: '',
    clientSecret: '',
    redirectURL: '',
    isActive: false,
  },
  {
    id: '004',
    name: 'Data provider 4',
    clientID: '12313',
    clientSecret: '45646',
    redirectURL: 'http://www.dataprovider2.com',
    isActive: true,
  },
  {
    id: '005',
    name: 'Data provider 5',
    clientID: '',
    clientSecret: '',
    redirectURL: '',
    isActive: false,
  },
];

const initialState = {
  allDataProviders: dataProviders,
  selectedDataProviders: dataProviders,
};
const dataProviderSlice = createSlice({
  name: 'dataProvider',
  initialState,
  reducers: {
    toggleDataProvider: (state, action) => {
      state.selectedDataProviders = state.selectedDataProviders.map((dataProvider) => {
        return {
          ...dataProvider,
          isActive: dataProvider.id === action.payload ? !dataProvider.isActive : dataProvider.isActive,
        };
      });
    },
    editDataProvider: (state, action) => {
      const dataProvider = state.allDataProviders.find((dataProvider) => dataProvider.id === action.payload);
      if (dataProvider) {
        const { clientID, clientSecret, redirectURL } = action.payload;
        dataProvider.clientID = clientID;
        dataProvider.clientSecret = clientSecret;
        dataProvider.redirectURL = redirectURL;
      }
    },
  },
});

export const { toggleDataProvider, editDataProvider } = dataProviderSlice.actions;

export default dataProviderSlice.reducer;
