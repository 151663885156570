import { styled } from '@mui/system';
import { Modal } from '@mui/material';

export const getStyles = ({ palette, breakpoints }) => ({
  modalWrapper: {
    height: 'auto',
    maxHeight: '90vh',
    width: '56%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflowY: 'auto',
    [breakpoints.down('sm')]: {
      width: '95%',
      height: '95%',
    },
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '20px',
    borderBottom: `1px solid ${palette.primary.pattensBlue}`,
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '0px 20px 20px',
  },
});
