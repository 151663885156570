import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';

import { FilledButton } from 'components/Buttons';

export const ModalContainer = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    width: '405px',
    height: '310px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '40px 30px 20px 30px',
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
  };
});

export const ContinueButton = styled(FilledButton)(({ theme }) => {
  return {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '20px',
    width: '325px',
    height: '56px',
    marginTop: '30px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px',
      width: '270px',
    },
  };
});

export const Description = styled(Box)(({ theme }) => {
  return {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#444444',
    textAlign: 'center',
    marginTop: '15px',
  };
});

export const Title = styled(Box)(() => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '29px',
  color: '#444444',
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  minWidth: 134,
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '2%',
  color: theme.palette.primary.darkBlue100,
}));
