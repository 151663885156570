import { createSlice } from '@reduxjs/toolkit';
const idFormatter = (id) => String(id).padStart(3, '0');

const emails = [
  {
    id: '001',
    title: 'Invitation to enroll',
    type: 'email',
    body: ''
  },
  {
    id: '002',
    title: 'Welcome to the Bloom study',
    type: 'email',
    body: ''
  },
  {
    id: '003',
    title: 'Study overview',
    type: 'email',
    body: ''
  },
  {
    id: '004',
    title: 'Reminder to enroll',
    type: 'email',
    body: ''
  },
  {
    id: '005',
    title: 'Schedule TeleVisit call',
    type: 'email',
    body: ''
  },
  {
    id: '006',
    title: 'Reminder',
    type: 'email',
    body: ''
  },
  {
    id: '007',
    title: 'Schedule TeleVisit call Follow up',
    type: 'email',
    body: ''
  },
];

const initialState = {
  list: emails,
};

const addEmailTemplateReducer = (state, { payload }) => {
  const data = payload && payload.data ? payload.data : {};
  const id = idFormatter(state.list.length + 1);
  state.list.push({
    id,
    ...data,
  });
};

const updateEmailTemplateReducer = (state, { payload }) => {
  const { id, ...rest } = payload.data;
  const targetIndex = state.list.findIndex((template) => template.id === id);
  if (targetIndex > -1) {
    state.list[targetIndex] = {
      ...state.list[targetIndex],
      ...rest,
    };
  }
};

const deleteEmailTemplateReducer = (state, action) => {
  state.list = state.list.filter((item) => item.id !== action.payload.id);
}

const slice = createSlice({
  name: 'emailTemplates',
  initialState,
  reducers: {
    addEmailTemplate: addEmailTemplateReducer,
    updateEmailTemplate: updateEmailTemplateReducer,
    deleteEmailTemplate: deleteEmailTemplateReducer
  },
});

export const { addEmailTemplate, updateEmailTemplate, deleteEmailTemplate } = slice.actions;

export const selectEmailTemplateById = id => state => state[slice.name].list.find(item => item.id === id) || {};

export default slice;
