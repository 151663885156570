import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'ihp-bloom-redux/app/redux';

import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {
  useGetParticipantGoalQuery,
  useGetParticipantGoalStatsQuery,
} from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import { NextPayout, ScorePanel } from 'components/Payment';
import {
  getPayments,
  getPointsInfo,
  getThresholdInfo,
} from 'components/Payment/Rewards/service';
import {
  getRewardsInfo,
  parsePayments,
  parseRewardsInfo,
  parseStudyThreshold,
  parseStudyConversionRate,
} from 'components/Payment/Rewards/util';
import { Loader } from 'components/Loader';
import HyperWalletAccount from '../HyperWallet/CreateUser';
import { useMenuCard } from '../HyperWallet/Menu/MenuCard';
import HyperWalletTransfer from '../HyperWallet/Transfer';
import HyperWalletVerify from '../HyperWallet/Verify';
import { HyperwalletModal } from '../HyperWallet/Modal';
import { RedeemModal } from '../Redeem/components/RedeemModal';
import {
  PaymentPageWrapper,
  PaymentPageTitle,
  Line,
  OverviewContainer,
  Separator,
  Spacer,
  SubHeaderContainer,
} from './styles';
import { findStudyAttribute } from 'utils/study';
import { useGetWalletTransactionsQuery } from 'ihp-bloom-redux/features/profile/walletApiSlice';
import { PaymentHistory } from './PaymentHistory';
import FilterDrawer from 'components/FilterDrawer';

const PaymentHeader = ({
  totalPointsEarned,
  pointsBalance,
  pointsAlreadyRedeemed,
  totalRewardsValueReceived,
  availablePoints,
  thresholdMinAmount,
  thresholdIncrement,
  maxAnnualCompensation,
  claimReward,
}) => {
  const [isAccountOpen, setAccountOpen] = useState(false);
  const [isTransferOpen, setTransferOpen] = useState(false);
  const [isVerifyOpen, setVerifyOpen] = useState(false);

  const { Menu, handleClick } = useMenuCard({
    openAccount: () => {
      handleClick(null); // close the popup menu
      setAccountOpen(true);
    },
    openTransfer: () => {
      handleClick(null); // close the popup menu
      setTransferOpen(true);
    },
    openVerify: () => {
      handleClick(null); // close the popup menu
      setVerifyOpen(true);
    },
  });

  const accountModal = (
    <HyperwalletModal
      isOpen={isAccountOpen}
      onClose={() => setAccountOpen(false)}
      title='Enter your information'
      content={<HyperWalletAccount />}
    />
  );

  const transferModal = (
    <HyperwalletModal
      isOpen={isTransferOpen}
      onClose={() => setTransferOpen(false)}
      title='Setup transfer method'
      content={<HyperWalletTransfer />}
    />
  );

  const verifyModal = (
    <HyperwalletModal
      isOpen={isVerifyOpen}
      title='Verify account'
      onClose={() => setVerifyOpen(false)}
      content={<HyperWalletVerify />}
    />
  );

  return (
    <Box>
      <>
        <Menu />
        {accountModal}
        {transferModal}
        {verifyModal}
        <Line />
        <SubHeaderContainer>
          <ScorePanel
            totalPointsEarned={totalPointsEarned}
            pointsBalance={pointsBalance}
            pointsAlreadyRedeemed={pointsAlreadyRedeemed}
            totalRewardsValueReceived={totalRewardsValueReceived}
            availablePoints={availablePoints}
          />
          <Separator />
          <OverviewContainer>
            <NextPayout
              availablePoints={availablePoints}
              remainingPoints={thresholdMinAmount - availablePoints}
              progress={(availablePoints / thresholdMinAmount) * 100}
              isComplete={availablePoints >= thresholdMinAmount}
              claimReward={claimReward}
              thresholdMinAmount={thresholdMinAmount}
            />
          </OverviewContainer>
        </SubHeaderContainer>
        <Spacer />
      </>
    </Box>
  );
};

const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setThreshold] = useState(100);
  const [conversionRate, setConversionRate] = useState(1);
  const [, setPaymentsList] = useState([]);
  const [pointsAndAmounts, setPointsAndAmounts] = useState({
    totalPointsEarned: 0,
    pointsBalance: 0,
    pointsAlreadyRedeemed: 0,
    totalRewardsValueReceived: 0,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const filtersConfig = useSelector((state) => state.filters.rewards);
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const { getAccessTokenSilently } = useAuth0();

  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });

  const { refetch } = useGetParticipantGoalStatsQuery(person_id, {
    skip: person_id ? false : true,
  });

  const { participantGoalId } = useGetParticipantGoalQuery(person_id, {
    skip: person_id ? false : true,
    selectFromResult: ({ data, ...rest }) => {
      const goalsList = data?.data;
      const target = goalsList?.find(
        (goal) =>
          goal.relationships?.goal?.attributes?.type === 'point_unbounded'
      );
      return {
        participantGoalId: target?.id,
        ...rest,
      };
    },
  });

  const { selectedTypes = [], startDate, endDate, balances } = filtersConfig;
  const taskTypes =
    selectedTypes[0] === 'show_all' ? null : selectedTypes.join(',');

  const timestampRef = useRef(Date.now()).current;
  const params = {
    person_id,
    include: 'participant-reward,participant-task',
    'participant-goal-id': participantGoalId,
    ...(taskTypes && { 'task-type': taskTypes }),
    ...(startDate &&
      endDate && {
        from: startDate,
        to: endDate,
      }),
    ...(balances.indexOf('show_all') === -1 && {
      'points-redeemed':
        balances.indexOf('points-redeemed') !== -1 ? '1' : 'null',
      value_gained: balances.indexOf('value_gained') !== -1 ? '1' : 'null',
    }),
    sessionId: timestampRef, // passing this sessionId to disable cache so always fetch new data
  };

  const {
    walletTransactions,
    refetch: refetchWalletTransactions,
    isFetching: isWalletTransactionsFetching,
    isLoading: isWalletTransactionsLoading,
  } = useGetWalletTransactionsQuery(params, {
    skip: participantGoalId ? false : true,
    selectFromResult: ({ data, ...rest }) => {
      const list = data?.data || [];
      const sortedList = [...list]?.sort(function (a, b) {
        var c = new Date(a?.attributes?.created_at);
        var d = new Date(b?.attributes?.created_at);
        return d - c;
      });
      return {
        walletTransactions: sortedList,
        ...rest,
      };
    },
  });

  const payoutMethod =
    findStudyAttribute(studyConfiguration || [], 'payout_method') || [];
  const thresholdMinAmount = payoutMethod?.[0]?.threshold?.min || 0;
  const thresholdIncrement = payoutMethod?.[0]?.threshold?.increment || 1;
  const maxAnnualCompensation = payoutMethod?.[0]?.maximum_annual;

  const fetchTokenAndPointsInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      if (person_id) {
        const rewardsResponse = await getPointsInfo(person_id, token);
        let rewardsInfo = getRewardsInfo(rewardsResponse.data);
        let pointsAndAmounts = parseRewardsInfo(rewardsInfo);
        setPointsAndAmounts(pointsAndAmounts);

        const thresholdResponse = await getThresholdInfo({ studyId: 1, token });
        const threshold = parseStudyThreshold(thresholdResponse?.data);
        setConversionRate(parseStudyConversionRate(thresholdResponse?.data));
        setThreshold(threshold);

        const paymentsResponse = await getPayments({
          participantId: person_id,
          token,
        });
        const payments = parsePayments(paymentsResponse?.data);
        setPaymentsList(payments);
        refetch();
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [getAccessTokenSilently, person_id]);

  useEffect(() => {
    fetchTokenAndPointsInfo();
  }, [fetchTokenAndPointsInfo]);

  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const onClose = () => {
    setOpenRedeemModal(false);
  };

  const toggleFilters = () => {
    setOpenFilters(!openFilters);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PaymentPageWrapper>
      {openRedeemModal && (
        <RedeemModal
          open={openRedeemModal}
          onClose={onClose}
          availablePoints={pointsAndAmounts.pointsBalance}
          pointsPerDollar={conversionRate}
          onSuccess={() => {
            fetchTokenAndPointsInfo();
            refetchWalletTransactions(); // refetch
          }}
          threshold={thresholdMinAmount}
          studyConfig={{
            thresholdMinAmount,
            thresholdIncrement,
            maxAnnualCompensation,
          }}
        />
      )}
      <PaymentPageTitle to='/profile'>Rewards</PaymentPageTitle>
      <Box
        display={'flex'}
        flexDirection={'column'}
        flex={1}
        overflow={'auto'}
        width='100%'
      >
        <PaymentHeader
          totalPointsEarned={pointsAndAmounts.totalPointsEarned}
          pointsBalance={pointsAndAmounts.pointsBalance}
          pointsAlreadyRedeemed={pointsAndAmounts.pointsAlreadyRedeemed}
          totalRewardsValueReceived={pointsAndAmounts.totalRewardsValueReceived}
          availablePoints={pointsAndAmounts.pointsBalance} // available points to progress bar
          thresholdMinAmount={thresholdMinAmount}
          thresholdIncrement={thresholdIncrement}
          maxAnnualCompensation={maxAnnualCompensation}
          claimReward={() => setOpenRedeemModal(true)}
        />
        {walletTransactions && (
          <PaymentHistory
            openFilter={toggleFilters}
            paymentsList={walletTransactions}
            isLoading={
              isWalletTransactionsLoading || isWalletTransactionsFetching
            }
          />
        )}
      </Box>

      <FilterDrawer
        open={openFilters}
        anchor='right'
        onClose={toggleFilters}
        type='rewards'
      />
    </PaymentPageWrapper>
  );
};

export default Payment;
