import { BaseHeader } from 'components/BaseLayout';
import { Divider } from 'configUI/components/Shared';
import { StyledBaseLayout } from './styles';
import { CardList } from './CardList';

const PregnancyJournalDetailedMobile = ({
  data: { header, forms },
  isLoading,
}) => (
  <StyledBaseLayout
    header={
      <>
        <BaseHeader to='/tools/pregnancy-journal'>
          {header}
        </BaseHeader>
        <Divider />
      </>
    }
    content={<CardList forms={forms} isLoading={isLoading} />}
  />
);

export default PregnancyJournalDetailedMobile;
