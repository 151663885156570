import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { VideoPlayerContainer, VideoPlayerPlayButton } from './styles';
import { ReactComponent as PlayIcon } from 'images/player/play.svg';
import { ReactComponent as ReplayIcon } from 'images/player/replay.svg';

const VideoPlayer = ({
  src,
  onComplete,
  overridePlayIcon,
  containerStyle,
  controls,
  ...rest
}) => {
  const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const OverridePlayIcon = overridePlayIcon;

  const play = () => {
    setIsPlaying(true);
    setIsFinished(false);
    playerRef.current.play();
  };

  const pause = () => {
    setIsPlaying(false);
    setIsFinished(false);
    playerRef.current.pause();
  };

  useEffect(() => {
    const player = playerRef.current;
    player.addEventListener('ended', onVideoEnd, false);
    function onVideoEnd(e) {
      setIsPlaying(false);
      setIsFinished(true);
      if (onComplete && typeof onComplete === 'function') onComplete();
    }
    return () => player.removeEventListener('ended', onVideoEnd, false);
  }, [onComplete]);

  return (
    <VideoPlayerContainer sx={containerStyle}>
      <video
        ref={playerRef}
        width='100%'
        onClick={() => {
          if (isPlaying) pause();
        }}
        controls={isPlaying && controls}
        {...rest}
      >
        <source src={src} type='video/mp4' />
        Your browser does not support HTML video.
      </video>
      {!isPlaying && (
        <VideoPlayerPlayButton>
          {!isFinished && !isPlaying && !overridePlayIcon && (
            <PlayIcon onClick={() => play()} />
          )}
          {!isFinished && !isPlaying && overridePlayIcon && (
            <OverridePlayIcon onClick={() => play()} />
          )}
          {isFinished && <ReplayIcon onClick={() => play()} />}
        </VideoPlayerPlayButton>
      )}
    </VideoPlayerContainer>
  );
};

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  overridePlayIcon: PropTypes.object,
  rest: PropTypes.object,
  containerStyle: PropTypes.object,
};

export default VideoPlayer;
