const getStyles = (theme) => {
  return {
    container: {
      backgroundColor: theme.palette.primary.fuchsia,
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.white,
      minHeight: '100vh',
    },
    formContainer: {
      paddingTop: '25px',
    },
    whiteBox: {
      backgroundColor: theme.palette.primary.white,
      width: 295,
      height: 435,
      padding: '25px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
    },
    note: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.gray75,
      fontSize: 14,
      paddingTop: '20px',
      textAlign: 'center',
      marginBottom: '20px'
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatar: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.black,
      fontSize: 24,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.fuchsia10,
      width: 70,
      height: 70,
    },
    buttonContainer: {
      paddingTop: '25px',
      width: '300px',
    },
  };
};
export default getStyles;
