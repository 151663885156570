import { Box, styled } from '@mui/system';

import { FilledButton, LinkButton } from 'components/Buttons';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.white,
    flexDirection: 'column',
    justifyContent: 'stretch',
    gap: '5px',
    padding: '25px 0px',
  },
}));

export const CancelButton = styled(LinkButton)(({ theme }) => ({
  color: theme.palette.primary.fuchsia75,
  height: '50px',
  width: '100%',
}));

export const OkButton = styled(FilledButton)(({ theme }) => ({
  height: '56px',
  width: '100%',
}));
