import { styled, useMediaQuery, useTheme } from '@mui/material';
import { FamilyLinkageInviteMemberModal } from 'features/familyLinkage/modals/sendInvite/FamilyLinkageInviteMemberModal';
import { FamilyLinkageInviteMemberSuccessModal } from 'features/familyLinkage/modals/sendInvite/FamilyLinkageInviteMemberSuccessModal';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as Add } from 'images/activity/add.svg';
import {
  MyProfilePageHeader,
  PageTitle,
  StyledBackArrow,
} from 'pages/Profile/MyProfile/styles';
import { useCallback, useState } from 'react';

export function Header() {
  const [modalView, setModalView] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onClose = useCallback(() => {
    setModalView(null);
  }, []);

  const onSuccess = useCallback(() => {
    setModalView('SUCCESS_MODAL');
  }, []);

  return (
    <>
      <MyProfilePageHeader>
        <StyledBackArrow />
        <PageTitle variant='h2'>Family Members</PageTitle>

        <InviteMemberButton
          onClick={() => setModalView('INVITE_MODAL')}
          size='xs'
          startIcon={<Add color='white' />}
        >
          {isMobile ? 'Invite' : 'Invite Family Member'}
        </InviteMemberButton>
      </MyProfilePageHeader>

      <FamilyLinkageInviteMemberModal
        onClose={onClose}
        onSuccess={onSuccess}
        open={modalView === 'INVITE_MODAL'}
      />

      <FamilyLinkageInviteMemberSuccessModal
        onClose={onClose}
        open={modalView === 'SUCCESS_MODAL'}
      />
    </>
  );
}

const InviteMemberButton = styled(Button)(() => ({
  alignSelf: 'baseline',
  marginLeft: 'auto',
  span: {
    margin: 0,
  },
  svg: {
    path: {
      fill: 'white',
    },
  },
}));
