import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const consentApiV3 = createApi({
  reducerPath: 'consentApiV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Consents'],
  endpoints: (build) => ({
    getConsentsList: build.query({
      query: (params) => ({
        url: `/app/people/${params.person_id}/study-roles/${params.study_role_id}/consents`,
        method: 'GET',
      }),
      providesTags: ['Consents'],
    }),
    getAllConsentsList: build.query({
      query: (params) => ({
        url: `/app/people/${params.person_id}/study-roles/${params.study_role_id}/consents?filter[status]=active,archived`,
        method: 'GET',
      }),
      providesTags: ['Consents'],
    }),
    fetchConsentDetails: build.query({
      query: (params) => ({
        url: `app/people/${params.person_id}/study-roles/${params.study_role_id}/consents/${params.consent_id}`,
        method: 'GET',
      }),
      providesTags: ['Consents'],
    }),
  }),
});

export const {
  useGetConsentsListQuery,
  useGetAllConsentsListQuery,
  useFetchConsentDetailsQuery,
} = consentApiV3;
