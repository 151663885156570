import * as React from 'react';
import PropTypes from 'prop-types';
import { theme } from 'theme';
import { Typography } from '@mui/material';
import { OptionItemWrapper, CheckIcon } from './styles';

export const OptionItem = (props, option, { selected }) => {
  const backgroundColor = selected
    ? theme.palette.primary.gray2
    : theme.palette.primary.white;
  const fontColor = selected
    ? theme.palette.primary.main
    : theme.palette.primary.gray75;

  return (
    <li {...props} style={{ backgroundColor }}>
      <OptionItemWrapper>
        <Typography color={fontColor}>{option.label}</Typography>
        {selected && <CheckIcon />}
      </OptionItemWrapper>
    </li>
  );
};

OptionItem.propTypes = {
  selected: PropTypes.bool,
  option: PropTypes.object,
  props: PropTypes.object,
};
