import { styled } from '@mui/system';
import { Box } from '@mui/material';

const padding = '0 20px';

export const Cell = styled(Box)(({ width = 0, align }) => ({
  width,
  flexGrow: width === 0 ? 1 : 0,
  display: 'flex',
  justifyContent: align === 'right'
    ? 'flex-end'
    : align === 'center'
      ? 'center'
      : 'flex-start',
  overflow: 'hidden',
}));

export const BodyRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  border: `1px solid ${theme.palette.primary.gray15}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  height: '60px',
  padding,
}));

export const HeaderRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding,

  textTransform: 'uppercase',
});