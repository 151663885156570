import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const resourceKeywordsApiSlice = createApi({
  reducerPath: 'resourceKeywordsApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Resource-keywords'],
  endpoints: (build) => ({
    getAllResourceKeywords: build.query({
      query: ({ ...params }) => ({
        url: `/resource-keywords`,
        method: 'GET',
        params,
      }),
      providesTags: ['Resource-keywords'],
    }),
  }),
});

export const {
  useGetAllResourceKeywordsQuery,
} = resourceKeywordsApiSlice;
