import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetReportersQuery } from 'ihp-bloom-redux/features/onboarding/reporterPageSlice';
import Button from 'ihp-components/components/v2/Button';
import Chip from 'ihp-components/components/v2/Chip';

import { Loader } from 'components/Loader';
import { AddReporterModal } from 'components/Reporters/AddReporterModal';
import { DeleteReporterModal } from 'components/Reporters/DeleteReporterModal';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-4.svg';
import { ReactComponent as MailIcon } from 'images/profile/add-reporter/email.svg';
import { ReactComponent as PersonIcon } from 'images/profile/add-reporter/user.svg';
import { ReactComponent as Pen } from 'images/shared/pencil.svg';

import {
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_CONFIG_VARIABLES,
} from 'constants/global';
import { RELATIONSHIP_NAME_MAP } from 'constants/reporters';

import {
  FieldContainer,
  ReporterContainer,
  RelationContainer,
  NameHeaderContainer,
  AlternativeReporterFullNameContainer,
  FlexedBox,
  EmailContainer,
  PhoneContainer,
  ButtonContainer,
  EditDeleteButton,
  TrashIcon,
} from './styles';

const ReportersList = () => {
  const theme = useTheme();

  const { activeAccountProfile } = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);
  const [reporters, setReporters] = useState();
  const [openAddReporterModal, setOpenAddReporterModal] = useState(false);
  const [selectedReporter, setSelectedReporter] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const {
    data,
    isLoading: isReportersLoading,
    isFetching: isReporterFetching,
    refetch: reFetchReporters,
  } = useGetReportersQuery({
    'filter[account_profile_id]': activeAccountProfile?.attributes?.profile?.id,
    'filter[study_arm_role_name]': 'reporter',
    'filter[study_arm_code]': 'research',
    'page[limit]': 100000,
  });

  const reporterInvitedStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_REPORTER_INVITED
    ].id;

  const mapChips = {
    registered: {
      variant: 'filled',
      type: 'success',
      text: 'Registered',
      backgroundColor: theme.palette.primary.lightGreen10,
      customStyle: {
        color: theme.palette.primary.green,
        textTransform: 'capitalize',
        padding: '5px 10px',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
      },
    },
    invited: {
      variant: 'filled',
      type: 'success',
      text: 'Invited',
      backgroundColor: theme.palette.primary.lightBlue7,
      customStyle: {
        color: theme.palette.primary.deepBlue,
        textTransform: 'capitalize',
        padding: '5px 10px',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: 'normal',
      },
    },
  };
  const getChips = ({ variant, type, text, backgroundColor, customStyle }) => {
    return (
      <Chip
        variant={variant}
        type={type}
        sx={{ ...customStyle, backgroundColor: backgroundColor }}
      >
        {text}
      </Chip>
    );
  };
  useEffect(() => {
    if (data) {
      setReporters(data?.data);
    }
  }, [data]);

  useEffect(() => {
    reFetchReporters();
  }, []);

  if (isReportersLoading || isReporterFetching) {
    return <Loader />;
  }

  const onClose = () => {
    setOpenAddReporterModal(false);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleAddNewReporter = () => {
    setSelectedReporter(null);
    setOpenAddReporterModal(true);
  };
  const handleEditReporter = (id) => {
    const selectedReporter = reporters.filter((r) => r.id == id)[0];
    setSelectedReporter(selectedReporter?.attributes);
    setOpenAddReporterModal(true);
  };

  const handleDeleteReporter = (id) => {
    const selectedReporter = reporters.filter((r) => r.id == id)[0];
    setSelectedReporter(selectedReporter?.attributes);
    setOpenDeleteModal(true);
  };

  return (
    <div>
      {openDeleteModal && (
        <DeleteReporterModal
          open={openDeleteModal}
          onClose={onCloseDeleteModal}
          selectedReporter={selectedReporter}
        />
      )}
      {openAddReporterModal && (
        <AddReporterModal
          open={openAddReporterModal}
          onClose={onClose}
          selectedReporter={selectedReporter}
        />
      )}
      <FieldContainer>
        <Button
          variant='outlined'
          fullWidth
          style={{ border: '2px solid' }}
          startIcon={<PlusIcon width={14} height={14} />}
          onClick={() => handleAddNewReporter()}
        >
          Add new reporter
        </Button>
      </FieldContainer>
      {reporters &&
        reporters?.map((reporter) => {
          let reporterData = reporter?.attributes?.person_study_arm_attributes;
          let reporterPersonStudyArm = reporter?.attributes?.person_study_arm;
          return (
            <ReporterContainer key={''}>
              <Stack>
                <NameHeaderContainer>
                  <AlternativeReporterFullNameContainer>
                    {reporterData?.first_name} {reporterData?.last_name}
                  </AlternativeReporterFullNameContainer>
                  <FlexedBox>
                    {reporterPersonStudyArm?.study_arm_status_id ==
                    reporterInvitedStatusId
                      ? getChips(mapChips['invited'])
                      : getChips(mapChips['registered'])}
                  </FlexedBox>
                </NameHeaderContainer>
                <FlexedBox>
                  <PersonIcon
                    width='14px'
                    height='14px'
                    color={theme.palette.primary.darkGray70}
                  />
                  <RelationContainer>
                    {RELATIONSHIP_NAME_MAP[reporterData?.relationship]}
                  </RelationContainer>
                </FlexedBox>
                <FlexedBox>
                  <Box marginTop='11px'>
                    <MailIcon
                      width='14px'
                      height='14px'
                      color={theme.palette.primary.darkGray70}
                    />
                  </Box>
                  <EmailContainer>{reporterData?.email}</EmailContainer>
                </FlexedBox>
                {reporterData?.phone && reporterData?.phone != '+' && (
                  <FlexedBox>
                    <Box marginTop='11px'>
                      <PhoneIcon
                        width='14px'
                        height='14px'
                        color={theme.palette.primary.darkGray70}
                      />
                    </Box>
                    <PhoneContainer>
                      {reporterData?.phone}
                      {(reporterData?.secondary_contact == '1' ||
                        reporterData?.secondary_contact == 'true') &&
                        ' (Alternative contact)'}
                    </PhoneContainer>
                  </FlexedBox>
                )}
                <ButtonContainer>
                  <EditDeleteButton
                    variant='outlined'
                    width='50%'
                    startIcon={<TrashIcon />}
                    onClick={() => handleDeleteReporter(reporter.id)}
                  >
                    Delete
                  </EditDeleteButton>
                  <EditDeleteButton
                    width='50%'
                    styles={{
                      marginLeft: '8px',
                      background: theme.palette.primary.main,
                      color: theme.palette.primary.white,
                    }}
                    startIcon={<Pen width='14px' height='14px' />}
                    onClick={() => handleEditReporter(reporter.id)}
                  >
                    Edit
                  </EditDeleteButton>
                </ButtonContainer>
              </Stack>
            </ReporterContainer>
          );
        })}
    </div>
  );
};

export default ReportersList;
