import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { flatten } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  resetInProgressProviders,
  updateFinishDataShareEnabled,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import {
  selectParticipantTask,
  setParticipantTask,
  setSelectedParticipantTaskAttributes,
  setSelectedParticipantTaskId,
  selectSelectedParticipanttaskId,
} from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { participantTask } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import { CenteredFullPageWrapper } from 'components/CenteredFullPageWrapper';
import ActivityInstructions from 'components/Activities/Instructions';
import ActivityHeader from 'components/Activities/Header';
import VideoPlayer from 'components/VideoPlayer';
import { Loader } from 'components/Loader';
import { useInitiateDataFetch } from '../hooks';
import { selectFromPatientTaskResult } from 'pages/Activities/ScheduleCall/utils';
import { taskTypeLabelMapping } from 'pages/Activities/utils';
import {
  fetchSMProviders,
  fetchSyncedData,
  getFilteredProviders,
  getHealthProviders,
  getProviderDetails,
  getPatientNames,
} from 'services/oneUpService';
import { convertUTCStringToLocalDateObject, dateFormatter } from 'utils/date';
import { SearchProvidersDesktop } from './index.desktop';
import { EMR_VIDEO_URL } from 'config/API';
import { Typography, useMediaQuery } from '@mui/material';
import { useGetProfileParticipantTask } from 'pages/Activities/hooks';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';
import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { withPerson } from 'authentication/withPerson';

function SearchProvidersV2() {
  document.title = 'Single Ventricle SOURCE EMR';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [clickedContinue, setClickedContinue] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [loadingInstructions, setLoadingInstruction] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [loadingConnectedProviders, setLoadingConnectedProviders] =
    useState(true);
  const [hasConnectedProviders, setHasConnectedProviders] = useState(false);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [manualProviders, setManualProviders] = useState([]);
  const [hasSearchTerm, setHasSearchTerm] = useState(false);
  const [namesMap, setNamesMap] = useState([]);
  const [typeMessage, setTypeMessage] = useState('');

  const selectedParticipantTask = useSelector(participantTask());
  const selectedTaskId = useSelector(selectSelectedParticipanttaskId());

  const onSuccess = () => navigate('/my-activities');
  const { initiate, loading } = useInitiateDataFetch(onSuccess);

  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { finishDataShareEnabled, inProgressProviders } = oneUpHealthData;
  const [updateParticipantTaskMutation, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();

  const [allConnectedProviders, setAllConnectedProviders] = useState({
    entries: [],
    connected: [],
    hasErrors: false,
  });

  const { activeAccountProfile, person } = useSelector((state) => state.user);

  const newPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id ||
    selectedParticipantTask?.personId;
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;
  const firstName = activeAccountProfile?.attributes?.profile?.first_name;
  const lastName = activeAccountProfile?.attributes?.profile?.last_name;
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('id');
  const pTaskById = useSelector(selectParticipantTask(Number(taskId)));

  if (taskId) {
    dispatch(setSelectedParticipantTaskId({ taskId: Number(taskId) }));
  }

  const participantTaskDetails = useGetProfileParticipantTask(
    taskId ?? selectedTaskId,
    accountProfileId
  );

  // TODO: refactor all api calls to be with RTQ queries
  const fetchTokenAndProviders = useCallback(async () => {
    let hasConnected;
    let token;
    let hasData = false;
    token = await getAccessTokenSilently();
    if (!newPersonId || !token) {
      return;
    }
    try {
      setLoadingConnectedProviders(true);
      setLoadingProviders(true);
      fetchSyncedData(token, newPersonId);
      const participantTaskProviders = await fetchSMProviders(
        token,
        newPersonId,
        participantTaskDetails?.id
      );

      const filteredProviders = participantTaskProviders?.data?.filter(
        (p) => p?.attributes?.status === 'success'
      );

      hasData = participantTaskProviders?.meta?.page?.total > 0;
      let hasPreviouslyConnected = hasData;
      hasConnected = inProgressProviders.length > 0 || hasPreviouslyConnected;

      let participantTaskProvidersIds = filteredProviders?.map((mp) =>
        parseInt(mp?.attributes?.health_system_id)
      );
      participantTaskProvidersIds = [...new Set(participantTaskProvidersIds)];

      const details = await getProviderDetails(
        token,
        participantTaskProvidersIds
      );

      const formatedParticipantTaskProviders = filteredProviders?.map((c) => {
        const detail = details?.filter(
          (d) => d.id === parseInt(c?.attributes?.health_system_id)
        )[0];
        if (detail) {
          c['logo'] = detail.logo;
          c['name'] = detail.name;
          c['locations'] = detail.locations;
        }
        return c;
      });

      setAllConnectedProviders({
        entries: formatedParticipantTaskProviders,
        hasErrors: false,
      });

      setHasConnectedProviders(hasConnected);
    } catch (error) {
      console.error(error);
    }
    setLoadingProviders(false);
    setLoadingConnectedProviders(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getAccessTokenSilently,
    inProgressProviders,
    newPersonId,
    participantTaskDetails?.id,
  ]);

  useEffect(() => {
    if (participantTaskDetails?.id) {
      fetchTokenAndProviders();
    }
  }, [fetchTokenAndProviders, participantTaskDetails?.id]);

  const [showInstructions, setShowInstructions] = useState(undefined);
  useEffect(() => {
    if (pTaskById) {
      dispatch(setParticipantTask(pTaskById));
    }
  }, [dispatch, pTaskById]);

  const howToData = participantTaskDetails?.attributes?.howTo;
  let data = {
    type: participantTaskDetails?.type,
    title: 'Share your medical records',
    timingDuration: participantTaskDetails?.estimatedTime,
    timeLimit: participantTaskDetails?.timeLimit,
    rewardPoints:
      participantTaskDetails?.point ?? selectedParticipantTask?.points,
    temp: {
      howTo: {
        title: participantTaskDetails?.title ?? 'Share your medical records',
        subtitle: howToData?.subtitle ?? 'Securely share your medical records.',
        instructions:
          howToData?.instructions ??
          `Connect to your healthcare provider to share your medical records electronically. From your medical records we only use information relevant to the study.`,
        display: howToData?.display ?? false,
      },
    },
  };

  const isDifferentPerson =
    accountPersonStudyArmId !== participantTaskDetails?.personStudyArmId;

  useEffect(() => {
    if (isDifferentPerson) {
      const roleName =
        activeAccountProfile?.attributes?.profile?.study_arm_role_name;

      const isLar = roleName === STUDY_ARM_ROLE_NAME.LAR;
      const isReporter = roleName === STUDY_ARM_ROLE_NAME.REPORTER;
      let suffix = '';
      if (isLar) {
        suffix = `On behalf of`;
      }
      if (isReporter) {
        suffix = `Related to`;
      }
      if (isLar || isReporter) {
        setTypeMessage(
          <p>
            {suffix} <br />
            <Typography
              variant='h5'
              fontSize={'16px'}
              color='primary.darkGray100'
            >
              {firstName} {lastName}
            </Typography>
          </p>
        );
      }
    }
  }, []);

  useEffect(() => {
    if (
      participantTaskDetails?.attributes?.howTo &&
      loadingInstructions &&
      !hasConnectedProviders
    ) {
      setShowInstructions(howToData?.display ?? false);
      setLoadingInstruction(false);
    }

    const showList =
      taskId?.length > 0 ? taskId?.length > 0 && clickedContinue : true;
    if (hasConnectedProviders && showList) {
      setShowInstructions(false);
      setLoadingInstruction(false);
    }
  }, [
    hasConnectedProviders,
    participantTaskDetails?.attributes?.howTo,
    taskId,
    clickedContinue,
  ]);

  const videoElement = (
    <VideoPlayer
      src={EMR_VIDEO_URL}
      height={'auto'}
      width={isMobile ? '100%' : '560px'}
      poster={''}
      controls
      containerStyle={{
        marginTop: '25px',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    />
  );

  const toggleShowInstructions = () => {
    if (showInstructions && taskId && taskId.length > 0) {
      setClickedContinue(true);
    }
    setShowInstructions((prev) => !prev);
  };

  if (
    showInstructions &&
    !participantTaskDetails?.loadingParticipantTask &&
    !loadingConnectedProviders &&
    (oneUpHealthData?.lastConnectedProvider?.isCheckStateTransitioned == null ||
      oneUpHealthData?.lastConnectedProvider?.isCheckStateTransitioned === true)
  ) {
    const estimatedTime = data.timingDuration
      ? `${data.timingDuration} min`
      : selectedParticipantTask?.duration;
    return (
      <>
        <ActivityHeader
          points={data.rewardPoints}
          type={taskTypeLabelMapping[data.type]}
          estimatedTime={
            data.timingDuration
              ? `${data.timingDuration} min`
              : selectedParticipantTask?.duration
          }
          timeLimit={data.timeLimit ? `${data.timeLimit} min` : data.timeLimit}
          showStepCounter={false}
          title={isMobile ? data.temp.howTo.title : 'My Activities'}
          subtitle={`Medical Records - ${estimatedTime}`}
        />
        <CenteredFullPageWrapper>
          <ActivityInstructions
            hideMetaData={isMobile}
            hideInstructionsTitle={!isMobile}
            title={data.temp.howTo.title}
            type={taskTypeLabelMapping[data.type]}
            estimatedTime={
              data.timingDuration
                ? `${data.timingDuration} min`
                : selectedParticipantTask?.duration
            }
            timeLimit={
              data.timeLimit ? `${data.timeLimit} min` : data.timeLimit
            }
            points={data.rewardPoints}
            subtitle={data?.temp?.howTo.subtitle}
            description={data?.temp?.howTo.instructions}
            onClickContinue={toggleShowInstructions}
            onCancel={() => navigate('/my-activities')}
            startButtonText='Continue'
            video={videoElement}
            participantInfo={typeMessage}
          />
        </CenteredFullPageWrapper>
      </>
    );
  }

  const setFiltered = async (newSearchTerm) => {
    if (newSearchTerm?.length >= 4) {
      setLoadingProviders(true);
      try {
        const token = await getAccessTokenSilently();
        const providers = await getFilteredProviders(token, newSearchTerm);
        setLoadingProviders(false);
        setFilteredProviders(providers);
      } catch (task) {
        setLoadingProviders(false);
        setFilteredProviders([]);
      }
    } else {
      setFilteredProviders([]);
    }
  };

  const updateSelectedTask = async (status, callback) => {
    if (!selectedParticipantTask) {
      console.log('Selected participant task is missing');
      return;
    }
    const start_time = dateFormatter(
      convertUTCStringToLocalDateObject(selectedParticipantTask?.date)
    );

    const end_time = dateFormatter(new Date());
    const requestBody = {
      participantTaskId: selectedParticipantTask.participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(selectedParticipantTask.participantTaskId),
          attributes: { status, start_time, end_time },
        },
      },
    };

    updateParticipantTaskMutation(requestBody)
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          return;
        }
        // save response in state to use on celebration screen
        dispatch(
          setSelectedParticipantTaskAttributes({
            attributes: {
              ...response?.data?.data?.attributes,
              compensationValue: participantTaskDetails?.point,
              thankyouMsg:
                participantTaskDetails?.attributes?.celebrationScreen?.title,
              description:
                participantTaskDetails?.attributes?.celebrationScreen
                  ?.description,
              display:
                participantTaskDetails?.attributes?.celebrationScreen?.display,
            },
          })
        );
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(console.error);
  };

  const handleFinish = async () => {
    dispatch(updateFinishDataShareEnabled(false));
    dispatch(resetInProgressProviders());
    if (selectedParticipantTask != null) {
      await updateSelectedTask('completed', async () => {
        await initiate();
        navigate('/activity/sync-data-final');
      });
    } else {
      dispatch(
        setSelectedParticipantTaskAttributes({
          attributes: {
            compensationValue: participantTaskDetails?.point,
            thankyouMsg:
              participantTaskDetails?.attributes?.celebrationScreen?.title,
            description:
              participantTaskDetails?.attributes?.celebrationScreen
                ?.description,
            display:
              participantTaskDetails?.attributes?.celebrationScreen?.display,
          },
        })
      );
      await initiate();
      navigate('/activity/sync-data-final');
    }
  };

  const handleAddMoreLater = async () => {
    await updateSelectedTask('in_progress', async () => {
      await initiate(); // trigger oneup initiate data fetch
      navigate('/my-activities');
    }); // save task as draft
  };

  if (
    loadingConnectedProviders ||
    loading ||
    isRedirecting ||
    participantTaskDetails?.loadingParticipantTask ||
    isUpdatingTask
  ) {
    return <Loader />;
  }

  const onAddManualProvider = (provider, oldProviderId) => {
    if (oldProviderId) {
      return setManualProviders([
        provider,
        ...manualProviders.filter((p) => p.id !== oldProviderId),
      ]);
    } else {
      return setManualProviders([provider, ...manualProviders]);
    }
  };

  const onDeleteManualProvider = (provider) => {
    return setManualProviders([
      ...manualProviders.filter((p) => p.id !== provider.id),
    ]);
  };

  return (
    <SearchProvidersDesktop
      providers={filteredProviders}
      connectedProviders={allConnectedProviders.entries}
      loadingProviders={loadingProviders}
      setLoadingProviders={setLoadingProviders}
      setLoadingConnectedProviders={setLoadingConnectedProviders}
      setIsRedirecting={setIsRedirecting}
      hasSearchTerm={hasSearchTerm}
      setHasSearchTerm={setHasSearchTerm}
      setFiltered={setFiltered}
      handleFinish={handleFinish}
      finishDataShareEnabled={finishDataShareEnabled}
      hasConnectedProviders={hasConnectedProviders}
      isLoading={loadingProviders}
      duration={
        data?.timingDuration
          ? `${data?.timingDuration} min`
          : selectedParticipantTask?.duration
      }
      timeRemaining={data.timeLimit ? `${data.timeLimit} min` : data.timeLimit}
      points={data?.rewardPoints}
      updateSelectedTask={updateSelectedTask}
      isUpdatingTask={isUpdatingTask}
      handleAddMoreLater={handleAddMoreLater}
      participantTaskId={taskId}
      taskPersonId={pTaskById?.personId ?? newPersonId}
      addManualProvider={onAddManualProvider}
      deleteManualProvider={onDeleteManualProvider}
      namesMap={namesMap}
      personName={firstName + ' ' + lastName}
      isChildTask={data.temp.howTo.title.toLowerCase().includes('child')}
    />
  );
}

export default withPerson(withSelectedProfile(SearchProvidersV2));