import { Modal } from '@mui/material';

import {
  ContentWrapper,
  AcmeLogo,
  ButtonContainer,
  StyledLink,
  Title,
  Text,
  AddedEverythingButton
} from './styles';

export const ConfirmationModal = ({ open, onClose, handleConfirmation }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ContentWrapper>
        <AcmeLogo />
        <Title>Almost done</Title>
        <Text>
          Do you have any more medical records you would like to share?
        </Text>
        <ButtonContainer>
          <AddedEverythingButton fullWidth onClick={handleConfirmation}>
            I’ve added everything
          </AddedEverythingButton>
          <StyledLink onClick={onClose}>Go back to add more</StyledLink>
        </ButtonContainer>
      </ContentWrapper>
    </Modal>
  );
};
