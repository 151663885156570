import { isValidElement, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetStudyArmRolePermissionsQuery } from 'ihp-bloom-redux/features/studyArms/studyArmConfigApiSlice';
import {
  setParticipantProfileConfig,
  setStudyEnumerations,
} from 'ihp-bloom-redux/features/settings/configurationSlice';
import { useGetStudyEnumerationsQuery } from 'ihp-bloom-redux/features/study/studyApiV3';

import { Loader } from 'components/Loader';
import { useLogout } from 'utils/useLogout';
import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';
import { Navigate } from 'react-router-dom';

export const withStudyArmRolePermissions = (Component) => (props) => {
  const dispatch = useDispatch();
  const logout = useLogout();
  const isActiveProfileAlreadyExists = useRef();

  const { activeAccountProfile, person } = useSelector((state) => state.user);

  if (
    isActiveProfileAlreadyExists?.current === undefined &&
    activeAccountProfile?.id
  ) {
    isActiveProfileAlreadyExists.current = true;
  }

  const {
    isLoadingAccountProfileDetails,
    defaultProfileNotFound,
    noActiveProfiles,
  } = useGetAccountProfileDetails({
    personId: person?.id,
    shouldSelectDefaultProfile: !isActiveProfileAlreadyExists?.current,
  });

  const studyArmRoleId =
    activeAccountProfile?.attributes?.profile?.study_arm_role_id;

  const { data, isLoading, isFetching, isUninitialized, isError, isSuccess } =
    useGetStudyArmRolePermissionsQuery(
      {
        params: `filter[study_arm_role_id]=${studyArmRoleId}`,
      },
      {
        skip: !activeAccountProfile?.id,
      }
    );

  const {
    data: enumerations,
    isLoading: isLoadingEnumerations,
    isFetching: isFetchingEnumerations,
    isError: isErrorEnumerations,
    isSuccess: isSuccessEnumerations,
  } = useGetStudyEnumerationsQuery({
    'page[limit]': 100000,
  });

  const participantProfileConfig = data?.data?.[0]?.attributes?.permission;

  // Logout if there is an error or if the person is not found
  useEffect(() => {
    if (isError) {
      console.log(
        'Something went wrong "Study arm role permissions" cannot be fetched'
      );
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data && participantProfileConfig) {
      dispatch(setParticipantProfileConfig(participantProfileConfig));
    }
  }, [data, isSuccess, dispatch]);

  useEffect(() => {
    if (isSuccessEnumerations && enumerations) {
      const enums = enumerations?.data.reduce((accum, currentValue) => {
        const copy = { ...accum };
        const attribute = currentValue?.attributes?.attribute_name;
        const title = currentValue?.attributes?.title;
        const code = currentValue?.attributes?.code;

        if (!copy[attribute]) {
          copy[attribute] = [
            {
              label: title,
              value: code,
            },
          ];
        } else {
          copy[attribute] = [
            ...copy?.[attribute],
            {
              label: title,
              value: code,
            },
          ];
        }
        return copy;
      }, {});
      console.log('enumerations: ', enums);
      dispatch(setStudyEnumerations(enums));
    }
  }, [enumerations, isSuccessEnumerations, dispatch]);

  if (noActiveProfiles && !isActiveProfileAlreadyExists?.current) {
    return <Navigate to='/no-active-profile' replace />;
  }

  if (defaultProfileNotFound && !isActiveProfileAlreadyExists?.current) {
    return <Navigate to='/select-profile' replace />;
  }

  if (
    isLoading ||
    isFetching ||
    isUninitialized ||
    isLoadingEnumerations ||
    isFetchingEnumerations ||
    isLoadingAccountProfileDetails
  ) {
    return <Loader />;
  }

  const componentToRender = (
    <Component {...props} participantProfileConfig={participantProfileConfig} />
  );
  return isValidElement(componentToRender) ? componentToRender : null;
};
