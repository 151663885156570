import { Box, styled, Typography } from '@mui/material';
import { ModalContainer } from 'components/Study/modal';

export const Container = styled(ModalContainer)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  height: 'fit-content',
  maxWidth: 'min(400px, 90%)',
}));

export const Content = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '40px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.secondary.gray4,
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign: 'center',
}));

export const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.pl3,
  color: theme.palette.primary.darkGray70,
  textAlign: 'center',
}));

export const Buttons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
}));
