import React from 'react';
import { Box, Avatar } from '@mui/material';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { LoginContainer, EmailContainer, FormLabel } from 'components/Login';
import { BoxItem } from 'components/PersonalInformation';
import Button from 'ihp-components/components/v2/Button';
import { OutlineButton } from 'components/Buttons/Outline';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import getStyles from './styles/confirm.mobile.styles';
import avatar from 'images/personalinformation/avatar.svg';
// import calendar from 'images/personalinformation/calendar.svg';
// import gender from 'images/personalinformation/gender.svg';
// import location from 'images/personalinformation/location.svg';
// import barcode from 'images/personalinformation/barcode.svg';

function ConfirmPersonalInformationMobile() {
  const theme = useTheme();
  const styles = getStyles(theme);
  let navigate = useNavigate();
  const {
    firstName,
    lastName,
    email /* dob, gender, zipCode, studyIdentifier */,
  } = useSelector((state) => state.user);

  const navigateToViewConsent = () => {
    navigate('/informedconsent');
  };

  const handleUpdate = () => {
    navigate('/personalinformation');
  };

  return (
    <LoginContainer>
      <EmailContainer>
        <Box sx={styles.formContainer} component='div'>
          <FormLabel>Confirm your information</FormLabel>
        </Box>
        <Box sx={styles.whiteBox}>
          <Box sx={styles.avatarContainer}>
            <Avatar sx={styles.avatar}>
              {firstName[0]}
              {lastName[0]}
            </Avatar>
          </Box>
          <Box sx={styles.note}>
            Please confirm your personal information provided by your Gmail
            account.
          </Box>
          <BoxItem icon={avatar} label={`${firstName} ${lastName}`} />
          <BoxItem icon={avatar} label={email} />
          {/* <BoxItem icon={calendar} label="10 / 17 / 1985" />
          <BoxItem icon={gender} label="Female" />
          <BoxItem icon={location} label="435261" />
          <BoxItem icon={barcode} label="PSI-14503" /> */}
          <Box sx={styles.buttonContainer}>
            <Button fullWidth variant='outlined'onClick={handleUpdate}>Update</Button>
          </Box>
        </Box>
        <Box sx={styles.buttonContainer} component='div'>
          <Button fullWidth={true} onClick={navigateToViewConsent}>
            Confirm
          </Button>
        </Box>
      </EmailContainer>
    </LoginContainer>
  );
}
export default ConfirmPersonalInformationMobile;
