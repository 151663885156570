import { useState, useCallback, useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetAvailableSlotsQuery } from 'ihp-bloom-redux/features/followup/televisitApiSlice';
import {
  useUpdateParticipantTaskMutation,
  useGetParticipantTaskWithRelationshipQuery,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useGetAllPersonAttributesQuery } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';

import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { CenteredFullPageWrapper } from 'components/CenteredFullPageWrapper';
import ActivityInstructions from 'components/Activities/Instructions';
import { Loader } from 'components/Loader';
import { ReactComponent as ArrowRightIcon } from 'images/shared/arrow-right-2.svg';
import { TimeSlotsErrorModal } from './TimeSlotsErrorModal';
import {
  formatHumanizedDate,
  formatAvailableSlots,
  timeConverter,
} from '../utils';
import {
  scheduleCallScreensMap,
  dateStepDataFormat,
  timeStepDataFormat,
} from './utils';
import {
  convertDateStringToObject,
  dateFormatter,
  generateDatabaseDateTime,
} from 'utils/date';
import {
  ScheduleCallPageWrapper,
  StyledProgressButtons,
  StepperWrapper,
  StyledActivityHeader,
  getStyles,
} from './styles';
import { taskTypeLabelMapping } from '../utils';
import { BookAppointment } from './BookAppointment';
import { getFormFields } from 'pages/Profile/MyProfile/utils';
import { findStudyAttribute } from 'utils/study';
import { useGetProfileParticipantTask } from '../hooks';

const mockData = {
  title: 'Select your preferred day and time',
  description: `<p><b>Appointment Details:</b> Follow-up call. This follow-up call is to check up on you.</p>
  <p><b>Instructions:</b> Please check our Study Agents’ availability and select the most suitable day and time for your remote appointment based on the available visit window and dates shown.</p>
  <p><b>Can’t find a time that works?</b> If there are no available time slots for your preferred day, please contact the Call Center at <%call_centre_phone%>.</p>`,
};

const ScheduleCall = () => {
  document.title = 'Single Ventricle SOURCE Schedule Call';

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const styles = getStyles(theme);
  const participantTaskId = parseInt(useParams().id);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isRescheduling = searchParams.get('is_rescheduling') === 'true';

  const { studyConfiguration } = useSelector((state) => state.configuration);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [benefitAttributes, setBenefitAttributes] = useState({});
  const [restartCounter, setRestartCounter] = useState(0);

  const onClose = () => {
    setShowErrorModal(!showErrorModal);
  };

  const { activeAccountProfile, person } = useSelector((state) => state.user);

  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const { personAttributes, areAttrbsFetching } =
    useGetAllPersonAttributesQuery(person?.id, {
      skip: !person?.id,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        personAttributes: data?.included,
        areAttrbsLoading: isLoading,
        areAttrbsFetching: isFetching,
      }),
    });

  let fields = getFormFields({ timezone: 'timezone' }, personAttributes);

  const participantTaskDetails = useGetProfileParticipantTask(
    participantTaskId,
    accountProfileId
  );

  const { parentTaskEndDate, isLoading: isLoadingParticipantTaskDetailsV3 } =
    useGetParticipantTaskWithRelationshipQuery(
      {
        participantId: person?.id,
        participantTaskId: participantTaskId,
        include:
          'parent-participant-task-relationship.parent-participant-tasks',
      },
      {
        skip: !isRescheduling || !person,
        selectFromResult: ({ data, ...rest }) => {
          const parentTask = data?.included?.find(
            (item) => item.type === 'participant-tasks'
          );

          return {
            parentTaskStartDate: parentTask?.attributes?.start_date,
            parentTaskEndDate: parentTask?.attributes?.end_date,
            ...rest,
          };
        },
      }
    );

  const today = new Date();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const utcStartDate = useMemo(
    () => generateDatabaseDateTime(today),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [restartCounter]
  );

  let utcEndDate = participantTaskDetails?.endDate;

  // In case of rescheduling use parent tasks end date
  if (isRescheduling && parentTaskEndDate) {
    utcEndDate = parentTaskEndDate;
  }

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { watch, resetField } = methods;
  const values = watch();
  // Make sure on date change time value is reset
  useEffect(() => {
    if (values?.date || !showErrorModal) {
      resetField('time');
    }
  }, [values?.date, resetField, showErrorModal]);

  const {
    formatedSlots: formattedSlots,
    isLoading: isLoadingAvailableSlots,
    isFetching: isFetchingAvailableSlots,
  } = useGetAvailableSlotsQuery(
    {
      start_time: `${utcStartDate}`,
      end_time: `${utcEndDate}`,
      person_id: person?.id,
      participant_task_id: participantTaskId, // Fix me with actual participant_task_id
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !participantTaskDetails?.id,
      selectFromResult: ({ data, ...rest }) => ({
        formatedSlots: formatAvailableSlots(
          data?.data || [],
          fields?.timezone,
          utcStartDate
        ),
        ...rest,
      }),
    }
  );

  console.log('formattedSlots: ', formattedSlots);

  const [updateParticipantTask, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();

  const disableDate = useCallback(
    (date) => {
      const formattedDate = dateFormatter(date);
      return !formattedSlots[formattedDate];
    },
    [formattedSlots]
  );

  const callCentrePhone =
    findStudyAttribute(studyConfiguration || [], 'call_centre_phone') ||
    '(xxx) xxx-xxxx';

  const dateStepMetaData = {
    ...dateStepDataFormat,
    content: {
      ...dateStepDataFormat.content,
      disableHighlightToday: true,
      shouldDisableDate: disableDate,
      value: null,
      defaultDate: values?.date ? values.date : null,
      name: 'date',
    },
  };

  const timeStepMetaData = {
    ...timeStepDataFormat,
    content: {
      ...timeStepDataFormat.content,
      value: values?.time || '',
      morning: {
        options: [],
      },
      afternoon: {
        options: [],
      },
      name: 'time',
    },
  };

  const date = values?.date;

  if (date) {
    const selectedFormattedDate = dateFormatter(date);
    timeStepMetaData.header = `${timeStepMetaData.header} ${formatHumanizedDate(
      date
    )}`;
    let selectedDate = formattedSlots[selectedFormattedDate];
    if (selectedDate) {
      selectedDate?.morning?.forEach((slot) => {
        timeStepMetaData.content.morning.options.push({
          id: `${slot.utc_start_time}_${slot.utc_end_time}_${selectedFormattedDate} ${slot.start_time}`, // this will be selected
          value: `${timeConverter(slot.start_time, slot.end_time)} ${
            fields?.timezone
          }`, // this will be displayed as label
        });
      });

      selectedDate?.afternoon?.forEach((slot) => {
        timeStepMetaData.content.afternoon.options.push({
          id: `${slot.utc_start_time}_${slot.utc_end_time}_${selectedFormattedDate} ${slot.start_time}`, // this will be selected
          value: `${timeConverter(slot.start_time, slot.end_time)} ${
            fields?.timezone
          }`, // this will be displayed as label
        });
      });
    }
  }

  const handleFinish = async () => {
    const value = values?.time?.split('_');
    /* This payload will be sent to API */
    const requestBody = {
      participantTaskId: participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participantTaskId),
          attributes: {
            status: 'completed',
            entry: {
              start_time: `${value[0]}`,
              end_time: `${value[1]}`,
            },
          },
        },
      },
    };

    updateParticipantTask(requestBody)
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          setRestartCounter(restartCounter + 1);
          setShowErrorModal(true);
          return;
        }
        if (participantTaskDetails?.attributes?.celebrationScreen?.display) {
          setBenefitAttributes(response?.data?.data?.attributes); // data for celebration screen
          setCurrentStep(currentStep + 1); // Move to celebration screen
        } else {
          navigate('/my-activities');
        }
      })
      .catch(console.error);
  };

  const rescheduleTask = async () => {
    const value = values?.time?.split('_');
    const requestBody = {
      participantTaskId: participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participantTaskId),
          attributes: {
            status: 'reschedule',
            start_time: `${value[0]}`,
            end_time: `${value[1]}`,
          },
        },
      },
    };
    updateParticipantTask(requestBody)
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          setShowErrorModal(true);
          return;
        }
        navigate('/my-activities');
      })
      .catch(console.error);
  };

  const [currentStep, setCurrentStep] = useState(scheduleCallScreensMap.howTo);

  const hasHowTo = isRescheduling
    ? false
    : participantTaskDetails?.attributes?.howTo?.display;

  if (currentStep === 0 && typeof hasHowTo === 'boolean' && !hasHowTo) {
    setCurrentStep(scheduleCallScreensMap.selectDay);
  }

  const next = () => {
    if (currentStep === scheduleCallScreensMap.selectDay) {
      isRescheduling ? rescheduleTask() : handleFinish(); // submit task
    } else if (currentStep === scheduleCallScreensMap.celebration) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const back = () => {
    if (
      currentStep - 1 === scheduleCallScreensMap.howTo &&
      !participantTaskDetails?.attributes?.howTo?.display
    ) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const mapDateOnCelebrationScreen = (title, time) => {
    /*
      time: utc-start-time_utc-end-time_local-time
    */
    const timeValues = time?.split('_');
    console.log('timeValues: ', timeValues);
    let humanizedDateString = '';
    let humanizedTime = '';
    if (timeValues && timeValues[2]) {
      humanizedTime = timeConverter(timeValues[2].split(' ').pop());
      humanizedDateString = formatHumanizedDate(
        convertDateStringToObject(timeValues[2].split(' ')?.[0])
      );
      humanizedDateString = `${humanizedDateString} at ${humanizedTime} ${fields?.timezone}`;
    }

    return timeValues && timeValues[2]
      ? title.replaceAll('<scheduled_datetime>', humanizedDateString)
      : title;
  };

  let estimatedTime = 'N/A';
  if (participantTaskDetails?.estimatedTime) {
    estimatedTime = `${participantTaskDetails?.estimatedTime} min`;
  }

  let timeLimit = undefined;
  if (participantTaskDetails?.timeLimit) {
    timeLimit = `${participantTaskDetails?.timeLimit} min`;
  }
  const startButtonText =
    participantTaskDetails?.attributes?.meta?.button_name || 'Schedule Call';

  const fullScheduleCallDescription =
    participantTaskDetails?.attributes?.full_schedule_call_description ||
    mockData;

  if (
    callCentrePhone !== '' &&
    fullScheduleCallDescription?.description !== ''
  ) {
    fullScheduleCallDescription.description =
      fullScheduleCallDescription?.description?.replaceAll(
        '<%call_centre_phone%>',
        callCentrePhone
      );
  }

  const stepsMap = {
    [scheduleCallScreensMap.howTo]: (
      <CenteredFullPageWrapper>
        <ActivityInstructions
          hideMetaData={isMobile}
          hideInstructionsTitle={!isMobile}
          title={participantTaskDetails?.title}
          type={
            taskTypeLabelMapping[
              participantTaskDetails?.type || 'call_schedule'
            ]
          }
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          points={participantTaskDetails?.point}
          description={participantTaskDetails?.attributes?.howTo?.instructions}
          startButtonText='Continue'
          onClickContinue={next}
          onCancel={() => navigate('/my-activities')}
        />
      </CenteredFullPageWrapper>
    ),
    [scheduleCallScreensMap.selectDay]: (
      <BookAppointment
        showAvailableSlots={values.date !== undefined}
        scheduleCallDescription={fullScheduleCallDescription}
        dateStepMetaData={dateStepMetaData}
        timeStepMetaData={timeStepMetaData}
        callCentrePhone={callCentrePhone}
        slotsAreAvailable={Boolean(Object.keys(formattedSlots)?.length)}
      />
    ),
    [scheduleCallScreensMap.celebration]: (
      <CelebrationScreen
        attributes={{
          points: participantTaskDetails?.point || 10,
          thankyouMsg: mapDateOnCelebrationScreen(
            participantTaskDetails?.attributes?.celebrationScreen?.title,
            values?.time
          ),
          description:
            participantTaskDetails?.attributes?.celebrationScreen?.description,
          ...benefitAttributes,
        }}
        maxHeight={false}
        sx={styles.celebrationScreenContainer}
      />
    ),
  };

  const getButtonLabel = () => {
    if (currentStep === scheduleCallScreensMap.celebration) {
      return 'Done';
    }
    if (
      currentStep === scheduleCallScreensMap.howTo ||
      currentStep === scheduleCallScreensMap.selectDay
    ) {
      return startButtonText;
    }
    return 'Continue';
  };

  const shouldDisableNextButton = () => {
    if (values?.date && values?.time) {
      return false;
    }

    return true;
  };

  const isCelebrationScreen =
    currentStep === scheduleCallScreensMap.celebration;

  if (
    participantTaskDetails?.loadingParticipantTask ||
    areAttrbsFetching ||
    isLoadingParticipantTaskDetailsV3
  ) {
    return <Loader />;
  }

  if (isLoadingAvailableSlots || isFetchingAvailableSlots) {
    return <Loader />;
  }

  if (isUpdatingTask && !isRescheduling) {
    return <Loader />;
  }

  if (isUpdatingTask && isRescheduling) {
    return <Loader />;
  }

  return (
    <ScheduleCallPageWrapper>
      {!isCelebrationScreen && (
        <StyledActivityHeader
          title='My Activities'
          points={participantTaskDetails?.point}
          type={
            taskTypeLabelMapping[
              participantTaskDetails?.type || 'call_schedule'
            ]
          }
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          currentStep={currentStep}
          lastStep={2}
          showStepCounter={false}
        />
      )}
      <StepperWrapper>
        <FormProvider {...methods}>{stepsMap[currentStep]}</FormProvider>
        {currentStep > 0 && (
          <StyledProgressButtons
            isLastStep={currentStep === scheduleCallScreensMap.celebration}
            buttonProps={{
              endIcon: isCelebrationScreen ? null : <ArrowRightIcon />,
              label: getButtonLabel(),
              onClick: next,
              disabled: shouldDisableNextButton(),
              maxWidth:
                currentStep === scheduleCallScreensMap.celebration
                  ? '500px'
                  : '295px',
            }}
            linkProps={{
              label: 'Back',
              disabled: currentStep === scheduleCallScreensMap.celebration,
              onClick: back,
              hide: currentStep === scheduleCallScreensMap.celebration,
            }}
          />
        )}
      </StepperWrapper>
      <TimeSlotsErrorModal open={showErrorModal} onClose={onClose} />
    </ScheduleCallPageWrapper>
  );
};

export default ScheduleCall;
