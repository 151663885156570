import {
  STUDY_ARM_CODE,
  STUDY_ARM_ROLE_NAME,
  STUDY_ARM_STATUS_NAMES,
} from 'constants/global';
import { eligibleCountries } from 'constants/country';

export const routes = {
  'HOME-PAGE': '/home',
  WELCOME: '/onboarding/welcome-to-study',
  LOGIN: '/login',
  'EMAIL-VERIFIED': '/welcome-aboard',
  'VERIFY-EMAIL': '/verify-email',

  'ACCOUNT-CREATION': '/create-user',
  'ENROLLMENT-ACCEPTED': '/basic-info',

  'ENROLLMENT-INTEREST-1': '/verification-failed',
  'ENROLLMENT-INTEREST-2': '/rejected',
  'ENROLLMENT-INTEREST-3': '/personalinformation',
  'ENROLLMENT-INTEREST-4': '/confirmpersonalinformation',
  'ENROLLMENT-DIAGNOSED': '/viewconsent',
  'ENROLLMENT-ROLE-ELIGIBLE': '/informedconsent',
  'ENROLLMENT-ROLE-NOT-ELIGIBLE': '/select-profile',
  'NO-ACTIVE-PROFILE': '/no-active-profile',

  'NOT-ELIGIBLE': '/onboarding/not-eligible',

  'CONTACT-BASIC-ELIGIBILIY': '/onboarding/basic-information',
  'INTEREST-SELECTION-1': '/onboarding/your-interests-1',
  'INTEREST-SELECTION-2': '/onboarding/your-interests-2',
  'INTEREST-SELECTION-3': '/onboarding/your-interests-3',
  'INTEREST-SELECTION-4': '/onboarding/your-interests-4',

  'ABOUT-REGISTRY': '/onboarding/about-registry',
  // SING-545
  // 'NOT-ELIGIBLE-FOR-RESEARCH': '/onboarding/research-registry-notEligible',
  'NOT-ELIGIBLE-FOR-RESEARCH': '/onboarding/not-eligible',
  'REGISTRY-OPTIONS': '/onboarding/registry-option',
  'ROLE-SELECTION': '/onboarding/research-eligibility/role',
  'SUB-ROLE-SELECTION': '/onboarding/research-eligibility/role/caregiver',
  'DIAGNOSED-SVS-INFO': '/onboarding/participant-information',
  CONDITIONS: '/onboarding/research-eligibility',
  'PERSONAL-INFO': '/onboarding/personal-information',
  CONSENTS: '/onboarding/participant-consents',
  'ADDING-REPORTERS': '/onboarding/add-reporter',
  'CREATING-YOUR-PROFILE': '/onboarding/create-profile',
  'ENROLLMENT-FEEDBACK-SURVEY': '/onboarding/enrollment-feedback',
  'MY-ACTIVITIES':'/my-activities',
};

// Based on https://confluence.rwts-tools.com/display/IHP/%5BDtP%5D+Onboarding+Navigation
const routeLogic = (
  userStatus,
  studyArmCode,
  studyArmStatusTitle,
  secondaryStudyArmStatusTitle,
  studyArmRoleName,
  studyArmSubRoleName,
  personCountry,
  accountProfiles
) => {
  console.log({
    userStatus,
    studyArmCode,
    studyArmStatusTitle,
    secondaryStudyArmStatusTitle,
    studyArmRoleName,
    studyArmSubRoleName,
    personCountry,
    accountProfiles,
  });

  // Custom steps
  if (
    studyArmCode === STUDY_ARM_CODE.RESEARCH &&
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED']
  ) {
    return routes['ROLE-SELECTION'];
  }
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-TO-BE-VERIFIED']
  ) {
    return routes['VERIFY-EMAIL'];
  }

  //   if (
  //     studyArmCode === STUDY_ARM_CODE.RESEARCH &&
  //     studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-CREATED']
  //   ) {
  //     return routes['ROLE-SELECTION'];
  //   }

  // Custom steps
  if (
    studyArmCode === STUDY_ARM_CODE.RESEARCH &&
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-SELECTED']
  ) {
    if (
      studyArmRoleName === STUDY_ARM_ROLE_NAME.LARSV ||
      studyArmRoleName === STUDY_ARM_ROLE_NAME.LARNONSV ||
      studyArmRoleName === STUDY_ARM_ROLE_NAME.PARENTNONSVCHILDUNDER7
    ) {
      return routes['PERSONAL-INFO'];
    }
    return routes['PERSONAL-INFO'];
  }
  if (
    studyArmCode === STUDY_ARM_CODE.RESEARCH &&
    studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-DIAGNOSED-PROFILE-SUBMITTED'] &&
    (studyArmRoleName === STUDY_ARM_ROLE_NAME.LARSV ||
      studyArmRoleName === STUDY_ARM_ROLE_NAME.LARNONSV ||
      studyArmRoleName === STUDY_ARM_ROLE_NAME.PARENTNONSVCHILDUNDER7)
  ) {
    console.log('accountProfiles: ', accountProfiles);
    const submittedPersonalInfoStatuses = [
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-PERSONAL-INFO-SUBMITTED'],
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACCEPTED'],
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED'],
      STUDY_ARM_STATUS_NAMES['ENROLLED'],
    ];
    const personalInfoSubmitted = accountProfiles.some((p) =>
      submittedPersonalInfoStatuses.includes(
        p.attributes.study_arm_status_title
      )
    );
    if (personalInfoSubmitted) {
      return routes['PERSONAL-INFO'];
    }
    return routes['PERSONAL-INFO'];
  }

  if (
    studyArmCode === STUDY_ARM_CODE.RESEARCH &&
    studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-PERSONAL-INFO-SUBMITTED']
  ) {
    return routes['CONSENTS'];
  }

  // STEP 2
  if (userStatus === false) {
    return routes['LOGIN'];
  }

  // STEP 3
  if (userStatus === null) {
    return routes['ACCOUNT-CREATION'];
  }
  // STEP 6
  if (
    studyArmCode === STUDY_ARM_CODE.CONTACT &&
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED'] &&
    !personCountry
  ) {
    return routes['CREATING-YOUR-PROFILE'];
  }

  if (
    studyArmCode === STUDY_ARM_CODE.CONTACT &&
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED']
  ) {
    return routes['CREATING-YOUR-PROFILE'];
  }
  // STEP 7a
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-ELIGIBLE'] ||
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTER-INVITED'] &&
      secondaryStudyArmStatusTitle ===
        STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED']) ||
    (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED'] &&
      personCountry)
  ) {
    return routes['ROLE-SELECTION'];
  }
  // STEP 7b
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-1'] ||
    secondaryStudyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-1']
  ) {
    return routes['INTEREST-SELECTION-2'];
  }
  // STEP 7c
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-2'] ||
    secondaryStudyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-2']
  ) {
    return routes['INTEREST-SELECTION-3'];
  }
  // STEP 7d
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-3'] ||
    secondaryStudyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-3']
  ) {
    return routes['INTEREST-SELECTION-4'];
  }
  // STEP 8
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-4'] &&
    eligibleCountries.includes(personCountry)
  ) {
    return routes['ABOUT-REGISTRY'];
  }
  // STEP 8a
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-4'] &&
    !eligibleCountries.includes(personCountry)
  ) {
    return routes['NOT-ELIGIBLE-FOR-RESEARCH'];
  }
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-NOT-ELIGIBLE']
  ) {
    return routes['NOT-ELIGIBLE'];
  }
  // STEP 9
  if (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ABOUT-REGISTRY']) {
    return routes['REGISTRY-OPTIONS'];
  }
  // STEP 10
  if (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACCEPTED']) {
    return routes['ROLE-SELECTION'];
  }
  // STEP 10a
  if (
    studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-ELIGIBLE'] &&
    studyArmRoleName === STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT
  ) {
    return routes['SUB-ROLE-SELECTION'];
  }

  // STEP 10b
  if (
    (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-4'] &&
      !eligibleCountries.includes(personCountry)) ||
    (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED'] &&
      studyArmRoleName === STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT) ||
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-HOLDER-IDENTIFIED'] &&
      studyArmRoleName === STUDY_ARM_ROLE_NAME.REPORTER) ||
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONFIRMED'] ||
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTERS']
  ) {
    return routes['CREATING-YOUR-PROFILE'];
  }

  // STEP 11
  if (
    studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-ELIGIBLE'] &&
    studyArmRoleName === STUDY_ARM_ROLE_NAME.LAR
  ) {
    return routes['DIAGNOSED-SVS-INFO'];
  }
  // STEP 12
  if (
    studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-SUBJECT-IDENTIFIED'] ||
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-ELIGIBLE'] &&
      studyArmRoleName === STUDY_ARM_ROLE_NAME.PARTICIPANT)
  ) {
    return routes['CONDITIONS'];
  }
  // STEP 13
  if (
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTER-INVITED'] ||
      studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-DIAGNOSED']) &&
    secondaryStudyArmStatusTitle !== null &&
    secondaryStudyArmStatusTitle !== STUDY_ARM_STATUS_NAMES['ENROLLED']
  ) {
    return routes['PERSONAL-INFO'];
  }
  // STEP 14
  if (
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-HOLDER-IDENTIFIED'] ||
      (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-DIAGNOSED'] &&
        (secondaryStudyArmStatusTitle === null ||
          secondaryStudyArmStatusTitle ===
            STUDY_ARM_STATUS_NAMES['ENROLLED']))) &&
    [
      STUDY_ARM_ROLE_NAME.LAR,
      STUDY_ARM_ROLE_NAME.PARTICIPANT,
      STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT,
    ].includes(studyArmRoleName)
  ) {
    return routes['CONSENTS'];
  }
  // STEP 15
  if (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED']) {
    return routes['CREATING-YOUR-PROFILE'];
  }
  // STEP 16
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONFIRMED'] ||
    (studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED'] &&
      ![STUDY_ARM_ROLE_NAME.LAR, STUDY_ARM_ROLE_NAME.PARTICIPANT].includes(
        studyArmRoleName
      )) ||
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-HOLDER-IDENTIFIED'] &&
      studyArmRoleName === STUDY_ARM_ROLE_NAME.REPORTER) ||
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTERS'] ||
    (studyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTER-INVITED'] &&
      !secondaryStudyArmStatusTitle &&
      studyArmRoleName === STUDY_ARM_ROLE_NAME.REPORTER)
  ) {
    return routes['CREATING-YOUR-PROFILE'];
  }
  // STEP 17
  // if (
  //   secondaryStudyArmTitle !== null && accountProfiles.length > 1) {
  //   return routes['ENROLLMENT-FEEDBACK-SURVEY'];
  // }
  // STEP 18a (redirect user to activity page)
  if(
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES.ENROLLED
  ){
    return routes['MY-ACTIVITIES'];
  }
  // STEP 18
  if (
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES.ENROLLED ||
    studyArmStatusTitle === STUDY_ARM_STATUS_NAMES.DECEASED
  ) {
    return routes['HOME-PAGE'];
  }
};

const getRoute = (user) => {
  const profile = user.activeAccountProfile?.attributes?.profile;
  const personAttributes =
    user.activeAccountProfile?.attributes?.person?.attributes;
  const studyArmCode = profile.study_arm_code;
  const studyArmStatusTitle = profile.study_arm_status_title;
  const secondaryStudyArmStatusTitle =
    profile.subject_secondary_study_arm_status_title;
  const studyArmRoleName = profile?.study_arm_role_name;
  const studyArmSubRoleName = profile?.study_arm_sub_role_name;
  const personCountry = personAttributes['country'];
  const accountProfiles = user.accountProfiles;
  const authenticationStatus =
    user.activeAccountProfile?.attributes?.user?.authentication_status;

  console.log(user);

  const targetPath = routeLogic(
    authenticationStatus,
    studyArmCode,
    studyArmStatusTitle,
    secondaryStudyArmStatusTitle,
    studyArmRoleName,
    studyArmSubRoleName,
    personCountry,
    accountProfiles
  );
  console.log('navigating too: ', targetPath);
  return targetPath;
};

export const validateRoute = (user, pathname) => {
  const targetPath = getRoute(user);
  if (pathname !== targetPath) {
    window.location.href = targetPath;
  }
};

export default getRoute;
