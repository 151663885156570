import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormHelperText, Checkbox } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import getStyles from './MiniCheckbox.styles';
import { UnCheckedboxIcon } from './styles';
import { ReactComponent as SmallCheckedboxIcon } from 'images/components/smallCheckedBox.svg';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';

export const MiniCheckbox = ({ label, ...rest }) => {
  const error = false;
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <>
      <FormControlLabel
        sx={styles.formControlLabel}
        size='large'
        label={label}
        control={
          <Checkbox
            icon={<UnCheckedboxIcon width='16px' height='16px' />}
            checkedIcon={<SmallCheckedboxIcon width='16px' height='16px' />}
            {...rest}
          />
        }
      />
      {error && (
        <FormHelperText sx={styles.errResponseText}>
          <InformationIcon />
          This field is required
        </FormHelperText>
      )}
    </>
  );
};

MiniCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
