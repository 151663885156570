import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const televisitApiSlice = createApi({
  reducerPath: 'televisitApiSlice',
  baseQuery: baseQuery(),
  endpoints: (build) => ({
    getAvailableSlots: build.query({
      query: (body) => ({
        url: '/time-slot/available',
        method: 'POST',
        body: {
          ...body,
        },
      }),
    }),
    confirmSlot: build.mutation({
      query: (body) => ({
        url: '/time-slot/confirm',
        method: 'POST',
        body: {
          ...body,
        },
      }),
    }),
  }),
});

export const { useGetAvailableSlotsQuery, useConfirmSlotMutation } =
  televisitApiSlice;
