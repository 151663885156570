import { styled } from '@mui/system';
import background from 'images/background.svg';

export const Form = styled('form')({
  width: '100%',
});

export const getStyles = (theme) => {
  return {
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '25px',
      margin: '0 auto',
      maxWidth: '395px',
    },
    icon: {
      position: 'absolute',
      width: 20,
      right: 19,
      top: 67,
    },
    formHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '5px',
      svg: {
        width: '101px',
      },
    },
    formContainer: {
      paddingTop: '25px',
      width: '100%',
      position: 'relative',
      '& .MuiInputBase-input': {
        paddingRight: '40px !important',
      },
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    radio: {
      width: '100%',
      '& .StyledLabel': {
        opacity: 1,
        color: theme.palette.primary.gray75,
        borderRadius: '8px',
        minHeight: '48px',
        padding: '14px 15px 10px 50px',
      },
    },
    datePicker: {
      borderRadius: '8px',
      borderColor: theme.palette.primary.gray15,
      '&:hover': {
        borderColor: theme.palette.primary.gray15,
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.fuchsia75,
      },
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.primary.gray75,
        opacity: 1,
      },
    },
    input: {
      height: '48px',
      opacity: 1,
      borderRadius: '8px',
      '& ::placeholder': {
        color: theme.palette.primary.gray75,
        opacity: 1,
      },
    },
    inputLabel: {
      fontSize: '14px',
      marginBottom: '8px',
      color: theme.palette.primary.gray75,
    },
    selectInput: {
      '& .MuiInputBase-root': {
        height: '48px',
        borderRadius: '8px',
        opacity: 1,
        borderColor: theme.palette.primary.gray15,
        '&: hover': {
          borderColor: theme.palette.primary.gray50,
        },
      },
    },
    linkButton: {
      justifyContent: 'start',
      height: '16px',
    },
    formerNamesContainer: {
      padding: '8px',
      borderRadius: '8px',
      color: theme.palette.primary.gray75,
      backgroundColor: theme.palette.primary.gray5,
    },
    formerName: {
      padding: '8px',
    },
    submitButton: {
      '&:disabled': {
        opacity: 1,
        background: theme.palette.primary.main,
      },
    },
    fieldContainer: {
      paddingTop: '25px',
      width: '100%',
      position: 'relative',
      '& .MuiInputBase-input': {
        paddingRight: '40px !important',
      },
    },
    additionalFormerName: {
      paddingTop: '10px',
      width: '100%',
    },
  };
};
