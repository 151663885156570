import { useGetParticipantTaskWithRelationshipQuery } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useGetTaskQuery } from 'ihp-bloom-redux/features/tasks/taskApiSliceV3';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

const parseAttributeValue = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const secToMin = (sec) => sec && sec / 60;

const attributesObject = (attributes) =>
  attributes?.reduce?.(
    (obj, { attribute, value }) =>
      Object.assign(obj, { [attribute]: parseAttributeValue(value) }),
    {}
  );

export const formatSurveyTask = (response) => {
  const {
    title,
    description,
    type,
    version: { instrument: form },
    attributes,
    id,
  } = response;
  const { duration, howTo, celebrationScreen, availability_window } =
    attributesObject(attributes);
  const compensationValue = 100; // info missing
  const timingDuration = secToMin(duration?.timing_duration);
  const thankyouMsg = celebrationScreen?.title;
  const hasCelebrationScreen = celebrationScreen?.display;
  return {
    form,
    attributes: {
      title,
      description,
      type,
      compensationValue,
      timingDuration,
      howTo,
      celebrationScreen,
      thankyouMsg,
      hasCelebrationScreen,
      id,
      availability_window,
    },
  };
};

export const formatTaskAttributes = (data) => {
  const attributes = data?.included?.reduce((accumulator, currentValue) => {
    if (currentValue?.type === 'task-attributes') {
      return {
        ...accumulator,
        [currentValue?.attributes?.attribute]: parseAttributeValue(
          currentValue?.attributes?.value
        ),
      };
    } else if (currentValue?.type === 'task-versions') {
      return {
        ...accumulator,
        form: currentValue?.attributes?.instrument,
      };
    }
    return {
      ...accumulator,
    };
  }, {});

  const compensationValue = data?.data?.attributes?.point;
  const estimatedTime = secToMin(attributes?.duration?.timing_duration);
  const timeLimit = attributes?.availability_window;
  const thankyouMsg = attributes?.celebrationScreen?.title;
  const hasCelebrationScreen = attributes?.celebrationScreen?.display;

  return {
    compensationValue,
    estimatedTime,
    timeLimit,
    thankyouMsg,
    hasCelebrationScreen,
    attributes,
  };
};

export const useGetParticipantTask = (participantTaskId) => {
  const { personId, currentUserLoading } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => ({
      personId: data?.data?.included?.[0]?.[0]?.data?.id,
      currentUserLoading: isLoading || isUninitialized || isFetching,
    }),
  });

  const { id, participantTaskAttributes, isLoadingParticipantTaskDetails } =
    useGetParticipantTaskWithRelationshipQuery(
      {
        participantId: personId,
        participantTaskId: participantTaskId,
        included: 'participant_task_entry',
      },
      {
        skip: !personId,
        selectFromResult: ({
          data,
          isLoading,
          isUninitialized,
          isFetching,
        }) => {
          return {
            id: data?.data?.id,
            participantTaskAttributes: data?.data?.attributes,
            isLoadingParticipantTaskDetails:
              isLoading || isUninitialized || isFetching,
          };
        },
      }
    );

  const { taskData, isLoadingTask } = useGetTaskQuery(
    { taskId: participantTaskAttributes?.task_id },
    {
      skip: !participantTaskAttributes?.task_id,
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => {
        return {
          taskData: data,
          isLoadingTask: isLoading || isUninitialized || isFetching,
        };
      },
    }
  );

  const loadingParticipantTask =
    currentUserLoading || isLoadingParticipantTaskDetails || isLoadingTask;

  const participantTaskEntry = taskData?.included?.find(
    (item) => item?.attributes.type === 'participant_task_entry'
  );

  return {
    personId,
    id: parseInt(id), // This is the participant task id
    ...taskData?.data?.attributes,
    ...formatTaskAttributes(taskData),
    completed: participantTaskAttributes?.status === 'completed',
    startDate: participantTaskAttributes?.start_date,
    endDate: participantTaskAttributes?.end_date,
    statusDatetime: participantTaskAttributes?.status_datetime,
    participantTaskEntry,
    loadingParticipantTask,
  };
};
