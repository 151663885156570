import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-2.svg';


export const DropDownIcon = styled(ChevronDown)(({ theme }) => ({
    '&.MuiSelect-icon': {
        width: '16px',
        height: '16px',
        marginTop: '2px',
        color: theme.palette.primary.lightGray100,
        right: '20px!important',
    },
}));

export const getStyles = () => ({
    saveIcon: {
        fontSize: 15
    },
});

export const Content = styled('div')(({ theme }) => ({
    width: '413px',
    height: 'auto',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '40px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    p: {
        width: '100%',
        maxWidth: '377px',
    },
    [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0px',
        width: '98%',
    },
}));

export const Header = styled('div')({
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    marginBottom: '20px',
});

export const Body = styled('div')({
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    marginBottom: '20px',
});

export const Row = styled('div')(({ marginBottom, textAlign, justifyContent }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    textAlign: textAlign || 'center',
    marginBottom: marginBottom || '20px',
}));


export const NavRow = styled(Typography)(({ marginBottom, textAlign, justifyContent, theme }) => ({
    display: 'none',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    textAlign: textAlign || 'center',
    marginBottom: marginBottom || '20px',
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
    },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray100,
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '140%',
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
    },
}));


export const FormContainer = styled('div')(({ }) => ({
    gap: '25px',
    display: 'flex',
    flexDirection: 'column'
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray70,
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
    },
}));

export const Footer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '16px',
    backgroundColor: theme.palette.primary.white,
}));