import { Box, styled, Typography } from '@mui/material';
import { ReactComponent as PersonIcon } from 'images/shared/person.svg';

export function EmptyInvitations() {
  return (
    <Container>
      <PersonIcon />

      <Typography
        color='primary.darkGray70'
        lineHeight='145%'
        mt='20px'
        variant='h5'
      >
        No Family Members
      </Typography>

      <Typography fontSize='12px' marginTop='5px' color='primary.darkGray50'>
        Your invitations are up to date.
      </Typography>
    </Container>
  );
}

const Container = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '70vh',
  justifyContent: 'center',
}));
