import React from 'react';
import { Box } from '@mui/system';
import { StyledIconButton } from '../Payment/styles';
import { useNavigate } from 'react-router-dom';

const HyperWalletCallback = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      🚧TO BE IMPLEMENTED🚧
      <br />
      Successful Hyperwallet transfer🎉
      <br />
      <StyledIconButton
        sx={{ display: 'flex', width: '170px' }}
        onClick={() => navigate('/profile/payment/hyperwallet')}
      >
        Continue
      </StyledIconButton>
    </Box>
  );
};

export default HyperWalletCallback;
