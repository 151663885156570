import { Box, styled } from '@mui/system';

export const CenteredFullPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '50px',
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.primary.gray20,
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0px'
  }
}));
