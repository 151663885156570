import { Stack, Box, Button, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { Tags } from '../Tags';
import { Container, DescriptionText } from './styles';
import { getStyles } from '../styles';
import { isMobile } from 'utils/isMobile';

export const Main = ({
  title,
  description,
  consentVersion,
  consentVersionDate,
  isSigned,
  handleSignConsent,
  points,
  duration,
  type,
  isLar,
  name,
  ...rest
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Container flex={1} {...rest}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack spacing='10px'>
          {title && (
            <Typography
              fontSize='18px'
              fontWeight={600}
              color='secondary.gray4'
            >
              {title}
            </Typography>
          )}
          {type && !isMobile() && (
            <Stack spacing='10px'>
              <DescriptionText>
                {type.charAt(0).toUpperCase() + type.slice(1)}
                {isLar && ` | On behalf of ${name}`}
              </DescriptionText>
            </Stack>
          )}
          {description && (
            <Stack spacing='10px'>
              <DescriptionText>{description}</DescriptionText>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Box
        width='100%'
        display='flex'
        marginTop='10px'
        justifyContent='center'
        flexWrap='wrap'
      >
        <Tags isSigned={isSigned} {...rest} />

        {!isSigned && (
          <Button
            variant='text'
            sx={styles.button}
            onClick={handleSignConsent}
            disabled={isSigned}
          >
            Review & sign
          </Button>
        )}
      </Box>
    </Container>
  );
};
