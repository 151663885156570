import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'ihp-bloom-redux/app/redux';

// import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
// import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import {
  useGetAllCountriesQuery,
  useGetStatesQuery,
} from 'ihp-bloom-redux/features/countries/countriesApiSlice';
// import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
// import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';

import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';

import { Footer } from '../components/Footer';
import OnboardingLayout from '../components/Layout';
import { ReactComponent as AvLogo } from 'images/svs-logo.svg';
import { Text, Title } from '../components/Text';
import { validateDate } from 'utils/formValidation';
// import {
//   STUDY_ARM_CONFIG_VARIABLES,
//   STUDY_ARM_STATUS_CODES,
// } from 'constants/global';
// import getRoute from 'utils/navigator';
// import { getPayload } from 'pages/Profile/MyProfile/utils';
import {
  // createPersonStudyArmPayload,
  isAboveStateAgeMajority,
  getIneligibilityReasons,
} from './helper';
import { FieldContainer, Row, DropDownIcon, CalendarIcon } from './styles';
import {
  bestDescribeOptions,
  nonCombinableBestDescribeOptions,
} from './constants';
import { SelectGroup } from 'components/SelectGroup';
import { getAuth0LoginPageConfig } from 'utils/auth0';
import { formatDateToDB } from 'utils/date';

const reversedBestDescribeOptions = Object.keys(bestDescribeOptions).reduce(
  (acc, key) => {
    return {
      ...acc,
      [bestDescribeOptions[key]]: key,
    };
  },
  {}
);

function BasicInformationPage() {
  document.title = 'Single Ventricle SOURCE Basic Information';

  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const countryRef = useRef();

  // const user = useSelector((state) => state.user);
  // const { activeAccountProfile, person } = useSelector((state) => state.user);
  // const { configuration } = useSelector((state) => state.configuration);

  const [bestDescribeSelectedOptions, setBestDescribeSelectedOptions] =
    useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
    resetField,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      date_of_birth: '',
      state: '',
      country: '',
    },
  });

  const selectedCountry = watch('country');
  const stateSelectedVal = watch('state');

  // API Calls
  // const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
  //   useEditPersonAttributesMutation();
  // const [updateStudyArm, { isLoading: isUpdatingStudyArm }] =
  //   useUpdatePersonStudyArmMutation();
  const { data, isLoading: isCountriesLoading } = useGetAllCountriesQuery();
  const countries = data?.data;

  const { states, isStatesFetching } = useGetStatesQuery(selectedCountry, {
    skip: selectedCountry === '',
    selectFromResult: ({ data, isFetching }) => ({
      states: data?.data?.attributes?.subdivisions,
      isStatesFetching: isFetching,
    }),
  });
  if (
    (selectedCountry !== '' || selectedCountry !== undefined) &&
    countryRef.current !== selectedCountry
  ) {
    setValue('state', '');
    countryRef.current = selectedCountry;
  }

  useEffect(() => {
    resetField('state');
  }, [isStatesFetching, resetField]);

  // STATUSES
  // const accountPersonStudyArmId =
  //   activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  // const contactEnrollmentEligible =
  //   configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
  //     STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_ELIGIBLE
  //   ];

  // const contactEnrollmentNotEligible =
  //   configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
  //     STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_NOT_ELIGIBLE
  //   ];

  // const eligibilityStatusIdMap = {
  //   true: contactEnrollmentEligible?.id,
  //   false: contactEnrollmentNotEligible?.id,
  // };
  // const eligibilityStatusTitleMap = {
  //   true: contactEnrollmentEligible?.title,
  //   false: contactEnrollmentNotEligible?.title,
  // };

  // const updateStudyArmStatus = async (isEligible) => {
  //   const payload = createPersonStudyArmPayload(
  //     accountPersonStudyArmId,
  //     eligibilityStatusIdMap[isEligible]
  //   );
  //   return updateStudyArm({
  //     personStudyArmId: accountPersonStudyArmId,
  //     payload,
  //   });
  // };

  // const prepareNewUserState = (isEligible, personAttributes) => {
  //   const attributes = personAttributes.reduce((result, item) => {
  //     return {
  //       ...result,
  //       [item['data']['attributes']['attribute']]:
  //         item['data']['attributes']['value'],
  //     };
  //   }, {});

  //   return {
  //     ...user,
  //     personAttributes: {
  //       ...user.personAttributes,
  //       ...attributes,
  //     },
  //     activeAccountProfile: {
  //       ...user.activeAccountProfile,
  //       attributes: {
  //         ...user.activeAccountProfile.attributes,
  //         profile: {
  //           ...user.activeAccountProfile.attributes.profile,
  //           study_arm_status_id: eligibilityStatusIdMap[isEligible],
  //           study_arm_status_title: eligibilityStatusTitleMap[isEligible],
  //         },
  //         person: {
  //           ...user.activeAccountProfile.attributes.person,
  //           attributes: {
  //             ...user.activeAccountProfile.attributes.person.attributes,
  //             ...attributes,
  //           },
  //         },
  //       },
  //     },
  //   };
  // };

  // const submitData = async (isEligible, personId, personAttributesPayload) => {
  //   try {
  //     const personAttributesResponse = await editPersonAttributes({
  //       personId,
  //       payload: personAttributesPayload,
  //     });
  //     const response = await updateStudyArmStatus(isEligible);

  //     if (!personAttributesResponse?.error && !response.error) {
  //       // Override redux state
  //       const userState = prepareNewUserState(
  //         isEligible,
  //         personAttributesPayload
  //       );
  //       await dispatch(setValues(userState));
  //       navigate(getRoute(userState));
  //     }
  //   } catch (error) {
  //     console.error('error', error);
  //   }
  // };

  const isLoading =
    // isEditingPersonAttributes ||
    // isUpdatingStudyArm ||
    isCountriesLoading || isStatesFetching;

  const onSubmit = (data) => {
    const {
      state,
      // ...restOfData
    } = data;

    const ineligibilityReasons = getIneligibilityReasons(
      data?.date_of_birth,
      state,
      selectedCountry,
      bestDescribeSelectedOptions
    );

    // const finalData = state !== '' ? data : restOfData;
    // const personAttributesPayload = getPayload(finalData);

    const prescreeningData = {
      date_of_birth: formatDateToDB(data.date_of_birth),
      country: selectedCountry,
      state,
      best_describe_attributes: bestDescribeSelectedOptions.map(
        (option) => reversedBestDescribeOptions[option]
      ),
    };

    if (ineligibilityReasons.length === 0) {
      // submitData(true, person.id, personAttributesPayload);
      return loginWithRedirect(
        getAuth0LoginPageConfig({
          context: 'signup',
          prescreening_data: JSON.stringify({
            ...prescreeningData,
            is_eligible: true,
          }),
        })
      );
    }

    // submitData(false, person.id, personAttributesPayload);

    if (!isAboveStateAgeMajority(data?.date_of_birth, state)) {
      return navigate('/onboarding/not-eligible', {
        replace: true,
        state: {
          ineligibilityReasons,
        },
      });
    }

    navigate('/onboarding/additional-resources', {
      replace: true,
      state: {
        ineligibilityReasons,
        prescreening_data: prescreeningData,
      },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout maxWidth='375px' backHandler={goBack}>
      <Row>
        <AvLogo />
      </Row>
      <Title>Welcome</Title>
      <Text
        color='primary.darkGray70'
        fontWeight={400}
        fontSize='14px'
        marginTop='5px'
      >
        Before we get started, let’s make sure you
        <br />
        are eligible to sign up for SOURCE.
      </Text>
      <Box>
        <form>
          <FieldContainer>
            <Controller
              name='date_of_birth'
              control={control}
              rules={{
                validate: validateDate,
                required: true,
              }}
              render={({ field }) => (
                <Input
                  type='datePicker'
                  label='Date of birth'
                  placeholder='Pick a date'
                  datePickerIcon={() => <CalendarIcon />}
                  error={errors.date_of_birth}
                  disableFuture
                  errorLabel={
                    errors?.date_of_birth?.message || 'This field is required'
                  }
                  fullWidth
                  {...field}
                />
              )}
            />
          </FieldContainer>

          <FieldContainer>
            <Controller
              name='country'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  type='select'
                  label='Select country'
                  placeholder='Select country'
                  fieldLabel='Country'
                  error={errors.country}
                  disabled={!countries?.length}
                  IconComponent={(props) => <DropDownIcon {...props} />}
                  errorLabel={
                    errors?.country?.message || 'This field is required'
                  }
                  fullWidth
                  {...field}
                >
                  {countries?.map(
                    ({
                      attributes: { country_name },
                      attributes: { country_code2 },
                    }) => (
                      <MenuItem key={country_code2} value={country_code2}>
                        {country_name}
                      </MenuItem>
                    )
                  )}
                </Input>
              )}
            />
          </FieldContainer>

          {states?.length > 0 && !isStatesFetching && (
            <FieldContainer>
              <Controller
                name='state'
                control={control}
                rules={{
                  required: states?.length && stateSelectedVal,
                }}
                haserrors={errors.state}
                render={({ field }) => (
                  <Input
                    error={errors.state}
                    errorLabel={errors?.state?.message}
                    type='select'
                    label='Select'
                    disabled={!states?.length}
                    fieldLabel='State or US Territory'
                    placeholder='Select relation'
                    IconComponent={(props) => <DropDownIcon {...props} />}
                    fullWidth
                    {...field}
                  >
                    {states?.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </FieldContainer>
          )}

          <FieldContainer>
            <SelectGroup
              label='What best describes you? Select all that applies'
              nonCombinableOptions={nonCombinableBestDescribeOptions}
              options={Object.values(bestDescribeOptions)}
              selectedOptions={bestDescribeSelectedOptions}
              setSelectedOptions={setBestDescribeSelectedOptions}
              type='multi'
            />
          </FieldContainer>

          <Footer padding='25px 0px 0px 0px' maxWidth='375px'>
            <Button
              fullWidth
              disabled={
                !isValid ||
                !isDirty ||
                (states?.length && !stateSelectedVal) ||
                isLoading ||
                bestDescribeSelectedOptions.length === 0
              }
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            >
              Save & continue
            </Button>
          </Footer>
        </form>
      </Box>
    </OnboardingLayout>
  );
}

export default BasicInformationPage;
