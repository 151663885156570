import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormLabel
} from '@mui/material';

const sizeWidthMap = {
  large: '500px',
  medium: '400px',
  small: '300px',
};

export const StyledFormControl = styled(FormControl)(({ fullWidth, size }) => ({
  width: !fullWidth ? sizeWidthMap[size] : '100%',
  '.MuiIconButton-root': {
    marginRight: '8px'
  }
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '140%',
  marginBottom: '8px',

  '&.Mui-focused': {
    color: theme.palette.primary.gray75,
  },
}));


