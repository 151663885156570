import { createSlice } from '@reduxjs/toolkit';

const paymentProviders = [
  {
    id: '001',
    name: 'tremendous',
    isActive: true,
  },
  {
    id: '002',
    name: 'hyperwallet',
    isActive: true,
  },
];

const initialState = {
  allPaymentProviders: paymentProviders,
  selectedPaymentProviders: paymentProviders,
};
const paymentProviderSlice = createSlice({
  name: 'paymentProvider',
  initialState,
  reducers: {
    togglePaymentProvider: (state, action) => {
      state.selectedPaymentProviders = state.selectedPaymentProviders.map(
        (paymentProvider) => {
          return {
            ...paymentProvider,
            isActive:
              paymentProvider.id === action.payload
                ? !paymentProvider.isActive
                : paymentProvider.isActive,
          };
        }
      );
    },
  },
});

export const { togglePaymentProvider } = paymentProviderSlice.actions;

export default paymentProviderSlice.reducer;
