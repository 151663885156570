import { Box, styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: theme.palette.primary.gray20,
  boxSizing: 'border-box',
}));

export const TopContent = styled(Box)({
  padding: '44px 0 0 40px',
});

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
});
