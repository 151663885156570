import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FilledButton } from './styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  paddingY: '20px',
  borderRadius: '8px',
};

export default function NoAnswerModal({ open, handleClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box padding={3}>
            <Typography
              margin={2}
              color='primary.gray75'
              id='modal-modal-title'
              variant='h4'
            >
              This item is blank, please complete it.
            </Typography>
            <FilledButton fullWidth onClick={handleClose} variant='contained'>
              Close
            </FilledButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
