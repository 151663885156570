import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useGetAccountProfileQuery,
  useGetAccountProfilesQuery,
} from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import {
  STUDY_ARM,
  STUDY_ARM_CODE,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS,
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_STATUS_NAMES,
} from 'constants/global';

/**
 * This custom hook is responsible to fetch person account profiles
 * select default profile
 *  get list of profiles
 *  filter profile which are not "sv source" AND status is not enrolled
 * and then fetch account details of selected profile
 * @param {string} personId - Current logged in person id
 * @param {string} accountProfileId - Selected account profile id
 * @param {Boolean} shouldSelectDefaultProfile - Should automatically select default in progress profile
 *
 * @returns {Object} - Will have loading states for both fetch profiles and fetch profile api calls
 */

export const useGetAccountProfileDetails = ({
  personId,
  shouldSelectDefaultProfile = false,
  accountProfileId,
}) => {
  const dispatch = useDispatch();
  const { configuration } = useSelector((state) => state.configuration);
  const [currentAccountProfile, setCurrentAccountProfile] = useState({});
  const [defaultProfileNotFound, setDefaultProfileNotFound] = useState(false);
  const [noActiveProfiles, setNoActiveProfiles] = useState(false);
  const [isProcessing, setIsProcessing] = useState(true);

  const activeProfilesRef = useRef([]);

  const { accountProfiles, isFetching: isLoadingAccountProfiles, isSuccess } =
    useGetAccountProfilesQuery(personId, {
      skip: !personId,
      forceRefetch: true,
      selectFromResult: ({ data, ...rest }) => ({
          ...rest,
          accountProfiles: data?.data || [],
      }),
    });

  const { data: accountProfile, isFetching: isLoadingAccountProfile } =
    useGetAccountProfileQuery(currentAccountProfile?.id || accountProfileId, {
      skip: !currentAccountProfile?.id && !accountProfileId,
      forceRefetch: true,
    });

  /**
   * If there is only one profile for a user (excluding SV Source) that is not enrolled, DtP need to navigate the user to the onboarding flow
   * If there are more profiles -> show select profile page
   * If there is only one profile and it's enrolled -> navigate to home page
   */
  useEffect(() => {
    if(accountProfiles?.length === 0 && isSuccess){
      setIsProcessing(false);
      setNoActiveProfiles(true);
    }
    if (!accountProfiles?.length) return;
    // enrolled status list for "Contact & Research" study arm
    const contactEnrolledStatusId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.CONTACT_ENROLLED
      ]?.id;
    const contactWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.CONTACT_WITHDRAWN
      ]?.id;
    const researchWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_WITHDRAWN
      ]?.id;
    const allftdWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.ALLFTD_WITHDRAWN
      ]?.id;
    const researchReporterRevoked =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_REPORTER_REVOKED
      ]?.id;
    const researchEnrolledStatusId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED
      ]?.id;

    const contactDeceasedStatusId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.CONTACT_DECEASED
      ]?.id;
    const researchDeceasedStatusId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_DECEASED
      ]?.id;
    const allftdDeceasedStatusId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.ALLFTD_DECEASED
      ]?.id;

    /**
     * We have to treat deceased status like enrolled because only enrolled user can be marked as deceased
     */
    const enrolledStatuses = [
      contactEnrolledStatusId,
      researchEnrolledStatusId,
      contactDeceasedStatusId,
      researchDeceasedStatusId,
      allftdDeceasedStatusId,
    ];
    const allFTDStudyArmId =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARMS][
        STUDY_ARM_CODE.ALLFTD
      ]?.id;
    const inActiveStatuses = [
      contactWithdrawn,
      researchWithdrawn,
      allftdWithdrawn,
      researchReporterRevoked,
    ];
    activeProfilesRef.current = accountProfiles?.filter(
      (profile) =>
        !inActiveStatuses?.includes(profile.attributes.study_arm_status_id)
    );
    const inActiveProfiles = accountProfiles?.filter((profile) =>
      inActiveStatuses?.includes(profile.attributes.study_arm_status_id)
    );
    const inCompleteProfiles = activeProfilesRef?.current.filter(
      (profile) =>
        profile.attributes.study_arm_id !== allFTDStudyArmId &&
        !enrolledStatuses.includes(profile.attributes.study_arm_status_id)
    );
    const isNoProfileActive =
      accountProfiles?.length === inActiveProfiles?.length;
    const enrolledProfiles = activeProfilesRef?.current.filter(
      (profile) =>
        profile.attributes.study_arm_id !== allFTDStudyArmId &&
        enrolledStatuses.includes(profile.attributes.study_arm_status_id)
    );

    if (isNoProfileActive) {
      setNoActiveProfiles(true);
      setIsProcessing(false);
    } else if (shouldSelectDefaultProfile && inCompleteProfiles.length === 1) {
      setCurrentAccountProfile(inCompleteProfiles[0]);
    } else if (shouldSelectDefaultProfile && enrolledProfiles.length === 1) {
      setCurrentAccountProfile(enrolledProfiles[0]);
    } else if (
      shouldSelectDefaultProfile &&
      (inCompleteProfiles.length > 1 || enrolledProfiles.length > 1)
    ) {
      setDefaultProfileNotFound(true);
      setIsProcessing(false);
    } else if (!shouldSelectDefaultProfile) {
      setIsProcessing(false);
    }

    // if (shouldSelectDefaultProfile && inCompleteProfiles?.length) {
    //   setCurrentAccountProfile(inCompleteProfiles[0]);
    // } else {
    //   setDefaultProfileNotFound(true);
    // }
    dispatch(setValues({ accountProfiles: accountProfiles }));
  }, [accountProfiles]);

  /**
   * We need to save account profile
   */
  useEffect(() => {
    if (accountProfile?.data) {
      dispatch(
        setValues({
          activeAccountProfile: accountProfile.data,
        })
      );
      setIsProcessing(false);
    }
  }, [accountProfile]);

  const isLoadingAccountProfileDetails =
    isLoadingAccountProfiles || isLoadingAccountProfile || isProcessing;

  return {
    isLoadingAccountProfileDetails,
    accountProfiles: activeProfilesRef.current,
    accountProfile,
    defaultProfileNotFound,
    noActiveProfiles,
  };
};
