import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  groups: [
    { id: '001', name: 'Consectetur viverra quam massa duis est', people: 21 },
    { id: '002', name: 'Sit facilisis ipsum sit eu euismod sit', people: 29 },
    {
      id: '003',
      name: 'Ac blandit augue sed id duis augue ultricies ',
      people: 122,
    },
    {
      id: '004',
      name: 'Libero, aliquam consequat tempus ante  ',
      people: 1892,
    },
  ],
};

const updateGroupAction = (groupState, action) => {
  let groupForUpdate = groupState.groups.find(
    (group) => group.id === action.payload.id
  );

  if (groupForUpdate) {
    groupForUpdate.name = action.payload.name;
  }
};

const deleteGroupAction = (groupState, action) => {
  const groupIndex = groupState.groups.findIndex(
    (group) => group.id === action.payload.id
  );

  groupState.groups.splice(groupIndex, 1);

};

const addGroupAction = (groupState, action) => {
  groupState.groups.push(action.payload);
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    addGroup: addGroupAction,
    updateGroup: updateGroupAction,
    deleteGroup: deleteGroupAction,
  },
});

export const { addGroup, updateGroup, deleteGroup } = groupSlice.actions;

export default groupSlice.reducer;
