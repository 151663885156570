const researchOpportunityStatuses = {
  CONTACTED: {
    title: 'Contacted',
    key: 'contacted',
    display: true,
  },
  INELIGIBLE: {
    title: 'Ineligible',
    key: 'ineligible',
    display: true,
  },
  ENROLLED: {
    title: 'Enrolled',
    key: 'enrolled',
    display: true,
  },
  DECLINED: {
    title: 'Declined',
    key: 'declined',
    display: true,
  },
  NOT_INTERESTED: {
    title: 'Not Interested',
    key: 'not_interested',
    display: false,
  },
  UN_ASSIGNED: {
    title: 'Un Assigned',
    key: 'unassigned',
    display: false,
  },
  UNREVIEWED: {
    title: 'Unreviewed',
    key: 'unreviewed',
    display: true,
  },
  UNSUBSCRIBE: {
    title: 'Unsubscribe',
    key: 'unsubscribe',
    display: false,
  },
};

export default researchOpportunityStatuses;
