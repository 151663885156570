import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from 'utils/isMobile';
import background from 'images/background.svg';

export const ContactsContainer = styled(Box)(() => ({
  padding: '0px 25px',
}));

export const ContactContainer = styled(Box)(() => ({
  background: '#F4F4F4',
  padding: isMobile() ? '15px' : '20px',
  borderRadius: '5px',
  marginTop: '30px',
}));

export const NameHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

export const DeleteIcon = styled(Box)(() => ({
  cursor: 'pointer',
  marginLeft: '16px',
}));

export const FullNameContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '140%',
}));

export const AlternativeContactFullNameContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '140%',
  marginBottom: '10px',
}));

export const RelationContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginLeft: '8px',
}));

export const FlexedBox = styled(Box)(() => ({
  display: 'flex',
}));

export const PhoneNumberContainer = styled(Box)(() => ({
  display: 'flex',
  marginTop: '10px',
}));

export const EmailContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '9px',
  marginLeft: '8px',
}));

export const PhoneContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '10px',
  marginLeft: '8px',
}));

export const DoctorPhoneContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '2px',
  marginLeft: '8px',
}));

export const MainContainer = styled('div')(({ theme }) => ({
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '25px',
  margin: '0 auto',
  maxWidth: '395px',
}));
