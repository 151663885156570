import { Box, Typography, styled } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as Logo } from 'images/av-logo.svg';
import { ReactComponent as Check } from 'images/shared/check-blue.svg';
import { ReactComponent as InfoCircle } from 'images/shared/info-circle-blue.svg';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-2.svg';
import { Input } from 'ihp-components/components/v2/Input';

export const ReporterContainer = styled(Box)(({ theme }) => ({
  background: '#F4F4F4',
  padding: isMobile() ? '15px' : '20px',
  borderRadius: '5px',
  marginTop: '20px',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
  },
}));

export const NameHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

export const AlternativeReporterFullNameContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '140%',
  marginBottom: '10px',
}));

export const FlexedBox = styled(Box)(() => ({
  display: 'flex',
}));

export const RelationContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginLeft: '8px',
}));

export const EmailContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '9px',
  marginLeft: '8px',
}));

export const PhoneContainer = styled(Box)(() => ({
  fontFamily: 'Inter',
  color: '#606B71',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16.8px',
  marginTop: '10px',
  marginLeft: '8px',
}));

export const TitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: '5px',
}));

export const CheckIcon = styled(Check)({
  width: '14px',
  height: '14px',
  gap: '10px',
});

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '10px',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  marginBottom: '10px',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const ReporterDetail = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.gray1,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  marginBottom: '15px',
  marginTop: '15px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '140%',
}));

export const FieldContainer = styled(Box)({
  paddingTop: '25px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const AnswerButtonWrapper = styled('div')({
  display: 'flex',
  gap: 10,
  flexDirection: 'row',
});

export const InfoWrapper = styled('div')({
  display: 'flex',
  gap: 10,
  marginTop: '10px',
  flexDirection: 'row',
});

export const InfoIcon = styled(InfoCircle)({
  width: '14px',
  height: '14px',
  top: '17px',
});

export const InfoDetail = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray60,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  marginTop: '15px',
  width: '312px',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '140%',
}));

export const StyledHR = styled('hr')(({ theme }) => ({
  marginTop: '20px',
  border: 'none',
  borderBottom: `1px solid ${theme.palette.primary.gray5}`,
}));

export const StyledAnswerButton = styled(Button)(({ theme, isSelected }) => ({
  display: 'flex',
  gap: 10,
  flexDirection: 'row',
  fontWeight: !isSelected && 500,
  color: !isSelected && theme.palette.secondary.gray4,
  borderColor: !isSelected && theme.palette.primary.lightGray100,
}));

export const DropDownIcon = styled(ChevronDown)(({ theme }) => ({
  '&.MuiSelect-icon': {
    width: '16px',
    height: '16px',
    marginTop: '2px',
    color: theme.palette.primary.lightGray100,
    right: '20px!important',
  },
}));

export const InputSelectWrapper = styled(Input)(({ theme }) => ({
  '& .MuiSelect-select': {
    [theme.breakpoints.down('sm')]: {
      left: '10px',
    }
  },
}));

const styles = {
  checkboxContainer: {
    backgroundColor: 'transparent',
    border: '0px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
      '& .MuiCheckbox-root': {
        padding: '0px',
        '& span': {
          width: '20px',
          height: '20px',
        },
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
};

export const getStyles = () => {
  return styles;
};
