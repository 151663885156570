import { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, MenuItem, Typography, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {
  useEditPersonAttributesMutation,
  useEditPersonMutation,
  useParticipantGoalMutation,
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { setOnboardingReward } from 'ihp-bloom-redux/features/user/userSlice';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';
import { areAllFieldsEmpty, getEmptyFields } from '../utils';

import { Loader } from 'components/Loader';
import { FormLabel } from 'components/Login';
import { Logo } from 'components/shared';
import { Form, getStyles } from '../styles/index.mobile.styles';
import { ReactComponent as PhoneIcon } from 'images/shared/phone.svg';

import { useValidatePhone } from '../hooks';
import {
  ContactContainer,
  ContactsContainer,
  FullNameContainer,
  NameHeaderContainer,
  RelationContainer,
  FlexedBox,
  DoctorPhoneContainer,
  MainContainer,
  PhoneNumberContainer,
} from '../AlternativeContact/styles';
import { ReactComponent as TrashIcon } from 'images/profile/alternativecontacts/trash-bin.svg';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { ReactComponent as PinIcon } from 'images/profile/alternativecontacts/pin.svg';
import { ReactComponent as PenIcon } from 'images/shared/pen-3.svg';
import { USStates } from '../AlternativeContact/mocks/us-states';
import { EditDoctorsModal } from '../EditModal';
import { findFirstErrorComponent } from 'utils/formValidation';

function DoctorsMobile({ person }) {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const [doctorId, setDoctorId] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [doctors, setDoctors] = useState([]);

  const [currentDoctor, setCurrentDoctor] = useState({
    provider_name: '',
    phone_number: '',
    city: '',
    state: '',
  });

  const [editPersonAttributes, { isLoading }] =
    useEditPersonAttributesMutation();
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const [triggerReward, { isLoading: isProcessingReward }] =
    useParticipantGoalMutation();

  const onAddDoctor = (data) => {
    const emptyFields = getEmptyFields(data);
    if (emptyFields.length !== 0) {
      setErrorsForFields(emptyFields);
      return;
    }
    const newDoctors = [...doctors, { id: doctorId, ...data }];
    setDoctors(newDoctors);
    setCurrentDoctor({
      provider_name: '',
      phone_number: '',
      city: '',
      state: '',
    });
    setDoctorId(doctorId + 1);
    reset();
  };

  const providerNameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);

  const refMap = {
    provider_name: providerNameRef,
    phone_number: phoneNumberRef,
    city: cityRef,
    state: stateRef,
  };

  useEffect(() => findFirstErrorComponent(errors, refMap));

  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      provider_name: '',
      phone_number: '',
      city: '',
      state: '',
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        provider_name: '',
        phone_number: '',
        city: '',
        state: '',
      });
    }
  }, [doctorId, isSubmitSuccessful]);

  const setErrorsForFields = (emptyFields) => {
    emptyFields.forEach((field) => setError(field, 'This field is required!'));
  };

  const onSubmit = async (data) => {
    let newDoctors = [...doctors];
    const emptyFields = getEmptyFields(data);
    const allFieldsEmpty = areAllFieldsEmpty(data);

    if (allFieldsEmpty && newDoctors.length === 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length > 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length === 0) {
      newDoctors = [...newDoctors, data];
    }

    const personAttributesPayload = [
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'doctor',
            value: JSON.stringify(
              newDoctors.map((item) => {
                const { id, ...rest } = item;
                return { ...rest };
              })
            ),
          },
        },
      },
    ];

    const editPersonPayload = {
      data: {
        type: 'people',
        id: `${person?.id}`,
        attributes: {
          state: 'enrollment',
          status: 'onboarded',
          status_reason: 'onboarded',
        },
      },
    };
    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person?.id,
        payload: personAttributesPayload,
      });
      if (!personAttributesResponse.error) {
        const personResponse = await editPerson({
          personId: person?.id,
          payload: editPersonPayload,
        });
        const rewardResponse = await triggerReward({
          personId: person?.id,
          payload: {
            data: {
              process: 'onboarding',
            },
          },
        });
        if (!rewardResponse?.error && rewardResponse?.data) {
          dispatch(setOnboardingReward(rewardResponse.data?.data || []));
        }
        if (!personResponse.error) {
          navigate('/onboarding/create-profile');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  const handleDelete = (id) => {
    const newDoctors = doctors.filter((c) => c.id !== id);
    setDoctors(newDoctors);
  };

  const onUpdateDoctor = (data) => {
    let updatedDoctors = doctors.map((doctor) => {
      if (doctor.id === data.id) {
        return data;
      }
      return doctor;
    });
    setDoctors(updatedDoctors);
  };

  const handleEdit = (id) => {
    const selectedProvider = doctors.find((c) => c.id === id);
    setSelectedProvider(selectedProvider);
    return setIsOpenModal(true);
  };

  return (
    <MainContainer sx={{ height: '100%' }}>
      <Box sx={styles.formHeader}>
        <Logo />
        <FormLabel fontSize='24px' marginTop='15px'>
          Your healthcare providers
        </FormLabel>
        <Typography color='primary.gray75' fontSize='14px' textAlign='center'>
          As part of the study, we will need to access your medical records
        </Typography>
      </Box>
      <ContactsContainer>
        <ul sx={{ width: '100%' }}>
          {doctors.map((doctor) => {
            return (
              <ContactContainer>
                <Stack>
                  <NameHeaderContainer>
                    <FullNameContainer>
                      {doctor.provider_name}
                    </FullNameContainer>
                    <FlexedBox>
                      <Box
                        sx={{ cursor: 'pointer', marginRight: '12px' }}
                        onClick={() => handleEdit(doctor.id)}
                      >
                        <PenIcon width='14px' height='14px' />
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(doctor.id)}
                      >
                        <TrashIcon width='14px' height='14px' />
                      </Box>
                    </FlexedBox>
                  </NameHeaderContainer>
                  <FlexedBox>
                    <PinIcon width='14px' height='14px' />
                    <RelationContainer>
                      {doctor.city}, {doctor.state}
                    </RelationContainer>
                  </FlexedBox>
                  <PhoneNumberContainer>
                    <Box>
                      <PhoneIcon width='14px' height='14px' color='#606B71' />
                    </Box>
                    <DoctorPhoneContainer>
                      {doctor.phone_number}
                    </DoctorPhoneContainer>
                  </PhoneNumberContainer>
                </Stack>
              </ContactContainer>
            );
          })}
        </ul>
        <Form>
          <Box sx={styles.formContainer}>
            <Controller
              name='provider_name'
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  label="Healthcare provider's name"
                  name='providerName'
                  error={errors.provider_name}
                  errorLabel={errors.provider_name?.message}
                  value={currentDoctor.provider_name}
                  fullWidth={true}
                  placeholder='Enter healthcare provider’s name'
                  inputRef={providerNameRef}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='phone_number'
              control={control}
              rules={{ validate: validatePhone, required: false }}
              render={({ field }) => (
                <MaskedInput
                  placeholder='(___)___-___'
                  mask='+1 (999) 999-9999'
                  {...field}
                >
                  {(inputProps) => (
                    <Input
                      fullWidth
                      name='phone_number'
                      label='Phone number'
                      error={errors.phone_number}
                      errorLabel={
                        errors?.phone_number?.message ||
                        'This field is required'
                      }
                      inputRef={phoneNumberRef}
                      {...inputProps}
                    />
                  )}
                </MaskedInput>
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='city'
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input
                  name='city'
                  label='City'
                  error={errors.city}
                  errorLabel={errors.city?.message}
                  value={currentDoctor.city}
                  fullWidth={true}
                  placeholder='Enter city'
                  inputRef={cityRef}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='state'
              control={control}
              rules={{ required: false }}
              haserrors={errors.state}
              render={({ field }) => (
                <Input
                  type='select'
                  error={errors.state}
                  errorLabel={errors.state?.message}
                  label='Select state'
                  fieldLabel='State'
                  placeholder='Select state'
                  fullWidth
                  sx={styles.selectInput}
                  inputRef={stateRef}
                  {...field}
                >
                  {USStates?.map(({ name, abbreviation }) => (
                    <MenuItem key={abbreviation} value={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </Input>
              )}
            />
          </Box>

          <Box sx={styles.formContainer} component='div'>
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading || isEditingPerson || isProcessingReward}
              sx={styles.submitButton}
              loading={isLoading || isEditingPerson || isProcessingReward}
            >
              Save
            </Button>
          </Box>
          <Box sx={{ ...styles.fieldContainer, marginTop: '10px' }}>
            <Button
              variant='outlined'
              fullWidth
              onClick={handleSubmit(onAddDoctor)}
              startIcon={<PlusIcon width={14} height={14} />}
            >
              Add new doctor
            </Button>
          </Box>
        </Form>
      </ContactsContainer>

      {selectedProvider != null && (
        <EditDoctorsModal
          open={isOpenModal}
          onClose={() => {
            setSelectedProvider(null);
            return setIsOpenModal(false);
          }}
          provider={{
            id: selectedProvider.id,
            provider_name: selectedProvider.provider_name,
            phone_number: selectedProvider.phone_number,
            city: selectedProvider.city,
            state: selectedProvider.state,
          }}
          onUpdateProvider={onUpdateDoctor}
        />
      )}
    </MainContainer>
  );
}

export default DoctorsMobile;
