import { styled } from '@mui/material/styles';
import { Box, Radio } from '@mui/material';

export const StyledRadio = styled(Radio)(({ theme, checked }) => ({
  '& .StyledLabel': {
    color: theme.palette.primary.gray75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    width: '50px',
    ...(checked
      ? {
          background: 'white',
          borderRadius: '8px',
        }
      : {}),
  },
}));

export const ItemName = styled(Box)(({ theme, checked }) => ({
  fontStyle: 'Inter',
  fontWeight: checked ? 700 : 400,
  fontSize: '16px',
  lineHeight: '22px',
  color: theme.palette.primary.white,
  cursor: 'pointer',
  marginTop: '10px',
  maxWidth: '129px',
  height: '50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}));

export const itemLinkStyles = {
  textDecoration: 'none',
};
