import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import getStyles from './styles';
import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {
  fetchSMProviders,
  fetchSyncedData,
  getHealthProviders,
  getPatientNames,
  getProviderDetails,
} from 'services/oneUpService';
import { flatten } from 'lodash';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { Loader } from 'components/Loader';
import { ProviderCardV2 } from 'pages/SyncData/ProviderCardV2';
import { ReactComponent as DoctorIcon } from 'images/shared/doctor-1.svg';
import { ReactComponent as PlusIcon } from 'images/shared/plus-white.svg';
import { EmptyState } from 'ihp-components/components/v2/EmptyState';

const MyProviders = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { getAccessTokenSilently } = useAuth0();
  const [namesMap, setNamesMap] = useState([]);
  const { activeAccountProfile, person } = useSelector((state) => state.user);

  const subjectPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;
  const firstName =
    activeAccountProfile?.attributes?.person?.attributes?.first_name;
  const lastName =
    activeAccountProfile?.attributes?.person?.attributes?.last_name;

  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { inProgressProviders } = oneUpHealthData;
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [loadingConnectedProviders, setLoadingConnectedProviders] =
    useState(true);
  const [hasConnectedProviders, setHasConnectedProviders] = useState(false);
  const [allConnectedProviders, setAllConnectedProviders] = useState({
    entries: [],
    hasErrors: false,
  });

  const fetchTokenAndProviders = useCallback(async () => {
    let connected = [];
    let hasConnected;
    let response;
    let hasData = false;
    const token = await getAccessTokenSilently();

    try {
      if (!subjectPersonId || !token) {
        return;
      }
      setLoadingProviders(true);
      await fetchSyncedData(token, subjectPersonId);
      const participantTaskProviders = await fetchSMProviders(
        token,
        subjectPersonId
      );

      const filteredProviders = participantTaskProviders?.data?.filter(
        (p) => p?.attributes?.status === 'success'
      );

      let participantTaskProvidersIds = filteredProviders?.map((mp) =>
        parseInt(mp?.attributes?.health_system_id)
      );
      participantTaskProvidersIds = [...new Set(participantTaskProvidersIds)];

      const details = await getProviderDetails(
        token,
        participantTaskProvidersIds
      );
      let result = filteredProviders?.map((c) => {
        const detail = details?.filter(
          (d) => d.id === parseInt(c.attributes?.health_system_id)
        )?.[0];

        return {
          ...c,
          logo: detail?.logo,
          name: detail?.name,
          locations: detail?.locations,
        };
      });
      result = result.filter((r) => r !== null);
      setLoadingProviders(false);

      setAllConnectedProviders({
        entries: result,
        hasErrors: false,
      });
    } catch (error) {
      console.error(error);
      setLoadingProviders(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, inProgressProviders, subjectPersonId]);

  useEffect(() => {
    fetchTokenAndProviders();
  }, [fetchTokenAndProviders]);

  if (loadingProviders) {
    return <Loader />;
  }

  let pageContent;
  if (
    !allConnectedProviders.entries ||
    allConnectedProviders?.entries?.length === 0
  ) {
    pageContent = (
      <Box sx={styles.emptyProviders}>
        <EmptyState
          icon={<DoctorIcon sx={{ alignContent: 'center' }} />}
          title='No providers added'
          description='Add a healthcare provider to your account to display their information'
          actionText='Add Healthcare Provider'
        />
      </Box>
    );
  } else {
    pageContent = (
      <Box sx={styles.providersContainer}>
        {flatten([...allConnectedProviders.entries]).map((provider) => {
          return (
            <Box
              onClick={() => {
                const isManualProvider = provider.logo === 'manualProvider';
                if (!isManualProvider) {
                  return navigate('/profile/provider', {
                    state: { provider, isProfile: true },
                  });
                }
              }}
            >
              <ProviderCardV2
                key={provider.id}
                provider={provider}
                setLoadingProviders={setLoadingProviders}
                setIsRedirecting={() => {}}
                setConnectingProvider={() => {}}
                patientName={namesMap?.[parseInt(provider?.health_system_id)]}
                personName={firstName + ' ' + lastName}
                connected={true}
                isProfile={true}
              />
            </Box>
          );
        })}
      </Box>
    );
  }
  return (
    <Box sx={styles.mainContainer}>
      <Box sx={styles.header}>
        <Box sx={styles.title}>My Healthcare Providers</Box>
      </Box>
      {pageContent}
    </Box>
  );
};

export default MyProviders;
