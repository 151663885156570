import { STUDY_ARM_ROLE_CODES } from 'constants/global';

export const GENERAL_ROLE = {
  BIOLOGICAL_MINOR_CHILD: 'BIOLOGICAL_MINOR_CHILD',
  DEPENDENT: 'DEPENDENT',
  MYSELF: 'MYSELF',
  NONE: 'NONE',
};

export const GENERAL_ROLE_OPTIONS = {
  [GENERAL_ROLE.MYSELF]: {
    label: 'Myself',
    options: [
      {
        label: 'I am an adult living with a single ventricle heart defect',
        study_arm_role_code: STUDY_ARM_ROLE_CODES.AdultSV,
      },
      {
        label:
          'I am the biological parent of someone living with a single ventricle heart defect',
        study_arm_role_code: STUDY_ARM_ROLE_CODES.AdultNonSV,
      },
      {
        label:
          'I am the biological sibling of someone living with a single ventricle heart defect',
        study_arm_role_code: STUDY_ARM_ROLE_CODES.AdultNonSV,
      },
      {
        label:
          'I am the biological child of someone living with a single ventricle heart defect',
        study_arm_role_code: STUDY_ARM_ROLE_CODES.AdultNonSV,
      },
      {
        label: 'None of the above',
        study_arm_role_code: 'none',
      },
    ],
  },
  [GENERAL_ROLE.BIOLOGICAL_MINOR_CHILD]: {
    label: 'My biological minor child',
    options: [
      {
        label:
          'Living with a single ventricle heart defect',
        study_arm_role_code: 'r_parent_sv',
      },
      {
        label:
          'The biological sibling of someone living with a single ventricle heart defect',
        study_arm_role_code: 'r_parent_nsv',
      },
      {
        label:
          'The biological child of someone living with a single ventricle heart defect',
        study_arm_role_code: 'r_parent_nsv',
      },
      {
        label: 'None of the above',
        study_arm_role_code: 'none',
      },
    ],
  },
  [GENERAL_ROLE.DEPENDENT]: {
    label: 'My dependent (minor or adult)',
    options: [
      {
        label:
          'Living with a single ventricle heart defect',
        study_arm_role_code: 'r_lar_sv',
      },
      {
        label:
          'The biological sibling of someone living with a single ventricle heart defect',
        study_arm_role_code: 'r_lar_nsv',
      },
      {
        label:
          'The biological child of someone living with a single ventricle heart defect',
        study_arm_role_code: 'r_lar_nsv',
      },
      {
        label: 'None of the above',
        study_arm_role_code: 'none',
      },
    ],
  },

  [GENERAL_ROLE.NONE]: {
    label: 'None of the above',
    options: [
      {
        label: 'None of the above',
        study_arm_role_code: 'none',
      },
    ],
  },
};

export const RESEARCH_ELIGIBILITY_ACCORDION_DETAILS = {
  id: 1,
  title: 'Remember, single ventricle disorders can include:',
  description: `
    <ul style="padding-left: 20px">
      <li>Hypoplastic Left Heart Syndrome (HLHS) </li>
      <li>Hypoplastic Right Heart (sometimes called HRHS) </li>
      <li>Tricuspid Atresia </li>
      <li>Double Inlet Left Ventricle (DILV) </li>
      <li>Double Outlet Right Ventricle (DORV) – some types </li>
      <li>Ebstein’s Anomaly </li>
      <li>Single Left Ventricle </li>
      <li>Pulmonary Atresia with Intact Ventricular Septum (PA-IVS) </li>
      <li>Unbalanced Atrioventricular (AV) Canal </li>
      <li>Other complex heart defects leading to a Fontan and/or Glenn operation and a single functioning pumping chamber</li>
    </ul>
  `,
};
