import { Slider as MUISlider } from '@mui/material';
import { styled } from '@mui/system';

import SliderThumbIcon from 'images/redeem/grip-lines-vertical.svg';

export const Slider = styled(MUISlider)(({ theme }) => ({
  '& .MuiSlider-root': {
    color: `${theme.palette.primary.lightBlue70} !important`,
  },
  '& .MuiSlider-thumb': {
    height: '21px',
    width: '21px',
    backgroundColor: theme.palette.primary.lightBlue100,
    backgroundImage: `url(${SliderThumbIcon})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '13px 14px',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.primary.lightBlue2,
    height: '14px',
  },
  '& .MuiSlider-track': {
    height: '14px',
    backgroundColor: theme.palette.primary.lightBlue70,
    borderColor: theme.palette.primary.lightBlue70,
  },
  '& .MuiSlider-mark': {
    height: '14px',
    color: theme.palette.primary.white,
  },
}));
