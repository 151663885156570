import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Divider } from '@mui/material';

import { BaseHeader } from 'components/BaseLayout';
import { DropDownCalendar } from 'pages/NotificationCenter/MedicationTracker/DropDownCalendar';
import {
  FloatingAddButton,
  Content,
  StyledBaseLayout,
} from 'pages/NotificationCenter/MedicationTracker/styles';
import { ReminderCard } from './ReminderCard';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import { useDetailedData } from './hooks';
import {
  getMedicationTrackerPath,
  getMedicationTrackerEditPath,
} from 'pages/NotificationCenter/paths';

const MedicationTrackerDetailedMobile = ({ date, onDateChange }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [medicationName, reminderIds] = useDetailedData(id, date);

  return (
    <StyledBaseLayout
      header={
        <>
          <BaseHeader
            onClickBack={() =>
              navigate(
                getMedicationTrackerPath({
                  date: format(date, 'yyyy-MM-dd'),
                })
              )
            }
          >
            {medicationName}
          </BaseHeader>
          <Divider />
          <DropDownCalendar date={date} onDateChange={onDateChange} />
        </>
      }
      content={
        <Content>
          {reminderIds.map((id) => (
            <ReminderCard key={id} id={id} date={date} />
          ))}
          <FloatingAddButton
            component={Link}
            to={getMedicationTrackerEditPath(id)}
            startIcon={<PlusIcon />}
          >
            Add
          </FloatingAddButton>
        </Content>
      }
    />
  );
};

export default MedicationTrackerDetailedMobile;
