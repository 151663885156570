import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { FormCardItem } from 'components/FormCard';
import ActivityHeader from 'components/Activities/Header';
import { ProgressButtons } from 'components/Buttons';

export const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray20,
}));

export const StyledFormCardItem = styled(FormCardItem)({
  padding: '24px',
});

export const VideoActivityContent = styled(Box)({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

export const StepContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
}));

export const StyledCircleIcon = styled(CircleIcon)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontSize: '4px',
}));

export const VideoPageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

export const StepperWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '700px',
  margin: 'auto',
}));

export const StyledActivityHeader = styled(ActivityHeader)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.lightGray5,
  '& > :first-child': {
    position: 'absolute',
    left: '25px',
    top: '25px',
  },
  '& > :last-child': {
    position: 'absolute',
    right: '24px',
    top: '25px',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.gray2,
  },
}));

export const StyledProgressButtons = styled(
  ({ isLastStep = false, ...rest }) => <ProgressButtons {...rest} />
)(({ isLastStep }) => ({
  width: '100%',
  margin: '25px 0px',
  alignItems: 'center',
  justifyContent: `center ${isLastStep ? '!important' : ''}`,
}));

export const VideoContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
}));

export const VideoDescription = styled('div')(({ theme }) => ({
  padding: '14px 30px',
  p: {
    margin: '16px 0',
  },
  ul: {
    paddingLeft: '32px',
  },
}));
