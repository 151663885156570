import { styled, Box } from '@mui/material';

export const FormBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.white,
    border: `1px solid ${'#D9D9D9'}`,
    boxSizing: 'border-box',
    borderRadius: '8px',

    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
    padding: '0 15px',
    height: '50px',
    marginTop: '8px',
    cursor: 'pointer',
    outline: 'none',

    '&:hover': {
      borderColor: theme.palette.primary.gray50,
    },
    '&:focus': {
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
      borderColor: theme.palette.primary.fuchsia75,
    },
  }));

  export const NotificationTitle = styled('p')(({ theme })=>({
    color: '#7C7C7C',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '200%'
}));

export const NotificationSubTitle = styled('p')(({ theme })=>({
  color: '#7C7C7C',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '140%'
}));

  const styles = (theme) => ({
    formWrapper: {
      width: '100%',
      maxWidth: '600px',
      marginTop: '30px',
    },
    inputWrapper: { marginBottom: '25px', flex: '1' },
  });

  export const getStyles = (theme) => {
    return styles(theme);
  };