import { useSelector } from 'ihp-bloom-redux/app/redux';

import {
  selectMedicationIds,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

import { Content } from 'pages/NotificationCenter/MedicationTracker/styles';
import { DrugCard } from './DrugCard';

export const DrugCardList = () => {
  const medicationIds = useSelector(selectMedicationIds());
  return (
    <Content>
      {medicationIds.map(id => <DrugCard key={id} id={id} />)}
    </Content>
  );
};
