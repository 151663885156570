import { createSlice } from '@reduxjs/toolkit';

import getNormalizedActions from '../utils/normalizedActions';

const initialState = {
  ids: ['001', '002', '003', '004', '005', '006', '007'],
  entities: {
    '001': {
      id: '001',
      name: 'Enrollment app',
      enabled: false,
    },
    '002': {
      id: '002',
      name: 'Identity verification',
      enabled: false,
      to: 'verification',
    },
    '003': {
      id: '003',
      name: 'Prescreening / Eligibility',
      enabled: false,
      to: 'prescreening',
    },
    '004': {
      id: '004',
      name: 'Consent',
      enabled: false,
      to: 'consent',
    },
    '005': {
      id: '005',
      name: 'HIPAA',
      enabled: false,
      to: 'hipaa',
    },
    '006': {
      id: '006',
      name: 'Account creation',
      enabled: false,
      to: 'account',
    },
    '007': {
      id: '007',
      name: 'Welcome page',
      enabled: false,
      to: 'welcome',
    },
  },
};

const normalizedActions = getNormalizedActions();
const slice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    reorderEnrollment: normalizedActions.reorderAction,
    enableEnrollment: normalizedActions.enableAction,
  },
});

export const { reorderEnrollment, enableEnrollment } = slice.actions;

export const selectEnrollmentIds = (state) => state[slice.name].ids;
export const selectEnrollmentById = (id) => (state) =>
  state[slice.name].entities[id];
export const selectNextEnrollmentById = (id) => (state) => {
  let data = {}
  const currentIndex = state[slice.name].ids.findIndex(item => item === id);
  if (currentIndex > -1 && ((currentIndex + 1) < state[slice.name].ids.length)) {
    const nextId = state[slice.name].ids[currentIndex + 1];
    data = state[slice.name].entities[nextId]
  }
  return data;
}

export default slice;
