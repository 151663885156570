import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  FormLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import getStyles from './FormCheckbox.styles';
import { UnCheckedboxIcon } from './styles';
import { ReactComponent as CheckedboxIcon } from 'images/components/checkedBox.svg';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';

export const FormCheckbox = forwardRef(
  ({ fieldLabel, label, containerStyle, ...rest }, ref) => {
    const error = false;
    const theme = useTheme();
    const styles = getStyles(theme, error);

    return (
      <>
        {fieldLabel && (
          <FormLabel sx={styles.formLabel}>{fieldLabel}</FormLabel>
        )}
        <FormGroup sx={[styles.formGroup, containerStyle]}>
          <FormControlLabel
            sx={styles.formControlLabel}
            size='large'
            label={label}
            control={
              <Checkbox
                ref={ref}
                icon={<UnCheckedboxIcon />}
                checkedIcon={<CheckedboxIcon />}
                {...rest}
              />
            }
          />
        </FormGroup>
        {error && (
          <FormHelperText sx={styles.errResponseText}>
            <InformationIcon />
            This field is required
          </FormHelperText>
        )}
      </>
    );
  }
);

FormCheckbox.propTypes = {
  fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerStyle: PropTypes.object,
};
