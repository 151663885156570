import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { MenuItem, Typography, Divider } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import { ReactComponent as Caretdown } from 'images/navigator/caret-down.svg';

import { useLogout } from 'utils/useLogout';

import {
  ProfileAvatar,
  StyledBox,
  StyledTypography,
  StyledMenu,
} from './style';
import { getPersonFullNameAndInitials } from 'pages/User/utils';

const enabledRoutes = ['/home'];

const ProfileOptions = () => {
  const navigate = useNavigate();
  const logout = useLogout();
  const theme = useTheme();
  const location = useLocation();
  const downSm = useMediaQuery(theme.breakpoints.down('md'));

  const { activeAccountProfile, accountProfiles, personAttributes } = useSelector(
    (state) => state.user
  );

  const [anchorElProfileOption, setAnchorElProfileOption] = useState(null);

  const openProfileOption = Boolean(anchorElProfileOption);
  const firstName =
    activeAccountProfile?.attributes?.person?.attributes?.first_name;
  const lastName =
    activeAccountProfile?.attributes?.person?.attributes?.last_name;
  const preferredName = personAttributes?.preferred_name;

  const { fullName, initials } = getPersonFullNameAndInitials(firstName, lastName, preferredName);

  const shouldShowSwitchProfile = accountProfiles?.length > 1;
  const cursor = {
    cursor: 'pointer',
  };
  const fontWeight = {
    fontWeight: '600',
  };
  const shouldHide = !enabledRoutes.includes(location.pathname);
  const onMenuClose = () => {
    setAnchorElProfileOption(null);
  };
  const onSwitchProfileClick = () => {
    onMenuClose();
    navigate('/switch-profile');
  };
  const onLogoutClick = () => {
    onMenuClose();
    logout();
  };

  if (shouldHide) {
    return <></>;
  }
  return (
    <StyledBox>
      <ProfileAvatar alt={fullName} sizes='small'>
        {initials}
      </ProfileAvatar>
      {!downSm && (
        <StyledTypography>
          {fullName}
        </StyledTypography>
      )}

      <Caretdown
        id='profile-options-button'
        aria-controls={openProfileOption ? 'profile-options-menu' : undefined}
        aria-expanded={openProfileOption ? 'true' : undefined}
        onClick={(event) => setAnchorElProfileOption(event.currentTarget)}
        variant='contained'
        style={cursor}
      />
      <StyledMenu
        id='profile-options-menu'
        anchorEl={anchorElProfileOption}
        MenuListProps={{
          'aria-labelledby': 'profile-options-button',
        }}
        open={openProfileOption}
        onClose={onMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {shouldShowSwitchProfile && (
          <>
            <MenuItem onClick={onSwitchProfileClick}>
              <Typography variant='body2' color='text.secondary'>
                Switch profile
              </Typography>
            </MenuItem>
            <Divider />
          </>
        )}
        <>
          <MenuItem onClick={onLogoutClick}>
            <Typography
              variant='body2'
              color={theme.palette.primary.darkblue70}
              sx={fontWeight}
            >
              Log out
            </Typography>
          </MenuItem>
        </>
      </StyledMenu>
    </StyledBox>
  );
};

export default ProfileOptions;
