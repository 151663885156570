/**
 *
 * @param {date string or Date object} date
 */
export const useIsValidDate = () => {
  const isValidDate = (date) => {
    const dateString = date?.toString();

    if (dateString) {
      return dateString !== 'Invalid Date';
    }
  };
  return { isValidDate };
};
