import {
  Footer,
  Header,
} from './Components';
import { Content, StyledBaseLayout } from './styles';

const CreatePregnancyJournalDayDesktop = ({
  date,
  steps,
  step,
  lastStep,
  isError,
  isLoading,
  handleForward,
  handleBackward,
}) => (
  <StyledBaseLayout
    header={
      <Header
        date={date}
        currentStep={step}
        lastStep={lastStep}
      />
    }
    content={
      isError
        ? null
        :
        <Content>
          {steps[step - 1]}
          <Footer
            currentStep={step}
            lastStep={lastStep}
            onForward={handleForward}
            onBackward={handleBackward}
            disabled={isLoading}
          />
        </Content>
    }
  />
);

export default CreatePregnancyJournalDayDesktop;
