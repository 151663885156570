import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import { useGetResearchOpportunityDetailsQuery } from 'ihp-bloom-redux/features/researchOpportunities/researchOpportunitiesApiSlice';
import Chip from 'ihp-components/components/v2/Chip';

import { OpportunityStatusModel } from '../../components/ResearchOpportunity/OpportunityStatusModel';

import { WarningModal } from '../EducationalMaterials/Modals/warningModal';
import { statusMapping } from '../../components/ResearchOpportunity/List/statusMapping';
import { Loader } from 'components/Loader';

import {
  Card,
  CardContentDescription,
  CardContentTitle,
  CardSubtitler,
  CardContentWapper,
  CardFooter,
  CardTitle,
  CardTitleWrapper,
  ChipWrapper,
  Container,
  ContainerWrapper,
  getStyles,
  Header,
  PageTitle,
  ReadMoreButton,
  SubHeader,
} from './researchOpportunityDetailsStyles';
import { ReactComponent as BackArrowIcon } from 'images/shared/chevron-left-2.svg';
import { ReactComponent as OpenNewIcon } from 'images/shared/open-new.svg';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';


function ResearchOpportunityDetailsPage() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { activeAccountProfile } = useSelector((state) => state.user);
  const personStudyArm =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [targetAddress, setTargetAddress] = useState(null);
  const [
    showSelectOpportunityStatusModal,
    setShowSelectOpportunityStatusModal,
  ] = useState(false);
  const [selectOpportunityStatus, setSelectOpportunityStatus] = useState(null);
  const [item, setItem] = useState({});

  const researchOpportunityId = parseInt(useParams().id);

  const { showWarningModal } = useSelector((state) => state.configuration);
  const navigate = useNavigate();
  const statusMap = statusMapping(theme);

  let customStatusStyle = {};
  let status = {};

  const {
    data,
    isLoading,
    item: opportunity,
    refetch,
  } = useGetResearchOpportunityDetailsQuery(
    {
      'filter[person-study-arm]': personStudyArm,
      researchOpportunityId: researchOpportunityId,
      include: 'study_sponsor,person_study_arm_research_opportunities',
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data,
        isLoading,
        isUninitialized,
        isFetching,
        error,
      }) => {
        return {
          data: data,
          error: error,
          isLoading: isLoading || isUninitialized || isFetching,
          item: {
            id: data?.data?.id,
            title: data?.data?.attributes['title'],
            summary: data?.data?.attributes['summary'],
            description: data?.data?.attributes['description'],
            link: data?.data?.attributes['link'],
            status: data?.data?.attributes['status'],
            studySponsorsName: data?.included?.find(
              (sponsor) =>
                +sponsor.id === +data?.data?.attributes['study_sponsor_id']
            )?.attributes?.name,
            ...(() => {
              let includes = {
                study_sponsors_name: '',
                person_study_arm_research_opportunity: null,
              };
              for (let i = 0; i < data?.included?.length; i++) {
                const element = data?.included[i];
                if (
                  +element?.id === +data?.data?.attributes['study_sponsor_id']
                ) {
                  includes['study_sponsors_name'] = element?.attributes?.name;
                }
                if (
                  element?.attributes['research_opportunity_id'] ===
                  +data?.data?.id
                ) {
                  includes['person_study_arm_research_opportunity'] =
                    +element?.id;
                }
              }
              return includes;
            })(),
          },
        };
      },
    }
  );

  useEffect(() => {
    setItem(opportunity);
  }, [data]);

  if (item?.status) {
    status = statusMap[item?.status];
    if (!status?.text) {
      status = statusMap['status'];
    }
    const customStyle = { backgroundColor: status.color, cursor: 'pointer' };
    const statusStyle = {
      borderColor: theme.palette.primary.darkGray70,
      color: theme.palette.primary.darkGray70,
    };

    customStatusStyle = status?.text === 'Status' ? statusStyle : customStyle;
  }

  const handleOnReadMore = () => {
    if (showWarningModal) {
      setTargetAddress(item.link);
      setIsOpenWarningModal(true);
    } else {
      window.open(item?.link, '_blank');
    }
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const goBack = () => {
    navigate('/research-opportunities');
  };
  const handleClick = (opportunity) => {
    setSelectOpportunityStatus(opportunity);
    setShowSelectOpportunityStatusModal(true);
  };

  const toggleSelectOpportunityStatusModal = (status) => {
    setShowSelectOpportunityStatusModal(!showSelectOpportunityStatusModal);
    if (status) {
      setItem({ ...item, status: status });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <WarningModal
        isOpen={isOpenWarningModal}
        setIsOpenModal={setIsOpenWarningModal}
        targetAddress={targetAddress}
        newTab={true}
      />
      <Header>
        <SubHeader onClick={goBack}>
          <BackArrowIcon style={styles.backArrowIcon} />
          <PageTitle>External Research</PageTitle>
        </SubHeader>
      </Header>
      {item.id && (
        <ContainerWrapper>
          <Container>
            <Card>
              <CardTitleWrapper>
                <CardTitle>{item.title}</CardTitle>
                <CardSubtitler>{item.studySponsorsName}</CardSubtitler>
                <ChipWrapper>
                  {item.status !== null && (
                    <Chip
                      icon={status.icon}
                      variant={status.variant}
                      type={status.type}
                      sx={{ ...customStatusStyle }}
                      onClick={() => handleClick(item)}
                    >
                      {status.text}
                    </Chip>
                  )}
                </ChipWrapper>
              </CardTitleWrapper>

              <CardContentWapper>
                <CardContentTitle>Brief Summary:</CardContentTitle>
                <CardContentDescription>{item.summary}</CardContentDescription>
              </CardContentWapper>

              <CardContentWapper>
                <CardContentTitle>Detailed Description:</CardContentTitle>
                <CardContentDescription>
                  {item.description}
                </CardContentDescription>
              </CardContentWapper>

              <CardFooter>
                <ReadMoreButton
                  variant='link'
                  onClick={handleOnReadMore}
                  endIcon={<OpenNewIcon style={styles.readMoreIcon} />}
                >
                  Read more
                </ReadMoreButton>
              </CardFooter>
            </Card>
          </Container>
        </ContainerWrapper>
      )}
      {showSelectOpportunityStatusModal && (
        <OpportunityStatusModel
          isOpen={showSelectOpportunityStatusModal}
          onClose={toggleSelectOpportunityStatusModal}
          selectOpportunityStatus={selectOpportunityStatus}
        />
      )}
    </>
  );
}

export default withPerson(withSelectedProfile(ResearchOpportunityDetailsPage));
