import { styled } from '@mui/material';
import { ReactComponent as BackIcon } from 'images/shared/chevron-left-2.svg';

export const LayoutContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#F2F2F2',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '72px 0px',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
    padding: '36px 0px',
    backgroundColor: 'white',
  },
}));

export const LayoutContent = styled('div')(({ theme, maxWidth = '375px' }) => ({
  width: '100%',
  maxWidth,
  height: 'fit-content',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '50px 40px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const GoBack = styled(BackIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.primary.darkBlue70,
  marginBottom: '30px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
