import { styled, Slider, Chip } from '@mui/material';
import CheckIcon from 'images/profile/check-white.svg';

export const MobileCardWrapper = styled('div')(
  ({ theme, backgroundColor }) => ({
    width: '100%',
    height: '106px',
    padding: '15px',
    display: 'flex',
    gap: '15px',
    backgroundColor: backgroundColor,
  })
);

export const MobileCardInfoBox = styled('div')(({ theme }) => ({
  textAlign: 'left',
}));

export const StyledSlider = styled(Slider)(
  ({ theme, isCompleted, backgroundColor }) => ({
    padding: '6px 0 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 0 12px',
    },
    '& .MuiSlider-thumb': {
      height: '18px',
      width: '18px',
      color: '#ffffff',
      backgroundColor: backgroundColor,
      backgroundImage: `url(${CheckIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '10px 13px',
      display: `${isCompleted ? 'flex' : 'none'}`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette.primary.gray10,
      height: '8px',
    },
    '& .MuiSlider-track': {
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
      height: '8px',
    },
  })
);

export const RewardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.primary.gray75,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '5px',
}));

export const StyledChip = styled(Chip)(({ theme, isCompleted }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
  background: !isCompleted
    ? theme.palette.primary.gray5
    : theme.palette.primary.white,
  borderRadius: '4px',
  margin: '0 2.5px',
}));

export const Title = styled('div')(({ theme, inProgress }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: !inProgress
    ? theme.palette.primary.gray75
    : theme.palette.primary.gray100,
  margin: '0px',
  textAlign: 'center',
}));

export const MyPoints = styled('span')(({ theme }) => ({
  color: theme.palette.primary.gray100,
}));
