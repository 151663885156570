import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import { Card, Typography, CardContent, CardHeader } from '@mui/material';

import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { RadioGroupInput } from 'components/Input';

import {
  RadioButtonsContainer,
  RadioButtonsToggleCardWrapper,
  StyledCard,
} from './style';

function RadioButtonsToggleCard({
  defaultValue,
  radioButtonProps,
  cardTitle,
  cardDescription,
  icon,
  showCard,
  sx,
  title,
  category,
  onChange,
}) {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedVal, setSelectedVal] = useState('');

  useEffect(() => {
    if (defaultValue) {
      setSelectedVal(defaultValue);
    }
  }, [defaultValue]);

  const radioButtonHandler = (value) => {
    setSelectedVal(value);
  };

  const radioButtonstyle = {
    display: 'flex',
    'flex-direction': 'row',
    gap: '20px',
    width: '100%',
    '& span': {
      width: '100%',
      margin: '0px !important',
    },
  };
  const cardTitleStyle = {
    color: '#444',
    'font-size': '14px ',
  };

  return (
    <RadioButtonsToggleCardWrapper
      sx={!isMobile && !isTablet ? { width: '600px' } : { width: sx.width }}
    >
      <Typography
        color='#6B757A'
        fontWeight={600}
        fontSize={18}
        marginBottom='15px'
      >
        {title}
      </Typography>
      <RadioButtonsContainer>
        <RadioGroupInput
          onChange={(e) => onChange(e.target.value, category)}
          value={selectedVal}
          optionsStyles={radioButtonstyle}
        >
          {radioButtonProps.map((prop) => (
            <RadioInput key={prop.id} value={prop.value} label={prop.label} />
          ))}
        </RadioGroupInput>
      </RadioButtonsContainer>

      {selectedVal === showCard && (
        <StyledCard>
          <Card>
            <CardHeader
              action={icon}
              title={cardTitle}
              titleTypographyProps={cardTitleStyle}
            />
            <CardContent>
              <Typography
                variant='body2'
                color='text.secondary'
                fontSize='12px'
              >
                {cardDescription}
              </Typography>
            </CardContent>
          </Card>
        </StyledCard>
      )}
    </RadioButtonsToggleCardWrapper>
  );
}
export default RadioButtonsToggleCard;
