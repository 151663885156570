import { useRef } from 'react';
import {
  useGetParticipantTaskWithRelationshipQuery,
  useGetProfileParticipantTaskQuery,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import {
  useGetTaskQuery,
  useGetTaskTypeQuery,
} from 'ihp-bloom-redux/features/tasks/taskApiSliceV3';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

const parseAttributeValue = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

const secToMin = (sec) => sec && sec / 60;

export const formatTaskAttributes = (data) => {
  const attributes = data?.included?.reduce((accumulator, currentValue) => {
    if (currentValue?.type === 'task-attributes') {
      return {
        ...accumulator,
        [currentValue?.attributes?.attribute]: parseAttributeValue(
          currentValue?.attributes?.value
        ),
      };
    } else if (currentValue?.type === 'task-versions') {
      return {
        ...accumulator,
        form: currentValue?.attributes?.instrument,
      };
    }
    return {
      ...accumulator,
    };
  }, {});

  const compensationValue = data?.data?.attributes?.point;
  const estimatedTime = secToMin(attributes?.duration?.timing_duration);
  const timeLimit = attributes?.availability_window;
  const thankyouMsg = attributes?.celebrationScreen?.title;
  const hasCelebrationScreen = attributes?.celebrationScreen?.display;

  return {
    compensationValue,
    estimatedTime,
    timeLimit,
    thankyouMsg,
    hasCelebrationScreen,
    attributes,
  };
};

export const useGetParticipantTask = (participantTaskId) => {
  const timestampRef = useRef(Date.now()).current;
  const { personId, currentUserLoading } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => ({
      personId: data?.data?.included?.[0]?.[0]?.data?.id,
      currentUserLoading: isLoading || isUninitialized || isFetching,
    }),
  });

  const { id, participantTaskAttributes, isLoadingParticipantTaskDetails } =
    useGetParticipantTaskWithRelationshipQuery(
      {
        participantId: personId,
        participantTaskId: participantTaskId,
        include: 'last-participant-task-entry',
        sessionId: timestampRef, // passing this sessionId to disable cache so always fetch new data
      },
      {
        skip: !personId,
        selectFromResult: ({
          data,
          isLoading,
          isUninitialized,
          isFetching,
        }) => {
          return {
            id: data?.data?.id,
            participantTaskAttributes: data?.data?.attributes,
            isLoadingParticipantTaskDetails:
              isLoading || isUninitialized || isFetching,
          };
        },
      }
    );

  const { taskData, isLoadingTask } = useGetTaskQuery(
    { taskId: participantTaskAttributes?.task_id },
    {
      skip: !participantTaskAttributes?.task_id,
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => {
        return {
          taskData: data,
          isLoadingTask: isLoading || isUninitialized || isFetching,
        };
      },
    }
  );

  const { taskTypeData, isLoadingTaskType } = useGetTaskTypeQuery(
    { taskTypeId: taskData?.data?.attributes?.task_type_id },
    {
      skip: !taskData?.data?.attributes?.task_type_id,
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => {
        return {
          taskTypeData: data,
          isLoadingTaskType: isLoading || isUninitialized || isFetching,
        };
      },
    }
  );

  const loadingParticipantTask =
    currentUserLoading ||
    isLoadingParticipantTaskDetails ||
    isLoadingTask ||
    isLoadingTaskType;

  const participantTaskEntry = taskData?.included?.find(
    (item) => item?.type === 'participant-task-entries'
  );

  return {
    personId,
    id: parseInt(id), // This is the participant task id
    type: taskTypeData?.data?.attributes?.code,
    ...taskData?.data?.attributes,
    ...formatTaskAttributes(taskData),
    completed: participantTaskAttributes?.status === 'completed',
    startDate: participantTaskAttributes?.start_date,
    endDate: participantTaskAttributes?.end_date,
    statusDatetime: participantTaskAttributes?.status_datetime,
    participantTaskEntry: participantTaskEntry?.data,
    loadingParticipantTask,
  };
};

export const formatProfileTaskAttributes = (data, instrument) => {
  const attributes = data?.attributes?.reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue?.name]: parseAttributeValue(currentValue?.value),
    };
  }, {});

  const compensationValue = data?.points;
  const estimatedTime = secToMin(attributes?.duration?.timing_duration);
  const timeLimit = attributes?.availability_window;
  const thankyouMsg = attributes?.celebrationScreen?.title;
  const hasCelebrationScreen = attributes?.celebrationScreen?.display;

  attributes.form = instrument;

  return {
    compensationValue,
    estimatedTime,
    timeLimit,
    thankyouMsg,
    hasCelebrationScreen,
    attributes,
  };
};

export const useGetProfileParticipantTask = (
  participantTaskId,
  accountProfileId
) => {
  const timestampRef = useRef(Date.now()).current;

  const {
    id,
    participantTaskAttributes,
    isLoadingParticipantTaskDetails,
    instrument,
    participantTaskEntry,
  } = useGetProfileParticipantTaskQuery(
    {
      accountProfileId: accountProfileId,
      participantTaskId: participantTaskId,
      params: {
        include: 'last-participant-task-entry,task-version',
        sessionId: timestampRef, // passing this sessionId to disable cache so always fetch new data
      },
    },
    {
      skip: !accountProfileId || !participantTaskId,
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => ({
        id: data?.data?.id,
        participantTaskAttributes: data?.data?.attributes,
        isLoadingParticipantTaskDetails:
          isLoading || isUninitialized || isFetching,
        instrument: data?.included?.['task-version']?.instrument,
        participantTaskEntry: data?.included?.['last-participant-task-entry'],
      }),
    }
  );
  const participantTask = participantTaskAttributes?.['participant-task'];
  const taskData = participantTaskAttributes?.['task'];

  return {
    personId: participantTask?.person_id,
    personStudyArmId: participantTask?.person_study_arm_id,
    id: parseInt(id), // This is the participant task id
    ...taskData,
    ...(taskData && formatProfileTaskAttributes(taskData, instrument)),
    completed: participantTask?.status === 'completed',
    startDate: participantTask?.start_date,
    endDate: participantTask?.end_date,
    statusDatetime: participantTask?.status_datetime,
    participantTaskEntry: participantTaskEntry?.data,
    loadingParticipantTask: isLoadingParticipantTaskDetails,
  };
};
