import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';
import { ArrowForwardIos } from '@mui/icons-material';

import {
  participantTaskApiSlice,
  useGetAllProfileParticipantTasksQuery,
  useUpdateParticipantTaskMutation,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllPersonAttributesQuery } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { updateLastOpenedConsent } from 'ihp-bloom-redux/features/consent/consentSlice';

import {
  activityTypeNamesMapping,
  ActivityTypesIconMap,
  ActivityTypesLinksMap,
} from 'utils';
import { mapTaskRequiredStatus } from 'utils/tasks';
import { ActivityCard } from 'components/Cards';
import { ActivityCardMobile } from 'components/Cards/ActivityCardMobile';
import { Loader } from 'components/Loader';

import {
  filterParticipantTasks,
  formatParticipantTasks,
} from '../../../utils/tasks';
import { getFormFields } from '../../Profile/MyProfile/utils';
import { getColors, iconBgColorMap } from '../../Activities/utils';
import { STUDY_ARM_CODE } from '../../../constants/global';
import {
  Container,
  getStyles,
  Header,
  Link,
  Title,
  ViewAllButton,
  Icon,
} from './style';
import { isHealthSurvey } from 'pages/Activities/List/util';
import ActivitiesList from 'pages/Activities/List/ActivitiesList';

function Activities() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [tasks, setTasks] = useState([]);
  const [activity, setActivity] = useState(null);
  const [activities, setActivities] = useState([{}]);
  const [icon, setIcon] = useState('');
  const [colors, setColors] = useState(null);
  const [selectedConsent, setSelectedConsent] = useState({});
  const [trigger, result] =
    participantTaskApiSlice.endpoints.getParticipantTask.useLazyQuery();
  const user = useSelector((state) => state.user);

  const { personAttributes } = useGetAllPersonAttributesQuery(
    user?.person?.id,
    {
      skip: !user?.person?.id,
      selectFromResult: ({ data }) => ({
        personAttributes: data?.included,
      }),
    }
  );
  let fields = getFormFields({ timezone: 'timezone' }, personAttributes);
  const { person, activeAccountProfile } = useSelector((state) => state.user);
  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const {
    data: response,
    isLoading,
    refetch,
  } = useGetAllProfileParticipantTasksQuery({
    accountProfileId,
    query: {
      display_status: 'active,overdue',
    },
  });

  const [updateParticipantTaskMutation, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();
  const handleProfileActivity = async (id) => {
    const requestBody = {
      participantTaskId: id,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(id),
          attributes: {
            status: 'completed',
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody)
      .then((response) => {
        refetch();
        if (!response.error) {
          // SING-676
          // navigate('/profile/accountholder-profile');
          navigate('/profile/accountholder-profile', {
            state: {
              redirectedFromUpdateProfileActivity: true,
            },
          });
        }
      })
      .catch(console.error);
  };

  const handleSignConsent = (consent) => {
    setSelectedConsent(consent);
    dispatch(updateLastOpenedConsent(consent));
    trigger({
      participantId: user?.person?.id,
      participantTaskId: consent.participantTaskId,
    });
  };

  useEffect(() => {
    let filteredTasks = filterParticipantTasks(response?.data || []);
    filteredTasks = formatParticipantTasks(filteredTasks);
    filteredTasks = filteredTasks?.sort(
      (a, b) => (a?.due_date > b?.due_date) - (a?.due_date < b?.due_date)
    );
    setTasks([...filteredTasks]);
  }, [response?.data]);

  useEffect(() => {
    if (tasks.length) {
      setActivities([...tasks]);
      setActivity({ ...tasks[0] });
      setIcon(ActivityTypesIconMap[tasks[0]?.type]);
      setColors(getColors(tasks[0]?.isOverdue, tasks[0]?.isPast));
    }
  }, [tasks]);

  if (!tasks.length) {
    return null;
  }
  let taskLink = ActivityTypesLinksMap(
    activity?.participantTaskId,
    activity?.type
  );

  if (isHealthSurvey(activity?.code)) {
    taskLink = `/health-survey/${activity?.id}`;
  }

  return (
    <>
      <Container>
        <Header>
          <Title>Activities</Title>
          {/* {studyArmCode?.toLowerCase() === STUDY_ARM_CODE.RESEARCH && (
            <Link onClick={() => navigate(`/my-activities`)}>
              View all <ArrowForwardIos style={Icon} />
            </Link>
          )} */}
        </Header>
        {isLoading ? <Loader /> : activity && <ActivitiesList />}
        {/* {tasks?.length > 1 && (
          <ViewAllButton
            endIcon={<ArrowForwardIos style={styles.icon} />}
            onClick={() => navigate(`/my-activities`)}
          >
            View All
          </ViewAllButton>
        )} */}
      </Container>
    </>
  );
}

export default Activities;
