import { styled, textAlign } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Button from 'ihp-components/components/v2/Button';
import avBgSrc from 'images/welcome/av-bg.png';

export const Container = styled(Box)({
  background: 'white',
});

export const HeroWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: theme.palette.primary.navy,
  '@media only screen and (min-width: 900px) and (max-width: 1000px)': {
    justifyContent: 'center',
  },
}));

export const HeroContentWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  padding: '0px 100px 40px 100px',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  backgroundColor: theme.palette.primary.navy,
  backgroundImage: `url(${avBgSrc})`,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr',
    padding: '60px 20px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    padding: '40px 100px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px 100px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '60px 20px 40px',
  },
}));

export const HeroItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const HeroFlowerImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  [theme.breakpoints.between('sm', 'md')]: {
    margin: '0 auto',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '18px',
  },
}));

export const MobileWrapper = styled(Box)({
  height: 'calc(100vh - 75px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const BtnWrapper = styled(Box)({
  'a, button': {
    width: '100%',
    marginBottom: '15px',
  },
});

export const PageLink = styled(Link)(({ navExpanded, theme }) => ({
  display: 'block',
  fontFamily: 'Nunito Sans',
  color: '#011C48',
  fontSize: '15px',
  textDecoration: 'none',
  fontWeight: navExpanded ? '800' : '800',
  lineHeight: '44px',
  padding: '10px 5px',
  [theme.breakpoints.down('lg')]: {
    textAlign: 'center',
  },
}));

export const GetStartedBtn = styled(Button)({
  height: '50px',
  width: '170px',
});

export const JoinUsBtn = styled(Button)({
  padding: '12px 25px',
  whiteSpace: 'nowrap',
  backgroundColor: '#011C48',
  color: '#fff',
  borderRadius: '120px',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20.25px',
});

export const LearnMoreBtn = styled(Button)({
  borderRadius: '120px',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20.25px',
  backgroundColor: '#334A6D',
  border: '2px solid transparent',
});

export const LoginBtn = styled(Button)({
  padding: '12px 25px',
  border: '2px solid #00BC70',
  backgroundColor: '#fff',
  color: '#011C48',
  borderRadius: '120px',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20.25px',
});

export const JoinNowBtn = styled(Button)({
  padding: '12px 25px',
  backgroundColor: '#011C48',
  border: '2px solid #00BC70',
  whiteSpace: 'nowrap',
  color: '#fff',
  borderRadius: '120px',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20.25px',
});

export const MobileMenuContainer = styled(Box)(({ isOpen, theme }) => ({
  position: 'fixed',
  left: isOpen ? 0 : '-100%',
  width: '100%',
  top: '0',
  height: '100%',
  padding: '20px 20px',
  zIndex: 100,
  background: theme.palette.primary.white,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  transition: 'left 0.3s',
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const DesktopMenuContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  alignItems: 'center',
}));

export const Navbar = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.white,
}));

export const NavContainer = styled(Box)(({ theme }) => ({
  maxWidth: '1240px',
  margin: '0 auto',
  padding: '20px',
  gap: '10px',
  width: '100%',
  zIndex: 100,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
  [theme.breakpoints.between('md', 'lg')]: {
    padding: '20px 30px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 15px',
  },
}));

export const MenuButton = styled('button')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    display: 'block',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
}));

export const CloseMenuButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: 20,
  padding: '10px',
  background: 'none',
  border: 'none',
  cursor: 'pointer',
}));

export const AcmeTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.green,
  fontSize: '40px',
  fontWeight: 900,
  lineHeight: "48px",
  [theme.breakpoints.down('sm')]: {
    // textAlign: 'center',
    lineHeight: '50.4px',
  },
}));

export const AcmeSubtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.white,
  fontSize: '18px',
  fontWeight: 600,
  [theme.breakpoints.down('sm')]: {
    // textAlign: 'center',
    lineHeight: '27px',
  },
}));
