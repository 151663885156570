import { AccordionDetails, Stack, Typography } from '@mui/material';

import {
  ExpandIcon,
  StyledAccordion,
  StyledAccordionSummary
} from './styles';

const Summary = ({ title, subtitle = '', value }) => (
  <StyledAccordionSummary expandIcon={<ExpandIcon />}>
    <Stack spacing='3px'>
      <Typography variant='pb3' color='primary.gray100'>
        {title}
      </Typography>
      <Typography
        variant='pl5'
        color={!!value ? 'primary.fuchsia75' : 'primary.gray75'}
      >
        {!!value ? value : subtitle}&nbsp;&nbsp;
      </Typography>
    </Stack>
  </StyledAccordionSummary>
);

export const Card = ({ title, subtitle, value, children, ...rest }) => (
  <StyledAccordion {...rest}>
    <Summary title={title} subtitle={subtitle} value={value} />
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </StyledAccordion >
);
