export const multiValueRow = {
  form: `
    <tr ref="row">
      <td class="formio-multi-value-component-column">
        {{element}}
      </td>
      {% if (!disabled) { %}
        <td>
          <button type="button" class="btn formio-button-multi-value-remove-row" ref="removeRow">
            <i class="formio-button-multi-value-remove-row-icon"></i>
          </button>
        </td>
      {% } %}
    </tr>
  `,
};
