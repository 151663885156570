import { styled } from '@mui/system';
import { Box, Button, Divider, Typography } from '@mui/material';

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  flex: '1 0 0',
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.palette.primary.gray10,
  alignSelf: 'stretch'
}));

export const LearnMoreContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '30px',
}));
export const LearnMoreBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '30px',
  background: theme.palette.primary.teal20,
  borderRadius: '10px',
  flexWrap:'wrap'
}));
export const LearnMoreButton = styled(Button)(({ theme }) => ({
  padding: '13px 18px',
  background: theme.palette.primary.white,
  color: theme.palette.primary.darkblue70,
  border: `1px solid ${theme.palette.primary.lightGray100}`,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.28px',
  textTransform:'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: theme.palette.primary.darkblue70,
    color: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.darkblue70}`,
    'svg':{
      color: theme.palette.primary.white,
    }
  },
  '&:active': {
    backgroundColor: theme.palette.primary.darkblue70,
    color: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.darkblue70}`,
    'svg':{
      color: theme.palette.primary.white,
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop:'15px'
  }
}));
export const StyledBox = styled(Box)(() => ({}));

export const StyledHeader = styled(Typography)(({ theme, textAlign }) => ({
  fontStyle: 'normal',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '140%',
  color: theme.palette.primary.gray75,
  textAlign
}));
export const StyledSubHeader = styled(Typography)(({ theme, textAlign }) => ({
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
  textAlign,
  marginTop: '3px'
}));

export const StyledOverviewHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '145%',
}));
