import { Stack, Typography } from '@mui/material';
import { Wrapper } from 'pages/Welcome/Resource/styles';
// import { GetStarted } from 'pages/Welcome/GetStarted';
import { Banner, Content } from 'pages/Welcome/Resource/styles';
import { resourcesContent } from 'pages/Welcome/Resources/constants';
import { Link, Navigate, ScrollRestoration, useParams } from 'react-router-dom';

export function Resource() {
  const { id } = useParams();
  const index = parseInt(id);
  const { pageTitle, resources } = resourcesContent;

  if (Number.isNaN(index) || index < 0 || index > resources.length - 1) {
    return <Navigate to='/welcome/resources' />;
  }

  const { alt, bannerImage, content, title } = resources[index];

  return (
    <>
      <ScrollRestoration />
      <Banner alt={alt} src={bannerImage} />

      <Wrapper>
        <Link
          to='/welcome/resources'
          style={{ marginRight: 'auto', textDecoration: 'none' }}
        >
          <Stack alignItems='center' direction='row' spacing='8px'>
            <svg
              width='24'
              height='25'
              viewBox='0 0 24 25'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M13.9934 4.3523L6.20502 11.6742C6.07405 11.8082 6 11.9934 6 12.1871C6 12.3808 6.07405 12.566 6.20502 12.7L13.9934 20.0186C14.1182 20.1368 14.2826 20.196 14.4491 20.1826C14.6155 20.1691 14.7697 20.0842 14.8765 19.9473L15.8488 18.7141C16.0748 18.4279 16.044 18.0012 15.7795 17.7555L9.87594 12.3128C9.84147 12.2811 9.82164 12.235 9.82164 12.1864C9.82164 12.1379 9.84147 12.0918 9.87594 12.0601L15.7801 6.61538C15.9066 6.49719 15.9843 6.33044 15.9962 6.15143C16.0112 5.97212 15.9576 5.79396 15.8476 5.65749L14.8765 4.42362C14.7699 4.28587 14.6155 4.20022 14.4487 4.18632C14.282 4.17283 14.1175 4.23282 13.9934 4.3523Z'
                fill='#00BC70'
              />
            </svg>

            <Typography color='primary.green' fontWeight={800}>
              {pageTitle}
            </Typography>
          </Stack>
        </Link>

        <Typography color='primary.navy' height='72px' mt='16px' variant='h5'>
          {title}
        </Typography>

        <Content>{content}</Content>
      </Wrapper>

      {/* <GetStarted /> */}
    </>
  );
}
