import { theme } from 'theme';
import { Box, Typography, styled } from '@mui/material';

export const Line = styled('hr')(({ theme }) => ({
  border: '0',
  borderTop: `1px solid ${theme.palette.primary.lightGray100}`,
  margin: '20px 0',
}));

export const ContributorsWrapper = styled(Box)(() => ({
  maxWidth: '600px',
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontFamily: 'Inter',
  color: theme.palette.primary.gray60,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  marginTop: '20px',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '10px',
  },
}));

export const FooterContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: '30px',
  width: '100%',
  gap: '20px',
}));

export const getStyles = () => ({
  checkboxActive: {
    background: theme.palette.primary.gray5,
  },

  circleCheckboxActive: {
    borderColor: theme.palette.primary.darkBlue90,
    '& .MuiTypography-root': {
      color: theme.palette.primary.darkBlue90,
      fontWeight: '600',
    },
  },

  checkboxContainerSquare: {
    borderColor: theme.palette.primary.lightGray100,
    padding: '1px 2px',
    '& .MuiTypography-root': {
      color: theme.palette.primary.darkGray100,
      opacity: '0.8',
      paddingTop: '5px',
      paddingBottom: '5px',
      '&.Mui-disabled': {
        color: `${theme.palette.primary.darkGray100} !important`,
      },
    },
    '& .MuiCheckbox-root': {
      '& span': {
        width: '30px',
        height: '30px',
        border: `1px solid ${theme.palette.primary.gray25} !important`,
        '&.MuiTouchRipple-root': {
          display: 'none',
        },
      },
      '& svg': {
        '& rect': {
          stroke: theme.palette.primary.darkBlue90,
          fill: theme.palette.primary.darkBlue90,
        },
      },
      '&.Mui-disabled': {
        '& svg': {
          '& rect': {
            stroke: theme.palette.primary.darkGray50,
            fill: theme.palette.primary.darkGray50,
          },
        },
      },
    },
  },

  checkboxContainerCircle: {
    borderColor: theme.palette.primary.lightGray100,
    padding: '2px 5px',
    '& .MuiTypography-root': {
      color: theme.palette.primary.darkGray100,
      opacity: '0.8',
    },
    '& .MuiCheckbox-root': {
      '& span': {
        width: '15px!important',
        height: '15px !important',
        borderRadius: '30px!important',
        margin: '7.5px!important',
        '&.MuiTouchRipple-root': {
          display: 'none',
        },
      },
      '& svg': {
        margin: '9px 7px',
      },
    },
  },
});
