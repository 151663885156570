import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const notificationsApiSlice = createApi({
  reducerPath: 'notificationsAPI',
  baseQuery: baseQuery('v3'),
  tagTypes: ['notifications'],
  endpoints: (build) => ({
    createNotification: build.mutation({
      query: ({ payload }) => ({
        url: `/notifications`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['notifications'],
    }),
    sendOtp: build.mutation({
      query: () => ({
        url: `/app/notifications/sms/send-otp`,
        method: 'POST',
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['notifications'],
    }),
    verifyOtp: build.mutation({
      query: ({ payload }) => ({
        url: `/app/notifications/sms/verify-otp`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['notifications'],
    }),
  }),
});

export const {
  useCreateNotificationMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
} = notificationsApiSlice;
