import { styled } from '@mui/material';
import { ReactComponent as DoctorIcon } from 'images/shared/doctor-1.svg';

export const EmptyConsentsWrapper = styled('div')({
  maxWidth: '235',
  width: '100%',
  height: '70vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
});

export const StyledIconContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '60px',
  height: '60px',
  color: theme.palette.primary.darkBlue50,
  marginBottom: '20px',
}));

export const StyledIconBackPlate = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '7px',
  opacity: '0.1',
  backgroundColor: theme.palette.primary.darkBlue100,
}));

export const StyledDoctorIcon = styled(DoctorIcon)(({ theme }) => ({
  color: theme.palette.primary.darkBlue50,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const getStyles = () => {
  return {
    icon: {
      lineHeight: 20,
      marginLeft: 7,
    },
    pastConsentBtn: {
      display: 'flex',
      marginTop: '20px',
      width:'327px',
      gap: '15px',
    },
  };
};
