import { styled } from '@mui/system';
import { Box } from '@mui/material';

import ActivityHeader from 'components/Activities/Header';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column-reverse',
  },
}));

export const StyledActivityHeader = styled(ActivityHeader)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    backgroundColor: 'unset'
  },
}));