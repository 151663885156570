import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../__base";

export const personAttributesApiSlice = createApi({
  reducerPath: "personAttributesApiSlice",
  baseQuery: baseQuery("v3"),
  tagTypes: ["PersonAttributes"],
  endpoints: (build) => ({
    getPersonStudyRoles: build.query({
      query: (personId) => ({
        url: `/people/${personId}/study-roles`,
        method: 'GET'
      }),
      providesTags: ['PersonStudyRoles']
  }),
    getAllPersonAttributes: build.query({
      query: (personId) => ({
        url: `/people/${personId}?include=attributes,study-roles,person-relationships`,
        method: 'GET'
      }),
      providesTags: ['PersonAttributes']
  }),
    getAllPatientAttributes: build.query({
      query: (patientId) => ({
          url: `/people/${patientId}?include=attributes,study-roles,person-relationships`,
          method: 'GET'
      }),
      providesTags: ['PatientAttributes']
    }),
    getRelationships: build.query({
      query: (personId) => ({
          url: `/people/${personId}/person-relationships`,
          method: 'GET',
          headers: {
            'Content-type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json'
          }
      }),
      providesTags: ['PersonRelationships']
     }),
      getSubjectRelationships: build.query({
        query: (personId) => ({
            url: `/people/${personId}/subject-relationships`,
            method: 'GET',
            headers: {
                'Content-type': 'application/vnd.api+json',
                Accept: 'application/vnd.api+json'
            }
        }),
        providesTags: ['PersonSubjectRelationships']
    }),
    editPersonAttributes: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/app/people/${personId}/attributes`,
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        },
      }),
      invalidatesTags: ["PersonAttributes"],
    }),
    editPerson: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/people/${personId}`,
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        },
      }),
    }),
    participantGoal: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/app/people/${personId}/participant-goal`,
        method: "PATCH",
        body: JSON.stringify(payload),
        headers: {
          "Content-type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
        },
      }),
    }),
  }),
});

export const {
  useGetPersonStudyRolesQuery,
  useGetRelationshipsQuery,
  useGetAllPatientAttributesQuery,
  useGetSubjectRelationshipsQuery,
  useGetAllPersonAttributesQuery,
  useEditPersonAttributesMutation,
  useEditPersonMutation,
  useParticipantGoalMutation,
} = personAttributesApiSlice;
