import { format } from 'date-fns';
import { Typography } from '@mui/material';

import {
  CardContainer,
  Container,
  StyledIconButton,
  TextContainer,
} from './Reminders.styles';
import { cardToOrdMap } from './utils';
import { ReactComponent as PenIcon } from 'images/shared/pen-1.svg';

const ReminderCard = ({
  index,
  time,
  pills,
  dosage,
  dosageUnit,
  onClick,
}) => (
  <CardContainer>
    <TextContainer>
      <Typography variant='pb3' color='primary.gray100'>
        {`${cardToOrdMap[index]} reminder at ${format(time, 'h:mm a')}`}
      </Typography>
      <Typography variant='pl5' color='primary.gray50'>
        {`${pills} units(s), ${dosage} ${dosageUnit}`}
      </Typography>
    </TextContainer>
    <StyledIconButton onClick={() => onClick(index)}>
      <PenIcon width='15px' height='15px' />
    </StyledIconButton>
  </CardContainer>
);

export const Reminders = ({ onClick, reminders, dosageUnit }) => (
  <Container>
    <Typography variant='pn3' color='primary.gray75'>
      Reminders
    </Typography>
    {reminders.map((reminder, index) =>
      <ReminderCard
        key={index}
        index={index}
        onClick={onClick}
        dosageUnit={dosageUnit}
        {...reminder}
      />
    )}
  </Container>
);
