import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const personStudyArmAttributeApiSlice = createApi({
  reducerPath: 'personStudyArmAttributeApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['PersonStudyArmAttributes, PersonStudyArmAttribute'],
  endpoints: (build) => ({
    getAllPersonStudyArmAttributes: build.query({
      query: ({ personStudyArmId, ...params }) => ({
        url: `/person-study-arms/${personStudyArmId}/attributes`,
        method: 'GET',
        params,
      }),
      providesTags: ['PersonStudyArmAttributes'],
    }),
    getPersonStudyArmAttribute: build.query({
      query: ({ personStudyArmId, personStudyArmAttributeId }) => ({
        url: `/person-study-arms/${personStudyArmId}/attributes/${personStudyArmAttributeId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'PersonStudyArmAttribute', id: arg },
      ],
    }),
    createPersonStudyArmAttribute: build.mutation({
      query: ({ personStudyArmId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}/attributes`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonStudyArmAttributes'],
    }),
    deletePersonStudyArmAttribute: build.mutation({
      query: ({ personStudyArmId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}/attributes`,
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonStudyArmAttributes'],
    }),
  }),
});

export const {
  useGetAllPersonStudyArmAttributesQuery,
  useGetPersonStudyArmAttributeQuery,
  useCreatePersonStudyArmAttributeMutation,
  useDeletePersonStudyArmAttributeMutation,
} = personStudyArmAttributeApiSlice;
