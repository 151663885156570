import { Box, Grid, Typography, useTheme } from '@mui/material';
import {
  Container,
  Section,
  ContactBox,
  EmailIcon,
  FaqLink,
  PhoneIcon,
  Subtitle,
  Title,
  ContactTitle,
} from './styles';
import { SVSOURCE_CONTACT } from 'utils/constants';

const ContactItem = ({ icon: Icon, title, content }) => (
  <Grid item xs={12} md={6}>
    <ContactBox>
      <Icon />
      <Box>
        <ContactTitle>{title}</ContactTitle>
        <Typography variant='body1' color='primary.navy'>
          {content}
        </Typography>
      </Box>
    </ContactBox>
  </Grid>
);

const ContactUs = () => {
  document.title = 'Single Ventricle SOURCE';
  const theme = useTheme();

  return (
    <>
      <Container>
        <Box>
          <Section>
            <Title variant='h1'>Contact Us</Title>
            <Typography variant='pl1'>
              Thank you for your interest in SOURCE! If you have questions, we
              would love to hear from you. Please see our contact information
              below.
            </Typography>
          </Section>
          <Section>
            <Subtitle variant='h2'>How to Contact the SOURCE Team</Subtitle>
            <Grid container spacing='40px'>
              <ContactItem
                icon={PhoneIcon}
                title='Phone'
                content={SVSOURCE_CONTACT.PHONE}
              />

              <ContactItem
                icon={EmailIcon}
                title='Email Address'
                content={
                  <a
                    href={`mailto: ${SVSOURCE_CONTACT.EMAIL}`}
                    style={{
                      color: theme.palette.primary.navy,
                      textDecoration: 'none',
                    }}
                  >
                    {SVSOURCE_CONTACT.EMAIL}
                  </a>
                }
              />
            </Grid>
          </Section>
          <Section>
            <Subtitle>Questions?</Subtitle>
            <Subtitle>
              Please review our <FaqLink to='/welcome/faq'>FAQ here</FaqLink>
            </Subtitle>
          </Section>
        </Box>
      </Container>
    </>
  );
};
export default ContactUs;
