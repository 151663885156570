import { Box, InputLabel, styled, Typography } from '@mui/material';
import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';

export const CommunicationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 'auto',
  },
}));

export const AcmeLogo = styled(Logo)({
  width: '102px',
  height: '70px',
});

export const Container = styled('div')(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '50px 40px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const TitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: '5px',
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: 20,
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontSize: '14px',
}));

export const FieldContainer = styled(Box)({
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '8px',
  color: theme.palette.primary.gray75,
}));

const styles = {
  checkboxContainer: {
    backgroundColor: 'transparent',
    border: '0px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
      '& .MuiCheckbox-root': {
        padding: '0px',
        '& span': {
          width: '20px',
          height: '20px',
        },
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
    },
  },
};

export const getStyles = () => {
  return styles;
};
