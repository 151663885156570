import { Stack, Typography } from '@mui/material';

export const Header = ({ availablePoints }) => (
  <Stack spacing='10px'>
    <Typography variant='h2' color='primary.gray100' align='center'>
      {`${availablePoints} points available`}
    </Typography>
    <Typography variant='pl3' color='secondary.gray4' align='center'>
      Use the slider to choose the amount of points you want converted to USD
    </Typography>
  </Stack>
);
