import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  BookAppointmentWrapper,
  BookAppointmentDetails,
  BookAppointmentDetailsTitle,
  BookAppointmentDetailsDescription,
  BookAppointmentDatePicker,
  BookAppointmentTimePicker,
  BookAppointmentTimeSlots,
  BookAppointmentTimeSectionTitle,
  NoSlotsWrapper,
} from './styles';
import { DatepickerInput, RadioGroupInput } from 'components/Input';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { ErrorWrapper } from 'components/ValidationError';
import { Typography } from '@mui/material';
import { ReactComponent as InfoIcon } from 'images/activity/types/Info.svg';

export const BookAppointment = (props) => {
  const {
    scheduleCallDescription,
    dateStepMetaData,
    timeStepMetaData,
    showAvailableSlots,
    callCentrePhone,
    slotsAreAvailable = false,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  let noSlots = false;

  if (slotsAreAvailable) {
    noSlots =
      showAvailableSlots &&
      timeStepMetaData?.content?.morning?.options?.length === 0 &&
      timeStepMetaData?.content?.afternoon?.options?.length === 0;
  } else {
    noSlots = true;
  }

  return (
    <BookAppointmentWrapper>
      <BookAppointmentDetails>
        <BookAppointmentDetailsTitle variant='h2'>
          {scheduleCallDescription?.title}
        </BookAppointmentDetailsTitle>
        <BookAppointmentDetailsDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: scheduleCallDescription?.description,
            }}
          />
        </BookAppointmentDetailsDescription>
      </BookAppointmentDetails>
      <BookAppointmentDatePicker>
        <ErrorWrapper>
          <Controller
            name={dateStepMetaData.content.name}
            control={control}
            rules={{ required: true }}
            haserrors={errors[dateStepMetaData.content.name]}
            render={({ field }) => (
              <DatepickerInput {...dateStepMetaData.content} {...field} />
            )}
          />
        </ErrorWrapper>
      </BookAppointmentDatePicker>
      {noSlots && (
        <NoSlotsWrapper>
          <InfoIcon />
          <div>
            <Typography variant='pn3' color='secondary.gray4'>
              No appointments available for your visit window
            </Typography>
            <br />
            <Typography variant='pl5' color='primary.gray90'>
              Please contact the Call Center at {callCentrePhone} to schedule
              your appointment.
            </Typography>
          </div>
        </NoSlotsWrapper>
      )}
      {showAvailableSlots && (
        <BookAppointmentTimePicker>
          <ErrorWrapper>
            <Controller
              name={timeStepMetaData.content.name}
              control={control}
              rules={{ required: true }}
              haserrors={errors[timeStepMetaData.content.name]}
              render={({ field }) => (
                <RadioGroupInput {...field} {...timeStepMetaData.content}>
                  {timeStepMetaData?.content?.morning?.options?.length > 0 && (
                    <div>
                      <BookAppointmentTimeSectionTitle variant='h6'>
                        Morning
                      </BookAppointmentTimeSectionTitle>
                      <BookAppointmentTimeSlots>
                        {timeStepMetaData?.content?.morning?.options.map(
                          (option) => (
                            <RadioInput
                              key={option.id}
                              value={option.id}
                              label={option.value}
                            />
                          )
                        )}
                      </BookAppointmentTimeSlots>
                    </div>
                  )}
                  {timeStepMetaData?.content?.afternoon?.options?.length >
                    0 && (
                    <div>
                      <BookAppointmentTimeSectionTitle variant='h6'>
                        Afternoon
                      </BookAppointmentTimeSectionTitle>
                      <BookAppointmentTimeSlots>
                        {timeStepMetaData?.content?.afternoon?.options.map(
                          (option) => (
                            <RadioInput
                              key={option.id}
                              value={option.id}
                              label={option.value}
                            />
                          )
                        )}
                      </BookAppointmentTimeSlots>
                    </div>
                  )}
                </RadioGroupInput>
              )}
            />
          </ErrorWrapper>
        </BookAppointmentTimePicker>
      )}
    </BookAppointmentWrapper>
  );
};
