import { Box } from '@mui/system';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import { ReactComponent as EnvelopeIcon } from 'images/shared/envelope-solid.svg';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-3.svg';
import { findStudyAttribute } from 'utils/study';
import {
  ButtonText,
  Close,
  PopupButton,
  PopupButtonsWrap,
  PopupContent,
  PopupText,
  PopupTitle,
  PopupWrapper,
} from './style';
import { styled } from '@mui/material';

const HelpPopupContent = ({ toggleShouldShowPopup }) => {
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const callCentreEmail = findStudyAttribute(
    studyConfiguration || [],
    'call_centre_email'
  );

  const callCenterPhone = findStudyAttribute(
    studyConfiguration || [],
    'call_centre_phone'
  );

  return (
    <PopupWrapper>
      <PopupContent>
        <Close>
          <XIcon
            fill='white'
            stroke='white'
            width='10px'
            height='16px'
            onClick={toggleShouldShowPopup}
          />
        </Close>
        <Box>
          <PopupTitle>Contact Us</PopupTitle>
          <PopupText>Have questions? We would love to help!</PopupText>
          <PopupText>Contact the SOURCE Team via phone or email</PopupText>
        </Box>
        <PopupButtonsWrap>
          {callCentreEmail && (
            <PopupButton href={'mailto:' + callCentreEmail}>
              <EnvelopeIcon width='19px' height='19px' />
              <ButtonText>{callCentreEmail}</ButtonText>
            </PopupButton>
          )}

          {callCenterPhone && (
            // SING-641
            // <PopupButton>
            <PopupButton href={'tel:' + callCenterPhone}>
              <StyledPhoneIcon />
              <ButtonText>{callCenterPhone}</ButtonText>
            </PopupButton>
          )}
        </PopupButtonsWrap>
      </PopupContent>
    </PopupWrapper>
  );
};

export default HelpPopupContent;

export const StyledPhoneIcon = styled(PhoneIcon)(({ theme, size }) => ({
  path: {
    fill: theme.palette.primary.blue,
  },
}));
