import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { BaseLayout } from 'components/BaseLayout';
import { Header } from 'components/Header';

export const StyledHeader = styled(Header)({
  zIndex: 0,
  position: 'unset',
});

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',

  '& > .BaseLayout-scrollable': {
    backgroundColor: theme.palette.primary.gray2,
    padding: '10px 25px',
  },
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray2,
    '& > .BaseLayout-header': {
      position: 'initial',
    },
  },
}));

export const CardList = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px'
});
