import { styled } from '@mui/system';
import Popper from '@mui/material/Popper';
import { Box } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { ReactComponent as Check } from '../../assets/svg/check.svg';

export const CheckIcon = styled(Check)({
  marginTop: '2px',
  marginRight: '10px',
  width: '16px',
  height: '16px',
});

export const StyledPopper = styled(Popper)(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    marginTop: '8px',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.gray15}`,
    borderRadius: '6px',
  },

  [`& .${autocompleteClasses.listbox}`]: {
    '& .MuiAutocomplete-option': {
      padding: '13px 20px',

      '& :hover': {
        color: `${theme.palette.primary.gray100}`,
      },
    },
  },
}));

export const OptionItemWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
});
