import { Grid } from '@mui/material';
import { GridItemGroup } from './grid-item-group';
import { useCards } from './useCards';

export const ACMESteady = () => {
  const cards = useCards();
  return (
    <Grid container>
      {cards.map((card, index) => (
        <GridItemGroup
          key={index}
          image={card.image}
          title={card.title}
          color={card.color}
          backgroundColor={card.backgroundColor}
          list={card.list}
          imageFirst={card.imageFirst}
        />
      ))}
    </Grid>
  );
};
