import { Stack } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { useLogout } from 'utils/useLogout';
import { ErrorCode, ErrorMessage } from '../ErrorTypography';

export const ErrorPage = ({
  errorCode,
  errorMessage,
  hideBackToHomeBtn = false,
}) => {
  const logout = useLogout();
  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      spacing='20px'
      height='100vh'
      sx={{ bgcolor: (theme) => theme.palette.primary.gray20 }}
    >
      <Stack maxWidth='295px' justifyContent='center' alignItems='center'>
        <ErrorCode>{errorCode}</ErrorCode>
        <ErrorMessage>{errorMessage}</ErrorMessage>
        {!hideBackToHomeBtn && (
          <Button
            sx={{
              width: '100%',
              maxHeight: '44px',
              fontSize: '14px',
              marginTop: '30px',
            }}
            onClick={logout}
          >
            Back to home
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
