import { INEGIBILITY_REASONS } from 'pages/Onboarding/BasicInformation/constants';

export const createPersonStudyArmPayload = (personStudyArmId, studyArmId) => {
  return {
    data: {
      type: 'person-study-arms',
      id: personStudyArmId + '',
      attributes: {
        study_arm_status_id: studyArmId,
      },
    },
  };
};

export const getIneligibilityReasons = (
  dateOfBirth,
  state,
  country,
  bestDescribeOptions
) => {
  const reasons = [];

  if (!isAboveStateAgeMajority(dateOfBirth, state)) {
    reasons.push(INEGIBILITY_REASONS.UNDER_AGE);
  }

  if (!isEligibleBestDescribeOptions(bestDescribeOptions)) {
    reasons.push(INEGIBILITY_REASONS.BEST_DESCRIBE_OPTIONS);
  }

  if (!isEligibleCountry(country)) {
    reasons.push(INEGIBILITY_REASONS.ELIGIBLE_COUNTRY);
  }

  return reasons;
};

export function isAboveStateAgeMajority(dateOfBirth, state) {
  if (!dateOfBirth) {
    return false;
  }

  const dob = new Date(dateOfBirth);
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth();
  const todayDay = today.getDate();

  const dobYear = dob.getFullYear();
  const dobMonth = dob.getMonth();
  const dobDay = dob.getDate();

  let age = todayYear - dobYear;

  if (todayMonth < dobMonth || (todayMonth === dobMonth && todayDay < dobDay)) {
    age--;
  }

  const PROVIDED_STATES = {
    'US-AL': 19,
    'US-NE': 19,
    'US-MS': 21,
  };

  const minimumAge = PROVIDED_STATES[state] || 18;
  return age >= minimumAge;
}

export function isEligibleCountry(country) {
  return country === 'US';
}

export function isEligibleBestDescribeOptions(selectedOptions) {
  return !Array.isArray(selectedOptions) ||
    selectedOptions.length === 0 ||
    selectedOptions.includes('None of these apply to me') ||
    (selectedOptions.length === 1 &&
      selectedOptions.includes(
        'I have lost a family member with a single ventricle heart defect'
      ))
    ? false
    : true;
}
