import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import { ReactComponent as UserPlus } from 'images/profile/user-plus.svg';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '820px',
  margin: '40px auto',
  backgroundColor: theme.palette.primary.gray20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '24px',
  },
}));

export const MobileContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.gray5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  flexDirection: 'column',
  gap: '20px',
  alignSelf: 'stretch',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    marginTop:'60px',
  },
}));

export const UserPlusIcon = styled(UserPlus)({
  width: '33px',
  height: '27px',
  fontSize: '23px',
});

export const EnvelopeIcon = styled(EmailIcon)({
  width: '23px',
  height: '23px',
  color: '#444444',
  fontSize: '23px',
});
