import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  organizations: [{ id: '001', name: 'IQVIA', category: 'ds', order: 4 }],
};

const updateOrganizationAction = (organizationState, action) => {
  let orgForUpdate = organizationState.organizations.find(
    (org) => org.id === action.payload.id
  );

  if (orgForUpdate) {
    orgForUpdate.name = action.payload.name;
  }
};

const addOrganizationAction = (organizationState, action) => {
  organizationState.organizations.push(action.payload);
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    addOrganization: addOrganizationAction,
    updateOrganization: updateOrganizationAction,
  },
});

export const { addOrganization, updateOrganization } =
  organizationSlice.actions;

export const selectAllOrganizations = (state) =>
  state.organizationState.organizations;

export const selectOrganizationById = (state, id) =>
  selectAllOrganizations(state).find((organization) => organization.id === id);

export default organizationSlice.reducer;
