import { Box, styled } from '@mui/system';

const FixedBottomRight = styled(Box)(({ theme, pathName }) => ({
  position: 'fixed',
  right: '30px',
  zIndex: '100',
  bottom: '20px',
  [theme.breakpoints.down('sm')]: {
    bottom: undefined,
    top: '20px',
    right: '10px',
  },
}));

export const HelpIconWrapper = styled(FixedBottomRight)(({ theme }) => ({
  height: '40px',
  cursor: 'pointer',
  borderRadius: '50px',
  padding: "10px 14px 10px 14px",
  backgroundColor: theme.palette.primary.helpBlue,
  borderColor: theme.palette.primary.helpBorderBlue,
  borderWidth: '1px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    height: '24px',
    borderRadius: '0px',
    padding: "0px 9px 0px 0px",
    left: "20px",
    width: "fit-content"
  },
}));

export const HelpTitle = styled(Box)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  color: theme.palette.primary.white,
  fontFamily: 'Inter',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginBottom: '10px',
    lineHeight: '20px',
    color: theme.palette.primary.helpBlue,
  },

}));
