import { useNavigate } from 'react-router-dom';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useTheme } from '@emotion/react';
import { ArrowForwardIos } from '@mui/icons-material';
import ExternalResearchOpportunityList from 'components/ResearchOpportunity/List';
import { useGetFilteredResearchOpportunitiesQuery } from 'ihp-bloom-redux/features/researchOpportunities/researchOpportunitiesApiSlice';
import { Loader } from 'components/Loader';
import {
  Container,
  getStyles,
  Header,
  StyledLink,
  Title,
  ViewAllButton,
} from './style';
import { PAGE_LIMIT } from '../../../utils/constants';
import ResearchOpportunityStatus from '../../../constants/researchOpportunityStatus';

function ExternalResearchOpportunity() {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { activeAccountProfile } = useSelector((state) => state.user);

  const person_study_arm_id =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const subject_primary_person_study_arm_id =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;

  const {
    isLoading: isLoadingPersonResearchOpportunities,
    items: personResearchOpportunities,
    refetch: refetchPersonResearchOpportunities,
  } = useGetFilteredResearchOpportunitiesQuery(
    {
      sort: '-createdAt',
      'filter[person-study-arm]': person_study_arm_id,
      'filter[suggested-to-me]': true,
      'page[limit]': PAGE_LIMIT,
      include: 'study_sponsor,person_study_arm_research_opportunities',
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({
        data,
        isLoading,
        isUninitialized,
        isFetching,
        error,
      }) => {
        // Filter the objects where "status": "unassigned".
        // If there are two or more items with "unassigned" status, select only the first two.
        // If there are less than two, select the remaining items from the beginning of the list until there are two items in total.

        let researchOpportunitiesUnAssigned = [];
        let otherResearchOpportunities = [];
        if (data?.data?.length > 0) {
          for (let i = 0; i < data?.data?.length; i++) {
            if (
              data?.data[i]?.attributes?.status ===
                ResearchOpportunityStatus.UN_ASSIGNED?.key &&
              researchOpportunitiesUnAssigned.length < 2
            ) {
              researchOpportunitiesUnAssigned.push(data?.data[i]);
            } else {
              if (
                otherResearchOpportunities.length < 2 &&
                researchOpportunitiesUnAssigned.length < 2
              ) {
                otherResearchOpportunities.push(data?.data[i]);
              }
            }

            if (researchOpportunitiesUnAssigned.length === 2) {
              break;
            }
          }
        }

        if (researchOpportunitiesUnAssigned.length < 2) {
          researchOpportunitiesUnAssigned = [
            ...researchOpportunitiesUnAssigned,
            ...otherResearchOpportunities.slice(
              0,
              2 - researchOpportunitiesUnAssigned.length
            ),
          ];
        }
        return {
          data: data,
          totalPages: data?.meta?.page?.lastPage,
          perPage: data?.meta?.page?.perPage,
          error: error,
          isLoading: isLoading || isUninitialized || isFetching,
          items: researchOpportunitiesUnAssigned?.map((item) => {
            return {
              id: item?.id,
              title: item?.attributes['title'],
              subtitle: item?.attributes['summary'],
              description: item?.attributes['description'],
              status: item?.attributes['status'],
              study_sponsor_id: item?.attributes['study_sponsor_id'],
              research_opportunity_id:
                item?.attributes['research_opportunity_id'],
              ...(() => {
                let includes = {
                  study_sponsors_name: '',
                  person_study_arm_research_opportunity: null,
                };
                for (let i = 0; i < data?.included?.length; i++) {
                  const element = data?.included[i];
                  if (+element?.id === item?.attributes['study_sponsor_id']) {
                    includes['study_sponsors_name'] = element?.attributes?.name;
                  }
                  if (
                    element?.attributes['research_opportunity_id'] === +item?.id
                  ) {
                    includes['person_study_arm_research_opportunity'] =
                      +element?.id;
                  }
                }
                return includes;
              })(),
            };
          }),
        };
      },
    }
  );

  const {
    isLoading: isLoadingSubjectResearchOpportunities,
    items: subjectResearchOpportunities = [],
    refetch: refetchSubjectResearchOpportunities,
  } = useGetFilteredResearchOpportunitiesQuery(
    {
      sort: '-createdAt',
      'filter[person-study-arm]': subject_primary_person_study_arm_id,
      'filter[suggested-to-me]': true,
      'page[limit]': PAGE_LIMIT,
      include: 'study_sponsor,person_study_arm_research_opportunities',
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !subject_primary_person_study_arm_id,
      selectFromResult: ({
        data,
        isLoading,
        isUninitialized,
        isFetching,
        error,
      }) => {
        // Filter the objects where "status": "unassigned".
        // If there are two or more items with "unassigned" status, select only the first two.
        // If there are less than two, select the remaining items from the beginning of the list until there are two items in total.

        let researchOpportunitiesUnAssigned = [];
        let otherResearchOpportunities = [];
        if (data?.data?.length > 0) {
          for (let i = 0; i < data?.data?.length; i++) {
            if (
              data?.data[i]?.attributes?.status ===
                ResearchOpportunityStatus.UN_ASSIGNED?.key &&
              researchOpportunitiesUnAssigned.length < 2
            ) {
              researchOpportunitiesUnAssigned.push(data?.data[i]);
            } else {
              if (
                otherResearchOpportunities.length < 2 &&
                researchOpportunitiesUnAssigned.length < 2
              ) {
                otherResearchOpportunities.push(data?.data[i]);
              }
            }

            if (researchOpportunitiesUnAssigned.length === 2) {
              break;
            }
          }
        }

        if (researchOpportunitiesUnAssigned.length < 2) {
          researchOpportunitiesUnAssigned = [
            ...researchOpportunitiesUnAssigned,
            ...otherResearchOpportunities.slice(
              0,
              2 - researchOpportunitiesUnAssigned.length
            ),
          ];
        }
        return {
          data: data,
          totalPages: data?.meta?.page?.lastPage,
          perPage: data?.meta?.page?.perPage,
          error: error,
          isLoading: isLoading || isUninitialized || isFetching,
          items: researchOpportunitiesUnAssigned?.map((item) => {
            return {
              id: item?.id,
              title: item?.attributes['title'],
              subtitle: item?.attributes['summary'],
              description: item?.attributes['description'],
              status: item?.attributes['status'],
              study_sponsor_id: item?.attributes['study_sponsor_id'],
              research_opportunity_id:
                item?.attributes['research_opportunity_id'],
              ...(() => {
                let includes = {
                  study_sponsors_name: '',
                  person_study_arm_research_opportunity: null,
                };
                for (let i = 0; i < data?.included?.length; i++) {
                  const element = data?.included[i];
                  if (+element?.id === item?.attributes['study_sponsor_id']) {
                    includes['study_sponsors_name'] = element?.attributes?.name;
                  }
                  if (
                    element?.attributes['research_opportunity_id'] === +item?.id
                  ) {
                    includes['person_study_arm_research_opportunity'] =
                      +element?.id;
                  }
                }
                return includes;
              })(),
            };
          }),
        };
      },
    }
  );

  const researchOpportunities = [...personResearchOpportunities];

  if (Array.isArray(subjectResearchOpportunities)) {
    subjectResearchOpportunities.forEach((subject) => {
      if (!researchOpportunities.find((s) => s.id === subject.id)) {
        researchOpportunities.push(subject);
      }
    });
  }

  const isLoading =
    isLoadingPersonResearchOpportunities ||
    isLoadingSubjectResearchOpportunities;

  if (researchOpportunities?.length === 0) {
    return null;
  }

  const refetch = () => {
    refetchPersonResearchOpportunities();
    refetchSubjectResearchOpportunities();
  };

  return (
    <>
      <Container>
        <Header>
          <Title>External Research</Title>
          <StyledLink
            onClick={() => {
              navigate(`/research-opportunities`);
            }}
          >
            View all <ArrowForwardIos style={styles.icon} />
          </StyledLink>
        </Header>
        {isLoading ? (
          <Loader />
        ) : (
          researchOpportunities &&
          researchOpportunities.length > 0 && (
            <ExternalResearchOpportunityList
              opportunities={researchOpportunities}
              refetch={refetch}
            />
          )
        )}
        <ViewAllButton
          endIcon={<ArrowForwardIos style={styles.icon} />}
          onClick={() => {
            navigate(`/research-opportunities`);
          }}
        >
          View All
        </ViewAllButton>
      </Container>
    </>
  );
}

export default ExternalResearchOpportunity;
