import { useEffect, useState } from 'react';
import { getDiagnosisTypes } from '../../../../constants/diagnosis';
import {
  useGetAllPersonStudyArmAttributesQuery
} from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

const useConditions = (studyArmsId) => {
  const [options, setOptions] = useState(getDiagnosisTypes());
  const [isCancelled, setIsCancelled] = useState(false);
  const {
    data,
    isFetching,
    isLoading,
    refetch,
  } = useGetAllPersonStudyArmAttributesQuery(
    {
      personStudyArmId: studyArmsId,
      'page[limit]': 10000,
    }
  );

  useEffect(() => {
    const attributes = [];
    if(data?.data?.length > 0) {
      data?.data?.forEach((item) => {
        const key = item.attributes.attribute;
        const value = item.attributes.value;
        attributes[key] = value === 'true';
      });

      const updatedOptions = [...options];
      updatedOptions.forEach((option) => {
        const key = option?.value;
        if (attributes[key]) {
          option.checked = attributes[key];
        } else {
          option.checked = false;
        }
      });
      setOptions([...updatedOptions]);
      setIsCancelled(false);
    }

  }, [data, isCancelled]);

  return { options, setOptions, data, isFetching, isLoading, refetch, isCancelled, setIsCancelled };
};

export default useConditions;
