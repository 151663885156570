import { useOutlet } from 'react-router-dom';
import { Modal, useMediaQuery, useTheme } from '@mui/material';

import { useSearchParamDate } from 'pages/NotificationCenter/MedicationTracker/hooks';
import MedicationTrackerDetailedDesktop from './index.desktop';
import MedicationTrackerDetailedMobile from './index.mobile';

const MedicationTrackerDetailed = () => {
  const [date, setDate] = useSearchParamDate('yyyy-MM-dd');

  const outlet = useOutlet();
  if (useMediaQuery(useTheme().breakpoints.up('sm')))
    return (
      <>
        <MedicationTrackerDetailedDesktop date={date} onDateChange={setDate} />
        <Modal open={!!outlet.props.children}>{outlet}</Modal>
      </>
    );
  if (!!outlet.props.children) return outlet;
  return <MedicationTrackerDetailedMobile date={date} onDateChange={setDate} />;
};

export default MedicationTrackerDetailed;
