import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetResourcesQuery } from 'ihp-bloom-redux/features/resource/resourceApiSlice';
import { useCreatePersonResourceMutation } from 'ihp-bloom-redux/features/personResource/personResourceApiSlice';

import Chip from '../../../ihp-components/components/v2/Chip';

import { WarningModal } from '../EducationalMaterials/Modals/warningModal';
import { statusMapping } from '../../components/EducationalMaterial/List/statusMapping';
import { Loader } from 'components/Loader';
import { FeedbackModal } from '../EducationalMaterials/Modals/feedbackModal';

import {
  Card,
  CardContentDescription,
  CardContentTitle,
  CardContentWapper,
  CardDescription,
  CardDescriptionContent,
  CardDescriptionHide,
  CardFooter,
  CardTitle,
  CardTitleWrapper,
  ChipWrapper,
  Container,
  ContainerWrapper,
  getStyles,
  Header,
  PageTitle,
  ReadMoreButton, SubHeader
} from './styles';
import { ReactComponent as BackArrowIcon } from 'images/shared/chevron-left-2.svg';
import { ReactComponent as OpenNewIcon } from 'images/shared/open-new.svg';

function EducationalMaterialDetailsPage() {
  const theme = useTheme();
  const styles = getStyles(theme);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [targetAddress, setTargetAddress] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentResourceId, setCurrentResourceId] = useState(null);
  const [status, setStatus] = useState('');
  const [responseData, setResponseData] = useState({});
  const [newlyCreated, setNewlyCreated] = useState([]);
  console.log('responseData: ', responseData);
  const educationalMaterialId = parseInt(useParams().id);
  const { person } = useSelector((state) => state.user);
  const { showWarningModal } = useSelector((state) => state.configuration);
  const navigate = useNavigate();
  const statusMap = statusMapping(theme);
  const readObject = statusMap['read'];
  const [createPersonResource] = useCreatePersonResourceMutation();

  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const requestBody = {
    personId: person.id,
    payload: {
      data: {
        type: 'person-resources',
        attributes: {
          resource_id: educationalMaterialId,
          status: 'read',
        },
      },
    },
  };

  const feedback = statusMap['feedback'];
  const customStyle = {
    cursor: 'pointer',
    ...(feedback.backgroundColor && {
      backgroundColor: feedback.backgroundColor,
    }),
    ...(feedback.borderColor && { borderColor: feedback.borderColor }),
    ...(feedback.color && { borderColor: feedback.color }),
  };
  const { isLoading, item, data, refetch } = useGetResourcesQuery(
    {
      resourceId: educationalMaterialId,
      include: 'person-resource,education-categories',
    },
    {
      selectFromResult: ({ data, ...rest }) => {
        return {
          item: {
            id: data?.data?.id,
            title: data?.data?.attributes['title'],
            summary: data?.data?.attributes['summary'],
            description: data?.data?.attributes['description'],
            link: data?.data?.attributes['link'],
            image_url: data?.data?.attributes['image_url'],
            status: data?.included
              ? data?.included.find(
                (includedItem) =>
                  includedItem.attributes.resource_id ===
                  parseInt(data?.data?.id)
              )?.attributes?.status
              : null,
            relationships: data?.data?.relationships,
          },
          data: data?.data,
          ...rest,
        };
      },
    }
  );

  const markReadStatus = async () => {
    try {
      const payload = {
        data: {
          type: 'person-resources',
          attributes: {
            resource_id: parseInt(educationalMaterialId),
            status: 'read',
          },
        },
      };
      const response = await createPersonResource({
        participantId: person?.id,
        payload,
      });
      // Refetch data to get status update
      refetch();
      const statusResponse = response?.data?.data?.attributes?.status;
      setStatus(statusResponse);
      setResponseData({
        ...responseData,
        statusResponse,
      });
      updateStatus(undefined, statusResponse);
      setNewlyCreated([
        ...newlyCreated,
        {
          resourceId: parseInt(currentResourceId),
          personResourceId: parseInt(response?.data?.data?.id),
        },
      ]);
    } catch (error) {
      console.log('Something went wrong');
    }
  };

  const handleOnReadMore = async () => {
    if (!responseData?.status) {
      markReadStatus();
    }
    if (showWarningModal) {
      setTargetAddress(item.link);
      setIsOpenWarningModal(true);
    } else {
      window.open(item?.link, '_blank');
    }
    // if (item.status == null) {
    //   setIsOpenWarningModal(true);
    //   createPersonResource(requestBody);
    // }
  };

  useEffect(() => {
    setResponseData(item);
  }, [data, status]);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const goBack = () => {
    navigate('/education-materials');
  };

  const updateStatus = (id, status) => {
    setResponseData({
      ...responseData,
      status,
    });
  };

  const openFeedBackModal = () => {
    setIsOpenModal(true);
    setCurrentResourceId(educationalMaterialId);
    setStatus(responseData?.status);
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <WarningModal
        isOpen={isOpenWarningModal}
        setIsOpenModal={setIsOpenWarningModal}
        targetAddress={targetAddress}
        newTab={true}
      />
      <FeedbackModal
        isOpen={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        currentResourceId={currentResourceId}
        status={status}
        personResources={[responseData]}
        updateResource={updateStatus}
      />
      <Header>
        <SubHeader onClick={goBack}>
          <BackArrowIcon style={styles.backArrowIcon} />
          <PageTitle>Information for You</PageTitle>
        </SubHeader>
      </Header>
      {responseData.id && (
        <ContainerWrapper>
          <Container>
            <Card>
              <CardContentWapper>
                {responseData?.image_url && (
                  <Box
                    component='img'
                    sx={
                      isMobile
                        ? styles.imageContainerMobile
                        : isTablet
                          ? styles.imageContainerTablet
                          : styles.imageContainer
                    }
                    src={responseData?.image_url}
                  />
                )}
              </CardContentWapper>
              <CardContentWapper>
                <CardTitle>{responseData?.title}</CardTitle>
              </CardContentWapper>
              <CardContentWapper>
                <CardContentTitle>Brief Summary:</CardContentTitle>
                <CardContentDescription>
                  {responseData.summary}
                </CardContentDescription>
              </CardContentWapper>

              <CardContentWapper>
                <CardContentTitle>Description:</CardContentTitle>
                <CardContentDescription>
                  {responseData.description}
                </CardContentDescription>
              </CardContentWapper>
              {/* <CardTitleWrapper>
                <CardDescription>
                  <CardDescriptionContent
                    sx={!isExpanded ? styles.truncate : ''}
                  >
                    Single Ventricle Source (SVS), Progressive supranuclear
                    palsy (PSP) or Richardson syndrome, Aphasia / Primary Progressive
                    Aphasia (PPA), Corticobasal degeneration (CBD) or
                    corticobasal syndrome (CBS), General information about
                    genetics and SV Source
                  </CardDescriptionContent>
                  <CardDescriptionHide onClick={toggleText}>
                    {isExpanded ? 'Hide' : 'See All'}
                  </CardDescriptionHide>
                </CardDescription>
              </CardTitleWrapper> */}

              <CardFooter>
                <ChipWrapper>
                  {responseData.status !== null ? (
                    <Grid
                      container
                      direction={'row'}
                      alignSelf={'stretch'}
                      alignItems={'flex-start'}
                      gap={'5px'}
                    >
                      {responseData.status === 'read' && (
                        <Chip
                          icon={readObject.icon}
                          variant={readObject.variant}
                          type={readObject.type}
                          sx={{
                            ...customStyle,
                            backgroundColor: readObject.backgroundColor,
                          }}
                          onClick={openFeedBackModal}
                        >
                          {readObject.text}
                        </Chip>
                      )}

                      {['not_helpful', 'helpful'].includes(
                        responseData.status
                      ) ? (
                        <>
                          <Chip
                            icon={readObject.icon}
                            variant={readObject.variant}
                            type={readObject.type}
                            sx={{
                              ...customStyle,
                              backgroundColor: readObject.backgroundColor,
                            }}
                            onClick={openFeedBackModal}
                          >
                            {readObject.text}
                          </Chip>
                          <Chip
                            icon={statusMap[responseData.status].icon}
                            variant={statusMap[responseData.status].variant}
                            type={statusMap[responseData.status].type}
                            sx={{
                              ...customStyle,
                              borderColor:
                                statusMap[responseData.status].borderColor,
                              color: statusMap[responseData.status].color,
                            }}
                            onClick={openFeedBackModal}
                          >
                            {statusMap[responseData.status].text}
                          </Chip>
                        </>
                      ) : (
                        responseData?.status !== 'read' && (
                          <Chip
                            icon={feedback.icon}
                            variant={feedback.variant}
                            type={feedback.type}
                            sx={{
                              ...customStyle,
                              borderColor: feedback.borderColor,
                              color: feedback.color,
                            }}
                            onClick={openFeedBackModal}
                          >
                            {feedback.text}
                          </Chip>
                        )
                      )}
                    </Grid>
                  ) : (
                    <Chip
                      icon={feedback.icon}
                      variant={feedback.variant}
                      type={feedback.type}
                      sx={{
                        ...customStyle,
                        borderColor: feedback.borderColor,
                        color: feedback.color,
                      }}
                      onClick={openFeedBackModal}
                    >
                      {feedback.text}
                    </Chip>
                  )}
                </ChipWrapper>
                {responseData.link && (
                  <ReadMoreButton
                    variant='link'
                    onClick={handleOnReadMore}
                    endIcon={<OpenNewIcon style={styles.readMoreIcon} />}
                  >
                    Read more
                  </ReadMoreButton>
                )}
              </CardFooter>
            </Card>
          </Container>
        </ContainerWrapper>
      )}
    </>
  );
}

export default EducationalMaterialDetailsPage;
