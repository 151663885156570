import AccontInfo from '../AccountInfo';
import ProfileOptions from '../ProfileOptions';

import { HeaderContainer } from './style';

const NavHeader = () => {
  return (
    <HeaderContainer>
      <AccontInfo />
      <ProfileOptions />
    </HeaderContainer>
  );
};

export default NavHeader;
