import { ArrowForwardIos, Newspaper } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

import { useNavigate } from 'react-router-dom';

import Chip from '../../../ihp-components/components/v2/Chip';

import { statusMapping } from '../EducationalMaterial/List/statusMapping';
import { Card } from '../Cards';

import {
  getStyles,
  LearnMoreButton,
} from '../../pages/Home/EducationalMaterials/style';

function EducationalMaterialsList({ resources }) {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const statusMap = statusMapping(theme);
  const readObject = statusMap['read'];
  const feedback = statusMap['feedback'];
  const customStyle = {
    cursor: 'pointer',
    ...(feedback.backgroundColor && {
      backgroundColor: feedback.backgroundColor,
    }),
    ...(feedback.borderColor && { borderColor: feedback.borderColor }),
    ...(feedback.color && { borderColor: feedback.color }),
  };

  const learnMoreButton = (resource) => (
    <LearnMoreButton
      onClick={() => navigate(`/education-materials/${resource.id}`)}
      disableRipple
      endIcon={<ArrowForwardIos style={styles.icon} />}
    >
      Learn more
    </LearnMoreButton>
  );

  const tags = (item) => {
    return item.status !== null ? (
      <Grid
        container
        direction={'row'}
        alignSelf={'stretch'}
        alignItems={'flex-start'}
        gap={'5px'}
      >
        {item.status === 'read' && (
          <Chip
            icon={readObject.icon}
            variant={readObject.variant}
            type={readObject.type}
            sx={{ ...customStyle, backgroundColor: readObject.backgroundColor }}
          >
            {readObject.text}
          </Chip>
        )}

        {['not_helpful', 'helpful'].includes(item.status) ? (
          <>
            <Chip
              icon={readObject.icon}
              variant={readObject.variant}
              type={readObject.type}
              sx={{
                ...customStyle,
                backgroundColor: readObject.backgroundColor,
              }}
            >
              {readObject.text}
            </Chip>
            <Chip
              icon={statusMap[item.status].icon}
              variant={statusMap[item.status].variant}
              type={statusMap[item.status].type}
              sx={{
                borderColor: statusMap[item.status].borderColor,
                color: statusMap[item.status].color,
              }}
            >
              {statusMap[item.status].text}
            </Chip>
          </>
        ) : (
          <Chip
            icon={feedback.icon}
            variant={feedback.variant}
            type={feedback.type}
            sx={{ borderColor: feedback.borderColor, color: feedback.color }}
          >
            {feedback.text}
          </Chip>
        )}
      </Grid>
    ) : (
      <Chip
        icon={feedback.icon}
        variant={feedback.variant}
        type={feedback.type}
        sx={{ borderColor: feedback.borderColor, color: feedback.color }}
      >
        {feedback.text}
      </Chip>
    );
  };

  return (
    <>
      {resources.map((resource) => {
        return (
          <Card
            title={resource.title}
            subtitle={resource.summary}
            description={resource.description}
            mainIcon={<Newspaper style={styles.newspaperIcon}></Newspaper>}
            actionButton={learnMoreButton(resource)}
            tags={tags(resource)}
          />
        );
      })}
    </>
  );
}

export default EducationalMaterialsList;
