import { Stack } from '@mui/material';

import { Slider } from './Slider';
import { ReactComponent as USDIcon } from 'images/redeem/usd.svg';
import { ReactComponent as Coins } from 'images/redeem/coins.svg';

import { FieldsWrapper, FieldWrapper } from './styles';
import { Input } from 'ihp-components/components/v2/Input';

const MINIMUM_SUBTRACTABLE_VALUE = 0.01;
export const Fields = ({
  availablePoints,
  pointsPerDollar,
  value,
  onChange,
  studyConfig,
}) => {
  const handleChange = (_, value) => onChange(value);
  let minValue = studyConfig.thresholdMinAmount;
  const maxValue = availablePoints || studyConfig.thresholdMinAmount;

  if (minValue === maxValue) {
    minValue = minValue - MINIMUM_SUBTRACTABLE_VALUE; // This is to trick slider component
  }

  return (
    <FieldsWrapper spacing='30px'>
      {studyConfig && (
        <Slider
          min={minValue}
          step={studyConfig.thresholdIncrement}
          marks={true}
          max={maxValue}
          value={value}
          onChange={handleChange}
        />
      )}
      <Stack spacing='10px' direction='row'>
        <FieldWrapper>
          <div className='icon'>
            <Coins />
          </div>
          <Input value={value} fullWidth />
        </FieldWrapper>
        <FieldWrapper>
          <div className='icon'>
            <USDIcon />
          </div>
          <Input fullWidth value={value / pointsPerDollar} />
        </FieldWrapper>
      </Stack>
    </FieldsWrapper>
  );
};
