import { styled } from '@mui/system';
import { Box, Button, Typography } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: '20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

export const CardContainer = styled(Box,{
  shouldForwardProp: prop => prop !== 'shouldBeFocussed',
})(({ theme, shouldBeFocussed }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: shouldBeFocussed?'40px':'20px',
  paddingLeft: '20px',
  // SING-38
  // flexDirection: 'column',
  flexDirection: 'row',
  justifyContent: 'center',
  // SING-38
  // alignItems: 'flex-start',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '10px',
  background: shouldBeFocussed ? theme.palette.primary.lightGreen : theme.palette.primary.white,
  flex: '1 0 0',
  [theme.breakpoints.down("sm")]: {
    alignItems: "start",
    flexDirection: "column",
  }
}));

export const MainTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '3px',
  color: theme.palette.primary.darkGray100,
  alignSelf: 'stretch',
  flex: '1 0 0',
}));

export const StyledTypograpy1 = styled(Typography,{
  shouldForwardProp: prop => prop !== 'shouldBeFocussed',
})(({ theme, shouldBeFocussed }) => ({
  fontFamily: 'Inter',
  fontSize: shouldBeFocussed?'20px':'16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  color: theme.palette.primary.darkGray100,
  alignSelf: 'stretch',
}));

export const StyledTypograpy2 = styled(Typography,{
  shouldForwardProp: prop => prop !== 'shouldBeFocussed',
})(({ theme, shouldBeFocussed }) => ({
  fontFamily: 'Inter',
  fontSize: shouldBeFocussed?'16px':'12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '145%',
  color: theme.palette.primary.darkGray100,
  alignSelf: 'stretch',
}));

export const InviteButtonContainer = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '13px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.primary.lightGray100}`,
  background: theme.palette.primary.white,
  textTransform: 'none',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'stretch',
    width: '100%',
  },
}));

export const StyledTypograpy3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.lightShadegreen,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  letterSpacing: '0.28px',
}));
