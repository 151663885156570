import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

import Button from 'ihp-components/components/v2/Button';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { Input } from 'ihp-components/components/v2/Input';

import { FTDDRLogo } from '../../Onboarding/components/FTDDRLogo';
import {
  ModalContainer,
  ModalContent,
  SectionContainer,
  ModalFooter,
} from './components';
import { Title, Text } from '../../Onboarding/components/Text';
import WithdrawFromStudyModal from '../MyStudy/withdrawFromStudyModal';
import { findStudyAttribute } from 'utils/study';
import { getMyProfileReasonsForStudyArm } from '../MyProfile/utils';
import { capitalizeFirstLetter, isProfileIdNotPresent } from './utils';
import DateOfDeathCondolencesModal from './dateOfDeathModalCondolencesModal';

import {
  STUDY_ARM_CODE,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
} from 'constants/global';
import { useGetAccountProfileDetails } from '../../../hooks/useGetAccountProfileDetails';
import { useIsLar } from 'authentication/useIsLar';
import { useIsParent } from 'authentication/useIsParent';

const ChangeStatusModal = ({ isOpen, onClose, onSubmit, statusoptions }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { studyConfiguration, configuration } = useSelector(
    (state) => state.configuration
  );

  const {
    activeAccountProfile,
    person,
    activeAccountProfile: {
      id: accountProfileId,
      attributes: {
        profile: {
          subject_primary_person_study_arm_id: subjectPersonStudyArmId,
        },
      },
    },
  } = useSelector((state) => state.user);

  const { isLar } = useIsLar();
  const { isParent } = useIsParent();

  const { noActiveProfiles, accountProfiles } = useGetAccountProfileDetails({
    personId: person?.id,
    accountProfileId: accountProfileId,
  });

  const researchDeceasedTitle =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_DECEASED
    ]?.title;
  const researchWithdrawnTitle =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_WITHDRAWN
    ]?.title;
  const studyArmStatusTitle =
    activeAccountProfile?.attributes?.profile?.study_arm_status_title;
  const defaultSelectedState = [
    researchDeceasedTitle,
    researchWithdrawnTitle,
  ]?.includes(studyArmStatusTitle)
    ? studyArmStatusTitle
    : 'Active';

  const user = useSelector((state) => state.user);

  const [selectedStatus, setSelectedStatus] = useState(
    capitalizeFirstLetter(defaultSelectedState)
  );
  const [isLoading] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showDeceasedModal, setShowDeceasedModal] = useState(false);

  const [
    createPersonStudyArmAttribute,
    {
      isLoading: isLoadingCreatePersonStudyArmAttribute,
      isSuccess: createPersonStudyArmAttributeSuccess,
    },
  ] = useCreatePersonStudyArmAttributeMutation();

  const [
    updatePersonStudyArm,
    { isLoading: isUpdatingStudyArm, isSuccess: updaingStudyArmSuccess },
  ] = useUpdatePersonStudyArmMutation();

  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const researchWithdrawn =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_WITHDRAWN
    ];

  const contactWithdrawn =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_WITHDRAWN
    ];

  const callCentrePhone =
    findStudyAttribute(studyConfiguration || [], 'call_centre_phone') ||
    '866-267-4479';

  let withdrawalReasons = getMyProfileReasonsForStudyArm(
    findStudyAttribute(studyConfiguration || [], 'withdrawal_reasons'),
    'self-withdrawal',
    activeAccountProfile?.attributes?.profile?.study_arm_code
  );

  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;

  const allowReEnroll =
    findStudyAttribute(studyConfiguration || [], 'allow_re_enroll') || false;

  useEffect(() => {
    if (noActiveProfiles || !isProfileIdNotPresent(accountProfiles,accountProfileId)) {
      setShowWithdrawModal(true);
    }
  }, [noActiveProfiles, accountProfiles]);

  const handleOnClose = () => {
    setSelectedStatus(capitalizeFirstLetter(defaultSelectedState));
    setShowDeceasedModal(false);
    onClose();
  };

  const handleOnChange = (e) => {
    selectedStatus === 'Deceased' && setShowDeceasedModal(true);
  };

  const handleSaveSubmission = () => {
    if (selectedStatus === 'Withdrawn') {
      setShowWithdrawModal(true);
    } else if (selectedStatus === 'Deceased') {
      setShowDeceasedModal(true);
    } else {
      setShowWithdrawModal(false);
    }
  };

  const handleSubmission = async (values = []) => {
    try {
      const createPersonStudyArmAttributePayload = {
        data: {
          type: 'person-study-arm-attributes',
          attributes: {
            attribute: 'withdrawal_reasons',
            value: values.join(','),
          },
        },
      };

      const promises = [
        createPersonStudyArmAttribute({
          personStudyArmId: accountPersonStudyArmId,
          payload: createPersonStudyArmAttributePayload,
        }),
      ];

      if (isLar || isParent) {
        promises.push(
          createPersonStudyArmAttribute({
            personStudyArmId: subjectPersonStudyArmId,
            payload: createPersonStudyArmAttributePayload,
          })
        );
      }

      const responses = await Promise.all(promises);
      const errors = responses.filter((r) => r.error);

      if (errors.length > 0) {
        errors.forEach((e) => {
          console.log('Error: ', e);
        });

        return;
      }
    } catch (error) {
      console.error(error);
    }

    // Update status
    if (
      activeAccountProfile?.attributes?.profile?.study_arm_code ===
      STUDY_ARM_CODE.RESEARCH
    ) {
      await handlePersonStudyArmUpdate(researchWithdrawn);
    }

    if (
      activeAccountProfile?.attributes?.profile?.study_arm_code ===
      STUDY_ARM_CODE.CONTACT
    ) {
      await handlePersonStudyArmUpdate(contactWithdrawn);
    }
    /**
     * make sure to invalidate cache for account profiles listing api to get fresh results from server
     */
    // fresh data not coming
    dispatch({
      type: `${accountProfileApiSlice.reducerPath}/invalidateTags`,
      payload: ['AccountProfiles'],
    });
  };

  const handlePersonStudyArmUpdate = async (studyArmStatus) => {
    const promises = [
      updatePersonStudyArm({
        personStudyArmId: accountPersonStudyArmId,
        payload: {
          data: {
            type: 'person-study-arms',
            id: `${accountPersonStudyArmId}`,
            attributes: {
              study_arm_status_id: studyArmStatus?.id,
            },
          },
        },
      }),
    ];

    if (isLar || isParent) {
      promises.push(
        updatePersonStudyArm({
          personStudyArmId: subjectPersonStudyArmId,
          payload: {
            data: {
              type: 'person-study-arms',
              id: `${subjectPersonStudyArmId}`,
              attributes: {
                study_arm_status_id: studyArmStatus?.id,
              },
            },
          },
        })
      );
    }

    await Promise.all(promises);
    await handleUpdateActiveAccountProfile(studyArmStatus);
  };

  const handleUpdateActiveAccountProfile = async (studyArmStatus) => {
    const activeAccountProfilePayload = {
      ...user,
      activeAccountProfile: {
        ...user.activeAccountProfile,
        attributes: {
          ...user.activeAccountProfile.attributes,
          profile: {
            ...user.activeAccountProfile.attributes.profile,
            study_arm_status_id: studyArmStatus?.id,
            study_arm_status_title: studyArmStatus?.title,
          },
        },
      },
    };

    await dispatch(setValues(activeAccountProfilePayload));
  };

  const navigateToProfile = () => {
    setShowWithdrawModal(false);
    onClose();
    navigate('/profile/change-status');
  };

  let filteredOptions;
  if (
    activeAccountProfile?.attributes?.profile?.study_arm_role_name !== 'lar'
  ) {
    filteredOptions = statusoptions.filter(
      (option) => option.label !== 'Deceased'
    );
  } else {
    filteredOptions = statusoptions;
  }
  useEffect(() => {
    if (createPersonStudyArmAttributeSuccess) {
      /**
       * make sure to invalidate cache for account profiles listing api to get fresh results from server
       */
      dispatch({
        type: `${accountProfileApiSlice.reducerPath}/invalidateTags`,
        payload: ['AccountProfiles'],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPersonStudyArmAttributeSuccess]);

  return !showWithdrawModal ? (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      {showDeceasedModal ? (
        <DateOfDeathCondolencesModal onCancel={handleOnClose} />
      ) : (
        <ModalContainer
          sx={{ backgroundColor: (theme) => theme.palette.primary.white }}
        >
          <>
            <ModalContent paddingTop='40px'>
              <Box display='flex' justifyContent='center' position='relative'>
                <FTDDRLogo />
              </Box>
              <Title marginTop='20px'>
                Are you sure you want to change the status?
              </Title>

              <SectionContainer marginTop='5px'>
                <Text lineHeight='140%'>You cannot undo this action</Text>
              </SectionContainer>
              <SectionContainer marginTop='30px'>
                <Input
                  type='radioInputGroup'
                  id='status'
                  name='status'
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                >
                  {filteredOptions.map((item) => (
                    <RadioInput
                      hideRadioIcon={false}
                      value={item.label}
                      label={item.label}
                      key={item.value}
                    />
                  ))}
                </Input>
              </SectionContainer>
            </ModalContent>

            <ModalFooter>
              <Button
                onClick={handleSaveSubmission}
                width='100%'
                disabled={selectedStatus === null || isLoading}
                loading={isLoading}
              >
                Save
              </Button>
              <Button variant='link' onClick={handleOnClose} width='100%'>
                Cancel
              </Button>
            </ModalFooter>
          </>
        </ModalContainer>
      )}
    </Modal>
  ) : (
    <WithdrawFromStudyModal
      isOpen={showWithdrawModal}
      onClose={navigateToProfile}
      callCentrePhone={callCentrePhone}
      withdrawalReasons={withdrawalReasons}
      submittingWithdrawal={
        isLoadingCreatePersonStudyArmAttribute || isUpdatingStudyArm
      }
      widthdrawSuccessfully={
        createPersonStudyArmAttributeSuccess || updaingStudyArmSuccess
      }
      allowReEnroll={allowReEnroll}
      onSubmit={handleSubmission}
      noActiveProfiles={noActiveProfiles}
      studyArmCode={studyArmCode}
      isAccountProfileIdNotPresent={!isProfileIdNotPresent(accountProfiles,accountProfileId)}
    />
  );
};

export default ChangeStatusModal;
