import { ReactComponent as Account } from 'images/profile/account.svg';
import { ReactComponent as Shield } from 'images/profile/shield.svg';
import { ReactComponent as Transfer } from 'images/profile/transfer.svg';
import { ReactComponent as ChevronRight } from 'configUI/images/list/chevronRight.svg';
import { HyperwalletCard } from './Card';

export const SetupAccount = ({ reward, ...rest }) => (
  <HyperwalletCard
    icon={<Account />}
    title='Setup an account'
    description='Neque ac bibendum donec at felis et id ultrices'
    {...rest}
  >
    <ChevronRight />
  </HyperwalletCard>
);

export const SetupTransfer = ({ reward, ...rest }) => (
  <HyperwalletCard
    icon={<Transfer />}
    title='Setup transfer method'
    description='Vestibulum viverra diam dolor, in rhoncus et'
    {...rest}
  >
    {' '}
    <ChevronRight />
  </HyperwalletCard>
);
export const VerifyAccount = ({ reward, ...rest }) => (
  <HyperwalletCard
    icon={<Shield />}
    title='Verify account'
    description='Proin scelerisque ut ornare mauris pretium'
    {...rest}
  >
    {' '}
    <ChevronRight />
  </HyperwalletCard>
);
