import {
  StyledAction,
  StyledContent,
  StyledDescription,
  StyledHyperwalletCard,
  StyledIcon,
  StyledTitle,
} from './Card.styles';

export const HyperwalletCard = ({
  icon,
  title,
  description,
  children,
  ...rest
}) => (
  <StyledHyperwalletCard {...rest}>
    <StyledIcon>{icon}</StyledIcon>
    <StyledContent>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledContent>
    <StyledAction>{children}</StyledAction>
  </StyledHyperwalletCard>
);
