import { Stack } from '@mui/material';
import { Box, styled } from '@mui/system';
import { ReactComponent as ArrowRightIcon } from 'images/shared/chevron-right.svg';

export const StyledInstructions = styled(Box)(({ theme }) => ({
  padding: '0px',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
  },
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  padding: '40px 40px 32px 40px',
  borderBottom: `1px solid ${theme.palette.primary.lightBlue10}`,
  [theme.breakpoints.down('sm')]: {
    borderBottom: 'none',
    padding: '40px 40px 15px 40px',
  },
}));

export const Content = styled(Stack)(({ theme }) => ({
  paddingLeft: '40px',
  paddingRight: '40px',
  paddingBottom: '40px',
}));

export const Header = styled('p')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '13px',
  lineHeight: '145%',
  textTransform: 'uppercase',
  color: theme.palette.primary.darkBlue50,
  marginBottom: '4px',
}));

export const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '50px',
  alignItems: 'center',
  gap: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    padding: '25px 25px',
    marginTop: '0px',
    backgroundColor: theme.palette.primary.gray20,
    '& >button': {
      width: '100%',
    },
  },
}));

export const StyledArrowRIght = styled(ArrowRightIcon)(() => ({
  height: '15px',
  width: '15px',
  marginLeft: '10px',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '660px',
}));

export const MetaData = styled('div')(({ theme, width }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '40px',
  backgroundColor: theme.palette.primary.gray20,
  borderRadius: '5px',
  padding: '15px 30px',
  margin: '0px 0px 20px 0px',
  width: '100%',
}));

export const Reward = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.darkGray100,
  fontWeight: '600',
  fontSize: '16px',
  svg: {
    marginRight: '10px',
  },
}));

export const AllowedTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.darkGray100,
  fontWeight: '600',
  fontSize: '16px',
  svg: {
    marginRight: '8px',
  },
}));

export const EstimatedTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.darkGray100,
  fontWeight: '600',
  fontSize: '16px',
  svg: {
    marginRight: '8px',
  },
}));

export const TaskType = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.darkGray100,
  fontWeight: '600',
  fontSize: '16px',
  svg: {
    marginRight: '8px',
    fill: theme.palette.primary.gray40,
  },
}));
