import { SVSOURCE_CONTACT } from "utils/constants";

export const REGISTER_DNA_KIT_ERROR_MESSAGE = `This kit number does not match the kit number that was sent to you. Do not proceed with saliva collection. Please reach out to study staff at ${SVSOURCE_CONTACT.EMAIL} or ${SVSOURCE_CONTACT.PHONE} so they can match you with your correct kit.`;

export const DUPLICATE_CHILD_EMAIL = {
  CUSTOM_CLASS: 'duplicateChildEmailError',
  API_CODE: '422',
  API_MESSAGE: 'Participant already exist with the email.',
  UI_ERROR_MSG: 'The email is already registered',
};
