import { v4 as uuid } from 'uuid';

import createQuestionsSlice from '../utils/questions/createQuestionsSlice.js';

const initialEntities = [
  {
    taskId: '001',
    title: 'Integer dapibus sed lacus porta blandit?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    numberOfAnswers: '',
    description: '',
    answers: []
  },
  {
    taskId: '001',
    title: 'Donec rutrum libero a magna porttitor?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    numberOfAnswers: '',
    description: '',
    answers: []
  },
  {
    taskId: '002',
    title: 'Morbi pulvinar ex sit amet?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    numberOfAnswers: '',
    description: '',
    answers: []
  },
  {
    taskId: '002',
    title: 'Fusce molestie egestas ligula. Vel elementum?',
    enabled: false,
    required: false,
    typeOfAnswer: '',
    numberOfAnswers: '',
    description: '',
    answers: []
  },
  {
    taskId: '003',
    title: 'Proin tempus magna ut.',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    numberOfAnswers: '',
    description: '',
    answers: []
  },
];

const getIds = (state, { taskId }) => {
  if (!state.taskIdToIds[taskId])
    state.taskIdToIds[taskId] = [];
  return state.taskIdToIds[taskId];
}
const getEntities = state => state.entities;

const initialState = { taskIdToIds: {}, entities: {} };
initialEntities.forEach(entity => {
  const id = uuid();
  getIds(initialState, entity).push(id);
  getEntities(initialState, entity)[id] = { id, ...entity };
});

const slice = createQuestionsSlice({
  name: 'task',
  initialState,
  getters: { getIds, getEntities, },
  extraReducers: builder => builder
    .addCase('tasks/deleteTask', (state, { payload: { id } }) => {
      if (state.taskIdToIds[id]) {
        state.taskIdToIds[id].forEach(id => delete state.entities[id]);
        delete state.taskIdToIds[id];
      }
    })
});

export const {
  reorderQuestion,
  enableQuestion,
  addQuestion,
  removeQuestion,
  updateQuestion,
} = slice.actions;

export const selectQuestionIdsByTaskId = taskId => state => (state[slice.name].taskIdToIds[taskId] || []);
export const selectQuestionById = id => state => state[slice.name].entities[id];

export default slice;
