import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isNavbarExpanded : false
};

export const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
        setNavbarExpanded: (state, action) => {
            state.isNavbarExpanded = action.payload;
        },
    },
});

export const { setNavbarExpanded } = navbarSlice.actions;

export default navbarSlice.reducer;
