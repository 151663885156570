import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: baseQuery(),
  tagTypes: ['Organizations'],
  endpoints: (build) => ({
    listOrganizations: build.query({
      query: () => ({ url: 'organizations', method: 'GET' }),
      providesTags: ['Organizations']
    }),
    createOrganization: build.mutation({
      query: (body) => ({ url: 'organizations', method: 'POST', body }),
      invalidatesTags: ['Organizations']
    }),
  }),
});

export default organizationApi;

export const {
  useCreateOrganizationMutation,
  useListOrganizationsQuery,
} = organizationApi;
