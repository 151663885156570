import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { TYPES, VARIANTS, SIZES_STYLES, DISABLED_STYLES } from './config';

const setColor = ({ color, background }) => ({
  ...(color ? { color } : {}),
  ...(background ? {
    background,
    '&: hover': { background },
  } : {})
});

const getConfig = (
  theme, 
  {
    size = 'md',
    variant = 'contained',
    type = 'primary',
    color,
    background,
    width,
  }
) => {
  const TYPE_COLOR = {
    [TYPES.primary]: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.white,
    },
    [TYPES.secondary]: {
      background: theme.palette.primary.darkGray50,
      color: theme.palette.primary.white,
    },
    [TYPES.info]: {
      background: theme.palette.primary.lightGray70,
      color: theme.palette.primary.darkGray70,
    }
  };
  
  const COLOR_VARIANT_MAP = {
    [VARIANTS.contained]: {
      [TYPES.primary]: {
        ...TYPE_COLOR[TYPES.primary],
        '&: hover': {
          background: TYPE_COLOR[TYPES.primary].background,
        },
        '&: disabled': {
          ...TYPE_COLOR[TYPES.primary],
        },
      },
      [TYPES.secondary]: {
        ...TYPE_COLOR[TYPES.secondary],
        '&: hover': {
          background: TYPE_COLOR[TYPES.secondary].background,
        },
        '&: disabled': {
          ...TYPE_COLOR[TYPES.secondary],
        },
      },
      [TYPES.info]: {
        ...TYPE_COLOR[TYPES.info],
        '&: hover': {
          background: TYPE_COLOR[TYPES.info].background,
        },
        '&: disabled': {
          ...TYPE_COLOR[TYPES.info],
        },
      }
    },
    [VARIANTS.outlined]: {
      [TYPES.primary]: {
        background: theme.palette.primary.transparent,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.primary].background,
        borderColor: TYPE_COLOR[TYPES.primary].background,
        '&: hover': {
          background: 'transparent',
        },
      },
      [TYPES.secondary]: {
        background: theme.palette.primary.white,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.secondary].background,
        borderColor: TYPE_COLOR[TYPES.secondary].background,
        '&: hover': {
          background: 'transparent',
        },
      },
      [TYPES.info]: {
        background: theme.palette.primary.white,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.info].background,
        borderColor: TYPE_COLOR[TYPES.info].background,
        '&: hover': {
          background: 'transparent',
        },
      }
    },
    [VARIANTS.link]: {
      [TYPES.primary]: {
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        color: TYPE_COLOR[TYPES.primary].background,
      },
      [TYPES.secondary]: {
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        color: TYPE_COLOR[TYPES.secondary].background,
      },
      [TYPES.info]: {
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        color: TYPE_COLOR[TYPES.info].background,
      },
    },
  };

  const variantStyles = COLOR_VARIANT_MAP[variant] || COLOR_VARIANT_MAP[VARIANTS.contained];

  return {
    ...(SIZES_STYLES[size] || SIZES_STYLES.lg),
    ...(variantStyles[type] || variantStyles[TYPES.primary]),
    borderRadius: '8px',
    display: 'flex',
    flexDireaction: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    '&:disabled': DISABLED_STYLES,
    ...setColor({ color, background }),
    ...(width ? { width } : {})
  };
}

const Button = styled(MUIButton)(
  ({
    theme,
    width,
    background,
    color,
    variant,
    type,
    size,
  }) => {
    return getConfig(theme, { variant, type, size, width, background, color })
  }
);

export const FilledButton = ({ children, loading, onClick, startIcon, endIcon, ...props }) => (
  <Button
    {...props}
    onClick={(e) => !loading ? onClick(e) : null}
    startIcon={!loading && startIcon}
    endIcon={!loading && endIcon}
  >
    {loading ? <CircularProgress color='inherit' size={25} /> : children}
  </Button>
)

FilledButton.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  type: PropTypes.oneOf(Object.keys(TYPES)),
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs'])
};
