import { CircularProgress, Typography } from '@mui/material';
import { ReactComponent as ThumbsUp } from 'images/shared/thumbs-up.svg';
import { ReactComponent as Medical } from 'images/shared/medical.svg';
import { EmptyListWrapper } from './styles';
import { EmptyState } from 'ihp-components/components/v2/EmptyState';

export const EmptyList = ({
  isLoading = false,
  filterApplied = false,
  past = false,
  upcoming = false
}) => {
  let icon = <Medical />;
  let title = isLoading ? 'Loading study activities' : 'You are all caught up!';
  let subtitle = null;
  if (isLoading) {
    icon = null;
  } else {
    icon = <ThumbsUp />;
  }
  if (filterApplied) {
    title = 'No activities to show';
    subtitle = 'Change your filter to display new records';
    icon = <Medical />;
  } else if ((past || upcoming) && !isLoading) {
    title = 'No activities available';
    subtitle = 'This participant has no activities to display';
    icon = <Medical />;
  }
  return (
    <EmptyListWrapper>
      <EmptyState icon={icon} title={title} description={subtitle} />
      {isLoading && (
        <CircularProgress sx={{ marginTop: '20px' }} color='primary' />
      )}
    </EmptyListWrapper>
  );
};
