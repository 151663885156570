import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.secondary.gray4,
  cursor: 'pointer',
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.primary.gray50,
  cursor: 'pointer',
}));

export const StyledHyperwalletCard = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.fuchsia5}`,
  boxSizing: 'border-box',
  height: '86px',
  padding: '15px 20px 15px 5px',
  backgroundColor: theme.palette.primary.white,
  borderRadius: '7px',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  maxWidth: '325px',
  '&:hover': {
    borderColor: theme.palette.primary.fuchsia75,
  },
}));

export const StyledIcon = styled(Box)({ padding: '0 10px 0px' });

export const StyledContent = styled((props) => (
  <Stack {...props} spacing='2px' />
))({ flex: '1 1 auto' });

export const StyledAction = styled(Box)({
  // flex: '0 0 40px',
  padding: '0 0 0 20px',
});
