import { fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { getBaseURL, getSmpBaseUrl } from 'config/API';

const BASE_URL = getBaseURL();
const SMP_BASE_URL = getSmpBaseUrl();

export const deferred = (() => {
  const props = {};
  props.promise = new Promise((resolve) => (props.resolve = resolve));
  return props;
})();

const getAccessToken = async () => {
  const getToken = await deferred.promise;
  return getToken();
};

const getToken = () => {
  if (window.navigator.userAgent && typeof document !== 'undefined') {
    let token;
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.search('@@auth0') === 0) {
        const item = localStorage.getItem(key);
        const data = JSON.parse(item);
        token = data && data.body && data.body.access_token;
      }
    }
    return token;
  }
  return null;
};

export const baseQuery = (version = 'v2') => {
  return retry(
    fetchBaseQuery({
      baseUrl: `${BASE_URL}/${version}`,
      // maxRetries: 3,
      async prepareHeaders(headers, { getState }) {
        const headersSet = headers.get('Authorization');
        if (!headersSet) {
          const token =
            getToken() ||
            getState().authentication.token ||
            (await getAccessToken());
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
        }
        return headers;
      },
    }),
    { maxRetries: 1 }
  );
};

export const baseSmpGqlQuery = () => {
  return retry(
    fetchBaseQuery({
      baseUrl: SMP_BASE_URL,
      maxRetries: 3,
      async prepareHeaders(headers, { getState }) {
        const headersSet = headers.get('Authorization');
        if (!headersSet) {
          const token =
            getToken() ||
            getState().authentication.token ||
            (await getAccessToken());
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
        }
        return headers;
      },
    }),
    { maxRetries: 1 }
  );
};

export const tremendousBaseQuery = () => {
  return fetchBaseQuery({
    baseUrl: 'https://testflight.tremendous.com/api/v2/',
    tagTypes: ['Payment'],
    prepareHeaders(headers) {
      headers.set(
        'authorization',
        `Bearer TEST_rg5eXr__qyO65WhrC5MkvL9y6ZbnVNo5x2DIB82_A-4`
      );
      return headers;
    },
  });
};
