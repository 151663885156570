import { styled } from '@mui/system';
import { Box } from '@mui/material';

import { BaseLayout } from 'components/BaseLayout';

export const Content = styled(Box)({
  maxWidth: 750,
  width: '100%',
  margin: '0 auto',
  padding: '0 25px',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.primary.gray20,
  '& .BaseLayout-scrollable': {
    backgroundColor: theme.palette.primary.gray20,
  },
  '& .BaseLayout-footer': {
    backgroundColor: theme.palette.primary.white,
  },
  '& .BaseLayout-content': {
    paddingTop: '25px',
  },
}));
