import { styled } from '@mui/system';
import { TextInput } from 'components/Input';
import { ReactComponent as SearchIcon } from 'configUI/images/manage/zoom.svg';

export const StyledTextInput = styled(TextInput)({
  width: '200px',
  height: '50px',
  borderRadius: '8px',
});

export const StyledSearchIcon = styled(SearchIcon)({
  paddingLeft: '15px',
  width: '23px',
  height: '23px',
});
