import { BaseHeader } from 'components/BaseLayout';
import { Divider } from 'configUI/components/Shared';
import { StyledBaseLayout } from 'pages/NotificationCenter/MedicationTracker/styles';
import { DrugCardList } from './DrugCardList';
import { getMedicationTrackerPath } from 'pages/NotificationCenter/paths';

const AllMedicationsMobile = () => (
  <StyledBaseLayout
    header={
      <>
        <BaseHeader to={getMedicationTrackerPath()}>All medications</BaseHeader>
        <Divider />
      </>
    }
    content={<DrugCardList />}
  />
);

export default AllMedicationsMobile;
