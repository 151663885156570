import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';

const getStyles = (theme, error) => ({
  label: {
    color: theme.palette.primary.gray75,
    fontWeight: '500',
    fontSize: '14px',
  },
  uploadContainer: {
    border: `1px solid ${theme.palette.primary.gray25}`,
    padding: '10px 20px 10px 10px',
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '8px',
    background: 'white',
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.primary.gray50,
    },
  },
  img: {
    left: 8,
    top: 8,
    borderRadius: '5px',
    width: '50px',
    height: '50px',
  },
  hiddenImg: {
    left: 8,
    top: 8,
    display: 'hidden',
  },
  uploadIcon: {
    left: 8,
    top: 2,
  },
  uploadBtn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    textTransform: 'none',
    color: theme.palette.primary.gray100,
    fontWeight: '500',
    fontSize: '16px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  uploadInfoText: {
    textTransform: 'none',
    color: theme.palette.primary.gray50,
  },
  removeBtn: {
    width: '10px',
    height: '15px',
    cursor: 'pointer',
    color: theme.palette.primary.gray25,
    '&:hover': {
      color: theme.palette.primary.gray50,
    },
  },
});

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red75,
  marginLeft: '0px',
  marginTop: '10px',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '17px',
  opacity: '0.8',

  '& svg': {
    marginRight: '5px',
  },
}));

export default getStyles;
