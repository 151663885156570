import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

import PregnancyJournalDetailedDesktop from './index.desktop';
import PregnancyJournalDetailedMobile from './index.mobile';
import { useGetTaskEntry } from './hooks';

const PregnancyJournalDetailed = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const id = parseInt(useParams().id);
  const { data, isLoading, isError } = useGetTaskEntry(id);

  const navigate = useNavigate();

  useEffect(() => {
    // Some visual error message would be nice before going back
    if (isError) navigate('/tools/pregnancy-journal');
  });

  return upSm ? (
    <PregnancyJournalDetailedDesktop data={data} isLoading={isLoading} />
  ) : (
    <PregnancyJournalDetailedMobile data={data} isLoading={isLoading} />
  );
};

export default PregnancyJournalDetailed;
