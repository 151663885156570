import { Modal } from '@mui/material';
import { ModalAction, ModalBox, Title } from './styles';
import Button from 'ihp-components/components/v2/Button';

export function OverlayModal({
  open,
  modalTitle,
  modalContent,
  modalButtonsConfig,
  onClose,
}) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby={modalTitle}>
      <ModalBox>
        {modalTitle && <Title>{modalTitle}</Title>}

        {modalContent}

        {modalButtonsConfig?.length > 0 && (
          <ModalAction>
            {modalButtonsConfig?.map((button) => (
              <Button
                variant={button?.variant}
                onClick={button?.handleClick}
                disabled={button?.disabled}
                loading={button?.loading}
              >
                {button?.text}
              </Button>
            ))}
          </ModalAction>
        )}
      </ModalBox>
    </Modal>
  );
}
