import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import {
  FinalScreenContainer,
  DoneButton,
  BottomContainer,
  FinalMessage,
  ThanksMessage,
  FinalIconContainer,
  FinalScreenContent,
  RewardBadge,
  RewardPoints,
} from './styles';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';

function Final() {
  const navigate = useNavigate();

  const navigateToActivities = () => {
    navigate('/my-activities');
  };

  return (
    <FinalScreenContainer>
      <FinalScreenContent>
        <FinalIconContainer>
          {/* <Completed width={200} height={198} /> */}
        </FinalIconContainer>
        <ThanksMessage>Connected!</ThanksMessage>
        <FinalMessage>
          Your health record will be used in the study
        </FinalMessage>
        <RewardBadge>
          <PointsIcon width={18} />
          <RewardPoints>{250}</RewardPoints>
          <Typography variant='pn1'>Points earned</Typography>
        </RewardBadge>
        <BottomContainer>
          <DoneButton
            fullWidth
            variant='contained'
            onClick={navigateToActivities}
          >
            Done
          </DoneButton>
        </BottomContainer>
      </FinalScreenContent>
    </FinalScreenContainer>
  );
}

export default Final;
