import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const userApiSlice = createApi({
  reducerPath: 'userApiSlice',
  baseQuery: baseQuery(),
  tagTypes: ['User', 'CurrentUser'],
  endpoints: (build) => ({
    checkUser: build.mutation({
      query: (email) => ({
        url: email ? `/users/check?email=${email}` : '/users/check',
        method: 'GET',
      }),
      invalidatesTags: ['User'],
    }),
    getStudyRoles: build.query({
      query: (body) => ({
        url: '/entity/list',
        method: 'POST',
        body: {
          entity_name: 'study_roles',
          params: {
            study_id: 1,
            study_version_id: 1,
            person_type: 'participant',
            ...body, // We can send only the status in the body for now
          },
        },
      }),
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: '/users?include=persons',
        method: 'POST',
        body: {
          ...body,
        },
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    getCurrentUser: build.query({
      query: () => ({
        url: '/users/current?include=persons,study_roles,person_attribute',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    getCurrentUserInfo: build.query({
      query: () => ({
        url: '/users/current?include=persons,study_roles,person_attribute,attributes',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    checkCurrentUser: build.mutation({
      query: () => ({
        url: '/users/current?include=persons',
        method: 'GET',
      }),
      providesTags: ['CurrentUser'],
      extraOptions: {
        maxRetries: 3,
      },
    }),
    editPerson: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/site/1/persons/${personId}?include=person_attribute`,
        method: 'PATCH',
        body: {
          ...payload,
        },
        headers: {
          Accept: `application/json`,
        },
      }),
      invalidatesTags: ['CurrentUser'],
    }),
    getTimezones: build.query({
      query: () => ({
        url: '/timezones',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ', // this api is public so no token is needed
        },
      }),
    }),
    getStatus: build.mutation({
      query: (personId) => ({
        url: `/persons/${personId}/oneup/enroll-status`,
        method: 'GET',
      }),
      invalidatesTags: ['CurrentUser'],
    }),
  }),
});

export const {
  useCheckCurrentUserMutation,
  useCheckUserMutation,
  useGetStudyRolesQuery,
  useCreateUserMutation,
  useGetCurrentUserQuery,
  useGetCurrentUserInfoQuery,
  useEditPersonMutation,
  useGetTimezonesQuery,
  useGetStatusMutation,
  useResendVerificationEmailQuery,
} = userApiSlice;
