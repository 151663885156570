import { Box, useTheme } from '@mui/material';
import { ReactComponent as GreenPlusIcon } from 'images/payment/plus-green.svg';
import { ReactComponent as RedSubtractIcon } from 'images/payment/subtract-red.svg';
import { ReactComponent as AwardIcon } from 'images/payment/award.svg';
import { ReactComponent as EnrollmentBadgeIcon } from 'images/payment/enrollment-badge.svg';
import { ReactComponent as RedeemBadgeIcon } from 'images/payment/redeem-badge.svg';
// Task type icons
import { ReactComponent as SurveyBadgeIcon } from 'images/payment/survey-badge.svg';
import { ReactComponent as CallBadgeIcon } from 'images/payment/call-badge.svg';
import { ReactComponent as ScheduleCallBadgeIcon } from 'images/payment/schedule-call-badge.svg';
import { ReactComponent as ArticleBadgeIcon } from 'images/payment/article-badge.svg';
import { ReactComponent as ProfileBadgeIcon } from 'images/payment/profile-badge.svg';
import { ReactComponent as ConsentBadgeIcon } from 'images/payment/consent-badge.svg';
import { ReactComponent as EMRBadgeIcon } from 'images/payment/emr-badge.svg';

import { taskTypeLabelMapping } from 'pages/Activities/utils';

import {
  PaymentItemWrapper,
  Icon,
  Content,
  Title,
  FlexedBox,
  Value,
  TransactionValue,
  Balance,
  Dot,
  RewardsLink,
} from './styles';
import { isMobile } from 'utils/isMobile';

function getDateResponse(date) {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const timeDifference = currentDate - inputDate;
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const millisecondsPerWeek = 7 * millisecondsPerDay;

  if (timeDifference <= millisecondsPerDay) {
    return 'Today';
  } else if (timeDifference <= 2 * millisecondsPerDay) {
    return 'Yesterday';
  } else {
    const weeks = Math.floor(timeDifference / millisecondsPerWeek);
    const days = Math.floor(
      (timeDifference % millisecondsPerWeek) / millisecondsPerDay
    );
    return weeks > 0
      ? `${weeks} week${weeks > 1 ? 's' : ''} ${
          days > 0 ? `and ${days} day${days > 1 ? 's' : ''}` : ''
        } ago`
      : `${days} day${days > 1 ? 's' : ''} ago`;
  }
}

const transactionTypeIndicatorMap = {
  credit: <GreenPlusIcon />,
  debit: <RedSubtractIcon />,
};

const taskTypeBadgeMap = {
  survey: <SurveyBadgeIcon />,
  data_sharing: <EMRBadgeIcon />,
  article: <ArticleBadgeIcon />,
  profile: <ProfileBadgeIcon />,
  video: <ArticleBadgeIcon />, // change this with new icon
  call_schedule: <ScheduleCallBadgeIcon />,
  call: <CallBadgeIcon />,
  consent: <ConsentBadgeIcon />,
};

export const PaymentItem = (props) => {
  const {
    task_title,
    created_at,
    task_type,
    participant_task_id,
    points_redeemed,
    value_gained,
    balance,
    amount_redeemed,
    reason,
    relationships,
  } = props;

  const theme = useTheme();

  const getTransactionIcon = () => {
    let icon = taskTypeBadgeMap[task_type]; // Replace this with task type icons
    if (!participant_task_id) {
      if (points_redeemed) {
        icon = <RedeemBadgeIcon />;
      } else if (reason) {
        icon = <CallBadgeIcon />;
      } else if (value_gained && !reason) {
        icon = <EnrollmentBadgeIcon />;
      }
    }
    return icon;
  };

  const getTransactionName = () => {
    let name = task_title;
    if (!participant_task_id) {
      if (points_redeemed) {
        name = `Redeem ${amount_redeemed}`;
      } else if (reason) {
        name = reason;
      } else if (value_gained && !reason) {
        // if should be value_gained && no reason
        name = 'Enrollment';
      }
    }
    return name;
  };

  const getTaskType = () => {
    let taskType = taskTypeLabelMapping[task_type];
    if (!participant_task_id) {
      if (reason) {
        taskType = 'Call center manual reward';
      } else if (value_gained && !reason) {
        taskType = 'Onboarding';
      }
    }
    return taskType;
  };

  const getTransactionType = () => {
    if (value_gained) {
      return 'credit';
    }
    if (points_redeemed) {
      return 'debit';
    }
  };

  const getTransactionValueColor = () => {
    const transactionType = getTransactionType();
    if (transactionType === 'debit') {
      return theme.palette.primary.red75;
    }
    return theme.palette.primary.green100;
  };

  const getTransactionValueBackgroundColor = () => {
    const transactionType = getTransactionType();
    if (transactionType === 'debit') {
      return theme.palette.primary.white;
    }
    return theme.palette.primary.gray5;
  };

  const getTransactionRowBackgroundColor = () => {
    const transactionType = getTransactionType();
    if (transactionType === 'debit') {
      return theme.palette.primary.red5;
    }
    return theme.palette.primary.white;
  };

  const actionLink =
    relationships?.['participant-reward']?.attributes?.transaction_details
      ?.order?.rewards?.[0]?.delivery?.link;

  return (
    <PaymentItemWrapper backgroundColor={getTransactionRowBackgroundColor()}>
      {!isMobile() && <Icon>{getTransactionIcon()}</Icon>}
      <Content>
        <FlexedBox>
          {isMobile() && <Icon>{getTransactionIcon()}</Icon>}
          <div>
            <Title>{getTransactionName()}</Title>
            <FlexedBox justifyContent='flex-start'>
              <Value>{getDateResponse(created_at)}</Value>
              {getTaskType() && (
                <>
                  <Dot />
                  <Value>{getTaskType()}</Value>
                </>
              )}
              {actionLink && (
                <>
                  <Dot />
                  <RewardsLink
                    href={actionLink}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Redeem reward
                  </RewardsLink>
                </>
              )}
            </FlexedBox>
          </div>
        </FlexedBox>
        <FlexedBox gap='7px'>
          {transactionTypeIndicatorMap[getTransactionType()]}
          <FlexedBox gap='26px'>
            <TransactionValue
              color={getTransactionValueColor()}
              backgroundColor={getTransactionValueBackgroundColor()}
            >
              <AwardIcon />
              <span>{value_gained || points_redeemed}</span>
            </TransactionValue>
            <Balance>
              <AwardIcon />
              <span>{balance}</span>
            </Balance>
          </FlexedBox>
        </FlexedBox>
      </Content>
    </PaymentItemWrapper>
  );
};
