import React from 'react';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { STUDY_ARM_CODES, STUDY_ARM_ROLE_NAME } from 'constants/global';
import { StyledAccountInfoDiv, StyledTypography, StyledStrong } from './style';

const AccountInfo = () => {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const firstName = activeAccountProfile?.attributes?.profile?.first_name;
  const lastName = activeAccountProfile?.attributes?.profile?.last_name;
  const roleName =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;
  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;
  const contactStudyArm = studyArmCode === STUDY_ARM_CODES.CONTACT;

  let accountHolder = '';
  let typeMessage = '';

  switch (roleName) {
    case STUDY_ARM_ROLE_NAME.ParentNonSVChild7ToMajority:
    case STUDY_ARM_ROLE_NAME.ParentNonSVChildUnder7:
    case STUDY_ARM_ROLE_NAME.ParentSVChild7ToMajority:
    case STUDY_ARM_ROLE_NAME.ParentSVChildUnder7:
      accountHolder = `Profile of ${firstName} ${lastName}`;
      typeMessage = ``;
      break;

    case STUDY_ARM_ROLE_NAME.LARNonSV:
    case STUDY_ARM_ROLE_NAME.LARNonSVChild7ToMajority:
    case STUDY_ARM_ROLE_NAME.LARNonSVChildUnder7:
    case STUDY_ARM_ROLE_NAME.LARSV:
    case STUDY_ARM_ROLE_NAME.LARSVChild7ToMajority:
    case STUDY_ARM_ROLE_NAME.LARSVChildUnder7:
      accountHolder = `Profile of ${firstName} ${lastName}`;
      typeMessage = ``;
      break;

    default:
      accountHolder = 'My Profile';
      break;
  }

  return (
    <StyledAccountInfoDiv hideAccountInfo={contactStudyArm}>
      <StyledTypography>
        <StyledStrong>{accountHolder}</StyledStrong> {typeMessage}
      </StyledTypography>
    </StyledAccountInfoDiv>
  );
};

export default AccountInfo;
