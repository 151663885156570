import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as ArrowBackwardIcon } from 'images/shared/chevron-left-1.svg';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock-filled.svg';
import { ReactComponent as ClipboardMedicalIcon } from 'images/activity/types/clipboard-medical.svg';
import { taskStatsPositionMap } from './constants';
import {
  ActivityHeaderContainer,
  Reward,
  StyledStepCounter,
  RequiredTime,
  AllowedTime,
  TaskType,
  SubtitleMobile,
} from './styles';

const ActivityHeader = ({
  title,
  points,
  estimatedTime,
  timeLimit,
  type,
  currentStep = 0,
  lastStep = 0,
  showStepCounter = false,
  subtitle,
  ...rest
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const subInfo = [];
  if (type && !isMobile) {
    subInfo.push(
      <TaskType key={taskStatsPositionMap.type}>
        <ClipboardMedicalIcon />
        {type}
      </TaskType>
    );
  }

  if (estimatedTime && !isMobile) {
    subInfo.push(
      <RequiredTime key={taskStatsPositionMap.estimatedTime}>
        <ClockIcon />
        {estimatedTime}
      </RequiredTime>
    );
  }

  return (
    <ActivityHeaderContainer {...rest}>
      <Stack direction='row' gap='25px' alignItems='center'
             style={{cursor: 'pointer'}} onClick={() => navigate('/my-activities')}>
        <ArrowBackwardIcon width={10} height='16px' color='#A1A1A1' />
        <Typography noWrap={false} variant='h6' color='primary.darkGray100'>
          {title}
          {isMobile && (
            <>
              <br />
              <SubtitleMobile>{subtitle}</SubtitleMobile>
            </>
          )}
        </Typography>
      </Stack>
      <StyledStepCounter
        current={currentStep}
        maximum={lastStep}
        show={showStepCounter}
      />
      <Typography variant='pl4' color='primary.darkGray100'>
        <Stack
          spacing='20px'
          direction='row'
          alignItems='center'
          sx={{ m: { xs: '12px 0 0 35px', sm: 0 } }}
        >
          {subInfo}
        </Stack>
      </Typography>
    </ActivityHeaderContainer>
  );
};

export default ActivityHeader;
