import { useSelector } from 'ihp-bloom-redux/app/redux';
import Info from './generalInfo';
const GeneralInfo = () => {
  const user = useSelector((state) => state.user);
  const { activeAccountProfile } = user;
  const subjectPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;
  return <Info subjectPersonId={subjectPersonId} />;
};

export default GeneralInfo;
