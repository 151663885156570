import { useIsValidDate } from 'hooks/useIsValidDate';

const countryZipCodeRegexs = [
  { country: 'US', regex: /^\d{5}(?:-\d{4})?$/ },
  {
    country: 'CA',
    regex: /^[A-Z]\d[A-Z] \d[A-Z]\d$/,
  },
];

export const validateDate = (value) => {
  if (!value) return true;

  // Preprocess the value if it's a string
  if (typeof value === 'string' && value.includes('/')) {
    const parts = value.split('/');
    // Check if the parsed date contains incomplete information
    if (parts.length < 3 || !parts[0] || !parts[1] || !parts[2]) {
      return 'Invalid date format. Please use MM/dd/yyyy';
    }
  }

  const parsedDate = new Date(value);

  // If parsing fails or the date is invalid, return error
  if (isNaN(parsedDate.getTime())) {
    return 'Invalid date format. Please use MM/dd/yyyy';
  }

  // Validate date range
  const currentDate = new Date();
  const minDate = new Date(currentDate.getFullYear() - 150, 0, 1);
  if (parsedDate > currentDate) return 'Date should not be in the future';
  if (parsedDate < minDate) return 'Please enter a valid date';

  return true;
};

export const useValidateDate = () => {
  const { isValidDate } = useIsValidDate();

  return (dateOfBirth) => {
    const message = 'Please enter a valid date';
    try {
      const minYear = new Date().getFullYear() - 150;
      const isValid =
        dateOfBirth &&
        isValidDate(dateOfBirth) &&
        Date.parse(dateOfBirth) <= Date.parse(new Date()) &&
        new Date(dateOfBirth)?.getFullYear() >= minYear;
      return isValid || message;
    } catch (e) {
      return message;
    }
  };
};

export const useValidatePhone = () => (phone) => {
  if (phone === '') {
    return true;
  }

  return !phone?.includes('_') || 'This phone number is invalid';
};

export const useRegexValidatePhone = (phoneHookVariable, countryHookVariable) => (phone, country) => {

  if (phone === '+' || phone === '') {
    return true;
  }

  if(['US', 'CA'].includes(countryHookVariable) || ['US', 'CA'].includes(country)) {
    const phoneNumberRegex =  new RegExp(/^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

    if (!phoneNumberRegex.test(phone)) {
      return 'Please check the phone number entered';
    }
  } else {
    const phoneRegex = new RegExp(/^\+[1-9][0-9]{3,12}$/);
    if (!phoneRegex.test(phone)) {
      return 'Please check the phone number entered';
    }
  }
};


export const useValidateZipCode = (country) => (zipCode) => {
  if (zipCode === '' || zipCode === undefined || zipCode === null) {
    return true;
  }
  const countryZipcode = countryZipCodeRegexs.find(
    (code) => code.country === country
  );
  if (countryZipcode) {
    const validZipCodeRegex = new RegExp(countryZipcode.regex);
    if (!validZipCodeRegex.test(zipCode)) {
      return 'Invalid zip code.';
    }
  }
};

export const useValidateEmail = () => (email) => {
  if (email === '') {
    return true;
  }

  const multipleAdjacentDotsRegex = new RegExp('\\b([.])\\1+\\b');
  if (multipleAdjacentDotsRegex.test(email)) {
    return 'This email is invalid';
  }

  if (email?.length > 0 && email[0] === '.') {
    return 'This email is invalid';
  }

  const parts = email?.split('@');
  if (parts?.length === 2) {
    if (parts[0].indexOf('#') > -1) {
      return 'This email is invalid';
    }
    if (parts[0].slice(-1) === '-') {
      return 'This email is invalid';
    }
  }

  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) || 'This email is invalid'
  );
};

export const useValidateName = () => (name) => {
  if (name === '') return true;
  return String(name).match(/^[a-zA-Z][a-zA-Z ]*$/)
    ? true
    : 'This name is invalid';
};

export const setToFocus = (ref) => {
  const targetRef = ref?.current;

  if (targetRef && typeof targetRef?.scrollIntoView === 'function') {
    targetRef?.focus();
    targetRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const findFirstErrorComponent = (errors, refMap) => {
  const firstError = Object.keys(errors).reduce((field, a) => {
    return !!errors[field] ? field : a;
  }, null);

  if (firstError) {
    setToFocus(refMap[firstError]);
  }
};

export const removeSpecialCharactersFromPhone = (phone) => {
  return phone.replace(/[()-\s]/g, '');
};
