import { createApi } from '@reduxjs/toolkit/query/react';
import { tremendousBaseQuery } from '../__base';

export const profileApi = createApi({
  name: 'profileApi',
  baseQuery: tremendousBaseQuery(),
  endpoints: (build) => ({
    createOrder: build.mutation({
      query: (body) => {
        return {
          url: `orders`,
          method: 'POST',
          body: {
            external_id: 'Clam Reward - User - Testing111',
            payment: {
              funding_source_id: 'W1XUGDO7GDI1', // From Config UI
            },
            rewards: [
              {
                campaign_id: 'SOMEIDSOMEID',
                products: [
                  'OKMHM2X2OHYV', //Amazon Gift Card
                  // "J2KFSN64KO1K",// Hotels.com AU
                  //"QM6A5K3TI1G4", //Nintendo eShop
                  //"8AUQY7C8S18Z", // adidas SE
                  // "ACGZXBCIGX8Y", //Overstock.com
                  // "L35H1ZBSUOKN" //"Outback Steakhouse"
                ],
                value: {
                  denomination: 50,
                  currency_code: 'USD',
                },
                delivery: {
                  method: 'LINK',
                },
                recipient: {
                  name: 'Test haha',
                  email: 'aldioni@gmail.com',
                  phone: '123-456-7890',
                },
              },
            ],
          },
        };
      },
      invalidatesTags: ['Payment'],
    }),
  }),
});

export const { useCreateOrderMutation } = profileApi;
