import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

import { updateTotalScore } from 'ihp-bloom-redux/features/user/userSlice';
import { useDispatch } from 'ihp-bloom-redux/app/redux';

import { InitialQuestion, PageForm } from './Components';
import CreatePregnancyJournalDayDesktop from './index.desktop';
import CreatePregnancyJournalDayMobile from './index.mobile';
import { usePregnancyJournal, useExtractDefaultValues } from './hooks.js';

const parentURL = '/tools/pregnancy-journal';

const CreatePregnancyJournalDay = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();

  const date = new Date();

  const {
    isLoading,
    isError,
    questions,
    completePregnancyJournal,
    compensationValue,
  } = usePregnancyJournal();

  const extractDefaultValues = useExtractDefaultValues(questions);
  const defaultValues = extractDefaultValues();

  const methods = useForm({ defaultValues });
  const { getValues } = methods;

  const steps = isError
    ? []
    : [
        <InitialQuestion questions={questions} />,
        <PageForm
          title='Physical symptoms'
          category='physical'
          questions={questions}
        />,
        <PageForm title='Mood' category='mood' questions={questions} />,
      ];

  const navigate = useNavigate();

  useEffect(() => {
    // Some visual error message would be nice before going back
    if (isError) navigate(parentURL);
  });

  const [step, setStep] = useState(1);
  const lastStep = steps.length;

  const handleForward = () => {
    if (step === lastStep) {
      const values = getValues();
      completePregnancyJournal(values)
        .then(() => {
          updateScore();
          navigate(parentURL);
        })
        .catch((rejected) => {
          console.error(rejected);
          navigate(parentURL);
        });
    } else {
      setStep(step + 1);
    }
  };

  const handleBackward = () => navigate(parentURL);

  const updateScore = () => {
    const taskScore = parseInt(compensationValue);
    dispatch(updateTotalScore(taskScore));
  };

  return (
    <FormProvider {...methods}>
      {upSm ? (
        <CreatePregnancyJournalDayDesktop
          date={date}
          steps={steps}
          step={step}
          lastStep={lastStep}
          isError={isError}
          isLoading={isLoading}
          handleForward={handleForward}
          handleBackward={handleBackward}
        />
      ) : (
        <CreatePregnancyJournalDayMobile
          date={date}
          steps={steps}
          step={step}
          lastStep={lastStep}
          isError={isError}
          isLoading={isLoading}
          handleForward={handleForward}
          handleBackward={handleBackward}
        />
      )}
    </FormProvider>
  );
};

export default CreatePregnancyJournalDay;
