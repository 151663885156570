import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { alpha, styled } from '@mui/system';
import PropTypes from 'prop-types';
import { useCallback } from 'react';

const SwitchGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  boxSizing: 'border-box',
  '&.MuiToggleButtonGroup-root': {
    height: '30px',
    gap: '5px',
    padding: '5px',
    background: `${theme.palette.primary.darkBlue70}`,
    color: `${theme.palette.primary.white}`,
    borderRadius: '50px',
    boxSizing: 'border-box',
    fontSize: 14,
    width: '100%', // takes the full width of the parent component
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.white,
      boxSizing: 'border-box',
      color: theme.palette.primary.darkBlue70,
      fontWeight: '700',
      '&:hover': {
        color: theme.palette.primary.darkBlue70,
        backgroundColor: theme.palette.primary.white,
      },
    },
  },
  '& .MuiToggleButtonGroup-grouped': {
    flex: 1,
    gap: '5px',
    color: `${theme.palette.primary.white}`,
    '&:not(:first-of-type)': {
      padding: '5px',
      borderRadius: '100%',
    },
    '&:first-of-type': {
      padding: '5px',
      borderRadius: '100%',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
    border: 0,
  },
}));

const SwitchButton = styled(ToggleButton)(() => ({
  width: '50%',
}));

export const Switch = ({ size = 'large', options, onChange, selected = options[0] }) => {
  const renderOptions = useCallback(
    () =>
      options.map((option) => (
        <SwitchButton key={option.value} value={option.value}>
          {option.label}
        </SwitchButton>
      )),
    [options]
  );
  return (
    <SwitchGroup size={size} value={selected.value} onChange={onChange}>
      {renderOptions()}
    </SwitchGroup>
  );
};

Switch.propTypes = {
  options: PropTypes.arrayOf({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};
