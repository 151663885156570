import { styled } from '@mui/system';
import { BaseLayout } from 'components/BaseLayout';
import { ProgressButtons } from 'components/Buttons';

export const StyledBaseLayout = styled(({ lastStep = false, ...rest }) => (
  <BaseLayout {...rest} />
))(({ theme, lastStep }) => ({
  minHeight: '100vh',
  '& > .BaseLayout-scrollable': {
    maxWidth: '720px',
    margin: 'auto',
    backgroundColor: 'transparent',
    ...(lastStep && { justifyContent: 'center' }),
    '& > .BaseLayout-content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        flex: 'none',
      },
    },
  },
}));

export const StyledProgressButtons = styled(
  ({ isLastStep = false, ...rest }) => <ProgressButtons {...rest} />
)(({ isLastStep }) => ({
  margin: '20px 25px 10px 25px',
  alignItems: 'center',
  justifyContent: `center ${isLastStep ? '!important' : ''}`,
}));
