import PropTypes from 'prop-types';
import { Text } from './styles';

const StepCounter = ({
  current,
  maximum,
  ...rest
}) => (
  <Text {...rest}>Step {current} / {maximum}</Text>
);

StepCounter.propTypes = {
  current: PropTypes.number.isRequired,
  maximum: PropTypes.number.isRequired,
};

export default StepCounter;
