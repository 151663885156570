import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { BaseHeader } from 'components/BaseLayout';
import Button from 'ihp-components/components/v2/Button';
import useScript from 'hooks/useScript';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import React, { useEffect, useState } from 'react';
import {
  getAuthToken,
  getEncodedCredentials,
  getVerifyUrl,
} from 'services/hyperwalletService';
import { Line } from '../../Payment/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import {
  SpinnerContainer,
  ButtonsContainer,
  VerifyContainer,
} from './index.styles';
import { isMobile } from 'utils/isMobile';

const HyperWalletVerify = () => {
  document.title = 'Single Ventricle SOURCE My Rewards';
  const navigate = useNavigate();
  const { user, hyperwallet } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  const addStyle = (url) => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;
    document.head.appendChild(style);
  };

  let scriptStatus = useScript(
    `https://uat.hyperwallet.com/rest/widgets/users/${user.hyperwalletToken}/en.min.js`
  );
  useScript(
    'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'
  );
  addStyle(
    'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css'
  );
  useScript(
    'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js'
  );

  useEffect(() => {
    const getAuthenticationToken = async (callback) => {
      const creds = getEncodedCredentials(
        hyperwallet.username,
        hyperwallet.password
      );
      const authTokenResponse = await getAuthToken(
        user.hyperwalletToken,
        creds
      );
      callback(JSON.parse(authTokenResponse));
    };

    const initializeUI = async () => {
      if (scriptStatus !== 'ready') {
        return;
      }

      window.HWWidgets?.events?.on('widget:loading:shown', () => {
        setIsLoading(true);
      });
      window.HWWidgets?.events?.on('widget:loading:hidden', () => {
        setIsLoading(false);
      });

      await window.HWWidgets?.initialize((onSuccess, onFailure) => {
        getAuthenticationToken((response) => {
          onSuccess(response.value);
        });
      });

      window.HWWidgets?.users
        .configure({
          template: 'bootstrap3',
          data: {},
          el: document.getElementById('VerificationUI'),
        })
        .display(() => {});

      // Example of the completed event
      window.HWWidgets?.events?.on(
        'widget:users:completed',
        (verificationObject, completionResult) => {
          // redirect payee to another page with the result, status and token
          window.location = getVerifyUrl(verificationObject, completionResult);
        }
      );
    };
    initializeUI();
  });

  const verifyDiv = <VerifyContainer id='VerificationUI'></VerifyContainer>;

  const spinner = (
    <SpinnerContainer>
      <CircularProgress color='primary' />
    </SpinnerContainer>
  );

  const header = (
    <>
      <BaseHeader to='/profile/payment/hyperwallet'>Verify Account</BaseHeader>
      <Line />
    </>
  );

  const footer = (
    <ButtonsContainer>
      <Button
        size='lg'
        variant='link'
        sx={{ minWidth: '170px' }}
        onClick={() => navigate('/my-activities')}
      >
        Cancel
      </Button>
      <Button
        startIcon={<CheckIcon width={14} height={14} />}
        type='submit'
        size='lg'
        sx={{ minWidth: '203px' }}
        onClick={() => navigate('/profile/payment/hyperwallet')}
      >
        Account verified
      </Button>
    </ButtonsContainer>
  );
  return (
    <Box>
      {isMobile() && header}
      {isLoading ? spinner : verifyDiv}
      {isMobile() && footer}
    </Box>
  );
};

export default HyperWalletVerify;
