import Check from '../../assets/svg/blue-check.svg';

export const selectStyles = {
  lineHeight: '20px',
  '& .MuiSelect-icon': {
    width: '13px',
    height: '14px',
    right: '20px !important',
  },
};

export const menuProps = (theme) => ({
  sx: {
    '& .MuiMenu-paper': {
      marginTop: '10px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.gray15,
      borderRadius: '6px',
      boxShadow: 'inherit',
      maxWidth: 0,
    },
    '& .MuiMenu-list': {
      background: theme.palette.primary.white,
      '& li': {
        padding: '15px 64px 15px 20px',
        color: theme.palette.primary.gray75,
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: '17px',
        opacity: 0.8,
        whiteSpace: 'unset',
        wordBreak: 'break-word',
      },
      '& li:hover': {
        '&:not(li.Mui-selected)': {
          background: theme.palette.primary.white,
          color: theme.palette.primary.gray100,
          opacity: 0.8,
        },
      },
      '& li.Mui-selected': {
        background: theme.palette.primary.gray2,
        color: theme.palette.primary.main,
        opacity: 1,
        backgroundImage: `url(${Check})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 21px center',
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
});
