const getStyles = (theme, error) => {
  return {
    formGroup: {
      border: `1px solid ${
        !error ? theme.palette.primary.gray25 : theme.palette.primary.red75
      }`,
      borderRadius: '8px',
      backgroundColor: theme.palette.primary.gray2,
      marginTop: '8px',
    },
    formControlLabel: {
      marginLeft: 0,
      '& .MuiCheckbox-root:hover': {
        background: 'transparent',
      },
      '& .MuiFormControlLabel-label': {
        fontSize: '14px',
        paddingLeft: '10px',
        color: theme.palette.primary.gray100,
      },
    },
    errResponseText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.red75,
      marginLeft: '15px',
      marginTop: '5px',
      fontSize: '14px',

      '& svg': {
        marginRight: '5px',
      },
    },
    formLabel: {
      color: theme.palette.primary.gray75,
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '140%',
      '&.Mui-focused': {
        color: theme.palette.primary.gray75,
      },
    },
  };
};

export default getStyles;
