import { styled } from '@mui/material/styles';
import { StyledButton } from './Button'; 

export const sizePaddingMap = {
  large: '15px',
  medium: '14px',
  small: '13px'
};

export const sizeWidthMap = {
  large: '50px',
  medium: '46px',
  small: '42px'
};

export const sizeIconSizeMap = {
  large: '20px',
  medium: '18px',
  small: '16px'
};

export const StyledIconButton = styled(StyledButton)(({ size }) => ({
  padding: 'unset',
  width: sizeWidthMap[size],
  height: sizeWidthMap[size],
  minWidth: sizeWidthMap[size],
  '& svg':  {
    width: sizeIconSizeMap[size],
    height: sizeIconSizeMap[size]
  }
}));