import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
});

export const Left = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 1 425px',
  overflow: 'auto',
  boxShadow: '0px 0px 9px rgb(0 0 0 / 4%)',
  [theme.breakpoints.up('sm')]: {
    minWidth: '400px',
    maxWidth: '500px',
  },
}));

export const Right = styled(Box)(({ theme }) => ({
  height: '100%',
  flex: '1',
  overflow: 'auto',
  [theme.breakpoints.up('sm')]: {
    minWidth: '750px',
  },
}));
