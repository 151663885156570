const getStyles = (theme, hasErrors) => {
  const borderColor = hasErrors
    ? theme.palette.primary.red70
    : theme.palette.primary.gray15;

  return {
    '& .MuiOutlinedInput-root': {
      '& > fieldset': { borderColor },
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': { borderColor: theme.palette.primary.main },
    },
    '& .MuiAutocomplete-endAdornment': {
      marginRight: '13px',
      marginTop: '2px',
      '& .MuiAutocomplete-popupIndicator': {
        width: '17px',
        height: '17px',
      },
      '& .MuiAutocomplete-clearIndicator': {
        padding: '0px',
        marginRight: '2px',
      },
    },
  };
};

export default getStyles;
