export function sortActivities(activities = []) {
  if (!Array.isArray(activities)) {
    return [];
  }

  const sortedActivities = activities.filter((a) => a?.code !== 'FEEDBACK');
  const feedbackSurveyActivity = activities.find((a) => a?.code === 'FEEDBACK');

  if (feedbackSurveyActivity) {
    sortedActivities.push(feedbackSurveyActivity);
  }

  return sortedActivities;
}
