import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: 'white',
  paddingTop: '15px',
  paddingLeft: '15px',
  borderRadius: '8px',
  cursor: 'pointer',
});

export const Icon = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '15px',
});

export const FlexedBox = styled(Box)({
  display: 'flex',
});

export const IconContainer = styled(Box)({
  marginLeft: '10px',
});

export const Content = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '-15px',
  background: 'transparent',
  padding: '0 15px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
});

const FontBase = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '1.5rem',
});

export const Title = styled(FontBase)(({ theme }) => ({
  fontSize: '13px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  color: theme.palette.secondary.gray100,
}));

export const Count = styled(FontBase)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  marginTop: '-3px',
  color: theme.palette.primary.gray50,
}));

export const Value = styled(FontBase)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.primary.gray75,
}));

export const ContainerList = styled(Box)({
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  gap: '12px',
});
