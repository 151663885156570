import { INEGIBILITY_REASONS } from 'pages/Onboarding/BasicInformation/constants';

export function isInvalidBestDescribeOptions(inegibilityReasons = []) {
  return inegibilityReasons.includes(INEGIBILITY_REASONS.BEST_DESCRIBE_OPTIONS);
}

export function isInvalidCountry(inegibilityReasons = []) {
  return inegibilityReasons.includes(INEGIBILITY_REASONS.ELIGIBLE_COUNTRY);
}

export function isUnderAge(inegibilityReasons = []) {
  return inegibilityReasons.includes(INEGIBILITY_REASONS.UNDER_AGE);
}
