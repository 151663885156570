import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllProfileParticipantTasksQuery } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { setParticipantTasks } from 'ihp-bloom-redux/features/tasks/tasksSlice';

import {
  categorizeCurrentActivities,
  filterParticipantTasks,
  formatParticipantTasks,
} from 'utils/tasks';
import { EmptyList } from '../Empty';
import { ActivitiesDateHeader } from '../styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import { isFilterApplied } from 'pages/Activities/utils';
import { useNavigate } from 'react-router-dom';
import { requiredActivities } from '../util';

function countGreaterThanZero(arr) {
  return arr.length > 0 ? 1 : 0;
}

function CurrentActivities({
  personId,
  studyRoleId,
  renderActivities,
  filtersConfig,
  maxVisible,
  isMobile,
  onViewMore,
  disableLoadMore,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedTypes = [], startDate, endDate } = filtersConfig;
  const taskTypes =
    selectedTypes[0] === 'show_all' ? null : selectedTypes.join(',');
  const [tasks, setTasks] = useState([]);
  const [prevFilters, setPrevFilters] = useState({ ...filtersConfig });
  const [pageNum, setPageNum] = useState(1);
  const isFiltersUpdated =
    JSON.stringify(filtersConfig) !== JSON.stringify(prevFilters);
  const [activitiesList, setActivitiesList] = useState({
    dueImmediately: [],
    dueThisWeek: [],
    otherActivities: [],
    overdue: [],
  });
  const { activeAccountProfile } = useSelector((state) => state.user);
  const { taskCategory } = useSelector((state) => state.participantTask);
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const query = {
    display_status: 'active,overdue',
    task_type: taskTypes,
    'end_date[from]': startDate,
    'end_date[to]': endDate,
    page: isFiltersUpdated ? 1 : pageNum,
  };

  const {
    data: response,
    isFetching,
    isLoading,
    refetch,
  } = useGetAllProfileParticipantTasksQuery(
    {
      accountProfileId,
      query: { ...query },
    },
    {
      skip: personId === undefined || studyRoleId === undefined,
    }
  );

  //required activities completed routing
  useEffect(()=>{
    const requiredActivitiesCompleted = requiredActivities(response?.data)?.length;
    if(requiredActivitiesCompleted && 
      !isFetching && 
      !sessionStorage.getItem('isRequiredActivitiesCompleted')
    ){
      sessionStorage.setItem('isRequiredActivitiesCompleted','false');
    }else if(
        sessionStorage.getItem('isRequiredActivitiesCompleted')==='false' && 
        requiredActivitiesCompleted === 0
      ){
        sessionStorage.setItem('isRequiredActivitiesCompleted', 'true');
        navigate('/home')
      }
  },[response?.data, isFetching])

  useEffect(()=>{
    if(sessionStorage.getItem('isFirstTimeLogin') !== 'false')
      sessionStorage.setItem('isFirstTimeLogin', 'true')
  },[])

  //intial render routing
  useEffect(()=>{
    if (response?.data?.length) {
      const requiredActivitiesCompleted = requiredActivities(response.data)?.length;
      if(!requiredActivitiesCompleted && sessionStorage.getItem('isFirstTimeLogin') === 'true'){
        sessionStorage.setItem('isRequiredActivitiesCompleted', 'true');
        navigate('/home');
      }
      sessionStorage.setItem('isFirstTimeLogin', 'false');
    }else if(response?.data?.length === 0 && 
            !isFetching && 
            (
              sessionStorage.getItem('isFirstTimeLogin') === 'true' ||
              !sessionStorage.getItem('isRequiredActivitiesCompleted')
            )
    ){
      navigate('/home');
      sessionStorage.setItem('isRequiredActivitiesCompleted','true');
      sessionStorage.setItem('isFirstTimeLogin', 'false')
    }
  },[response?.data, isFetching])


  useEffect(() => {
    if (!isFetching && !isLoading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response?.data?.length) {
      let tsks = filterParticipantTasks(response.data);
      tsks = formatParticipantTasks(tsks, true);
      /*tsks = tsks?.sort(
        (a, b) => (a?.due_date > b?.due_date) - (a?.due_date < b?.due_date)
      );*/
      dispatch(setParticipantTasks(tsks));
      setTasks(tsks);
      // Group tasks if available
      if (tsks?.length) {
        const result = categorizeCurrentActivities(tsks);
        const newList =
          pageNum === 1
            ? {
              dueImmediately: [...result?.dueImmediately],
              dueThisWeek: [...result?.dueThisWeek],
              otherActivities: [...result?.otherActivities],
              overdue: [...result?.overdue],
            }
            : {
              dueImmediately: [
                ...(activitiesList?.dueImmediately ?? []),
                ...(result?.dueImmediately ?? []),
              ],
              dueThisWeek: [
                ...(activitiesList?.dueThisWeek ?? []),
                ...(result.dueThisWeek ?? []),
              ],
              otherActivities: [
                ...(activitiesList?.otherActivities ?? []),
                ...(result?.otherActivities ?? []),
              ],
              overdue: [
                ...(activitiesList?.overdue ?? []),
                ...(result?.overdue ?? []),
              ],
            };

        /* 
          Below logic will make sure in "dueImmediately" tasks with "display_status=blocking" appears on top
        */

        if (newList?.dueImmediately?.length) {
          newList?.dueImmediately?.sort(function customSort(a, b) {
            if (
              a.displayStatus === 'blocking' &&
              b.displayStatus !== 'blocking'
            ) {
              return -1; // "blocking" comes before other statuses
            } else if (
              a.displayStatus !== 'blocking' &&
              b.displayStatus === 'blocking'
            ) {
              return 1; // Other statuses come after "blocking"
            } else {
              return 0; // No change in order
            }
          });
        }
        setActivitiesList(newList);
      }
    } else {
      setActivitiesList([]);
    }
  }, [response?.data]);

  useEffect(() => {
    if (JSON.stringify(filtersConfig) !== JSON.stringify(prevFilters)) {
      setPageNum(1);
      setPrevFilters(filtersConfig);
    }
  }, [filtersConfig]);

  let isEmpty = tasks?.length === 0;
  if (
    Object.keys(activitiesList).length === 0 ||
    (activitiesList.dueImmediately?.length === 0 &&
      activitiesList.dueThisWeek?.length === 0 &&
      activitiesList.otherActivities?.length === 0 &&
      activitiesList.overdue?.length === 0)
  ) {
    isEmpty = true;
  } else {
    isEmpty = false;
  }

  function isScrollAtBottom(element) {
    return element.scrollHeight - element.scrollTop === element.clientHeight;
  }

  function handleScroll() {
    if (disableLoadMore) {
      return;
    }

    const totalPages = Math.ceil(
      response?.meta?.total / response?.meta?.per_page
    );
    const elem = document.getElementById('page-main-content');

    if (isScrollAtBottom(elem) && pageNum + 1 <= totalPages) {
      setPageNum(pageNum + 1);
    }
  }

  useEffect(() => {
    const elem = document.getElementById('page-main-content');
    elem.addEventListener('scroll', handleScroll);
    return () => elem.removeEventListener('scroll', handleScroll);
  }, [tasks]);

  if (isEmpty || response === undefined) {
    return (
      <>
        {isMobile && <Typography variant='h4'>Current Activities</Typography>}
        <EmptyList
          isLoading={isFetching || response === undefined}
          filterApplied={isFilterApplied(filtersConfig)}
        />
      </>
    );
  }

  const allActivities = [
    ...(activitiesList.dueImmediately?.length > 0
      ? activitiesList.dueImmediately
      : []),
    ...(activitiesList.dueThisWeek?.length > 0
      ? activitiesList.dueThisWeek
      : []),
    ...(activitiesList.otherActivities?.length > 0
      ? activitiesList.otherActivities
      : []),
  ].slice(0, maxVisible);

  if (isMobile && maxVisible) {
    const activities =
      activitiesList?.overdue?.length > 0
        ? activitiesList.overdue
        : allActivities;
    const newMaxVisible =
      activitiesList?.overdue?.length > 0
        ? activitiesList.overdue.length
        : maxVisible;

    return (
      <Box display={'flex'} flexDirection={'column'}>
        {isMobile && (
          <Typography mb={'20px'} variant='h4'>
            Current Activities
          </Typography>
        )}
        {renderActivities(activities, isFetching, newMaxVisible)}
        {/* <Box width={'100%'}>
          <Button
            onClick={() => onViewMore('CURRENT')}
            fullWidth
            endIcon={<ArrowForwardIcon width='14px' height='14px' />}
          >
            View All Current
          </Button>
        </Box> */}
      </Box>
    );
  }
  const totalSectionsWithResult =
    countGreaterThanZero(activitiesList.dueImmediately) +
    countGreaterThanZero(activitiesList.dueThisWeek) +
    countGreaterThanZero(activitiesList.otherActivities);

  return (
    <>
      {isMobile && maxVisible && (
        <Typography variant='h4'>Current Activities</Typography>
      )}
      {activitiesList.dueImmediately?.length > 0 && (
        <>
          {totalSectionsWithResult > 1 && (
            <ActivitiesDateHeader>Due immediately</ActivitiesDateHeader>
          )}
          {renderActivities(
            activitiesList.dueImmediately,
            isFetching && pageNum === 1
          )}
        </>
      )}

      {activitiesList.dueThisWeek?.length > 0 && (
        <>
          {totalSectionsWithResult > 1 && (
            <ActivitiesDateHeader>Due this week</ActivitiesDateHeader>
          )}
          {renderActivities(
            activitiesList.dueThisWeek,
            isFetching && pageNum === 1
          )}
        </>
      )}

      {activitiesList.otherActivities?.length > 0 && (
        <>
          {totalSectionsWithResult > 1 && (
            <ActivitiesDateHeader>
              Other Current Activities
            </ActivitiesDateHeader>
          )}
          {renderActivities(
            activitiesList.otherActivities,
            isFetching && pageNum === 1
          )}
        </>
      )}
      {/* This loader is for pagination section only*/}
      {isFetching && pageNum > 1 && (
        <CircularProgress sx={{ margin: '50px auto' }} color='primary' />
      )}
    </>
  );
}

CurrentActivities.propTypes = {
  personId: PropTypes.number,
  studyRoleId: PropTypes.number,
  renderActivities: PropTypes.func,
};

export default CurrentActivities;
