import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Chip from 'ihp-components/components/v2/Chip';

import { BasicCardMobile } from './BasicCardMobile';
import { isMobile } from 'utils/isMobile';
import { mapTaskRequiredStatus } from 'utils/tasks';
import { daysBetweenDates, months } from 'utils/date';
import {
  DescriptionStyle,
  OverdueButtonsStyle,
  StyledAvatar,
  StyledCard,
  StyledCardBottom,
  StyledCardContent,
  LockIconStyle,
  TaskGroupInfo,
} from './BasicCard.styles';
import { formatDate } from 'pages/NotificationCenter/PregnancyJournal/Detailed/utils';
import { formatCallDetails } from 'pages/Activities/utils';
import { ReactComponent as GrayCircleIcon } from 'images/Task/gray-circle.svg';
import { ReactComponent as LockIcon } from 'images/activity/types/lock.svg';
import { ReactComponent as ClockSmallIcon } from 'images/shared/clock-small.svg';
import { ReactComponent as AlertDiamondIcon } from 'images/shared/alert-diamond.svg';
import { ReactComponent as CheckCircleSolidIcon } from 'images/shared/check-1.svg';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';

export const BasicCard = (props) => {
  const {
    children,
    icon,
    iconBgColor,
    title,
    description,
    sx,
    currentIndex,
    hideNumbers,
    status,
    statusColor,
    color,
    details,
    isTaskGroup,
    subtasksLength,
    completedSubtasks,
    type,
    points,
    label,
    ...rest
  } = props;

  const { activeAccountProfile, person } = useSelector((state) => state.user);

  if (isMobile()) {
    return (
      <BasicCardMobile
        {...props}
        points={points}
        label={label}
        details={details}
      />
    );
  }

  const required = mapTaskRequiredStatus(details?.required);
  const isOverdue = details?.isOverdue;
  const isPast = details?.isPast;
  const isAwaitingVerification = details?.isAwaitingVerification;
  const isConsent = type === 'consent';
  const isCall = type === 'call';
  const isDeclinedConsent = isConsent && details?.status === 'rejected';
  const isSignedConsent = isConsent && details?.status === 'completed';
  const isExpired = details?.status === 'expired';
  const isCompleted = details?.status === 'completed';
  const showRemainingLabel =
    !isOverdue &&
    !isCompleted &&
    details?.remaining !== null &&
    details?.daysUntilDueDate !== null &&
    details?.daysUntilDueDate !== undefined &&
    !isNaN(details?.daysUntilDueDate);
  const isLocked = details?.isLocked;
  const days = daysBetweenDates(new Date(), new Date(details?.date));
  let consentText = null;
  if (isSignedConsent) {
    consentText = 'SIGNED ';
  }
  if (isDeclinedConsent) {
    consentText = 'DECLINED ';
  }

  const firstName = activeAccountProfile?.attributes?.profile?.first_name;
  const lastName = activeAccountProfile?.attributes?.profile?.last_name;

  const desktopDetails = isLocked ? (
    <Box sx={OverdueButtonsStyle}>
      {isAwaitingVerification ? (
        <Chip icon={<ClockSmallIcon />} variant='contained' type='primary'>
          Awaiting Verification
        </Chip>
      ) : (
        <Chip icon={<AlertDiamondIcon />} variant='filled' type='info2'>
          {`UNLOCKS IN ${days} DAYS`}
        </Chip>
      )}
      {required === 'REQUIRED' && (
        <Chip icon={<AlertDiamondIcon />} variant='bordered' type='secondary'>
          {required}
        </Chip>
      )}
    </Box>
  ) : (
    // TODO: Implement a proper mapping solution with the respective icon and label.
    <Box sx={OverdueButtonsStyle}>
      {isPast && !isCompleted && (
        <Chip icon={<ClockSmallIcon />} variant='filled' type='primary'>
          {!isCompleted ? 'NON-COMPLIANT' : details?.remaining?.label}
        </Chip>
      )}

      {!isPast && isOverdue && (
        <Chip icon={<ClockSmallIcon />} variant='filled' type='primary'>
          Overdue
        </Chip>
      )}

      {!isPast && showRemainingLabel && (
        <Chip icon={<ClockSmallIcon />} variant='contained' type='primary'>
          {details?.remaining?.label}
        </Chip>
      )}

      {isCompleted && (
        <Chip
          icon={<CheckCircleSolidIcon width={'10px'} height='10px' />}
          variant='outlined'
          type='info'
        >
          {'COMPLETED ' + formatDate(details?.statusDate, 'MM-dd-yyyy')}
        </Chip>
      )}
      {(isDeclinedConsent || isSignedConsent) && (
        <Chip icon={<ClockSmallIcon />} variant='filled' type='secondary'>
          {consentText + formatDate(details?.statusDate, 'MM-dd-yyyy')}
        </Chip>
      )}

      {required === 'REQUIRED' && (
        <Chip icon={<AlertDiamondIcon />} variant='bordered' type='secondary'>
          {required}
        </Chip>
      )}
    </Box>
  );
  const isDifferentPerson = person?.id !== details?.activityPersonId;

  let typeMessage = '';
  if (isDifferentPerson) {
    const roleName =
      activeAccountProfile?.attributes?.profile?.study_arm_role_name;

    const isLar = roleName === STUDY_ARM_ROLE_NAME.LAR;
    if (isLar) {
      typeMessage = `| On behalf of ${firstName} ${lastName}`;
    }

    const isReporter = roleName === STUDY_ARM_ROLE_NAME.REPORTER;
    if (isReporter) {
      typeMessage = `| Related to ${firstName} ${lastName}`;
    }
  }

  const formattedData = formatCallDetails(
    {
      startDate: details?.statusDateFull,
    },
    details?.timezone
  );

  function capitalizeFirstLetter(input) {
    if (input === null || input === undefined || input?.length === 0) {
      return '';
    }
    return input?.charAt(0).toUpperCase() + input?.slice(1);
  }

  function reformatDate(date) {
    if (date === null || date === undefined || date?.length === 0) {
      return '';
    }
    let parts = date?.split('.').map((p) => parseInt(p));
    let monthString = months[parts[0] - 1];
    return `${monthString} ${parts[1]}, ${parts[2]}`;
  }
  const hasDateTime =
    formattedData?.date != null &&
    formattedData?.dayString != null &&
    formattedData?.time;

  return (
    <StyledCard variant='outlined' sx={sx} {...rest}>
      <Box sx={{ display: 'flex', gap: '25px', flex: 1 }}>
        <Box>
          {details?.isLocked && (
            <Box sx={LockIconStyle}>
              <LockIcon />
            </Box>
          )}
          <StyledAvatar
            iconBgColor={iconBgColor}
            isLocked={isLocked}
            aria-label='recipe'
          >
            {icon}
          </StyledAvatar>
        </Box>
        <StyledCardContent>
          <Typography variant='h5' color={sx?.color || 'primary.gray100'}>
            {title}
          </Typography>
          <Typography
            variant='pn4'
            fontStyle={'Italic'}
            color={'primary.darkGray70'}
          >
            <Box sx={{ display: 'flex' }}>
              <Box>{details?.type?.label}</Box>
              {isDifferentPerson && (
                <Box sx={{ fontStyle: 'normal', marginLeft: '5px' }}>
                  {typeMessage}
                </Box>
              )}
            </Box>
          </Typography>
          {/* SING-656 */}
          {/* {isConsent && (
            <>
              <Typography
                variant='pn4'
                color={sx?.color || 'primary.darkGray70'}
              >
                {`Version no. ${details?.consentVersion}`}
              </Typography>
              {details?.consentVersionDate && (
                <Typography
                  variant='pn4'
                  color={sx?.color || 'primary.darkGray70'}
                >
                  {'Version date: ' +
                    format(
                      new Date(details.consentVersionDate),
                      'MMMM d, yyyy'
                    )}
                </Typography>
              )}
            </>
          )} */}
          {isCall && hasDateTime && (
            <>
              <Typography variant='pn4' color={sx?.color || 'primary.gray100'}>
                {`Day: ${capitalizeFirstLetter(
                  formattedData?.dayString
                )}, ${reformatDate(formattedData?.date)}`}
              </Typography>
              <Typography variant='pn4' color={sx?.color || 'primary.gray100'}>
                {`Time: ${formattedData?.time} ${details?.timezone}`}
              </Typography>
            </>
          )}
          <Typography
            variant='pl1'
            sx={DescriptionStyle}
            color='primary.darkGray70'
          >
            {description}
          </Typography>
          {isTaskGroup && (
            <TaskGroupInfo>
              {completedSubtasks}/{subtasksLength}
              <GrayCircleIcon width={15} style={{ margin: '3' }} />
              Task group
            </TaskGroupInfo>
          )}
          {!isTaskGroup && <>{!isMobile() && desktopDetails}</>}
        </StyledCardContent>
      </Box>
      <StyledCardBottom>{children}</StyledCardBottom>
    </StyledCard>
  );
};

BasicCard.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  iconBgColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  currentIndex: PropTypes.number,
  hideNumbers: PropTypes.bool,
  isTaskGroup: PropTypes.bool,
  subtasksLength: PropTypes.number,
  completedSubtasks: PropTypes.number,
};
