import { forwardRef, useState } from 'react';

import { TextInput } from 'components/Input';
import { Card } from '../Common/Card';

const TextCardImpl = forwardRef(({
  title,
  subtitle,
  onChange,
  value,
  placeholder,
  multiline,
  rows,
  ...rest
}, ref) => (
  <Card title={title} subtitle={subtitle} value={value} {...rest}>
    <TextInput
      ref={ref}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      sx={{ width: '100%' }}
    />
  </Card>
));

export const TextCard = forwardRef(({
  title,
  subtitle,
  value,
  onChange,
  defaultValue,
  placeholder,
  multiline = false,
  rows,
  ...rest
}, ref) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  const handleChange = (_, value) => onChange
    ? onChange(_, value)
    : setInternalValue(value);

  return <TextCardImpl
    ref={ref}
    title={title}
    subtitle={subtitle}
    onChange={handleChange}
    value={onChange ? value : internalValue}
    placeholder={placeholder}
    multiline={multiline}
    rows={rows}
    {...rest}
  />;
});
