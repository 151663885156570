export const StudyAchievements = [
  {
    name: 'Games',
    type: 'Games',
    points: '45',
    totalPoints: '50',
  },
  {
    name: 'Surveys',
    type: 'survey',
    points: '12',
    totalPoints: '50',
  },
  {
    name: 'Health data',
    type: 'HealthData',
    points: '29',
    totalPoints: '50',
  },
  {
    name: 'Videos',
    type: 'Videos',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Devices',
    type: 'Devices',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Follow-ups',
    type: 'FollowUps',
    points: '7',
    totalPoints: '50',
  },
  {
    name: 'Lab tests',
    type: 'LabTests',
    points: '5',
    totalPoints: '50',
  },
  {
    name: 'Phase',
    type: 'Polls',
    points: '0',
    totalPoints: '50',
  },
];

export const COMPLETED = 'COMPLETED';
export const UPCOMING = 'UPCOMING';
export const AVAILABLE = 'AVAILABLE';

export const OverviewMainTitle = `<p style="font-size: 16px; font-weight: 600; line-height: 150%; color: #444444; margin-bottom: 15px;"><b>The SV Source study is a pregnancy safety study for the MS medication Alduretin.</b></p>
<p>This study collects information only, and does not provide treatment or management for MS or pregnancy. Your
doctor will manage your care no differently than if you were not taking part in this study. You will be asked to
participate in the study for period of four years, starting during your pregnancy, as long as the study is active. The
end of the study is currently planned for May 2033.</p>`;

export const OverviewFirstSubtitle = (
  <>
    {/* This research study aims to learn about how the immune system affects
    different health conditions.
    <br />
    <br />
    The main part of this study lasts seven years. You will have an opportunity
    to earn $700 ($100 per year) in gift cards over the course of the study.
    Participants can earn additional rewards for continuing in the study longer.
    <br />
    <br />
    Eligibility is based on a selected set of criteria. You will need to answer
    some questions to help us determine if you are eligible to participate. It
    is possible that we may determine that you are not eligible after you have
    signed the informed consent. */}
  </>
);

export const OverviewTimelineDescription = `Scroll down to view the timeline of activities that will earn points toward
   your reward gift cards.`;

export const StudyOverviewData = [
  { id: 'games', title: 'Games', type: 'Games', pointsPerWeek: '10' },
  { id: 'surveys', title: 'Surveys', type: 'survey', pointsPerWeek: '5' },
  {
    id: 'healthData',
    title: 'HealthData',
    type: 'HealthData',
    pointsPerWeek: '1',
  },
];
