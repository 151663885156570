import { useNavigate, useLocation } from 'react-router-dom';
import { RadioGroup } from '@mui/material';

export const NavigatorGroup = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEMR = location.pathname === `/activity/sync-data`;

  const handleChange = (_, newValue) => {
    if (!isEMR) return navigate(newValue);
    const shouldNavigate = props.shouldNavigate();
    if (shouldNavigate) return navigate(newValue);
  };
  return (
    <RadioGroup value={location.pathname} onChange={handleChange} {...props} />
  );
};
