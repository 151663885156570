import { styled, FormHelperText } from '@mui/material';

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red70,
  marginLeft: '0px',
  marginTop: '8px',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '19.6px',
  gap: '5px',
}));
