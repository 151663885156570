// import { StyledLink } from './styles';
import { ADDITIONAL_VENTURE_LINK } from 'constants/global';
import { SVSOURCE_CONTACT } from 'utils/constants';

export const faqContent = {
  documentTitle: 'Single Ventricle SOURCE FAQs',
  pageTitle: 'Most frequently asked questions'
};

export const mockFAQ = {
  'About single ventricle heart defects': [
    {
      id: 1,
      title: 'What is a single ventricle heart defect?',
      description: `Single ventricle heart defects are some of the most complex congenital heart defects. </br></br> Before a baby is born, the first organ to grow is the heart. In most hearts, there are four chambers. Two are atria, and the other two are ventricles. In some people, one ventricle doesn’t grow fully and may be smaller. It may not develop all the way, or it may be missing an important piece. When any of these things happen, it is called a single ventricle heart defect. </br></br> There are many types of single ventricle heart defects. The most common are: </br></br>
            <ul style="padding-left: 60px">
                <li>Hypoplastic Left Heart Syndrome (HLHS) </li>
                <li>Hypoplastic Right Heart (sometimes called HRHS) </li>
                <li>Tricuspid Atresia </li>
                <li>Double Inlet Left Ventricle (DILV) </li>
                <li>Double Outlet Right Ventricle (DORV) – some types </li>
                <li>Ebstein’s Anomaly </li>
                <li>Single Left Ventricle </li>
                <li>Pulmonary Atresia with Intact Ventricular Septum (PA-IVS) – some types </li>
                <li>Unbalanced Atrioventricular (AV) Canal </li>
                <li>Other complex heart defects leading to a Fontan and/or Glenn operation and a single functioning pumping chamber</li>
            </ul>`,
    },
    {
      id: 2,
      title: 'How are single ventricle heart defects treated?',
      description:
        'Each patient is unique, but right now, most people born with a single ventricle heart defect will need several heart surgeries. The last of these surgeries is usually the Fontan procedure. These surgeries are done to make a new circulatory system within the body. This allows blood to flow to all parts of the body and bring more oxygen from the lungs, but many patients end up with other health problems even after surgery. These problems can be minor or life-threatening.',
    },
  ],
  'About SOURCE': [
    {
      id: 1,
      title: `What is the purpose of SOURCE?`,
      description: `This study’s purpose is to look at the genes involved in single ventricle heart defects so that scientists can find and better understand the causes of single ventricle heart defects and the related health issues many patients develop. As part of this effort, SOURCE collects DNA from people with single ventricle heart defects and from their families who may or may not have single ventricle heart defects. We also collect information about their health and things that can affect health, like where people live. 
            </br></br>
        To succeed, SOURCE needs thousands of people with single ventricle and their families to join. What we collect and learn will be shared with many single ventricle researchers to help speed up the progress of research and lead to better treatments, and maybe one day, a cure.
        `,
    },
    {
      id: 2,
      title: 'Why should I take part in SOURCE?',
      description: `By participating in SOURCE, you will help shape the future of single ventricle research. Your DNA could be the source of the next genetic discovery!
        </br></br>
        Scientists are trying to find out why some people’s hearts grow this way, but they don’t yet have a full understanding. With DNA from thousands of families across the country, we will be able to learn more about genes that may be related to single ventricle and discover new ones. There are also many additional health problems that can happen to those with a single ventricle heart, such as problems in the liver and in other organs in the body, and we can learn about these as well.
        </br></br>
        Understanding these genes can help scientists develop new treatments to prevent, treat, or even cure single ventricle heart defects and the common conditions that occur in those patients. 
        </br></br>
        In return, you will be able to get updates on the latest research and power single ventricle research for years to come.
        `,
    },
    {
      id: 3,
      title: 'Who can take part in SOURCE?',
      description: `The study is open to anyone living in the United States who:
            </br></br>
            <ul style="padding-left: 60px">
            <li>Has been diagnosed with a single ventricle heart defect. The person can be any age. They can be at any stage in their patient journey (pre-Fontan, post-Fontan, or post-heart transplant).</li>
            <li>Is the biological (birth) parent or sibling of someone living with a single ventricle heart defect.</li>
            <li>Is the biological (birth) child of someone living with a single ventricle heart defect.</li>
            </ul></br>
            Children who are minors must have consent from a parent or guardian. Adults not able to consent themselves due to physical or cognitive disability must have consent from a legally authorized representative (such as a guardian). 
            `,
    },
    {
      id: 4,
      title:
        'Can I or my child take part in SOURCE if we already had genetic testing? What If the testing was negative?',
      description: `Yes, you can take part even if you’ve had genetic testing and no matter what the results were. We don’t yet know what causes single ventricle, so we still need your help to learn more.`,
    },
    {
      id: 5,
      title:
        'Can I enroll in SOURCE if I have participated in research or shared my DNA sample in other studies?',
      description: `Yes. Families who have already completed clinical genetic testing are encouraged to participate in and complete our sample collection process if they are eligible for our study.`,
    },
    {
      id: 6,
      title:
        'How could learning about the genetic causes of single ventricle heart defects help people who are already living with one?',
      description: `
        Researchers know that single ventricle heart defects may be caused by more than one thing, including a person’s genetics. There are likely many genes involved in these defects, and while a small number of these genes have been linked to single ventricle, large studies like SOURCE can help find others. By studying the DNA and medical histories of people with single ventricle heart defects and looking at the DNA of their close family members, researchers may be able to better understand the causes of these heart defects. 
        </br></br>
        Learning about the genetic causes of single ventricle heart defects could help researchers find potential treatments and preventive measures for things that are common for people with single ventricle heart defects (such as ventricular failure, liver disease, and plastic bronchitis, among others). 
        </br></br>
        We may even see this research support the development of cures.
        </br></br>
        Because every person is unique, some treatments may work well for one person while other treatments may work better for someone else. Understanding the genetic and other causes of single ventricle heart defects will help personalize treatment options in this way.
        </br></br>
        Better understanding about the causes of single ventricle heart defects may also eventually help identify what babies are at risk of developing these heart defects and develop treatments to prevent these defects.
        `,
    },
    {
      id: 7,
      title:
        'How can participating in SOURCE help people with single ventricle heart defects?',
      description: `
        By studying DNA and medical histories of people with these diseases and looking at the DNA of entire families, researchers may be able to learn what causes single ventricle heart defects. And that could lead to better treatments.
        </br></br>
        Researchers know that these defects may be caused by more than one thing, including a person’s genetics. But they don’t understand which genes or combination of genes are responsible. SOURCE will provide the data researchers need to understand what causes single ventricle heart defects.
        </br></br>
        This knowledge can help future patients in two ways:</br></br>
        <ul style="padding-left: 60px">
        <li>Identifying what babies are at risk of developing these heart defects.</li>
        <li>Developing treatments that will prevent these defects. </li>
        </ul>
        </br>
        And this information can help patients living now with single ventricle heart defects by:</br></br>
        <ul style="padding-left: 60px">
        <li>Understanding why some patients develop other health conditions like liver disease and plastic bronchitis so those patients can receive personalized therapy.</li>
        <li>Developing new and better treatments to improve outcomes by targeting the root cause of the disease.</li>
        <li>Potentially developing cures for single ventricle heart defects.</li>
        </ul>
        `,
    },
    {
      id: 8,
      title:
        'Will you find new treatments for people with single ventricle heart defects?',
      description: `Results from SOURCE researchers could be the first step to help find new treatments or help decide which treatments to use for which patients. `,
    },
    {
      id: 9,
      title:
        'How long will it take for researchers to find the cause of single ventricle heart defects?',
      description: `We don’t know how long it will take to find the answers we hope to uncover. That’s why we don’t have an end date for SOURCE right now.`,
    },
    {
      id: 10,
      title: 'Who pays for SOURCE?',
      description: `
          <a href="${ADDITIONAL_VENTURE_LINK}">Additional Ventures</a>, a non-profit foundation,
          is the sole funder of SOURCE. Additional Ventures does not make money
          (a profit) for its work on SOURCE. You will not receive any payment
          for participating in SOURCE, and participating in SOURCE will not cost
          you any money.`,
    },
    {
      id: 11,
      title:
        'Who oversees SOURCE?',
      description: `SOURCE has a study protocol (a description of the study and how it will do everything related to the study) that has been approved by an Institutional Review Board called WCG. An Institutional Review Board (IRB) is a group that reviews research involving human subjects to make sure that it is done ethically and safely. The IRB has also reviewed and approved all of SOURCE's consent forms, website content, and recruitment materials. SOURCE has a Principal Investigator who works for Additional Ventures who is responsible for ensuring that the study is conducted according to its IRB-approved protocol and consents.`,
    },
  ],
  'What to expect': [
    {
      id: 1,
      title: 'What happens when I enroll in SOURCE?',
      description: `First, you will create an account on our webpage. Next, you’ll sign consents to participate in the study, and then if you are a person living with a single ventricle, you’ll be asked to share your heart-related medical records. You will also fill out a short survey to help us learn a bit more about you and your medical history. 
        </br></br>
        We will send you a DNA collection kit in the mail. We are collecting DNA samples from people enrolled in the study and using these samples to look at their genes. These samples are collected by swabbing the inside of your cheek – it’s quick and easy and you can do it yourself in the comfort of your own home. 
        </br></br>
        We put this genetic and medical information together to study how your genes relate to your heart defect and other related conditions.
        `,
    },
    {
      id: 2,
      title:
        'Why do I need to provide me medical records and how will they be used? ',
      description: `We are asking people with single ventricle heart defects to sign a medical record release form and share their relevant medical records about their heart so that we may pair their medical information (specific to their single ventricle defect) with the DNA in their saliva. The decision to collect and pair these pieces together was based on guidance from SOURCE’s scientific advisors, as they believe this combination will provide researchers with stronger data for analysis.
        </br></br>
        Information from the medical record that is of interest to the study includes a detailed pre-Fontan heart anatomy and any history of single ventricle-related health problems.
        </br></br>
        Researchers do not have access to the actual medical records or any identifiable information. Only approved study staff and the SOURCE research staff at Boston Children’s Hospital have access to the medical records. 
        `,
    },
    {
      id: 3,
      title: 'How will my DNA be collected?',
      description:
        'We will send you a sample collection kit in the mail. Be sure to read the instructions in the box. All you need to do is swab the inside of your cheek – it’s quick and easy! The box will have an envelope inside with a prepaid return label already on it.',
    },
    {
      id: 4,
      title: 'How do I mail back my sample collection kit?',
      description:
        'The kits include an envelope with a prepaid return label attached, and you can mail the kit at a U.S. post office near you. There will be no cost to you to ship it back.',
    },
    {
      id: 5,
      title: 'Why are family members asked to participate?',
      description: `The causes of single ventricle heart defects are complex. Looking at the DNA of family members may help. This may show genetic differences that lead to single ventricle heart defects within the family. What we learn may help us find causes for single ventricle heart defects. That might help us develop better treatments.
            </br></br>
            We are more likely to discover information about single ventricle heart defects if we can compare the DNA of a person with a single ventricle heart defect with the DNA of their biological relatives. 
            </br></br>
            The more people in a family that enroll, the more researchers will be able to learn about the causes of single ventricle heart defects.`,
    },
    {
      id: 6,
      title: 'How many family members need to take part?',
      description: `The more DNA samples we have, the better. We would like as many immediate family members (biological parents, biological siblings, and biological children) as possible to join the study, but even one family member is helpful. 
            </br></br>
            If your family members are not able to participate, don’t worry! You still can.`,
    },
    {
      id: 7,
      title: 'Why is SOURCE asking for medical records?',
      description: `By studying DNA and medical histories of patients and looking at the DNA of entire families, researchers may be able to uncover what causes single ventricle heart defects. Right now, we know that these defects may be caused by more than one thing, including a person’s genetics. 
            </br></br>
            We are asking those with single ventricle heart defects to sign a medical record release form and share their relevant medical records about their heart so that we may pair their clinical information (specific to their single ventricle defect) with the DNA in their saliva. The decision to collect and pair these pieces together was based on guidance from SOURCE’s scientific advisors, as they believe this combination will provide researchers with stronger data for analysis.`,
    },
    {
      id: 8,
      title:
        'What kind of consent is required to participate in SOURCE? What is the difference between consent and assent?',
      description: `To join SOURCE, a consent form must be signed. The consent form gives us your permission to study your genetic and medical information and to share it in a de-identified way with qualified researchers.</br></br>

            When signing up for the study, you may use a consent or assent form, depending on your age and disability status. Here’s what you should know:
            </br></br>
            <ul style="padding-left: 60px">
            <li>If you are an adult participant who can consent yourself, you will sign a consent form. </li>
            <li>If you are an adult participant who cannot consent yourself and have a legal guardian, your legal guardian will sign a consent form on your behalf.  </li>
            <li>If you are under 18, you cannot sign your own consent form. Your parent or legal guardian must sign the form for you. </li>
            </ul></br>
            For children ages 7 and over, there is also a form called an assent form. Your parents or guardians can read this document to you to help you understand the study. Then, you can provide a verbal agreement to participate.
            `,
    },
    {
      id: 9,
      title: 'Can I enroll my child’s other biological parent for them?',
      description:
        'No, each adult participant in the study will have to create their own account and enroll themselves. You can invite them to participate, and we hope you will. SOURCE is more powerful the more family members that enroll.',
    },
    {
      id: 10,
      title: 'Can I enroll my child?',
      description:
        'If your child is a minor, yes. </br></br> If your child is an adult who cannot legally consent to participate and you are their legal guardian, yes. </br></br> If your child is an adult, they will have to create their own account and enroll themselves. You can invite them to participate, and we hope you will. SOURCE is more powerful the more family members that enroll.',
    },
    {
      id: 11,
      title: 'Can I quit the study?',
      description:
        `You can quit the study at any time. If you have any questions, you may call us at ${SVSOURCE_CONTACT.PHONE} or email us at <a href='mailto:${SVSOURCE_CONTACT.EMAIL}'>${SVSOURCE_CONTACT.EMAIL}</a>.`,
    },
    {
      id: 12,
      title: 'Do I need to go anywhere to participate?',
      description:
        'You won’t need to go to a clinic or anywhere else to take part. You can sign up on this website. We will mail you a collection kit to use at home. The kit will have an envelope inside with a pre-paid return label already attached so that you can send it back to us free of charge.',
    },
    {
      id: 13,
      title:
        'Will I be paid for taking part in this study? Will the study cost me any money?',
      description:
        'No. There is no cost to join SOURCE and you will not be paid. You will never be asked to give money as part of this project.',
    },
    {
      id: 14,
      title: `Will you contact me in the future?`,
      description: `
        We may contact you in the future for:</br></br>
        <ul style="padding-left: 60px">
            <li>More information we may need for the study.</li>
            <li>To invite you to take part in other surveys or studies.</li>
            <li>To share information about the study progress and/or discoveries.</li>
        </ul>
        `,
    },
  ],
  'My data and privacy': [
    {
      id: 1,
      title: 'What is SOURCE doing with the information it collects?',
      description: `We are making the genetic and medical information we collect available free of charge to researchers around the world.  
        </br></br>
        Before the information is shared, it is “de-identified.” This means that information that could identify participants is removed.
        </br></br>
        Researchers will use the data to learn how single ventricle heart defects form. If researchers can understand the specific genes that cause these defects, they can use that information to work to identify treatments that improve health and wellness throughout the entire lifetime.
        `,
    },
    {
      id: 2,
      title: 'How will my information be kept safe?',
      description: `Your privacy is very important to us. SOURCE follows strict guidelines to assure patient information is protected. An Institutional Review Board (IRB) has approved this study. 
        </br></br>
        Your personal information will be stored in a secure database and protected with a password. When you join, SOURCE will assign you a unique numeric identifier. Only authorized people who work on SOURCE at Additional Ventures and the SOURCE study staff at Boston Children’s Hospital will be able to connect this identifier to your name and contact information. To protect your confidentiality, we will use only secure computers and secure means of analyzing your information.
        </br></br>
        Only de-identified data is shared with researchers. This means we take out details such as your name and birth date, as well as other identifying information, which makes it hard to identify you.
        </br></br>
        The lead researcher (principal investigator) for SOURCE works for Additional Ventures. They have signed an agreement to keep all identifying information private.
        </br></br>
        If you have any questions or concerns about how we handle data, we are very happy to answer them. You can call us at ${SVSOURCE_CONTACT.PHONE}, or you can email us at <a href="mailto:${SVSOURCE_CONTACT.EMAIL}">${SVSOURCE_CONTACT.EMAIL}</a>.
        `,
    },
    {
      id: 3,
      title: `Who will have access to my information?`,
      description: `Only authorized people who work on SOURCE at Additional Ventures and the SOURCE study staff at Boston Children’s Hospital have access to your identifiable information.
        </br></br>
        We may share your de-identified information with other researchers. This means they won’t be able to identify you. It also means they can’t ask you if they can use your information in future studies, so you won’t get to choose if your de-identified information is used in these studies.
        </br></br>
        These researchers are approved by a science review committee before being granted access to the de-identified data.
        </br></br>
        Some of these future studies may be on diseases other than single ventricle heart disease. Some researchers may study ways to measure health or how health might change with certain treatments or care. Some of these studies may be with for-profit companies for commercial uses so the companies can try to develop new treatments. 
        </br></br>
        Researchers can’t attempt to identify you or contact you. Outside researchers must sign an agreement with SOURCE. In it, they agree to use the information only for studies approved by the science review committee.
        `,
    },
    {
      id: 4,
      title: `Will I learn anything about my (or my child’s or other family member’s) single ventricle heart defect?`,
      description: `
        Not right now. This is because we don’t know which parts of DNA cause single ventricle heart defects. That is why we are doing this study! This means we don’t know which parts of your (or your child or family member’s) DNA may cause single ventricle heart disease, so we cannot give you any information about your (or your family’s) genetic cause. 
        </br></br>
        In the future, we may make those discoveries. If we do, we may contact you. We may ask if you want information about your (or your child or family member’s) genetics specific to single ventricle heart disease, but we don’t know if or when this might be possible.
        </br></br>
        Single ventricle heart defects likely come from many causes. That means not all results will apply to all those with these conditions – not even major discoveries. SOURCE may provide a summary of research results. We may email you the summary or we may put it on our website. We may publish results, but the results may not be specific to you (or your child or family member’s) single ventricle heart defect.
        </br></br>
        Your doctor will not get your (or your child or family member’s) genetic results, and you will not get any data files such as your (or your child or family member’s) genetic sequence. 
        `,
    },
    {
      id: 5,
      title: `Will I learn anything about any other conditions that may affect my (or my child or other family member’s) health?`,
      description: `
        Maybe, but only if you want to. In this study, we want to understand the causes of single ventricle heart disease. To do this, we will look at every piece of DNA collected. When we do, we may find changes (mutations) in the DNA. These changes may be linked to some other disease that is not single ventricle heart disease. These results are called “secondary findings”. This type of result is not common, and most people in this study will not have a result like this.
        </br></br>
        Although uncommon, we could find a secondary finding while looking at your (or your child’s) DNA. If we do, and you have told us that you want to know, we will contact you. Because this is a research study, we can’t promise a specific timeframe that we can send you the result.
        </br></br>
        These secondary findings would be limited to “actionable findings”. An actionable finding means that there is a known medical treatment or monitoring that can improve health or prevent the disease.
        </br></br>
        If you have one of these rare secondary findings and you have told us that you would want to know about it, we will connect you with a genetics counselor, who can arrange a second genetic test to confirm the finding. This second test is free of charge to you.
        </br></br>
        If the finding is confirmed, the genetic counselor will work with you. They will answer your questions and help you understand what the finding means. They can also arrange for other family members to be tested. They will help you understand what the next steps are for you.`,
    },
  ],
  'DNA and genetics': [
    {
      id: 1,
      title: 'What is DNA and why is it important?',
      description: `We are using your cheek swab to study the DNA inside your cells. We do this through gene sequencing. Genes are part of your DNA and give instructions for how the cells in your body should work and grow. Sometimes changes in genes can give instructions that make it more likely for us to get a certain disease. 
        </br></br>
        Genetic research is the study of DNA. We want to find out which genes and things in a person’s environment might lead to disease. If we can find what causes single ventricle heart defects, we can find better ways to prevent, diagnose, and treat these diseases.
        `,
    },
    {
      id: 2,
      title: 'How will SOURCE study DNA?',
      description: `Sometimes researchers study only a small part of DNA. They may look at just the part they know can cause a disease. We don’t know much about the causes of single ventricle heart defects, so this study will look at all of your genes to understand how genetic differences relate to your heart defect.`,
    },
  ],
};
