import { Box, Typography, useMediaQuery } from "@mui/material";
import { Container } from "./styles";

import { AccordionList } from 'ihp-components/components/Accordion';
// import { GetStarted } from "pages/Welcome/GetStarted";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom/dist";
import { faqContent, mockFAQ } from "./mock";


import { ReactComponent as ArrowBackwardIcon } from 'images/shared/chevron-left-1.svg';
import { useTheme } from "@emotion/react";
import useScrollToTop from "hooks/useScrollToTop";
import { WelcomePageWrapper } from "pages/Welcome/styles";
import { ACCORDION_ICONS } from "utils/configParseUtils";

const FAQ = () => {
  const { documentTitle, pageTitle } = faqContent;
  document.title = documentTitle;
  useScrollToTop();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const navigate = useNavigate();
  const theme = useTheme();

  const [categories] = useState(Object.keys(mockFAQ));
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  // const navigateToWelcomeToStudy = () => {
  //   navigate('/onboarding/welcome-to-study');
  // };

  useEffect(() => {
    if (isMobile === false && selectedCategory === null) {
      setSelectedCategory(categories[0]);
    }
  }, [isMobile, categories, selectedCategory]);

  const getAccordionItems = () => {
    return mockFAQ[selectedCategory].map((faq) => {
      return {
        ...faq,
        title: <Typography variant="pb2" fontWeight={800} color='primary.navy'>{faq.title}</Typography>,
        description: <Typography variant="pl1" color='primary.navy' dangerouslySetInnerHTML={{ __html: faq.description }}></Typography>
      };
    });
  };

  return (
    // refactor this to return only the page and use the navbar and footer components in the main parent page
    <>
      <Container >
        <WelcomePageWrapper>
          <Typography display={isMobile && selectedCategory ? 'none' : 'block'} color='primary.navy' variant="h1">{pageTitle}</Typography>
          <Box display={'flex'} flexDirection={isMobile ? 'column' : 'row'} >
            <Box display={(isMobile && selectedCategory) ? 'none' : 'flex'} flexDirection={'column'} flex={1}>
              {categories.map((category, index) => {
                return <Box key={index} display={'flex'} flexDirection={'column'} py={2} onClick={() => setSelectedCategory(category)} style={{ cursor: 'pointer' }}>
                  <Typography variant="pb1" sx={{ textDecoration: selectedCategory === category ? 'underline' : 'none' }} color={selectedCategory === category ? 'primary.green' : 'primary.navy'}>{category}</Typography>
                </Box>;
              }
              )}
            </Box>

            <Box display={isMobile && selectedCategory ? 'flex' : 'none'} alignItems={'center'} onClick={() => setSelectedCategory(null)} >
              <ArrowBackwardIcon height={24} color={theme?.palette?.primary?.green} />
              <Typography color='primary.green' pl={4} py={2} variant="pb1">
                {pageTitle}</Typography>
            </Box>
            <Typography display={isMobile && selectedCategory ? 'block' : 'none'} color='primary.navy' py={2} variant="pb1">{selectedCategory}</Typography>

            {selectedCategory && <Box display={'flex'} flexDirection={'column'} flex={2}>
              {<AccordionList accordionItems={getAccordionItems()} accordionIcons={ACCORDION_ICONS} />}
            </Box>}
          </Box>
        </WelcomePageWrapper>
        {/* <GetStarted onGetStarted={navigateToWelcomeToStudy} /> */}
      </Container>
    </>
  );
};
export default FAQ;
