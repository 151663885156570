import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';

import { FormCardItem } from '.';
import { formDataItemShape } from './FormCardItem';

export const FormCardList = ({ formDataList, ...rest }) => (
  <Stack spacing="20px" {...rest}>
    {formDataList.map(({ id, ...formDataItem }) => (
      <FormCardItem key={id} formDataItem={formDataItem} />
    ))}
  </Stack>
);

FormCardList.propTypes = {
  formDataList: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      ...formDataItemShape,
    })
  ),
};
