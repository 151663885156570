import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';

import { useCreateYourInterestsMutation } from 'ihp-bloom-redux/features/educationCategories/educationCategoriesApiSlice';

import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';

import { withStudyArmRolePermissions } from 'hocs/withStudyArmRolePermissions';
import { withCommunicationPreference } from 'hocs/withCommunicationPreference';

import { LinkButton } from 'components/Buttons';

import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';

import RadioButtonsToggleCard from 'components/RadioButtonsToggleCard';
import CommunicationPreferenceEditModal from './CommunicationPreferenceEditModal';

import {
  CategoryWrapper,
  Title,
  Footer,
  CommunicationPreferenceWrapper,
  PencilLogo,
  StyledFilledButton,
  styles,
} from './style';

const CommunicationPreference = (props) => {
  const { preSelectedInterestData } = props;

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [preSelectedInterests, setPreSelectedInterests] = useState([]);

  const [modalContent, setModalContent] = useState({});

  const user = useSelector((state) => state.user);
  const { person } = user;

  const [createYourInterests, { isLoading: isCreating }] =
    useCreateYourInterestsMutation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const radioToggleCardList = [
    {
      category: '2',
      title: 'Are you interested in learning about Single Ventricle disorders?',
    },
    {
      category: '3',
      title:
        'Are you interested in information on genes that can contribute to the risk of SV Source?',
    },
    {
      category: '4',
      title:
        'Are you planning to share information with other people in diagnosed SV Source participant’s life?',
    },
  ];

  const radioButtonProps = [
    {
      label: 'Yes',
      value: 'Yes',
      id: '1',
    },
    {
      label: 'No',
      value: 'No',
      id: '2',
    },
  ];

  const resetSelectedInterests = () => {
    if (preSelectedInterestData?.length > 0) {
      const clone = JSON.parse(JSON.stringify(preSelectedInterestData));
      /**
       * If any category has atleast one option checked mark it as active=true
       */
      setPreSelectedInterests(
        clone.map((item) => ({
          ...item,
          active: item?.options?.some((it) => it.checked),
        }))
      );
    }
  };

  useEffect(() => {
    resetSelectedInterests();
  }, [preSelectedInterestData]);

  const filterInterestsByCategory = (category) => {
    let filteredInterests = {};
    if (preSelectedInterests.length > 0) {
      filteredInterests = preSelectedInterests.find(
        (interest) => category === interest.category
      );
    }
    return filteredInterests;
  };

  const getSelectedInterestValues = (category) => {
    const filteredInterest = filterInterestsByCategory(category);
    if (filteredInterest?.category) {
      let selectedInterests = '';
      const checkedInterests = filteredInterest?.options?.filter(
        (option) => option.checked === true
      );
      if (checkedInterests?.length > 0) {
        selectedInterests = checkedInterests
          ?.map((interest) => interest.label)
          .join(', ');
      }
      return selectedInterests;
    }
  };

  const getYesNoValue = (category) => {
    return getSelectedInterestValues(category) ? 'Yes' : 'No';
  };

  const pencilClickHandler = (editValue) => {
    setEditModalOpen(true);
    const interestList = filterInterestsByCategory(editValue);
    const clone = JSON.parse(JSON.stringify(interestList));
    setModalContent(clone);
  };

  const handleOnChange = (code, category) => {
    const interestsCopy = [...preSelectedInterests];
    const filteredInterests = filterInterestsByCategory(category);
    const updatedOptionList = filteredInterests?.options.map((option) => {
      option.checked = code === option.code ? !option.checked : option.checked;
      return option;
    });
    filteredInterests.options = updatedOptionList;
    const newSelectedInterests = interestsCopy.map((interest) => {
      if (interest.category === filteredInterests.category) {
        interest.options = filteredInterests.options;
      }
      return interest;
    });
    setPreSelectedInterests(newSelectedInterests);
  };

  const onContinueHandler = (selectedCategory) => {
    const interestsCopy = [...preSelectedInterests];
    const initialSelectedInterests = interestsCopy.find(
      (interest) => selectedCategory.category === interest.category
    );
    initialSelectedInterests.options = selectedCategory.options;

    const newSelectedInterests = interestsCopy.map((interest) => {
      if (interest.category === selectedCategory.category) {
        interest.options = initialSelectedInterests.options;
      }
      return interest;
    });
    setPreSelectedInterests(newSelectedInterests);
  };

  const onCancelClick = () => {
    resetSelectedInterests();
  };

  const makeAPICalls = async (createInterestsPayload) => {
    try {
      await createYourInterests({
        personId: person.id,
        payload: createInterestsPayload,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = async (data) => {
    let selectedCategoryList = [];
    let selectedCategoryObj = {};

    preSelectedInterests.forEach((data) => {
      data.options.forEach((option) => {
        if (option.checked) {
          selectedCategoryObj = {
            type: 'person-education-categories',
            attributes: {
              education_category_code: option.code,
            },
          };
          selectedCategoryList = [...selectedCategoryList, selectedCategoryObj];
        }
      });
    });
    const createInterestsPayload = {
      data: [...selectedCategoryList],
    };

    makeAPICalls(createInterestsPayload);
  };

  const handleCategoryToggle = (value, category) => {
    if (value === 'Yes') {
      const targetIndex = preSelectedInterestData?.findIndex(
        (item) => item.category === category
      );
      const cloned = preSelectedInterests?.map((item) => {
        if (item.category === category) {
          item.options = preSelectedInterestData[targetIndex]?.options;
          item.active = true;
        }
        return item;
      });
      setPreSelectedInterests(cloned);
    } else {
      const cloned = preSelectedInterests?.map((item) => {
        if (item.category === category) {
          item.options = item.options?.map((option) => ({
            ...option,
            checked: false,
          }));
          item.active = false;
        }
        return item;
      });
      setPreSelectedInterests(cloned);
    }
  };

  return (
    <Grid
      xs={isMobile ? 12 : 6}
      sx={!isMobile && !isTablet ? { maxWidth: '600px' } : {}}
      key={'name'}
    >
      <CommunicationPreferenceWrapper>
        <Title>Topics you are interested in receiving information about:</Title>

        {filterInterestsByCategory('1')?.options?.map((option) => (
          <FormCheckbox
            containerStyle={
              option.checked
                ? styles.activeCheckboxStyle
                : styles.uncheckedCheckboxStyle
            }
            key={option.code}
            value={option.code}
            label={option.label}
            checked={option.checked}
            onChange={() => handleOnChange(option.code, '1')}
          />
        ))}
        <CategoryWrapper>
          {radioToggleCardList.map((item) => (
            <RadioButtonsToggleCard
              sx={{ width: '343px' }}
              defaultValue={
                preSelectedInterests?.find(
                  (category) => category.category === item.category
                )?.active
                  ? 'Yes'
                  : 'No'
              }
              cardTitle='Interests'
              cardDescription={getSelectedInterestValues(item.category)}
              onIconClick={pencilClickHandler}
              icon={
                <PencilLogo onClick={() => pencilClickHandler(item.category)} />
              }
              radioButtonProps={radioButtonProps}
              showCard='Yes'
              title={item.title}
              category={item.category}
              onChange={handleCategoryToggle}
            />
          ))}
        </CategoryWrapper>
        <Footer>
          <LinkButton
            onClick={() => {
              onCancelClick();
            }}
          >
            Cancel
          </LinkButton>

          <StyledFilledButton
            onClick={() => {
              onSave();
            }}
            disabled={isCreating}
            loading={isCreating}
            startIcon={<CheckIcon width={14} height={14} />}
          >
            Save
          </StyledFilledButton>
        </Footer>
        <CommunicationPreferenceEditModal
          open={editModalOpen}
          onClose={setEditModalOpen}
          interestList={modalContent}
          onModalContinue={onContinueHandler}
        />
      </CommunicationPreferenceWrapper>
    </Grid>
  );
};

export default withStudyArmRolePermissions(
  withCommunicationPreference(CommunicationPreference)
);
