import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.greenTint,
}));

export const MobileWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '50px 20px',
  gap: '30px',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '70px 40px',
  gap: '40px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px 20px',
  },
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'start',
  maxWidth: '650px',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'start',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.navy,
  fontSize: '36px',
  fontWeight: 900,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.navy,
  fontSize: '18px',
  fontWeight: 600,
}));

export const JoinNowBtn = styled(Button)(({ theme }) => ({
  padding: '12px 25px',
  backgroundColor: theme.palette.primary.navy,
  color: '#fff',
  borderRadius: '120px',
  textAlign: 'center',
  fontFamily: 'Nunito Sans',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20.25px',
}));
