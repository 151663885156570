import {
  Alert,
  Box,
  CircularProgress,
  styled,
  Typography,
} from '@mui/material';
import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';
import Button from 'ihp-components/components/v2/Button';
import { Link } from 'react-router-dom';

export const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const AcmeLogo = styled(Logo)({
  width: '102px',
  height: '70px',
});

export const Container = styled('div')(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '50px 40px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const TitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '80%',
  margin: 'auto',
  marginBottom: '5px',
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: 20,
  gap: 15,
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '33.6px',
  textAlign: 'center',
  marginTop: '20px',
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '19.6px',
  marginBottom: '20px',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '600',
  textDecoration: 'none',
  marginTop: '27px',
  color: theme.palette.primary.fuchsia75,
}));

export const SignInButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
}));

export const SignUpButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
}));

export const Loader = () => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    height='200px'
  >
    <CircularProgress />
  </Box>
);

export const StyledAlert = styled(Alert)(() => ({
  fontSize: '13px',
  marginTop: '20px',
  padding: '6px 10px',
}));
