import { HOMEPAGE_ROUTE_PATHS, SVSOURCE_CONTACT } from "utils/constants";
import { StyledLink } from "./styles";
import { concatRoutePaths } from "components/LandingLayout/utils";

export const anyQuestionsContent = {
  title: 'Questions',
  description: (
    <>
      Learn more about <StyledLink to={concatRoutePaths('', HOMEPAGE_ROUTE_PATHS.WELCOME, HOMEPAGE_ROUTE_PATHS.ABOUT)}>who we are</StyledLink>, read more in our <StyledLink to={concatRoutePaths('', HOMEPAGE_ROUTE_PATHS.WELCOME, HOMEPAGE_ROUTE_PATHS.FAQ)}>FAQs</StyledLink>, or send us an email at <StyledLink to='javascript:void(0)' onClick={() => window.location = `mailto:${SVSOURCE_CONTACT.EMAIL}`}>{SVSOURCE_CONTACT.EMAIL}</StyledLink>
    </>
  )
};