import {
  useCreatePersonStudyArmMutation,
  useUpdatePersonStudyArmMutation,
} from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useCreatePersonMutation } from 'ihp-bloom-redux/features/user/createPersonApiSlice';
import {
  useCreateNewAccountProfileMutation,
  useUpdateAccountProfileMutation,
} from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

import {
  createPersonPayload,
  updatePersonStudyArmPayload,
  createPersonStudyArmPayload,
  createAccountProfilePayload,
  updateAccountProfilePayload,
} from './payloads';
import { useState } from 'react';

export const useCreateNewPerson = () => {
  const [createPerson, state] = useCreatePersonMutation();

  const create = async () => {
    const newPerson = await createPerson(createPersonPayload()).unwrap();
    return newPerson;
  };

  return [create, state];
};

export const useCreatePersonStudyArm = () => {
  const [createPersonStudyArm, state] = useCreatePersonStudyArmMutation();
  const [error, setError] = useState('');

  const create = async ({
    personId,
    studyArmId,
    studyArmRoleId,
    studyArmStatusId,
  }) => {
    setError('');
    // Check if all required parameters are provided
    if (!studyArmId || !studyArmRoleId || !studyArmStatusId) {
      throw new Error(
        'Missing required parameters for creating person and study arm record.'
      );
    }
    const personStudyArmRecord = await createPersonStudyArm({
      personId,
      payload: createPersonStudyArmPayload({
        personId: personId,
        studyArmId,
        studyArmStatusId,
        studyArmRoleId,
      }),
    });

    if (personStudyArmRecord.error) {
      const error = personStudyArmRecord.error.data?.errors?.[0].detail;
      if (
        error ===
        'This association of person id, study arm id, study arm role id and person for whom id fields was already been taken.'
      ) {
        setError(
          'Can not enroll the same individual as the account holder. Please select another individual to enroll to proceed.'
        );
      }
    }

    return personStudyArmRecord;
  };

  return [create, state, error];
};

export const useUpdatePersonStudyArm = () => {
  const [updatePersonStudyArm, state] = useUpdatePersonStudyArmMutation();

  const update = async ({
    personStudyArmId,
    studyArmRoleId,
    studyArmStatusId,
    personForWhomId,
  }) => {
    // Check if all required parameters are provided
    if (!personStudyArmId || !studyArmRoleId || !studyArmStatusId) {
      throw new Error(
        'Missing required parameters for updating person study arm record.'
      );
    }
    const personStudyArmRecord = await updatePersonStudyArm({
      personStudyArmId,
      payload: updatePersonStudyArmPayload({
        personStudyArmId,
        studyArmStatusId,
        studyArmRoleId,
        personForWhomId,
      }),
    }).unwrap();
    return personStudyArmRecord;
  };

  return [update, state];
};

export const useCreateNewAccountProfile = () => {
  const [createNewAccountProfile, state] = useCreateNewAccountProfileMutation();

  const create = ({
    accountPersonStudyArmId,
    subjectPrimaryPersonStudyArmId,
  }) => {
    return createNewAccountProfile(
      createAccountProfilePayload({
        accountPersonStudyArmId,
        subjectPrimaryPersonStudyArmId,
      })
    ).unwrap();
  };

  return [create, state];
};

export const useUpdateAccountProfile = () => {
  const [updateProfile, state] = useUpdateAccountProfileMutation();

  const update = ({
    accountProfileId,
    subjectPrimaryPersonStudyArmId,
    accountPersonStudyArmId,
    subjectSecondaryPersonStudyArmId,
  }) => {
    return updateProfile({
      accountProfileId,
      payload: updateAccountProfilePayload({
        accountProfileId,
        subjectPrimaryPersonStudyArmId,
        accountPersonStudyArmId,
        subjectSecondaryPersonStudyArmId,
      }),
    }).unwrap();
  };

  return [update, state];
};

export const useCreatePersonAndUpdateStudyArm = () => {
  const [createPerson] = useCreateNewPerson();
  const [updatePersonStudyArm] = useUpdatePersonStudyArmMutation();

  const trigger = async ({
    personStudyArmId,
    studyArmRoleId,
    studyArmStatusId,
  }) => {
    const newPerson = await createPerson();
    const newPersonId = newPerson.data.id;

    await updatePersonStudyArm({
      personStudyArmId,
      payload: updatePersonStudyArmPayload({
        personStudyArmId,
        studyArmRoleId,
        studyArmStatusId,
        personForWhomId: newPersonId,
      }),
    }).unwrap();

    return newPersonId;
  };

  return [trigger];
};
