import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const resourceApiSlice = createApi({
  reducerPath: 'resourceApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Resources'],
  endpoints: (build) => ({
    getAllResources: build.query({
      query: ({ ...params }) => ({
        url: `/resources`,
        method: 'GET',
        params,
      }),
      providesTags: ['Resources'],
    }),
    getResources: build.query({
      query: ({ resourceId, ...params }) => ({
        url: `/resources/${resourceId}`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => [{ type: 'Resource', id: arg }]
    }),
  }),
});

export const {
  useGetAllResourcesQuery,
  useGetResourcesQuery
} = resourceApiSlice;
