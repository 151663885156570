import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  connectingProvider: null,
  inProgressProviders: [],
  connectedProviders: [],
  lastConnectedProviders: [],
  finishDataShareEnabled: false,
  userCreationResponse: {},
};

export const oneUpHealthSlice = createSlice({
  name: 'oneUpHealthData',
  initialState,
  reducers: {
    updateConnectingProvider: (state, action) => {
      state.connectingProvider = action.payload;
    },
    addInProgressProvider: (state, action) => {
      state.inProgressProviders = [
        ...state.inProgressProviders,
        action.payload,
      ];
    },
    removeInProgressProvider: (state, action) => {
      const filtered = state.inProgressProviders.filter((p) => {
        return p.id !== action.payload;
      });
      state.inProgressProviders = [...filtered];
    },
    removeInProgressProviderByParticipantTaskId: (state, action) => {
      state.inProgressProviders = state.inProgressProviders.filter(
        (p) => p.participant_task_id !== action.payload
      );
    },
    resetInProgressProviders: (state, action) => {
      state.inProgressProviders = [];
    },
    updateLastConnectedProvider: (state, action) => {
      state.lastConnectedProvider = action.payload;
    },
    updateFinishDataShareEnabled: (state, action) => {
      state.finishDataShareEnabled = action.payload;
    },
    updateConnectedProviders: (state, action) => {
      let isExisting = false;
      let updatedProviders = state.connectedProviders.map((cp) => {
        if (cp.id === action.payload.id) {
          isExisting = true;
          cp.connectedAt = action.payload.connectedAt;
        }
        return cp;
      });
      if (isExisting) {
        state.connectedProviders = updatedProviders;
      } else {
        state.connectedProviders.push(action.payload);
      }
    },
    updateUserCreationResponse: (state, action) => {
      state.userCreationResponse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },

});

export const {
  updateConnectingProvider,
  updateUserCreationResponse,
  addInProgressProvider,
  removeInProgressProvider,
  removeInProgressProviderByParticipantTaskId,
  resetInProgressProviders,
  updateConnectedProviders,
  updateLastConnectedProvider,
  updateFinishDataShareEnabled,
} = oneUpHealthSlice.actions;

export default oneUpHealthSlice.reducer;
