import React from 'react';

import { ReactComponent as FollowupBadge } from 'images/profile/achievements/followup.svg';
import { ReactComponent as FollowupFilledBadge } from 'images/profile/achievements/followup-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobileScheduleFollowUpCard } from './index.mobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  IsCompletedMark,
} from '../styles';
import { isMobile } from 'utils/isMobile';

export const ScheduleFollowUpCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobileScheduleFollowUpCard
        inProgress={inProgress}
        isCompleted={isCompleted}
      />
    );
  }
  return (
    <CardWrapper backgroundColor={isCompleted ? '#E2F0F8' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#24A9F3' />}
      {!inProgress ? <FollowupBadge /> : <FollowupFilledBadge />}
      <Info>
        <Title>Schedule a Follow-up</Title>
        <StyledSlider
          value={100}
          isCompleted={isCompleted}
          backgroundColor='#50BAF5'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Dolor sit amet'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
