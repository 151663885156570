import { forwardRef, useState } from 'react';
import MobileTimePicker from "@mui/lab/MobileTimePicker";
import PickersModalDialog from "@mui/lab/internal/pickers/PickersModalDialog";

import { StaticTimePicker } from './StaticTimePicker';

export const ModalTimePicker = forwardRef(({
  renderInput,
  disabled,
  value,
  onChange,
  defaultValue = null,
  ...rest
}, ref) => {
  const [tempValue, setTempValue] = useState(defaultValue);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (!disabled) {
      setTempValue(value);
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <MobileTimePicker
        onChange={onChange}
        value={value}
        open={false}
        disabled={disabled}
        onOpen={handleOpen}
        renderInput={renderInput}
        ref={ref}
        {...rest}
      />
      <PickersModalDialog
        open={open}
        onAccept={() => {
          onChange(tempValue);
          setOpen(false);
        }}
        onDismiss={handleClose}
        onClear={handleClose}
      >
        <StaticTimePicker
          value={tempValue}
          onChange={newValue => setTempValue(newValue)}
        />
      </PickersModalDialog>
    </>
  );
});
