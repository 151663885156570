import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { StepperContainer } from 'components/StepperContainer';
import StepCounter from 'components/Activities/StepCounter';
import { ProgressButtons } from 'components/Buttons';
import ActivityHeader from 'components/Activities/Header';

export const StyledStepCounter = styled(StepCounter)(({ theme }) => ({
  marginTop: '35px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const StyledStepperContainer = styled(StepperContainer)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray20,
  '& > .BaseLayout-main': {
    height: 'auto',
  },
  '& > .BaseLayout-header': {
    backgroundColor: theme.palette.primary.gray20,
    zIndex: '10',
    borderBottom: '0px',
  },
  '& > .BaseLayout-scrollable': {
    width: 325,
    [theme.breakpoints.up('sm')]: {
      width: 700,
      backgroundColor: theme.palette.primary.gray20,
      padding: '60px 50px 40px',
      flex: '0 0 auto !important',
      margin: '0 auto 36px',
      borderRadius: '8px',
    },
  },
  '& .BaseLayout-footer': {
    padding: '10px',
  },
  '& * > .ProgressButtons-root': {
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse !important',
      justifyContent: 'flex-start',
      margin: '20px 0px 10px',
    },
  },
  '& .MuiPickerStaticWrapper-root': {
    boxShadow: '0px 0px 30px rgb(0 84 135 / 5%)',
    borderRadius: '13px',
    padding: '20px 30px 30px',
    '& > div > div': {
      width: '100% !important',
    },
    '& .MuiCalendarPicker-root': {
      width: '100% !important',
      '& > div:first-child': {
        paddingLeft: '16px',
        paddingRight: '4px',
      },
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      '& > div > div': {
        justifyContent: 'space-between !important',
      },
      '& div[role=row]': {
        margin: '16px 0',
        justifyContent: 'space-between !important',
      },
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: '310px !important',
    },
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.gray2,
  },
}));

export const StyledInstructions = styled(Box)(({ theme }) => ({
  padding: '24px',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
  },
}));

export const getStyles = (theme) => ({
  modalContainer: {
    width: '405px',
    padding: '60px 40px 20px',
    background: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    boxSizing: 'border-box',
    textAlign: 'center',
    outline: 'none',

    [theme.breakpoints.down('sm')]: {
      width: '325px',
      padding: '40px 30px 10px',
    },
  },
  infoBox: {
    padding: '0px 15px 30px',
  },
  info: {
    color: theme.palette.secondary.gray4,
    textAlign: 'center',
    marginBottom: '15px',
  },
  subInfo: {
    color: theme.palette.secondary.gray4,
    textAlign: 'center',
  },
  celebrationScreenContainer: {
    margin: 'auto',
    maxWidth: '500px',
  },
});

export const StyledProgressButtons = styled(
  ({ isLastStep = false, ...rest }) => <ProgressButtons {...rest} />
)(({ isLastStep }) => ({
  width: '100%',
  margin: '25px auto',
  alignItems: 'center',
  justifyContent: `center ${isLastStep ? '!important' : ''}`,
}));

export const StepperWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '700px',
  padding: '0',
  margin: 'auto',
  '& .MuiPickerStaticWrapper-root': {
    boxShadow: '0px 0px 30px rgb(0 84 135 / 5%)',
    borderRadius: '13px',
    padding: '24px 50px 24px',
    '& > div > div': {
      width: '100% !important',
      maxHeight: '100% !important',
    },
    '& .MuiCalendarPicker-root': {
      width: '100% !important',
      maxHeight: '100% !important',
      '& > div:first-child': {
        paddingLeft: '16px',
        paddingRight: '4px',
        paddingTop: '16px',
        margin: '0px',
        '& > div:last-child': {
          '& > div': {
            width: '25px',
          },
        },
      },
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      '& > div > div:first-child': {
        marginTop: '40px',
        justifyContent: 'space-between !important',
      },
      '& div[role=row]': {
        margin: '0px 0',
        justifyContent: 'space-between !important',
      },
    },
    '& .MuiIconButton-edgeStart, .MuiIconButton-edgeEnd': {
      width: '12px',
      height: '24px',
      color: theme.palette.primary.gray100,
      '& svg': {
        fontSize: '2rem',
        fill: `${theme.palette.primary.gray100} !important`,
      },
    },
    '& .PrivatePickersFadeTransitionGroup-root': {
      color: theme.palette.primary.gray100,
      fontSize: '18px',
      fontWeight: '600',
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.gray100,
    },
    '& .MuiPickersDay-root': {
      backgroundColor: theme.palette.primary.darkBlue10,
      color: theme.palette.primary.darkBlue100,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      margin: '0px 0px 15px 0px',
      borderRadius: '5px',
    },
    '& .MuiPickersDay-root.Mui-disabled': {
      color: theme.palette.primary.gray50,
      backgroundColor: 'transparent',
    },
    '& .MuiPickersDay-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
      borderRadius: '5px',
      fontWeight: '600',
    },
    '& .PrivatePickersSlideTransition-root': {
      minHeight: '310px !important',
    },
    '& .MuiTypography-caption': {
      color: theme.palette.primary.gray50,
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '600',
      opacity: '0.6',
    },
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 25px',
  },
}));

export const ScheduleCallPageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

export const StyledActivityHeader = styled(ActivityHeader)(({ theme }) => ({
  marginBottom: '20px',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.lightGray5,
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.lightGray5,
    '& > :first-child': {
      position: 'static',
    },
    '& > :last-child': {
      position: 'static',
    },
  },
}));

export const BookAppointmentWrapper = styled('div')(({ theme }) => ({}));

export const BookAppointmentDetails = styled('div')({
  maxWidth: '564px',
  margin: '0px auto 25px',
});

export const BookAppointmentDetailsTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  textAlign: 'center',
  marginBottom: '10px',
}));

export const BookAppointmentDetailsDescription = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.primary.gray75,
    textAlign: 'left',
    p: {
      marginBottom: '24px',
    },
  })
);

export const BookAppointmentDatePicker = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
}));

export const BookAppointmentTimePicker = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
}));

export const BookAppointmentTimeSlots = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',

  '& .MuiRadio-root': {
    width: '345px',
    height: '50px',
    '& .StyledLabel': {
      justifyContent: 'center',
    },
    '& .StyledLabel-checked': {
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%)',
        width: '70%',
      },
    },
  },
}));

export const BookAppointmentTimeSectionTitle = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.secondary.gray4,
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '30px',
  })
);

export const NoSlotsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '16px',
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.white,
  padding: '20px',
  marginTop: '35px',
}));
