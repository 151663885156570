import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { selectMedicationIds } from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

import { BaseHeader } from 'components/BaseLayout';
import { DropDownCalendar } from './DropDownCalendar';
import {
  AddButton,
  Content,
  Header,
  ListButton,
  StyledBaseLayout,
} from './styles';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import { DrugCard } from './DrugCard';
import { ReactComponent as ListIcon } from 'images/shared/list-ul.svg';
import {
  getMedicationTrackerAllPath,
  getMedicationTrackerCreatePath,
} from 'pages/NotificationCenter/paths';

const MedicationTrackerDesktop = ({ date, onDateChange }) => {
  const weekday = date.getDay();
  const medicationIds = useSelector(selectMedicationIds({ weekday }));
  return (
    <StyledBaseLayout
      header={
        <Header>
          <BaseHeader>Medication tracker</BaseHeader>
          <Stack direction='row' spacing='15px'>
            <DropDownCalendar date={date} onDateChange={onDateChange} />
            <ListButton component={Link} to={getMedicationTrackerAllPath()}>
              <ListIcon width='18px' height='18px' />
            </ListButton>
            <AddButton
              component={Link}
              to={getMedicationTrackerCreatePath()}
              startIcon={<PlusIcon />}
            >
              Add
            </AddButton>
          </Stack>
        </Header>
      }
      content={
        <Content>
          {medicationIds.map((id) => (
            <DrugCard key={id} id={id} date={date} />
          ))}
        </Content>
      }
    />
  );
};

export default MedicationTrackerDesktop;
