import {
  CardContainer,
  LearnMoreBox,
  LearnMoreButton,
  LearnMoreContainer,
  StyledBox,
  StyledDivider,
  StyledHeader, StyledOverviewHeader,
  StyledSubHeader
} from './styles';
import RegistryStatisticsOverview from '../RegistryStatisticsOverview';
import { ReactComponent as ChevronRightIcon } from 'images/shared/chevron-right.svg';
import { theme } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import {sectionTitle} from './constants';

const RegistryStatisticsWidget = () => {
  const navigate = useNavigate();

  const navigateToStatistics = () => {
    navigate('/registry-statistics');
  };
  const header = 'Discover more registry statistics';
  const subHeader = 'Learn more about people enrolled in the registry';
  const buttonLabel = 'See more';

  return (
    <>
      <StyledOverviewHeader>
        {sectionTitle}
      </StyledOverviewHeader>
      <CardContainer>
        <RegistryStatisticsOverview />
        <StyledDivider />
        <LearnMoreContainer>
          <LearnMoreBox>
            <StyledBox>
              <StyledHeader>
                {header}
              </StyledHeader>
              <StyledSubHeader>
                {subHeader}
              </StyledSubHeader>
            </StyledBox>
            <LearnMoreButton
              onClick={navigateToStatistics}
              endIcon={<ChevronRightIcon width="20px" height="20px" color={theme.palette.primary.darkblue70} />}>
              {buttonLabel}
            </LearnMoreButton>
          </LearnMoreBox>
        </LearnMoreContainer>
      </CardContainer>
    </>
  );
};

export default RegistryStatisticsWidget;
