import { Link } from 'react-router-dom';
import { Alert, styled } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';

export const VerifyEmailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '60px 40px 40px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const AcmeLogo = styled(Logo)({
  width: '102px',
  height: '70px',
});

export const FormContainer = styled('div')({
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const BackLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.fuchsia75,
  textDecoration: 'none',
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  background: theme.palette.white,
  borderRadius: '8px',
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1);',
  alignItems: 'center',
}));

export const EmailButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray95,
  marginBottom: '30px',
  '&:hover': {
    backgroundColor: theme.palette.primary.gray95,
  },
}));

const styles = {
  emailText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '90%'
  },
};

export const getStyles = () => {
  return styles;
};
