import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { LinkButton } from './LinkButton';
import Button from 'ihp-components/components/v2/Button';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const StyledFilledButton = styled(
  ({ linkDisabled, maxWidth = '325px', ...rest }) => <Button {...rest} />
)(({ theme, linkDisabled, maxWidth }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: linkDisabled ? maxWidth : '295px',
  },
  '& .MuiButton-endIcon, .MuiButton-startIcon': {
    width: '14px',
    height: '15px',
  },
}));

export const StyledLinkButton = styled(LinkButton)(({ disabled }) => ({
  width: '100%',
  display: disabled ? 'none' : 'inherit',
  maxWidth: '135px',
}));
