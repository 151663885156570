export const multiValueTable = {
  form: `
  <table class="table table-bordered formio-multi-value-table">
    <tbody>
      {{rows}}
      {% if (!ctx.disabled) { %}
        <tr>
          <td colspan="2">
            <button class="btn btn-primary formio-button-add-another" ref="addButton">
              <i class="formio-button-add-another-icon"></i>
              Add new
            </button>
          </td>
        </tr>
      {% } %}
    </tbody>
  </table>
  `,
};
