import { produce } from 'immer';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useConfiguration } from './useConfiguration';
import { updatePersonStudyArmPayload } from './payloads';
import {
  useCreateNewPerson,
  useCreatePersonStudyArm,
  useCreateNewAccountProfile,
  useUpdateAccountProfile,
} from './utils';
import {
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_STATUS_NAMES,
} from 'constants/global';

export const useEnrollParticipant = () => {
  const [updatePersonStudyArm, studyArmState] =
    useUpdatePersonStudyArmMutation();
  const [createNewPerson] = useCreateNewPerson();
  const [updateAccountProfile] = useUpdateAccountProfile();
  const [createPersonStudyArm, _, error] = useCreatePersonStudyArm();
  const [createProfile] = useCreateNewAccountProfile();
  const [lazyFetchAccountProfile] =
    accountProfileApiSlice.endpoints.getAccountProfile.useLazyQuery();
  const dispatch = useDispatch();

  const { studyArmRoles, getResearchStudyArm, getStudyArmStatusByCode } =
    useConfiguration();

  const { isEnrollingNewPerson } = useSelector((s) => s.user);

  const enrollParticipant = async ({ user, studyArmRoleCode }) => {
    const { person, activeAccountProfile } = user;
    const { account_person_study_arm_id: personStudyArmId } =
      activeAccountProfile.attributes.profile;

    console.log(user, activeAccountProfile);

    // Find the study arm status ID
    const selectedStudyArmStatusId = getStudyArmStatusByCode(
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_ROLE_SELECTED
    ).id;
    console.log({
      studyArmRoleCode,
      selectedStudyArmStatusId,
    });

    // Find the study arm role ID
    const { id: selectedStudyArmRoleId, title: selectedStudyArmRoleName } =
      studyArmRoles.find((s) => s.code === studyArmRoleCode);

    if (!selectedStudyArmStatusId || !selectedStudyArmRoleId) {
      throw new Error(
        'Unable to find study arm status ID or study arm role ID'
      );
    }

    try {
      if (isEnrollingNewPerson) {
        // Create a new person study arm
        // Create a new person record
        const studyArmResearch = getResearchStudyArm();
        // const representeePerson = await createNewPerson();
        const representeePersonStudyArm = await createPersonStudyArm({
          personId: person.id,
          studyArmId: studyArmResearch.id,
          studyArmRoleId: selectedStudyArmRoleId,
          studyArmStatusId: selectedStudyArmStatusId,
        });
        // Create a new profile
        const newPersonStudyArmId = representeePersonStudyArm.data?.data?.id;
        // check if has has an existing profile (rejoining the study)
        const subjectSecondaryPersonStudyArmId =
          activeAccountProfile.attributes.profile
            .subject_secondary_person_study_arm_id;
        let refetchProfileId = null;
        if (subjectSecondaryPersonStudyArmId) {
          await updateAccountProfile({
            accountProfileId: activeAccountProfile.attributes.profile.id,
            subjectPrimaryPersonStudyArmId: newPersonStudyArmId,
            accountPersonStudyArmId: newPersonStudyArmId,
            subjectSecondaryPersonStudyArmId: null,
          });
          refetchProfileId = activeAccountProfile.attributes.profile.id;
        } else {
          const newProfile = await createProfile({
            accountPersonStudyArmId: newPersonStudyArmId,
            subjectPrimaryPersonStudyArmId: newPersonStudyArmId,
          });
          refetchProfileId = newProfile.data.id;
        }

        const updatedProfile = await lazyFetchAccountProfile(
          refetchProfileId
        ).unwrap();

        const updatedUser = produce(user, (draft) => {
          draft.activeAccountProfile = updatedProfile.data;
        });

        dispatch(setValues(updatedUser));
        return updatedUser;
      } else {
        // Update Person Study Arm
        await updatePersonStudyArm({
          personStudyArmId,
          payload: updatePersonStudyArmPayload({
            personStudyArmId,
            studyArmRoleId: selectedStudyArmRoleId,
            studyArmStatusId: selectedStudyArmStatusId,
          }),
        }).unwrap();

        const updatedUser = produce(user, (draft) => {
          const profile = draft.activeAccountProfile.attributes.profile;

          const updates = {
            study_arm_role_name: selectedStudyArmRoleName,
            study_arm_role_id: selectedStudyArmRoleId,
            study_arm_status_id: selectedStudyArmStatusId,
            study_arm_status_title: 'enrollment-role-selected',
          };

          Object.entries(updates).forEach(([key, value]) => {
            profile[key] = value;
          });
        });

        dispatch(setValues(updatedUser));
        return updatedUser;
      }
    } catch (err) {
      // Handle error
      console.error(err);
    }
  };

  return [enrollParticipant, studyArmState, error];
};
