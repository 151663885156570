import { styled } from '@mui/system';
import { Box, Button, Link } from '@mui/material';
import { FilledButton } from 'ihp-components/components/Buttons';

export const getStyles = (theme) => ({
  viewAllButton: {
    display: 'flex',
    alignSelf: 'stretch',
    alignItems: 'center',
    color: theme.palette.primary.main,
    padding: '10px 16px',
    justifyContent: 'center',
    gap: '12px',
  },
  icon: {
    fontSize: '14px',
  },
  newspaperIcon: {
    height: '45px',
    width: '45px',
    color: theme.palette.primary.white,
    background: '#FFC980',
    padding: '8px 5px',
    borderRadius: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  learnMoreButton: {
    textTransform: 'none',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0.28px',
    display: 'flex',
    padding: '13px 18px',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'end',
    gap: '12px',
    borderRadius: '8px',
    border: '1px solid ' + theme.palette.primary.lightGray100,
    background: theme.palette.primary.white,
  },
});

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '20px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '145%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '145%',
  },
}));

export const ViewAllLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: '10px 16px',
  justifyContent: 'center',
  gap: '12px',
  cursor: 'pointer',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '140%',
  letterSpacing: '0.26px',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '25px',
  alignItems: 'flex-start',
  gap: '25px',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  boxShadow: '0px 0px 30px 0px rgba(0, 84, 135, 0.05)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '145%',
    gap: '24px',
    flexDirection: 'column',
    padding: '24px',
  },
}));

export const CardContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
  flex: '1 0 0',
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
}));

export const CardTitle = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray100,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '135%',
}));

export const CardSponsor = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  fontStyle: 'italic',
  fontWeight: '400',
  lineHeight: '145%',
}));

export const CardDetail = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '145%',
}));

export const LearnMoreButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.28px',
  display: 'flex',
  padding: '13px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'end',
  gap: '12px',
  borderRadius: '8px',
  border: '1px solid ' + theme.palette.primary.lightGray100,
  background: theme.palette.primary.white,
  color: theme.palette.primary.darkBlue70,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ViewAllButton = styled(FilledButton)(({ theme }) => ({
  display: 'none',
  padding: '15px 25px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.primary.main,
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '135%',
  letterSpacing: '0.3px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    '&: hover': {
      background: theme.palette.primary.main,
    },
  },
}));
