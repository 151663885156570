import { MenuItem } from '@mui/material';
import { PropTypes } from 'prop-types';

import {
  RadioGroupInput,
  RadioInput,
  TextInput,
  DatepickerInput,
} from 'components/Input';
import { SelectInput } from 'configUI/components';
import { forwardRef } from 'react';

const size = { width: '100%' };

export const ParsedTextLineInput = forwardRef(
  ({ placeholder, onChange, value }, ref) => (
    <TextInput
      ref={ref}
      sx={size}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
);

ParsedTextLineInput.propTypes = {
  placeholder: PropTypes.string,
};

export const ParsedTextAreaInput = forwardRef(
  ({ rows, placeholder, onChange, value }, ref) => (
    <TextInput
      ref={ref}
      sx={size}
      multiline
      rows={rows || 3}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
);

ParsedTextAreaInput.propTypes = {
  rows: PropTypes.number,
  placeholder: PropTypes.string,
};

export const ParsedSelectInput = forwardRef(
  ({ defaultValue, options, onChange, value }, ref) => (
    <SelectInput
      ref={ref}
      sx={size}
      defaultValue={defaultValue}
      onChange={onChange}
      value={value}
      label='Select'
    >
      {options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.value}
        </MenuItem>
      ))}
    </SelectInput>
  )
);

ParsedTextLineInput.propTypes = {
  defaultValue: PropTypes.any,
  options: PropTypes.objectOf(PropTypes.string),
};

export const ParsedDatepickerInput = forwardRef(({ size, ...rest }, ref) => (
  <DatepickerInput ref={ref} sx={size} {...rest} />
));

ParsedDatepickerInput.propTypes = {
  defaultValue: PropTypes.any,
};

export const ParsedRadioInput = forwardRef(
  ({ defaultValue, options, onChange, ...rest }, ref) => (
    <RadioGroupInput
      ref={ref}
      sx={size}
      defaultValue={defaultValue}
      onChange={onChange}
      {...rest}
    >
      {options.map((option) => (
        <RadioInput key={option.id} value={option.id} label={option.value} />
      ))}
    </RadioGroupInput>
  )
);

ParsedRadioInput.propTypes = {
  defaultValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object),
};

export const ParsedInput = forwardRef(({ type, content, ...rest }, ref) => {
  switch (type) {
    case 'text':
      return <ParsedTextLineInput ref={ref} {...content} {...rest} />;
    case 'textArea':
      return <ParsedTextAreaInput ref={ref} {...content} {...rest} />;
    case 'select':
      return <ParsedSelectInput ref={ref} {...content} {...rest} />;
    case 'radio':
      return <ParsedRadioInput ref={ref} {...content} {...rest} />;
    case 'datePicker':
      return <ParsedDatepickerInput ref={ref} {...content} {...rest} />;
    default:
      return <></>;
  }
});
