import { styled } from '@mui/system';
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => {
  return {
    display: 'flex',
    width: '40px',
    height: '40px',
    color: theme.palette.primary.gray50,
    marginTop: '10px',
    marginRight: '10px',
  };
});

export const Line = styled('div')(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.primary.gray10,
}));

export const Title = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '145%',
  textAlign: 'center',
  color: theme.palette.primary.gray100,
  marginLeft: '40px',
  marginBottom: '20px',
  marginTop: '20px',
}));

export const MainContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
}));

export const MainContainerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 560,
  bgcolor: 'background.paper',
};
