import { styled } from '@mui/system';
import { Box, Button, Stack } from '@mui/material';

export const Icon = styled(Box)({
  display: 'flex',
});

export const LeftProgress = styled(Box)(({ theme }) => ({
  margin: '10px 0 10px 0',
  borderRadius: '7px 0px 0px 7px',
  height: '73px',
  display: 'flex',
  overflow: 'visible',
}));

export const RightProgress = styled(Box)(({ theme }) => ({
  margin: '10px 0 10px 0',
  background: theme.palette.primary.lightBlue70,
  borderRadius: '0px 7px 7px 0px',
  height: '73px',
}));

export const MainTextContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  color: 'white',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '140%',
});

export const PointsContainer = styled(Stack)({
  margin: '-68px 0 0 70px',
  opacity: '1',
  height: '73px',
  fontSize: '14px',
  fontWeight: '600',
});

export const Amount = styled(Stack)({
  margin: '-60px 30px',
  fontSize: '18px',
  fontWeight: '600',
});

export const ClaimButton = styled(Button)({
  background: '#6DBE5D',
  color: 'white',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '145%',
  margin: '-65px 30px',
  height: '40px',
  width: '127px',
  textTransform: 'uppercase',
  paddingLeft: '20px',
  padding: '12px 20px',
  borderRadius: '8px',
});

// Mobile styles here

export const NextPayoutMobileWrapper = styled(Box)(({ theme }) => ({
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.green100,
  color: theme.palette.primary.white,
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

export const MobileClaimButton = styled(Button)({
  background: '#6DBE5D',
  color: 'white',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '12px',
  lineHeight: '145%',
  height: '40px',
  width: '127px',
  textTransform: 'uppercase',
  padding: '12px 20px',
  borderRadius: '8px',
});
