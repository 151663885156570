import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { Typography } from '@mui/material';

import { ReactComponent as ChevronRight } from 'configUI/images/list/chevronRight.svg';
import { Container, Content, StyledIconButton } from './styles';

export const PregnancyJournalItemCard = ({ id, createdAt, data }) => {
  const date = parse(createdAt.split('T')[0], 'yyyy-MM-dd', new Date());

  const feel = JSON.parse(data)?.content?.find?.(
    ({ category }) => category === 'intro'
  )?.values[0]?.value;

  const hasFeel = feel && feel.length > 0;
  const title = hasFeel ? feel : format(date, 'eeee');
  const description = hasFeel
    ? format(date, 'eeee, MM.dd.yyyy')
    : format(date, 'MM.dd.yyyy');

  return (
    <Container component={Link} to={`${id}/`}>
      <Content>
        <Typography variant='pb3' color='secondary.gray1'>
          {title}
        </Typography>
        <Typography variant='pl5' color='primary.gray50'>
          {description}
        </Typography>
      </Content>
      <StyledIconButton>
        <ChevronRight width='8px' height='13px' />
      </StyledIconButton>
    </Container>
  );
};
