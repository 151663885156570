import { Typography } from "@mui/material";
import { DownloadLink, Text } from "./styles";
import { SVSOURCE_CONTACT } from "utils/constants";
import { ADDITIONAL_VENTURE_LINK } from "constants/global";

export const cliniciansContent = {
  documentTitle: 'Single Ventricle SOURCE',
  pageTitle: 'For Clinicians',
  footnote: (
    <>
      If you’re a researcher and you’d like to learn more about the study
      and its data, please visit the{' '}
      <a href={ADDITIONAL_VENTURE_LINK}>Additional Ventures</a> website.
    </>
  ),
  helpDescription: {
      subTitle: (
        <>
          Help us spread the word about SOURCE{' '}
          <Typography variant='pl1'>
              by sharing information about this study with your single ventricle
              patients and families.
          </Typography>
        </>
        ),
      description: `We need your help to make SOURCE the largest and most extensive
                  genetic and clinical dataset focused exclusively on single ventricle
                  heart disease. The de-identified data will be available to all
                  qualified researchers, fueling many studies for years to come to
                  advance the field and accelerate improvements in outcomes and care for
                  patients.`
    },
    getAccessDescription: {
      subTitle: 'You can get access to our recruitment materials in two ways:',
      requestPrintedMaterials: (
          <>
            <Text>
              Request printed informational materials such as brochures and flyers
              to be sent to your clinic by emailing us at&nbsp;
              <DownloadLink
                  to='javascript:void(0)'
                  onClick={() => (window.location = `mailto:${SVSOURCE_CONTACT.EMAIL}`)}
                  >
                {SVSOURCE_CONTACT.EMAIL}
              </DownloadLink>
            </Text>
          </>
        ),
        downloadBrochure: (
          <>
            <Text>
              Download the{' '}
              <DownloadLink to='/pdf/source_brochure.pdf' target='_blank'>
                SOURCE Brochure
              </DownloadLink>{' '}
              and{' '}
              <DownloadLink to='/pdf/source_onepager.pdf' target='_blank'>
                SOURCE One-Pager
              </DownloadLink>
            </Text>
          </>
        )
    }
};