import { Box } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { useTheme } from '@mui/material/styles';
import Button from 'ihp-components/components/v2/Button';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  addInProgressProvider,
  updateConnectingProvider,
  updateFinishDataShareEnabled,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useEffect, useState } from 'react';
import { useUpdateStatusMutation } from 'ihp-bloom-redux/features/oneUpHealth/manualProvidersApi';
import { selectSelectedParticipanttaskId } from 'ihp-bloom-redux/features/tasks/tasksSlice';

function SpecificFinalPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, ,] = useSearchParams();
  let isSuccessful = searchParams.get('success') === 'true';
  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { inProgressProviders, userCreationResponse } = oneUpHealthData;
  const participantTaskId = useSelector(selectSelectedParticipanttaskId());
  const { isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [updateStatusMutation, { isLoading: isUpdatingStatus }] =
    useUpdateStatusMutation();

  const provider = oneUpHealthData?.lastConnectedProvider;
  const providerName = provider?.name ?? 'Medical provider';

  const { activeAccountProfile } = useSelector((state) => state.user);
  const personId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;

  useEffect(() => {
    if (!isSuccessful) {
      navigate('/activity/sync-data');
    } else if (
      isSuccessful &&
      participantTaskId &&
      provider &&
      personId &&
      isLoading &&
      isAuthenticated
    ) {
      const alreadyIn = (inProgressProviders ?? []).find(
        (c) => c.id?.toString() === provider.id.toString()
      );
      if (!alreadyIn) {
        dispatch(
          addInProgressProvider({
            ...provider,
            // we store +1 as id as from the backend side on the update operation the id is incremented
            providerId: (userCreationResponse?.provider_id + 1).toString(),
          })
        );
      }
      dispatch(updateFinishDataShareEnabled(true));
      dispatch(updateConnectingProvider(null));

      const request = {
        participantId: personId,
        providerId: userCreationResponse?.provider_id.toString(),
        payload: {
          data: {
            id: userCreationResponse?.provider_id.toString(),
            type: 'person-providers',
            attributes: {
              participant_task_id: participantTaskId,
              status: 'success',
            },
          },
        },
      };
      try {
        updateStatusMutation(request);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
  }, [
    dispatch,
    inProgressProviders,
    isSuccessful,
    participantTaskId,
    provider,
    personId,
    isAuthenticated,
    updateStatusMutation,
  ]);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.gray20,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <CelebrationScreen
          attributes={{
            thankyouMsg: `Thank you for sharing your ${providerName} health record`,
          }}
          maxHeight={false}
          maxWidth
        />
        <Box
          sx={{
            color: theme.palette.secondary.gray4,
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: 14,
            fontWeight: 400,
            margin: '15px 0px 0px 30px',
            maxWidth: '250px',
            textAlign: 'center',
          }}
        >
          Your health record will be used in the study
        </Box>
        <Button
          type='submit'
          size='large'
          sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
          onClick={() => navigate('/activity/sync-data')}
          loading={isLoading}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
}

export default SpecificFinalPage;
