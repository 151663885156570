export const getAuth0Data = () => {
  let data;
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.search('@@auth0') === 0) {
      const item = localStorage.getItem(key);
      data = JSON.parse(item);
    }
  }
  return data;
};

export const getAuth0LoginPageConfig = (params) => {
  let redirectUri = `${window.location.origin}/auth0-callback`;
  if (params?.context === 'signup') {
    redirectUri = `${window.location.origin}/create-user`;
  }

  return {
    ...params,
    appState: { ...params },
    prompt: 'select_account',
    redirectUri,
  };
};
