import { styled, Typography } from '@mui/material';
import { Input } from 'ihp-components/components/v2/Input';

export const Body = styled('div')({
  width: '100%',
  maxWidth: '400px',
  margin: '0px auto 20px',
});

export const Row = styled('div')(
  ({ marginBottom, textAlign, justifyContent }) => ({
    display: 'flex',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    textAlign: textAlign || 'center',
    marginBottom: marginBottom || '20px',
  })
);

export const NavRow = styled(Typography)(
  ({ marginBottom, textAlign, justifyContent, theme }) => ({
    display: 'none',
    justifyContent: justifyContent || 'center',
    alignItems: 'center',
    textAlign: textAlign || 'center',
    marginBottom: marginBottom || '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  })
);

export const InfoText = styled(Typography)(({ theme, fontWeight }) => ({
  alignSelf: 'stretch',
  fontWeight: fontWeight || '600',
  lineHeight: '19.6px',
  color: theme.palette.primary.darkGray100,
  fontSize: '14px',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
}));

export const AccordionTitle = styled(Typography)(({ theme, fontWeight }) => ({
  fontWeight: '800',
  color: theme.palette.primary.navy,
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 20,
  maxWidth: '420px',
  textAlign: 'center',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    maxWidth: '400px',
  },
}));

const styles = {
  formStyle: {
    backgroundColor: '#fff',
    borderColor: '#D9D9D9',
  },
  saveIcon: {
    fontSize: 15,
  },
};

export const getStyles = () => {
  return styles;
};

export const UL = styled('ul')(() => ({
  paddingLeft: '20px',
}));

export const RadioWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  justifyContent: 'center',
  width: '420px',
  '& .MuiRadio-root': {
    width: '100%', // Take 100% of the parent container's width
    '& .StyledLabel': {
      textAlign: 'left',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const InputWrapper = styled(Input)({
  '& label[data-shrink="false"]':{
    top: '40px',
  }
});
