import { useNavigate } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';

import { ProfileCard } from 'components/Profile/ProfileCard';
import {
  Divider,
  ResponsiveSideBySideLayout,
} from 'configUI/components/Shared';
import { CardList, StyledBaseLayout, StyledHeader } from './styles';

import { ReactComponent as Pills } from 'images/shared/pills.svg';
import { ReactComponent as Notebook } from 'images/shared/notebook.svg';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { getRewardsInfo } from 'components/Payment/Rewards/util';

const NotificationCenter = () => {
  document.title = 'Single Ventricle SOURCE Tools';
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const handleClickMedicationCard = () => navigate('medication-tracker');
  const handleClickPregnancyCard = () => navigate('pregnancy-journal');

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const { data } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const rewardsData = getRewardsInfo(data?.data);

  return (
    <ResponsiveSideBySideLayout>
      <StyledBaseLayout
        header={
          !notMobile && (
            <>
              <StyledHeader points={rewardsData?.balance} />
              <Divider />
            </>
          )
        }
        content={
          <CardList>
            <ProfileCard
              onClick={handleClickMedicationCard}
              icon={<Pills height='24px' />}
              title='Medication Tracker'
              description='Track all your medications and set a specific reminder for each of them'
            />
            <ProfileCard
              onClick={handleClickPregnancyCard}
              icon={<Notebook height='24px' />}
              title='Pregnancy Journal'
              description='Track your pregnancy progress daily and keep your journal up to date'
            />
          </CardList>
        }
      />
    </ResponsiveSideBySideLayout>
  );
};

export default NotificationCenter;
