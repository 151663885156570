import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontFamily: 'Inter',
  color: theme.palette.primary.gray60,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  marginTop: '10px',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignSelf: 'stretch',
  padding: '15px 0px 40px',
}));

export const HorizontalDivider = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  borderBottom: `1px solid ${theme.palette.primary.lightGray100}`,
}));

export const getStyles = (isNoMiddleNameChecked) => {
  return {
    checkboxContainer: {
      backgroundColor: 'transparent',
      border: '0px',
      '& .MuiFormControlLabel-label': {
        color: isNoMiddleNameChecked ? '#4D88AB' : '#444444',
      },
      '& .MuiFormControlLabel-root': {
        alignItems: 'start',
        '& .MuiCheckbox-root': {
          padding: '0px',
          '& span': {
            width: '20px',
            height: '20px',
          },
          '& svg': {
            width: '20px',
            height: '20px',
          },
        },
      },

      display: 'inline-block',
    },
    buttonStyle: {
      fontSize: '14px',
      width: '120px',
    },
  };
};
