import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const accountProfileApiSlice = createApi({
  reducerPath: 'accountProfileApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['AccountProfiles', 'AccountProfile'],
  endpoints: (build) => ({
    getAccountProfiles: build.query({
      query: (personId) => ({
        url: `/app/people/${personId}/profiles`,
        method: 'GET',
      }),
      providesTags: ['AccountProfiles'],
    }),
    getAccountProfile: build.query({
      query: (accountProfileId) => ({
        url: `/app/profiles/${accountProfileId}`,
        method: 'GET',
      }),
      providesTags: ['AccountProfile'],
    }),
    /*
      This is a composite api call responsible for account creation / signup
    */
    createAccountProfile: build.mutation({
      query: (payload) => ({
        url: `/app/profiles`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          accept: 'application/vnd.api+json',
          Authorization: 'Bearer ', // this api is public so no token is needed
        },
      }),
      extraOptions: { maxRetries: 0 }, // We have to avoid retries here
      invalidatesTags: ['AccountProfiles'],
    }),
    updateAccountProfile: build.mutation({
      query: ({ accountProfileId, payload }) => ({
        url: `/account-profiles/${accountProfileId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
    // create account profile
    createNewAccountProfile: build.mutation({
      query: (payload) => ({
        url: `/account-profiles`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['AccountProfiles'],
    }),
  }),
});

export const {
  useGetAccountProfilesQuery,
  useGetAccountProfileQuery,
  useCreateAccountProfileMutation,
  useCreateNewAccountProfileMutation,
  useUpdateAccountProfileMutation,
} = accountProfileApiSlice;
