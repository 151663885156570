import { styled } from '@mui/system';
import { Modal } from '@mui/material';

const getStyles = ({ palette, breakpoints }) => ({
  title: {
    color: palette.primary.darkGray100,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    lineHeight: '140%',
  },

  modalWrapper: {
    width: '375px',
    padding: '25px 40px 20px 40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflowY: 'auto',
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '35px 40px',
    borderBottom: `1px solid ${palette.primary.pattensBlue}`,
    [breakpoints.down('sm')]: {
      padding: '24px 24px 0px 24px',
      borderBottom: '0px',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
    },
  },
  buttonsContainer: {
    marginTop: '30px',
    alignItems: 'center',
    alignSelf: 'stretch',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  cancelButton: {
    fontSize: '16px',
    display: 'flex',
    padding: '17px 30px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    fontSize: '14px',
    display: 'flex',
    padding: '12px 20px',
    marginTop: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    color: palette.primary.darkRed,
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: '140%',
    letterSpacing: '0.28px',
    '&: hover': {
      color: palette.primary.darkRed,
    },
  },
});

export const DtpModal = styled(Modal)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: theme.palette.primary.gray5,
    opacity: '0.9 !important',
  },
}));

export default getStyles;
