import { forwardRef, useState } from 'react';
import { Typography } from '@mui/material';

import { Card } from '../Common/Card';
import { RadioContainer, RadioOptionContainer } from './styles';

const RadioOption = ({
  value,
  label,
  selected,
  onSelect,
  ...rest
}) => {
  const variant = selected ? 'pn5' : 'pl5';
  const color = selected ? 'primary.white' : 'primary.gray75';
  const handleClick = () => onSelect(selected ? '' : value);
  return (
    <RadioOptionContainer selected={selected} onClick={handleClick} {...rest}>
      <Typography variant={variant} color={color}>
        {label}
      </Typography>
    </RadioOptionContainer>
  );
};

const Radio = forwardRef(({
  options,
  selected,
  onSelect,
  ...rest
}, ref) => (
  <RadioContainer ref={ref} {...rest}>
    {options.map((option) => {
      const value = option?.value || option;
      const label = option?.label || option;
      return (
        <RadioOption
          key={value}
          value={value}
          label={label}
          selected={value === selected}
          onSelect={onSelect}
        />
      );
    })}
  </RadioContainer>
));

const OptionCardImpl = forwardRef(({
  title,
  subtitle,
  options,
  onChange,
  value,
  ...rest
}, ref) => {
  const handleOptionChange = (option) => onChange(null, option);
  const selected = options.find((option) =>
    option?.value === value || option === value);
  const displayValue = selected?.value
    ? selected.label
    : (selected || '');
  return (
    <Card
      title={title}
      subtitle={subtitle}
      value={displayValue}
      {...rest}
    >
      <Radio
        ref={ref}
        options={options}
        selected={value}
        onSelect={handleOptionChange}
      />
    </Card>
  );
});

export const OptionCard = forwardRef(({
  title,
  subtitle,
  options,
  value,
  onChange,
  defaultValue,
  ...rest
}, ref) => {
  const [internalValue, setInternalValue] = useState(defaultValue);

  const handleChange = (_, value) => onChange
    ? onChange(_, value)
    : setInternalValue(value);

  return <OptionCardImpl
    ref={ref}
    title={title}
    subtitle={subtitle}
    options={options}
    onChange={handleChange}
    value={onChange ? value : internalValue}
    {...rest}
  />;
});
