import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Chip from 'ihp-components/components/v2/Chip';

import { ReactComponent as Alert } from 'images/shared/alert-diamond.svg';
import { ReactComponent as Clock } from 'images/profile/my-consents/clock.svg';
import { ReactComponent as Tick } from 'images/profile/my-consents/tick.svg';
import {
  CircleDot,
  ConsentInfo,
  DateInfo,
  TextWrapper,
  getStyles,
} from './styles';
import { months } from 'utils/date';

export const Tags = ({
  isSigned,
  isMandatory,
  isArchived,
  isRejected,
  statusDate,
  showRemainingLabel,
  remaining,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  let formattedDate = statusDate;
  try {
    if (statusDate && isSmallDevice) {
      const parts = statusDate?.split('-');
      const month = months[parseInt(parts[0] - 1)]?.slice(0, 3)?.toUpperCase();
      formattedDate = `${parts[1]}-${month}-${parts[2]}`;
    }
  } catch (e) {
    console.error('Error during formatting date: ', statusDate);
  }

  let status = null;

  if (isSigned) {
    status = isSmallDevice ? 'Signed' : 'SIGNED';
  }

  if (isRejected) {
    status = 'DECLINED';
  }

  if (isArchived) {
    status = 'ARCHIVED';
  }

  const tagList = [];

  if (showRemainingLabel && remaining && isMandatory) {
    tagList.push(
      <Chip key={1} icon={<Clock />} variant='filled' type='primary'>
        {remaining}
      </Chip>
    );
  }

  if (status && formattedDate) {
    if (isSmallDevice) {
      tagList.push(
        <TextWrapper>
          <DateInfo>
            {`${status} ${formattedDate}`} <CircleDot />
          </DateInfo>
        </TextWrapper>
      );
    } else {
      tagList.push(
        <Chip
          key={2}
          icon={<Tick />}
          variant='outlined'
          type='info'
          sx={styles.infoChip}
        >
          {`${status} ${formattedDate}`}
        </Chip>
      );
    }
  }

  if (isMandatory === 'REQUIRED' && !isSigned) {
    if (isSmallDevice) {
      tagList.push(
        <TextWrapper>
          <ConsentInfo>Required</ConsentInfo>
        </TextWrapper>
      );
    } else {
      tagList.push(
        <Chip
          key={4}
          icon={<Alert />}
          variant='outlined'
          type='primary'
          sx={styles.chipRequired}
        >
          REQUIRED
        </Chip>
      );
    }
  }

  if (isArchived)
    tagList.push(
      <Chip key={6} variant='outlined' type='info'>
        ARCHIVED
      </Chip>
    );

  if (!!tagList.length)
    return (
      <Stack direction='row' alignItems='center' spacing='5px'>
        {tagList}
      </Stack>
    );
  return null;
};
