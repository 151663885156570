import React from 'react';

import { ReactComponent as DataBadge } from 'images/profile/achievements/data.svg';
import { ReactComponent as DataFilledBadge } from 'images/profile/achievements/data-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobileSyncDataCard } from './index.mobile';
import { isMobile } from 'utils/isMobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  MyPoints,
  IsCompletedMark,
} from '../styles';

export const SyncDataCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobileSyncDataCard inProgress={inProgress} isCompleted={isCompleted} />
    );
  }
  return (
    <CardWrapper backgroundColor={isCompleted ? '#F8EFD6' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#F3D167' />}
      {!inProgress ? <DataBadge /> : <DataFilledBadge />}
      <Info>
        <Title>
          <MyPoints>1 &nbsp;</MyPoints> / 3 Health records shared
        </Title>
        <StyledSlider
          value={80}
          isCompleted={isCompleted}
          backgroundColor='#F2C94C'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Health advocate'
            isCompleted={isCompleted}
          />
          <StyledChip label='$20' isCompleted={isCompleted} />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
