import { months } from 'utils/date';

export const makeCallScreensMap = {
  howTo: 0,
  callDetails: 1,
  videoCall: 2,
  celebration: 3,
};

export function reformatDate(date) {
  if (date === null || date === undefined || date?.length === 0) {
    return '';
  }
  let parts = date?.split('.').map((p) => parseInt(p));
  let monthString = months[parts[0] - 1];
  return ` ${parts[1]} ${monthString.substring(0, 3)} ${parts[2]}`;
}
