import { Stack, Typography } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  selectMedicationById,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

import {
  Icon,
  CardContainer,
  StyledIconButton,
} from './DrugCard.styles';
import { useMenuDrugCard } from './MenuDrugCard';
import { ReactComponent as BellDisabledIcon } from 'images/shared/bell-slash.svg';
import { ReactComponent as ThreeDots } from 'images/shared/ellipsis-v.svg';

export const DrugCard = ({ id }) => {
  const {
    drug: {
      name,
    },
    enabled,
  } = useSelector(selectMedicationById(id));

  const { Menu, handleClick } = useMenuDrugCard(id);

  return (
    <>
      <CardContainer>
        <Typography variant='pb3' color='secondary.gray1'>
          {name}
        </Typography>
        <Stack direction='row' alignItems='center' gap='5px'>
          {!enabled &&
            <Icon>
              <BellDisabledIcon height='14px' />
            </Icon>
          }
          <StyledIconButton onClick={handleClick}>
            <ThreeDots height='16px' />
          </StyledIconButton>
        </Stack>
      </CardContainer>
      <Menu />
    </>
  );
};
