const getStyles = (theme) => {
  return {
    container: {},
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      position: 'absolute',
      right: 17,
      top: 44,
    },
    formContainer: {
      paddingTop: '25px',
      width: '300px',
      position: 'relative',
      '& .MuiInputBase-input': {
        paddingRight: '40px !important',
      },
    },
    formLogin: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};
export default getStyles;
