export const isHealthSurvey = (code) => {
  const healthSurveyCodes = ['S3DP', 'S3LAR', 'S3IP', 'S3REP'];
  return healthSurveyCodes.includes(code);
};

export const requiredActivities = (activities = []) =>{
  if(activities.length === 0){
    return null;
  }
  let result = []
  result = activities.filter((activity) =>{
    return activity?.data?.required_behavior === 'must';
  })
  return result;
}
