import { useEffect, useState } from 'react';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import ProfileList from 'components/SelectProfile/ProfileList';

import {
  Container,
  Row,
  PageTitle,
  HelperText,
  SelectProfileWrapper,
  getStyles,
} from './styles';

import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';
import getRoute from 'utils/navigator';

function SelectProfile() {
  document.title = 'Single Ventricle SOURCE Select Profile';
  const styles = getStyles();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { activeAccountProfile, person } = user;

  const [selectedProfileId, setSelectedProfileId] = useState(null);

  const {
    isLoadingAccountProfileDetails,
    accountProfiles,
    accountProfile,
    noActiveProfiles,
  } = useGetAccountProfileDetails({
    personId: person?.id,
    shouldSelectDefaultProfile: false,
    accountProfileId: selectedProfileId,
  });

  const routeMap = {
    '/select-profile': {
      description:
        'Multiple profiles are associated with this email. Select the profile you would like proceed with.',
      initialValue: accountProfiles[0]?.attributes?.id,
    },
    '/switch-profile': {
      description: 'Select the profile you would like to proceed with.',
      initialValue: activeAccountProfile?.attributes?.profile?.id,
    },
  };
  const { description = '', initialValue } = routeMap[pathname] || {};
  const [selectedOption, setSelectedOption] = useState(null);

  const handleContinue = () => {
    setSelectedProfileId(selectedOption);
    sessionStorage.setItem('isFirstTimeLogin', 'true')
    sessionStorage.removeItem('isRequiredActivitiesCompleted');
  };

  useEffect(() => {
    if (accountProfile) {
      dispatch(
        setValues({
          activeAccountProfile: accountProfile?.data,
        })
      );
      navigate(
        getRoute({
          ...user,
          activeAccountProfile: accountProfile?.data,
        })
      );
    }
  }, [accountProfile]);

  useEffect(() => {
    if (initialValue) {
      setSelectedOption(initialValue);
    }
  }, [initialValue]);

  if (noActiveProfiles) {
    return <Navigate to='/no-active-profile' replace />;
  }

  return (
    <SelectProfileWrapper>
      <Container>
        <Row marginBottom={'5px'}>
          <PageTitle varient='h2'>Select Profile</PageTitle>
        </Row>
        <Row marginBottom={'30px'}>
          <HelperText varient='pl3'>{description}</HelperText>
        </Row>
        <Row marginBottom={'25px'}>
          <ProfileList
            profileList={accountProfiles}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Row>
        <Row>
          <Button
            onClick={() => handleContinue()}
            fullWidth
            style={styles.button}
            disabled={isLoadingAccountProfileDetails}
            loading={isLoadingAccountProfileDetails}
          >
            Continue
          </Button>
        </Row>
      </Container>
    </SelectProfileWrapper>
  );
}

export default SelectProfile;
