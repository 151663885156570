import { styled } from '@mui/system';

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '25px',
  alignItems: 'flex-start',
  gap: '25px',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  boxShadow: '0px 0px 30px 0px rgba(0, 84, 135, 0.05)',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '145%',
    gap: '24px',
    flexDirection: 'column',
    padding: '24px',
  },
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
  flex: '1 0 0',
}));

export const CardContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
  wordBreak: 'break-word',
}));

export const CardTitle = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray100,
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '135%',
}));

export const CardSubtitler = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  fontStyle: 'italic',
  fontWeight: '400',
  lineHeight: '145%',
}));

export const CardDescription = styled('div')(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray70,
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '145%',
}));
