import { Loader } from 'components/Loader';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAccountProfileQuery } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSlice';
import {
  selectSelectedParticipanttaskId,
  setSelectedParticipantTaskId,
} from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as SVSLogo } from 'images/svs-logo.svg';
import { Text, Title } from 'pages/Onboarding/components/Text';
import { useActiveConsents } from 'pages/Profile/Consents/List/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getRoute from 'utils/navigator';
import { Footer } from '../components/Footer';
import OnboardingLayout from '../components/Layout';
import { Container, Row } from './styles';
import { setConsentContext } from 'ihp-bloom-redux/features/user/userSlice';

function ConsentSignedPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const [
    updateParticipantTaskMutation,
    { isLoading: isUpdatingTask, isSuccess: isUpdatingTaskSuccess },
  ] = useUpdateParticipantTaskMutation();

  const selectedTaskId = useSelector(selectSelectedParticipanttaskId());

  const { data: accountProfile, isFetching: isLoadingAccountProfile } =
    useGetAccountProfileQuery(user.activeAccountProfile?.id, {
      skip: user.consentContext === 'profile' || !user.activeAccountProfile?.id,
      forceRefetch: true,
    });

  const [enabledFetchActiveConsents, setEnabledFetchActiveConsents] =
    useState(false);

  const activeConsents = useActiveConsents({
    enabled: enabledFetchActiveConsents,
  });

  useEffect(() => {
    if (accountProfile?.data) {
      dispatch(
        setValues({
          activeAccountProfile: accountProfile.data,
        })
      );
    }
  }, [accountProfile?.data, dispatch]);

  useEffect(() => {
    if (!selectedTaskId || !user?.person?.id) {
      return;
    }

    const requestBody = {
      participantTaskId: selectedTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(selectedTaskId),
          attributes: {
            status: 'completed',
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody).catch(console.error);
    dispatch(setSelectedParticipantTaskId({ taskId: null }));
  }, [
    dispatch,
    selectedTaskId,
    updateParticipantTaskMutation,
    user?.person?.id,
  ]);

  useEffect(() => {
    if (user?.consentContext === 'profile' && activeConsents.isSuccess) {
      dispatch(setConsentContext(''));
      navigate('/profile/consents');
    }
  }, [
    accountProfile?.data,
    activeConsents.data.length,
    activeConsents.isSuccess,
    dispatch,
    isUpdatingTaskSuccess,
    navigate,
    user,
  ]);

  useEffect(() => {
    if (!user?.consentContext && activeConsents.isSuccess) {
      navigate(
        activeConsents.data.length > 0
          ? '/onboarding/participant-consents'
          : getRoute({
              ...user,
              activeAccountProfile: accountProfile?.data,
            })
      );
    }
  }, [
    accountProfile?.data,
    activeConsents.data.length,
    activeConsents.isSuccess,
    dispatch,
    isUpdatingTaskSuccess,
    navigate,
    user,
  ]);

  if (isUpdatingTask || isLoadingAccountProfile || activeConsents.isLoading) {
    return <Loader />;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout width='375px' backHandler={goBack}>
      <Container>
        <Row>
          <SVSLogo width={200} />
        </Row>
        <Title marginBottom='10px'>Consent Accepted</Title>
        <Text>Thank you for signing your study document(s). You can find your signed study documents on your participant Dashboard under My Consents in your Profile.</Text>
        <Footer bottomFixed>
          <Button fullWidth onClick={() => setEnabledFetchActiveConsents(true)}>
            Continue
          </Button>
        </Footer>
      </Container>
    </OnboardingLayout>
  );
}

export default ConsentSignedPage;
