import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetFamilyInvitationsQuery } from 'ihp-bloom-redux/features/family/familyApiSlice';

export function useGetFamilyInvitations() {
  const { person } = useSelector((state) => state.user);

  const { data, ...rest } = useGetFamilyInvitationsQuery(person.id, {
    skip: typeof person.id !== 'number',
  });

  const familyInvitations = data?.data.map(({ attributes, id }) => ({
    ...attributes,
    id,
  }));

  return {
    data: familyInvitations || [],
    ...rest,
  };
}
