import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Stack, Typography } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  selectMedicationById,
  selectReminderIds,
  selectRemindersByIds,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';
import { selectDoseTaken } from 'ihp-bloom-redux/features/medicationTracker/doseTakenSlice';

import { ReactComponent as ChevronRight } from 'configUI/images/list/chevronRight.svg';
import {
  Container,
  StyledIconButton,
  LeftContent,
  RightContent,
} from './styles';
import { getMedicationTrackerRemindersPath } from 'pages/NotificationCenter/paths';

const Name = ({ name }) => (
  <Typography variant='pb3' color='secondary.gray1'>
    {name}
  </Typography>
);

const Reminder = ({ current, total, reminder }) => {
  if (current === total) {
    return (
      <Typography variant='pl5' color='primary.gray50'>
        No reminder
      </Typography>
    );
  }

  const dateStr = `1970-01-01T${reminder}Z`;
  const nextReminder = format(new Date(dateStr), 'h:mm a');

  return (
    <Stack direction='row' spacing='3px'>
      <Typography variant='pl5' color='secondary.gray4'>
        Next:
      </Typography>
      <Typography variant='pl5' color='primary.gray50'>
        {nextReminder}
      </Typography>
    </Stack>
  );
};

const Completed = ({ current, total }) => (
  <>
    <Typography variant='pb3' color='secondary.gray1'>
      {`${current} / ${total}`}
    </Typography>
    <Typography variant='pl5' color='primary.gray50'>
      completed
    </Typography>
  </>
);

const DetailsButton = () => (
  <StyledIconButton>
    <ChevronRight width='8px' height='13px' />
  </StyledIconButton>
);

export const DrugCard = ({ id, date }) => {
  const parsedDate = format(date, 'yyyy/MM/dd');
  const {
    drug: { name },
  } = useSelector(selectMedicationById(id));
  const reminderIds = useSelector(
    selectReminderIds({ medicationId: id, date: parsedDate })
  );
  const reminders = useSelector(selectRemindersByIds(reminderIds));
  const doseTakenIds = useSelector(selectDoseTaken(reminderIds, parsedDate));
  const doseNotTaken = reminders
    .filter(({ id }) => !doseTakenIds.includes(id))
    .sort(({ time: lhsTime }, { time: rhsTime }) =>
      lhsTime < rhsTime ? -1 : lhsTime > rhsTime ? 1 : 0
    );
  const current = doseTakenIds.length;
  const total = reminderIds.length;

  if (total === 0) {
    return null;
  }

  return (
    <Container
      component={Link}
      to={getMedicationTrackerRemindersPath(id, {
        date: format(date, 'yyyy-MM-dd'),
      })}
    >
      <LeftContent>
        <Name name={name} />
        <Reminder
          current={current}
          total={total}
          reminder={doseNotTaken[0]?.time || ''}
        />
      </LeftContent>
      <RightContent>
        <Completed current={current} total={total} />
      </RightContent>
      <DetailsButton />
    </Container>
  );
};
