import { styled } from '@mui/system';
import { Avatar } from '@mui/material';

export const WeekDay = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'checked',
})(({
  theme,
  checked = false,
}) => ({
  ...theme.typography.pb3,
  width: '40px',
  height: '40px',
  backgroundColor: checked
    ? theme.palette.primary.fuchsia75
    : theme.palette.primary.gray15,
  color: theme.palette.primary.white,
}));
