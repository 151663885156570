import { Box } from '@mui/system';
import { ReactComponent as InformationIcon } from '../../assets/svg/information-filled.svg';
import styled from '@emotion/styled';
import { FormHelperText } from '@mui/material';

const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.red70,
  marginLeft: '0px',
  marginTop: '8px',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '19.6px',
  gap: '5px',
}));

export const ErrorWrapper = ({ children, error, errorLabel, manualMessage, ...rest }) => {
  const message = errorLabel || 'This field is required';

  return (
    <Box sx={{ flexDirection: 'column', display: 'flex' }} {...rest}>
      {children}
      {error && (
        <StyledFormHelperText>
          <InformationIcon />
          {message}
        </StyledFormHelperText>
      )}
    </Box>
  );
};
