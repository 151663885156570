import { Box, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { Controller, useForm } from 'react-hook-form';
import getStyles, { DtpModal } from './styles';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';
import { useValidateEmail } from 'utils/formValidation';
import useSendNotification from '../../../hooks/useSendNotification';
import { useState } from 'react';

export const InviteModel = ({ open, onClose, invite }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  let sx = { width: '710px' };

  if (isTablet) {
    sx = { width: '600px' };
  }

  if (isMobile) {
    sx = { width: '279px' };
  }
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: invite?.firstName ?? '',
      lastName: invite?.lastName ?? '',
      email: invite?.email ?? '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = useValidateEmail();

  const { sendNotification } = useSendNotification();

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { firstName, lastName, email } = data;
    try {
      await sendNotification(firstName, lastName, email);
      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  return (
    <DtpModal
      className={'Hello'}
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles.modalWrapper, ...sx }}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>Spread the Word</Box>
          <Box sx={styles.iconWrapper}>
            <XIcon
              width='12px'
              height='18px'
              color='#888888'
              onClick={(e) => onClose()}
            />
          </Box>
        </Box>
        <Box sx={styles.formWrapper}>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='firstName'
                    label="Recipient's First Name"
                    error={errors.firstName}
                    errorLabel={errors.firstName?.message}
                    fullWidth={true}
                    placeholder='Enter first name'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='lastName'
                    label="Recipient's Last Name"
                    error={errors.lastName}
                    errorLabel={errors.lastName?.message}
                    fullWidth={true}
                    placeholder='Enter last name'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='email'
                control={control}
                rules={{
                  validate: validateEmail,
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    label="Recipient's Email"
                    error={errors.email}
                    errorLabel={errors.email?.message}
                    fullWidth={true}
                    placeholder='Enter email'
                    {...field}
                  />
                )}
              />
            </Box>
          </form>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ fontSize: '16px' }}
            variant='link'
            onClick={(e) => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            sx={{ fontSize: '16px' }}
            fullWidth={isMobile}
            onClick={handleSubmit(onSubmit)}
          >
            Invite
          </Button>
        </Box>
      </Box>
    </DtpModal>
  );
};
