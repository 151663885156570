import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { Navigator } from 'components/Navigator';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100dvh',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'left',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  [theme.breakpoints.up('sm')]: {
    height: '100vh',
  },
}));

export const StyledNavigator = styled(Navigator)(({ theme, isExpanded }) => {
  const width = isExpanded ? '250' : '90';
  return {
    background: 'linear-gradient(1.33deg, #005587 -2.85%, #00BEB1 144.31%)',
    [theme.breakpoints.up('sm')]: {
      flex: `0 0 ${width}px`,
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 60px',
    },
  };
});
