import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { Container, InfoMessage } from './styles';

export const Loader = ({ infoMessage, fullScreen = false,...rest }) => {
  return (
    <Container fullScreen={fullScreen}>
      <CircularProgress color='primary' />
      <InfoMessage>{infoMessage}</InfoMessage>
    </Container>
  );
};

Loader.defaultProps = {
  infoMessage: 'Loading',
};

Loader.propTypes = {
  infoMessage: PropTypes.string,
};
