import { SVSOURCE_CONTACT } from "utils/constants";

export const getInvolvedContent = {
    documentTitle: 'Get Involved - SOURCE',
    pageTitle: 'Get Involved',
    communityJoinMessageTitle : `We can’t wait for you to join the SOURCE community!`,
    communityJoinMessageDescription : `We look forward to working together to advance our understanding of single ventricle heart defects for years to come. Ready to get started?`,
};

export const mockGetInvolved = [
    {
        header: 'Are you interested in joining SOURCE? We’re excited to tell you more.',
        subheader: 'We’re excited you’re thinking about joining SOURCE! The choice to join is always yours – here’s what you need to know.',
        items: [
            {
                title: 'SOURCE is for anyone living in the U.S. with a single ventricle heart defect and their immediate biological family members (parents, siblings, and children).',
                description: 'You can be at <b>any stage</b>, from pre-Fontan to post-Fontan, including post-transplant.'
            },
            {
                title: 'This study has the power to accelerate single ventricle heart defect research and improve the lives of people living with single ventricle and their families.',
                description:
                    `Studying the genes of people with single ventricle heart defects can help researchers understand both the causes of these defects and the common conditions related to these defects. This knowledge can help in the development of potential treatments to improve quality of life and potentially, cures. </br></br>
                    We hope to accelerate discoveries by working with researchers all over the world. Your information will fuel <strong>not just one research study, but many.</strong>  
                    </br>
                    </br>
                    You have the power to impact the future people with single ventricle and their families!`
            },
            {
                title: 'Giving back to our participants is important to us.',
                description: `We’ll keep you posted on our study progress and what researchers are learning.`
            },
            {
                title: 'Your information will be kept private and secure.',
                description: `We will never share your data without your permission or with other parties not specified in our consent forms. We will never sell your data. `
            }
        ]
    },
    {
        header: 'Your online registration will be simple and should take less than 30 minutes. ',
        subheader: null,
        items: [
            {
                title: 'If you are eligible to participate, we will ask you important information about yourself and participating family members.',
                description: `This information helps us create the right kind of account for you.  
                <br/><br/>
                Remember, adults must sign up for themselves, while any minor children or others who are unable to consent on their own must be signed up by their parent or legal guardian.`
            },
            {
                title: `We encourage you to invite your family members to join, too! `,
                description: `Studying families can help us learn more about single ventricle and learn faster. 
                <br/>
                <br/>
                <ul style="padding-left: 40px">
                <li>If you are <b>an adult born with a single ventricle heart defect:</b> you can invite your biological parents, your biological siblings, and your own biological children.</li>
                <li>If you are a <b>biological parent</b> of a person born with a single ventricle heart defect: you can invite their other biological parent and any of your own biological children.</li>
                <li>If you are an <b>adoptive parent or guardian</b> of a person with single ventricle: you can invite their biological parents.</li>
                </ul>
                <br/>
                <br/>
                All families are different. If your family members are not able to participate, don’t worry! You still can! `
            },
            {
                title: `This study has two forms for you to read and sign: a Study Consent and a Medical Release form.`,
                description: `All participants will be asked to sign the Study Consent. Only people with a single ventricle heart defect will be asked to sign the Medical Release.
               <br/><br/>
                Your informed consent is important to us. Please read and review this carefully and email us at ${SVSOURCE_CONTACT.EMAIL} if you have any questions! We are always here to help, and your participation is always voluntary.`
            },
            {title: `We will send you a sample collection kit in the mail.`,
        description: `Be sure to read the instructions in the box. Sample collection is done by swabbing the inside of your cheek – it’s quick and easy! The box will have an envelope inside with a prepaid return label already on it.`},
            
        ]
    },
];