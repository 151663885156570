import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';

import { SearchResultListItemWrapper } from './style';
import { ReactComponent as Plus } from 'images/profile/plus.svg';

const SearchResultListItem = ({ title, dosage }) => (
  <SearchResultListItemWrapper>
    <Box flex={1} marginRight='12px'>
      <Typography variant='pb3' color='primary.gray100'>
        {title}
      </Typography>
    </Box>
    <IconButton
      component={Link}
      to={`/tools/medication-tracker/create?name=${title}&dosage=${dosage}`}
    >
      <Plus height='16px' width='16px' />
    </IconButton>
  </SearchResultListItemWrapper>
);

export default SearchResultListItem;
