import { CircularProgress, Typography } from '@mui/material';
import { ReactComponent as ChartLine } from 'images/shared/chart-line-4.svg';
import { EmptyListWrapper } from './style';
import { EmptyState } from 'ihp-components/components/v2/EmptyState';

export const EmptyList = ({
  isLoading = false,
}) => {
    let title = isLoading ? 'Loading research opportunities' : 'No records to display';
    let icon = <ChartLine />;
    if (isLoading) {
      icon = null;
    } else {
      icon = <ChartLine />;
    }
  return (
    <EmptyListWrapper>
      <EmptyState icon={icon} title={title} />
      {isLoading && (
        <CircularProgress sx={{ marginTop: '20px' }} color='primary' />
      )}
    </EmptyListWrapper>
  );
};
