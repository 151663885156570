import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const content = `
      <h3 style="color:#A0438B">Overview</h3>
      <p>Dear Sir or Madam, In this consent form “you” always refers to the subject. If you are a legally authorized representative, please remember that the “you” refers to the study subject.Thank you for taking the time to consider joining this study. This consent document can help  you  make  your  decision  by  explaining what  you  can  expect  to  happen  during this study, also known as a research study. Your participation in this study is completely voluntary (your choice). Take as long as you need to make your decision. You also can choose to take part in the study now, and then change your mind later at any time.</p>
      <h3 style="color:#A0438B">About the study</h3>
      <p>We encourage you to have conversations  with your family, caregivers, doctors, and study team about taking part in this study and whether it is right for you. The study team  will  work  with  you  to  answer  any  questions  that  you  may  have  about  the  study.  The study team includes the study doctor, nurses, and others who work with the study doctor. If  you  choose  to  participate  in  this  study, you  will  be  asked  to  sign  this  consent document prior to the study to let the study team know your decision. You will receive a signed copy of this consent document for your records. Please keep this consent document for your reference. We appreciate that you are thinking of taking part in this study.</p>
      `;
const initialState = {
  consents: [
    {
      id: '001',
      dueDate: '04/25/2022',
      version: '1.023',
      signed: false,
      show: false,
      title: 'Et arcu, dui vehicula metus nunc',
      description:
        'Commodo, suspendisse urna vel imperdiet quis senectus. Curabitur sit bibendum fringilla interdum.',
      isRequired: true,
      signLink: 'https://account.docusign.com',
      content,
    },
    {
      id: '002',
      dueDate: '04/25/2022',
      version: '1.023',
      signed: false,
      show: false,
      title: 'Nibh libero netus dictum varius',
      description:
        'Vel amet ullamcorper vestibulum vivamus amet, egestas. Amet ultrices massa a fringilla vivamus.',
      isRequired: true,
      signLink: 'https://account.docusign.com',
      content,
    },
    {
      id: '003',
      dueDate: '04/25/2022',
      version: '1.023',
      signed: false,
      show: false,
      title: 'In faucibus neque morbi id elit.',
      description:
        'Netus et pretium, elit enim, quis bibendum. Amet eget arcu aliquam donec feugiat pellentesque est mauris.',
      isRequired: true,
      signLink: 'https://account.docusign.com',
      content,
    },
    {
      id: '004',
      title: 'Turpis ante odio sed gravida tellus',
      dueDate: '04/25/2022',
      version: '1.023',
      signed: false,
      show: false,
      description:
        'Fermentum interdum gravida urna velit, eget porttitor malesuada est. Facilisis nunc semper potenti proin.',
      isRequired: false,
      signLink: 'https://account.docusign.com',
      content,
    },
  ],
  list: [
    {
      id: 1,
      title: 'Gravida cursus est',
      dueDate: '04 / 25 / 2022',
      signed: false,
      show: false,
    },
    {
      id: 2,
      title: 'Scelerisque',
      dueDate: '04 / 25 / 2022',
      signed: false,
      show: false,
    },
    {
      id: 3,
      title: 'Felis mauris ultrices',
      dueDate: '04 / 25 / 2022',
      signed: true,
      show: false,
    },
    {
      id: 4,
      title: 'Quam egestas',
      dueDate: '04 / 25 / 2022',
      signed: true,
      show: false,
    },
    {
      id: 5,
      title: 'Iaculis est ultricies',
      dueDate: '04 / 25 / 2022',
      signed: true,
      show: false,
    },
  ],
  lastOpenedConsent: null,
};

export const slice = createSlice({
  name: 'consentSlice',
  initialState,
  reducers: {
    signConsent: (state, { payload }) => {
      state.list = state.list.map((item) =>
        item.id === payload.id ? { ...item, signed: true } : item
      );
    },
    getConsentById: (state, { payload }) => {
      const consent = state.consents.find(
        (consent) => consent.id === payload.id
      );
      return consent;
    },
    updateLastOpenedConsent: (state, action) => {
      state.lastOpenedConsent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const selectConsent = (id) => (state) =>
  state[slice.name].list.find((item) => item.id === id);

export const { signConsent, getConsentById, updateLastOpenedConsent } =
  slice.actions;

export default slice;
