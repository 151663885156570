import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const personResourceApiSlice = createApi({
  reducerPath: 'personResourceApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['PersonResources'],
  endpoints: (build) => ({
    getAllPersonResources: build.query({
      query: (participantId) => ({
        url: `/resources?filter[person]=${participantId}&include=person-resource`,
        method: 'GET',
      }),
      providesTags: ['PersonResources'],
    }),
    createPersonResource: build.mutation({
      query: ({ participantId, payload }) => ({
        url: `/people/${participantId}/resources`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonResources'],
    }),
    updatePersonResource: build.mutation({
      query: ({ participantId, resourceId, payload }) => ({
        url: `/people/${participantId}/resources/${resourceId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'PersonResource', id: arg.id },
        'PersonResources',
      ],
    }),
  }),
});

export const {
  useGetAllPersonResourcesQuery,
  useCreatePersonResourceMutation,
  useUpdatePersonResourceMutation,
} = personResourceApiSlice;
