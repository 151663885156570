import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';
import {
  StyledFormControl,
  StyledFormHelperText,
  StyledInputAdornment,
  StyledInputStartAdornment,
  StyledInputBase,
  StyledFormLabel,
} from './TextField.styles';

export const TextField = forwardRef(({
  id,
  error,
  errorLabel,
  size,
  fullWidth,
  color,
  icon,
  startIcon,
  label,
  ...rest
}, ref) => (
  <StyledFormControl fullWidth={fullWidth} size={size}>
    {label && <StyledFormLabel>{label}</StyledFormLabel>}
    <StyledInputBase
      ref={ref}
      id={id}
      aria-describedby={`${id}-helper-text`}
      size={size}
      fullWidth={fullWidth}
      icon={icon}
      starticon={startIcon}
      color={color}
      error={error}
      startAdornment={
        startIcon && (
          <StyledInputStartAdornment position="start" size={size}>
            {startIcon}
          </StyledInputStartAdornment>
        )
      }
      endAdornment={
        icon && (
          <StyledInputAdornment position="end" size={size}>
            {icon}
          </StyledInputAdornment>
        )
      }
      {...rest}
    />
    {error && (
      <StyledFormHelperText id={`${id}-helper-text`}>
        <InformationIcon />
        {errorLabel}
      </StyledFormHelperText>
    )}
  </StyledFormControl>
));

TextField.defaultProps = {
  size: 'medium',
  fullWidth: false,
};

TextField.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  errorLabel: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  color: PropTypes.string,
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  label: PropTypes.node,
};
