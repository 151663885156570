import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useDeclineFamilyInvitationMutation } from 'ihp-bloom-redux/features/family/familyApiSlice';

export function useDeclineFamilyInvitation() {
  const { person } = useSelector((state) => state.user);

  const [declineFamilyInvitation, mutation] =
    useDeclineFamilyInvitationMutation();

  function submit({ familyInvitationId }) {
    declineFamilyInvitation({
      familyInvitationId,
      personId: person.id,
    });
  }

  return {
    mutation,
    submit,
  };
}
