import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';

const Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  padding: '0 15px',
  borderRadius: '8px',
  height: '70px',
  boxShadow: '0 0 9px rgba(0, 0, 0, 0.04)',
}));

export const Container = styled(Card)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '10px',
  textDecoration: 'none',
});

export const Content = styled(Box)({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '15px',
  height: '15px',
  color: theme.palette.primary.gray50,
  padding: 0,
}));
