import { Box, styled, Typography } from '@mui/material';

export function FamilyLinkageInvitation({
  children,
  firstName = '',
  lastName = '',
}) {
  const initials =
    firstName[0].toUpperCase() + lastName[0].toUpperCase();

  return (
    <Container>
      <Avatar>{initials}</Avatar>

      <Typography>
        {firstName} {lastName}
      </Typography>

      {children}
    </Container>
  );
}

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.white,
  borderRadius: '8px',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '20px',
  justifyContent: 'flex-start',
  padding: '20px',
  width: '100%',
}));

const Avatar = styled(Box)(({ theme }) => ({
  ...theme.typography.h5,
  alignItems: 'center',
  backgroundColor: theme.palette.primary.gray4,
  borderRadius: '50%',
  color: theme.palette.primary.white,
  display: 'flex',
  fontWeight: 500,
  height: '40px',
  justifyContent: 'center',
  minHeight: '40px',
  minWidth: '40px',
  width: '40px',
}));
