import React from 'react';
import { Grid, Box, List, ListItem, Typography } from '@mui/material';
import { Title, ListText, ResponsiveGrid } from './styles';
import { styled } from '@mui/system';

const CustomList = styled(List)(({ theme }) => ({
  marginLeft: '11px',
}));

const CustomListItem = styled(ListItem)(({ color }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-start',
  paddingRight: 0,
  '&::before': {
    content: '"•"',
    position: 'absolute',
    left: '-4px',
    top: '-4px',
    color: color,
    fontSize: '2em', // Adjust size of the bullet
    lineHeight: '1', // Ensure the bullet is vertically centered
  },
}));

const GridItemWithText = ({ title, list, color, backgroundColor }) => (
  <ResponsiveGrid
    item
    sx={{
      backgroundColor: backgroundColor,
    }}
    xs={12}
    sm={12}
    md={12}
    lg={6}
  >
    <Box>
      <Title color={color}>{title}</Title>
      <CustomList>
        {list.map((item, index) => (
          <CustomListItem key={index} color={color}>
            <ListText color={color}>{item}</ListText>
          </CustomListItem>
        ))}
      </CustomList>
    </Box>
  </ResponsiveGrid>
);

const GridItemWithImage = ({ image }) => (
  <Grid item xs={12} sm={12} md={12} lg={6}>
    <img src={image} style={{ width: '100%', height: '100%' }} alt='image' />
  </Grid>
);

export const GridItemGroup = ({
  image,
  title,
  color,
  backgroundColor,
  list,
  imageFirst = true,
}) => (
  <>
    {imageFirst ? <GridItemWithImage image={image} /> : null}
    <GridItemWithText
      title={title}
      color={color}
      backgroundColor={backgroundColor}
      list={list}
    />
    {!imageFirst ? <GridItemWithImage image={image} /> : null}
  </>
);
