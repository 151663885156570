import { Box, styled } from '@mui/material';
import { ReactComponent as Circle } from 'images/shared/circle-dot.svg';

export const getStyles = (theme) => {
  return {
    chipRequired: {
      fontWeight: 600,
      fontSize: 10,
      padding: '5px 10px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '14px',
      },
      borderColor: theme.palette.primary.magenta100,
      color: theme.palette.primary.magenta100,
    },
    infoChip: {
      border: `1px solid ${theme.palette.primary.lightGray100}`,
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '10px',
    },
  };
};

export const CircleDot = styled(Circle)({
  width: '4px',
  height: '6px',
  bottom: 2,
});

export const TextWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
});

export const ConsentInfo = styled('h2')(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Inter',
  fontSize: 14,
  color: theme.palette.primary.darkGray90,
}));

export const DateInfo = styled('h2')(({ theme }) => ({
  fontWeight: 500,
  bottom: '5px',
  fontFamily: 'Inter',
  fontSize: 14,
  color: theme.palette.primary.darkGray90,
}));

const Tag = styled(Box)({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '140%',
  display: 'flex',
  alignItems: 'self-end',
  gap: '7px',
  boxSizing: 'border-box',
  borderRadius: '50px',
  padding: '0 9px',
  height: '30px',
  textTransform: 'uppercase',
});

export const TagStatus = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  color: '#7C7C7C',
  border: `1px solid #7C7C7C`,
  backgroundColor: theme.palette.primary.white,
}));

export const TagRemaining = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  color: theme.palette.primary.white,
  backgroundColor: '#005587',
}));

export const TagRequired = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  color: theme.palette.primary.magenta100,
  border: `1px solid ${theme.palette.primary.magenta100}`,
  backgroundColor: theme.palette.primary.white,
}));

export const TagArchived = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.gray75,
  backgroundColor: theme.palette.primary.white,
  border: '1px solid #D9D9D9',
}));
