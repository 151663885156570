import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetConsentDetailsQuery } from 'ihp-bloom-redux/features/consent/consentApi';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

import Button from 'ihp-components/components/v2/Button';
import { FormCheckbox } from 'components/Checkbox';
import { SectionTitle } from 'components/InformedConsent';
import { RejectConsentConfirmationModal } from 'components/Profile/RejectConsentConfirmationModal';
import { Loader } from 'components/Loader';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { ReactComponent as CheckIcon } from 'images/profile/check-circle-solid-green.svg';
import { ReactComponent as Clock } from 'images/profile/clock.svg';
import { ReactComponent as ExclamationIcon } from 'images/profile/exclamation-circle.svg';
import { ReactComponent as VersionIcon } from 'images/profile/version.svg';
import { isMobile } from 'utils/isMobile';
import { SignButton } from '../List/styles';
import { MobileDetails } from './Details.mobile';
import {
  ConsentCheckMark,
  Content,
  ContentWrap,
  DesktopConsentText,
  DesktopConsentTextHeader,
  DesktopContainer,
  DesktopPageHeader,
  Footer,
  SignDate,
  Stats,
  StatusChip,
  Version,
  Header,
} from './styles';
import { BaseHeader } from 'components/BaseLayout';
import { format } from 'date-fns';
import { useAcknowledgeConsent } from 'utils/consents';

const ConsentDetails = () => {
  const { consentId, consentVersionId } = useParams();
  const consent_id = parseInt(consentId);
  const consent_version_id = parseInt(consentVersionId);

  const navigate = useNavigate();

  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });
  const { consentDetails, isLoading } = useGetConsentDetailsQuery(
    {
      person_id,
      consent_id,
      consent_version_id,
    },
    {
      skip: !person_id,
      selectFromResult: ({ data: consentDetails, ...rest }) => ({
        consentDetails,
        ...rest,
      }),
    }
  );

  const [consentAgreed, setConsentAgreed] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const onToggle = () => setConsentAgreed(!consentAgreed);
  const handleCancel = () => navigate(-1);

  const [acknowledgeConsent] = useAcknowledgeConsent();

  const handleReject = () => {
    if (!consentDetails?.mandatory) {
      handleCancel();
      return;
    }
    setShowRejectConfirmation(true);
  };

  const handleConfirmRejection = (confirm) => {
    setShowRejectConfirmation(false);
    if (confirm) {
      handleCancel();
    }
  };

  const isSigned = consentDetails?.participant_consent_status === 'completed';
  const isMobileScreen = isMobile();

  if (isMobileScreen) {
    return (
      <MobileDetails
        details={consentDetails}
        isSigned={isSigned}
        handleSign={() => acknowledgeConsent(consent_version_id)}
        handleReject={handleReject}
        handleConfirmRejection={handleConfirmRejection}
      />
    );
  }

  if (isLoading && !consentDetails) return <Loader />;

  return (
    <DesktopContainer>
      <DesktopPageHeader>
        <Header sx={{ marginLeft: '25px' }}>
          <BaseHeader forceShowClickBack={true} to='/profile/consents'>
            {consentDetails?.title}
          </BaseHeader>
        </Header>
        <Stats>
          <SignDate>
            {consentDetails?.signed ? <CheckIcon /> : <Clock />}
            <span>
              {!!consentDetails &&
                format(
                  new Date(
                    consentDetails?.participant_consent_date ?? Date.now()
                  ),
                  'yyy-MM-dd'
                )}
            </span>
          </SignDate>
          <Version>
            <VersionIcon />
            <span>{consentDetails?.consent_version_id}</span>
          </Version>
          <StatusChip isRequired={consentDetails?.mandatory}>
            <ExclamationIcon />
            <span>{consentDetails?.mandatory ? 'required' : 'optional'}</span>
          </StatusChip>
        </Stats>
      </DesktopPageHeader>
      <DesktopConsentText sx={{ position: 'relative' }}>
        <DesktopConsentTextHeader>
          <h3 style={{ textAlign: 'center' }}>INFORMED CONSENT</h3>
          <h2 style={{ textAlign: 'center' }}>{consentDetails?.title}</h2>
        </DesktopConsentTextHeader>
        <Content>
          <div className='body'>
            <Box>
              <SectionTitle>{consentDetails?.name}</SectionTitle>
              <Box>
                <Typography variant='pl3' color='primary.gray75'>
                  <ContentWrap>{consentDetails?.content}</ContentWrap>
                </Typography>
              </Box>
            </Box>
          </div>
          {/* no data from the backend for this for now */}
          {!isSigned && (
            <>
              <ConsentCheckMark>
                <FormCheckbox
                  label='I agree to sign this consent'
                  checked={consentAgreed}
                  onChange={onToggle}
                />
              </ConsentCheckMark>

              <Footer>
                <Button size='lg' variant='link' onClick={handleReject}>
                  Reject
                </Button>
                <SignButton
                  variant='contained'
                  size='large'
                  disabled={!consentAgreed}
                  startIcon={<Sign width={19} height={15} />}
                  onClick={() => acknowledgeConsent(consent_version_id)}
                >
                  Sign consent
                </SignButton>
              </Footer>
            </>
          )}
        </Content>
      </DesktopConsentText>
      <RejectConsentConfirmationModal
        open={showRejectConfirmation}
        onClose={() => setShowRejectConfirmation(false)}
        onConfirmation={handleConfirmRejection}
      />
    </DesktopContainer>
  );
};

export default ConsentDetails;
