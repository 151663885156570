import { format } from 'date-fns';

import { ProgressBar } from 'components/StepperContainer/progressBar';
import { Container, StyledActivityHeader } from './Header.styles';

export const Header = ({
  date,
  currentStep,
  lastStep
}) => (
  <Container>
    <StyledActivityHeader
      title='Add a journal entry'
      durationText={format(date, 'eeee')}
      activityType={format(date, 'MM.dd.yyyy')}
      currentStep={currentStep}
      lastStep={lastStep}
      showStepCounter={true}
    />
    <ProgressBar currentStep={currentStep} stepsLength={lastStep} />
  </Container>
);
