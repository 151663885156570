export const articleScreensMap = {
  howTo: 0,
  description: 1,
  celebration: 2,
};

export const selectFromPatientTaskResult = ({ data, ...rest }) => {
  const attributes = {};
  data?.data.task.attributes.forEach((attribute) => {
    if (
      attribute.attribute === 'duration' ||
      attribute.attribute === 'howTo' ||
      attribute.attribute === 'meta' ||
      attribute.attribute === 'celebrationScreen'
    ) {
      attributes[attribute.attribute] = {
        ...attribute,
        value: attribute.value ? JSON.parse(attribute.value) : attribute.value,
      };
    } else {
      attributes[attribute.attribute] = { ...attribute };
    }
  });

  return {
    participantTaskDetails: {
      ...data?.data,
      task: {
        ...data?.data?.task,
        ...attributes,
      },
    },
    ...rest,
  };
};
