import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ChartContainer = styled(Box)(({ theme }) => ({
  minHeight:'893px',
  width:'100%',
  [theme.breakpoints.down('sm')]: {
    minHeight:'1167px',
  }
}));
