import { Link } from 'react-router-dom';
import { Accordion, AccordionSummary, Box, styled } from '@mui/material';

export const Container = styled(Box)({
  width: '100%',
});

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: theme.palette.primary.white,
  borderRadius: '8px !important',
  marginBottom: '10px',
  width: '100%',
  border: '1px solid',
  borderColor: theme.palette.primary.gray15,
  boxShadow: 'none',
  textAlign: 'left',
  '&.MuiAccordion-root:before': {
    content: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(
  ({ theme, expanded }) => ({
    transition: 'none',
    borderBottom: expanded === 'true' ? 'solid 1px' : 'none',
    borderColor: theme.palette.primary.gray15,
    '& .Mui-expanded': {
      margin: '0px !important',
    },
    '& .MuiAccordionSummary-content': {
      transition: 'none',
    },
  })
);

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.navy,
  textDecoration: 'underline',
  cursor: 'pointer',
}));
