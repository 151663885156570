import { Modal } from '@mui/material';
import { Container, Content, Text, Title } from '../styles';
import Button from 'ihp-components/components/v2/Button';

export function UpdateProfileWarningModal({ onClose, open }) {
  return (
    <Modal onClose={onClose} open={open}>
      <Container>
        <Content>
          <Title>Update Your Profile</Title>

          <Text>
            Please be sure to review and update both the Account Holder Profile
            and Participant Profile sections as part of this activity.
          </Text>

          <Button onClick={onClose} fullWidth>
            Close
          </Button>
        </Content>
      </Container>
    </Modal>
  );
}
