const getStyles = ({ palette, breakpoints }) => ({
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: palette.primary.white,
    boxShadow: 24,
    borderRadius: '8px',
    overflow: 'auto',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [breakpoints.up('sm')]: {
      maxWidth: '710px',
    },
    [breakpoints.down('sm')]: {
      maxWidth: '327px',
      display: 'flex',
      alignItems: 'center',
      top: '47px',
      transform: 'translate(-50%, 0px)',
      height: 'calc(100vh - 96px)',
      overflowY: 'auto',
    },
  },

  titleWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
    padding: '35px 40px',
    borderBottom: `1px solid ${palette.primary.lightGray100}`,
    alignSelf: 'stretch',
    [breakpoints.down('sm')]: {
      padding: '24px 40px 30px 40px',
      borderBottom: '0px',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },

  title: {
    color: palette.primary.darkGray100,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    lineHeight: '140%',
  },
  iconWrapper: {
    marginTop: '3px',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  radioInputWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '40px 40px 20px 40px',
    gap: '15px',
    '& .StyledLabel': {
      textAlign: 'left !important',
      padding: '15px !important',
    },
    [breakpoints.down('sm')]: {
      padding: '0px 24px 24px 24px',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '0px 40px 40px',
    gap: '15px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 24px 24px',
    },
  },
});

export default getStyles;
