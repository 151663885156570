import { createApi } from '@reduxjs/toolkit/query/react';
import { tremendousBaseQuery } from '../__base';

export const tremendousApiSlice = createApi({
  reducerPath: 'tremendousApiSlice',
  tagTypes: ['TremProducts'],
  baseQuery: tremendousBaseQuery(),
  endpoints: (builder) => ({
    getTremProducts: builder.query({
      query: () => ({ url: 'products', method: 'GET' }),
      providesTags: ['TremProducts'],
    }),
  }),
});
export const { useGetTremProductsQuery } = tremendousApiSlice;
