import React from 'react';

import { ReactComponent as OnboardingBadge } from 'images/profile/achievements/onboarding.svg';
import { ReactComponent as OnboardingFilledBadge } from 'images/profile/achievements/onboarding-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';

import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
} from '../styles.mobile.js';

export const MobileOnboardingCompleteCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#F3E7F0' : '#ffffff'}>
      {!inProgress ? <OnboardingBadge /> : <OnboardingFilledBadge />}
      <MobileCardInfoBox>
        <Title>Onboarding complete</Title>
        <StyledSlider
          value={80}
          isCompleted={isCompleted}
          backgroundColor='#9B51E0'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Enthusiast'
            isCompleted={isCompleted}
          />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
