import { styled } from '@mui/material';

export const ButtonContainer = styled('div')(
  ({ theme, bottomFixed, maxWidth = '375px', padding }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    [theme.breakpoints.down('sm')]: {
      position: bottomFixed && 'fixed',
      maxWidth: maxWidth,
      margin: 'auto',
      padding: padding || '0px 16px',
      marginBottom: '20px',
      bottom: 0,
      left: 0,
      right: 0,
    },
  })
);
