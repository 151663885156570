import { createTheme } from '@mui/material/styles';
import '@fontsource/inter';

const palette = {
  primary: {
    main: '#005587',
    white: '#FFFFFF',
    black: '#000000',
    black1: '#231F20',
    blue: '#00A1DF',
    blue1: '#00778B',
    blue2: '#0EA7E0',
    blue3: '#5DCBD7',
    deepBlue: '#005487',
    lightBlue: '#FAFCFD',
    lightBlue1: '#6FB9C3',
    lightBlue2: '#AFCADA',
    lightBlue5: '#005487',
    lightBlue6: '#E3EEFB',
    lightBlue7: '#00548740',
    lightBlue70: '#4CBFE9',
    lightBlue100: '#00A3E0',
    darkBlue5: '#F2F7F9',
    darkBlue10: '#E5EEF3',
    darkBlue50: '#80AAC3',
    darkBlue70: '#4C88AB',
    darkBlue90: '#1A6693',
    darkBlue100: '#085685',
    pattensBlue: '#DEE9F0',
    green: '#00BC70',
    greenTint: '#E5F8F0',
    green5: '#F6FBF5',
    green25: '#D1EBCB',
    green100: '#48AE34',
    greenPKD: '#A3CF5E',
    greenREAD: '#44945C',
    red: '#DF2525',
    red5: '#FDF4F4',
    red70: '#E46B67',
    red80: '#E05551',
    red75: '#E16058',
    red100: '#D82B26',
    lightRed: '#D26E74',
    redAHA: '#C0242B',
    darkRed: '#C0242B',
    gray2: '#FBFBFB',
    gray3: '#E9E9E9',
    grey3: '#F0F0F0',
    gray4: '#BDBDBD',
    grey4: '#BABABA',
    gray7: '#4A4A4A',
    grey7: '#2E2E2E',
    gray5: '#F4F4F4',
    grey5: '#959595',
    gray6: '#424242',
    gray8: '#1E1E1E',
    gray9: '#AAAAAA',
    gray10: '#EAECEC',
    gray11: '#B8B8B8',
    gray15: '#DFE2E3',
    gray20: '#F8F8F8',
    gray25: '#CACED0',
    gray50: '#959CA0',
    gray60: '#6B757A',
    gray70: '#F6F6F6',
    gray75: '#606B71',
    gray80: '#666666',
    gray90: '#888888',
    gray95: '#E4E4E4',
    gray100: '#2B3A41',
    gray120: '#D2D2D2',
    gray130: '#363636',
    gray150: '#393939',
    gray200: '#222222',
    darkGray20: '#DADADA',
    darkGray50: '#A1A1A1',
    darkGray70: '#7C7C7C',
    darkGray90: '#575757',
    darkGray100: '#444444',
    lightGray5: '#FEFEFE',
    lightGray70: '#F6F6F6',
    lightGray100: '#D9D9D9',
    fuchsia: '#FCF9FB',
    fuchsia5: '#F8F2F7',
    fuchsia10: '#F3E7F0',
    fuchsia25: '#DFC0D8',
    fuchsia30: '#DAB6D1',
    fuchsia50: '#C080B1',
    fuchsia75: '#A0438B',
    fuchsia90: '#8E2274',
    fuchsia100: '#810964',
    purple: '#8F80AC',
    helpBlue: '#00A3E0',
    helpBorderBlue: '#4D88AB',
    darkblue70: '#4D88AB',
    darkblue100: '#005587',
    magenta5: '#FCF5FB',
    magenta10: '#F9EAF6',
    magenta70: '#D66DC0',
    magenta100: '#C42EA5',
    orange70: '#FFB34C',
    orange100: '#FF9300',
    lightBlue10: '#E5F6FC',
    lightGreen: '#CCF2F0',
    lightShadegreen: '#4D88AB',
    lightGreen10: '#43B02A40',
    teal20: '#CCF2F0',
    queenBlue: '#33779F',
    darkRed: '#E20000',
    navy: '#011C48',
    mint: '#C1E3A3',
    tealTint: '#E5F8F8',
    avgreen: '#008747',
  },
  secondary: {
    lightBlue4: '#CCDDE7',
    main: '#959CA0',
    gray1: '#333333',
    gray4: '#444444',
  },
  tertiary: {
    main: '#EAECEC',
  },
};

// Common configuration
const commonFontProps = {
  fontFamily: 'Nunito Sans',
  fontStyle: 'normal',
  lineHeight: '140%',
  color: palette.primary.gray100,
};

const headerTypes = {
  h1: {
    fontSize: '36px',
    fontWeight: 900,
    ...commonFontProps,
  },
  h2: {
    fontSize: '24px',
    fontWeight: 600,
    ...commonFontProps,
  },
  h3: {
    fontSize: '22px',
    fontWeight: 600,
    ...commonFontProps,
  },
  h4: {
    fontSize: '20px',
    fontWeight: 600,
    ...commonFontProps,
  },
  h5: {
    fontSize: '18px',
    fontWeight: 800,
    ...commonFontProps,
  },
  h6: {
    fontSize: '16px',
    fontWeight: 600,
    ...commonFontProps,
  },
};

const paragraphBoldTypes = {
  pb1: {
    fontSize: '18px',
    fontWeight: 800,
    ...commonFontProps,
  },
  pb2: {
    fontSize: '16px',
    fontWeight: 800,
    ...commonFontProps,
  },
  pb3: {
    fontSize: '14px',
    fontWeight: 600,
    ...commonFontProps,
  },
  pb4: {
    fontSize: '13px',
    fontWeight: 600,
    ...commonFontProps,
  },
  pb5: {
    fontSize: '12px',
    fontWeight: 600,
    ...commonFontProps,
  },
  pb6: {
    fontSize: '11px',
    fontWeight: 600,
    ...commonFontProps,
  },
  pb7: {
    fontSize: '10px',
    fontWeight: 600,
    ...commonFontProps,
  },
};

const paragraphNormalTypes = {
  pn1: {
    fontSize: '16px',
    fontWeight: 500,
    ...commonFontProps,
  },
  pn2: {
    fontSize: '15px',
    fontWeight: 500,
  },
  pn3: {
    fontSize: '14px',
    fontWeight: 500,
    ...commonFontProps,
  },
  pn4: {
    fontSize: '13px',
    fontWeight: 500,
    ...commonFontProps,
  },
  pn5: {
    fontSize: '12px',
    fontWeight: 500,
    ...commonFontProps,
  },
  pn6: {
    fontSize: '11px',
    fontWeight: 500,
    ...commonFontProps,
  },
  pn7: {
    fontSize: '10px',
    fontWeight: 500,
    ...commonFontProps,
  },
};

const paragraphLightTypes = {
  pl1: {
    fontSize: '16px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl2: {
    fontSize: '15px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl3: {
    fontSize: '14px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl4: {
    fontSize: '13px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl5: {
    fontSize: '12px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl6: {
    fontSize: '11px',
    fontWeight: 400,
    ...commonFontProps,
  },
  pl7: {
    fontSize: '10px',
    fontWeight: 400,
    ...commonFontProps,
  },
};

const typography = {
  ...commonFontProps,
  ...headerTypes,
  ...paragraphBoldTypes,
  ...paragraphNormalTypes,
  ...paragraphLightTypes,
};

export const theme = createTheme({
  palette: palette,
  typography: typography,
});

// Install this colorize extension in VSCode to see colors here!
// https://marketplace.visualstudio.com/items?itemName=kamikillerto.vscode-colorize
// config:
// .vscode/settings.json
// "colorize.languages": [
//     "javascript",
//   ]
