import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';

export function useResearchEligibility() {
  const [
    createPersonStudyArmAttribute,
    { isLoading: isLoadingCreatePersonStudyArmAttribute },
  ] = useCreatePersonStudyArmAttributeMutation();
  const [updatePersonStudyArm, { isLoading: isLoadingUpdatePersonStudyArm }] =
    useUpdatePersonStudyArmMutation();

  const createPersonStudyArmAttributeRecord = (personId, options) => {
    const payload = {
      data: options.map((option) => {
        const key = Object.keys(option)[0];
        const value = option.checked ? 'true' : 'false';

        return {
          type: 'person-study-arm-attributes',
          attributes: {
            attribute: key,
            value: value,
          },
        };
      }),
    };

    return createPersonStudyArmAttribute({
      personStudyArmId: personId,
      payload,
    });
  };

  const updatePersonStudyArmRecord = (id, studyArmStatusId) => {
    const payload = {
      data: {
        type: 'person-study-arms',
        id: id + '',
        attributes: {
          study_arm_status_id: studyArmStatusId,
        },
      },
    };

    return updatePersonStudyArm({
      personStudyArmId: id,
      payload,
    });
  };

  return {
    updatePersonStudyArmRecord,
    createPersonStudyArmAttributeRecord,
    isLoading:
      isLoadingCreatePersonStudyArmAttribute || isLoadingUpdatePersonStudyArm,
  };
}
