import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const taskApiSlice = createApi({
  reducerPath: 'taskApiV3',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Task', 'TaskType'],
  endpoints: (build) => ({
    getTask: build.query({
      query: ({ taskId, ...params }) => ({
        url: `/tasks/${taskId}?include=attributes,versions`,
        method: 'GET',
        params,
      }),
      providesTags: ['Task'],
    }),
    getTaskType: build.query({
      query: ({ taskTypeId, ...params }) => ({
        url: `/task-types/${taskTypeId}`,
        method: 'GET',
        params,
      }),
      providesTags: ['TaskType'],
    }),
    getTaskTypes: build.query({
      query: (params) => ({
        url: `/task-types`,
        method: 'GET',
        params
      }),
      providesTags: ['TaskTypes'],
    }),
  }),
});

export const { useGetTaskQuery, useGetTaskTypeQuery, useGetTaskTypesQuery } = taskApiSlice;
