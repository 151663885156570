import { Box, Button } from '@mui/material';
import { styled } from '@mui/system';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',
}));

export const Title = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  fontSize: '24px',
}));

export const InfoMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontWeight: 400,
  fontSize: '12px',
  marginTop: '10px',
  maxWidth: '300px',
  textAlign: 'center',
}));

export const GetStartedButton = (props) => (
  <Button
    sx={{
      width: '186px',
      height: '50px',
      background: 'transparent',
      color: theme => theme.palette.primary.fuchsia75,
      border: '2px solid',
      borderColor: theme => theme.palette.primary.fuchsia75,
      borderRadius: 2,
      padding: '15px',
      marginTop: '20px',
      fontWeight: 600,
      fontSize: '16px',
      textTransform: 'none',
      '&: hover': {
        background: theme => theme.palette.primary.white,
      },
    }}
    {...props}
  />
);

export const StyledArrowIcon = styled(ArrowForwardIcon)(() => ({
  marginLeft: '8px',
  width: '15px',
  height: '15px',
}));
