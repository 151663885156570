import { Box, Grid, Snackbar, SnackbarContent } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  getAccountHolderProfileTabInfo,
  FIELD_TYPE_RENDERERS_MAP,
  notification_preferences,
} from 'utils/configParseUtils';
import { ContactTitle, Footer, StyledFilledButton, saveIcon } from './styles';
import {
  useDeletePersonAttributesMutation,
  useEditPersonAttributesMutation,
} from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { getPayload } from '../../MyProfile/utils';
import { LinkButton } from 'components/Buttons';
import { withPersonAttributes } from 'hocs/withPersonAttributes';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import { ReactComponent as PhoneIcon } from 'images/profile/phone.svg';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { useEffect, useState } from 'react';

import PhoneNumberVerificationModal from './PhoneVerification';

import {removeSpecialCharactersFromPhone, useRegexValidatePhone} from 'utils/formValidation';

const notificationPreferenceOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS/Text',
    value: 'sms',
  },
];

const notificationPreferenceDefaultValue = 'email';
const phoneDefaultValue = 'phone';

const ContactInfo = (props) => {
  const { person, activeAccountProfile } = useSelector((state) => state.user);

  const countryName =
      activeAccountProfile?.attributes?.person?.attributes?.country;

  const { participantProfileConfig } = useSelector(
    (state) => state.configuration
  );
  const [phoneNumberSnack, setPhoneNumberSnack] = useState(false);
  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();
  const [deletePersonAttributes, { isLoading: isDeletingPersonAttributes }] =
    useDeletePersonAttributesMutation();

  const { fieldsMap, isTabReadOnly } = getAccountHolderProfileTabInfo(
    participantProfileConfig,
    'Contact Info'
  );

  const validatePhone = useRegexValidatePhone();

  // available fields list from configuration

  let fieldShown = [];

  fieldsMap?.forEach((group) => {
    if (group.fields?.length) {
      fieldShown = [...fieldShown, ...group.fields];
    }
  });

  const availableFields = fieldShown.map((field) => {
    if (field.show) {
      return field.name;
    }
  });

  const initialValueAttributes = {};
  Object.keys(props.personAttributes || {})?.forEach((key) => {
    if (availableFields?.includes(key)) {
      initialValueAttributes[key] = props.personAttributes[key];
    }
  });

  const defaultValues = {
    email: '',
    phone: '+',
    notification_preferences: notificationPreferenceDefaultValue,
    ...initialValueAttributes,
  };

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    getValues,
    watch,
    trigger,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
  });

  const [showPhoneNumberVerification, setShowPhoneNumberVerification] =
    useState(false);

  const allFormValues = watch();
  const checkboxValue = watch('notification_preferences', false);
  const phoneNumber = watch('phone');

  const handleCancel = () => {
    reset();
  };

  const handleModalClose = async (verified = false) => {
    if (!verified) {
      removeSms(allFormValues);
      setValue('notification_preferences', notificationPreferenceDefaultValue);
      const notification_preferences = getValues('notification_preferences');
      await editPersonAttributes({
        personId: person.id,
        payload: [
          {
            data: {
              type: 'person-attributes',
              attributes: {
                attribute: 'notification_preferences',
                value: notification_preferences.replace(',sms', ''),
              },
            },
          },
        ],
      });
      setPhoneNumberSnack(true);
    }
    props?.refetchPersonAttributes();
    setShowPhoneNumberVerification(false);
  };

  const removeSms = (notificationObject) => {
    if (notificationObject && notificationObject.notification_preferences) {
      notificationObject.notification_preferences =
        notificationObject.notification_preferences.replace(',sms', '');
    }
  };

  const deletePersonAttributeFunc = (deleteAttributesList, personId) => {
    deletePersonAttributes({
      personId: personId,
      payload: { attributes: deleteAttributesList },
    })
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          return;
        }
      })
      .catch(console.error);
  };

  const onSubmit = (data) => {
    data.phone = removeSpecialCharactersFromPhone(data.phone);

    if (data.phone === '' || data.phone === '+') {
      delete data.phone;
      data.notification_preferences = data.notification_preferences.replace(
        ',sms',
        ''
      );
      setValue('notification_preferences', notificationPreferenceDefaultValue);
      if (!isEmpty(props?.personAttributes?.['phone'])) {
        const deleteAttributesList = ['phone'];
        if (props?.personAttributes?.['sms_ph_verified'] === '1') {
          deleteAttributesList.push('sms_ph_verified');
          setPhoneNumberSnack(true);
        }
        deletePersonAttributeFunc(deleteAttributesList, person.id);
      }
    } else if (
      props?.personAttributes?.['sms_ph_verified'] === '1' &&
      !checkboxValue?.includes('sms')
    ) {
      deletePersonAttributeFunc(['sms_ph_verified'], person.id);
      setPhoneNumberSnack(true);
    }

    let payload = getPayload({
      ...data,
    });
    editPersonAttributes({
      personId: person.id,
      payload,
    })
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          return;
        } else {
          if (
            checkboxValue?.includes('sms') &&
            !isEmpty(data?.phone) &&
            (props?.personAttributes?.['sms_ph_verified'] !== '1' ||
              data.phone !== props?.personAttributes?.['phone'])
          ) {
            props?.personAttributes?.['sms_ph_verified'] === '1' &&
              deletePersonAttributeFunc(['sms_ph_verified'], person.id);
            setShowPhoneNumberVerification(true);
          }
        }
      })
      .catch(console.error);
  };

  const handleCloseSnackBar = () => {
    setPhoneNumberSnack(false);
  };

  useEffect(() => {
    async function validatePhoneNumber() {
      await trigger(phoneDefaultValue);
    }
    validatePhoneNumber();
  }, [getValues(phoneDefaultValue)]);

  useEffect(async () => {
    const getPhoneInputValue = getValues(phoneDefaultValue);
    if (
      checkboxValue &&
      checkboxValue?.includes('sms') &&
      (getPhoneInputValue === '+' || isEmpty(getPhoneInputValue))
    ) {
      setError(phoneDefaultValue, {
        message: 'This field is required.',
      });
    } else {
      clearErrors(phoneDefaultValue);
    }
  }, [checkboxValue]);

  return (
    <div>
      {showPhoneNumberVerification && (
        <PhoneNumberVerificationModal
          isOpen={showPhoneNumberVerification}
          setIsOpenModal={setShowPhoneNumberVerification}
          onClose={handleModalClose}
        />
      )}
      <>
        <Box>
          <form>
            {fieldsMap?.map((group) => {
              return (
                <div>
                  <ContactTitle>{group?.name}</ContactTitle>
                  <Grid container rowSpacing={3} columnSpacing={3}>
                    {group?.fields?.map((mappedField) => {
                      let {
                        type,
                        name,
                        label,
                        placeholder,
                        Component: Field,
                        disabled,
                        colSpan,
                        validation,
                        show,
                        required,
                        ...rest
                      } = mappedField;
                      label = `${label} ${
                        !mappedField?.required ? '(Optional)' : ''
                      }`;
                      const customRenderer =
                        FIELD_TYPE_RENDERERS_MAP[mappedField.name];
                      const containsSms =
                        checkboxValue && checkboxValue?.includes('sms');
                      const customProps = {};
                      if (name === notification_preferences) {
                        customProps.defaultValue =
                          notificationPreferenceDefaultValue;
                        customProps.options = notificationPreferenceOptions;
                        customProps.disabled = isEditingPersonAttributes;
                      }
                      if (name === notificationPreferenceDefaultValue) {
                        customProps.endIcon = <EmailIcon color='#D9D9D9' />;
                      }
                      if (name === phoneDefaultValue) {
                        if(containsSms) {
                          validation = {
                            required: mappedField?.required,
                            validate: (value) => validatePhone(value, countryName)
                          };
                        }else{
                          validation = {
                            required: false,
                            validate: (value) => validatePhone(value, countryName)
                          };
                        }
                      }

                      if (name === phoneDefaultValue) {
                        customProps.disabled = isEditingPersonAttributes;
                        customProps.endIcon = <PhoneIcon color='#D9D9D9' />;
                      }
                      if (!show) return null;
                      return (
                        <Grid item xs={colSpan} key={name}>
                          <Controller
                            name={name}
                            control={control}
                            rules={validation}
                            render={({ field }) =>
                              customRenderer ? (
                                customRenderer({
                                  label,
                                  placeholder,
                                  name,
                                  country: countryName,
                                  error: errors?.[name],
                                  errorLabel:
                                    errors?.[name]?.message ||
                                    'This field is required',
                                  ...rest,
                                  ...field,
                                  ...customProps,
                                })
                              ) : (
                                <Field
                                  name={name}
                                  error={errors?.[name]}
                                  errorLabel={
                                    errors?.[name]?.message ||
                                    'This field is required'
                                  }
                                  fullWidth={true}
                                  label={label}
                                  placeholder={placeholder}
                                  disabled={disabled}
                                  {...customProps}
                                  {...rest}
                                  {...field}
                                />
                              )
                            }
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              );
            })}
          </form>
        </Box>
      </>
      {!isTabReadOnly && (
        <Footer>
          <StyledFilledButton
            onClick={handleSubmit(onSubmit)}
            startIcon={<CheckIcon width={14} height={14} />}
            width={102}
            disabled={isEditingPersonAttributes}
            loading={
              isEditingPersonAttributes ||
              isDeletingPersonAttributes ||
              props?.isFetchingPersonAttributes
            }
          >
            Save
          </StyledFilledButton>
          <LinkButton
            disabled={isEditingPersonAttributes || isDeletingPersonAttributes}
            variant='link'
            onClick={handleCancel}
          >
            Cancel
          </LinkButton>
          <Snackbar
            open={phoneNumberSnack}
            autoHideDuration={4000}
            onClose={handleCloseSnackBar}
          >
            <SnackbarContent
              style={{
                backgroundColor: 'green',
              }}
              message={
                <span>
                  <DoneIcon style={saveIcon} /> Your phone number has been
                  removed from the study. You will no longer receive SMS
                  notifications
                </span>
              }
            />
          </Snackbar>
        </Footer>
      )}
    </div>
  );
};

export default withPersonAttributes(ContactInfo);
