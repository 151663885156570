import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';

import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';

import { FilledButton } from 'components/Buttons';

import { ReactComponent as XIcon } from 'images/shared/x.svg';
import {
  ModalContainer,
  ModalTitle,
  ModalContentWrapper,
  Title,
  Footer,
  TitleSeparator,
  styles,
  StyledLinkButton,
} from './style';

const CommunicationPreferenceEditModal = (props) => {
  const { open, onClose, onModalContinue, interestList } = props;
  const [selectedInterestCategory, setSelectedInterestCategory] = useState({});
  const [checkboxOnChangeVal, setCheckboxOnChangeVal] = useState(true);

  const categoryNo = +interestList?.category;
  const categoryTitles = {
    1: 'Topics you are interested in receiving information about:',
    2: 'Single Ventricle disorders you want to learn more about:',
    3: 'Information related to any of the following genes that can contribute to the risk of SV Source:',
    4: 'I want to receive information for the following people in a diagnosed SV Source participant’s life:',
  };

  useEffect(() => {
    if (interestList?.category) {
      const clone = JSON.parse(JSON.stringify(interestList));
      setSelectedInterestCategory(clone);
    }
  }, [interestList]);

  const handleOnChange = (code) => {
    setCheckboxOnChangeVal(false);
    const interestsCopy = { ...selectedInterestCategory };
    const updatedOptionList = interestsCopy.options.map((option) => {
      option.checked = code === option.code ? !option.checked : option.checked;
      return option;
    });
    interestsCopy.options = updatedOptionList;
    setSelectedInterestCategory(interestsCopy);
  };

  const onCancelClick = () => {
    setCheckboxOnChangeVal(true);
    setSelectedInterestCategory(interestList);
    onClose();
  };
  const onContinue = () => {
    setCheckboxOnChangeVal(true);
    onModalContinue(selectedInterestCategory);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContainer>
        <ModalTitle>
          Select your interests
          <XIcon
            width='12px'
            height='18px'
            color='#888888'
            onClick={(e) => onCancelClick()}
          />
        </ModalTitle>
        <TitleSeparator />
        <ModalContentWrapper>
          <Title>{categoryTitles[categoryNo]}</Title>

          {selectedInterestCategory?.options?.map((option) => (
            <FormCheckbox
              containerStyle={
                option.checked
                  ? styles.activeCheckboxStyle
                  : styles.uncheckedCheckboxStyle
              }
              key={option.code}
              value={option.code}
              label={option.label}
              checked={option.checked}
              onChange={() => handleOnChange(option.code)}
            />
          ))}

          <Footer>
            <StyledLinkButton onClick={() => onCancelClick()}>
              Cancel
            </StyledLinkButton>
            <FilledButton
              onClick={() => onContinue(selectedInterestCategory)}
              disabled={checkboxOnChangeVal}
            >
              Continue
            </FilledButton>
          </Footer>
        </ModalContentWrapper>
      </ModalContainer>
    </Modal>
  );
};

export default CommunicationPreferenceEditModal;
