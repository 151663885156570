import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setIsConsented } from 'ihp-bloom-redux/features/authentication/authenticationSlice';

import InformedConsentMobile from './index.mobile';
import Button from 'ihp-components/components/v2/Button';
import { OnboardingContainer } from 'components/Onboarding';
import BodyContainer from 'components/InformedConsent/BodyContainer';
import { isMobile } from 'utils/isMobile';
import getStyles, { StyledButtonText } from './styles/index.styles';
import { data } from 'mocks/informedconsent';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { Logo } from 'components/shared/Logo';
import { Loader } from 'components/Loader';

function InformedConsent() {
  document.title = 'Single Ventricle SOURCE Consent';
  const theme = useTheme();
  const styles = getStyles(theme);
  const dispatch = useDispatch();
  const [consentAgreed, setConsentAgreed] = useState(false);
  const { isConsented } = useSelector((state) => state.authentication);
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();

  const navigateToStudyOverview = () => {
    dispatch(setIsConsented());
    navigate('/final');
  };
  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  if (isMobile()) {
    return <InformedConsentMobile />;
  }

  return (
    <OnboardingContainer>
      <Logo width='80px' style={styles.logo} />
      <Grid container>
        <Grid item xl={12} sx={styles.mainContainer}>
          <BodyContainer
            title='INFORMED CONSENT'
            data={data}
            consentAgreed={consentAgreed}
            onToggle={() => setConsentAgreed(!consentAgreed)}
          />
        </Grid>
        <Grid item xl={12} sx={styles.footerContainer}>
          <Box sx={styles.buttonContainer} component='div'>
            <Button
              fullWidth={true}
              onClick={navigateToStudyOverview}
              disabled={!consentAgreed}
            >
              <Sign width={25} />
              <StyledButtonText>Sign consent</StyledButtonText>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </OnboardingContainer>
  );
}
export default InformedConsent;
