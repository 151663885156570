import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { InputAdornment } from '@mui/material';
import {
  ColorPickerWrapper,
  ColorPickerBox,
  SelectedColorIndicator,
} from './styles';
import { useOutsideAlerter } from '../../utils/useOutsideAlerter';
import { TextField } from '../TextField';

export const ColorPicker = ({
  size,
  fullWidth,
  color,
  onChange,
  defaultColor,
  ...rest
}) => {
  const [value, setValue] = useState(color || '');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);
  const handleInputChange = (event) => setValue(event.target.value);
  const handleColorChange = ({ hex }) => {
    setValue(hex);
    if (typeof onChange === 'function') onChange(hex);
  };
  const preventClickEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const colorPickerBoxRef = useRef();
  useOutsideAlerter(colorPickerBoxRef, () => setShowColorPicker(false));

  return (
    <ColorPickerWrapper size={size} fullWidth={fullWidth}>
      <TextField
        {...rest}
        ref={rest.inputRef}
        size={size}
        fullWidth={fullWidth}
        value={value}
        onChange={handleInputChange}
        onClick={toggleColorPicker}
        endIcon={
          <InputAdornment position='end'>
            <SelectedColorIndicator
              size={size}
              selectedColor={value || defaultColor}
            />
          </InputAdornment>
        }
      />
      {showColorPicker && (
        <ColorPickerBox
          ref={colorPickerBoxRef}
          size={size}
          onClick={preventClickEvent}
        >
          <ChromePicker
            color={value || defaultColor}
            onChangeComplete={handleColorChange}
          />
        </ColorPickerBox>
      )}
    </ColorPickerWrapper>
  );
};

ColorPicker.defaultProps = {
  size: 'md',
  defaultColor: '#000000',
};

ColorPicker.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  color: PropTypes.string,
  defaultColor: PropTypes.string,
  onChange: PropTypes.func, // This function will get hex string as an argument
};
