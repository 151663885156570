import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const fields = [
  {
    id: '1',
    fieldName: 'First Name',
    typeOfField: 'InputField',
    optionOfField: 'TextField',
    enabled: true,
  },
  {
    id: '2',
    fieldName: 'Phone Number',
    typeOfField: 'InputField',
    optionOfField: 'NumberInput',
    enabled: false,
  },
  {
    id: '3',
    fieldName: 'Date Of Birth',
    typeOfField: 'DateField',
    optionOfField: 'Datepicker',
    enabled: false,
  },
  {
    id: '4',
    fieldName: 'Gender',
    typeOfField: 'SelectField',
    optionOfField: 'SelectInput',
    enabled: false,
    options: [],
  },
];

const types = [
  {
    id: 'InputField',
    name: 'Input',
  },
  {
    id: 'DateField',
    name: 'Datepicker',
  },
  {
    id: 'SelectField',
    name: 'Select',
  },
];

const options = {
  InputField: [
    {
      id: 'TextField',
      name: 'Text',
    },
    {
      id: 'NumberInput',
      name: 'Number',
    },
    {
      id: 'PasswordInput',
      name: 'Password',
    },
  ],
  DateField: [
    {
      id: 'Datepicker',
      name: 'Datepicker',
    },
  ],
  SelectField: [
    {
      id: 'SelectInput',
      name: 'Select',
    },
  ],
};

const initialState = {
  fields,
  types,
  options,
};

export const slice = createSlice({
  name: 'accountFields',
  initialState,
  reducers: {
    addField: (state, { payload }) => {
      const id = uuidv4();
      state.fields.push({ id, ...payload });
    },
    updateField: (state, { payload }) => {
      const { id, fieldName, typeId, optionId } = payload;
      let fieldToUpdate = state.fields.find((field) => field.id === id);
      if (fieldToUpdate) {
        fieldToUpdate.fieldName = fieldName;
        fieldToUpdate.typeId = typeId;
        fieldToUpdate.optionId = optionId;
      }
    },
    reorderField: (state, { payload }) => {
      const { from, to } = payload;
      const [removed] = state.fields.splice(from, 1);
      state.fields.splice(to, 0, removed);
    },
    enableField: (state, { payload }) => {
      const { id, enabled } = payload;
      let fieldToEnable = state.fields.find((field) => field.id === id);
      fieldToEnable.enabled = enabled;
    },
    deleteField: (state, { payload }) => {
      const { id } = payload;
      const fieldIndexToDelete = state.fields.findIndex(
        (field) => field.id === id
      );
      state.fields.splice(fieldIndexToDelete, 1);
    },
  },
});

export const { addField, updateField, reorderField, enableField, deleteField } =
  slice.actions;

export const selectFieldIds = (state) =>
  state[slice.name].fields.map((item) => item.id);
export const selectFieldById = (id) => (state) =>
  state[slice.name].fields.find((item) => item.id === id);

export default slice;
