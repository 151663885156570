import { forwardRef } from 'react';
import { styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';

export const TextInput = styled(
  forwardRef(({ haserrors, ...rest }, ref) => <InputBase ref={ref} {...rest} />)
)(({ theme, haserrors, startAdornment }) => {
  const borderColor = haserrors
    ? theme.palette.primary.red75
    : theme.palette.primary.gray15;

  return {
    '& .MuiInputBase-input': {
      padding: '15px 15px 15px 20px',
      paddingLeft: startAdornment ? '10px' : '15px',
    },

    background: theme.palette.primary.white,

    color: theme.palette.primary.gray100,
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '17px',
    opacity: 0.8,

    border: `1px solid ${borderColor}`,
    borderRadius: '6px',
    position: 'relative',

    boxSizing: 'border-box',

    resize: 'none',
    '&:hover': { borderColor },
    '&.Mui-focused': {
      boxShadow: `0 0 0 1px ${theme.palette.primary.fuchsia75}`,
      borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&::placeholder': {
      color: theme.palette.primary.gray50,
    },
  };
});
