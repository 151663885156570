import React from 'react';
import { Grid } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { useNavigate } from 'react-router-dom';
import BodyContainer from 'components/InformedConsent/BodyContainer';
import { useTheme } from '@mui/material/styles';
import { Logo } from 'components/shared/Logo';
import getStyles from './styles';
import { data } from 'mocks/studyoverview';
function StudyOverviewMobile() {
  const theme = useTheme();
  const styles = getStyles(theme);
  let navigate = useNavigate();

  const redirect = () => {
    navigate('/final');
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: theme.palette.primary.fuchsia,
      }}
    >
      <Logo />
      <Grid item sx={styles.mainContainerMob}>
        <BodyContainer title='STUDY OVERVIEW' data={data} />
      </Grid>

      <Grid item xs={12} sx={styles.stickyFooter}>
        <Button fullWidth={true} onClick={redirect}>
          Continue
        </Button>
      </Grid>
    </Grid>
  );
}
export default StudyOverviewMobile;
