import background from 'images/background.svg';

const getStyles = ({ palette, breakpoints }) => ({
  title: {
    color: '#2B3A41',
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
  },
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
  },
  formContainer: {
    background: palette.primary.white,
    marginTop: '40px',
    padding: '20px 20px 40px 20px',
    boxShadow: '  0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
  },
  formHeader: {
    paddingTop: '25px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
    svg: {
      width: '101px',
    },
  },
  fieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
  },
  firstNameFieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
  },
  lastNameFieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
    [breakpoints.down('sm')]: {
      paddingTop: '0px',
    },
  },
  stateFieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
  },
  cityFieldContainer: {
    paddingTop: '25px',
    width: '100%',
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingRight: '40px !important',
    },
  },
  leftBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gridGap: 200,
    padding: '35px',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: palette.primary.white,
    minHeight: '100vh',
  },
  mainContainerMob: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionBox: {
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '100%',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stickyFooter: {
    position: 'fixed',
    bottom: 0,
    backgroundColor: palette.primary.white,
    width: '100%',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '45px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  radio: {
    width: '145px',
    '& .StyledLabel': {
      opacity: 1,
      color: palette.primary.gray75,
      borderRadius: '8px',
      minHeight: '48px',
      padding: '14px 15px 10px 50px',
    },
  },
  icon: {
    width: '20px',
    position: 'absolute',
    right: 19,
    top: 68,
  },
  datePicker: {
    borderRadius: '8px',
    borderColor: palette.primary.gray15,
    '&:hover': {
      borderColor: palette.primary.gray15,
    },
    '&.Mui-focused': {
      borderColor: palette.primary.fuchsia75,
    },
    '& .MuiInputBase-input::placeholder': {
      color: palette.primary.gray75,
      opacity: 1,
    },
  },
  input: {
    height: '48px',
    opacity: 1,
    borderRadius: '8px',
    '& ::placeholder': {
      color: palette.primary.gray75,
      opacity: 1,
    },
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '8px',
    color: palette.primary.gray75,
  },
  selectInput: {
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
      opacity: 1,
      borderColor: palette.primary.gray15,
      '&: hover': {
        borderColor: palette.primary.gray50,
      },
    },
  },
  submitButton: {
    '&:disabled': {
      opacity: 1,
      background: palette.primary.fuchsia75,
    },
  },
  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 560,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    [breakpoints.down('sm')]: {
      width: '100%',
      top: '0px',
      transform: 'translate(-50%, 0)',
      height: '100vh',
      overflowY: 'auto',
    },
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '30px 40px',
    borderBottom: `1px solid ${palette.primary.pattensBlue}`,
    [breakpoints.down('sm')]: {
      padding: '24px',
      borderBottom: '0px',
      justifyContent: 'center',
    },
  },
  iconWrapper: {
    marginTop: '3px',
    cursor: 'pointer',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    padding: '20px 40px',
    [breakpoints.down('sm')]: {
      padding: '0px 24px 24px',
    },
  },
  continueButton: { width: '100%' },
  cancelLink: {
    margin: 'auto',
    [breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  fieldsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '20px',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  formWrapper: {
    padding: '15px 40px 40px',
    [breakpoints.down('sm')]: {
      padding: '0px 24px 24px',
    },
  },
});

export default getStyles;
