import { Box, styled } from '@mui/material';
import { ReactComponent as Calendar } from 'ihp-components/components/v2/assets/svg/calendar.svg';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-2.svg';

export const CalendarIcon = styled(Calendar)(({ theme }) => ({
  width: '24px',
  height: '24px',
  [theme.breakpoints.down('sm')]: {
    display: 'inline !important',
  },
}));

export const DropDownIcon = styled(ChevronDown)(({ theme }) => ({
  '&.MuiSelect-icon': {
    width: '16px',
    height: '16px',
    marginTop: '2px',
    color: theme.palette.primary.lightGray100,
    right: '20px!important',
  },
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '10px',
}));

export const FieldContainer = styled(Box)(({ theme }) => ({
  paddingTop: '25px',
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));
