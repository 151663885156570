import { styled } from '@mui/system';
import { Typography } from '@mui/material';

import background from 'images/background.svg';

export const LoginContainer = styled('div')(({ theme }) => ({
  backgroundImage: `url(${background})`,
  backgroundSize: 'cover',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const FormContainer = styled('div')({
  alignItems: 'center',
  justifyContent: 'center',
});

export const EmailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '25px',
});

export const Title = styled((props) => (
  <Typography variant='h4' color='primary.gray100' {...props} />
))({
  fontWeight: 700,
});

export const Description = styled((props) => (
  <Typography color='secondary.gray4' align='center' {...props} />
))({
  width: 295,
  paddingTop: 20,
  paddingBottom: 50,
  fontWeight: '600',
  fontSize: 16,
});

export const DividerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5px',
  paddingTop: 25,
  paddingBottom: 25,
});

export const Line = styled('div')(({ theme }) => ({
  width: 132.16,
  height: 1,
  background: theme.palette.primary.pattensBlue,
}));

export const OrText = (props) => (
  <Typography variant='pl5' color='primary.gray75' align='center' {...props} />
);

export const ButtonsContainer = styled('div')({
  display: 'grid',
  gridGap: 15,
  gridTemplateRows: 'repeat(3,1fr)',
});

export const TermsOfUseContainer = styled((props) => (
  <Typography variant='pl5' color='primary.gray100' align='center' {...props} />
))(({ theme }) => ({
  paddingBottom: 40,
  width: 295,
}));

export const FormLabel = (props) => (
  <Typography variant='h4' color='secondary.gray4' align='center' {...props} />
);
