import { ReactComponent as ChecklistIcon } from 'images/activity/types/checklist.svg';
import { ReactComponent as ConsentIcon } from 'images/activity/types/consent-color.svg';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock.svg';
import { ReactComponent as PlayCircleIcon } from 'images/activity/types/play-circle.svg';
import { ReactComponent as ArticleIcon } from 'images/activity/types/article.svg';
import { ReactComponent as ScheduleTelevisitIcon } from 'images/activity/types/schedule-televisit.svg';
import { ReactComponent as MakeTelevisitIcon } from 'images/activity/types/make-televisit.svg';
import { ReactComponent as ScheduleTelehealthIcon } from 'images/activity/types/schedule-telehealth.svg';
import { ReactComponent as MakeTelehealthIcon } from 'images/activity/types/make-telehealth.svg';

export const taskStatsPositionMap = {
  type: '0',
  estimatedTime: '1',
  timeLimit: '2',
  points: '3',
};

export const ActivityTypesIconMap = {
  survey: <ChecklistIcon width={25} height={25} />,
  consent: <ConsentIcon width={25} height={25} />,
  video: <PlayCircleIcon width={25} height={25} />,
  medicalHistory: <ClockIcon width={25} height={25} />,
  article: <ArticleIcon width={25} height={25} />,
  'data-sharing': <ClockIcon width={25} height={25} />,
  'schedule-televisit': <ScheduleTelevisitIcon width={25} height={25} />,
  'make-televisit': <MakeTelevisitIcon width={25} height={25} />,
  'schedule-telehealth': <ScheduleTelehealthIcon width={25} height={25} />,
  'make-telehealth': <MakeTelehealthIcon width={25} height={25} />,
  call: <MakeTelehealthIcon width={25} height={25} />,
  televisit_video: <MakeTelevisitIcon width={25} height={25} />,
};
