import { useLocation } from 'react-router-dom';

import Button from 'ihp-components/components/v2/Button';
import {
  UnblockEmailWrapper,
  Container,
  Row,
  TitleContainer,
  ButtonContainer,
  PageTitle,
  HelperText,
} from './styles';
import { ReactComponent as ExclamationCircle } from 'images/shared/exclamation-circle.svg';

function Auth0EmailUnblock() {
  document.title = 'Single Ventricle SOURCE Unblock Your Email Address';

  const params = useLocation();

  const unblock = () => {
    const queryParams = new URLSearchParams(params.search);
    const url = queryParams.get('url');

    window.location.replace(url);
  };

  return (
    <UnblockEmailWrapper>
      <Container>
        <Row>
          <ExclamationCircle width='295' height='181' />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>You’re almost there</PageTitle>
        </TitleContainer>
        <Row>
          <HelperText varient='pl3'>
            Unblock your email by pressing ‘Unblock’ below.
          </HelperText>
        </Row>
        <ButtonContainer>
          <Button onClick={unblock} fullWidth>
            Unblock
          </Button>
        </ButtonContainer>
      </Container>
    </UnblockEmailWrapper>
  );
}

export default Auth0EmailUnblock;
