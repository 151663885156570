import { Divider, Stack } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';

import Edit from './Edit';

const SubmitToolbar = ({ onSubmit, errors }) => {
  const hasErrors = Object.entries(errors).reduce(
    (acc, [, value]) => acc || !!value,
    false
  );
  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      spacing='30px'
      padding='20px'
    >
      <Button width='130px' disabled={hasErrors} onClick={onSubmit}>
        Submit
      </Button>
    </Stack>
  );
};

const Setup = ({ onSubmit, onChange, value, onErrors, errors }) => (
  <Stack divider={<Divider orientation='horizontal' flexItem />}>
    <Stack
      padding='20px'
      spacing='20px'
      divider={<Divider orientation='horizontal' flexItem />}
    >
      <Edit
        name='task'
        placeholder='Enter JSON Task...'
        value={value.task}
        onChange={onChange}
        error={errors.task}
        onError={onErrors}
      />
      <Edit
        name='defaultValues'
        placeholder='Enter JSON default values...'
        value={value.defaultValues}
        onChange={onChange}
        error={errors.defaultValues}
        onError={onErrors}
      />
    </Stack>
    <SubmitToolbar onSubmit={onSubmit} errors={errors} />
  </Stack>
);

export default Setup;
