import { Box, MenuItem, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { Input } from '../../../../ihp-components/components/v2/Input';
import { FilledButton } from '../../../components/Buttons';
import { Text, Title } from '../components/Text';
import { validateDate } from 'utils/formValidation';
import { Body, DropDownIcon, Footer, FormContainer, Row } from './styles';

import { ReactComponent as AvLogo } from 'images/svs-logo.svg';
import { pronouns } from './constants';
import { useNavigate } from 'react-router-dom';
import { isAboveStateAgeMajority } from 'pages/Onboarding/BasicInformation/helper';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {firstNameLabel, lastNameLabel, dobLabel} from './constants';
import { isParticipantChild } from './utils';

function ParticipantInformationForm({ initialState, onSubmit, role }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const stateProvince =
    user.stateProvince ||
    person?.included?.find((attr) => attr?.data?.attributes?.name === 'state')
      ?.data?.attributes?.value ||
    '';

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: initialState,
  });

  const roleTextMap = {
    r_parent_sv: 'child',
    r_parent_nsv: 'child',
    r_lar_sv: 'dependent',
    r_lar_nsv: 'dependent',
  };

  const dependentText = roleTextMap[role] || '';

  const dateOfBirth = watch('date_of_birth');
  const isParticipantChildOrNot = isParticipantChild(role);

  function validateDateOfBirth(date) {
    const isValidDate = validateDate(date);

    if (isValidDate !== true) {
      return isValidDate;
    }

    if (role === 'r_parent_sv' || role === 'r_parent_nsv') {
      if (isAboveStateAgeMajority(dateOfBirth, stateProvince)) {
        return "You've entered in a date of birth for an adult. Please review the birth date. If the dependent is an adult, they must enroll themselves.";
      }
    }

    return true;
  }

  return (
    <>
      <Row>
        <AvLogo />
      </Row>
      <Title>Who is the person being enrolled?</Title>
      <Text marginTop={'5px'} marginBottom={'30px'}>
        Please enter the information of the {dependentText}.
      </Text>
      <Body>
        <Stack mt={4}>
          <FormContainer>
            <Box component='div'>
              <Controller
                name='first_name'
                control={control}
                rules={{
                  required: 'This field is required.',
                }}
                haserrors={errors.first_name}
                render={({ field }) => (
                  <Input
                    name='first_name'
                    label={isParticipantChildOrNot ? `Child's ${firstNameLabel}` : `Dependent's ${firstNameLabel}`}
                    value={''}
                    fullWidth={true}
                    placeholder='Enter first name'
                    error={!!errors.first_name}
                    errorLabel={errors.first_name?.message}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='middle_name'
                control={control}
                rules={{
                  required: false,
                }}
                haserrors={errors.middle_name}
                render={({ field }) => (
                  <Input
                    name='middle_name'
                    label='Middle Name (Optional)'
                    error={errors.middle_name}
                    errorLabel={errors?.middle_name?.message}
                    value={''}
                    fullWidth={true}
                    placeholder='Enter middle name'
                    {...field}
                  />
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='last_name'
                control={control}
                rules={{
                  required: 'This field is required.',
                }}
                haserrors={errors.last_name}
                render={({ field }) => (
                  <Input
                    name='last_name'
                    label={isParticipantChildOrNot ? `Child's ${lastNameLabel}` : `Dependent's ${lastNameLabel}`}
                    value={''}
                    fullWidth={true}
                    placeholder='Enter last name'
                    error={errors.last_name}
                    errorLabel={errors?.last_name?.message}
                    {...field}
                  />
                )}
              />
            </Box>
            {/*SING-921*/}
            {/* <Box component='div'>
              <Controller
                name='pronouns'
                control={control}
                render={({ field }) => (
                  <Input
                    type='select'
                    label='Select pronouns'
                    placeholder='Select pronouns'
                    fieldLabel='Pronouns'
                    error={errors.pronouns}
                    disabled={!pronouns?.length}
                    IconComponent={(props) => <DropDownIcon {...props} />}
                    fullWidth
                    {...field}
                  >
                    {pronouns?.map((p) => (
                      <MenuItem key={p} value={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box> */}
            {/*SING-921*/}
            <Box
              component='div'
              sx={{
                svg: {
                  minHeight: '16px',
                  minWidth: '16px',
                },
              }}
            >
              <Controller
                name='date_of_birth'
                control={control}
                rules={{
                  required: true,
                  validate: validateDateOfBirth,
                }}
                render={({ field }) => (
                  <Input
                    name='date_of_birth'
                    type='datePicker'
                    disableFuture
                    label={isParticipantChildOrNot ? `Child's ${dobLabel}` : `Dependent's ${dobLabel}`}
                    value={''}
                    fullWidth={true}
                    placeholder='Select date'
                    error={errors.date_of_birth}
                    errorLabel={
                      errors?.date_of_birth?.message || 'This field is required'
                    }
                    {...field}
                  />
                )}
              />
            </Box>
          </FormContainer>
        </Stack>
      </Body>
      <Footer>
        <FilledButton
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Save & continue
        </FilledButton>

        <FilledButton fullWidth onClick={() => navigate(0)} variant='link'>
          Go back
        </FilledButton>
      </Footer>
    </>
  );
}

export default ParticipantInformationForm;
