import { v4 as uuid } from 'uuid';

import createQuestionsSlice from '../utils/questions/createQuestionsSlice.js';

const initialEntities = [
  {
    title: 'Integer dapibus sed lacus porta blandit?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    fileFormat: '',
    numberOfAnswers: '',
    description: '',
    answers: [],
  },
  {
    title: 'Donec rutrum libero a magna porttitor?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    fileFormat: '',
    numberOfAnswers: '',
    description: '',
    answers: [],
  },
  {
    title: 'Morbi pulvinar ex sit amet?',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    fileFormat: '',
    numberOfAnswers: '',
    description: '',
    answers: [],
  },
  {
    title: 'Fusce molestie egestas ligula. Vel elementum?',
    enabled: false,
    required: false,
    typeOfAnswer: '',
    fileFormat: '',
    numberOfAnswers: '',
    description: '',
    answers: [],
  },
  {
    title: 'Proin tempus magna ut.',
    enabled: true,
    required: false,
    typeOfAnswer: '',
    fileFormat: '',
    numberOfAnswers: '',
    description: '',
    answers: [],
  },
];

const initialState = { ids: [], entities: {} };
initialEntities.forEach((entity) => {
  const id = uuid();
  initialState.ids.push(id);
  initialState.entities[id] = { id, ...entity };
});

const slice = createQuestionsSlice({
  name: 'prescreening',
  initialState,
});

export const {
  reorderQuestion,
  enableQuestion,
  addQuestion,
  removeQuestion,
  updateQuestion,
} = slice.actions;

export const selectQuestionIds = () => (state) => state[slice.name].ids;
export const selectQuestionById = (id) => (state) =>
  state[slice.name].entities[id];

export default slice;
