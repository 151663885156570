import React from 'react';

import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as NameIcon } from 'images/shared/user-circle.svg';
import { ReactComponent as EmailIcon } from 'images/shared/envelope.svg';
import {
  UserBasicInfoWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
  FormContainer,
  AcmeLogo,
  BackLink,
} from './styles/BasicInfo.styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useForm, Controller } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { Input } from 'ihp-components/components/v2/Input';

function UserBasicInfo() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: user,
  });
  const gray25 = useTheme().palette.primary.gray25;

  const onSubmit = (data) => {
    dispatch(setValues({ ...data, study_role_id: 1 }));
    navigate('/health-check');
  };

  return (
    <UserBasicInfoWrapper>
      <Container>
        <Row>
          <AcmeLogo />
        </Row>
        <Row marginBottom='5px'>
          <PageTitle variant='h2'>Enter your information</PageTitle>
        </Row>
        <Row marginBottom='15px'>
          <HelperText variant='pl3'>
            Please add your name and email to start yout sign up process.
          </HelperText>
        </Row>
        <Row marginBottom='27px'>
          <form>
            <FormContainer>
              <Controller
                name='firstName'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.firstName}
                render={({ field }) => (
                  <Input
                    name='firstName'
                    error={errors.firstName}
                    errorLabel={errors.firstName?.message}
                    placeholder='First Name'
                    fullWidth={true}
                    endIcon={<NameIcon color={gray25} />}
                    {...field}
                  />
                )}
              />
            </FormContainer>
            <FormContainer>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.lastName}
                render={({ field }) => (
                  <Input
                    name='lastName'
                    error={errors.lastName}
                    errorLabel={errors.lastName?.message}
                    placeholder='Last Name'
                    fullWidth={true}
                    endIcon={<NameIcon color={gray25} />}
                    {...field}
                  />
                )}
              />
            </FormContainer>
            <FormContainer>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    error={errors.email}
                    errorLabel={errors.email?.message}
                    placeholder='Email'
                    fullWidth={true}
                    endIcon={<EmailIcon color={gray25} />}
                    {...field}
                  />
                )}
              />
            </FormContainer>
            <FormContainer>
              <Button fullWidth={true} onClick={handleSubmit(onSubmit)}>
                Continue
              </Button>
            </FormContainer>
          </form>
        </Row>
        <Row>
          <BackLink to='/onboarding/welcome-to-study'>Back to home</BackLink>
        </Row>
      </Container>
    </UserBasicInfoWrapper>
  );
}

export default UserBasicInfo;
