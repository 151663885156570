import {
  CardContainer,
  CardContent,
  ContentWrapper,
  CardDescription,
  CardSubtitler,
  CardTitle,
} from './Card.style';
import PropTypes from 'prop-types';

export const Card = ({
  title,
  subtitle,
  description,
  mainIcon,
  tags,
  actionButton,
  styles,
}) => {
  return (
    <CardContainer container>
      {mainIcon}
      <ContentWrapper>
        <CardContent>
          <CardTitle style={styles?.cardTitleStyle}>{title}</CardTitle>
          <CardSubtitler>{subtitle}</CardSubtitler>
          <CardDescription style={styles?.cardContentStyle}>
            {description}
          </CardDescription>
        </CardContent>
        {tags}
      </ContentWrapper>
      {actionButton}
    </CardContainer>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  mainIcon: PropTypes.element.isRequired,
  actionButton: PropTypes.element.isRequired,
  styleClass: PropTypes.string,
};
