import { ReactComponent as Medications } from 'images/data-sync/prescriptions.svg';
import { ReactComponent as HealthStatus } from 'images/data-sync/default-category.svg';
import { ReactComponent as Procedures } from 'images/data-sync/procedures.svg';
import { ReactComponent as Problems } from 'images/data-sync/problems.svg';
import { ReactComponent as Immunizations } from 'images/data-sync/immunizations.svg';
import { ReactComponent as Allergies } from 'images/data-sync/allergies.svg';
import { ReactComponent as Laboratory } from 'images/data-sync/lab-results.svg';
import { ReactComponent as PatientDemographics } from 'images/data-sync/patient-demographics.svg';
import { ReactComponent as ClinicalTests } from 'images/data-sync/clinical-tests.svg';
import { ReactComponent as EncounterInformation } from 'images/data-sync/encounter-information.svg';
import { FlexedBox, IconWrapper, NextPrevNames } from './styles';
import { Stack } from '@mui/material';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as NextProvider } from 'images/data-sync/next.svg';
import { ReactComponent as PrevProvider } from 'images/data-sync/prev.svg';
import { ReactComponent as ShortNextProvider } from 'images/data-sync/short-next.svg';
import { ReactComponent as ShortPrevProvider } from 'images/data-sync/short-prev.svg';

export const SvgCategoryImageMap = {
  HealthStatus,
  Laboratory,
  Medications,
  Problems,
  Immunizations,
  Allergies,
  PatientDemographics,
  EncounterInformation,
  ClinicalTests,
  Procedures,
};

const imgSize = isMobile() ? 30 : 50;
const maxWidth = isMobile() ? { maxWidth: '180px' } : {};

export const generateLeftProvider = (provider, providers, icon, navigate) => {
  return (
    <FlexedBox
      sx={{ marginTop: '13px', cursor: 'pointer', ...maxWidth }}
      onClick={() =>
        navigate('/activity/provider', { state: { provider, providers } })
      }
    >
      <IconWrapper>
        <img
          src={provider.logo}
          alt='health center'
          width={imgSize}
          height={imgSize}
        />
      </IconWrapper>
      <Stack sx={{ margin: '10px 0px 0px 20px' }}>
        <FlexedBox sx={{ marginLeft: '-5px' }}>{icon}</FlexedBox>
        <NextPrevNames>{provider.name}</NextPrevNames>
      </Stack>
    </FlexedBox>
  );
};

export const generateRightProvider = (provider, providers, icon, navigate) => {
  return (
    <FlexedBox
      sx={{ marginTop: '13px', cursor: 'pointer', ...maxWidth }}
      onClick={() =>
        navigate('/activity/provider', { state: { provider, providers } })
      }
    >
      <Stack sx={{ margin: '10px 0px 0px 20px' }}>
        <FlexedBox sx={{ flexDirection: 'row-reverse', marginRight: '12px' }}>
          {icon}
        </FlexedBox>
        <NextPrevNames
          sx={{ flexDirection: 'row-reverse', marginRight: '12px' }}
        >
          {provider.name}
        </NextPrevNames>
      </Stack>
      <IconWrapper>
        <img
          src={provider.logo}
          alt='health center'
          width={imgSize}
          height={imgSize}
        />
      </IconWrapper>
    </FlexedBox>
  );
};

export const getProviderData = (providerData) => {
  const r4Data = providerData[0]?.resources?.r4;
  if (r4Data != null) {
    return r4Data;
  }

  const dstu2Data = providerData[0]?.resources?.dstu2;
  if (dstu2Data != null) {
    return dstu2Data;
  }

  return null;
};

export const prev = isMobile() ? (
  <ShortPrevProvider width={67} height={12} />
) : (
  <PrevProvider width={119} height={12} />
);

export const next = isMobile() ? (
  <ShortNextProvider width={43} height={12} />
) : (
  <NextProvider width={119} height={12} />
);
