import React from 'react';
import {
  Container,
  TextContainer,
  Title,
  Subtitle,
  Wrapper,
  JoinNowBtn,
} from './styles';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import iconFamilySrc from 'images/welcome/icon-family.png';
// import { NewsletterLink } from 'pages/Welcome/Header/Banner';

export const GetStarted = ({ onGetStarted }) => {
  return (
    <Container>
      <Wrapper>
        <img src={iconFamilySrc} alt='icon-family' />
        <TextContainer>
          <Title variant='h2'>Get started</Title>
          <Subtitle>
            Start the prescreening process to see if you are eligible to
            continue with the rest of the study.
          </Subtitle>
          {/* <NewsletterLink> */}
            <JoinNowBtn
              onClick={() => onGetStarted('signup')}
              endIcon={<ArrowRight width='16px' />}
            >
              Join Now
            </JoinNowBtn>
          {/* </NewsletterLink> */}
        </TextContainer>
      </Wrapper>
    </Container>
  );
};
