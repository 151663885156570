import { useConvertPointsMutation } from 'ihp-bloom-redux/features/profile/walletApiSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { profileApi } from 'ihp-bloom-redux/features/profile/profileApi';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useEffect, useState } from 'react';

import { ButtonsBar, Container, Content, Header } from './components';

const Redeem = ({
  availablePoints,
  pointsPerDollar,
  onCancel,
  onSuccess,
  threshold,
  studyConfig,
}) => {
  const [selectedPoints, setSelectedPoints] = useState(availablePoints || 0);
  const [error, setError] = useState(false);

  const [{ isLoading: isOrderLoading }] = profileApi.useCreateOrderMutation();

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
      ...rest,
    }),
  });

  const { refetch } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const [convertPoints, { isLoading }] = useConvertPointsMutation();

  const handleClaim = async () => {
    try {
      const response = await convertPoints({
        person_id: person?.id,
        payload: {
          study_id: 1,
          points_redeemed: selectedPoints,
          amount_redeemed: selectedPoints,
          currency: 'USD',
        },
      });

      if (response.error) {
        setError(response.error.data.error);
        return;
      }

      const orderData = JSON.parse(
        response.data.data.attributes.transaction_details
      );
      refetch();
      if (orderData.order) {
        onSuccess && onSuccess();
        onCancel && onCancel();
        return window.open(
          orderData.order.rewards[0].delivery.link,
          '_blank',
          'noopener,noreferrer'
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (availablePoints > 0) {
      setSelectedPoints(availablePoints);
    }
  }, [availablePoints]);

  return (
    <Container>
      {/* <Header onBack={onCancel} /> */}
      <Content
        availablePoints={availablePoints}
        pointsPerDollar={pointsPerDollar}
        value={selectedPoints}
        onChange={setSelectedPoints}
        error={error}
        studyConfig={studyConfig}
      />
      <ButtonsBar
        threshold={studyConfig.thresholdMinAmount}
        selectedDollar={selectedPoints / pointsPerDollar}
        selectedPoints={selectedPoints}
        onClaim={handleClaim}
        isLoading={isLoading || isOrderLoading}
        onCancel={onCancel}
      />
    </Container>
  );
};

export default Redeem;
