import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  urls: {
    production: '',
    uat: '',
    test: '',
  },
};
const urlSlice = createSlice({
  name: 'url',
  initialState,
  reducers: {
    setUrlValue: (state, action) => {
      const { name, value } = action.payload;
      state.urls[name] = value;
    },
    saveUrls: () => {
      //make api calls to save data
      alert('urls saved :D');
    },
  },
});

export const { setUrlValue, saveUrls } = urlSlice.actions;

export default urlSlice.reducer;
