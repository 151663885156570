import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up("sm")]: {
    flexDirection: 'column-reverse'
  }
}));
