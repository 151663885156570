import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontSize: '12px',
  whiteSpace: 'pre-line',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginBottom: '10px',
  },
}));
