/*
 Auth0 callback route is responsible to handle all processing and status based navigation it will be acting as a middleware
*/

import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useAppEventsMutation } from 'ihp-bloom-redux/features/user/userApiSliceV3';

import { Loader } from 'components/Loader';
import { withPerson } from 'authentication/withPerson';
import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';
import getRoute from 'utils/navigator';
import { useVerifyEmail } from './useVerifyEmail';
import { withPersonAttributes } from 'hocs/withPersonAttributes';
import { withSelectedProfile } from 'hocs/withSelectedProfile';

function Auth0Callback({ person, userId, authenticationStatus }) {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();

  const { activeAccountProfile, accountProfiles } = useSelector(
    (state) => state.user
  );
  const { configuration } = useSelector((state) => state.configuration);

  const [appEvents, { isLoading: isLoggingLastLogin }] = useAppEventsMutation();

  /**
   * Fetch and save active account profile
   */

  const {
    isLoadingAccountProfileDetails,
    defaultProfileNotFound,
    noActiveProfiles,
  } = useGetAccountProfileDetails({
    personId: person?.id,
    shouldSelectDefaultProfile: true,
  });

  /**
   * Make sure to verify email in database
   */

  const { verifyingEnrollmentFlag } = useVerifyEmail(
    authenticationStatus,
    userId,
    activeAccountProfile,
    isLoadingAccountProfileDetails
  );

  console.log({
    isLoadingAccountProfileDetails,
    defaultProfileNotFound,
    noActiveProfiles,
    verifyingEnrollmentFlag,
    isLoggingLastLogin,
  });

  const isLoading =
    isLoadingAccountProfileDetails ||
    verifyingEnrollmentFlag ||
    isLoggingLastLogin;

  console.log('Auth0 callback route');
  useEffect(() => {
    /**
     * Make sure navigation is triggered once
     * authenticationStatus = true AND activeAccountProfile has attributes && isLoading = false
     */

    const isActiveAccountProfileSet = activeAccountProfile?.id !== null;

    if (authenticationStatus && isActiveAccountProfileSet && !isLoading) {
      const targetRoute = getRoute({
        authenticationStatus,
        accountProfiles,
        activeAccountProfile,
        studyArmConfigurations: configuration,
      });
      if (targetRoute) {
        navigate(targetRoute);
      }
    }
  }, [
    navigate,
    activeAccountProfile,
    isLoading,
    configuration,
    accountProfiles,
    authenticationStatus,
  ]);

  useEffect(() => {
    /**
     * We need to trigger this api to log last login time in server
     */
    const payload = {
      data: {
        type: 'last-login',
      },
    };
    appEvents({ payload });
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (noActiveProfiles) {
    return <Navigate to='/no-active-profile' replace />;
  }

  if (defaultProfileNotFound) {
    return <Navigate to='/select-profile' replace />;
  }

  if (
    isAuthenticated &&
    !user?.email_verified &&
    person?.id &&
    !authenticationStatus &&
    activeAccountProfile?.id &&
    !isLoadingAccountProfileDetails
  ) {
    return <Navigate to='/onboarding/verify-email' replace />;
  }

  return (
    <div>
      <Loader />
    </div>
  );
}

export default withPerson(
  withPersonAttributes(withSelectedProfile(Auth0Callback, false))
);
