import { useIsValidDate } from 'hooks/useIsValidDate';

export const useValidateDate = () => {
  const { isValidDate } = useIsValidDate();
  return (dateOfBirth) => {
    const minYear = new Date().getFullYear() - 150;
    const dateOfBirthDate = new Date(dateOfBirth);
    const isValid =
      dateOfBirth &&
      isValidDate(dateOfBirth) &&
      Date.parse(dateOfBirth) <= Date.parse(new Date()) &&
      dateOfBirthDate.getFullYear() >= minYear;

    return isValid || 'Please enter a valid date of birth';
  };
};

export const useValidatePhone = () => (phone) =>
  !phone.includes('_') || 'This phone number is invalid';

export const useValidateZipCode = () => (zipCode) =>
  !zipCode.includes('_') || 'This field should consist five digits';

export const useValidateEmail = () => (email) => {
  if (email === '') {
    return true;
  }

  const multipleAdjacentDotsRegex = new RegExp('\\b([.])\\1+\\b');
  if (multipleAdjacentDotsRegex.test(email)) {
    return 'This email is invalid';
  }

  if (email?.length > 0 && email[0] === '.') {
    return 'This email is invalid';
  }

  const parts = email?.split('@');
  if (parts?.length === 2) {
    if (parts[0].indexOf('#') > -1) {
      return 'This email is invalid';
    }
    if (parts[0].slice(-1) === '-') {
      return 'This email is invalid';
    }
  }

  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) || 'This email is invalid'
  );
};
