import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, Stack, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setOnboardingReward } from 'ihp-bloom-redux/features/user/userSlice';
import {
  useEditPersonAttributesMutation,
  useEditPersonMutation,
  useParticipantGoalMutation,
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { Input } from 'ihp-components/components/v2/Input';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import Button from 'ihp-components/components/v2/Button';

import { Loader } from 'components/Loader';
import { FormLabel } from 'components/Login';
import { Logo } from 'components/shared';
import { getStyles } from '../styles/additionalinformation.mobile.styles';
import { useValidateDate, useValidateZipCode } from '../hooks';
import { ReactComponent as TrashIcon } from 'images/profile/alternativecontacts/trash-bin.svg';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { getPayload } from './utils';
import { ReactComponent as LocationIcon } from 'images/personalinformation/location.svg';
import { ReactComponent as ArrowDownIcon } from 'images/components/chevron-down-1.svg';
import { findFirstErrorComponent } from 'utils/formValidation';
import { findStudyAttribute } from 'utils/study';

function AdditionalInformationMobile({ person }) {
  document.title = 'Single Ventricle SOURCE Additional Information';
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { studyConfiguration } = useSelector((state) => state.configuration);
  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const [triggerReward, { isLoading: isProcessingReward }] =
    useParticipantGoalMutation();
  const { isAuthenticated } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const [formerNames, setFormerNames] = useState([]);

  const personalDetailsConfiguration = findStudyAttribute(
    studyConfiguration,
    'personal_details_configuration'
  );

  const {
    additional_attributes: additionalAttributes = {},
    alternative_contacts = false,
    doctor_information = false,
  } = personalDetailsConfiguration || {};

  const dateOfBirthRef = useRef(null);
  const postalCodeRef = useRef(null);
  const sexAtBirthRef = useRef(null);
  const formerNameRef = useRef(null);
  const ethnicityRef = useRef(null);
  const raceRef = useRef(null);
  const recruitmentSourceRef = useRef(null);
  const maidenNameRef = useRef(null);

  const refMap = {
    date_of_birth: dateOfBirthRef,
    sex_at_birth: sexAtBirthRef,
    postal_code: postalCodeRef,
    former_name: formerNameRef,
    ethnicity: ethnicityRef,
    race: raceRef,
    recruitment_source: recruitmentSourceRef,
    maiden_name: maidenNameRef,
  };

  useEffect(() => findFirstErrorComponent(errors, refMap));

  const validateDate = useValidateDate();
  const validateZipCode = useValidateZipCode();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...user,
      date_of_birth: '',
      sex_at_birth: '',
      postal_code: '',
      former_name: '',
      ethnicity: '',
      race: '',
      recruitment_source: '',
      maidan_name: '',
    },
  });

  const addFormerName = () => {
    const value = getValues('former_name');
    if (value) {
      setFormerNames([...formerNames, getValues('former_name')]);
      setValue('former_name', '');
    }
  };

  const removeFormerName = (formerName) => {
    const newNames = formerNames.filter((item) => item !== formerName);
    setFormerNames(newNames);
  };

  const onSubmit = async (data) => {
    let payloadNames = [...formerNames];
    if (!payloadNames.length && additionalAttributes?.former_names) {
      const former_name = getValues('former_name');
      if (!former_name) {
        setError('former_name', 'former_name is required!');
        return;
      }
      payloadNames = [...payloadNames, former_name];
    }

    let status = 'onboarded';
    if (alternative_contacts || doctor_information) {
      status = 'registered';
    }
    const personAttributesPayload = getPayload({
      ...data,
      former_name: JSON.stringify(payloadNames),
    });
    const editPersonPayload = {
      data: {
        type: 'people',
        id: `${person.id}`,
        attributes: {
          state: 'enrollment',
          status,
          status_reason: status,
        },
      },
    };
    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person.id,
        payload: personAttributesPayload,
      });
      if (!personAttributesResponse.error) {
        const personResponse = await editPerson({
          personId: person.id,
          payload: editPersonPayload,
        });
        if (!personResponse.error) {
          if (status === 'onboarded') {
            const rewardResponse = await triggerReward({
              personId: person.id,
              payload: {
                data: {
                  process: 'onboarding',
                },
              },
            });
            if (!rewardResponse?.error && rewardResponse?.data) {
              dispatch(setOnboardingReward(rewardResponse.data?.data || []));
            }
          }
          if (alternative_contacts) {
            navigate('/onboarding/alternativecontact');
          } else if (doctor_information) {
            navigate('/onboarding/doctors');
          } else {
            navigate('/onboarding/create-profile');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  return (
    <Grid container sx={styles.container}>
      <Box sx={styles.formContainer}>
        <Box sx={styles.formHeader}>
          <Logo />
          <FormLabel fontSize='24px' marginTop='15px'>
            Additional information
          </FormLabel>
          <Typography
            color='primary.gray75'
            fontSize='14px'
            textAlign='center'
            width='295px'
          >
            We just need a bit more information to complete your profile
          </Typography>
        </Box>
        <Box padding='0px 25px'>
          <form>
            {additionalAttributes?.date_of_birth && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='date_of_birth'
                  control={control}
                  rules={{
                    validate: validateDate,
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      label='Date of birth'
                      error={errors.date_of_birth}
                      errorLabel={
                        errors?.date_of_birth?.message ||
                        'This field is required'
                      }
                      type='datePicker'
                      datePickerIcon={ArrowDownIcon}
                      value={user.date_of_birth}
                      fullWidth
                      placeholder='Select date'
                      inputRef={dateOfBirthRef}
                      {...field}
                    />
                  )}
                />
              </Box>
            )}
            {additionalAttributes?.maidan_name && (
              <>
                <Box sx={styles.fieldContainer}>
                  <Controller
                    name='maidan_name'
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Input
                        name='maiden_name'
                        error={errors.maidan_name}
                        errorLabel={
                          errors?.maidan_name?.message ||
                          'This field is required'
                        }
                        label='Maidan Name'
                        fullWidth={true}
                        placeholder='Use last name if same as maiden name'
                        inputRef={maidenNameRef}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.additionalFormerName}>
                  <Button
                    size='xs'
                    variant='link'
                    startIcon={<PlusIcon width={14} height={14} />}
                    onClick={addFormerName}
                  >
                    Add additional former name
                  </Button>
                </Box>
              </>
            )}
            {additionalAttributes?.sex_at_birth && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='sex_at_birth'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      type='radioInputGroup'
                      direction='column'
                      label='Sex assigned at birth'
                      id='sex_at_birth'
                      name='sex_at_birth'
                      value={user.sex_at_birth}
                      error={errors.sex_at_birth}
                      errorLabel={errors.sex_at_birth?.message}
                      inputRef={sexAtBirthRef}
                      {...field}
                    >
                      <RadioInput
                        hideRadioIcon={false}
                        fullWidth
                        value='male'
                        label='Male'
                      />
                      <RadioInput
                        hideRadioIcon={false}
                        fullWidth
                        value='female'
                        label='Female'
                      />
                      <RadioInput
                        hideRadioIcon={false}
                        fullWidth
                        value='prefer_not_to_say'
                        label='Prefer not to say'
                      />
                    </Input>
                  )}
                />
              </Box>
            )}
            {formerNames.length > 0 && (
              <Box sx={styles.fieldContainer}>
                <Stack
                  justifyContent='space-between'
                  sx={styles.formerNamesContainer}
                >
                  {formerNames.map((formerName) => (
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      sx={styles.formerName}
                    >
                      <Typography variant='pb3'>{formerName}</Typography>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => removeFormerName(formerName)}
                      >
                        <TrashIcon width='14px' height='14px' />
                      </Box>
                    </Stack>
                  ))}
                </Stack>
              </Box>
            )}

            {additionalAttributes?.former_names && (
              <>
                <Box sx={styles.fieldContainer}>
                  <Controller
                    name='former_name'
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <Input
                        label='Former Name'
                        error={errors.former_name}
                        errorLabel={
                          errors?.former_name?.message ||
                          'This field is required'
                        }
                        fullWidth={true}
                        placeholder='Enter former name'
                        inputRef={formerNameRef}
                        {...field}
                      />
                    )}
                  />
                </Box>
                <Box sx={styles.additionalNameContainer}>
                  <Button
                    size='xs'
                    variant='link'
                    startIcon={<PlusIcon width={14} height={14} />}
                    onClick={addFormerName}
                  >
                    Add additional former name
                  </Button>
                </Box>
              </>
            )}
            {additionalAttributes?.ethnicity && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='ethnicity'
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <Input
                      label='Ethnicity'
                      name='ethnicity'
                      error={errors.ethnicity}
                      errorLabel={
                        errors?.ethnicity?.message || 'This field is required'
                      }
                      fullWidth={true}
                      placeholder='Enter ethnicity'
                      inputRef={ethnicityRef}
                      {...field}
                    />
                  )}
                />
              </Box>
            )}
            {additionalAttributes?.postal_code && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='postal_code'
                  control={control}
                  rules={{
                    validate: validateZipCode,
                    required: true,
                  }}
                  render={({ field }) => (
                    <MaskedInput
                      placeholder='Enter zip code'
                      mask='99999'
                      {...field}
                    >
                      {(inputProps) => (
                        <Input
                          label='ZIP code'
                          name='postal_code'
                          error={errors.postal_code}
                          errorLabel={
                            errors?.postal_code?.message ||
                            'This field is required'
                          }
                          fullWidth
                          endIcon={<LocationIcon color='#DFE2E3' />}
                          inputRef={postalCodeRef}
                          {...inputProps}
                        />
                      )}
                    </MaskedInput>
                  )}
                />
              </Box>
            )}
            {additionalAttributes?.race && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='race'
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <Input
                      name='race'
                      error={errors.race}
                      errorLabel={
                        errors?.race?.message || 'This field is required'
                      }
                      label='Race'
                      fullWidth={true}
                      placeholder='Enter race'
                      inputRef={raceRef}
                      {...field}
                    />
                  )}
                />
              </Box>
            )}
            {additionalAttributes?.recruitment_source && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='recruitment_source'
                  control={control}
                  rules={{
                    required: false,
                  }}
                  haserrors={
                    errors.recruitment_source && {
                      type: 'custom',
                      message:
                        errors?.recruitment_source?.message ||
                        'This field is required',
                    }
                  }
                  render={({ field }) => (
                    <Input
                      name='recruitment_source'
                      label='Recruitment source'
                      error={errors.recruitment_source}
                      errorLabel={
                        errors?.recruitment_source?.message ||
                        'This field is required'
                      }
                      fullWidth={true}
                      placeholder='Enter race'
                      inputRef={recruitmentSourceRef}
                      {...field}
                    />
                  )}
                />
              </Box>
            )}
            <Box sx={styles.fieldContainer}>
              <Button
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isLoading={
                  isEditingPersonAttributes ||
                  isEditingPerson ||
                  isProcessingReward
                }
                sx={styles.submitButton}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
}

export default AdditionalInformationMobile;
