import { styled } from '@mui/system';

export const Form = styled('form')({
  width: '100%',
});

export const getStyles = (theme) => {
  return {
    icon: {
      position: 'absolute',
      width: 20,
      right: 19,
      top: 67,
    },
    formHeader: {
      paddingTop: '25px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '5px',
      svg: {
        width: '101px',
      },
    },
    formContainer: {
      paddingTop: '25px',
      width: '100%',
      position: 'relative',
      '& .MuiInputBase-input': {
        paddingRight: '40px !important',
      },
    },
    radioGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      gap: '10px',
    },
    radio: {
      minWidth: '150px',
    },
    datePicker: {
      borderRadius: '8px',
      borderColor: theme.palette.primary.gray15,
      '&:hover': {
        borderColor: theme.palette.primary.gray15,
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.darkBlue100,
      },
      '& .MuiInputBase-input::placeholder': {
        color: theme.palette.primary.gray75,
        opacity: 1,
      },
    },
    input: {
      height: '48px',
      opacity: 1,
      borderRadius: '8px',
      '& ::placeholder': {
        color: theme.palette.primary.gray75,
        opacity: 1,
      },
    },
    inputLabel: {
      fontSize: '14px',
      marginBottom: '8px',
      color: theme.palette.primary.gray75,
    },
    selectInput: {
      '& .MuiInputBase-root': {
        height: '48px',
        borderRadius: '8px',
        opacity: 1,
        borderColor: theme.palette.primary.gray15,
        '&: hover': {
          borderColor: theme.palette.primary.gray50,
        },
      },
    },
    submitButton: {
      '&:disabled': {
        opacity: 1,
        background: theme.palette.primary.darkBlue100,
      },
    },
  };
};
