import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';

import { ReactComponent as CheckIcon } from 'images/shared/check-white-2.svg';

import {
  FiltersContent,
  FiltersLayoutWrapper,
  Footer,
  XButton,
  StyledXIcon,
} from './styles';

export const FiltersLayout = (props) => {
  const { title, children, onClose, clearAll, onApply } = props;

  return (
    <FiltersLayoutWrapper>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h2'>{title}</Typography>
        <XButton onClick={onClose}>
          <StyledXIcon />
        </XButton>
      </Stack>
      <FiltersContent>{children}</FiltersContent>
      <Footer>
        <Stack direction='row' justifyContent='space-between'>
          <Button variant='link' onClick={clearAll}>
            Clear all
          </Button>
          <Button variant='link' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onApply}>
            <CheckIcon /> Apply
          </Button>
        </Stack>
      </Footer>
    </FiltersLayoutWrapper>
  );
};

FiltersLayout.defaultProps = {
  title: 'Filters',
  onClose: () => { },
  clearAll: () => { },
  onApply: () => { },
};

FiltersLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  clearAll: PropTypes.func,
  onApply: PropTypes.func,
};
