import { format } from 'date-fns';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  selectMedicationById,
  selectReminderIds,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

export const useDetailedData = (medicationId, date) => {
  const medicationName = useSelector(selectMedicationById(medicationId)).drug.name;

  const reminderIds = useSelector(selectReminderIds({ medicationId, date: format(date, 'yyyy/MM/dd') }));
  const { repeat: { type, when } } = useSelector(selectMedicationById(medicationId));
  const showToday = type === 'daily'
    || (type === 'weekly' && when.includes(date.getDay()));
  return [medicationName, (showToday ? reminderIds : [])];
};
