import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ids: ['001', '002', '003', '004'],
  entities: {
    '001': {
      id: '001',
      name: 'Company A',
    },
    '002': {
      id: '002',
      name: 'Company B',
    },
    '003': {
      id: '003',
      name: 'Company C',
    },
    '004': {
      id: '004',
      name: 'Company D',
    },
  },
  selectedId: '',
};

const setSelectedCompanyAction = (state, { payload }) => {
  state.selectedId = payload;
};

export const slice = createSlice({
  name: 'verificationCompany',
  initialState,
  reducers: {
    setSelectedCompany: setSelectedCompanyAction
  },
});

export const { setSelectedCompany } = slice.actions;

export const selectCompanyIds = state => state[slice.name].ids;
export const selectCompanyById = id => state => state[slice.name].entities[id];
export const selectSelectedCompanyId = state => state[slice.name].selectedId;

export default slice;
