import { Box, styled } from '@mui/material';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-2.svg';

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '10px',
}));

export const FieldContainer = styled(Box)({
  paddingTop: '25px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 20,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    position: 'sticky',
    width: '100%',
    bottom: '10px',
    left: '0px',
    zIndex: '100',
    cursor: 'pointer',
    padding: 'initial',
    gap: '15px',
  },
}));

export const DropDownIcon = styled(ChevronDown)(({ theme }) => ({
  '&.MuiSelect-icon': {
    width: '16px',
    height: '16px',
    marginTop: '2px',
    color: theme.palette.primary.lightGray100,
    right: '20px!important',
  },
}));

