import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from 'utils/isMobile';

const getStyles = (theme) => {
  return {
    container: {
      backgroundColor: isMobile()
        ? theme.palette.primary.gray2
        : theme.palette.primary.white,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '20px',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    formContainer: {
      paddingTop: '5px',
      marginBottom: '5px',
      width: '100%',
      position: 'relative',
      '& .MuiInputBase-input': {
        paddingRight: '40px !important',
      },
    },
    mainContainerMob: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    sectionBox: {
      paddingTop: '15px',
      paddingBottom: '15px',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    stickyFooter: {
      position: 'fixed',
      bottom: 0,
      backgroundColor: '#FFFFFF',
      height: '120px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '45px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    radioGroup: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      paddingTop: '25px',
    },
    icon: {
      position: 'absolute',
      right: 17,
      top: 45,
    },
    subtitle: {
      marginTop: '4px',
      marginBottom: '10px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 13,
      lineHeight: '145%',
      textAlign: 'center',
      color: theme.palette.secondary.gray4,
    },
    label: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '140%',
      color: theme.palette.primary.gray75,
      marginBottom: '5px',
    },
  };
};

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '10px',
  paddingBottom: '10px',
  borderTop: '1px solid ' + theme.palette.primary.gray10,
}));

export default getStyles;
