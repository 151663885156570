import { Divider } from 'configUI/components/Shared';
import {
  Footer,
  Header,
} from './Components';
import { Content, StyledBaseLayout } from './styles';

const CreatePregnancyJournalDayMobile = ({
  date,
  steps,
  step,
  lastStep,
  isError,
  isLoading,
  handleForward,
  handleBackward,
}) => (
  <StyledBaseLayout
    header={
      <Header
        date={date}
        currentStep={step}
        lastStep={lastStep}
      />
    }
    content={
      <Content>
        {isError ? null : steps[step - 1]}
      </Content>
    }
    footer={
      <>
        <Divider />
        <Content>
          <Footer
            currentStep={step}
            lastStep={lastStep}
            onForward={handleForward}
            onBackward={handleBackward}
            disabled={isLoading}
          />
        </Content>
      </>
    }
    fixedFooter={true}
  />
);

export default CreatePregnancyJournalDayMobile;
