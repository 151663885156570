import { Loader } from 'components/Loader';
import { useGetFamilyCancelledInvitations } from 'features/familyLinkage/hooks/useGetFamilyCancelledInvitations';
import { useGetFamilyDeclinedInvitations } from 'features/familyLinkage/hooks/useGetFamilyDeclinedInvitations';
import { useGetFamilyMembers } from 'features/familyLinkage/hooks/useGetFamilyMembers';
import { useGetFamilyPendingInvitations } from 'features/familyLinkage/hooks/useGetFamilyPendingInvitations';
import { useGetFamilySentInvitations } from 'features/familyLinkage/hooks/useGetFamilySentInvitations';
import { CancelledInvitations } from 'pages/Profile/FamilyLinkage/components/CancelledInvitations';
import { DeclinedInvitations } from 'pages/Profile/FamilyLinkage/components/DeclinedInvitations';
import { EmptyInvitations } from 'pages/Profile/FamilyLinkage/components/EmptyInvitations';
import { Header } from 'pages/Profile/FamilyLinkage/components/Header';
import { Members } from 'pages/Profile/FamilyLinkage/components/Members';
import { PendingInvitations } from 'pages/Profile/FamilyLinkage/components/PendingInvitations';
import { SentInvitations } from 'pages/Profile/FamilyLinkage/components/SentInvitations';
import { MyProfilePageWrapper } from 'pages/Profile/MyProfile/styles';

export function FamilyLinkagePage() {
  const familyMembers = useGetFamilyMembers();
  const familyPendingInvitations = useGetFamilyPendingInvitations();
  const familySentInvitations = useGetFamilySentInvitations();
  const familyDeclinedInvitations = useGetFamilyDeclinedInvitations();
  const familyCancelledInvitations = useGetFamilyCancelledInvitations();

  const hasFamilyMembers = familyMembers.data.length > 0;
  const hasPendingInvitations = familyPendingInvitations.data.length > 0;
  const hasSentInvitations = familySentInvitations.data.length > 0;
  const hasDeclinedInvitations = familyDeclinedInvitations.data.length > 0;
  const hasCancelledInvitations = familyCancelledInvitations.data.length > 0;

  const isLoading =
    familyMembers.isFetching ||
    familyPendingInvitations.isFetching ||
    familySentInvitations.isFetching ||
    familyDeclinedInvitations.isFetching ||
    familyCancelledInvitations.isFetching;

  const isEmpty =
    !hasFamilyMembers &&
    !hasPendingInvitations &&
    !hasSentInvitations &&
    !hasDeclinedInvitations &&
    !hasCancelledInvitations;

  return (
    <MyProfilePageWrapper>
      <Header />

      {isLoading ? (
        <Loader />
      ) : isEmpty ? (
        <EmptyInvitations />
      ) : (
        <>
          {hasFamilyMembers && <Members familyMembers={familyMembers.data} />}

          {hasPendingInvitations && (
            <PendingInvitations invitations={familyPendingInvitations.data} />
          )}

          {hasSentInvitations && (
            <SentInvitations invitations={familySentInvitations.data} />
          )}

          {hasDeclinedInvitations && (
            <DeclinedInvitations invitations={familyDeclinedInvitations.data} />
          )}

          {hasCancelledInvitations && (
            <CancelledInvitations
              invitations={familyCancelledInvitations.data}
            />
          )}
        </>
      )}
    </MyProfilePageWrapper>
  );
}
