import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { BaseLayout } from 'components/BaseLayout';

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',

  '& > .BaseLayout-scrollable': {
    backgroundColor: theme.palette.primary.gray20,
    padding: '40px 20px',
  },
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
    padding: '50px',
    '& > .BaseLayout-header': {
      position: 'initial',
      paddingBottom: '37px',
    },
    '& > .BaseLayout-scrollable': {
      padding: 0,
    },
  },
}));
