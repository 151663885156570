import { Box, useTheme } from '@mui/material';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useCreatePersonStudyArmAttributeMutation } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { reporterPageApiSlice } from 'ihp-bloom-redux/features/onboarding/reporterPageSlice';
import Button from 'ihp-components/components/v2/Button';

import {
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_CONFIG_VARIABLES,
} from 'constants/global';
import { Text, Title } from 'pages/Onboarding/components/Text';

import getStyles, { DtpModal } from './styles';

export const DeleteReporterModal = ({ open, onClose, selectedReporter }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const dispatch = useDispatch();
  const { activeAccountProfile } = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);

  const [updatePersonStudyArm, { isLoading: isLoadingPersonStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const [
    createPersonStudyArmAttribute,
    { isLoading: isLoadingCreatePersonStudyArmAttribute },
  ] = useCreatePersonStudyArmAttributeMutation();

  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const reporterInvitedStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_REPORTER_INVITED
    ].id;

  const researchWithdrawn =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_WITHDRAWN
    ].id;

  const isLoading =
    isLoadingCreatePersonStudyArmAttribute || isLoadingPersonStudyArm;

  const updateCurrentStudyArmAPI = () => {
    const payload = {
      data: {
        type: 'person-study-arms',
        id: `${selectedReporter?.person_study_arm?.id}`,
        attributes: {
          study_arm_status_id: researchWithdrawn,
        },
      },
    };
    return updatePersonStudyArm({
      personStudyArmId: selectedReporter?.person_study_arm?.id,
      payload,
    });
  };

  const handleDelete = async () => {
    try {
      const createPersonStudyArmAttributePayload = {
        data: {
          type: 'person-study-arm-attributes',
          attributes: {
            attribute: 'withdrawal_reasons',
            value: 'removed_by_the_participant',
          },
        },
      };

      const response = await createPersonStudyArmAttribute({
        personStudyArmId: selectedReporter?.person_study_arm?.id,
        payload: createPersonStudyArmAttributePayload,
      });

      if (response?.error) {
        console.log('Error: ', response);
        return;
      } else if (response?.data) {
        let deleteReporterResponse = await updateCurrentStudyArmAPI();

        if (deleteReporterResponse?.error) {
          console.log('Error: ', response);
          return;
        }
        if (deleteReporterResponse?.data) {
          dispatch({
            type: `${reporterPageApiSlice.reducerPath}/invalidateTags`,
            payload: ['Reporters'],
          });
          onClose();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    handleDelete();
  };
  return (
    <DtpModal open={open} onClose={onClose}>
      <Box sx={{ ...styles.modalWrapper }}>
        <Title>Delete reporter?</Title>
        <Text
          color={theme.palette.primary.darkGray100}
          marginTop='5px'
          lineHeight='140%'
        >
          {selectedReporter?.person_study_arm?.study_arm_status_id ==
          reporterInvitedStatusId
            ? 'Are you sure you want to remove this reporter? Their invitation will be terminated.'
            : 'Are you sure you want to remove this reporter? Their access to the study will be terminated.'}
        </Text>

        <Box sx={styles.buttonsContainer}>
          <Button sx={styles.cancelButton} fullWidth onClick={(e) => onClose()}>
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            sx={styles.deleteButton}
            variant='link'
            fullWidth
            onClick={(e) => onDelete(e)}
          >
            Delete reporter
          </Button>
        </Box>
      </Box>
    </DtpModal>
  );
};
