import React from 'react';
import { Box, Modal, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Button from 'ihp-components/components/v2/Button';

import { Text, Title } from 'pages/Onboarding/components/Text';
import { useLogout } from 'utils/useLogout';
import { getStyles } from './styles';
import { isMobile } from 'utils/isMobile';

export const ProfileNotFoundModal = (props) => {
  const { open, onClose } = props;
  const logout = useLogout();
  const theme = useTheme();
  const navigate = useNavigate();
  const styles = getStyles(theme);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles.modalWrapper }}>
        <Box sx={{ ...styles.titleWrapper }}>
          <Title marginTop='0px'>Participant not found</Title>
        </Box>

        <Box sx={{ ...styles.contentWrapper }}>
          <Text variant='h3'>
            We could not find the study participant. Do you want to choose a
            different profile or logout?
          </Text>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ fontSize: '16px' }}
            variant='link'
            onClick={(e) => logout()}
          >
            Logout
          </Button>
          <Button
            sx={{ fontSize: '16px' }}
            fullWidth={isMobile()}
            onClick={() => navigate('/select-profile')}
          >
            Select profile
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
