import { ReactComponent as ChevronRightIcon } from 'images/shared/chevron-right.svg';
import {
  MainTextContainer,
  StyledTypograpy1,
  StyledTypograpy2,
  InviteButtonContainer,
  StyledTypograpy3,
  CardContainer,
} from './styles.js';

export const InvitationCard = ({
  icon,
  title,
  description,
  buttonText,
  onClick,
  shouldBeFocussed = false,
}) => {
  return (
    <CardContainer shouldBeFocussed={shouldBeFocussed}>
      {icon}
      <MainTextContainer>
        <StyledTypograpy1 shouldBeFocussed={shouldBeFocussed}>{title}</StyledTypograpy1>
        <StyledTypograpy2 shouldBeFocussed={shouldBeFocussed}>{description}</StyledTypograpy2>
      </MainTextContainer>
      <InviteButtonContainer
        endIcon={
          <ChevronRightIcon width='20px' height='20px' color='#4D88AB' />
        }
        onClick={onClick}
      >
        <StyledTypograpy3>{buttonText}</StyledTypograpy3>
      </InviteButtonContainer>
    </CardContainer>
  );
};
