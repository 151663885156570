import { createSlice } from '@reduxjs/toolkit';
const idFormatter = (id) => String(id).padStart(3, '0');

const sms = [
  {
    id: '001',
    text: 'Invitation to enroll',
    type: 'sms',
  },
  {
    id: '002',
    text: 'Welcome to the Bloom study',
    type: 'sms',
  },
  {
    id: '003',
    text: 'Study overview',
    type: 'sms',
  },
  {
    id: '004',
    text: 'Reminder to enroll',
    type: 'sms',
  },
  {
    id: '005',
    text: 'Schedule TeleVisit call',
    type: 'sms',
  },
  {
    id: '006',
    text: 'Reminder',
    type: 'sms',
  },
  {
    id: '007',
    text: 'Schedule TeleVisit call Follow up',
    type: 'sms',
  },
];

const initialState = {
  list: sms,
};

const addSmsTemplateReducer = (state, { payload }) => {
  const data = payload && payload.data ? payload.data : {};
  const id = idFormatter(state.list.length + 1);
  state.list.push({
    id,
    ...data,
  });
};

const updateSmsTemplateReducer = (state, { payload }) => {
  const { id, ...rest } = payload.data;
  const targetIndex = state.list.findIndex((template) => template.id === id);
  if (targetIndex > -1) {
    state.list[targetIndex] = {
      ...state.list[targetIndex],
      ...rest,
    };
  }
};

const deleteSmsTemplateReducer = (state, action) => {
  state.list = state.list.filter((item) => item.id !== action.payload.id);
}

const slice = createSlice({
  name: 'smsTemplates',
  initialState,
  reducers: {
    addSmsTemplate: addSmsTemplateReducer,
    updateSmsTemplate: updateSmsTemplateReducer,
    deleteSmsTemplate: deleteSmsTemplateReducer
  },
});

export const { addSmsTemplate, updateSmsTemplate, deleteSmsTemplate } = slice.actions;

export const selectSmsTemplateById = id => state => state[slice.name].list.find(item => item.id === id) || {};

export default slice;
