import { useState } from 'react';
import { useParams } from 'react-router-dom';
import HealthInstructions from './HealthInstructions';
import { HealthQuestionnaire } from './HealthQuestionnaire';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetProfileParticipantTask } from 'pages/Activities/hooks';
import { Loader } from 'components/Loader';
import { taskTypeLabelMapping } from 'pages/Activities/utils';
import { ReactComponent as ClockIcon } from 'images/activity/types/clock-filled.svg';
import ActivityHeader from 'components/Activities/Header';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';

function HealthQuestionnairePage() {
  const [isInstructions, setIsInstructions] = useState(true);

  const participantTaskId = parseInt(useParams().id);
  const { activeAccountProfile } = useSelector((state) => state.user);

  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const activeActivity = useGetProfileParticipantTask(
    participantTaskId,
    accountProfileId
  );

  let estimatedTime = 'N/A';
  if (activeActivity?.estimatedTime) {
    estimatedTime = `${activeActivity?.estimatedTime} min`;
  }

  let timeLimit = undefined;
  if (activeActivity?.timeLimit) {
    timeLimit = `${activeActivity?.timeLimit} min`;
  }

  const header = (
    <ActivityHeader
      title={activeActivity?.title}
      points={activeActivity?.compensationValue}
      type={taskTypeLabelMapping[activeActivity?.type]}
      estimatedTime={estimatedTime}
      timeLimit={timeLimit}
      showStepCounter={false}
      subtitle={
        <p style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          Survey - <ClockIcon fill='grey' /> {estimatedTime}
        </p>
      }
    />
  );

  if (activeActivity.loadingParticipantTask) {
    return <Loader />;
  }

  if (isInstructions) {
    return (
      <>
        {header}
        <HealthInstructions
          setIsInstructions={setIsInstructions}
          description={activeActivity?.attributes?.howTo?.instructions}
        />
        ;
      </>
    );
  }

  return (
    <>
      {header}
      <HealthQuestionnaire
        activeActivity={activeActivity}
        setIsInstructions={setIsInstructions}
      />
    </>
  );
}

export default withPerson(withSelectedProfile(HealthQuestionnairePage));

