import React from 'react';

import { ReactComponent as PointsBadge } from 'images/profile/achievements/points.svg';
import { ReactComponent as PointsFilledBadge } from 'images/profile/achievements/points-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobilePointsEarnedCard } from './index.mobile';
import { isMobile } from 'utils/isMobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  MyPoints,
  IsCompletedMark,
} from '../styles';

export const PointsEarnedCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobilePointsEarnedCard
        inProgress={inProgress}
        isCompleted={isCompleted}
      />
    );
  }

  return (
    <CardWrapper backgroundColor={isCompleted ? '#D7F5E4' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#6FCF97' />}
      {!inProgress ? <PointsBadge /> : <PointsFilledBadge />}
      <Info>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 1,000 Points earned
        </Title>
        <StyledSlider
          value={60}
          isCompleted={isCompleted}
          backgroundColor='#6FCF97'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Happy camper'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
