import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  url: '',
  username: '',
  password: '',
  token: '',
};

export const hyperwalletSlice = createSlice({
  name: 'hyperwallet',
  initialState,
  reducers: {
    setValues: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    },
  },
});

export const { setValues, decrement } = hyperwalletSlice.actions;

export default hyperwalletSlice.reducer;
