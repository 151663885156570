import { MenuItem as MUIMenuItem, Stack, Typography } from '@mui/material';
import { ReactComponent as Transfer } from 'images/profile/left-right-arrows.svg';
import { ReactComponent as List } from 'images/profile/list-check.svg';
import { ReactComponent as Verify } from 'images/profile/shield-check.svg';
import { useState } from 'react';
import { MenuIcon, StyledMenu } from './MenuCard.styles';

export const MenuItem = ({ Icon, text, ...rest }) => (
  <MUIMenuItem {...rest}>
    <Stack direction='row' spacing='12px' alignItems='center'>
      <MenuIcon>
        <Icon height='14px' />
      </MenuIcon>
      <Typography variant='pl3' color='primary.gray75'>
        {text}
      </Typography>
    </Stack>
  </MUIMenuItem>
);

const MenuCard = ({
  anchorEl,
  onClose,
  openAccount,
  openTransfer,
  openVerify,
}) => {
  return (
    <StyledMenu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem
        onClick={() => openAccount()}
        Icon={List}
        text='Setup an account'
      />
      <MenuItem
        onClick={() => openTransfer()}
        Icon={Transfer}
        text='Setup transfer method'
      />
      <MenuItem
        onClick={() => openVerify()}
        Icon={Verify}
        text='Verify account'
      />
    </StyledMenu>
  );
};

export const useMenuCard = ({ openAccount, openTransfer, openVerify }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event?.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const Menu = () => (
    <MenuCard
      anchorEl={anchorEl}
      onClose={handleClose}
      openAccount={openAccount}
      openTransfer={openTransfer}
      openVerify={openVerify}
    />
  );

  return { Menu, handleClick };
};
