export const getRewardsInfo = (response) => {
  if (response === null || response === undefined) {
    return null;
  }
  const found = response.filter(
    (r) => r?.relationships?.goal?.attributes?.type === 'point_unbounded'
  );
  if (found != null && found.length === 1) {
    return found[0]?.statistics;
  }
};

export const parseRewardsInfo = (data) => {
  if (data === null || data === undefined) {
    return {
      totalPointsEarned: 0,
      pointsBalance: 0,
      pointsAlreadyRedeemed: 0,
      totalRewardsValueReceived: 0,
    };
  }

  return {
    totalPointsEarned: data.total_no_points,
    pointsBalance: data.balance,
    pointsAlreadyRedeemed: data.converted_points,
    totalRewardsValueReceived: data.converted_amount,
  };
};

export const parseStudyThreshold = (data) => {
  const defaultValue = 100;
  if (data === null || data === undefined) {
    return defaultValue;
  }

  const found = data.find(
    (item) => item?.data?.attributes?.attribute === 'payout_method'
  );

  if (!found) {
    return defaultValue;
  }

  const foundThreshold = found.data?.attributes?.value[0]?.threshold?.min;
  return foundThreshold || defaultValue;
};

export const parseStudyConversionRate = (data) => {
  const defaultValue = 1;
  if (data === null || data === undefined) {
    return defaultValue;
  }

  const found = data.find(
    (item) => item?.data?.attributes?.attribute === 'payout_method'
  );

  if (!found) {
    return defaultValue;
  }

  const foundConversionRate =
    found.data?.attributes?.value[0]?.currency[0]?.rate;
  return foundConversionRate || defaultValue;
};

export const parsePayments = (data) => {
  if (data === null || data === undefined) {
    return [];
  }

  const found = data.filter(
    (item) => item?.attributes?.type === 'payment_adjustable'
  );

  if (!found) {
    return [];
  }

  return found.map((payment) => {
    const rewardInfo =
      payment?.attributes?.transaction_details?.order?.rewards[0];
    return {
      date: format(new Date(rewardInfo?.created_at)),
      value: rewardInfo?.value?.denomination,
      name: payment?.attributes?.title,
      redirectUrl: rewardInfo?.delivery?.link,
    };
  });
};

const format = (inputDate) => {
  let date, month, year;
  date = inputDate.getDate();
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();
  date = date.toString().padStart(2, '0');
  month = month.toString().padStart(2, '0');
  return `${month} / ${date} / ${year}`;
};
