import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, useTheme, MenuItem } from '@mui/material';
import MaskedInput from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import {
  useCreateReporterMutation,
  reporterPageApiSlice,
} from 'ihp-bloom-redux/features/onboarding/reporterPageSlice';
import {
  useCreatePersonStudyArmAttributeMutation,
  useDeletePersonStudyArmAttributeMutation,
} from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import useSendNotification from '../../../hooks/useSendNotification';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

import {removeSpecialCharactersFromPhone, useRegexValidatePhone, useValidateEmail} from 'utils/formValidation';
import { isMobile } from 'utils/isMobile';
import { getEmptyFields } from 'pages/User/utils';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import {
  STUDY_ARM_CODES,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_CODES,
  STUDY_ARM_STATUS_CODES,
  VIRTUAL_SITE,
} from 'constants/global';

import { relationships } from 'constants/reporters';
import getStyles, {
  DtpModal,
  InputSelectWrapper,
  DropDownIcon,
  InfoWrapper,
  InfoDetail,
  InfoIcon,
  SelectField,
} from './styles';
import { FIELD_TYPE_RENDERERS_MAP } from '../../../utils/configParseUtils';
import _ from 'lodash';

export const AddReporterModal = ({ open, onClose, selectedReporter }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const validatePhone = useRegexValidatePhone();
  const dispatch = useDispatch();
  const validateEmail = useValidateEmail();
  const { sendNotification } = useSendNotification();

  const { configuration } = useSelector((state) => state.configuration);
  const user = useSelector((state) => state.user);

  const [currentReporter, setCurrentReporter] = useState({
    first_name: '',
    last_name: '',
    email: '',
    confirm_email: '',
    phone: '',
    relationship: '',
    reporter_consent: false,
  });
  const [isResendInviteLoading, setResendInviteLoading] = useState(false);

  const [createReporter, { isLoading: isCreatingReporter }] =
    useCreateReporterMutation();
  const [createPersonStudyArmAttribute, { isLoading: isUpdatingReporter }] =
    useCreatePersonStudyArmAttributeMutation();

  const [deletePersonStudyArmAttribute, { isLoading: isDeletingReporter }] =
    useDeletePersonStudyArmAttributeMutation();

  const { activeAccountProfile, person } = user;
  const isLoading = isCreatingReporter || isUpdatingReporter;
  const studyArmId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARMS][
      STUDY_ARM_CODES.RESEARCH
    ].id;
  const studyArmStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_REPORTER_INVITED
    ].id;
  const studyArmReporterRoleId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
      STUDY_ARM_ROLE_CODES.R_REPORTER
    ].id;
  const siteId = VIRTUAL_SITE.id;

  const customPhoneRenderer = FIELD_TYPE_RENDERERS_MAP['phone'];

  const country = activeAccountProfile?.attributes?.person?.attributes?.country;

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    setError,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      first_name:
        selectedReporter?.person_study_arm_attributes?.first_name ?? '',
      last_name: selectedReporter?.person_study_arm_attributes?.last_name ?? '',
      email: selectedReporter?.person_study_arm_attributes?.email ?? '',
      confirm_email: selectedReporter?.person_study_arm_attributes?.email ?? '',
      phone: selectedReporter?.person_study_arm_attributes?.phone ?? '+',
      relationship:
        selectedReporter?.person_study_arm_attributes?.relationship ?? '',
      reporter_consent:
        selectedReporter?.person_study_arm_attributes?.secondary_contact ==
          '1' ||
        selectedReporter?.person_study_arm_attributes?.secondary_contact ==
          'true'
          ? true
          : false,
    },
  });

  const watchAllFields = watch();
  const isInviteDisabled =
    watchAllFields?.first_name?.length !== 0 &&
    watchAllFields?.last_name?.length !== 0 &&
    watchAllFields?.email?.length !== 0 &&
    watchAllFields?.confirm_email?.length !== 0 &&
    watchAllFields?.relationship?.length !== 0;

  const onAddReporter = async (data) => {
    data.phone = removeSpecialCharactersFromPhone(data.phone);
    if (data.phone === '' || data.phone === '+') {
      delete data.phone;
    }

    const emptyFields = getEmptyFields(data);
    const filteredEmptyFields = emptyFields.filter(
      (field) => field !== 'phone'
    );

    if (filteredEmptyFields.length !== 0) {
      setErrorsForFields(filteredEmptyFields);
      return;
    }

    if (filteredEmptyFields.length == 0) {
      if (data.email != data.confirm_email) {
        setError('confirm_email', { message: 'Incorrect email' });
      } else {
        onSubmit();
        setCurrentReporter({
          first_name: '',
          last_name: '',
          email: '',
          confirm_email: '',
          phone: '',
          relationship: '',
          reporter_consent: false,
        });
        reset();
      }
    }
  };

  const handleEdit = async () => {
    try {
      const reporter = getValues();
      reporter.phone = removeSpecialCharactersFromPhone(reporter.phone);

      const phoneObject = {
        type: 'person-study-arm-attributes',
        attributes: {
          attribute: 'phone',
          value: reporter.phone,
        },
      };

      let createPersonStudyArmAttributePayload = {
        data: [
          {
            type: 'person-study-arm-attributes',
            attributes: {
              attribute: 'first_name',
              value: reporter.first_name,
            },
          },
          {
            type: 'person-study-arm-attributes',
            attributes: {
              attribute: 'last_name',
              value: reporter.last_name,
            },
          },
          {
            type: 'person-study-arm-attributes',
            attributes: {
              attribute: 'relationship',
              value: reporter.relationship,
            },
          },
          {
            type: 'person-study-arm-attributes',
            attributes: {
              attribute: 'secondary_contact',
              value: `${reporter.reporter_consent}`,
            },
          }
        ],
      };

      if (reporter.phone === '+' || isEmpty(reporter.phone)) {
        // delete phone
        const deleteAttributePayload = { attributes: ['phone'] };
        const deleteResponse = await deletePersonStudyArmAttribute({
          personStudyArmId: selectedReporter?.person_study_arm?.id,
          payload: deleteAttributePayload,
        });
        if (deleteResponse?.error) {
          console.log('Error: ', deleteResponse);
          return;
        }
      } else {
        createPersonStudyArmAttributePayload.data.push(phoneObject);
      }

      const response = await createPersonStudyArmAttribute({
        personStudyArmId: selectedReporter?.person_study_arm?.id,
        payload: createPersonStudyArmAttributePayload,
      });

      if (response?.error) {
        console.log('Error: ', response);
        return;
      }
      if (response?.data) {
        dispatch({
          type: `${reporterPageApiSlice.reducerPath}/invalidateTags`,
          payload: ['Reporters'],
        });
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onReSendNotification = async () => {
    setResendInviteLoading(true);
    const { first_name, last_name, email } = getValues();
    let sendNotificationInvitePayload = {
      to_account_profile_id: selectedReporter?.account_profile?.id,
      to_person_id: selectedReporter?.person_study_arm?.person_id,
      status: 'unsent',
    };
    try {
      await sendNotification(
        first_name,
        last_name,
        email,
        'Email',
        'RESEND_INVITE',
        'participant-notifications',
        { ...sendNotificationInvitePayload }
      );
      setResendInviteLoading(false);
    } catch (error) {
      setResendInviteLoading(false);
      console.log('error', error);
    }
  };

  const setErrorsForFields = (emptyFields) => {
    emptyFields.forEach((field) =>
      setError(field, { message: 'This field is required!' })
    );
  };

  const removeEmptyProperties = (obj) => {
    Object.keys(obj).forEach(
      (key) => (obj[key] === null || obj[key] === '') && delete obj[key]
    );
    return obj;
  };

  const handleCreateReporter = (reporter) => {
    const attributes = {
      first_name: reporter.first_name,
      last_name: reporter.last_name,
      email: reporter.email,
      phone: reporter.phone,
      relationship: reporter.relationship,
      secondary_contact: reporter.reporter_consent,
      subject:
        activeAccountProfile.attributes.profile.subject_primary_person_id,
      account_holder: person.id,
      account_profile: activeAccountProfile.attributes.profile.id,
      study_arm: studyArmId,
      study_arm_status: studyArmStatusId,
      study_arm_role: studyArmReporterRoleId,
      site: siteId,
      country: country,
    };
    try {
      const payload = {
        data: {
          type: 'reporters',
          attributes: removeEmptyProperties(attributes),
        },
      };

      // Return the promise created by createReporter
      return createReporter(payload);
    } catch (error) {
      console.error('Error creating reporter:', error);
      return Promise.resolve();
    }
  };

  const onSubmit = async (data) => {
    const reporter = getValues();
    reporter.phone = removeSpecialCharactersFromPhone(reporter.phone);
    if (reporter) {
      let addReporterResponseData = await handleCreateReporter(reporter);
      if (addReporterResponseData?.data) {
        onClose();
      }
    }
  };

  return (
    <DtpModal open={open} onClose={onClose}>
      <Box sx={{ ...styles.modalWrapper }}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>
            {selectedReporter ? 'Edit reporter' : 'Add reporter'}
          </Box>
          <Box sx={styles.iconWrapper}>
            <XIcon onClick={(e) => onClose()} />
          </Box>
        </Box>
        <Box sx={styles.formWrapper}>
          <form>
            <Box sx={styles.flexContainer}>
              <Box sx={styles.firstNameContainer}>
                <Controller
                  name='first_name'
                  width='50%'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  styles={{ lineHeight: 'normal', fontStyle: 'normal' }}
                  render={({ field }) => (
                    <Input
                      width='50%'
                      name='first_name'
                      value={currentReporter?.first_name}
                      error={errors.first_name}
                      errorLabel={errors.first_name?.message}
                      label='First name'
                      placeholder='Enter first name'
                      containerStyle={styles.inputContainer}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box sx={styles.lastNameContainer}>
                <Controller
                  name='last_name'
                  width='50%'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='last_name'
                      width='50%'
                      error={errors.last_name}
                      value={currentReporter?.last_name}
                      errorLabel={errors.last_name?.message}
                      label='Last name'
                      placeholder='Enter last name'
                      containerStyle={styles.inputContainer}
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='email'
                control={control}
                rules={{
                  validate: validateEmail,
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    errorLabel={
                      errors?.email?.message || 'This field is required'
                    }
                    error={errors.email}
                    label='Email'
                    value={currentReporter?.email}
                    placeholder='Enter email'
                    fullWidth
                    disabled={selectedReporter}
                    {...field}
                  />
                )}
              />
            </Box>
            {!selectedReporter && (
              <Box sx={styles.fieldContainer}>
                <Controller
                  name='confirm_email'
                  control={control}
                  rules={{
                    validate: validateEmail,
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='confirm_email'
                      errorLabel={
                        errors?.confirm_email?.message ||
                        'This field is required'
                      }
                      error={errors.confirm_email}
                      label='Confirm Email'
                      value={currentReporter?.confirm_email}
                      placeholder='Enter email'
                      fullWidth
                      disabled={selectedReporter}
                      {...field}
                    />
                  )}
                />
              </Box>
            )}
            {selectedReporter && (
              <Box>
                <Button
                  sx={styles.linkContainer}
                  variant='link'
                  onClick={onReSendNotification}
                  disabled={isResendInviteLoading}
                  loading={isResendInviteLoading}
                >
                  Resend invite
                </Button>
              </Box>
            )}
            <Box sx={styles.fieldContainer}>
              <Controller
                name='phone'
                control={control}
                rules={{
                  required: false,
                  validate: (value) => validatePhone(value, country)
                }}
                render={({ field }) =>
                  customPhoneRenderer({
                    errorLabel: errors?.phone?.message,
                    error: errors.phone,
                    country,
                    type: 'text',
                    name: 'phone',
                    label: 'Phone (Optional)',
                    placeholder: 'Enter phone',
                    ...field,
                  })
                }
              />
            </Box>
            <SelectField>
              <Controller
                name='relationship'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.relationship}
                render={({ field }) => (
                  <Input
                    error={errors.relationship}
                    errorLabel={errors?.relationship?.message}
                    value={currentReporter?.relationship}
                    type='select'
                    label='Select relationship'
                    fieldLabel='Relationship'
                    placeholder='Select relation'
                    IconComponent={(props) => <DropDownIcon {...props} />}
                    fullWidth
                    {...field}
                  >
                    {relationships?.map(({ id, name, key }) => (
                      <MenuItem key={id} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </SelectField>
            <Box>
              <Controller
                name='reporter_consent'
                control={control}
                haserrors={errors.reporter_consent}
                render={({ field }) => (
                  <Input
                    error={errors.reporter_consent}
                    errorLabel={errors.reporter_consent?.message}
                    type='checkbox'
                    value={currentReporter?.reporter_consent}
                    label='Do you give permission for this person to also be a secondary contact for your account?'
                    checked={getValues('reporter_consent')}
                    containerStyle={styles.checkboxContainer}
                    size='medium'
                    {...field}
                  />
                )}
              />
            </Box>
            <Box>
              <InfoWrapper>
                <InfoIcon />
                <InfoDetail>
                  A Secondary Contact is someone that the registry team can
                  reach out to for communication regarding this account.
                </InfoDetail>
              </InfoWrapper>
            </Box>
          </form>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ fontSize: '16px' }}
            variant='link'
            onClick={(e) => onClose()}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading || !isInviteDisabled}
            sx={{ fontSize: '16px' }}
            fullWidth={isMobile()}
            onClick={
              selectedReporter
                ? handleSubmit(handleEdit)
                : handleSubmit(onAddReporter)
            }
          >
            {selectedReporter ? 'Save' : 'Invite'}
          </Button>
        </Box>
      </Box>
    </DtpModal>
  );
};
