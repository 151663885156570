import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AcmeLogo } from 'images/av-logo-vertical.svg';
import { HeaderContainer, Logo, Reward } from './styles';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';

export const Header = ({ points, ...rest }) => (
  <HeaderContainer {...rest}>
    <Logo>
      <AcmeLogo />
    </Logo>
    <Reward>
      {points}
      <PointsIcon width={18} />
    </Reward>
  </HeaderContainer>
);

Header.propTypes = {
  points: PropTypes.number.isRequired,
};
