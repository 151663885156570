export const DEFAULT_MAIN_NAVIGATION = [
  {
    order: 1,
    label: {
      'en-us': 'My Activity',
    },
    key: 'my_activity',
    icon: {
      default: '',
      'in-focus': '',
    },
    display: true,
  },
  {
    order: 2,
    label: {
      'en-us': 'Tools (Journals)',
    },
    key: 'tools',
    icon: {
      default: '',
      'in-focus': '',
    },
    display: false,
  },
  {
    order: 3,
    label: {
      'en-us': 'Rewards',
    },
    key: 'my_rewards',
    icon: {
      default: '',
      'in-focus': '',
    },
    display: false,
  },
  {
    order: 4,
    label: {
      'en-us': 'My Profile',
    },
    key: 'my_profile',
    icon: {
      default: '',
      'in-focus': '',
    },
    display: true,
  },
  {
    order: 5,
    label: {
      'en-us': 'Notifications',
    },
    key: 'notifications',
    icon: {
      default: '',
      'in-focus': '',
    },
    display: false,
  },
];
