// TODO: Delete this later when its done on the back end
// SING-510
// import { useEffect, useRef } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Grid, useMediaQuery } from '@mui/material';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import {
  useDeletePersonAttributesMutation,
  useEditPersonAttributesMutation,
} from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useGetAllCountriesQuery,
  useGetStatesQuery,
} from 'ihp-bloom-redux/features/countries/countriesApiSlice';

import Button from 'ihp-components/components/v2/Button';

import { ReactComponent as Home } from 'images/navigator/home.svg';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';

import {
  FIELD_TYPE_RENDERERS_MAP,
  country,
  date_of_birth,
  first_name,
  getAccountHolderProfileTabInfo,
  last_name,
  middle_name,
  no_middle_name,
  postal_code,
  state,
  street_address,
} from 'utils/configParseUtils';

import { withPersonAttributes } from 'hocs/withPersonAttributes';

import {
  findFirstErrorComponent,
  useValidateZipCode,
} from 'utils/formValidation';

import { useDynamicRefs } from '../../ParticipantProfile/GeneralInfo/helper';

import { formatDateToDB, parseDate } from 'utils/date';
import {
  ButtonContainer,
  HorizontalDivider,
  StyledBox,
  Title,
  getStyles,
} from './styles';

const mapValue = {
  true: true,
  false: false,
};

const GeneralInfo = (props) => {
  const dispatch = useDispatch();
  const countryRef = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const initialValueAttributes = {};

  const { participantProfileConfig } = useSelector(
    (state) => state.configuration
  );
  const user = useSelector((state) => state.user);

  const { person, activeAccountProfile } = user;

  const accountProfileId = activeAccountProfile?.id;
  const countryName =
    activeAccountProfile?.attributes?.person?.attributes?.country;

  const [deletePersonAttributes, { isLoading: isDeletingPersonAttributes }] =
    useDeletePersonAttributesMutation();

  const { countriesList, isLoading: isCountriesLoading } =
    useGetAllCountriesQuery(undefined, {
      selectFromResult: ({ data, ...rest }) => ({
        countriesList: data?.data?.map((country) => ({
          label: country?.attributes?.country_name,
          value: country?.attributes?.country_code2,
        })),
        ...rest,
      }),
    });

  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();

  const { fieldsMap, isTabReadOnly } = getAccountHolderProfileTabInfo(
    participantProfileConfig,
    'General Info'
  );

  const availableFields = fieldsMap.reduce((acc, obj) => {
    const nameFields = obj.fields
      .filter((field) => field?.name)
      .map((field) => {
        if (field.show) {
          return field.name;
        }
      });
    return acc.concat(nameFields);
  }, []);

  Object.keys(props.personAttributes || {})?.forEach((key) => {
    if (availableFields?.includes(key)) {
      initialValueAttributes[key] =
        mapValue[props.personAttributes[key]] ?? props.personAttributes[key];
    }
    if (key === date_of_birth) {
      initialValueAttributes[key] = parseDate(props.personAttributes[key]);
    }
  });
  /**
   * make sure for all available(show=true) fields we set initial value so all input components render as controlled components
   */
  availableFields?.forEach((field) => {
    if (initialValueAttributes[field] === undefined) {
      initialValueAttributes[field] = field === date_of_birth ? null : '';
    }
  });

  const refMap = useDynamicRefs(availableFields);

  const {
    control,
    watch,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: { ...initialValueAttributes },
  });

  const intialCountryValue = initialValueAttributes?.country;
  const isCountryValueChange = watch(country);
  const isNoMiddleNameChecked = watch(no_middle_name);
  const isCountryValue = intialCountryValue || isCountryValueChange;

  const posatlCodeValidation = useValidateZipCode(isCountryValueChange);

  if (
    (isCountryValueChange !== '' || isCountryValueChange !== undefined) &&
    countryRef.current !== isCountryValueChange &&
    isCountryValueChange !== intialCountryValue
  ) {
    setValue(state, '');
    countryRef.current = isCountryValueChange;
  }

  if (
    (isNoMiddleNameChecked !== '' || isNoMiddleNameChecked !== undefined) &&
    isNoMiddleNameChecked
  ) {
    setValue(middle_name, '');
  }

  const { states, isStatesFetching } = useGetStatesQuery(isCountryValueChange, {
    skip: !isCountryValue,
    forceRefetch: true,
    selectFromResult: ({ data, isFetching }) => ({
      states: data?.data?.attributes?.subdivisions?.map((subdivision) => ({
        label: subdivision.name,
        value: subdivision.code,
      })),
      isStatesFetching: isFetching,
    }),
  });

  const isLoading =
    isEditingPersonAttributes ||
    isCountriesLoading ||
    isStatesFetching ||
    props?.isFetchingPersonAttributes ||
    isDeletingPersonAttributes;

  const styles = getStyles(isNoMiddleNameChecked);

  useEffect(() => findFirstErrorComponent(errors, refMap));

  const handleCancel = () => {
    reset(initialValueAttributes);
  };

  const onSubmit = async (data) => {
    let forRemoval = [];
    let payload = [];

    for (let key in data) {
      if (data[key] === undefined || data[key] === '' || data[key] === null) {
        forRemoval.push(key);
      }
    }
    /**
     * Filter out attributes which does not exists in BE side and only have default initial value
     */
    const filtered = forRemoval?.filter((key) => {
      if (props.personAttributes[key] === undefined) {
        return false;
      }
      return true;
    });

    if (filtered?.length > 0) {
      await deletePersonAttributes({
        personId: person?.id,
        payload: { attributes: filtered },
      });
    }

    if (
      data.date_of_birth !== '' ||
      data.date_of_birth !== null ||
      data.date_of_birth !== undefined
    ) {
      data.date_of_birth = formatDateToDB(data.date_of_birth);
    }

    for (let key in data) {
      if (data[key] === undefined || data[key] === '' || data[key] === null) {
        delete data[key];
      } else {
        const obj = {
          data: {
            type: 'person-attributes',
            attributes: {
              attribute: key,
              value:
                typeof data[key] === 'boolean' ? `${data[key]}` : data[key],
            },
          },
        };
        payload = [...payload, obj];
      }
    }
    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person?.id,
        payload: payload,
      });
      if (personAttributesResponse?.error) {
        console.log('Error: ', personAttributesResponse);
        return;
      } else {
        const accountProfileResponse = await dispatch(
          accountProfileApiSlice.endpoints.getAccountProfile.initiate(
            accountProfileId,
            {
              forceRefetch: true,
            }
          )
        );

        if (accountProfileResponse?.data?.data) {
          const activeAccountProfilePayload = {
            ...user,
            activeAccountProfile: accountProfileResponse?.data?.data,
          };
          const targetIndex =
            activeAccountProfilePayload?.accountProfiles?.findIndex(
              (profile) =>
                Number(profile.id) ===
                Number(accountProfileResponse?.data?.data?.id)
            );
          if (targetIndex < 0) {
            activeAccountProfilePayload.accountProfiles = [
              ...activeAccountProfilePayload.accountProfiles,
              {
                id: accountProfileResponse?.data?.data?.attributes?.profile?.id,
                type: 'Profiles',
                attributes: {
                  ...accountProfileResponse?.data?.data?.attributes?.profile,
                },
              },
            ];
          } else {
            activeAccountProfilePayload.accountProfiles =
              activeAccountProfilePayload.accountProfiles?.map((profile) => {
                if (
                  Number(profile.id) ===
                  Number(accountProfileResponse?.data?.data?.id)
                ) {
                  return {
                    id: accountProfileResponse?.data?.data?.id,
                    type: 'Profiles',
                    attributes: {
                      ...accountProfileResponse?.data?.data?.attributes
                        ?.profile,
                    },
                  };
                }
                return profile;
              });
          }
          await dispatch(setValues(activeAccountProfilePayload));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form>
      {fieldsMap?.map((obj, index) => (
        <>
          <StyledBox>
            <Title>{obj?.name}</Title>
            <Grid container rowSpacing={3} columnSpacing={3}>
              {obj?.fields?.map((mappedField) => {
                let {
                  type,
                  name,
                  label,
                  placeholder,
                  Component: Field,
                  disabled,
                  colSpan,
                  validation,
                  show,
                  items,
                  required,
                  ...rest
                } = mappedField;

                const customRenderer =
                  FIELD_TYPE_RENDERERS_MAP[mappedField.name];

                if (!required && type === 'input') {
                  label += ' (optional)';
                }

                let customProps = {};
                if (name === country) {
                  customProps.optionsList = countriesList;
                }
                if (name === state) {
                  customProps.optionsList = states;
                  customProps.disabled =
                    !countriesList?.length ||
                    !isCountryValue ||
                    !states?.length;

                  validation = {
                    required: required && states?.length > 0,
                  };
                }
                if (name === no_middle_name) {
                  customProps.value = isNoMiddleNameChecked;
                  customProps.checked = isNoMiddleNameChecked;
                }
                if (name === street_address) {
                  customProps.endIcon = <Home color='#D9D9D9' />;
                }
                if (name === middle_name && !isNoMiddleNameChecked) {
                //   validation = {
                //     required: true,
                //   };
                }
                if (name === postal_code) {
                  validation = {
                    ...validation,
                    validate: posatlCodeValidation,
                  };
                }

                if (!show || (isNoMiddleNameChecked && name === middle_name))
                  return null;
                return (
                  <Grid
                    item
                    xs={
                      isMobile
                        ? 12
                        : (name === first_name || name === last_name) &&
                          isNoMiddleNameChecked
                        ? 6
                        : colSpan
                    }
                    key={name}
                  >
                    <Controller
                      name={name}
                      control={control}
                      rules={validation}
                      render={({ field }) =>
                        customRenderer ? (
                          customRenderer({
                            label,
                            placeholder,
                            name,
                            country: countryName,
                            inputRef: refMap[name],
                            error: errors?.[name],
                            disabled: disabled,
                            errorLabel:
                              errors?.[name]?.message ||
                              'This field is required',
                            ...rest,
                            ...field,
                          })
                        ) : (
                          <Field
                            name={name}
                            error={errors?.[name]}
                            errorLabel={
                              errors?.[name]?.message ||
                              'This field is required'
                            }
                            fullWidth={true}
                            label={label}
                            placeholder={placeholder}
                            disabled={disabled}
                            containerStyle={styles.checkboxContainer}
                            inputRef={refMap[name]}
                            {...customProps}
                            {...rest}
                            {...field}
                          />
                        )
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </StyledBox>
          {index !== fieldsMap?.length - 1 && <HorizontalDivider />}
        </>
      ))}

      {!isTabReadOnly && (
        <ButtonContainer>
          <Button
            sx={{ fontSize: '16px' }}
            disabled={isTabReadOnly ? true : isLoading}
            onClick={handleCancel}
            variant='link'
          >
            Cancel
          </Button>

          <Button
            startIcon={<CheckIcon width={14} height={14} />}
            sx={styles.buttonStyle}
            onClick={handleSubmit(onSubmit)}
            disabled={isTabReadOnly ? true : isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </ButtonContainer>
      )}
    </form>
  );
};
export default withPersonAttributes(GeneralInfo);
