import {GENERAL_ROLE} from './constants';

export const getLabelText = (role) => {
    if(role === GENERAL_ROLE.DEPENDENT){
        return 'Which best describes the dependent (minor or adult) you want to enroll?'
    }else if(role === GENERAL_ROLE.BIOLOGICAL_MINOR_CHILD){
        return 'Which best describes the biological minor child you want to enroll?'
    }else{
        return 'Which best describes the individual you want to enroll?';
    }
}