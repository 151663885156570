import { Box } from '@mui/material';
import {
  ItemName,
  StyledRadio,
  itemLinkStyles,
} from './NavigatorOption.styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link, useNavigate } from 'react-router-dom';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement='right' />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export const NavigatorOption = (props) => {
  const { title, icon, isExpanded, ...rest } = props;
  const navigate = useNavigate();

  return (
    <div>
      <Link to={props.value} style={itemLinkStyles}>
        <LightTooltip title={title}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <StyledRadio
              disableRipple
              color='default'
              checkedIcon={
                <Box className='StyledLabel StyledLabel-checked'>{icon}</Box>
              }
              icon={<Box className='StyledLabel'>{icon}</Box>}
              {...rest}
            />
            {isExpanded && (
              <ItemName
                checked={props.checked}
                onClick={() => navigate(props.value)}
              >
                {title}
              </ItemName>
            )}
          </Box>
        </LightTooltip>
      </Link>
    </div>
  );
};
