export const SIZES_STYLES = {
  lg: {
    height: '56px',
    padding: '17px 30px',
    gap: '15px',
    fontSize: '16px',
    lineHeight: '140%',
    letterSpacing: '0.02em',
    fontWeight: 600,
  },
  md: {
    height: '50px',
    padding: '15px 25px',
    gap: '15px',
    fontSize: '15px',
    lineHeight: '135%',
    letterSpacing: '0.02em',
    fontWeight: 600,
  },
  sm: {
    height: '44px',
    padding: '10px 16px',
    gap: '12px',
    fontSize: '14px',
    lineHeight: '140%',
    letterSpacing: '0.02em',
    fontWeight: 600,
  },
  xs: {
    height: '38px',
    padding: '12px 20px',
    gap: '12px',
    fontSize: '13px',
    lineHeight: '140%',
    letterSpacing: '0.02em',
    fontWeight: 600,
  },
};

export const TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
  info2: 'info2',
};

export const VARIANTS = {
  contained: 'contained',
  outlined: 'outlined',
  link: 'link'
};

export const getDisabledStyles = (type) => ({
  '&: disabled': {
    opacity: '0.5',
    cursor: 'not-allowed',
  },
});
