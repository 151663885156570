import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ChangeStatusModal from '../MyStudy/ChangeStatusModal';
import {
  StatusChangePageWrapper,
  Title,
  StyledBox,
  Button,
  StyledBackArrow,
  MyProfilePageHeader,
  PageTitle,
} from './styles';
import { statusoptions } from './helper';

const StatusChange = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);

  const toggleWithdrawModal = () =>
    setShowChangeStatusModal(!showChangeStatusModal);

  const navigateToProfile = () => navigate('/profile');
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleSubmission = (value) => {
    console.log('submit value', value);
  };

  return (
    <StatusChangePageWrapper>
      <MyProfilePageHeader onClick={upSm ? null : navigateToProfile}>
        <StyledBackArrow />
        {!upSm && <PageTitle>Change of Status</PageTitle>}
      </MyProfilePageHeader>

      <StyledBox>
        <div>
          <Title variant='h5'>Change of Status</Title>

          <Typography
            color={theme.palette.primary.darkGray70}
            variant='pl3'
            lineHeight={'145%'}
          >
            It is our hope that you stay in the study and continue to share your 
            data. However, if you would like to withdraw from the study, click the 
            button below.
          </Typography>
        </div>

        <Button onClick={() => setShowChangeStatusModal(true)}>
          Change Status
        </Button>
      </StyledBox>
      <ChangeStatusModal
        isOpen={showChangeStatusModal}
        onClose={toggleWithdrawModal}
        statusoptions={statusoptions}
        onSubmit={handleSubmission}
      />
    </StatusChangePageWrapper>
  );
};

export default StatusChange;
