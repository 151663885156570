import { useNavigate } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import PropTypes from 'prop-types';

import { useGetAccountProfileDetails } from 'hooks/useGetAccountProfileDetails';

import {
  ContentWrapper,
  Description,
  DescriptionWrapper,
  HorizontalDivider,
  ProfileAvatar,
  ProfileHeaderWrapper,
  Switchprofile,
  Username,
} from './ProfileComponents';
import { getPersonFullNameAndInitials } from 'pages/User/utils';

export const ProfileHeader = () => {
  const navigate = useNavigate();

  const { activeAccountProfile, person, personAttributes } = useSelector((state) => state.user);

  const { accountProfiles } = useGetAccountProfileDetails({
    personId: person?.id,
  });

  const firstName =
    activeAccountProfile?.attributes?.person?.attributes?.first_name;
  const lastName =
    activeAccountProfile?.attributes?.person?.attributes?.last_name;
  const preferredName = personAttributes?.preferred_name;
  const shouldShowSwitchProfile = accountProfiles?.length > 1;

  const { fullName, initials } = getPersonFullNameAndInitials(firstName, lastName, preferredName);

  return (
    <>
      <ProfileHeaderWrapper>
        <ProfileAvatar alt={fullName}>
          {initials || ''}
        </ProfileAvatar>
        <ContentWrapper>
          <DescriptionWrapper>
            <Username>
              {fullName}
            </Username>
            {/* <ProfileInfo activeProfile={activeAccountProfile} /> */}
            <Description>
              {activeAccountProfile?.attributes?.profile?.study_arm_title}
            </Description>
            {shouldShowSwitchProfile && (
              <Switchprofile onClick={() => navigate('/switch-profile')}>
                Switch Profile
              </Switchprofile>
            )}
          </DescriptionWrapper>
        </ContentWrapper>
      </ProfileHeaderWrapper>
      <HorizontalDivider />
    </>
  );
};

ProfileHeader.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};
