import PropTypes from 'prop-types';
import { Box, Modal, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { data } from 'mocks/studyoverview';
import {
  ModalContainer,
  ContentContainer,
  FuchsiaTitle,
  OverviewModalTitle,
  TitleSeparator,
  OverviewFirstSubtitleContainer,
  CloseButton,
} from 'components/Study/modal';
import { OverviewList } from './overview';
import { OverviewFirstSubtitle } from 'mocks/study';

const StudyOverviewModal = ({ isOpen, onClose }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <ModalContainer sx={{ backgroundColor: theme => theme.palette.primary.white }}>
      <OverviewModalTitle>STUDY OVERVIEW</OverviewModalTitle>
      <TitleSeparator />
      <ContentContainer>
        {data.map(({ id, section, body }) => (
          <Stack key={id}>
            <FuchsiaTitle>{section}</FuchsiaTitle>
            <div>{body}</div>
          </Stack>
        ))}
        <Box mt='10px'>
          <OverviewList />
        </Box>
        <OverviewFirstSubtitleContainer>
          {OverviewFirstSubtitle}
        </OverviewFirstSubtitleContainer>
        <CloseButton onClick={onClose}>
          <CloseIcon sx={{ marginRight: 1, width: 18, height: 20 }} /> Close
        </CloseButton>
      </ContentContainer>
    </ModalContainer>
  </Modal>
);

StudyOverviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default StudyOverviewModal;
