import Drawer from 'components/Drawer';
import React, { useState, useEffect } from 'react';
import { format, isValid } from 'date-fns';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ErrorWrapper } from 'components/ValidationError';
import { useGetTaskTypesQuery } from 'ihp-bloom-redux/features/tasks/taskApiSliceV3';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  setActivities,
  setRewards,
  initialState,
} from 'ihp-bloom-redux/features/filters/filtersSlice';
import Button from 'ihp-components/components/v2/Button';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';

import { capitalizeWords } from 'utils/string';
import { CustomDatePicker } from 'configUI/components/DatePicker';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { ReactComponent as CheckIcon } from 'images/shared/check-white-2.svg';
import { checkboxContainer, StyledInputLabel } from './styles';

const resolveAction = (type) => {
  if (type === 'activities') {
    return setActivities;
  }

  if (type === 'rewards') {
    return setRewards;
  }
};

const FilterDrawer = ({ open, onClose, anchor, type }) => {
  const filters = useSelector((state) => state.filters);
  const { taskCategory } = useSelector((state) => state.participantTask);
  const dispatch = useDispatch();
  const actionData = filters[type];
  const action = resolveAction(type);

  const [filtersData, setFiltersData] = useState({ ...actionData });
  const [allFilters, setAllFilters] = useState([]);
  const [queryParams, setQueryParams] = useState({ page: 1, limit: 10 });
  const [appliedFilters, setAppliedFilters] = useState(null);

  const {
    data: taskTypesResponse,
    isLoading: isLoadingTaskTypes,
    isFetching,
    next,
    pageMeta,
  } = useGetTaskTypesQuery(
    {
      'page[page]': queryParams.page,
      'page[limit]': queryParams.limit,
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        data: !data
          ? []
          : data.data
              .filter((item) => {
                const filterScope = item.attributes.filter_scope
                  ? JSON.parse(item.attributes.filter_scope)
                  : null;
                return (
                  !item.attributes.deletedAt && filterScope && filterScope[type]
                );
              })
              .map((item) => ({
                value: item.attributes.code,
                label: item.attributes.title,
              })),
        next: data?.links?.next,
        pageMeta: data?.meta?.page,
        isLoading: rest.isLoading,
        ...rest,
      }),
    }
  );

  const onCancel = () => {
    const initialStateToUse = appliedFilters || initialState[type];
    setFiltersData({
      ...initialStateToUse,
    });
    onClose();
  };

  const clearAll = () => {
    setFiltersData({
      ...initialState[type],
    });
    reset();
  };

  useEffect(() => {
    clearAll();
  }, [taskCategory]);

  useEffect(() => {
    if (next && !isLoadingTaskTypes && !isFetching && pageMeta) {
      setQueryParams({
        page: pageMeta.currentPage + 1,
        limit: 10,
      });
    }

    if (type === 'activities') {
      // const allowedValues = ['data_sharing', 'profile', 'survey'];
      //SING-962
      const allowedValues = ['profile', 'survey'];

      const newFiltersArr = [...allFilters];

      allowedValues.forEach((allowedValue) => {
        const isFound = newFiltersArr.find(
          (filterItem) => filterItem.value === allowedValue
        );
        if (!isFound) {
          let capitalizedLabel = capitalizeWords(allowedValue);
          if (capitalizedLabel === 'Data Sharing') {
            capitalizedLabel = 'Medical Records';
          }
          newFiltersArr.push({
            value: allowedValue,
            label: capitalizedLabel,
          });
        }
      });
      setAllFilters(newFiltersArr);
    } else {
      const newFiltersArr = [...allFilters];

      taskTypesResponse.forEach((item) => {
        const isFound = newFiltersArr.find(
          (filterItem) => filterItem.value === item.value
        );
        if (!isFound) {
          let capitalizedLabel = capitalizeWords(item);
          if (capitalizedLabel === 'Data Sharing') {
            capitalizedLabel = 'Medical Records';
          }
          newFiltersArr.push({
            value: item,
            label: capitalizedLabel,
          });
        }
      });
      setAllFilters(newFiltersArr);
    }
    // Clear filters state on component unmount (use case: switching pages)
    return () => {
      dispatch(
        action({
          ...initialState[type],
        })
      );
    };
  }, [next, isLoadingTaskTypes, isFetching, pageMeta]);

  const {
    control,
    formState: { errors },
    reset,
    watch,
    getValues,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: { ...filtersData },
  });
  const endDate = watch('endDate');
  const startDate = watch('startDate');

  useEffect(() => {
    setValue('startDate', startDate, { shouldValidate: true });
  }, [endDate, setValue]);

  const onSubmit = (data) => {
    const { startDate, endDate } = data;

    const actionData = {
      ...data,
      selectedTypes: filtersData.selectedTypes,
      balances: filtersData.balances,
      startDate: isValid(startDate) ? format(startDate, 'yyyy-MM-dd') : null,
      endDate: isValid(endDate) ? format(endDate, 'yyyy-MM-dd') : null,
      timestamp: new Date().getTime(),
    };

    dispatch(action(actionData));
    setAppliedFilters(actionData); // Store the applied filters
    onClose();
  };

  const handleOnFiltersChange =
    (field, multipleSelect = true) =>
    (e) => {
      const value = e.target.value;
      let selected = [...filtersData[field]];

      if (value === 'show_all') {
        selected = ['show_all'];

        setFiltersData({
          ...filtersData,
          [field]: selected,
        });
        return;
      }

      if (!multipleSelect) {
        selected = selected.filter((item) => item === value);
        setFiltersData({
          ...filtersData,
          [field]: !selected.length ? [value] : ['show_all'],
        });
        return;
      }

      // remove show_all from array as one of other options is selected
      selected = selected.filter((item) => item !== 'show_all');

      if (selected.indexOf(value) !== -1) {
        selected = selected.filter((item) => item !== value);
        setFiltersData({
          ...filtersData,
          [field]: selected.length ? selected : ['show_all'],
        });
      } else {
        setFiltersData({
          ...filtersData,
          [field]: [value, ...selected],
        });
      }
    };

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <form style={{ height: '100%' }}>
        <Box
          sx={{
            p: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            minWidth: '380px',
            gap: '25px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h2'>Filters</Typography>
            <IconButton onClick={onClose}>
              <XIcon color='#959CA0' />
            </IconButton>
          </Box>
          <Stack sx={{ flex: 1 }}>
            <StyledInputLabel>ACTIVITY TYPE</StyledInputLabel>
            <FormCheckbox
              containerStyle={checkboxContainer}
              value='show_all'
              label='All Activities'
              checked={filtersData.selectedTypes.indexOf('show_all') !== -1}
              onChange={handleOnFiltersChange('selectedTypes')}
            />
            {allFilters.map(({ value, label }) => (
              <FormCheckbox
                key={value}
                value={value}
                containerStyle={checkboxContainer}
                label={label.replace('_', ' ')}
                checked={filtersData.selectedTypes.indexOf(value) !== -1}
                onChange={handleOnFiltersChange('selectedTypes')}
              />
            ))}
            {type === 'rewards' && (
              <Stack mt={4}>
                <StyledInputLabel>BALANCE</StyledInputLabel>
                <FormCheckbox
                  containerStyle={checkboxContainer}
                  value='show_all'
                  label='All points'
                  checked={filtersData.balances.indexOf('show_all') !== -1}
                  onChange={handleOnFiltersChange('balances', false)}
                />
                <FormCheckbox
                  value={'value_gained'}
                  containerStyle={checkboxContainer}
                  label={'Earned points'}
                  checked={filtersData.balances.indexOf('value_gained') !== -1}
                  onChange={handleOnFiltersChange('balances', false)}
                />
                <FormCheckbox
                  value={'points-redeemed'}
                  containerStyle={checkboxContainer}
                  label={'Redeemed points'}
                  checked={
                    filtersData.balances.indexOf('points-redeemed') !== -1
                  }
                  onChange={handleOnFiltersChange('balances', false)}
                />
              </Stack>
            )}

            <Stack gap={'10px'} display={'flex'} mt={4}>
              <StyledInputLabel>DATE RANGE</StyledInputLabel>
              <ErrorWrapper>
                <Controller
                  name='startDate'
                  control={control}
                  rules={{
                    validate: (date) => {
                      if (date === '') {
                        return true;
                      }
                      const startDate = new Date(date);
                      const isValidStartDate = isValid(startDate);
                      if (!isValidStartDate) {
                        return 'Start date is not valid.';
                      }
                      if (endDate === '') {
                        return true;
                      }
                      const valid = startDate <= new Date(endDate);
                      return (
                        valid || 'Start date can not be bigger than end date'
                      );
                    },
                  }}
                  haserrors={
                    errors.startDate && {
                      type: 'custom',
                      message:
                        errors?.startDate?.message || 'This field is required',
                    }
                  }
                  render={({ field }) => (
                    <CustomDatePicker
                      value={filtersData.startDate}
                      fullWidth
                      placeholder='Select Start Date'
                      {...field}
                    />
                  )}
                />
              </ErrorWrapper>

              <ErrorWrapper>
                <Controller
                  name='endDate'
                  control={control}
                  haserrors={
                    errors.endDate && {
                      type: 'custom',
                      message:
                        errors?.endDate?.message || 'This field is required',
                    }
                  }
                  rules={{
                    validate: (date) => {
                      if (date === '') {
                        return true;
                      }
                      const endDate = new Date(date);
                      const isValidEndDate = isValid(endDate);
                      if (!isValidEndDate) {
                        return 'End date is not valid.';
                      }
                      const { startDate } = getValues();
                      if (startDate === '') {
                        return true;
                      }
                      const valid = new Date(startDate) <= endDate;
                      return (
                        valid || 'End date can not be smaller than start date'
                      );
                    },
                  }}
                  render={({ field }) => (
                    <CustomDatePicker
                      value={filtersData.endDate}
                      fullWidth
                      placeholder='Select End Date'
                      {...field}
                    />
                  )}
                />
              </ErrorWrapper>
            </Stack>
          </Stack>

          <Box
            sx={{
              display: 'flex',
              gap: '15px',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button sx={{ px: 0 }} variant='link' onClick={clearAll}>
              Clear all
            </Button>
            <Button sx={{ px: 0 }} variant='link' onClick={onCancel}>
              Cancel
            </Button>
            <Button
              height='50px'
              startIcon={<CheckIcon />}
              onClick={handleSubmit(onSubmit)}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </form>
    </Drawer>
  );
};

FilterDrawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.oneOf(['activities', 'rewards']),
};

export default FilterDrawer;
