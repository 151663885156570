import { Footer } from "pages/Welcome/Footer";
import { GetStarted } from "pages/Welcome/GetStarted";
import { Nav } from "pages/Welcome/Header/Nav";
import AnyQuestions from "pages/any-questions";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { shouldRenderAnyQuestions, shouldRenderGetStarted } from "./utils";

export const LandingLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const navigateToWelcomeToStudy = () => {
        navigate('/onboarding/welcome-to-study');
    };

    return (
        <>
            <Nav />
            <Outlet />
            {shouldRenderGetStarted(location.pathname) ? 
                <GetStarted onGetStarted={navigateToWelcomeToStudy} /> 
                    : <></>}
            {shouldRenderAnyQuestions(location.pathname) ? <AnyQuestions /> : <></>}
            <Footer />
        </>
    );
};
