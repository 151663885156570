import { VIRTUAL_SITE } from 'constants/global';
import { convertDateStringToUTC, formatDateToDB } from 'utils/date';

export const createPersonStudyArmPayload = ({
  personId,
  studyArmId,
  studyArmStatusId,
  studyArmRoleId,
}) => {
  const payload = {
    data: {
      type: 'person-study-arm',
      attributes: {
        person_id: Number(personId),
        study_arm_id: studyArmId,
        study_arm_status_id: studyArmStatusId,
        site_id: VIRTUAL_SITE.id,
        study_arm_role_id: studyArmRoleId,
      },
    },
  };
  return payload;
};

export const createPersonPayload = () => {
  const payload = {
    data: {
      type: 'people',
      attributes: {
        state: 'enrollment',
        status: 'ini',
      },
    },
  };
  return payload;
};

export const updatePersonStudyArmPayload = ({
  studyArmId,
  personStudyArmId,
  studyArmRoleId,
  studyArmStatusId,
  personForWhomId,
}) => {
  let payload = {
    data: {
      type: 'person-study-arms',
      id: String(personStudyArmId),
      attributes: {},
    },
  };

  if (studyArmId) {
    payload.data.attributes.study_arm_id = studyArmId;
  }

  if (studyArmStatusId) {
    payload.data.attributes.study_arm_status_id = studyArmStatusId;
  }

  if (studyArmRoleId) {
    payload.data.attributes.study_arm_role_id = studyArmRoleId;
  }

  if (personForWhomId) {
    payload.data.attributes.person_for_whom_id = Number(personForWhomId);
  }
  return payload;
};

export const createAccountProfilePayload = ({
  accountPersonStudyArmId,
  subjectPrimaryPersonStudyArmId,
}) => {
  const payload = {
    data: {
      type: 'account-profiles',
      attributes: {
        account_person_study_arm_id: Number(accountPersonStudyArmId),
        subject_primary_person_study_arm_id: Number(
          subjectPrimaryPersonStudyArmId
        ),
      },
    },
  };
  return payload;
};

export const updateAccountProfilePayload = ({
  accountProfileId,
  subjectPrimaryPersonStudyArmId,
  accountPersonStudyArmId,
  subjectSecondaryPersonStudyArmId,
}) => {
  const payload = {
    data: {
      type: 'account-profiles',
      id: `${accountProfileId}`,
      attributes: {
        subject_primary_person_study_arm_id: Number(
          subjectPrimaryPersonStudyArmId
        ),
      },
    },
  };
  if (accountPersonStudyArmId) {
    payload.data.attributes.account_person_study_arm_id = Number(
      accountPersonStudyArmId
    );
  }
  if (subjectSecondaryPersonStudyArmId) {
    payload.data.attributes.subject_secondary_person_study_arm_id = Number(
      subjectSecondaryPersonStudyArmId
    );
  }
  return payload;
};

export const updatePersonAttributePayload = (data) => {
  let payload = [
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'first_name',
          value: data.first_name,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'last_name',
          value: data.last_name,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'date_of_birth',
          value: formatDateToDB(data.date_of_birth),
        },
      },
    },
  ];
  if (data?.middle_name) {
    payload = [
      ...payload,
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'middle_name',
            value: data.middle_name,
          },
        },
      },
    ];
  }

  if (data?.pronouns) {
    payload = [
      ...payload,
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'pronouns',
            value: data.pronouns,
          },
        },
      },
    ];
  }

  return payload;
};
