import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, MenuItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import {
  useGetCurrentUserQuery,
  useGetStudyRolesQuery,
  useEditPersonMutation,
  userApiSlice,
  useGetTimezonesQuery,
} from 'ihp-bloom-redux/features/user/userApiSlice';

import { Loader } from 'components/Loader';
import Button from 'ihp-components/components/v2/Button';
import { LoginContainer, EmailContainer, FormLabel } from 'components/Login';
import { Logo } from 'components/shared';
import { getPersonPayload } from './personalInfoPayload';
import { Form, getStyles } from './styles/index.mobile.styles';
import { ReactComponent as CalendarIcon } from 'configUI/images/icons/calendar.svg';
import { ReactComponent as LocationIcon } from 'images/personalinformation/location.svg';
import { ReactComponent as PhoneIcon } from 'images/shared/phone.svg';
import { useValidateDate, useValidatePhone, useValidateZipCode } from './hooks';
import { Input } from 'ihp-components/components/v2/Input';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';

function PersonalInformationMobile() {
  const theme = useTheme();
  let navigate = useNavigate();
  const styles = getStyles(theme);
  const dispatch = useDispatch();
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const { user, language } = useSelector((state) => state);
  const { isAuthenticated, user: auth0User } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const { studyRoleId } = useGetStudyRolesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      studyRoleId: data?.data?.[0]?.id,
      ...rest,
    }),
  });
  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });
  const { timezones } = useGetTimezonesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      timezones: data || [],
    }),
  });

  const validateDate = useValidateDate();
  const validateZipCode = useValidateZipCode();
  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...user,
      dob: '',
      gender: '',
      phone: '',
      postal_code: '',
      timezone: '',
      language: 'English',
    },
  });

  const onSubmit = (data) => {
    const payload = getPersonPayload(
      {
        ...data,
        email: auth0User?.email,
        firstName: auth0User?.given_name,
        lastName: auth0User?.family_name,
      },
      studyRoleId
    );
    editPerson({ personId: person_id, payload })
      .then(() => {
        dispatch(setValues(data));
        dispatch({
          type: `${userApiSlice.reducerPath}/invalidateTags`,
          payload: ['CurrentUser'],
        });
        navigate('/enrolled');
      })
      .catch(console.error);
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  return (
    <LoginContainer sx={{ height: '100%' }}>
      <EmailContainer>
        <Box sx={styles.formHeader}>
          <Logo />
          <FormLabel fontSize='24px' marginTop='15px'>
            Personal Information
          </FormLabel>
          <Typography color='primary.gray75' fontSize='14px' textAlign='center'>
            Please enter your personal information to finalize your profile
            creation
          </Typography>
        </Box>
        <Form>
          <Box sx={styles.formContainer}>
            <Controller
              name='dob'
              control={control}
              rules={{
                validate: validateDate,
                required: true,
              }}
              render={({ field }) => (
                <Input
                  label='Date of birth'
                  type='datePicker'
                  error={errors.dob}
                  errorLabel={errors?.dob?.message || 'This field is required'}
                  value={user.dob}
                  fullWidth
                  placeholder='Select date'
                  endIcon={<CalendarIcon />}
                  {...field}
                />
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='gender'
              control={control}
              rules={{
                required: true,
              }}
              haserrors={errors.gender}
              render={({ field }) => (
                <Input
                  type='radioInputGroup'
                  id='gender'
                  name='gender'
                  label='Sex at birth'
                  error={errors.gender}
                  errorLabel={errors.gender?.message}
                  value={user.gender}
                  sx={styles.radioGroup}
                  {...field}
                >
                  <RadioInput
                    fullWidth
                    sx={styles.radio}
                    value='male'
                    label='Male'
                  />
                  <RadioInput
                    fullWidth
                    sx={styles.radio}
                    value='female'
                    label='Female'
                  />
                </Input>
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='phone'
              control={control}
              rules={{
                validate: validatePhone,
                required: true,
              }}
              render={({ field }) => (
                <MaskedInput
                  placeholder='Enter phone number'
                  mask='+1 (999) 999-9999'
                  {...field}
                >
                  {(inputProps) => (
                    <Input
                      fullWidth
                      size='sm'
                      name='phone'
                      label='Phone'
                      error={errors.phone}
                      errorLabel={
                        errors?.phone?.message || 'This field is required'
                      }
                      endIcon={<PhoneIcon color='#DFE2E3' />}
                      {...inputProps}
                    />
                  )}
                </MaskedInput>
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='postal_code'
              control={control}
              rules={{
                validate: validateZipCode,
                required: true,
              }}
              render={({ field }) => (
                <MaskedInput
                  placeholder='Enter zip code'
                  mask='99999'
                  {...field}
                >
                  {(inputProps) => (
                    <Input
                      fullWidth
                      size='sm'
                      name='postal_code'
                      label='ZIP code'
                      error={errors.postal_code}
                      errorLabel={
                        errors?.postal_code?.message || 'This field is required'
                      }
                      endIcon={<LocationIcon color='#DFE2E3' />}
                      {...inputProps}
                    />
                  )}
                </MaskedInput>
              )}
            />
          </Box>
          <Box sx={styles.formContainer}>
            <Controller
              name='timezone'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  error={errors.timezone}
                  errorLabel={errors?.timezone?.messahe}
                  type='select'
                  label='Select timezone'
                  fieldLabel='Timezone'
                  placeholder='Select timezone'
                  fullWidth
                  size='sm'
                  {...field}
                >
                  {timezones?.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Input>
              )}
            />
          </Box>

          <Box sx={styles.formContainer}>
            <Controller
              name='language'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  type='select'
                  error={errors.language}
                  errorLabel={errors.language?.message}
                  label='Select language'
                  fieldLabel='Study language'
                  placeholder='Select language'
                  fullWidth
                  sx={styles.selectInput}
                  {...field}
                >
                  {language.allLanguages
                    .filter(({ name }) => name === 'English')
                    .map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                </Input>
              )}
            />
          </Box>
          <Box sx={styles.formContainer} component='div'>
            <Button
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={styles.submitButton}
              loading={isEditingPerson}
            >
              Save
            </Button>
          </Box>
        </Form>
      </EmailContainer>
    </LoginContainer>
  );
}

export default PersonalInformationMobile;
