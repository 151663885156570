import { styled } from '@mui/system';
import Button from 'ihp-components/components/v2/Button';

export const StyledButton = styled(Button)({
  height: '43px',
  maxWidth: '325px',
  borderRadius: '4px',
  fontWeight: 600,
  fontSize: '13px',
  lineHeight: '145%',
});
