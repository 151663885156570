import React from 'react';
import StudyOverviewMobile from './index.mobile';
import { Grid, Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ContinueButton, OnboardingContainer } from 'components/Onboarding';
import BodyContainer from 'components/InformedConsent/BodyContainer';
import { useTheme } from '@mui/material/styles';
import getStyles from './styles';
import { data } from 'mocks/studyoverview';
import { ReactComponent as ArrowForwardIcon } from 'images/shared/arrow-right-2.svg';

function StudyOverview() {
  document.title = 'Single Ventricle SOURCE Overview';
  const theme = useTheme();
  const styles = getStyles(theme);
  let navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const redirect = () => {
    navigate('/final');
  };

  if (isMobile) {
    return <StudyOverviewMobile />;
  }

  return (
    <OnboardingContainer>
      <Grid container>
        <Grid item xl={12} sx={styles.mainContainer}>
          <BodyContainer title='STUDY OVERVIEW' data={data} />
        </Grid>
        <Grid item xl={12} sx={styles.footerContainer}>
          <Box sx={styles.buttonContainer} component='div'>
            <ContinueButton
              fullWidth={true}
              onClick={redirect}
              endIcon={<ArrowForwardIcon />}
            >
              Continue
            </ContinueButton>
          </Box>
        </Grid>
      </Grid>
    </OnboardingContainer>
  );
}
export default StudyOverview;
