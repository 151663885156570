import { Stack, Typography } from '@mui/material';

import { Divider } from 'configUI/components/Shared';
import { Container, Edge } from './Card.styles.js';
import { formatDate } from './utils';

const Item = ({ item: { questionTitle, questionType, answer } }) => {
  const formattedAnswer =
    answer && questionType === 'datePicker'
      ? formatDate(answer, 'eeee, MM/dd/yyyy')
      : answer;
  return (
    <Stack spacing='3px' px='15px' justifyContent='center' height='65px'>
      <Typography variant='pb3' color='primary.gray100'>
        {questionTitle}
      </Typography>
      <Typography variant='pb5' color='primary.fuchsia75' noWrap>
        {formattedAnswer}
      </Typography>
    </Stack>
  );
};

export const Card = ({ title, form }) => (
  <Container>
    <Stack divider={<Divider />}>
      <Edge>
        <Typography
          variant='pb5'
          color='primary.gray50'
          sx={{ textTransform: 'uppercase' }}
        >
          {title}
        </Typography>
      </Edge>
      {form.map(({ id, ...item }) => (
        <Item key={id} item={item} />
      ))}
    </Stack>
  </Container>
);
