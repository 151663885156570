import { FamilyLinkageErrorModal } from 'features/familyLinkage/modals/receiveInvite/FamilyLinkageErrorModal';
import { FamilyLinkageInvitationModal } from 'features/familyLinkage/modals/receiveInvite/FamilyLinkageInvitationModal';
import { FamilyLinkageSuccessModal } from 'features/familyLinkage/modals/receiveInvite/FamilyLinkageSuccessModal';
import { useGetFamilyPendingInvitations } from 'features/familyLinkage/hooks/useGetFamilyPendingInvitations';
import { useIsActiveProfileContactRole } from 'features/profile/hooks/useIsActiveProfileContactRole';
import { useCallback, useEffect, useState } from 'react';

export function FamilyLinkageGuard({ setIsFamilyModalClosed }) {
  const [modalView, setModalView] = useState(null);

  const familyInvitations = useGetFamilyPendingInvitations();
  const { isActiveProfileContactRole } = useIsActiveProfileContactRole();

  useEffect(() => {
    if (familyInvitations.isSuccess && familyInvitations.data.length > 0) {
      setModalView('CONFIRMATION_MODAL');
    }
  }, [familyInvitations.data.length, familyInvitations.isSuccess]);

  useEffect(() => {
    if (!modalView && familyInvitations.isSuccess && familyInvitations.data.length === 0) {
      setIsFamilyModalClosed(true);
    }
  }, [modalView, familyInvitations.data.length, familyInvitations.isSuccess]);

  const onAccept = useCallback(() => {
    setModalView('SUCCESS_MODAL');
  }, []);

  const onClose = useCallback(() => {
    setModalView(null);
    setIsFamilyModalClosed(true);
  }, []);

  const onError = useCallback(() => {
    setModalView('ERROR_MODAL');
  }, []);

  return (
    <>
      {!isActiveProfileContactRole && (
        <>
          {familyInvitations.data.length > 0 && (
            <FamilyLinkageInvitationModal
              onAccept={onAccept}
              onClose={onClose}
              onDecline={onClose}
              onError={onError}
              open={modalView === 'CONFIRMATION_MODAL'}
              setIsFamilyModalClosed={setIsFamilyModalClosed}
            />
          )}

          <FamilyLinkageErrorModal
            onClose={onClose}
            open={modalView === 'ERROR_MODAL'}
            setIsFamilyModalClosed={setIsFamilyModalClosed}
          />

          <FamilyLinkageSuccessModal
            onClose={onClose}
            open={modalView === 'SUCCESS_MODAL'}
            type='accept'
            setIsFamilyModalClosed={setIsFamilyModalClosed}
          />
        </>
      )}
    </>
  );
}
