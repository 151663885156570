import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

import Button from 'ihp-components/components/v2/Button';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.primary.gray20,
  padding: '60px 60px 0px 60px',
  display: 'flex',
  justifyContent: 'center',
}));

export const MobileContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.gray5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const MobileListContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray5,
  marginBottom: '10px',
  gap: '20px',
  display: 'flex',
  flexDirection: 'column',
}));

export const EmptyListWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80vh',
  padding: '40px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.white,
}));

export const StyledStack = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  width: '75%',
  maxWidth: '820px',
  flex: 1,
});

export const ArrowBackwardIcon = styled(ArrowForwardIcon)({
  transform: 'rotate(180deg)',
});

export const ActivitiesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
  gap: '10px',
});

export const ActivitiesDateHeader = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '21.6px',
  color: '#606B71',
  marginBottom: '10px',
  marginTop: '33px',
  [theme.breakpoints.down('sm')]: {
    margin: '0',
  },
}));

export const LoaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
}));

export const LoaderTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '145%',
  color: theme.palette.primary.gray100,
}));

export const LoaderSubTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
}));

export const MainHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const MainHeaderButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: '350px',
  gap: '5px',
});

export const Divider = styled(Box)({
  borderLeft: '2px solid #D9D9D9',
  height: '24px',
  marginLeft: '15px',
});

export const FiltersButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  background: '#F8F8F8',
  color: '#677176',
  padding: '10px 15px',
  fontSize: '12px',
  fontWeight: 'bold',
  maxHeight: '40px',
  '&:hover': {
    background: '#F8F8F8',
    color: '#677176',
    cursor: 'pointer',
  },
});

export const FiltersTitle = styled(Box)({
  color: '#A0438B',
  marginLeft: '10px',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '17px',
});

export const getStyles = (
  theme,
  task,
  isTaskGroup,
  isChild,
  isLast,
  isOverdue
) => {
  let color = theme.palette.primary.darkGray100;
  let backgroundColor = '#FFFFFF';
  let width = '100%';
  let margin = isMobile() ? '10px' : '10px 0';
  let flexDirection = isMobile() ? 'column' : 'row';
  let borderRadius;

  if (task?.isDueTomorrow) {
    color = theme.palette.primary.darkGray100;
  }

  if (task?.displayStatus === 'past') {
    backgroundColor = '#FFFFFF';
    color = '#2B3A41';
  }

  if (isTaskGroup) {
    borderRadius = '10px 10px 0 0';
    margin = isMobile() ? '0 10px 0 10px' : '10px -1px -1px';
  }
  if (isChild) {
    borderRadius = '0';
    margin = isMobile() ? '0 10px 0 10px' : '-1px';
  }
  if (isLast) {
    borderRadius = '0 0 10px 10px';
    margin = isMobile() ? margin : '-1px -1px 10px -1px';
  }

  let border = 'none';
  if (isOverdue && task?.displayStatus !== 'past') {
    backgroundColor = theme.palette.primary.magenta5;
    border = `1px solid ${theme.palette.primary.magenta70}`;
  }

  return {
    [theme.breakpoints.up('sm')]: { margin },
    boxShadow: '0px 0px 30px 0px #0054870D',
    display: 'flex',
    justifyContent: 'space-between',
    border,
    overflow: 'auto',
    backgroundColor,
    color,
    width,
    margin,
    flexDirection,
    borderRadius,
  };
};
