import { Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'ihp-components/components/v2/Button';

import {
  DetailsWrapper,
  DetailsHeaderSection,
  CallDetailsBox,
  CallDay,
  CallDate,
  CallTime,
  CallManipulationActions,
} from './styles';
import { reformatDate } from './utils';

export const Details = ({ data, onCancel }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();

  const reschedule = () => {
    navigate(`/activity/schedule-call/${id}?is_rescheduling=true`);
  };

  const dateString = reformatDate(data?.date);

  return (
    <DetailsWrapper>
      <DetailsHeaderSection>
        <Typography variant='h2' align='center' color='secondary.gray4'>
          Remote appointment details
        </Typography>
        <Typography variant='pl4' align='center' color='primary.gray75'>
          Please find the details below for your upcoming remote appointment.
        </Typography>
      </DetailsHeaderSection>
      <CallDetailsBox>
        <CallDay>
          <Typography variant='h1' align='center' color='primary.gray100'>
            {data.dayString}
          </Typography>
        </CallDay>
        <CallDate>
          <Typography variant='h2' align='center' color='primary.gray100'>
            {dateString}
          </Typography>
        </CallDate>
        <CallTime>
          <Typography variant='h2' align='center'>
            {data.time}
          </Typography>
          <Typography variant='pn1' align='center' color='primary.gray75'>
            Timezone: {data.timezone}
          </Typography>
        </CallTime>
      </CallDetailsBox>
      <CallManipulationActions>
        <div>
          <Button
            variant='link'
            type='info2'
            color={theme.palette.primary.darkBlue70}
            onClick={reschedule}
          >
            Reschedule
          </Button>
        </div>
        <div>
          <Button
            variant='link'
            type='info2'
            color={theme.palette.primary.darkBlue70}
            onClick={onCancel}
          >
            Cancel call
          </Button>
        </div>
      </CallManipulationActions>
    </DetailsWrapper>
  );
};
