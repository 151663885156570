export const areAllFieldsEmpty = (fields) => {
  const fieldValues = Object.values(fields);
  const areEmpty = fieldValues.every((field) => field === '');
  return areEmpty;
};

export const getEmptyFields = (fields) => {
  const fieldKeys = Object.keys(fields);
  const emptyFields = fieldKeys.reduce((acc, currVal) => {
    if (fields[currVal] === '') {
      acc.push(currVal);
    }
    return acc;
  }, []);
  return emptyFields;
};

export const getPersonFullNameAndInitials = (firstName, lastName, preferredName) => {
  const preferredFirstName = preferredName ? preferredName : firstName;

  return {
    fullName: (preferredFirstName || "") + " " + (lastName || ""),
    initials: preferredFirstName && lastName ? `${preferredFirstName[0]}${lastName[0]}` : ' '
  };
};