export const bestDescribeOptions = Object.freeze({
  born_with_defect: 'I was born with a single ventricle heart defect',
  family_member_with_defect:
    'My biological immediate family member is living with a single ventricle heart defect',
  guardian_of_patient:
    'I am a guardian of someone living with a single ventricle heart defect',
  guardian_of_family_member_patient:
    'I am a guardian of someone whose biological immediate relative is living with a single ventricle heart defect',
  lost_family_member_to_defect:
    'I have lost a family member with a single ventricle heart defect',
  none_apply: 'None of these apply to me',
});

export const nonCombinableBestDescribeOptions = Object.freeze([
  'None of these apply to me',
]);

export const INEGIBILITY_REASONS = {
  BEST_DESCRIBE_OPTIONS: 'best_describe_options',
  ELIGIBLE_COUNTRY: 'eligible_country',
  UNDER_AGE: 'under_age',
};
