import { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as AvLogo } from 'images/av-logo-vertical.svg';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { Title, Text } from '../../Onboarding/components/Text/index.js';
import OnboardingLayout from '../components/Layout';
import { findStudyAttribute } from 'utils/study';
import { getAuth0LoginPageConfig } from 'utils/auth0';

import {
  Row,
  ButtonContainer,
  SignUpButton,
  SignInButton,
  StyledAlert,
} from './styles';
import { useAuth0Extended } from 'hooks/useAuth0Extended.js';
import { Loader } from 'components/Loader/index.js';
import { useLogout } from 'utils/useLogout.js';
import { persistor } from 'App.js';
import { iOSDeviceWarning } from './constants.js';

function WelcomeToStudy() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const logout = useLogout('/onboarding/welcome-to-study');
  const [searchParams] = useSearchParams();
  if (searchParams.get('redirected_from') === 'family_linkage_invitation') {
    logout();
  }

  const { configuration } = useSelector((state) => state);
  const { person } = useSelector((state) => state.user);

  const { loginWithRedirect, routeIsAuthenticated, isLoading } =
    useAuth0Extended();

  const languages =
    findStudyAttribute(configuration.studyConfiguration || [], 'languages') ||
    [];
  const welcomeScreen =
    findStudyAttribute(
      configuration.studyConfiguration || [],
      'welcome_screen'
    ) || [];

  const urlParams = new URLSearchParams(location.search);
  const reporterInvitedToken = urlParams.get('token');

  const navigateUser = () => {
    /**
     * Saving invite token to state to use it after coming from auth0 callback while creating account
     */

    dispatch(
      setValues({
        language: languages?.[0]?.language || 'en-US',
        reporterInviteToken: reporterInvitedToken ?? null,
      })
    );

    navigate('/onboarding/basic-information');
  };
  const openLoginPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig({ context: 'signin' }));
  };

  useEffect(() => {
    const process = async () => {
      if (routeIsAuthenticated) {
        return navigate('/auth0-callback');
      } else if (person?.id && !isLoading && !routeIsAuthenticated) {
        /* Make sure to clear local storage if user visits this page and session is expired */
        await sessionStorage.clear();
        await localStorage.clear();
        await persistor.purge();
      }
    };

    process();
  }, [navigate, routeIsAuthenticated]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <OnboardingLayout maxWidth='490px'>
      <Row>
        <AvLogo />
      </Row>

      <Title varient='h2' marginBottom='16px'>
        {welcomeScreen.title ?? 'Welcome'}
      </Title>

      <Text align='center'>
        {welcomeScreen.description ? (
          <div
            dangerouslySetInnerHTML={{
              __html: welcomeScreen.description,
            }}
          />
        ) : (
          <>
            Thank you for your interest in participating in
            <br />
            SOURCE. We are so happy you’re here.
            <br />
            <br />
            Please select ‘sign up’ below and set your profile preferences
            <br />
            to create your account.
            <br />
            <br />
            <i>
              Note: You may change your preference choices in
              <br />
              your profile at any time.
            </i>
          </>
        )}
      </Text>

      <StyledAlert severity='warning'>{iOSDeviceWarning}</StyledAlert>

      <ButtonContainer>
        <SignUpButton fullWidth onClick={navigateUser}>
          Sign up
        </SignUpButton>
        <SignInButton onClick={() => openLoginPage()} variant='link'>
          Sign in
        </SignInButton>
      </ButtonContainer>
    </OnboardingLayout>
  );
}

export default WelcomeToStudy;
