import { useMediaQuery, useTheme } from '@mui/material';

import CreateMedicationTrackerDesktop from './index.desktop';
import CreateMedicationTrackerMobile from './index.mobile';

const CreateMedicationTracker = () => {
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  return upSm ? (
    <CreateMedicationTrackerDesktop />
  ) : (
    <CreateMedicationTrackerMobile />
  );
};

export default CreateMedicationTracker;
