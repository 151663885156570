import { Box } from '@mui/material';
import { styled } from '@mui/system';
import {
  /* the components you used */
  Tooltip as MuiTooltip,
  tooltipClasses,
} from '@mui/material';
export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    h1: {
      fontSize: '20px',
    },
    '.MuiTypography-pl4': {
      fontSize: '12px',
    },
  },
}));

export const DescriptionBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
});

export const StyledTooltip = styled(({ className, color, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    width: '200px',
    borderRadius: '4px',
    padding: '2px 8px',
    backgroundColor: theme.palette.primary.white,
    color: '#696969',
    fontSize: '14px',
    lineHeight: '24px',
    border: '1px solid #ECECEC',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `1px solid #ECECEC`,
      color: '#fff',
    },
  },
}));
