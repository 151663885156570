import { FormGroup, FormHelperText, FormLabel, Radio } from '@mui/material';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';
import { ReactComponent as SmallCheckedboxIcon } from '../../images/components/check.svg';
import { CustomFormControlLabel, getStyles } from './CustomRadioInput.styles';

const CustomRadioInput = (props) => {
  const { key, label, selectedOption, fieldLabel, error, ...rest } = props;
  const styles = getStyles();
  return (
    <div style={styles.containerStyle}>
      {fieldLabel && (
        <FormLabel style={styles.formLabel}>{fieldLabel}</FormLabel>
      )}
      <FormGroup style={styles.formGroup}>
        <CustomFormControlLabel
          key={key}
          value={selectedOption}
          control={
            <Radio
              disableRipple
              icon={
                <SmallCheckedboxIcon style={styles.transparentRadioButton} />
              }
              checkedIcon={
                <SmallCheckedboxIcon style={styles.radioCheckButton} />
              }
              {...rest}
            />
          }
          label={label}
        />
      </FormGroup>
      {error && (
        <FormHelperText style={styles.errResponseText}>
          <InformationIcon />
          This field is required
        </FormHelperText>
      )}
    </div>
  );
};

export default CustomRadioInput;
