import { useCallback } from 'react';

const hasJsonError = (value) => {
  try {
    JSON.parse(value);
    return false;
  } catch {
    return true;
  }
};

export const getSyntaxError = (value) =>
  (value.length === 0 && 'This field is required') ||
  (hasJsonError(value) && 'Invalid JSON');

export const stringifyJson = (value) => JSON.stringify(value, null, 2);

export const formatJson = (value) => stringifyJson(JSON.parse(value));

export const useChangeHandler = (setter) =>
  useCallback(
    ({ target: { name, value } }) =>
      setter((prev) => ({ ...prev, [name]: value })),
    [setter]
  );

export const useHandleSaveJson = (name, value) => () => {
  const element = document.createElement('a');
  const file = new Blob([value], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = `${name}.json`;
  document.body.appendChild(element);
  element.click();
};

export const useHandleCopy = (value) => () =>
  navigator.clipboard.writeText(value);
