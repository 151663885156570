import { Stack } from '@mui/material';
import { updateLastOpenedConsent } from 'ihp-bloom-redux/features/consent/consentSlice';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { mapTaskRequiredStatus } from 'utils/tasks';
import { Main } from './Main';
import { Card } from './styles';

export const ConsentItem = ({
  consent,
  setConsent,
  onOpenSignedConsent,
  handlePastConsent,
  tab,
}) => {
  const {
    title,
    description,
    consentStatus,
    consentVersion,
    consentVersionDate,
    status,
    isOverdue,
    remaining,
    required,
    ...rest
  } = consent;
  const dispatch = useDispatch();

  const isSigned = consentStatus === 'completed';
  const isRejected = consentStatus === 'rejected';
  const isArchived = status === 'archived';
  const isExpired = status === 'expired';
  const isCompleted = status === 'completed';
  const showRemainingLabel = !isOverdue && !isCompleted;
  const isTabActionable = tab === 'ACTIONABLE';
  const isMandatory = mapTaskRequiredStatus(required);

  const handleNavigateToConsentDetails = () => {
    onOpenSignedConsent(consentVersion);
    dispatch(updateLastOpenedConsent(consent));
  };

  const handleSignConsent = () => {
    setConsent(consent);
    dispatch(updateLastOpenedConsent(consent));
  };

  return (
    <Card showBorder={isOverdue || isTabActionable}>
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        flex={1}
        alignItems='stretch'
      >
        <Main
          handlePastConsent={handlePastConsent}
          title={title}
          description={description}
          isSigned={isSigned}
          isMandatory={isMandatory}
          isArchived={isArchived}
          isRejected={isRejected}
          isExpired={isExpired}
          consentVersion={consentVersion}
          consentVersionDate={consentVersionDate}
          showRemainingLabel={showRemainingLabel}
          remaining={remaining?.label}
          handleNavigateToConsentDetail={handleNavigateToConsentDetails}
          handleSignConsent={handleSignConsent}
          tab={tab}
          {...rest}
        />
      </Stack>
    </Card>
  );
};
