import React from 'react';

import { ReactComponent as DataBadge } from 'images/profile/achievements/data.svg';
import { ReactComponent as DataFilledBadge } from 'images/profile/achievements/data-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
  MyPoints,
} from '../styles.mobile.js';

export const MobileSyncDataCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#F8EFD6' : '#ffffff'}>
      {!inProgress ? <DataBadge /> : <DataFilledBadge />}
      <MobileCardInfoBox>
        <Title>
          <MyPoints>1 &nbsp;</MyPoints> / 3 Health records shared
        </Title>
        <StyledSlider
          value={80}
          isCompleted={isCompleted}
          backgroundColor='#F2C94C'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Health advocate'
            isCompleted={isCompleted}
          />
          <StyledChip label='$20' isCompleted={isCompleted} />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
