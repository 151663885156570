import { forwardRef, useState } from 'react';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PickersModalDialog from '@mui/lab/internal/pickers/PickersModalDialog';

import { StaticDatePicker } from './StaticDatePicker';

export const ModalDatePicker = forwardRef(
  (
    {
      customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      renderInput,
      disabled,
      value,
      onChange,
      defaultValue = null,
      minDate,
      maxDate,
      ...rest
    },
    ref
  ) => {
    const [tempValue, setTempValue] = useState(defaultValue);

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
      if (!disabled) {
        setTempValue(value);
        setOpen(true);
      }
    };
    const handleClose = () => setOpen(false);

    return (
      <>
        <MobileDatePicker
          onChange={onChange}
          value={value}
          open={false}
          disabled={disabled}
          onOpen={handleOpen}
          renderInput={renderInput}
          ref={ref}
          {...rest}
        />
        <PickersModalDialog
          open={open}
          onAccept={() => {
            onChange(tempValue);
            setOpen(false);
          }}
          onSetToday={() => {
            onChange(new Date());
            setOpen(false);
          }}
          onDismiss={handleClose}
          onClear={handleClose}
        >
          <StaticDatePicker
            customWeekdays={customWeekdays}
            value={tempValue}
            onChange={(newValue) => setTempValue(newValue)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </PickersModalDialog>
      </>
    );
  }
);
