import { useIdleTimer } from 'react-idle-timer';
import { useLogout } from 'utils/useLogout';
import { useAuth0 } from '@auth0/auth0-react';

import { LOGOUT_TIME_IN_MINUTES } from 'utils/constants';
import { persistor } from 'App';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { logout } from 'ihp-bloom-redux/features/authentication/authenticationSlice';

export const logoutChannel = new BroadcastChannel('logout');

const logoutAllTabsEventListener = (dispatch) => {
  logoutChannel.onmessage = (event) => {
    console.log('Logout channel: ', event);
    sessionStorage.clear();
    localStorage.clear();
    persistor
      .purge()
      .then(() => {
        logoutChannel.close();
        window.location.href = `${window.location.origin}/onboarding/welcome-to-study`;
      })
      .catch((err) => console.log('state purge error: ', err));
  };
};

function AutoLogoutWrapper({ children }) {
  const dispatch = useDispatch();
  const logout = useLogout();
  const { isAuthenticated } = useAuth0();
  logoutAllTabsEventListener(dispatch);
  const onIdle = () => {
    if (isAuthenticated) {
      logout(undefined, undefined, false);
    }
  };
  useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * LOGOUT_TIME_IN_MINUTES,
    crossTab: true, // make sure to detect inactivity if application is open in multiple tabs
  });
  return <>{children}</>;
}

export default AutoLogoutWrapper;
