import React from 'react';
import LoginWithEmailMobile from './email.mobile';
import { Grid } from '@mui/material';
import { FormContainer } from 'components/Login';
import { useTheme } from '@mui/material/styles';
import LeftContainer from 'components/Onboarding/LeftContainer';

import getStyles from './styles/email.styles';
import { isMobile } from 'utils/isMobile';

function LoginWithGoogle() {
  const theme = useTheme();
  const styles = getStyles(theme);

  if (isMobile()) {
    return <LoginWithEmailMobile />;
  }

  return (
    <Grid container sx={styles.container}>
      <LeftContainer />
      <Grid item xl={6} sx={styles.rightContainer}>
        <FormContainer>
          {/* <Box sx={styles.formContainer} component='div'>
            <FormLabel>Enter your email address</FormLabel>
          </Box>
          <Box sx={styles.formContainer} component='div'>
            <TextInput fullWidth={true} placeholder='Email' />
            <EmailIcon style={styles.icon} />
          </Box>
          <Box sx={styles.formContainer} component='div'>
            <Button
              fullWidth={true}
              onClick={navigateToPersonalInformation}
            >
              Continue
            </Button>
          </Box> */}
        </FormContainer>
      </Grid>
    </Grid>
  );
}
export default LoginWithGoogle;
