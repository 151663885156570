import { createSlice } from '@reduxjs/toolkit';

import getNormalizedActions from '../utils/normalizedActions';

const initialState = {
  ids: [
    'phasellus.dolor@yahoo.com',
    'phasellus.dapibus@yahoo.edu',
    'magna@outlook.edu',
    'turpis.egestas@google.net',
    'et@aol.edu'
  ],
  entities: {
    'phasellus.dolor@yahoo.com': {
      id: 'phasellus.dolor@yahoo.com',
      firstName: 'Sean',
      lastName: 'Joseph',
      phone: '+1 (662) 501-1472',
      role: 'Admin',
      study: '001'
    },
    'phasellus.dapibus@yahoo.edu': {
      id: 'phasellus.dapibus@yahoo.edu',
      firstName: 'Irene',
      lastName: 'Moore',
      phone: '+1 (679) 390-9937',
      role: 'Admin',
      study: '002'
    },
    'magna@outlook.edu': {
      id: 'magna@outlook.edu',
      firstName: 'Donovan',
      lastName: 'Mcgowan',
      phone: '+1 (966) 835-8673',
      role: 'Super Admin',
      study: ''
    },
    'turpis.egestas@google.net': {
      id: 'turpis.egestas@google.net',
      firstName: 'Imogene',
      lastName: 'Peterson',
      phone: '+1 (668) 630-1048',
      role: 'Super Admin',
      study: ''
    },
    'et@aol.edu': {
      id: 'et@aol.edu',
      firstName: 'Holly',
      lastName: 'Patrick',
      phone: '+1 (609) 665-2810',
      role: 'Test Users',
      study: '003'
    }
  },
};

const normalizedActions = getNormalizedActions();
const slice = createSlice({
  name: 'allUsers',
  initialState,
  reducers: {
    addUser: normalizedActions.addAction,
    updateUser: normalizedActions.updateAction,
    removeUser: normalizedActions.removeAction,
  },
});

export const { addUser, updateUser, removeUser } = slice.actions;

export const selectUserIds = (query) => (state) => {
  const { ids, entities } = state[slice.name];

  const filterByTerm = term => {
    const lowerCaseTerm = term.toLowerCase();
    return (id) => {
      const { firstName, lastName } = entities[id];
      return `${firstName} ${lastName}`.toLowerCase().includes(lowerCaseTerm)
        || id.toLowerCase().includes(lowerCaseTerm);
    }
  };

  const filterByChunk = ({ page, count }) => {
    const min = (page - 1) * count;
    const max = page * count;
    return (_, index) => min <= index && index < max;
  };

  const sortByLastName = (lhsId, rhsId) =>
    entities[lhsId].lastName.localeCompare(entities[rhsId].lastName);

  const filters = [];
  if (query && query.term) filters.push(filterByTerm(query.term));
  if (query && query.chunk) filters.push(filterByChunk(query.chunk));

  return (filters.length !== 0
    ? filters.reduce((ids, func) => ids.filter(func), ids)
    : ids).sort(sortByLastName);
}
export const selectUserById = (id) => (state) =>
  state[slice.name].entities[id];
export const selectUsersByIds = (ids) => (state) =>
  ids.map(id => state[slice.name].entities[id]);
export const selectHasId = (id) => (state) =>
  Boolean(state[slice.name].entities[id]);
export const selectUserCount = () => (state) => state[slice.name].ids.length;

export default slice;
