import React from 'react';
import Button from 'ihp-components/components/v2/Button';
import {
  VerifyEmailWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
  Footer,
} from './styles';
import { ReactComponent as Completed } from 'images/av-logo-vertical.svg';
import { useAuth0 } from '@auth0/auth0-react';

function WithdrawnFromStudy() {
  document.title = 'Single Ventricle SOURCE withdrawn from study';

  const { logout } = useAuth0();

  const onContinue = () => {
    logout({ localOnly: true });
    window.location.href = '/auth0-callback';
  };

  return (
    <VerifyEmailWrapper>
      <Container>
        <Row>
          <Completed width={200} height={198} />
        </Row>
        <Row>
          <PageTitle variant='h4'>
            You have successfully withdrawn your participation from the SV Source
            Study.
          </PageTitle>
        </Row>
        <Row>
          <HelperText variant='pl3'>
            You can re-enroll in the study at any time and start earning
            rewards.
          </HelperText>
        </Row>
      </Container>

      <Footer>
        <Button onClick={onContinue} fullWidth>
          Re-enroll
        </Button>
      </Footer>
    </VerifyEmailWrapper>
  );
}

export default WithdrawnFromStudy;
