import { Box } from '@mui/system';
import { ReactComponent as CoinsIcon } from 'images/payment/coinsIcon.svg';
import { ReactComponent as GreenCoinsIcon } from 'images/payment/greenCoins.svg';
import {
  LeftProgress,
  RightProgress,
  MainTextContainer,
  PointsContainer,
  ClaimButton,
  NextPayoutMobileWrapper,
  MobileClaimButton,
} from './nextPayout.styles';
import { ChevronRight } from '@mui/icons-material';
import { Typography } from '@mui/material';

export const NextPayoutMobile = ({
  availablePoints,
  remainingPoints,
  progress,
  isComplete,
  claimReward,
  readOnly,
  thresholdMinAmount, // min amount
}) => {
  const isEmpty = availablePoints === 0;
  const colorComplete = isComplete ? { background: '#48AE34' } : {};

  // const message =
  //   isComplete && !readOnly
  //     ? 'Payment ready'
  //     : `${availablePoints} points available`;

  let message = '';
  let pointsMessage = '';
  if (availablePoints < thresholdMinAmount) {
    message = `${availablePoints} unredeemed points`;
    pointsMessage = `${
      thresholdMinAmount - availablePoints
    } points to your next reward`;
  } else if (availablePoints >= thresholdMinAmount) {
    message = 'Reward ready to be redeemed';
    pointsMessage = `${availablePoints} points available`;
  }

  // const pointsMessage =
  //   isComplete && !readOnly
  //     ? `${availablePoints} points available`
  //     : `${remainingPoints} points to your next reward`;

  const leftProgressRadius = isComplete
    ? { borderRadius: '8px' }
    : { borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' };

  const leftBackground = isEmpty
    ? { background: '#005587' }
    : { background: '#085685' };
  const width = isEmpty ? 100 : progress;
  const showRedeem = availablePoints >= thresholdMinAmount;

  return (
    <NextPayoutMobileWrapper>
      <div>
        <GreenCoinsIcon />
      </div>
      <div>
        <Typography variant='pb1' color='primary.white'>
          {message}
        </Typography>
        <br />
        <Typography variant='pl5' color='primary.white'>
          {pointsMessage}
        </Typography>
      </div>
      {showRedeem && !readOnly && (
        <MobileClaimButton onClick={claimReward}>
          Redeem
          <ChevronRight sx={{ marginTop: '-2px' }} />
        </MobileClaimButton>
      )}
    </NextPayoutMobileWrapper>
  );
};
