import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const profileAttributesApiSlice = createApi({
  reducerPath: 'profileAttributesApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['ProfileAttributes'],
  endpoints: (build) => ({
    getAllPersonAttributes: build.query({
      query: ({ personId }) => ({
        url: `/people/${personId}?include=attributes`,
        method: 'GET',
      }),
      providesTags: ['ProfileAttributes'],
    }),
    editPersonAttributes: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/app/people/${personId}/attributes`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ProfileAttributes'],
    }),
    submitPersonAttributes: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/people/${personId}/attributes`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ProfileAttributes'],
    }),
    deletePersonAttributes: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/people/${personId}/attributes`,
        method: 'DELETE',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ProfileAttributes'],
    }),
  }),
});

export const {
  useGetAllPersonAttributesQuery,
  useEditPersonAttributesMutation,
  useSubmitPersonAttributesMutation,
  useDeletePersonAttributesMutation,
} = profileAttributesApiSlice;
