import { Box, MenuItem, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { Input } from '../../../../ihp-components/components/v2/Input';

import { FilledButton } from '../../../components/Buttons';
import OnboardingLayout from '../components/Layout';
import { Text, Title } from '../components/Text';

import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
} from 'constants/global';
import { validateDate } from 'utils/formValidation';

import { withPersonAttributes } from 'hocs/withPersonAttributes';
import { convertDateStringToUTC, formatDateToDB } from 'utils/date';
import getRoute from 'utils/navigator';
import {
  Body,
  DropDownIcon,
  Footer,
  FormContainer,
  Row,
  getStyles,
} from './styles';

import { ReactComponent as AvLogo } from 'images/svs-logo.svg';
import { pronouns } from './constants';

const initialState = {
  first_name: '',
  last_name: '',
  middle_name: '',
  pronouns: '',
  date_of_birth: '',
};

const getPayload = ({ data }) => {
  let payload = [
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'first_name',
          value: data.first_name,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'last_name',
          value: data.last_name,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'date_of_birth',
          value: formatDateToDB(data.date_of_birth),
        },
      },
    },
  ];
  if (data?.middle_name) {
    payload = [
      ...payload,
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'middle_name',
            value: data.middle_name,
          },
        },
      },
    ];
  }

  if (data?.pronouns) {
    payload = [
      ...payload,
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'pronouns',
            value: data.pronouns,
          },
        },
      },
    ];
  }

  return payload;
};

function ParticipantInformation({ personAttributes }) {
  document.title = 'Single Ventricle SOURCE Participant Information';
  const styles = getStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { activeAccountProfile } = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);

  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();
  const [updatePersonStudyArm, { isLoading: isUpdatingStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const isLoading = isEditingPersonAttributes || isUpdatingStudyArm;

  const researchEnrollmentDiagnosedProfileStatus =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_DIAGNOSED_PROFILE_SUBMITTED
    ];

  console.log(researchEnrollmentDiagnosedProfileStatus);

  const subjectPrimaryPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;

  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;

  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;

  const personStudyArmPayload = (personStudyArmId) => {
    return {
      data: {
        type: 'person-study-arms',
        id: `${personStudyArmId}`,
        attributes: {
          study_arm_status_id: researchEnrollmentDiagnosedProfileStatus?.id,
        },
      },
    };
  };

  const onSubmit = async (data) => {
    try {
      // these attributes are for subjectPrimaryPerson
      const personAttributesResponse = await editPersonAttributes({
        personId: subjectPrimaryPersonId,
        payload: getPayload({ data }),
      }).unwrap();
      const accountPersonStudyArmRes = await updatePersonStudyArm({
        personStudyArmId: accountPersonStudyArmId,
        payload: personStudyArmPayload(accountPersonStudyArmId),
      }).unwrap();
      const primarypersonStudyArmRes = await updatePersonStudyArm({
        personStudyArmId: subjectPrimaryPersonStudyArmId,
        payload: personStudyArmPayload(subjectPrimaryPersonStudyArmId),
      }).unwrap();
      console.log({
        personAttributesResponse,
        accountPersonStudyArmRes,
        primarypersonStudyArmRes,
      });
      const newUserState = {
        ...user,
        activeAccountProfile: {
          ...user.activeAccountProfile,
          attributes: {
            ...user.activeAccountProfile.attributes,
            profile: {
              ...user.activeAccountProfile.attributes.profile,
              study_arm_status_id: researchEnrollmentDiagnosedProfileStatus?.id,
              study_arm_status_title:
                researchEnrollmentDiagnosedProfileStatus?.title,
              first_name: data.first_name, // subjects first name
              last_name: data.last_name, // subjects last name
            },
          },
        },
      };
      console.log(newUserState);
      dispatch(setValues(newUserState));
      navigate(getRoute(newUserState));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Pre-populate person attributes if server has data
   */

  Object.keys(personAttributes || {})?.forEach((key) => {
    if (initialState[key] === '' && personAttributes[key]) {
      initialState[key] = personAttributes[key];
    }
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: initialState,
  });

  const goBack = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout maxWidth='375px' backHandler={goBack}>
      <Row>
        <AvLogo />
      </Row>
      <Title>Who is the person being enrolled?</Title>
      <Text marginTop={'5px'} marginBottom={'30px'}>
        Please enter the information of the adult dependent.
      </Text>
      <Body>
        <Stack mt={4}>
          <FormContainer>
            <Box component='div'>
              <Controller
                name='first_name'
                control={control}
                rules={{
                  required: 'This field is required.',
                }}
                haserrors={errors.first_name}
                render={({ field }) => (
                  <Input
                    name='first_name'
                    label='First Name'
                    value={''}
                    fullWidth={true}
                    placeholder='Enter first name'
                    error={!!errors.first_name}
                    errorLabel={errors.first_name?.message}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='middle_name'
                control={control}
                rules={{
                  required: false,
                }}
                haserrors={errors.middle_name}
                render={({ field }) => (
                  <Input
                    name='middle_name'
                    label='Middle Name (Optional)'
                    error={errors.middle_name}
                    errorLabel={errors?.middle_name?.message}
                    value={''}
                    fullWidth={true}
                    placeholder='Enter middle name'
                    {...field}
                  />
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='last_name'
                control={control}
                rules={{
                  required: 'This field is required.',
                }}
                haserrors={errors.last_name}
                render={({ field }) => (
                  <Input
                    name='last_name'
                    label='Last Name'
                    value={''}
                    fullWidth={true}
                    placeholder='Enter last name'
                    error={errors.last_name}
                    errorLabel={errors?.last_name?.message}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='pronouns'
                control={control}
                render={({ field }) => (
                  <Input
                    type='select'
                    label='Select pronouns'
                    placeholder='Select pronouns'
                    fieldLabel='Pronouns'
                    error={errors.pronouns}
                    disabled={!pronouns?.length}
                    IconComponent={(props) => <DropDownIcon {...props} />}
                    fullWidth
                    {...field}
                  >
                    {pronouns?.map((p) => (
                      <MenuItem key={p} value={p}>
                        {p}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>
            <Box component='div'>
              <Controller
                name='date_of_birth'
                control={control}
                rules={{
                  required: true,
                  validate: validateDate,
                }}
                render={({ field }) => (
                  <Input
                    name='date_of_birth'
                    type='datePicker'
                    disableFuture
                    label='Date of Birth'
                    value={''}
                    fullWidth={true}
                    placeholder='Select date'
                    error={errors.date_of_birth}
                    errorLabel={
                      errors?.date_of_birth?.message || 'This field is required'
                    }
                    {...field}
                  />
                )}
              />
            </Box>
          </FormContainer>
        </Stack>
      </Body>
      <Footer>
        <FilledButton
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
          loading={isLoading}
        >
          Save & continue
        </FilledButton>
      </Footer>
    </OnboardingLayout>
  );
}

export default withPersonAttributes(ParticipantInformation);
