import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { FilledButton } from 'ihp-components/components/Buttons';
import { isMobile } from '../../../utils/isMobile';

export const getStyles = (
  theme,
  task,
  isTaskGroup,
  isChild,
  isLast,
  isOverdue
) => {
  let color = theme.palette.primary.darkGray100;
  let backgroundColor = '#FFFFFF';
  let width = '100%';
  let margin = isMobile() ? '10px' : '10px 0';
  let flexDirection = isMobile() ? 'column' : 'row';
  let borderRadius;

  if (task?.isDueTomorrow) {
    color = theme.palette.primary.darkGray100;
  }

  if (task?.displayStatus === 'past') {
    backgroundColor = '#FFFFFF';
    color = '#2B3A41';
  }

  if (isTaskGroup) {
    borderRadius = '10px 10px 0 0';
    margin = isMobile() ? '0 10px 0 10px' : '10px -1px -1px';
  }
  if (isChild) {
    borderRadius = '0';
    margin = isMobile() ? '0 10px 0 10px' : '-1px';
  }
  if (isLast) {
    borderRadius = '0 0 10px 10px';
    margin = isMobile() ? margin : '-1px -1px 10px -1px';
  }

  let border = 'none';
  if (isOverdue && task?.displayStatus !== 'past') {
    backgroundColor = theme.palette.primary.magenta5;
    border = `1px solid ${theme.palette.primary.magenta70}`;
  }

  return {
    [theme.breakpoints.up('sm')]: { margin },
    boxShadow: '0px 0px 30px 0px #0054870D',
    display: 'flex',
    justifyContent: 'space-between',
    border,
    overflow: 'auto',
    backgroundColor,
    color,
    width,
    margin,
    flexDirection,
    borderRadius,
  };
};

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '20px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '145%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '145%',
  },
}));

export const Link = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: '10px 16px',
  justifyContent: 'center',
  gap: '12px',
  cursor: 'pointer',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '140%',
  letterSpacing: '0.26px',
  textAlight: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const ViewAllButton = styled(FilledButton)(({ theme }) => ({
  display: 'none',
  padding: '15px 25px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '135%',
  letterSpacing: '0.3px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
  },
}));

export const Icon = {
  fontSize: '14px'
};