import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '../Switch';
import {
  StyledFormControl,
  StyledEndInputAdornment,
  StyledInputStartAdornment,
  StyledInputBase,
  StyledFormLabel,
} from './TextField.styles';
import { ErrorWrapper } from './ErrorWrapper';

export const TextField = forwardRef(
  (
    {
      id,
      error,
      errorLabel,
      size,
      fullWidth,
      color,
      endIcon,
      startIcon,
      label,
      withSwitch,
      onSwitchChange,
      options,
      selected,
      helperText,
      helperTextPlacement,
      ...rest
    },
    ref
  ) => (
    <StyledFormControl fullWidth={fullWidth} size={size}>
      <ErrorWrapper error={error} errorLabel={errorLabel}>
        {helperText && helperTextPlacement === 'top' && (
          <StyledFormLabel>{helperText}</StyledFormLabel>
        )}
        {label && <StyledFormLabel>{label}</StyledFormLabel>}
        <StyledInputBase
          ref={rest.inputRef ?? ref}
          id={id}
          aria-describedby={`${id}-helper-text`}
          size={size}
          fullWidth={fullWidth}
          endIcon={endIcon}
          startIcon={startIcon}
          error={error}
          {...(withSwitch
            ? {
                endAdornment: (
                  <StyledEndInputAdornment position='end' size={size}>
                    <Switch
                      size='small'
                      options={options}
                      selected={selected}
                      onChange={onSwitchChange}
                    />
                  </StyledEndInputAdornment>
                ),
              }
            : {
                endAdornment: endIcon && (
                  <StyledEndInputAdornment position='end' size={size}>
                    {endIcon}
                  </StyledEndInputAdornment>
                ),
              })}
          startAdornment={
            startIcon && (
              <StyledInputStartAdornment position='start' size={size}>
                {startIcon}
              </StyledInputStartAdornment>
            )
          }
          {...rest}
        />
        {helperText && helperTextPlacement === 'bottom' && (
          <StyledFormLabel id={`${id}-helper-text`}>
            {helperText}
          </StyledFormLabel>
        )}
      </ErrorWrapper>
    </StyledFormControl>
  )
);

TextField.defaultProps = {
  size: 'md',
  fullWidth: false,
  helperTextPlacement: 'bottom'
};

TextField.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  errorLabel: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  label: PropTypes.node,
  options: PropTypes.arrayOf({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onSwitchChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  helperText: PropTypes.string,
  helperTextPlacement: PropTypes.string
};
