import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, InputLabel, Typography, useTheme } from '@mui/material';

import { isImgValidToRender, formatBytes, isImage } from './utils';
import getStyles, { StyledFormHelperText } from './ImageUpload.styles';
import { ReactComponent as UploadIcon } from 'images/components/upload.svg';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';
import { File } from './File';

const allowedImageFileTypes = ['png', 'jpeg'];
const allowedFileTypes = [
  ...allowedImageFileTypes,
  'gif',
  'svg+xml',
  'webp',
  'pdf',
  'xlx',
  'xlsx',
  'ods',
  'ppt',
  'pptx',
  'txt',
];

export const MultiFileUpload = forwardRef(
  (
    {
      label,
      fileWidth,
      fileHeight,
      maxFileSizeInBytes,
      onFileUpload,
      acceptOnlyImages,
      ...rest
    },
    ref
  ) => {
    const [files, setFiles] = useState([]);
    const [validDimensions, setValidDimensions] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const theme = useTheme();
    const styles = getStyles(theme, error);

    const addNewFile = (newFile) => {
      for (let file of newFile) {
        if (file.size <= maxFileSizeInBytes) {
          return { file };
        }
      }
      return { ...files };
    };

    const isValidFileType = (file) => {
      if (!file) return;
      let flag = true;
      let fileExtension = file.type.split('/').pop().toLowerCase();
      if (acceptOnlyImages) {
        if (allowedImageFileTypes.indexOf(fileExtension) < 0) {
          return false;
        }
      }
      if (allowedFileTypes.indexOf(fileExtension) < 0) {
        flag = false;
      }
      return flag;
    };

    const handleNewFileUpload = (e) => {
      const { files: newFile } = e.target;
      if (newFile.length) {
        let updatedFiles = addNewFile(newFile);
        // Validate file size based on provided limit
        if (!updatedFiles.file) {
          setError(true);
          setErrorMsg(
            `Invalid file size. The file size should not be greater than ${formatBytes(
              maxFileSizeInBytes
            )}`
          );
          return;
        }
        // Check for valid file type
        if (isValidFileType(updatedFiles.file)) {
          const filesCopy = [...files, updatedFiles];
          setError(false);
          setErrorMsg('');
          setFiles(filesCopy);
          onFileUpload(updatedFiles);
          if (isImage(updatedFiles.file)) {
            checkImageDimensions(updatedFiles.file, filesCopy);
          } else {
            checkFileSize(updatedFiles.file, filesCopy);
          }
        } else {
          setValidDimensions(false);
          setError(true);
          const supported = acceptOnlyImages
            ? allowedImageFileTypes.join(', ')
            : allowedFileTypes.join(', ');
          setErrorMsg(`Invalid file type. Supported types are ${supported}`);
        }
      }
    };

    const checkFileSize = (file, localFiles) => {
      if (file.size > maxFileSizeInBytes) {
        setError(true);
        setErrorMsg(
          `Invalid file size. The file size should not be greater than ${formatBytes(
            maxFileSizeInBytes
          )}`
        );
        localFiles.pop();
        setFiles([...localFiles]);
      } else {
        setError(false);
        setErrorMsg('');
      }
    };

    const checkImageDimensions = (file, localFiles) => {
      if (!file) return;
      let img = new Image();
      img.onload = () => {
        if (img.width !== fileWidth && img.height !== fileHeight) {
          setValidDimensions(false);
          setError(true);
          setErrorMsg(
            `Invalid file size. The logo size should be ${fileWidth}x${fileHeight} px`
          );
          localFiles.pop();
          setFiles([...localFiles]);
        } else {
          setValidDimensions(true);
          setError(false);
          setErrorMsg('');
        }
      };
      img.src = URL.createObjectURL(file);
    };

    const handleRemoveFile = (index) => {
      files.splice(index, 1);
      setFiles([...files]);
    };

    return (
      <Box width='100%'>
        <InputLabel sx={styles.label}>{label}</InputLabel>
        <Box sx={styles.uploadContainer} component='div'>
          <UploadIcon style={styles.uploadIcon} />
          <Button component='label' sx={styles.uploadBtn}>
            {isImgValidToRender(files, validDimensions)
              ? files.file.name
              : 'Select files'}
            <input
              {...rest}
              type='file'
              hidden
              onChange={handleNewFileUpload}
              multiple={false}
              ref={ref}
            />
            <Typography variant='pl5' sx={styles.uploadInfoText}>
              {isImgValidToRender(files, validDimensions)
                ? formatBytes(files.file.size)
                : `the logo size should be ${fileWidth}x${fileHeight} px`}
            </Typography>
          </Button>
        </Box>
        <Box>
          {files.map((file, index) => (
            <File
              key={index}
              file={file.file}
              index={index}
              onRemove={handleRemoveFile}
            />
          ))}
        </Box>
        {error && (
          <StyledFormHelperText id={`helper-text`}>
            <InformationIcon />
            {errorMsg}
          </StyledFormHelperText>
        )}
      </Box>
    );
  }
);

MultiFileUpload.propTypes = {
  label: PropTypes.string,
  fileWidth: PropTypes.number.isRequired,
  fileHeight: PropTypes.number.isRequired,
  maxFileSizeInBytes: PropTypes.number.isRequired,
  onFileUpload: PropTypes.func.isRequired, // This function will return the file uploaded
};

/*  USAGE
const getImages = (files) => {
  console.log(files);
};

<MultiFileUpload
  label="Upload"
  fileWidth={100}
  fileHeight={50}
  maxFileSizeInBytes={500000}
  onFileUpload={getImages}
/>
*/
