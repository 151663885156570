import MaskedInput from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { Box, MenuItem, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import {
  useGetCurrentUserQuery,
  useGetTimezonesQuery,
  userApiSlice,
} from 'ihp-bloom-redux/features/user/userApiSlice';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { participantTaskApiSlice } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import { useValidatePhone } from 'pages/User/hooks';
import { getPayload } from './utils';
import Button from 'ihp-components/components/v2/Button';
import {
  CommunicationWrapper,
  AcmeLogo,
  Container,
  TitleContainer,
  PageTitle,
  Row,
  FieldContainer,
  ButtonContainer,
  getStyles,
} from './styles';
import { Input } from 'ihp-components/components/v2/Input';

function CommunicationPage({ setAdditionalAttr }) {
  document.title = 'Single Ventricle SOURCE';
  const styles = getStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [editPersonAttributes, { isLoading }] =
    useEditPersonAttributesMutation();
  const { user } = useSelector((state) => state);
  const { user: authUser } = useAuth0();

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      phone: '',
      timezone: '',
      iqvia_consent: false,
    },
  });

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const { timezones } = useGetTimezonesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      timezones: data || [],
    }),
  });

  const validatePhone = useValidatePhone();

  const onSubmit = (data) => {
    const payload = getPayload(
      user.language,
      data.phone,
      data.timezone,
      authUser
    );

    editPersonAttributes({
      personId: person.id,
      payload,
    })
      .then(() => {
        // invalidate "CurrentUser" tag so newly saved attributes came from api
        dispatch({
          type: `${userApiSlice.reducerPath}/invalidateTags`,
          payload: ['CurrentUser'],
        });
        // invalidate "ParticipantTasks" tag so new date fetched from api on onboarding consents page
        dispatch({
          type: `${participantTaskApiSlice.reducerPath}/invalidateTags`,
          payload: ['ParticipantTasks'],
        });
        navigate('/onboarding/participant-consents', {
          state: { backFrom: 'communication_page' },
        });
      })
      .catch(console.error);
  };

  return (
    <CommunicationWrapper>
      <Container>
        <Row>
          <AcmeLogo />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>Communication</PageTitle>
        </TitleContainer>
        <Box>
          <form>
            <FieldContainer>
              <Controller
                name='phone'
                control={control}
                rules={{
                  validate: validatePhone,
                  required: true,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='Preferred phone number'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        error={errors.phone}
                        errorLabel={
                          errors?.phone?.message || 'This field is required'
                        }
                        fullWidth
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name='timezone'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.timezone}
                render={({ field }) => (
                  <Input
                    error={errors.timezone}
                    errorLabel={errors?.timezone?.message}
                    type='select'
                    label='Select time zone'
                    fieldLabel='Timezone'
                    placeholder='Select time zone'
                    fullWidth
                    {...field}
                  >
                    {timezones?.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </FieldContainer>
            <FieldContainer>
              <Controller
                name='iqvia_consent'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.iqvia_consent}
                render={({ field }) => (
                  <Input
                    error={errors.iqvia_consent}
                    errorLabel={errors.iqvia_consent?.message}
                    type='checkbox'
                    label='By selecting the checkbox to the left, I consent to the processing of my personal data by the study team, and to be contacted by the study team at the telephone number(s) provided, via automated dialling and/or artificial or pre-recorded voice, for the purposes of keeping me updated with important study-related information, according to the terms of the Privacy Policy. My consent is not required as a condition of purchasing any property, goods, or services.'
                    checked={getValues('iqvia_consent')}
                    containerStyle={styles.checkboxContainer}
                    size='medium'
                    {...field}
                  />
                )}
              />
            </FieldContainer>
          </form>
        </Box>
        <ButtonContainer>
          <Button
            fullWidth
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
          >
            Save & continue
          </Button>
        </ButtonContainer>
      </Container>
    </CommunicationWrapper>
  );
}

CommunicationPage.propTypes = {
  setAdditionalAttr: PropTypes.func,
};

export default CommunicationPage;
