import { useState, useEffect } from 'react';
import { Box, Modal, useTheme, useMediaQuery } from '@mui/material';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import getStyles from './styles';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';
import { StatusLists } from './helper';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useCreatePersonStudyArmResearchOpportunityMutation,
  useUpdatePersonStudyArmResearchOpportunityMutation
} from 'ihp-bloom-redux/features/researchOpportunities/researchOpportunitiesApiSlice';

export const OpportunityStatusModel = ({
  isOpen,
  onClose,
  selectOpportunityStatus,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { person, activeAccountProfile } = useSelector((state) => state.user);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [updatePersonStudyArmResearchOpportunity, { isLoading }] =
    useUpdatePersonStudyArmResearchOpportunityMutation();

  const [createPersonStudyArmResearchOpportunity, { isLoading: isCreating }] =
    useCreatePersonStudyArmResearchOpportunityMutation();
  useEffect(() => {
    setSelectedStatus(selectOpportunityStatus?.status);
  }, [selectOpportunityStatus]);


  const personStudyArmId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_study_arm_id;

  const onSubmit = () => {
    const payload = {
      data: {
        type: 'person-study-arm-research-opportunities',
        attributes: {
          status: selectedStatus,
        },
      },
    };

    if (selectOpportunityStatus?.person_study_arm_research_opportunity) {
      payload.data.id = selectOpportunityStatus?.person_study_arm_research_opportunity?.toString();
      updatePersonStudyArmResearchOpportunity({
        personStudyArmId: personStudyArmId,
        personStudyArmResearchOpportunityId: selectOpportunityStatus?.person_study_arm_research_opportunity,
        payload,
      })
        .then((response) => {
          if (response?.error) {
            console.log('Error: ', response);
            return;
          }
          onClose(selectedStatus);
        })
        .catch(console.error);
    } else {
      payload.data.attributes.research_opportunity_id = +selectOpportunityStatus?.id;
      createPersonStudyArmResearchOpportunity({
        personStudyArmId, payload
      }).then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          return;
        }
        onClose(selectedStatus);
      })
        .catch(console.error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>External research status</Box>
          <Box sx={styles.iconWrapper}>
            <XIcon
              width='12px'
              height='18px'
              color='#888888'
              onClick={() => onClose()}
            />
          </Box>
        </Box>
        <Box sx={styles.radioInputWrapper}>
          <Input
            type='radioInputGroup'
            id='status'
            name='status'
            value={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            fullWidth
          >
            {StatusLists.map((item) => (
              <RadioInput
                hideRadioIcon={false}
                value={item.key}
                label={item.label}
                key={item.key}
              />
            ))}
          </Input>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Button
            sx={{ fontSize: '16px' }}
            variant='link'
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            sx={{ fontSize: '16px' }}
            fullWidth={isMobile}
            onClick={() => onSubmit()}
            loading={isLoading || isCreating}
            disabled={selectedStatus === null || isLoading || isCreating}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
