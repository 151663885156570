import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { isMobile } from "utils/isMobile";

export const PageWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: isMobile() ? '20px' : '50px',
    maxWidth: '1440px',
    margin: '0 auto',
});

export const Container = styled(Box)({
    width: '100%',
});

export const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.green,
    fontWeight: 800,
    '&:visited': {
        color: theme.palette.primary.green,
    },
    '&:hover': {
        color: theme.palette.primary.green,
    }
}));
