import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import { findStudyAttribute } from 'utils/study';
import {
  PageWrapper,
  Title,
  SectionSubTitle,
  Card,
  CardContent,
  PageTitle,
} from './components';

export default function Study() {
  document.title = 'Single Ventricle SOURCE My Study';
  const theme = useTheme();

  const { studyConfiguration } = useSelector((state) => state.configuration);

  const studyContent = findStudyAttribute(
    studyConfiguration,
    'full_study_description'
  );

  return (
    <PageWrapper sx={{ backgroundColor: theme.palette.primary.gray2 }}>
      <PageTitle>My study</PageTitle>
      <Card>
        <CardContent gap={5}>
          {studyContent.map((study) => (
            <Box key={study.section}>
              <Title>{study.title}</Title>
              <SectionSubTitle>{study.content}</SectionSubTitle>
            </Box>
          ))}
        </CardContent>
      </Card>
    </PageWrapper>
  );
}
