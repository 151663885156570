import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from 'utils/isMobile';

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const TopContainer = styled(Box)(({ theme }) => ({
  height: '90vh',
  display: 'flex',
  justifyContent: 'center',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

export const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '5px',
  borderRadius: '5px',
}));

export const CenterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: isMobile() ? '85%' : '70%',
}));

export const FlexedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
}));

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  lineHeight: '35px',
  fontSize: '24px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  alignContent: 'center',
  justifyContent: 'center',
  margin: '3px 0px 0px 20px',
}));

export const NextPrevNames = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  lineHeight: '20px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
}));

export const CategoryName = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray75,
  fontWeight: 600,
  lineHeight: '20px',
  fontSize: '14px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  alignContent: 'center',
}));

export const CategoryDate = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray75,
  fontWeight: 400,
  lineHeight: '18px',
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
}));

export const IconWrapper = styled(Box)({
  width: '40px',
  height: '40px',
  alignSelf: 'center',
});

export const desktopTitle = (theme) => ({
  display: 'flex',
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  lineHeight: '35px',
  fontSize: '24px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  alignContent: 'center',
  justifyContent: 'center',
  margin: '3px 0px 0px 20px',
});

export const mobileTitle = () => ({
  display: 'flex',
  color: '#444444',
  fontWeight: 600,
  lineHeight: '23px',
  fontSize: '16px',
  fontStyle: 'bold',
  fontFamily: 'Inter',
  alignContent: 'center',
  justifyContent: 'center',
  margin: '8px 0px 0px 17px',
});

export const title = (theme) =>
  isMobile() ? mobileTitle(theme) : desktopTitle(theme);
