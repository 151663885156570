import { useGetFamilyInvitations } from 'features/familyLinkage/hooks/useGetFamilyInvitations';
import { FAMILY_INVITATION_STATUS } from '../constants';
import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export function useGetFamilyPendingInvitations() {
  const { user } = useAuth0();
  const { data = [], ...rest } = useGetFamilyInvitations();

  const filteredData = useMemo(
    () =>
      data.filter(
        (i) =>
          i.inviter_email.toLowerCase() !== user.email.toLowerCase() &&
          i.status === FAMILY_INVITATION_STATUS.INVITED
      ),
    [data, user]
  );

  return {
    data: filteredData,
    ...rest,
  };
}
