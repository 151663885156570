import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  color: theme.palette.primary.gray50,
  border: `1px solid ${theme.palette.primary.gray10}`,
  borderRadius: '8px',
}));
