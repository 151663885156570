import { toHyperWalletTimeFormat } from 'utils/date';

export const createUser = async ({
  endpoint,
  programToken,
  credentials,
  user,
}) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${credentials}`);

  const { firstName, lastName, email, city, country, stateProvince } = user;
  const dateOfBirth = toHyperWalletTimeFormat(user.dob);
  const clientUserId = getClientUserId(email);
  const body = JSON.stringify({
    profileType: 'INDIVIDUAL',
    clientUserId,
    firstName,
    lastName,
    email,
    dateOfBirth,
    addressLine1: user.address,
    city,
    country,
    stateProvince,
    postalCode: user.zipCode,
    programToken,
  });

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body,
    redirect: 'follow',
  };

  let result = await fetch(endpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log('error', error));

  return result;
};

const getClientUserId = (email) => {
  return email.replace('@', '');
};

export const getAuthToken = async (userToken, credentials) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Basic ${credentials}`);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  const endpoint = `https://api.sandbox.hyperwallet.com/rest/v3/users/${userToken}/authentication-token`;
  return fetch(endpoint, requestOptions)
    .then((response) => response.text())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log('error', error));
};

export const getEncodedCredentials = (user, pass) => {
  return btoa(`${user}:${pass}`);
};

// return only data for easier testing while development
export const getTransferData = () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      transferMethodCountry: 'US',
      transferMethodCurrency: 'USD',
      type: 'VENMO_ACCOUNT',
      accountId: '5555555555',
    };
  }

  return {};
};

export const getTransferUrl = (trmObject, completionResult) => {
  let url;
  let redirect;
  if (process.env.NODE_ENV === 'development') {
    redirect = 'localhost:3000/profile/hyperwallet-callback';
    url = `http://${redirect}?result=${completionResult}&token=${trmObject.token}`;
  } else {
    redirect = 'bloom-dev.ihp.iqvia.com/profile/hyperwallet-callback';
    url = `https://${redirect}?result=${completionResult}&token=${trmObject.token}`;
  }
  return url;
};

export const getVerifyUrl = (verificationObject, completionResult) => {
  const token = verificationObject.token;
  const status = verificationObject.status;

  let redirect;
  let url;
  if (process.env.NODE_ENV === 'development') {
    redirect = 'localhost:3000/profile/hyperwallet-callback';
    url = `http://${redirect}?result=${completionResult}&token=${token}&status=${status}`;
  } else {
    redirect = 'bloom-dev.ihp.iqvia.com/profile/hyperwallet-callback';
    url = `http://${redirect}?result=${completionResult}&token=${token}&status=${status}`;
  }
  return url;
};
