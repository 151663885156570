import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme, fullScreen }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: fullScreen ? '100vh' : '50vh',
  width: fullScreen ? '100vw' : 'auto',
  position: fullScreen ? 'fixed' : 'static',
  backgroundColor: fullScreen ? theme.palette.primary.white : 'transparent',
  zIndex: fullScreen ? '1000' : '1',
  top: fullScreen ? '0' : 'unset',
  left: fullScreen ? '0' : 'unset',
}));

export const InfoMessage = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontWeight: 400,
  fontSize: '12px',
  marginTop: '10px',
  maxWidth: '300px',
  textAlign: 'center',
}));
