import { useEffect, useRef } from 'react';
import { isValidElement } from 'react';

import { useSelector, useDispatch } from 'ihp-bloom-redux/app/redux';
import { useGetAllPersonAttributesQuery } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import { Loader } from 'components/Loader';
import _ from 'lodash';

export const withPersonAttributes = (Component) => (props) => {
  const { person, personAttributes } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const timestampRef = useRef(Date.now()).current;
  const {
    attributes,
    isLoading: isLoadingPersonAttributes,
    isFetching,
    refetch: refetchPersonAttributes,
  } = useGetAllPersonAttributesQuery(
    {
      personId: props?.subjectPersonId ?? person?.id,
      sessionId: timestampRef, // passing this sessionId to disable cache so always fetch new data
    },
    {
      skip: !person?.id,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        attributes: data?.included?.reduce((result, item) => {
          return {
            ...result,
            [item['attributes']['attribute']]: item['attributes']['value'],
          };
        }, {}),
      }),
    }
  );

  useEffect(() => {
    if (
      (Object.keys(personAttributes || {}).length === 0 ||
        !_.isEqual(personAttributes, attributes)) &&
      attributes &&
      Object.keys(attributes).length > 0 &&
      props?.subjectPersonId === undefined
    ) {
      dispatch(setValues({ personAttributes: attributes }));
    }
  }, [dispatch, attributes, personAttributes]);

  const newProps = {
    ...props,
    personAttributes: attributes,
    isFetchingPersonAttributes: isFetching,
    refetchPersonAttributes,
  };

  if (isLoadingPersonAttributes) {
    return <Loader />;
  }

  const componentToRender = <Component {...newProps} />;
  return isValidElement(componentToRender) ? componentToRender : null;
};
