import React from 'react';
import { ButtonContainer } from './styles';

export const Footer = (props) => {
  const { children, bottomFixed, maxWidth, padding } = props;

  return (
    <ButtonContainer bottomFixed={bottomFixed} maxWidth={maxWidth} padding={padding}>
      {children}
    </ButtonContainer>
  );
};
