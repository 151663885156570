import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import { Box, styled } from '@mui/system';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { isMobile } from 'utils/isMobile';
import { Button } from '@mui/material';

export const StudyTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.primary.gray10,
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 70,
    height: 4,
    width: '100%',
    backgroundColor: theme.palette.primary.fuchsia75,
  },
}));

export const StudyTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  marginRight: theme.spacing(1),
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.primary.gray50,
  '&:hover': {
    color: theme.palette.primary.fuchsia50,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.fuchsia100,
    fontWeight: 600,
  },
}));

export const StudyTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`study-tabpanel-${index}`}
      aria-labelledby={`study-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

StudyTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const Title = styled('div')(({ theme }) => ({
  fontSize: isMobile() ? 18 : 24,
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  paddingTop: 30,
}));

export const AchievementsTitle = styled('div')(({ theme }) => {
  const color = isMobile()
    ? theme.palette.primary.gray100
    : theme.palette.primary.gray50;

  return {
    fontSize: isMobile() ? 16 : 18,
    color,
    fontWeight: 600,
    marginTop: 40,
  };
});

export const AchievementsContainer = styled(Box)(({ theme }) => {
  const gridAutoRows = isMobile() ? 151 : 160;
  return {
    marginTop: '20px',
    display: 'grid',
    color: theme.palette.primary.gray5,
    gridTemplateColumns: 'repeat(7, minmax(110px, 1fr))',
    gridAutoRows,
    [theme.breakpoints.between('sm', 'lg')]: {
      gridTemplateColumns: 'repeat(5, minmax(110px, 1fr))',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(3, minmax(110px, 1fr))',
    },
  };
});

export const DaysInfo = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: 14,
  color: theme.palette.primary.gray50,
  fontWeight: 600,
  lineHeight: '145%',
  marginTop: 20,
}));

export const PointsInfo = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: 14,
  color: theme.palette.primary.gray50,
  fontWeight: 400,
  lineHeight: '17px',
  marginTop: 12,
}));

export const LevelInfo = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: 14,
  lineHeight: '17px',
  color: theme.palette.primary.gray50,
  fontWeight: 700,
  marginTop: 12,
}));

export const StudyProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  marginTop: 12,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.primary.gray10,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.fuchsia75,
  },
}));

export const OverviewTitle = styled('div')(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  color: theme.palette.primary.gray50,
  fontSize: 16,
  fontWeight: 600,
  padding: '40px 0px 10px 0px',
}));

export const LeftStudyContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '50px',
  paddingRight: '50px',
  overflow: 'auto',
  height: '100%',
  marginRight: '20px',
}));

export const StudyContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
}));

export const OverviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  borderRadius: '10px',
}));

export const OverviewMainTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: 12,
  color: theme.palette.secondary.gray4,
  fontWeight: 400,
  padding: '20px 20px 0px 30px',
}));

export const TimelineContainer = styled(Box)(({ theme }) => ({
  width: '35%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%',
}));

export const ReadMoreButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.white,
  color: theme.palette.primary.fuchsia75,
  border: '1px solid',
  borderColor: theme.palette.primary.fuchsia75,
  borderRadius: 2,
  padding: '15px 15px 15px 15px',
  marginLeft: '30px',
  marginTop: '20px',
  width: '130px',
  height: '40px',
  fontWeight: 'bold',
  fontSize: '14px',
  textTransform: 'none',
  '&: hover': {
    background: theme.palette.primary.white,
  },
}));
