import {
  PaymentContentContainer,
  PaymentHistoryTitle,
  FlexedBox,
} from './styles';
import { PaymentList } from './PaymentList';
import { ReactComponent as FunnelIcon } from 'images/payment/funnel.svg';
import { CircularProgress, Typography } from '@mui/material';
import { isMobile } from 'utils/isMobile';
import Button from 'ihp-components/components/v2/Button';
import { Box } from '@mui/system';

export const PaymentHistory = (props) => {
  const { paymentsList, openFilter, isLoading } = props;

  return (
    <div>
      <FlexedBox
        justifyContent='space-between'
        padding={isMobile() ? '24px' : '0px'}
      >
        <PaymentHistoryTitle>Points history</PaymentHistoryTitle>
        <Button size='sm' variant='link' onClick={openFilter}>
          <FunnelIcon width='16px' height='16px' />
          <Typography variant='pb3' color='primary.gray100'>
            FILTERS
          </Typography>
        </Button>
      </FlexedBox>
      <PaymentContentContainer
        display={paymentsList.length !== 0 ? 'block' : 'grid'}
      >
        {isLoading && (
          <FlexedBox>
            <Box sx={{ minHeight: '50px' }}>
              <CircularProgress color='primary' />
            </Box>
          </FlexedBox>
        )}

        {!isLoading && <PaymentList list={paymentsList} />}
      </PaymentContentContainer>
    </div>
  );
};
