import { useSelector } from 'ihp-bloom-redux/app/redux';

export function useIsLar() {
  const {
    activeAccountProfile: {
      attributes: {
        profile: {
          account_person_study_arm_id: accountPersonStudyArmId,
          study_arm_role_name: studyArmRoleName = '',
          subject_primary_person_study_arm_id: subjectPersonStudyArmId,
        },
      },
    },
  } = useSelector((state) => state.user);

  if (
    !Number.isInteger(accountPersonStudyArmId) ||
    typeof studyArmRoleName !== 'string' ||
    !Number.isInteger(subjectPersonStudyArmId)
  ) {
    return {
      isLar: false,
    };
  }

  return {
    isLar:
      studyArmRoleName.toLowerCase().includes('lar') &&
      accountPersonStudyArmId !== subjectPersonStudyArmId,
  };
}
