import { Typography } from '@mui/material';
import { Container, DescriptionBox, StyledTooltip } from './Score.styles.js';
import { ReactComponent as CircleQuestionIcon } from 'images/redeem/circle-question.svg';

export const Score = ({
  label,
  description,
  showInfo = true,
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Typography variant='h1'>{children}</Typography>
      <DescriptionBox>
        <Typography
          variant='pl4'
          color='primary.gray75'
          sx={{ textAlign: 'center' }}
        >
          {label}
        </Typography>
        {showInfo && (
          <StyledTooltip title={description} arrow={true}>
            <CircleQuestionIcon />
          </StyledTooltip>
        )}
      </DescriptionBox>
    </Container>
  );
};
