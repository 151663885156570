import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const familyApiSlice = createApi({
  reducerPath: 'familyApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['FamilyInvitations'],
  endpoints: (build) => ({
    acceptFamilyInvitation: build.mutation({
      invalidatesTags: ['FamilyInvitations', 'FamilyMembers'],
      query: ({ familyInvitationId, personId }) => ({
        method: 'POST',
        url: `/people/${personId}/family-invitations/${familyInvitationId}/accept`,
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      }),
    }),
    declineFamilyInvitation: build.mutation({
      invalidatesTags: ['FamilyInvitations'],
      query: ({ familyInvitationId, personId }) => ({
        method: 'POST',
        url: `/people/${personId}/family-invitations/${familyInvitationId}/decline`,
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      }),
    }),
    getFamilyInvitations: build.query({
      providesTags: ['FamilyInvitations'],
      query: (personId) => ({
        method: 'GET',
        url: `/people/${personId}/family-invitations`,
      }),
    }),
    getFamilyMembers: build.query({
      providesTags: ['FamilyMembers'],
      query: (personId) => ({
        method: 'GET',
        url: `/people/${personId}/person-group-memberships`,
      }),
    }),
    sendFamilyInvitation: build.mutation({
      invalidatesTags: ['FamilyInvitations'],
      query: ({ payload, personId }) => ({
        body: JSON.stringify(payload),
        method: 'POST',
        url: `/people/${personId}/family-invitations`,
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
      }),
    }),
  }),
});

export const {
  useAcceptFamilyInvitationMutation,
  useDeclineFamilyInvitationMutation,
  useGetFamilyInvitationsQuery,
  useGetFamilyMembersQuery,
  useSendFamilyInvitationMutation,
  useUpdateFamilyInvitationMutation,
} = familyApiSlice;
