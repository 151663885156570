import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ActivityDetailsContainer,
  ActivityDetails,
  StyledCircleIcon,
} from './ActivityDetails.styles';
import { Typography } from '@mui/material';

//TODO: Move this component into ihp-components
export const Details = ({
  metaData,
  details,
  detailsDividerColor,
  selectedTaskCategory,
}) => {
  const isOverdue = details?.isOverdue;
  const isPast = details?.isPast;

  return (
    <ActivityDetailsContainer>
      <ActivityDetails>
        {metaData.map((item, index) =>
          item?.label ? (
            <React.Fragment key={`${item?.label}-${index}`}>
              <Typography noWrap variant='pn4'>{item.label}</Typography>
              <StyledCircleIcon color={detailsDividerColor} />
            </React.Fragment>
          ) : null
        )}
        {!isPast && isOverdue && (
          <>
            <Typography variant='pn3'>
              Overdue
            </Typography>
          </>
        )}
      </ActivityDetails>
    </ActivityDetailsContainer>
  );
};

Details.propTypes = {
  details: PropTypes.arrayOf(PropTypes.object).isRequired,
  detailsDividerColor: PropTypes.string,
};
