import { Box, styled } from '@mui/system';
import { FilledButton, OutlinedButton } from 'configUI/components/Buttons';
import { LinkButton } from 'components/Buttons';
import Button from 'ihp-components/components/v2/Button';

export const MainContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray20,
  minHeight: '100vh',
}));

export const ContentContainer = styled(Box)({
  marginLeft: '40px',
  marginRight: '40px',
  padding: '44px 0px',
});

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '25px',
  marginBottom: '30px',
});

export const EditorWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  '.rdw-option-wrapper, .rdw-dropdown-wrapper': {
    border: 0,
    '&:hover': {
      boxShadow: 'none',
    },
  },
  '.rdw-option-active': {
    background: theme.palette.primary.pattensBlue,
    boxShadow: 'none',
    border: 0,
  },
  '.rdw-editor-wrapper': {
    width: '100% !important',
    minHeight: '577px !important',
    backgroundColor: theme.palette.primary.white,
  },
  '.rdw-editor-main': {
    height: '480px !important',
  },
}));

export const FooterButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '30px 0px 40px 40px',
  borderTop: `1px solid ${theme.palette.primary.gray15}`,
}));

export const SaveButton = styled(FilledButton)({
  fontSize: '16px',
  fontWeight: 500,
});

export const CancelButton = styled(Button)(({ theme }) => ({
  minWidth: 134,
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '2%',
  color: theme.palette.primary.darkBlue100,
}));

export const ContinueButton = styled(OutlinedButton)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  marginLeft: '24px',
  height: '56px',
  width: '222px',
  color: theme.palette.primary.gray75,
  backgroundColor: `${theme.palette.primary.gray10} !important`,
  '&:hover': {
    color: theme.palette.primary.gray100,
    backgroundColor: `${theme.palette.primary.gray15} !important`,
    boxShadow: 'none !important',
  },
  '&:disabled': {
    color: `${theme.palette.primary.gray25} !important`,
    backgroundColor: theme.palette.primary.gray10,
  },
}));

export const getStyles = (theme) => ({
  wrapperClassName: {
    width: '800px',
    border: `1px solid ${theme.palette.primary.gray25}`,
    borderRadius: '8px',
  },
  toolbarClassName: {
    marginBottom: 0,
    border: 0,
    padding: '13px 8px 5px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  editorStyle: {
    background: theme.palette.primary.white,
    borderTop: `1px solid ${theme.palette.primary.pattensBlue}`,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    padding: '0 20px',
  },
});

export const MainContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '40px',
}));

export const EditorArea = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const PreviewArea = styled(Box)(({ theme }) => ({}));
