import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const getStyles = (theme) => ({
  icon: {
    fontSize: '14px',
  },
  educationIcon: {
    height: '45px',
    width: '45px',
    color: theme.palette.primary.white,
    background: '#FFC980',
    padding: '8px',
    borderRadius: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const LearnMoreButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.28px',
  fontSize: '14px',
  display: 'flex',
  padding: '13px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'end',
  gap: '12px',
  borderRadius: '8px',
  border: '1px solid ' + theme.palette.primary.lightGray100,
  background: theme.palette.primary.white,
  color: theme.palette.primary.darkBlue70,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
