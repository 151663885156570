import RegistryStatisticsOverview from '../../components/RegistryStatistics/RegistryStatisticsOverview';
import {
  CardContainer,
  HeaderBackButton,
  HeaderContainer,
  PageContainer,
  StyledOverviewHeader,
} from './styles';
import RegistryParticipantWorldChart from '../../components/RegistryStatistics/RegistryParticipantWorldChart';
import RegistryParticipantStatesChart from '../../components/RegistryStatistics/RegistryParticipantStatesChart';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../theme';
import { ReactComponent as ChevronLeftIcon } from 'images/shared/chevron-left-2.svg';
import RegistryParticipantAgeChart from '../../components/RegistryStatistics/RegistryParticipantAgeChart';
import DistributionOfGeneInheritanceChart from '../../components/RegistryStatistics/DistributionOfGeneInheritanceChart';
import DistributionOfFtdDisorderChart from '../../components/RegistryStatistics/DistributionOfFtdDisorderChart';

const RegistryStatistics = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderContainer>
        <HeaderBackButton onClick={() => navigate('/home')}>
          <ChevronLeftIcon
            style={{ marginRight: '10px' }}
            width='24px'
            height='24px'
            color={theme.palette.primary.darkGray50}
          />{' '}
          Overview
        </HeaderBackButton>
      </HeaderContainer>
      <PageContainer>
        <StyledOverviewHeader>Registry Statistics</StyledOverviewHeader>
        <CardContainer>
          <RegistryStatisticsOverview />
        </CardContainer>
        {/* <CardContainer>
          <RegistryParticipantWorldChart />
        </CardContainer> */}
        <CardContainer>
          <RegistryParticipantStatesChart />
        </CardContainer>
        <CardContainer>
          <RegistryParticipantAgeChart />
        </CardContainer>
        {/* <CardContainer>
          <DistributionOfFtdDisorderChart />
        </CardContainer> */}
        <CardContainer>
          <DistributionOfGeneInheritanceChart />
        </CardContainer>
      </PageContainer>
    </>
  );
};

export default RegistryStatistics;
