import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const taskApiSlice = createApi({
  reducerPath: 'taskApi',
  baseQuery: baseQuery('v1'),
  tagTypes: ['Tasks', 'PatientTasks', 'PatientPoints'],
  endpoints: (build) => ({
    getAllPatientTasks: build.mutation({
      query: () => ({
        url: `/patient-tasks`,
        method: 'GET',
      }),
      providesTags: ['PatientTasks'],
    }),
    fetchAllPatientTasks: build.query({
      query: (participantId) => {
        return {
          url: `/persons/${participantId}/participant-tasks`,
          method: 'GET',
        };
      },
      providesTags: ['ParticipantTasks'],
    }),
    getTasks: build.query({
      query: () => ({
        url: `/tasks`,
        method: 'GET',
      }),
      providesTags: ['Tasks'],
    }),
    getTasksByParam: build.query({
      query: (param) => ({
        url: `/tasks?find=${param}`,
        method: 'GET',
      }),
      providesTags: ['Tasks'],
    }),
    getTask: build.query({
      query: (id) => ({
        url: `/tasks/${id}`,
        method: 'GET',
      }),
    }),
    createTask: build.mutation({
      query: (createTaskData) => ({
        url: `/tasks`,
        method: 'POST',
        body: createTaskData,
      }),
      invalidatesTags: ['Tasks'],
    }),
    updateTask: build.mutation({
      query: ({ id, ...data }) => ({
        url: `/tasks/${id}`,
        method: 'PUT',
        body: data,
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['Tasks'],
    }),
    deleteTask: build.mutation({
      query: (id) => ({
        url: `/tasks/${id}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['Tasks'],
    }),
    getPatientTasks: build.query({
      query: (params) => ({
        url: '/patient-tasks',
        method: 'GET',
        params,
      }),
      providesTags: ['PatientTasks'],
    }),
    getPatientTask: build.query({
      query: (id) => ({
        url: `/patient-tasks/${id}`,
        method: 'GET',
      }),
    }),
    createPatientTask: build.mutation({
      query: (params) => ({
        url: '/patient-tasks',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['PatientTasks'],
    }),
    completePatientTask: build.mutation({
      query: (patientTaskData) => ({
        url: `/patient-tasks/${patientTaskData.patient_task_id}`,
        method: 'PUT',
        body: patientTaskData,
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['PatientTasks', 'PatientPoints'],
    }),
    getPatientPoints: build.query({
      query: (patientId) => ({
        url: `/tasks/${patientId}/points`,
        method: 'GET',
      }),
      providesTags: ['PatientPoints'],
    }),
    getParticipantGoalStats: build.query({
      query: (participantId) => ({
        url: `/persons/${participantId}/participant-goal-stats?include=goal`,
        method: 'GET',
      }),
      providesTags: ['PatientPoints'],
    }),
    getParticipantGoalStatsNotCached: build.query({
      // sessionId is dummy not used param which serves to prevent caching this call
      query: ({ participantId, sessionId }) => ({
        url: `/persons/${participantId}/participant-goal-stats?include=goal`,
        method: 'GET',
      }),
      providesTags: ['PatientPoints'],
    }),
    getParticipantGoal: build.query({
      query: (participantId) => ({
        url: `/persons/${participantId}/participant-goal?include=goal`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksByParamQuery,
  useGetTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useGetPatientTasksQuery,
  useGetPatientTaskQuery,
  useCreatePatientTaskMutation,
  useGetAllPatientTasksMutation,
  useCompletePatientTaskMutation,
  useGetPatientPointsQuery,
  useFetchAllPatientTasksQuery,
  useGetParticipantGoalStatsQuery,
  useGetParticipantGoalStatsNotCachedQuery,
  useGetParticipantGoalQuery,
} = taskApiSlice;
