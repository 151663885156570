export const getPayload = (language, phone, timezone, user) => {
  const mandatoryItems = [
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'languages',
          value: language || 'en-US',
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'phone',
          value: phone,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'timezone',
          value: timezone,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'first_name',
          value: user?.given_name || '',
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'last_name',
          value: user?.family_name || '',
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'email',
          value: user?.email || '',
        },
      },
    },
  ];

  if (phone == null || timezone == null) {
    return mandatoryItems;
  }

  return [
    ...mandatoryItems,
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'phone',
          value: phone,
        },
      },
    },
    {
      data: {
        type: 'person-attributes',
        attributes: {
          attribute: 'timezone',
          value: timezone,
        },
      },
    },
  ];
};
