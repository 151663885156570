import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';


export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  flex: '1 0 0',
  padding:'25px',
  maxHeight:'71px'
}));
export const HeaderBackButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '140%',
  cursor:'pointer',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const PageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '820px',
  margin: '40px auto',
  backgroundColor: theme.palette.primary.gray20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '24px',
  },
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  height:'100%',
  overflowY:'auto',
  gap:'14px'
}));

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  flex: '1 0 0',
  padding:'30px',
}));
export const StyledOverviewHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '145%',
}));
