import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormLabel
} from '@mui/material';

const sizeWidthMap = {
  large: '500px',
  medium: '400px',
  small: '300px',
};

export const StyledFormControl = styled(FormControl)(({ fullWidth, size }) => ({
  width: !fullWidth ? sizeWidthMap[size] : '100%',
  '.MuiIconButton-root': {
    marginRight: '8px'
  }
}));
