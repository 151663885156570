import { createSlice } from '@reduxjs/toolkit';
const idFormatter = (id) => String(id).padStart(3, '0');

const notifications = [
  {
    id: '001',
    title: 'Invitation to enroll',
    type: 'notification',
  },
  {
    id: '002',
    title: 'Welcome to the Bloom study',
    type: 'notification',
  },
  {
    id: '003',
    title: 'Study overview',
    type: 'notification',
  },
  {
    id: '004',
    title: 'Reminder to enroll',
    type: 'notification',
  },
  {
    id: '005',
    title: 'Schedule TeleVisit call',
    type: 'notification',
  },
  {
    id: '006',
    title: 'Reminder',
    type: 'notification',
  },
  {
    id: '007',
    title: 'Schedule TeleVisit call Follow up',
    type: 'notification',
  },
];

const initialState = {
  list: notifications,
};

const addNotificationTemplateReducer = (state, { payload }) => {
  const data = payload && payload.data ? payload.data : {};
  const id = idFormatter(state.list.length + 1);
  state.list.push({
    id,
    ...data,
  });
};

const updateNotificationTemplateReducer = (state, { payload }) => {
  const { id, ...rest } = payload.data;
  const targetIndex = state.list.findIndex((template) => template.id === id);
  if (targetIndex > -1) {
    state.list[targetIndex] = {
      ...state.list[targetIndex],
      ...rest,
    };
  }
};

const deleteNotificationTemplateReducer = (state, action) => {
  state.list = state.list.filter((item) => item.id !== action.payload.id);
}

const slice = createSlice({
  name: 'notificationTemplates',
  initialState,
  reducers: {
    addNotificationTemplate: addNotificationTemplateReducer,
    updateNotificationTemplate: updateNotificationTemplateReducer,
    deleteNotificationTemplate: deleteNotificationTemplateReducer
  },
});

export const { addNotificationTemplate, updateNotificationTemplate, deleteNotificationTemplate } = slice.actions;

export const selectNotificationTemplateById = id => state => state[slice.name].list.find(item => item.id === id) || {};

export default slice;
