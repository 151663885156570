import { Box, styled, Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.h2,
  color: theme.palette.secondary.gray4,
  fontFamily: 'Inter',
  fontWeight: 600,
  textAlign: 'center',
}));

export const Text = styled(Typography)(({ theme }) => ({
  ...theme.typography.pl3,
  color: theme.palette.primary.darkGray70,
  textAlign: 'center',
}));

export const Buttons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
}));

export const SendInviteForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
}));

export const inputStyles = {
  svg: {
    marginRight: '20px',
  },
};