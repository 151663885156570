import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import {
  CategoryTitle,
  CategoryTitleContainer,
  HeartPoints,
  IconWrapper,
  Info,
  LeftContainer,
  RightContainer,
  TimelineCardContainer,
  Title,
  TitleContainer,
  StyledCircle,
} from 'components/Study/timeline';
import { formatDistance } from 'date-fns';
import { ReactComponent as CompletedIcon } from 'images/shared/check-circle-green.svg';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';
import { SvgImageMap } from 'utils';

export const dateFormater = (date, separatorSymbol = '-') => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join(separatorSymbol);
};

const iconMap = {
  survey: SvgImageMap['survey'],
  call: SvgImageMap['CalendarIcon'],
  games: SvgImageMap['GameboardIcon'],
  call_schedule: SvgImageMap['CalendarIcon'],
  video: SvgImageMap['Videos'],
  data_sharing: SvgImageMap['HealthData'],
};

export function TimelineCard(props) {
  const Icon = iconMap[props.task.type] || null;

  const theme = useTheme();
  const filter = props.category === 'completed' ? 'grayscale(90%)' : '';
  const opacity = props.category === 'upcoming' ? 0.5 : 1;
  const completedIcon =
    props.status === 'completed' ? (
      <Box sx={{ marginTop: '-50px', marginLeft: '-5px' }}>
        <CompletedIcon />
      </Box>
    ) : null;

  const timeAgo = props.start_date
    ? formatDistance(new Date(props.start_date), new Date(), {
        addSuffix: true,
      })
    : '';

  return (
    <TimelineCardContainer
      sx={{ bgcolor: theme.palette.primary.white, opacity }}
    >
      <LeftContainer>
        {Icon != null && (
          <IconWrapper>
            <Box sx={{ filter }}>
              <Icon height='40px' width='40px' />
            </Box>
            {completedIcon}
          </IconWrapper>
        )}
        <TitleContainer>
          <Title>{props.task.title}</Title>
          <Info>
            {props.status === 'completed' ? props.status : timeAgo}{' '}
            <StyledCircle height='4px' width='4px' />{' '}
            {props.task.type.replace('_', ' ')}
          </Info>
        </TitleContainer>
      </LeftContainer>
      {props.points && (
        <RightContainer>
          <Box
            sx={{
              color: theme.palette.primary.fuchsia75,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PointsIcon width={18} />
          </Box>
          <HeartPoints>{props.points}</HeartPoints>
        </RightContainer>
      )}
    </TimelineCardContainer>
  );
}

export function TimelineDayCard(props) {
  const theme = useTheme();
  const title = props.title;
  const color =
    title === 'AVAILABLE'
      ? theme.palette.primary.fuchsia75
      : theme.palette.primary.gray75;

  return (
    <TimelineCardContainer sx={{ marginBottom: 0 }}>
      <CategoryTitleContainer>
        <CategoryTitle sx={{ color }}>{title}</CategoryTitle>
        <CategoryTitle sx={{ color, display: 'flex', paddingRight: '3px' }}>
          {props.count}
        </CategoryTitle>
      </CategoryTitleContainer>
    </TimelineCardContainer>
  );
}
