import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const countriesApiSlice = createApi({
  reducerPath: 'countriesApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['AllCountries', 'States'],
  endpoints: (build) => ({
    getAllCountries: build.query({
      query: () => ({
        url: `/countries`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ',
        },
      }),
      providesTags: ['AllCountries'],
    }),
    getStates: build.query({
      query: (countryCode) => ({
        url: `/countries/${countryCode}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ',
        },
      }),
      providesTags: ['States'],
    }),
  }),
});

export const { useGetAllCountriesQuery, useGetStatesQuery } =
  countriesApiSlice;
