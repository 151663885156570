import { ArrowForwardIos } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { statusMapping } from './statusMapping';

import { Card } from '../../Cards';
import Chip from 'ihp-components/components/v2/Chip';
import { ReactComponent as EducationIcon } from 'images/shared/education.svg';

import { getStyles, LearnMoreButton } from './style';

function EducationalMaterialsList({
  educationalMaterials,
  openFeedbackModal,
  openWarningModal,
  setTargetAddress,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const statusMap = statusMapping(theme);
  const readObject = statusMap['read'];
  const configuration = useSelector((state) => state.configuration);

  const truncateTextStyle = {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 4,
  };

  const getChips = (educationalMaterial, customStyle, feedback, status) => {
    return educationalMaterial.read ? (
      <Grid
        container
        direction={'row'}
        alignSelf={'stretch'}
        alignItems={'flex-start'}
        gap={'5px'}
      >
        <Chip
          icon={readObject.icon}
          variant={readObject.variant}
          type={readObject.type}
          sx={{ ...customStyle, backgroundColor: readObject.backgroundColor }}
          onClick={() => openFeedbackModal(educationalMaterial)}
        >
          {readObject.text}
        </Chip>
        <Chip
          icon={feedback.icon}
          variant={feedback.variant}
          type={feedback.type}
          sx={{ ...customStyle, color: statusMap[status].color }}
          onClick={() => openFeedbackModal(educationalMaterial)}
        >
          {feedback?.text}
        </Chip>
      </Grid>
    ) : (
      <Chip
        icon={feedback.icon}
        variant={feedback.variant}
        type={feedback.type}
        sx={customStyle}
        onClick={() => openFeedbackModal(educationalMaterial)}
      >
        {feedback.text}
      </Chip>
    );
  };

  return (
    <>
      {educationalMaterials.map((educationalMaterial) => {
        const status =
          !educationalMaterial?.personAttribute?.status ||
          educationalMaterial?.personAttribute?.status === 'read'
            ? 'feedback'
            : educationalMaterial?.personAttribute?.status;
        const feedback = statusMap[status] ?? statusMap['feedback'];
        const customStyle = {
          cursor: 'pointer',
          ...(feedback.backgroundColor && {
            backgroundColor: feedback.backgroundColor,
          }),
          ...(feedback.borderColor && {
            borderColor: feedback.borderColor,
          }),
        };

        return (
          <Card
            title={educationalMaterial.title}
            subtitle={educationalMaterial.sponsor}
            description={educationalMaterial.description}
            mainIcon={
              <EducationIcon style={styles.educationIcon}></EducationIcon>
            }
            styles={{ cardContentStyle: { ...truncateTextStyle } }}
            tags={getChips(educationalMaterial, customStyle, feedback, status)}
            actionButton={
              <LearnMoreButton
                disableRipple
                endIcon={<ArrowForwardIos style={styles.icon} />}
                onClick={() => {
                  navigate(`/education-materials/${educationalMaterial.id}`);
                }}
              >
                Learn more
              </LearnMoreButton>
            }
          />
        );
      })}
    </>
  );
}

EducationalMaterialsList.propTypes = {
  educationalMaterials: PropTypes.array.isRequired,
};

export default EducationalMaterialsList;
