import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
  height: '72px',
  backgroundColor: theme.palette.primary.gray2,
}));

const showFalse = { show: false };
const enabledFalse = { enabled: false };

const axisOptions = {
  floating: false,
  show: true,
  labels: showFalse,
  axisBorder: showFalse,
  axisTicks: showFalse,
  tooltip: enabledFalse,
  forceNiceScale: true,
};

export const chartOptions = theme => ({
  chart: {
    type: 'area',
    toolbar: showFalse,
    animations: enabledFalse,
  },
  dataLabels: enabledFalse,
  stroke: {
    width: 2,
    curve: 'smooth',
  },
  fill: {
    type: ['solid'],
    colors: [theme.palette.primary.white],
    opacity: 1
  },
  colors: [theme.palette.primary.fuchsia75],
  grid: {
    show: false,
    padding: {
      /* 
       * Magic numbers to make chart fill the available space.
       * They were discovered by testing them manually. It keeps 
       * the same regardless the dimension of the screen.
       * If, for some reason we need to re-fit the chart, change
       * 'grid.show' to true to see the chart grid limits.
       */
      top: -25,
      bottom: -15,
      left: -10,
      right: -5,
    }
  },
  toolbar: showFalse,
  tooltip: enabledFalse,
  xaxis: axisOptions,
  yaxis: { 
    ...axisOptions,
    min: -5,
  },
  plotOptions: {
    area: {
        fillTo: 'end',
    }
}
});
