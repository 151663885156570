import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const Title = styled(props =>
  <Typography
    variant='h6'
    color='primary.fuchsia75'
    align='center'
    {...props}
  />
)({
  fontWeight: 700,
});

export const Line = styled('div')(({ theme }) => ({
  marginTop: 20,
  backgroundColor: theme.palette.primary.gray50,
  width: '40px',
  height: 3,
  borderRadius: 3,
  marginBottom: 15,
}));

export const SectionTitle = props => (
  <Typography
    variant='h5'
    color='primary.fuchsia75'
    {...props}
  />
);
