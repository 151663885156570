import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  studyArms: [
    {
      id: '001',
      name: 'Name 1',
      type: 'Type 1',
      description: 'Arcu, facilisis feugiat egestas dictum viverra',
      rule: 'Rule 1',
    },
    {
      id: '002',
      name: 'Mi at odio et aliquet dignissim dictum eu, in',
      type: 'Type 2',
      description: 'Arcu, facilisis feugiat egestas dictum viverra',
      rule: 'Rule 2',
    },
  ],
};

const updateStudyArmAction = (studyArmState, action) => {
  let studyArmForUpdate = studyArmState.studyArms.find(
    (studyArm) => studyArm.id === action.payload.id
  );

  if (studyArmForUpdate) {
    const { name, type, description, rule } = action.payload;
    studyArmForUpdate.name = name;
    studyArmForUpdate.type = type;
    studyArmForUpdate.description = description;
    studyArmForUpdate.rule = rule;
  }
};

const deleteStudyArmAction = (studyArmState, action) => {
  const studyArmIndex = studyArmState.studyArms.findIndex(
    (studyArm) => studyArm.id === action.payload.id
  );

  studyArmState.studyArms.splice(studyArmIndex, 1);
};

const addStudyArmAction = (studyArmState, action) => {
  studyArmState.studyArms.push(action.payload);
};

export const studyArmSlice = createSlice({
  name: 'studyArm',
  initialState,
  reducers: {
    addStudyArm: addStudyArmAction,
    updateStudyArm: updateStudyArmAction,
    deleteStudyArm: deleteStudyArmAction,
  },
});

export const { addStudyArm, updateStudyArm, deleteStudyArm } =
  studyArmSlice.actions;

export const selectStudyArms = () => state =>
  state.studyArmState.studyArms;

export default studyArmSlice.reducer;
