import { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useEditPersonAttributesMutation,
  useEditPersonMutation,
  useParticipantGoalMutation,
} from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';
import { setOnboardingReward } from 'ihp-bloom-redux/features/user/userSlice';

import { FormLabel } from 'components/Login';
import { Logo } from 'components/shared';
import { Loader } from 'components/Loader';
import {
  findFirstErrorComponent,
  useValidateEmail,
  useValidatePhone,
} from 'utils/formValidation';
import { areAllFieldsEmpty, getEmptyFields } from '../utils';
import { isMobile } from 'utils/isMobile';
import AlternativeContactMobile from './index.mobile';
import {
  ContactContainer,
  ContactsContainer,
  AlternativeContactFullNameContainer,
  NameHeaderContainer,
  RelationContainer,
  FlexedBox,
  EmailContainer,
  PhoneContainer,
} from './styles';
import getStyles from '../styles/index.styles';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { ReactComponent as PhoneIcon } from 'images/profile/alternativecontacts/phone.svg';
import { ReactComponent as MailIcon } from 'images/profile/alternativecontacts/mail.svg';
import { ReactComponent as PersonIcon } from 'images/profile/alternativecontacts/person.svg';
import { ReactComponent as TrashIcon } from 'images/shared/trash-3.svg';
import { ReactComponent as PenIcon } from 'images/shared/pen-4.svg';
import { EditAlternateContactsModal } from '../EditModal/editAlternateContactsModal';
import { relations } from './relations';
import { withPerson } from 'authentication/withPerson';
import { findStudyAttribute } from 'utils/study';

function AlternativeContact({ person }) {
  document.title = 'Single Ventricle SOURCE Alternative contact';
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const { studyConfiguration } = useSelector((state) => state.configuration);
  const [contactId, setContactId] = useState(1);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [editPersonAttributes, { isLoading }] =
    useEditPersonAttributesMutation();
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const [triggerReward, { isLoading: isProcessingReward }] =
    useParticipantGoalMutation();

  const personalDetailsConfiguration = findStudyAttribute(
    studyConfiguration,
    'personal_details_configuration'
  );

  const { doctor_information = false } = personalDetailsConfiguration || {};

  const [currentContact, setCurrentContact] = useState({
    relation: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });

  const relationRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const refMap = {
    relation: relationRef,
    fistName: firstNameRef,
    lastName: lastNameRef,
    email: emailRef,
    phoneNumber: phoneNumberRef,
  };

  useEffect(() => findFirstErrorComponent(errors, refMap));

  const validatePhone = useValidatePhone();
  const validateEmail = useValidateEmail();
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      relation: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  });

  const setErrorsForFields = (emptyFields) => {
    emptyFields.forEach((field) =>
      setError(field, { message: 'This field is required!' })
    );
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        relation: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      });
    }
  }, [contactId, isSubmitSuccessful]);

  const onAddContact = (data) => {
    const emptyFields = getEmptyFields(data);
    if (emptyFields.length !== 0) {
      setErrorsForFields(emptyFields);
      return;
    }
    setContacts([...contacts, { id: contactId, ...data }]);
    setCurrentContact({
      relation: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    });
    setContactId(contactId + 1);
  };

  const onUpdateContact = (data) => {
    let updatedContacts = contacts.map((contact) => {
      if (contact.id === data.id) {
        return data;
      }
      return contact;
    });
    setContacts(updatedContacts);
  };

  const onSubmit = async (data) => {
    let newContacts = [...contacts];
    const emptyFields = getEmptyFields(data);
    const allFieldsEmpty = areAllFieldsEmpty(data);

    if (allFieldsEmpty && newContacts.length === 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length > 0) {
      setErrorsForFields(emptyFields);
      return;
    }

    if (!allFieldsEmpty && emptyFields.length === 0) {
      newContacts = [...newContacts, data];
    }

    const personAttributesPayload = [
      {
        data: {
          type: 'person-attributes',
          attributes: {
            attribute: 'alternative_contact',
            value: JSON.stringify(newContacts),
          },
        },
      },
    ];

    const editPersonPayload = {
      data: {
        type: 'people',
        id: `${person?.id}`,
        attributes: {
          state: 'enrollment',
          status: 'onboarded',
          status_reason: 'onboarded',
        },
      },
    };

    try {
      const personAttributesResponse = await editPersonAttributes({
        personId: person?.id,
        payload: personAttributesPayload,
      });
      console.log('Alternative contacts: ', personAttributesResponse);
      if (!personAttributesResponse.error && !doctor_information) {
        await editPerson({
          personId: person?.id,
          payload: editPersonPayload,
        });
        const rewardResponse = await triggerReward({
          personId: person?.id,
          payload: {
            process: 'onboarding',
          },
        });
        if (!rewardResponse?.error && rewardResponse?.data) {
          dispatch(setOnboardingReward(rewardResponse.data?.data || []));
        }
      }
      if (doctor_information) {
        navigate('/onboarding/doctors');
      } else {
        navigate('/onboarding/create-profile');
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  if (isMobile()) {
    return <AlternativeContactMobile person={person} />;
  }

  const handleDelete = (id) => {
    const newContacts = contacts.filter((c) => c.id !== id);
    setContacts(newContacts);
  };

  const handleEdit = (id) => {
    const selectedContact = contacts.filter((c) => c.id === id)[0];
    setSelectedContact(selectedContact);
    return setIsOpenModal(true);
  };

  return (
    <Grid container sx={{ ...styles.container, margin: '0 auto' }}>
      <Box sx={styles.formContainer}>
        <Box sx={styles.formHeader}>
          <Logo />
          <FormLabel fontSize='24px' marginTop='15px'>
            Alternative contact
          </FormLabel>
          <Typography
            color='primary.gray75'
            fontSize='14px'
            textAlign='center'
            width='295px'
          >
            Who can we contact if we can't reach you?
          </Typography>
        </Box>
        <ContactsContainer>
          <ul>
            {contacts.map((contact) => (
              <ContactContainer key={contact.email}>
                <Stack>
                  <NameHeaderContainer>
                    <AlternativeContactFullNameContainer>
                      {contact.firstName} {contact.lastName}
                    </AlternativeContactFullNameContainer>
                    <FlexedBox>
                      <Box
                        sx={{ cursor: 'pointer', marginRight: '16px' }}
                        onClick={() => handleEdit(contact.id)}
                      >
                        <PenIcon width='14px' height='14px' />
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(contact.id)}
                      >
                        <TrashIcon
                          width='14px'
                          height='14px'
                          sx={{ marginTop: '16px' }}
                        />
                      </Box>
                    </FlexedBox>
                  </NameHeaderContainer>
                  <FlexedBox>
                    <PersonIcon width='14px' height='14px' />
                    <RelationContainer>{contact.relation}</RelationContainer>
                  </FlexedBox>
                  <FlexedBox>
                    <Box marginTop='7px'>
                      <MailIcon width='14px' height='14px' />
                    </Box>
                    <EmailContainer>{contact.email}</EmailContainer>
                  </FlexedBox>
                  <FlexedBox>
                    <Box marginTop='7px'>
                      <PhoneIcon width='14px' height='14px' color='#606B71' />
                    </Box>
                    <PhoneContainer>{contact.phoneNumber}</PhoneContainer>
                  </FlexedBox>
                </Stack>
              </ContactContainer>
            ))}
          </ul>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='relation'
                control={control}
                rules={{ required: false }}
                haserrors={errors.relation}
                render={({ field }) => (
                  <Input
                    type='select'
                    name='relation'
                    label='Select relation to you'
                    fieldLabel='Relation to you'
                    placeholder='Select relation to you'
                    fullWidth
                    error={errors?.relation}
                    errorLabel={errors?.relation?.message}
                    sx={styles.selectInput}
                    inputRef={relationRef}
                    {...field}
                  >
                    {relations?.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='firstName'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    type='text'
                    name='firstName'
                    label='First name'
                    error={errors.firstName}
                    errorLabel={errors?.firstName?.message}
                    value={currentContact.firstName}
                    fullWidth={true}
                    placeholder='Enter first name'
                    inputRef={firstNameRef}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='lastName'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    type='text'
                    error={errors.lastName}
                    errorLabel={errors?.lastName?.message}
                    label='Last name'
                    value={currentContact.lastName}
                    fullWidth={true}
                    placeholder='Enter last name'
                    inputRef={lastNameRef}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='email'
                control={control}
                rules={{
                  validate: validateEmail,
                  required: false,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    type='text'
                    label='Email'
                    error={errors.email}
                    errorLabel={
                      errors?.email?.message || 'This field is required'
                    }
                    value={currentContact.email}
                    fullWidth={true}
                    placeholder='example@email.com'
                    inputRef={emailRef}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phoneNumber'
                control={control}
                rules={{
                  validate: validatePhone,
                  required: false,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='(___)___-___'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        name='phoneNumber'
                        error={errors.phoneNumber}
                        errorLabel={
                          errors?.phoneNumber?.message ||
                          'This field is required'
                        }
                        label='Phone number'
                        fullWidth
                        sx={styles.input}
                        inputRef={phoneNumberRef}
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Button
                fullWidth
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading}
                loading={isLoading || isEditingPerson || isProcessingReward}
              >
                Continue
              </Button>
            </Box>
            <Box sx={styles.fieldContainer}>
              <Button
                variant='outlined'
                fullWidth
                onClick={handleSubmit(onAddContact)}
                startIcon={<PlusIcon width={14} height={14} />}
              >
                Add new contact
              </Button>
            </Box>
          </form>
        </ContactsContainer>
      </Box>
      {selectedContact != null && (
        <EditAlternateContactsModal
          open={isOpenModal}
          onClose={() => {
            setSelectedContact(null);
            return setIsOpenModal(false);
          }}
          contact={{
            id: selectedContact.id,
            relation: selectedContact.relation,
            firstName: selectedContact.firstName,
            lastName: selectedContact.lastName,
            email: selectedContact.email,
            phoneNumber: selectedContact.phoneNumber,
          }}
          onUpdateContact={onUpdateContact}
        />
      )}
    </Grid>
  );
}
export default withPerson(AlternativeContact);
