import { Link } from 'react-router-dom';
import { styled, Box, Typography } from '@mui/material';
import MuiPhoneIcon from '@mui/icons-material/Phone';
import MuiEmailIcon from '@mui/icons-material/Email';

export const Container = styled(Box)(({ theme }) => ({
  maxWidth: '1080px',
  padding: '0px 20px',
  margin: '0 auto',
}));

export const Section = styled(Box)(({ theme }) => ({
  padding: '40px 0px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  padding: 0,
  marginBottom: '20px',
  fontWeight: 900,
  color: theme.palette.primary.navy,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  padding: 0,
  marginBottom: '20px',
  fontWeight: 800,
  color: theme.palette.primary.navy,
}));

export const ContactBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '30px',
  gap: '30px',
  borderRadius: '10px',
  border: '1px solid',
  borderColor: theme.palette.primary.gray15,
}));

export const PhoneIcon = styled(MuiPhoneIcon)(({ theme }) => ({
  width: '48px',
  height: '48px',
  color: theme.palette.primary.green,
}));

export const EmailIcon = styled(MuiEmailIcon)(({ theme }) => ({
  width: '48px',
  height: '48px',
  color: theme.palette.primary.green,
}));

export const FaqLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.green,
  textDecoration: 'underline',
}));

export const ContactTitle = styled(Typography)(({ theme }) => ({
  color: '#4D4D4D7A',
  fontSize: '14px',
  marginBottom: '10px',
}));
