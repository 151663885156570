import { forwardRef } from 'react';
import { Select, FormControl, useTheme, InputLabel } from '@mui/material';
import PropTypes from 'prop-types';

import { selectStyles, menuProps } from './styles';
import { ReactComponent as ChevronDown } from '../../assets/svg/chevron-down.svg';
import { TextField } from '../TextField';
import styled from '@emotion/styled';

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: `${theme.palette.primary.darkGray50} !important`,
  fontSize: '14px',
  lineHeight: '22px',
  paddingLeft: '5px',
  opacity: 0.7,
}));

export const getPlaceholderStyles = (hasFieldLabel) => ({
  top: hasFieldLabel ? '22px' : '0px',
});

export const SelectInput = forwardRef(
  (
    { hasErrors, value, label, fieldLabel, errorLabel, labelId, ...rest },
    ref
  ) => {
    const theme = useTheme();
    const selectMenuProps = { ...menuProps(theme), ...rest?.menuProps };
    return (
      <FormControl fullWidth {...rest}>
        {(value === '' || !value?.toString()) && (
          <StyledInputLabel
            shrink={false}
            sx={getPlaceholderStyles(fieldLabel)}
            id='selectLabelId'
          >
            {label}
          </StyledInputLabel>
        )}
        <Select
          label={label || fieldLabel}
          IconComponent={ChevronDown}
          id='selectLabelId'
          input={
            <TextField
              {...rest}
              error={rest.error || !!hasErrors}
              errorLabel={errorLabel || hasErrors?.message}
              label={fieldLabel || label}
            />
          }
          MenuProps={selectMenuProps}
          value={value}
          {...rest}
          sx={selectStyles}
          ref={rest.inputRef ?? ref}
        />
      </FormControl>
    );
  }
);

SelectInput.propTypes = {
  hasErrors: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldLabel: PropTypes.string,
};
