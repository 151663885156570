import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ids: ['001', '002', '003', '004'],
  entities: {
    '001': {
      id: '001',
      name: 'Provider A',
    },
    '002': {
      id: '002',
      name: 'Provider B',
    },
    '003': {
      id: '003',
      name: 'Provider C',
    },
    '004': {
      id: '004',
      name: 'Provider D',
    },
  },
  taskIdToProviderId: {
    '004': '001',
    '005': '002',
    '006': '004',
  },
};

const setProviderAction = (state, { payload: { id, taskId } }) => {
  state.taskIdToProviderId[taskId] = id;
}

const resetProviderAction = (state, { payload: { taskId } }) => {
  delete state.taskIdToProviderId[taskId];
}

export const slice = createSlice({
  name: 'telehealthProviders',
  initialState,
  reducers: {
    setProvider: setProviderAction,
    resetProvider: resetProviderAction,
  },
  extraReducers: builder => builder
    .addCase('tasks/deleteTask', (state, { payload: { id } }) => {
      delete state.taskIdToProviderId[id];
    })
});

export const { setProvider, resetProvider } = slice.actions;

export const selectProviderIds = () => state => state[slice.name].ids;
export const selectProviderById = id => state => state[slice.name].entities[id];
export const selectProviderIdByTaskId = taskId => state => state[slice.name].taskIdToProviderId[taskId] || '';

export default slice;
