import { Link } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { selectMedicationIds } from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

import { BaseHeader } from 'components/BaseLayout';
import { Divider } from 'configUI/components/Shared';
import { DropDownCalendar } from './DropDownCalendar';
import {
  FloatingAddButton,
  Content,
  StyledBaseLayout,
  StyledIconButton,
} from './styles';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import { ReactComponent as ListIcon } from 'images/shared/list-ul.svg';
import { DrugCard } from './DrugCard';
import {
  getNotificationCenterPath,
  getMedicationTrackerAllPath,
  getMedicationTrackerSearchPath,
} from 'pages/NotificationCenter/paths';

const DrugCardList = ({ date }) => {
  const weekday = date.getDay();
  const medicationIds = useSelector(selectMedicationIds({ weekday }));
  return (
    <Content>
      {medicationIds.map((id) => (
        <DrugCard key={id} id={id} date={date} />
      ))}
      <FloatingAddButton
        component={Link}
        to={getMedicationTrackerSearchPath()}
        startIcon={<PlusIcon />}
      >
        Add
      </FloatingAddButton>
    </Content>
  );
};

const MedicationTrackerMobile = ({ date, onDateChange }) => (
  <StyledBaseLayout
    header={
      <>
        <BaseHeader
          to={getNotificationCenterPath()}
          rightMenu={
            <StyledIconButton
              component={Link}
              to={getMedicationTrackerAllPath()}
            >
              <ListIcon height='18px' />
            </StyledIconButton>
          }
        >
          Medication tracker
        </BaseHeader>
        <Divider />
        <DropDownCalendar date={date} onDateChange={onDateChange} />
      </>
    }
    content={<DrugCardList date={date} />}
  />
);

export default MedicationTrackerMobile;
