import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import {
  HeroWrapper,
  HeroContentWrapper,
  HeroFlowerImage,
  LearnMoreBtn,
  JoinNowBtn,
  AcmeTitle,
  AcmeSubtitle,
  HeroItem,
} from './styles';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';
import youngAdult from 'images/welcome/young-adult.png';
import avBgSrc from 'images/welcome/av-bg.png';
// import { NewsletterLink } from 'pages/Welcome/Header/Banner';

export const Hero = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const navigateToWelcomeToStudy = () => {
    navigate('/onboarding/welcome-to-study');
  };
  return (
    <HeroWrapper>
      <HeroContentWrapper>
        <HeroItem justifyContent={'center'}>
          <Box
            display='flex'
            flexDirection='column'
            sx={{
              maxWidth: '516px',
              [theme.breakpoints.down('lg')]: {
                maxWidth: '80%',
              },
            }}
          >
            <AcmeTitle>
              Be the SOURCE of New Discoveries in Single Ventricle Heart Defect
              Research
            </AcmeTitle>
            <AcmeSubtitle>
              Help change the future of single ventricle research and care by
              joining SOURCE, a research study designed to unlock answers about
              single ventricle and to find new and better treatments.
            </AcmeSubtitle>
            <Box
              display='flex'
              gap={2}
              mb={4}
              sx={{
                mt: { xs: 2, sm: 2 },
                mb: { xs: 0, sm: 3 },
              }}
            >
              <LearnMoreBtn
                sx={{ whiteSpace: 'nowrap' }}
                type='info2'
                onClick={() => navigate('/welcome/get-involved')}
              >
                Learn More
              </LearnMoreBtn>
              {/* <NewsletterLink> */}
                <JoinNowBtn
                  onClick={navigateToWelcomeToStudy}
                  endIcon={<ArrowRight width='16px' />}
                >
                  Join Now
                </JoinNowBtn>
              {/* </NewsletterLink> */}
            </Box>
          </Box>
        </HeroItem>
        <HeroItem>
          <HeroFlowerImage src={youngAdult} alt='young-adult' />
        </HeroItem>
      </HeroContentWrapper>
    </HeroWrapper>
  );
};
