import { Box, Stack } from "@mui/material";
import { StyledAccordion, StyledAccordionSummary } from "./styles";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useEffect, useState } from "react";
import { ChevronRight } from "@mui/icons-material";

const ExpandIcon = (props) => (
  <Box sx={{ transform: "rotate(90deg)" }} {...props}>
    <ChevronRight />
  </Box>
);

const Accordion = ({
  index,
  accordionItem,
  accordionIcons,
  expanded,
  handleChange,
}) => {
  return (
    <StyledAccordion key={index} expanded={expanded} onChange={handleChange}>
      <StyledAccordionSummary
        expandIcon={
          (expanded
            ? accordionIcons?.collapseIcon
            : accordionIcons?.expandIcon) ?? <ExpandIcon color="#E196D2" />
        }
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        expanded={expanded.toString()}
      >
        <Stack spacing="3px">
          {accordionItem?.title}
          {accordionItem?.subtitle}
        </Stack>
      </StyledAccordionSummary>
      <AccordionDetails>{accordionItem?.description}</AccordionDetails>
    </StyledAccordion>
  );
};

// Parent component that manages the expanded state and renders the accordion list
export const AccordionList = ({ accordionItems, accordionIcons }) => {
  const [expanded, setExpanded] = useState(false); // Track which accordion is expanded

  useEffect(() => {
    setExpanded(false);
  }, [accordionItems]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false); // Expand only the clicked panel, collapse others
  };

  return (
    <Box>
      {accordionItems?.map((item, index) => (
        <Accordion
          key={index}
          index={index}
          accordionItem={item}
          expanded={expanded === index} // Check if the current accordion is expanded
          handleChange={handleChange(index)} // Pass the handleChange function
          accordionIcons={accordionIcons}
        />
      ))}
    </Box>
  );
};
