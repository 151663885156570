import { cloneElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import MaskedInput from 'react-input-mask';
import { Box } from '@mui/system';

import { ErrorMessage as ErrorMessageContainer } from 'configUI/components/ValidationError/styles';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';

const ErrorMessage = ({ error }) => {
  if (!Boolean(error)) return null;

  const { type, message } = error;

  const errorMsg =
    type === 'required' && message === '' ? 'This field is required' : message;

  return (
    <ErrorMessageContainer>
      <InformationIcon />
      {errorMsg}
    </ErrorMessageContainer>
  );
};

export const ControllerForm = ({
  name,
  required,
  validate,
  pattern,
  maskProps,
  defaultValue,
  children,
  ...rest
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  // To be used by regular text fields with mask props
  const maskInputRender = ({ field: { onChange, value, ...rest } }) => (
    <MaskedInput onChange={onChange} value={value} {...maskProps} {...rest}>
      {(inputProps) => cloneElement(children, { ...inputProps })}
    </MaskedInput>
  );

  const parseChangeHandler = (onChange) => (e, value) => {
    if (e?.target?.type === 'checkbox') return onChange(value);
    else if (e?.target?.value != null) return onChange(e.target.value);
    else if (value) return onChange(value);
    else return onChange(e);
  };

  // To be used by all fields, without mask: check box, select, radio, input
  const regularRender = ({ field: { onChange, value, ...rest } }) => {
    value = value === null ? '' : value;
    return cloneElement(children, {
      // cloneElement to inject props on child
      value,
      onChange: parseChangeHandler(onChange),
      ...rest,
    });
  };

  const error = _.get(errors, name);

  return (
    <Box {...rest}>
      <Controller
        name={name}
        control={control}
        rules={{ required, validate, pattern }}
        defaultValue={defaultValue}
        haserrors={Boolean(error)}
        render={maskProps ? maskInputRender : regularRender}
      />
      <ErrorMessage error={error} />
    </Box>
  );
};
