import { styled } from '@mui/material/styles';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { ReactComponent as CheckIcon } from 'images/shared/check-gray.svg';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as ClockIcon } from 'images/profile/clock-activity.svg';
import { ReactComponent as ExclamationIcon } from 'images/profile/exclamation-circle.svg';
import { ReactComponent as CheckGrey } from 'images/profile/check-grey.svg';
import { ReactComponent as ExclamationIconWhite } from 'images/profile/exclamation-circle-white.svg';

export const StyledCardContent = styled(CardContent)({
  padding: 0,
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,

  '&: last-child': {
    paddingBottom: '0px',
  }
});

export const StyledCardContentMobile = styled(CardContent)({
  display: 'flex',
  padding: '25px 0px',
  marginLeft: '0px',
  flexDirection: 'column',
  gap: '1rem',
  maxWidth: '90%',
});

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const Steps = styled((props) => (
  <Typography variant='pb4' color='primary.gray100' {...props} />
))({
  opacity: '0.5',
});

export const StyledCard = styled(Card)({
  width: 435,
  minWidth: 335,
  borderRadius: '10px',
  border: '0px',
  padding: '25px',
  gap: '25px',
  boxSizing: 'border-box',
  '&:hover': {
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  margin: '0 !important'
});

export const TaskStyledCard = styled(Card)({
  width: 935,
  minWidth: 935,
  borderRadius: '10px',
  border: '0px',
  padding: '25px',
  boxSizing: 'border-box',
  '&:hover': {
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const StyledAvatar = styled(({ iconBgColor, isLocked, ...rest }) => (
  <Avatar {...rest} />
))(({ iconBgColor, isLocked }) => ({
  width: '45px',
  height: '45px',
  opacity: isLocked ? '50%' : '100%',
  backgroundColor: iconBgColor,
}));

export const StyledCardBottom = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const TaskGroupInfo = styled('div')({
  fontSize: '12px',
  marginTop: '10px',
  fontWeight: '600',
});

export const OverdueButtonsStyle = {
  display: 'flex',
  gap: '10px',
  marginTop: '15px',
};
export const DescriptionStyle = {
  maxWidth: '95%',
  fontSize: '13px',
  lineHeight: '18px',
};
export const LockIconStyle = {
  position: 'absolute',
  marginTop: '-25px',
  marginLeft: '-25px',
  zIndex: 1000,
};

export const getInfoButton = ({
  text,
  isOverdue,
  isLocked,
  isStatus,
  isCompleted,
  isConsent,
}) => {
  if (!text || Object.keys(text).length === 0) {
    return <></>;
  }
  let background = 'white';
  const labelIsOverdue = text?.includes('overdue');
  let icon = isOverdue ? <ClockIcon /> : <ExclamationIcon />;
  let iconColor = '#A0438B';
  let color = isStatus ? '#A0438B' : '#606B71';
  let borderColor = isStatus ? '#A0438B' : '#CACED0';
  if (!labelIsOverdue && isOverdue && text) {
    color = 'white';
    iconColor = 'white';
    borderColor = 'white';
    background = '#C080B1';
    icon = <ExclamationIcon />;
  }
  if (isLocked) {
    color = 'white';
    icon = (
      <Box sx={{ marginTop: '-2px', marginRight: '2px' }}>
        <ExclamationIconWhite />
      </Box>
    );
    background = '#959CA0';
    borderColor = '#959CA0';
  }

  if (text === 'NON-COMPLIANT') {
    icon = <></>;
    color = '#606B71';
    borderColor = '#606B71';
    background = 'white';
  }
  if (isCompleted) {
    icon = (
      <Box sx={{ marginTop: '1px', marginRight: '2px' }}>
        <CheckGrey />
      </Box>
    );
    color = '#606B71';
    borderColor = '#606B71';
    background = 'white';
  }
  if (isConsent) {
    icon = <CheckIcon />;
    color = '#606B71';
    borderColor = '#606B71';
    background = 'white';
  }

  let border = '1.3px solid ' + borderColor;
  const iconPadding = '0px 3px 4px 3px';

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: isLocked ? '350px' : '252px',
        height: '25px',
        verticalAlign: 'center',
        background: background,
        color,
        border,
        borderRadius: '50px',
        padding: '6px 8px 4px 8px',
        margin: '5px',
        fontWeight: 500,
        fontSize: '10px',
        fontFamily: 'Inter',
        textTransform: isLocked ? 'none' : 'uppercase',
      }}
    >
      {isStatus && (
        <Box sx={{ color: iconColor, padding: iconPadding }}>{icon}</Box>
      )}
      <Box>{text}</Box>
    </Box>
  );
};
