import { ReactComponent as Email } from 'images/activity/mail-file.svg';
import { ReactComponent as Pause } from 'images/activity/pause.svg';
import { ReactComponent as Minus } from 'images/activity/minus-fill.svg';
import { ReactComponent as CheckCircle } from 'images/activity/check-circle-solid.svg';
import { ReactComponent as CloseCircle } from 'images/activity/close-circle.svg';
import { ReactComponent as Add } from 'images/activity/add.svg';
import researchOpportunityStatus from '../../../constants/researchOpportunityStatus';

export const statusMapping = (theme) => {
  return {
    [researchOpportunityStatus.ENROLLED?.key]: {
      icon: <CheckCircle />,
      color: theme.palette.primary.greenREAD,
      text: researchOpportunityStatus.ENROLLED?.title,
      type: 'success',
      variant: 'filled',
    },
    [researchOpportunityStatus.NOT_INTERESTED?.key]: {
      icon: <Minus />,
      color: theme.palette.primary.darkGray70,
      text: researchOpportunityStatus.NOT_INTERESTED?.title,
      type: 'primary',
      variant: 'filled',
    },
    [researchOpportunityStatus.DECLINED?.key]: {
      icon: <CloseCircle />,
      color: theme.palette.primary.red75,
      type: 'danger',
      text: researchOpportunityStatus.DECLINED?.title,
      variant: 'filled',
    },
    [researchOpportunityStatus.CONTACTED?.key]: {
      icon: <Email />,
      color: theme.palette.primary.lightBlue100,
      text: researchOpportunityStatus.CONTACTED?.title,
      type: 'primary',
      variant: 'filled',
    },
    [researchOpportunityStatus.INELIGIBLE?.key]: {
      icon: <Pause />,
      color: theme.palette.primary.orange100,
      text: researchOpportunityStatus.INELIGIBLE?.title,
      type: 'primary',
      variant: 'filled',
    },
    status: {
      icon: <Add />,
      text: 'Status',
      color: null,
      type: 'primary',
      variant: 'bordered',
      borderColor: theme.palette.primary.darkGray70,
    },
  };
};
