import { Box, styled } from '@mui/system';
import { Alert } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '25px',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.white,
  },
  [theme.breakpoints.down('sm')]: {
    flex: 1,
  },
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  background: theme.palette.white,
  borderRadius: '8px',
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1);',
  alignItems: 'center',
  textAlign: 'left',
}));
