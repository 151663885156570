import { createSlice } from '@reduxjs/toolkit';

const pages = [
  {
    id: '001',
    name: 'Informed Consent',
    active: true,
    content: 'Informed Consent',
    type: 'page', // based on design, the static page type can be either  'page' | 'email' | 'contact'
    // but this implementation is about page
  },
  {
    id: '002',
    name: 'Study Overview',
    active: true,
    content: '',
    type: 'page', // 'page' | 'email' | 'contact'
  },
  {
    id: '003',
    name: 'Terms & Conditions',
    active: false,
    content: '',
    type: 'page', // 'page' | 'email' | 'contact'
  },
  {
    id: '004',
    name: 'Privacy Policy',
    active: false,
    content: '',
    type: 'page', // 'page' | 'email' | 'contact'
  },
  {
    id: '005',
    name: 'Instructions',
    active: false,
    content: '',
    type: 'page', // 'page' | 'email' | 'contact'
  },
];

const initialState = {
  selectedPages: pages,
};

const slice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    togglePage: (state, { payload }) => {
      state.selectedPages = state.selectedPages.map((page) => ({
        ...page,
        active: page.id === payload ? !page.active : page.active,
      }));
    },
    addPage: (state, { payload }) => {
      const newId = `00${state.selectedPages.length + 1}`;
      console.log({ ...payload, id: newId, active: true });
      state.selectedPages.push({ ...payload, id: newId, active: true });
    },
    updatePage: (state, { payload }) => {
      console.log({ payload });
      state.selectedPages = state.selectedPages.map((page) => {
        console.log('page.id === payload.id', page.id === payload.id);
        return page.id === payload.id ? payload : page;
      });
      console.log('state.selectedPages', state.selectedPages);
    },
  },
});

export const { togglePage, addPage, updatePage } = slice.actions;

export default slice;
