import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';

export const Header = styled(Box)({
  padding: '0 20px',
  height: '74px',
  display: 'flex',
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Layout = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '50px 0',
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
  position: 'relative',
  overflow: 'auto',
  alignItems: 'center',
});

export const Content = styled(Box)(({ theme }) => ({
  width: '560px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  borderRadius: '8px',
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '12px',
  height: '12px',
  color: theme.palette.primary.gray50,
  padding: 0,
  fontSize: '15px',
}));
