import { Stack, styled } from '@mui/material';

export const FieldsWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray70,
  padding: '20px',
  borderRadius: '8px',
}));

export const FieldWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  input: {
    paddingLeft: '60px !important',
    paddingRight: '30px',
    color: theme.palette.primary.darkGray70,
    fontWeight: '600',
  },
  '.icon': {
    position: 'absolute',
    top: '55%',
    left: '36px',
    transform: 'translateY(-55%)',
    zIndex: '1',
    svg: {
      fill: '#7C7C7C',
      width: '16px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    input: {
      paddingLeft: '40px !important',
    },
    '.icon': {
      left: '20px',
    },
  },
}));
