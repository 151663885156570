import { Box, styled } from '@mui/system';
import {
  RadioGroup,
  Divider as MUIDivider,
  CircularProgress,
} from '@mui/material';
import { FilledButton } from 'components/Buttons';
import { isMobile } from 'utils/isMobile';

export const ModalContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(isMobile()
    ? {
        height: '100%',
        borderRadius: 0,
      }
    : {
        maxWidth: '500px',
        maxHeight: '90%',
        borderRadius: '10px',
        boxShadow: 24,
      }),
});

export const ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 40px',
  justifyContent: 'center',
});

export const GoalsWrapper = styled(Box)({
  padding: '15px 20px',
  marginTop: 3,
  marginBottom: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: 1.5,
  borderRadius: '5px',
  backgroundColor: '#F9F9F9',
});

export const ReasonsContainer = styled(Box)({
  marginTop: '20px',
  flexDirection: 'column',
  display: 'flex',
});

export const ModalFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
  width: '100%',
  padding: '0px 40px 30px 40px',
});

export const RadioInputGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

export const Loader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.deepBlue,
}));

export const Divider = styled(MUIDivider)({
  margin: '10px 0',
});

export const SubmitBtn = styled(FilledButton)(({ theme }) => ({
  '&.Mui-disabled': {
    backgroundColor: theme.palette.primary.deepBlue,
    opacity: 0.5,
  },
  backgroundColor: theme.palette.primary.deepBlue,
  '&:hover': {
    backgroundColor: theme.palette.primary.deepBlue,
  },
}));
