import { Typography } from '@mui/material';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';
import { StyledFormHelperText } from './styles';

const MultiselectCheckbox = ({
  options = [],
  handleOnChange = () => {},
  title = '',
  styles = {},
  disabled = false,
  error,
  errorLabel,
  inputRef,
}) => {
  return (
    <div ref={inputRef}>
      <Typography style={{ ...styles?.headingStyle }}>{title}</Typography>
      {options?.map((item) => (
        <FormCheckbox
          containerStyle={
            item.checked
              ? { ...styles?.formCheckBox, ...styles?.formCheckBoxChecked }
              : styles?.formCheckBox
          }
          key={item.value}
          value={item.value}
          label={item.label}
          disabled={disabled}
          checked={item.checked}
          onChange={() => handleOnChange(item.value)}
        />
      ))}
      {error && (
        <StyledFormHelperText>
          <InformationIcon />
          {errorLabel}
        </StyledFormHelperText>
      )}
    </div>
  );
};

export default MultiselectCheckbox;
