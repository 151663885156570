import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from "redux-persist";

const initialState = {
  isAuthenticated: false,
  isOnboardingCompleted: false,
  type: '',
  token: '',
  isConsented: false,
  isEmailVerified: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login: (state, action) => {
      const { payload } = action;
      state.isAuthenticated = true;
      state.type = payload.type;
    },
    updateOneUpHealthUserId: (state, action) => {
      state.oneUpHealthUserId = action.payload;
    },
    updateOneUpHealthToken: (state, action) => {
      state.oneUpHealthToken = action.payload;
    },
    setOnboardingCompleted: (state) => {
      state.isOnboardingCompleted = true;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.token = '';
    },
    authenticate: (state, action) => {
      const token = action.payload;
      state.isAuthenticated = true;
      state.token = token;
      state.type = 'google';
    },
    setIsConsented: (state) => {
      state.isConsented = true;
    },
    setEmailVerified: (state) => {
      state.isEmailVerified = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    })
  },

});

export const {
  login,
  logout,
  authenticate,
  updateOneUpHealthUserId,
  updateOneUpHealthToken,
  setOnboardingCompleted,
  setIsConsented,
  setEmailVerified,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
