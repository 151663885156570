export const FAMILY_INVITATION_STATUS = {
  ACCEPTED: 'Accepted',
  CANCELLED: 'Cancelled',
  DECLINED: 'Declined',
  INVITED: 'Invited',
};

export const INVITE_FAMILY_MEMBERS = {
  title: 'Invite adult family members',
  description: 'Do you know any adult family members who should join SOURCE? Invite them here!'
};

export const SEND_INVITE_MODAL = {
  title: 'Add Family Member',
  description: `Send an invitation to a family member's email address to join your family in the study.`,
  errorMessage: 'An error occurred while sending the invitation. Please try again later.',
  form: {
    firstName: {
      label: 'First name',
      placeholder: 'Enter first name'
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Enter last name'
    },
    email: {
      label: 'Email',
      placeholder: 'Enter email address'
    }
  },
  buttonText: {
    sendInvitation: 'Send Invitation',
    close: 'Close'
  }
};

export const SEND_INVITE_SUCCESS_MODAL = {
  title: 'Invitation has been sent successfully',
  buttonText: {
    close: 'Close'
  }
};

export const RECEIVE_INVITE_MODAL = {
  title: 'Join your family',
  description: 'has invited you to join their family! Accept the invitation to become part of their family within our app.',
  buttonText: {
    accept: 'Accept',
    decline: 'Decline'
  }
};

export const RECEIVE_INVITE_SUCCESS_MODAL = {
  title: 'You have linked your family successfully',
  buttonText: {
    close: 'Close'
  }
};

export const RECEIVE_INVITE_ERROR_MODAL = {
  title: 'An error has occurred',
  description: 'If you want to try again, you can see your pending invitations in your profile.',
  buttonText: {
    seeInvitation: 'See invitations',
    close: 'Close'
  }
};