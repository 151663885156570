import { ADDITIONAL_VENTURE_LINK } from "constants/global";
import { StyledLink } from "./styles";

export const aboutSourceContent = {
  title: 'About SOURCE',
  joinNowBtnText: 'Join Now',
  description: (
    <>
      SOURCE is a platform focused on improving the lives of people living
      with single ventricle heart defects. Our goal is to collect health and
      genetic information from people with single ventricle heart defects and
      their family members. We will share that information with researchers so
      they can work to uncover the causes of single ventricle heart defects
      and the related health issues many people with these conditions
      develop. We believe this research will lead to better treatments and
      maybe even, one day, a cure.
      <br />
      We are deeply committed to supporting research that will lead to better
      single ventricle treatments and improve outcomes for patients. SOURCE
      data will be available free of charge to qualified researchers. Our data
      access application process for researchers enables quick review and
      decision-making to get as much research as possible underway as quickly
      as possible, while carefully protecting the privacy of all who
      participate in SOURCE.
    </>
  )
};

export const whoWeAreContent = {
  title: 'Who We Are',
  description: (
    <>
      SOURCE is powered by{' '}
      <StyledLink to={ADDITIONAL_VENTURE_LINK}>
      Additional Ventures
      </StyledLink>
      , a nonprofit organization that funds research to improve the lives of
      those with single ventricle heart disease. Additional Ventures aims to
      accelerate research progress and improve clinical care for people born
      with single ventricle heart defects.
    </>
  )
};

export const ourNetworksContent = {
  title: 'Our Network',
  description: `We can’t do this alone. Additional Ventures is working with Boston
          Children’s Hospital, IQVIA, Gencove, and Gene by Gene to carry out
          SOURCE.`
};

export const ourClinicalSitesContent = {
  title: 'Our Clinical Sites',
  description: [`We know not everyone is able to sign up online. We have partnered with 
                  clinical sites to assist in patient recruitment and enrollment.`,
          'We will be announcing our clinical sites soon.']
};