import { Stack, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { StackContainer, Wrapper } from './styles';

export const EmptyProviders = ({
  loader,
  hasConnectedProviders,
  isLoading,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const title = hasConnectedProviders ? '' : 'Learn how to share your EMR';
  const subtitle = hasConnectedProviders
    ? ''
    : 'Please follow the instructions in the video on how you can share your EMR (Electronic medical records) data with the study.';

  return (
    <StackContainer justifyContent='center' alignItems='center' spacing='20px'>
      <Wrapper>
        <Stack
          justifyContent='center'
          spacing='5px'
          sx={{
            margin: '0 20px',
            paddingTop: isMobile ? '30px' : '0px',
            paddingBottom: isMobile ? '15px' : '0px',
            background: isMobile ? 'transparent' : 'white',
          }}
        >
          {/* {hasConnectedProviders && (
            <Box sx={{ margin: 'auto' }}>
              <HospitalIcon
                height={60}
                width={60}
                sx={{ alignContent: 'center' }}
              />
            </Box>
          )} */}
          {!isLoading && !loader && (
            <>
              <Typography variant='h5' color='primary.gray75' align='left'>
                {title}
              </Typography>
              <Typography variant='pl5' color='primary.gray50' align='left'>
                {subtitle}
              </Typography>
            </>
          )}
        </Stack>
        <Box sx={{ marginTop: hasConnectedProviders ? '-200px' : '0px' }}>
          {loader}
        </Box>
      </Wrapper>
    </StackContainer>
  );
};
