// import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container } from '@mui/material';
import img1Src from 'images/about/father-child.jpg';
import img2Src from 'images/about/man-and-child.jpg';

import {
  NetworksContainer,
  Section,
  Subtitle,
  TextWrapper,
  TextContainer,
  Title,
  // StyledLink,
  AboutContainer,
  StyledImg,
} from './styles';
import { JoinNowBtn } from '../Welcome/GetStarted/styles';
import { ReactComponent as ArrowRight } from 'images/shared/arrow-right-2.svg';

import bostonChildrensLogo from 'images/about/boston-children-logo.png';
import { ReactComponent as GencoveLogo } from 'images/about/gencove-logo.svg';
import geneByGeneLogo from 'images/about/gene-by-gene-logo.png';
import { ReactComponent as IQVIALogo } from 'images/about/iqvia-logo.svg';
// import { ADDITIONAL_VENTURE_LINK } from 'constants/global';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import { NewsletterLink } from 'pages/Welcome/Header/Banner';
import { whoWeAreContent, aboutSourceContent, ourNetworksContent, ourClinicalSitesContent } from './constants';

export const AboutGrid = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <AboutContainer columns={isLargeScreen ? 2 : 1}>
      {!isLargeScreen && <StyledImg src={img1Src} />}
      <TextContainer bgcolor='primary.mint'>
        <AboutSource />
      </TextContainer>
      {isLargeScreen && <StyledImg src={img1Src} />}
      <StyledImg src={img2Src} />
      <TextContainer bgcolor='primary.green'>
        <WhoWeAre />
      </TextContainer>
    </AboutContainer>
  );
};

export const AboutSource = () => {
  const navigate = useNavigate();
  const { title, description, joinNowBtnText } = aboutSourceContent;

  const navigateToWelcomeToStudy = () => {
    navigate('/onboarding/welcome-to-study');
  };
  return (
    <TextWrapper>
      <Title variant='h1'>{title}</Title>
      <Subtitle variant='pl1'>
        {description}
      </Subtitle>
      {/* <NewsletterLink> */}
        <JoinNowBtn
          onClick={navigateToWelcomeToStudy}
          endIcon={<ArrowRight width='16px' />}
          sx={{ marginTop: '20px' }}
        >
          {joinNowBtnText}
        </JoinNowBtn>
      {/* </NewsletterLink> */}
    </TextWrapper>
  );
};

export const WhoWeAre = () => {
  const { title, description } = whoWeAreContent;

  return (
    <TextWrapper>
      <Title variant='h1'>{title}</Title>
      <Subtitle variant='pl1'>
        {description}
      </Subtitle>
    </TextWrapper>
  );
};

export const OurNetworks = () => {
  const { title, description } = ourNetworksContent;

  return (
    <>
      <Section>
        <Typography
          variant='h1'
          padding='0'
          marginBottom={'20px'}
          color={'primary.navy'}
        >
          {title}
        </Typography>
        <Typography variant='pl1' color={'primary.navy'}>
          {description}
        </Typography>
      </Section>
      <Section>
        <NetworksContainer>
          <img src={bostonChildrensLogo} />
          <IQVIALogo />
          <GencoveLogo />
          <img src={geneByGeneLogo} />
        </NetworksContainer>
      </Section>
    </>
  );
};

export const OurClinicalSites = () => {
  const { title, description } = ourClinicalSitesContent;
  
  return (
    <Box bgcolor='#E5F8F8'>
      <Container>
        <Section>
          <Typography
            variant='h1'
            padding='0'
            marginBottom={'20px'}
            color={'primary.navy'}
          >
            {title}
          </Typography>
          <Typography variant='pl1' color={'primary.navy'} component={'p'}>
            {description?.[0]}
          </Typography>
          <Typography
            variant='pl1'
            color={'primary.navy'}
            component={'p'}
            marginTop={'20px'}
          >
            {description?.[1]}
          </Typography>
          {/* <List>
            <ListItem>Boston Children’s Hospital</ListItem>
            <ListItem>Children’s Hospital of Philadelphia</ListItem>
          </List> */}
        </Section>
      </Container>
    </Box>
  );
};
