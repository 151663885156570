import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const AchievementsPageWrapper = styled('div')(({ theme }) => ({
  padding: '50px',
  [theme.breakpoints.down('sm')]: {
    padding: '25px',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  marginBottom: '20px',
}));

export const AchievementsGrid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
});
