import { useState } from 'react';
import { Box, Modal, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { updateShowWarningModal } from 'ihp-bloom-redux/features/settings/configurationSlice';

import { isMobile } from 'utils/isMobile';
import { Loader } from 'components/Loader';
import { ReactComponent as OpenNewIcon } from 'images/shared/open-new.svg';
import { MiniCheckbox } from 'ihp-components/components/v2/Input/Checkbox';

import {
  ButtonContainer,
  CancelButton,
  ContinueButton,
  WarningIconWrapper,
  LineBorder,
  ModalContainer,
  WarningTitle,
  WarningDescription,
  WarningText,
  CheckBoxContainer,
} from './styles';

export const WarningModal = ({
  isOpen,
  setIsOpenModal,
  targetAddress,
  newTab,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);

  const onCloseModal = () => {
    return setIsOpenModal(false);
  };

  const onSaveModal = async () => {
    if (isSelected) {
      dispatch(updateShowWarningModal(false));
    }
    setIsOpenModal(false);
    if (newTab) {
      window.open(targetAddress, '_blank');
    } else {
      window.location.href = targetAddress;
    }
  };

  if (isOpen && !targetAddress) {
    return <Loader />;
  }
  if (isSelected && !isOpen) {
    dispatch(updateShowWarningModal(false));
  }

  const width = isMobile() ? '327px' : '710px';
  const height = isMobile() ? '422px' : '341px';
  const modalStyles = {
    backgroundColor: (theme) => theme.palette.primary.white,
    width,
    height,
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ zIndex: 9999999 }}
    >
      <ModalContainer sx={modalStyles}>
        <>
          <Box sx={{ display: 'flex' }}>
            <WarningTitle>External Link</WarningTitle>
            {!isMobile() && (
              <WarningIconWrapper>
                <CloseIcon onClick={onCloseModal} />
              </WarningIconWrapper>
            )}
          </Box>
          {!isMobile() && <LineBorder sx={{ width: '710px' }} />}
          <WarningDescription>
            <span style={{ fontWeight: '600' }}>Please note:</span> You are
            leaving our site and will be redirected to an external website.
            Select ‘Leave Site’ below to proceed.
          </WarningDescription>
          <CheckBoxContainer>
            <MiniCheckbox
              label=''
              checked={isSelected}
              onChange={() => setIsSelected(!isSelected)}
            />
            <WarningText onClick={() => setIsSelected(!isSelected)}>
              Don't show this message again
            </WarningText>
          </CheckBoxContainer>
          <ButtonContainer>
            {!isMobile() && (
              <CancelButton variant='link' onClick={onCloseModal}>
                Go back
              </CancelButton>
            )}
            <ContinueButton
              variant='contained'
              onClick={onSaveModal}
              endIcon={<OpenNewIcon />}
              sx={{ width: isMobile() ? '260px' : '180px' }}
            >
              Leave site
            </ContinueButton>
            {isMobile() && (
              <CancelButton variant='link' onClick={onCloseModal}>
                Go back
              </CancelButton>
            )}
          </ButtonContainer>
        </>
      </ModalContainer>
    </Modal>
  );
};
