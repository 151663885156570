import { styled } from '@mui/material/styles';
import { Avatar, Box, Menu, Typography } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    zIndex: '100',
    position: 'fixed',
    bottom: undefined,
    top: 0,
    right: '10px',
    backgroundColor: 'transparent',
    paddingTop: '14px',
  },
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.darkBlue50,
  fontStyle: 'Inter',
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '22px',
  color: theme.palette.primary.white,
  width: '40px',
  height: '40px',
  [theme.breakpoints.down('md')]: {
    width: '32px',
    height: '32px',
    fontSize: '14px',
    lineHeight: '10px',
  },
}));

export const StyledTypography = styled(Typography)(({ theme, fontWeight }) => ({
  color: theme.palette.primary.black,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 10px 0px #00000014',
    borderRadius: 8,
    width: '255px',
    transform: 'translate(20px, 20px) !important',
    '& ul li': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
}));
