import {
  Container,
  StyledFilledButton,
  StyledLinkButton,
} from './ProgressButtons.styles';

export const ProgressButtons = ({
  buttonProps = {
    disabled: false,
    label: '',
  },
  linkProps = {
    disabled: false,
    label: '',
    hide: false,
  },
  className = '',
  ...rest
}) => (
  <Container className={`ProgressButtons-root ${className}`} {...rest}>
    <StyledFilledButton
      className='ProgressButtons-button'
      linkDisabled={linkProps.disabled}
      {...buttonProps}
    >
      {buttonProps.label}
    </StyledFilledButton>
    {!linkProps.hide && (
      <StyledLinkButton className='ProgressButtons-link' {...linkProps}>
        {linkProps.label}
      </StyledLinkButton>
    )}
  </Container>
);
