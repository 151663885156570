import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem as MUIMenuItem, Stack, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  enableMedication,
  removeMedication,
  selectMedicationById,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

import { StyledMenu, MenuIcon } from './MenuDrugCard.styles';
import { ReactComponent as PenIcon } from 'images/shared/pen.svg';
import { ReactComponent as BellDisabledIcon } from 'images/shared/bell-slash.svg';
import { ReactComponent as BellEnabledIcon } from 'images/shared/bell.svg';
import { ReactComponent as TrashIcon } from 'images/shared/trash.svg';
import { getMedicationTrackerEditPath } from 'pages/NotificationCenter/paths';

export const MenuItem = ({ Icon, text, ...rest }) => (
  <MUIMenuItem {...rest}>
    <Stack direction='row' spacing='12px' alignItems='center'>
      <MenuIcon>
        <Icon height='14px' />
      </MenuIcon>
      <Typography variant='pl3' color='primary.gray75'>
        {text}
      </Typography>
    </Stack>
  </MUIMenuItem>
);

const MenuDrugCard = ({ id, anchorEl, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enabled } = useSelector(selectMedicationById(id));

  const disableLabel = enabled ? 'Disable reminder' : 'Enable reminder';
  const DisableIcon = enabled ? BellDisabledIcon : BellEnabledIcon;

  const handleEditMedication = () => {
    navigate(getMedicationTrackerEditPath(id));
    onClose();
  };

  const handleDisableReminder = () => {
    dispatch(enableMedication({ id, enabled: !enabled }));
    onClose();
  };

  const handleRemoveMedication = () => {
    dispatch(removeMedication(id));
    onClose();
  };

  return (
    <StyledMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <MenuItem
        onClick={handleEditMedication}
        Icon={PenIcon}
        text='Edit medication'
      />
      <MenuItem
        onClick={handleDisableReminder}
        Icon={DisableIcon}
        text={disableLabel}
      />
      <MenuItem
        onClick={handleRemoveMedication}
        Icon={TrashIcon}
        text='Remove medication'
      />
    </StyledMenu>
  );
};

export const useMenuDrugCard = (id) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const Menu = () => (
    <MenuDrugCard id={id} anchorEl={anchorEl} onClose={handleClose} />
  );

  return { Menu, handleClick };
};
