import { STUDY_ARM_SUB_ROLE_CODES } from 'constants/global';
import _ from 'lodash';

/**
 * @param {array} arr - fields array associated with tab config
 * @param {string} key - object key value
 *
 * @return {object}
 * @description find object based on key value of fields array
 * can be done easily like fieldsarray[0] to get relationships object,
 * but in case new array property added on top, this will break render order
 */
export const getObjectBasedOnKey = (arr, key) => _.find(arr, { key }) ?? {};

export const getReporterSubroleSet = (data) => {
  const relationshipAttr = _.filter(
    data,
    (item) => item?.attributes?.attribute === 'relationship'
  );

  const relationSubRole = relationshipAttr.map(
    (item) => item?.attributes?.value
  );

  return new Set(relationSubRole);
};

export const getIParticipantSubroleSet = (data) => {
  const subRoleName = data.data.map((role) => role?.attributes?.code);

  return new Set(subRoleName);
};

export const ROLE_MAP = {
  participant: 'A person diagnosed with Single Ventricle disorder, answering for myself.',
  lar: 'A legally authorized representative for a diagnosed person, here to enroll that person in research.',
  independent_participant:
    'A caregiver, care partner, or biological family member, answering for myself on my past or current lived experience',
  reporter: 'Reporter',
};

export const subRoleLists = (active) => {
  return [
    {
      label: 'Biological family member',
      value: 'biological_family_member',
      subRole: STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER,
      checked:
        (active.has(
          STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER
        ) ||
          active.has('biological_family_member')) ??
        false,
      id: 15,
    },
    {
      label: 'Care partner / caregiver',
      value: 'caregiver',
      subRole: STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_CAREGIVER,
      checked:
        (active.has(STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_CAREGIVER) ||
          active.has('caregiver')) ??
        false,
      id: 14,
    },
    {
      label: 'Friend',
      value: 'friend',
      subRole: 'friend',
      checked: active.has('friend') ?? false,
    },
    {
      label: 'Healthcare provider',
      value: 'healthcare_provider',
      subRole: 'healthcare_provider',
      checked: active.has('healthcare_provider') ?? false,
    },
  ];
};
