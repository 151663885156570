import { Box, InputAdornment, MenuItem, Modal, useTheme } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

import {
  useValidateEmail,
  useValidatePhone,
  useRegexValidatePhone,
} from 'utils/formValidation';

import { ContinueButton } from 'components/Onboarding';

import { ReactComponent as XIcon } from 'images/profile/add-reporter/x.svg';
import { ReactComponent as PhoneIcon } from 'images/profile/phone.svg';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { ReactComponent as UserIcon } from 'images/profile/user-circle.svg';
import { relationships } from 'constants/reporters';
import getStyles, {
  DropDownIcon,
  InfoDetail,
  InfoIcon,
  InfoWrapper,
} from './styles';
import { FIELD_TYPE_RENDERERS_MAP } from '../../../../utils/configParseUtils';
import {useSelector} from "ihp-bloom-redux/app/redux";

export const EditReporterModal = ({
  open,
  onClose,
  reporter,
  onUpdateReporter,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const validatePhone = useRegexValidatePhone();
  const validateEmail = useValidateEmail();
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const country =
      activeAccountProfile?.attributes?.person?.attributes?.country;

  const customPhoneRenderer = FIELD_TYPE_RENDERERS_MAP['phone'];

  const {
    control,
    getValues,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({
    defaultValues: {
      id: reporter?.id ?? 0,
      first_name: reporter?.first_name ?? '',
      last_name: reporter?.last_name ?? '',
      relationship: reporter?.relationship ?? '',
      phone: reporter?.phone ?? '+',
      email: reporter?.email ?? '',
      confirm_email: reporter?.email ?? '',
      reporter_consent: reporter?.reporter_consent ?? '',
    },
  });

  const onSubmit = async (data) => {
    if (data.email != data.confirm_email) {
      setError('confirm_email', { message: 'Incorrect email' });
    } else {
      onUpdateReporter(data);
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>Edit reporter</Box>
          <Box sx={styles.iconWrapper}>
            <XIcon width='13px' height='20px' onClick={(e) => onClose()} />
          </Box>
        </Box>
        <Box sx={styles.formWrapper}>
          <form>
            <Box sx={styles.fieldsRow}>
              <Box sx={styles.firstNameFieldContainer}>
                <Controller
                  name='first_name'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='first_name'
                      label='First name'
                      error={errors.first_name}
                      errorLabel={errors.first_name?.message}
                      fullWidth={true}
                      placeholder='Enter first name'
                      endAdornment={
                        <InputAdornment position='end'>
                          <UserIcon style={styles.inputIcon} />
                        </InputAdornment>
                      }
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box sx={styles.lastNameFieldContainer}>
                <Controller
                  name='last_name'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='last_name'
                      label='Last name'
                      error={errors.last_name}
                      errorLabel={errors.last_name?.message}
                      fullWidth={true}
                      placeholder='Enter last name'
                      endAdornment={
                        <InputAdornment position='end'>
                          <UserIcon style={styles.inputIcon} />
                        </InputAdornment>
                      }
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: true,
                  validate: validateEmail,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    label='Email'
                    error={errors.email}
                    errorLabel={errors.email?.message}
                    fullWidth={true}
                    endAdornment={
                      <InputAdornment position='end'>
                        <EmailIcon style={styles.inputIcon} color='#D9D9D9' />
                      </InputAdornment>
                    }
                    placeholder='Enter email address'
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='confirm_email'
                control={control}
                rules={{
                  required: true,
                  validate: validateEmail,
                }}
                render={({ field }) => (
                  <Input
                    name='confirm_email'
                    label='Confirm Email'
                    error={errors.confirm_email}
                    errorLabel={errors.confirm_email?.message}
                    fullWidth={true}
                    endAdornment={
                      <InputAdornment position='end'>
                        <EmailIcon style={styles.inputIcon} color='#D9D9D9' />
                      </InputAdornment>
                    }
                    placeholder='Enter email address'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phone'
                control={control}
                rules={{
                  required: false,
                  validate: (value) => validatePhone(value, country)
                }}
                render={({ field }) =>
                  customPhoneRenderer({
                    errorLabel: errors?.phone?.message,
                    error: errors.phone,
                    country,
                    type: 'text',
                    name: 'phone',
                    label: 'Phone (Optional)',
                    placeholder: 'Enter phone',
                    ...field,
                  })
                }
              />
            </Box>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='relationship'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    type='select'
                    error={errors.relationship}
                    errorLabel={errors.relationship?.message}
                    label='Select relation to patient'
                    fieldLabel='Relation to patient'
                    placeholder='Select relation to patient'
                    fullWidth
                    value={getValues('relationship')}
                    IconComponent={(props) => <DropDownIcon {...props} />}
                    {...field}
                  >
                    {relationships?.map(({ id, name, key }) => (
                      <MenuItem key={id} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='reporter_consent'
                control={control}
                haserrors={errors.reporter_consent}
                render={({ field }) => (
                  <Input
                    error={errors.reporter_consent}
                    errorLabel={errors.reporter_consent?.message}
                    type='checkbox'
                    label='Check if reporter can be used as a secondary contact for your account'
                    checked={getValues('reporter_consent')}
                    containerStyle={styles.checkboxContainer}
                    size='medium'
                    {...field}
                  />
                )}
              />
            </Box>

            <InfoWrapper>
              <InfoIcon />
              <InfoDetail>
                A Secondary Contact is someone that the registry team can reach
                out to for communication regarding this account.
              </InfoDetail>
            </InfoWrapper>
          </form>
        </Box>

        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.continueButton}>
            <ContinueButton
              sx={styles.continueButton}
              onClick={handleSubmit(onSubmit)}
            >
              Save Contact
            </ContinueButton>
            <Button
              variant='link'
              sx={styles.cancelLink}
              onClick={(e) => onClose()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
