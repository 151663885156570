import { STUDY_ARM_ROLE_NAME } from 'constants/global';

export const ACTION_TYPES = {
  QUESTION_UPDATE: 'QUESTION_UPDATE',
};

export const INITIAL_STATE = {
  answers: {},
};

export const reducerFn = (state, action) => {
  switch (action.type) {
    case 'QUESTION_UPDATE': {
      return {
        ...state,
        answers: { ...state.answers, ...action.payload },
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export const isCaregiver = (roleName) => {
  return (
    roleName === STUDY_ARM_ROLE_NAME.REPORTER ||
    roleName === STUDY_ARM_ROLE_NAME.REPORTER ||
    roleName === STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT
  );
};
