import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 *
 * @param {number} defaultTabIndex
 * @returns void
 * @description Detects the 'tab' query param and sets the current tab to the tab whose index matches search param
 */

export const useTabs = (defaultTab) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

  useEffect(() => {
    if (currentTab) {
      setSelectedTab(Number(currentTab));
    }
  }, [currentTab]);

  return {
    selectedTab,
    setSelectedTab,
  };
};
