import { isEmpty } from 'lodash';
import { isMobile } from 'utils/isMobile';
import {
  convertDateStringToLocalDateObject,
  convertUTCDateToLocalDate,
  convertUTCStringToLocalDateObject,
  dateFormatter,
} from 'utils/date';

const orderedWeekDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/* This helper function will convert 24 hours time format into 12 housr format with AM / PM*/
export const timeConverter = (time, endTime) => {
  // make sure if time/endTime is like 24:00:00 then change it to 00:00:00
  let timeComponents = time?.split(':');
  let endTimeComponents = endTime?.split(':');
  if (timeComponents?.[0] >= 24) {
    timeComponents[0] = '00';
    time = timeComponents.join(':');
  }

  if (endTimeComponents?.[0] >= 24) {
    endTimeComponents[0] = '00';
    endTime = endTimeComponents.join(':');
  }

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-4])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];
  endTime =
    endTime?.toString()?.match(/^([01]\d|2[0-4])(:)([0-5]\d)(:[0-5]\d)?$/) ||
    endTime;

  if (time?.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  if (endTime?.length > 1) {
    // If time format correct
    endTime = endTime.slice(1); // Remove full string match value
    endTime[5] = +endTime[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    endTime[0] = +endTime[0] % 12 || 12; // Adjust hours
  }

  if (endTime) {
    return `${time[0]}${time[1]}${time[2]}${time[time.length - 1]} - ${
      endTime[0]
    }${endTime[1]}${endTime[2]}${endTime[endTime.length - 1]}`; // return adjusted time or original string
  }
  return `${time[0]}${time[1]}${time[2]}${time[time.length - 1]}`; // return adjusted time or original string
};

export const flatPayload = (payload) =>
  payload.map(({ weekly_periods, profile_id }) =>
    weekly_periods.map((period) => ({ ...period, profile_id }))
  );

/* This helper method will convert day to date after tomorrow */
export const transformDayToDate = (payload) => {
  let lastBookedDate = new Date();

  return payload.map((slot) => {
    const next7days = getNext7Days(lastBookedDate);

    const targetDate = next7days.find(
      (date) => orderedWeekDays[date.getDay()] === slot.day
    );

    lastBookedDate = targetDate;

    return {
      ...slot,
      date: dateFormatter(targetDate),
    };
  });
};

/* This helper method will convert flatten payload to a has map */
export const mapPayload = (payload) =>
  payload
    .reduce((array, profile) => array.concat(profile), [])
    .reduce(
      (obj, { date, ...data }) => ({
        ...obj,
        [date]: [...(obj[date] || []), data],
      }),
      {}
    );

export const findOccurrences = (array) =>
  array.reduce(
    (map, item) => ({
      ...map,
      [`${item.day}-${item.start_time}`]:
        ([`${item.day}-${item.start_time}`] || 0) + 1,
    }),
    {}
  );

/* This helper method will be used to map time against profile_ids: e.g: 
  {
    "19:00": ['1', '2', ...]
  }
*/

export const timeMap = (array) =>
  array.reduce(
    (map, item) => ({
      ...map,
      [item.start_time]: map[item.start_time]
        ? [...map[item.start_time], item.profile_id]
        : [item.profile_id],
    }),
    {}
  );

export const getRandomIndex = (max) => {
  return Math.floor(Math.random() * (max - 1 + 1)) + 1;
};

/* This helper method will randomly remove duplicated slots of same time for multiple users,
When more than one users have same time for selected date than show one of them randomly
*/
export const randomlyRemoveDuplicatedSlots = (slots) => {
  const timesMap = timeMap(slots);
  const uniqueVals = [...new Set(slots.map((item) => item.start_time))];
  const filteredSlots = uniqueVals.reduce((prev, current) => {
    const duplicatedSlots = timesMap[current].length;
    const random = getRandomIndex(duplicatedSlots);
    prev.push({
      start_time: current,
      profile_id: timesMap[current][duplicatedSlots > 1 ? random - 1 : 0],
    });
    return prev;
  }, []);

  return filteredSlots;
};

const getNext7Days = (date) => {
  return [...Array(7).keys()].map((i) => {
    const currentDate = new Date();
    currentDate.setDate(date.getDate() + (i + 1));
    return currentDate;
  });
};

// REMOVE ME: not being used anywhere
export const extractFormatedData = (callDetails) => {
  const callDate = new Date(callDetails.event_start_datetime_utc);
  const timeString = callDate.toLocaleTimeString();
  const timePieces = timeString.split(':');
  timePieces.pop();
  const time = timeConverter(timePieces.join(':'));
  const date = dateFormatter(callDate, '.');
  const dayString = orderedWeekDays[callDate.getDay()];
  const humanizedDate = formatHumanizedDate(
    callDetails.event_start_datetime_utc
  );

  return { date, time, dayString, humanizedDate };
};

export const formatHumanizedDate = (dateString) => {
  const date = new Date(dateString);
  let dayString = orderedWeekDays[date.getDay()];
  dayString = dayString[0].toUpperCase() + dayString.substring(1);
  let monthString = monthNames[date.getMonth()];
  monthString = monthString[0].toUpperCase() + monthString.substring(1);
  const humanizedDate = `${dayString}, ${date.getDate()} ${monthString} ${date.getFullYear()}`;
  return humanizedDate;
};

/* We will get data type of a question from questions list */
export const extractQuestionType = (questionId, questions) => {
  const targetQuestion = questions.find(
    (question) => question.id === questionId
  );
  return targetQuestion.questionType;
};

/* This is a formatter for payload of an activity */
export const formatActivityJsonPayload = (
  userId,
  patientTaskId,
  participantId,
  taskId,
  taskType,
  content,
  questions,
  compensationType,
  compensationProdut,
  compensationValue
) => {
  const json = {
    user_id: userId,
    participant_id: participantId,
    patient_task_id: patientTaskId,
    task_id: taskId,
    study_version_id: 4,
    status: 'completed',
    data: {
      task_id: taskId,
      study_version_id: 4,
      status: 'completed',
      compensation: {
        compensationType,
        compensationValue,
        compensationProdut,
      },
      content: [],
    }, // this will be json of users feedback
  };

  if (taskType === P_TASK_TYPES.SURVEY || taskType === P_TASK_TYPES.VIDEO) {
    Object.entries(content.answers).forEach(([key, value]) => {
      const contentItem = {
        id: key,
        dataType: extractQuestionType(key, questions),
        values: [
          {
            value,
          },
        ],
      };
      json.data.content.push(contentItem);
    });
  } else if (taskType === 'teleresearch') {
    json.data.content.push(content);
  }
  json.data = JSON.stringify(json.data);
  return json;
};

const groupDaySlots = (currentSlot, localStartTime, localEndTime) => {
  const map = {
    morning: [],
    afternoon: [],
  };
  const nativeDate = convertDateStringToLocalDateObject(currentSlot.start_time);
  const curHr = nativeDate.getHours();
  if (curHr <= 11) {
    map.morning = [
      {
        ...currentSlot,
        start_time: localStartTime,
        end_time: localEndTime,
      },
    ];
  } else {
    map.afternoon = [
      {
        ...currentSlot,
        start_time: localStartTime,
        end_time: localEndTime,
      },
    ];
  }
  return map;
};

/*
  This function will get a list of available time slots coming from api and return data in the following format
  - Response:
  {
    "2023-05-18": {
      morning: [{start_time: "08:00:00", end_time: "09:00:00"}],
      afternoon: [{start_time: "16:00:00", end_time: "17:00:00"}]
    },
    ...
  }
*/
export const formatAvailableSlots = (data, timezone, min_start_time) => {
  let groupedSlots = {};
  // first make sure to skip any slot that is before min_start_time to avoid past slots
  data = data?.filter(
    (slot) => convertUTCStringToLocalDateObject(slot.start_time) > new Date()
  );
  // first convert utc slots to local time (users selected time zone) slots
  data = data?.map((slot) => ({
    utc_start_time: slot.start_time,
    utc_end_time: slot.end_time,
    start_time: convertUTCDateToLocalDate(slot?.start_time, timezone),
    end_time: convertUTCDateToLocalDate(slot?.end_time, timezone),
  }));
  console.log('Converted slots: ', data);
  // Now group local time slots to morning and afternoon
  groupedSlots = data.reduce((previousResponse, slot) => {
    let map = { ...previousResponse };
    const [startDate, startTime] = slot?.start_time.split(' '); // e.g: ["2023-05-18", "16:00:00"] = "2023-05-18 16:00:00"
    const [, endTime] = slot?.end_time.split(' ');

    if (!map[startDate]) {
      map[startDate] = {
        morning: [],
        afternoon: [],
      };
      const dayGroup = groupDaySlots(slot, startTime, endTime);
      map[startDate].morning = dayGroup.morning;
      map[startDate].afternoon = dayGroup.afternoon;
    } else {
      previousResponse[startDate] = {
        ...previousResponse[startDate],
      };
      const dayGroup = groupDaySlots(slot, startTime, endTime);
      map[startDate].morning = [...map[startDate].morning, ...dayGroup.morning];
      map[startDate].afternoon = [
        ...map[startDate].afternoon,
        ...dayGroup.afternoon,
      ];
    }
    return map;
  }, {});

  return groupedSlots;
};

export const getColors = (isOverdue, isPast) => {
  // TODO: refactor this with colors from the theme palette
  let actionButtonBGColor = '#F8F2F7';
  let actionButtonColor = '#A0438B';
  let rewardColor = isOverdue ? 'white' : '#A0438B';
  let detailsColor = isOverdue ? 'white' : '#2B3A41';
  let rewardBGColor = 'white';
  let statusColor = '#959CA0';
  if (isMobile()) {
    rewardBGColor = isOverdue ? '#A0438B' : '#F8F2F7';
    rewardColor = isOverdue ? 'white' : '#A0438B';
    actionButtonColor = isOverdue ? '#A0438B' : 'white';
    actionButtonBGColor = isOverdue ? 'white' : '#A0438B';
  }

  if (isPast) {
    detailsColor = '#F8F2F7';
    rewardColor = '#A0438B';
  }

  return {
    actionButtonBGColor,
    actionButtonColor,
    rewardColor,
    rewardBGColor,
    statusColor,
    detailsColor,
  };
};

export const getTaskAttribute = (task, attribute, attributeKey) => {
  let foundAttribute = task?.attributes?.filter(
    (a) => a.attribute === attribute
  );
  if (foundAttribute && foundAttribute.length > 0 && foundAttribute[0]?.value) {
    if (attribute === 'content') {
      return foundAttribute[0]?.value;
    }
    return JSON.parse(foundAttribute[0]?.value)[attributeKey];
  }
  return null;
};

export const getTaskActionText = (taskMeta) => {
  if (taskMeta) {
    let meta = JSON.parse(taskMeta);
    return meta?.button_name;
  }
  return 'Details';
};

export const getTaskDuration = (taskDuration) => {
  if (taskDuration) {
    let duration = JSON.parse(taskDuration);
    if (duration) {
      const timingInMinutes = duration.timing_duration / 60;
      var durationInMinutes = formatMinutes(timingInMinutes);
      return durationInMinutes;
    }
  }
  return '';
};

export function formatMinutes(minutes) {
  minutes = Number(minutes);
  var d = Math.floor(minutes / (60 * 24));
  var h = Math.floor((minutes % (60 * 24)) / 60);
  var m = Math.floor(minutes % 60);

  if (d > 0) {
    if (h > 12) {
      return `${d + 1} days`;
    } else {
      return d + (d === 1 ? ' day' : ' days');
    }
  }

  if (h > 0) {
    if (m > 30) {
      return `${h + 1} hours`;
    } else {
      return h + (h === 1 ? ' hour' : ' hours');
    }
  }

  return m + (m === 1 ? ' min ' : ' mins');
}

export const P_TASK_TYPES = {
  SURVEY: 'survey',
  DATA_SHARING: 'data-sharing',
  CONSENT: 'consent',
  PROFILE: 'profile',
  ARTICLE: 'article',
  MAKE_TELEHEALTH: 'make-telehealth',
  MAKE_TELEVISIT: 'make-televisit',
  SCHEDULE_TELEHEALTH: 'schedule-telehealth',
  SCHEDULE_TELEHVISIT: 'schedule-televisit',
  VIDEO: 'video',
};

export const getColor = (type) => {
  if (type === P_TASK_TYPES.SURVEY) return '#F6FBF5';
  if (type === P_TASK_TYPES.DATA_SHARING) return '#4381A3';
  if (type === P_TASK_TYPES.CONSENT) return '#A0438B';
  if (type === P_TASK_TYPES.PROFILE) return '#F3FCFB';
  if (type === P_TASK_TYPES.ARTICLE) return '#FFF9F4';
  if (type === P_TASK_TYPES.MAKE_TELEHEALTH) return '#F3FCFB';
  if (type === P_TASK_TYPES.MAKE_TELEVISIT) return '#F3FCFB';
  if (type === P_TASK_TYPES.SCHEDULE_TELEHEALTH) return '#F3FCFB';
  if (type === P_TASK_TYPES.SCHEDULE_TELEHVISIT) return '#F3FCFB';
  if (type === P_TASK_TYPES.VIDEO) return '#FEFBF5';

  return 'white';
};

/*
  Temporarily we are putting these ui settings here,
  eventually these ui settings will be coming from backend
  when these can be set from configUI
*/
export const iconBgColorMap = {
  survey: '#E5EEF3',
  consent: 'white',
  'data-sharing': '#80AAC2',
  teleresearch: '#80AAC2',
  video: '#F6D895',
  game: '#FDC48C',
  poll: '#83DFD9',
};

const formatCallDate = (date, separatorSymbol = '-') => {
  let d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join(separatorSymbol);
};

/*
  input dateString: example 2023-09-12
  output dayString: Monday
 */
const getDayFromDate = (dateString) => {
  const [year, month, date] = dateString?.split('-');
  const dateObj = new Date(year, parseInt(month) - 1, date);
  return orderedWeekDays[dateObj?.getDay()];
};

export const formatCallDetails = (data, timezone) => {
  if (!data?.startDate) {
    return {};
  }
  // date converted to users selected timezone
  let localDateTime = convertUTCDateToLocalDate(data?.startDate, timezone);
  const dateTimeData = localDateTime?.split(' ');
  const originalDate = new Date(dateTimeData?.[0]);
  const date = formatCallDate(originalDate, '.');

  // Split only time
  const originalTime = localDateTime?.split(' ')?.[1];
  const time = timeConverter(originalTime);
  const dayString = getDayFromDate(dateTimeData?.[0]); // orderedWeekDays[originalDate?.getDay()];
  return { date, time, dayString };
};

export const capitalize = (word) => {
  return word?.[0]?.toUpperCase() + word?.substring(1);
};

export const taskTypeLabelMapping = {
  data_sharing: 'Medical Record',
  survey: 'Survey',
  call: 'Appointment',
  call_schedule: 'Appointment Scheduling',
  article: 'Article',
  video: 'Video',
  profile: 'Profile',
  consent: 'Consent',
};

export const getUploadFields = (jsonObj) => {
  const fieldsWithFiles = [];

  for (let key in jsonObj) {
    if (
      Array.isArray(jsonObj[key]) &&
      jsonObj[key].length > 0 &&
      jsonObj[key][0].hasOwnProperty('fileType')
    ) {
      fieldsWithFiles.push(key);
    }
  }

  return fieldsWithFiles;
};

export const isFilterApplied = (filerConfigurations) => {
  return (
    !filerConfigurations.selectedTypes.includes('show_all') ||
    !isEmpty(filerConfigurations.endDate) ||
    !isEmpty(filerConfigurations.startDate)
  );
};

export const surveyDocumentTypesMap = (taskCode) => {
  switch (true) {
    case taskCode.toLowerCase().startsWith('lab'):
      return 'labs';
    case taskCode.toLowerCase().startsWith('ba'):
      return 'autopsy';
  }
  return 'enrollment_document';
};
