import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  borderRadius: '8px',
  boxShadow: '0 0 9px rgba(0, 0, 0, 0.04)',
}));

export const Edge = styled(Box)({
  padding: '12px 15px',
  display: 'flex',
  alignItems: 'center',
});
