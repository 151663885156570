import { styled } from '@mui/material/styles';
import {
  FormControl,
  FormLabel,
  InputAdornment,
  InputBase,
} from '@mui/material';
import { SIZE_STYLES } from './config';

export const StyledFormControl = styled(FormControl)(({ fullWidth, size }) => ({
  width: !fullWidth ? 'auto' : '100%',
}));

export const StyledInputStartAdornment = styled(InputAdornment)(({ size }) => ({
  marginRight: '0px',
  paddingLeft: SIZE_STYLES[size]?.paddingTop,
  '& svg': {
    width: '20px',
  },
}));

export const StyledEndInputAdornment = styled(InputAdornment)(({ size }) => ({
  paddingRight: SIZE_STYLES[size]?.paddingTop,
  '& svg': {
    width: '20px',
  },
}));

export const StyledInputBase = styled(InputBase)(
  ({ theme, size = 'md', fullWidth, width, endIcon, startIcon }) => {
    return {
      '&.MuiInputBase-root': {
        width: !fullWidth ? width || SIZE_STYLES[size]?.width : '100%',
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.primary.darkGray70,
      },
      '& .MuiInputBase-input': {
        ...SIZE_STYLES[size],
        paddingRight: endIcon != null ? '0px' : SIZE_STYLES[size]?.paddingRight,
        paddingLeft: startIcon != null ? '0px' : SIZE_STYLES[size]?.paddingLeft,
        width: !fullWidth ? width || SIZE_STYLES[size]?.width : '100%',
        color: theme.palette.primary.gray100,
        gap: '10px',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: '17px',
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',

        '&::placeholder': {
          color: theme.palette.primary.darkGray50,
          opacity: 1,
        },
      },

      backgroundColor: theme.palette.primary.white,
      border: `1px solid ${theme.palette.primary.lightGray100}`,
      borderRadius: '8px',

      boxSizing: 'border-box',

      resize: 'none',
      '&:hover': {
        borderColor: theme.palette.primary.darkGray50,
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.darkBlue70,
        placeholder: theme.palette.primary.darkGray50,
      },
      '&.Mui-error': {
        border: `1px solid ${theme.palette.primary.red100}`,
      },
    };
  }
);

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '16.94px',
  marginBottom: '8px',
  '&.Mui-focused': {
    color: theme.palette.primary.darkGray70,
  },
}));
