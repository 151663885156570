import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mainLogo: {},
  shortLogo: {},
  backgroundImage: {},
  headingFont: '',
  textFont: '',
  primaryColor: '',
  secondaryColor: '',
  fontColor: '',
  borderColor: '',
};

export const uiComponentsSlice = createSlice({
  name: 'uiComponents',
  initialState,
  reducers: {
    setValue: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
  },
});

export const { setValue } = uiComponentsSlice.actions;

export default uiComponentsSlice.reducer;
