import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { logout as localLogout } from 'ihp-bloom-redux/features/authentication/authenticationSlice';

import Button from 'ihp-components/components/v2/Button';
import {
  VerifyEmailWrapper,
  Container,
  Row,
  TitleContainer,
  ButtonContainer,
  PageTitle,
  HelperText,
} from './styles';
import { ReactComponent as ExclamationCircle } from 'images/shared/exclamation-circle.svg';
import { useEffect } from 'react';

function Auth0EmailVerify() {
  document.title = 'Single Ventricle SOURCE Verify Your Email Address';

  const params = useLocation();
  const navigate = useNavigate();

  const { user, logout } = useAuth0();

  const dispatch = useDispatch();

  const handleBackToHome = async () => {
    logout({
      returnTo: `${window.location.origin}/onboarding/welcome-to-study?open-auth0=signup`,
    });
    dispatch(localLogout());
    sessionStorage.clear();
  };

  const verify = () => {
    const queryParams = new URLSearchParams(params.search);
    const url = queryParams.get('url');

    window.location.replace(url);
  };

  useEffect(() => {
    if (user?.email_verified) {
      return navigate('/auth0-callback');
    }
  }, [user, navigate]);

  return (
    <VerifyEmailWrapper>
      <Container>
        <Row>
          <ExclamationCircle width='295' height='181' />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>You’re almost there</PageTitle>
        </TitleContainer>
        <Row>
          <HelperText varient='pl3'>
            Complete email verification by pressing ‘Verify’ below.
          </HelperText>
        </Row>
        <ButtonContainer>
          <Button onClick={verify} fullWidth>
            Verify
          </Button>
        </ButtonContainer>
      </Container>
    </VerifyEmailWrapper>
  );
}

export default Auth0EmailVerify;
