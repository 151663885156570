import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: '900',
  marginBottom: '12px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '28px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
  },
}));

export const ListText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '600',
  marginTop: '-6px',
  lineHeight: '27px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '18px',
  },
}));

export const ResponsiveGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '60px 100px',
  [theme.breakpoints.down('xl')]: {
    padding: '112px 100px',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '40px 100px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '30px 20px',
  },
}));
