import { forwardRef, useState } from 'react';
import { Box, TextareaAutosize, InputLabel } from '@mui/material';
import { styled } from '@mui/system';
import { StyledFormHelperText } from 'components/Input/TextField.styles';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  border: 0,
  width: `100%`,
  maxWidth: `100%`,
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  '::placeholder': {
    color: theme.palette.primary.gray50,
    fontSize: 14,
  },
  '& :focus, :focus-visible': {
    border: 0,
    outline: 'none',
  },
}));
/**
 const borderColor = haserrors
      ? theme.palette.primary.red75
      : theme.palette.primary.gray15;
 */
const TextAreaWrapper = styled(({ isMouseIn, isActive, ...rest }) => (
  <Box {...rest} />
))(({ theme, isMouseIn, isActive, hasError }) => ({
  border: `1px solid ${
    hasError
      ? theme.palette.primary.red75
      : isActive
      ? theme.palette.primary.fuchsia75
      : isMouseIn
      ? theme.palette.primary.gray50
      : theme.palette.primary.gray25
  }`,
  padding: 13,
  borderRadius: 8,
  backgroundColor: theme.palette.primary.white,
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontWeight: '500',
  fontSize: '14px',
  marginBottom: '8px',
}));

export const Textarea = forwardRef(
  (
    {
      label,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      placeholder,
      error,
      wrapperStyle,
      ...props
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);
    const [isMouseIn, setIsMouseIn] = useState(false);

    return (
      <Box>
        <StyledInputLabel>{label}</StyledInputLabel>
        <TextAreaWrapper
          isActive={isActive}
          isMouseIn={isMouseIn}
          hasError={!!error}
          sx={wrapperStyle}
        >
          <StyledTextArea
            ref={ref}
            {...props}
            onFocus={() => {
              setIsActive(true);
              onFocus?.(); //for external use same for the rest below
            }}
            onBlur={() => {
              setIsActive(false);
              onBlur?.();
            }}
            onMouseEnter={() => {
              setIsMouseIn(true);
              onMouseEnter?.();
            }}
            onMouseLeave={() => {
              setIsMouseIn(false);
              onMouseLeave?.();
            }}
            minRows={6}
            placeholder={placeholder || 'Enter text...'}
          />
        </TextAreaWrapper>
        {error && (
          <StyledFormHelperText>
            <InformationIcon />
            {error}
          </StyledFormHelperText>
        )}
      </Box>
    );
  }
);
