import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Left, Right, Container } from './styles';

export const ResponsiveSideBySideLayout = ({
  children,
  isParentActive,
  ...rest
}) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Container>
      {(smUp || isParentActive) && <Left>{children}</Left>}
      <Right>
        <Outlet />
      </Right>
    </Container>
  );
};
