
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const personStudyArmApiSlice = createApi({
  reducerPath: 'personStudyArmApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['PersonStudyArm'],
  endpoints: (build) => ({
    getAllPersonStudyArms: build.query({
      query: ({ participantId, ...params }) => ({
        url: `/people/${participantId}/study-arms`,
        method: 'GET',
        params,
      }),
      providesTags: ['PersonStudyArms'],
    }),
    getPersonStudyArm: build.query({
      query: ({ studyArmId }) => ({
        url: `/study-arms/${studyArmId}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'PersonStudyArm', id: arg }]
    }),
    createPersonStudyArm: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/people/${personId}/study-arms`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['PersonStudyArms']
    }),
    updatePersonStudyArm: build.mutation({
      query: ({ personStudyArmId, payload }) => ({
        url: `/person-study-arms/${personStudyArmId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'PersonStudyArm', id: arg.id }, 'PersonStudyArms']
    }),
  }),
});

export const {
  useGetAllPersonStudyArmsQuery,
  useGetPersonStudyArmQuery,
  useCreatePersonStudyArmMutation,
  useUpdatePersonStudyArmMutation,
} = personStudyArmApiSlice;
