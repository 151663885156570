import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as Completed } from 'images/shared/done-checkmark.svg';
import {
  WelcomeAboardWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
} from './styles';

function WelcomeAboard() {
  document.title = 'Single Ventricle SOURCE Welcome Aboard';

  const { logout } = useAuth0();

  const onContinue = () => {
    logout({ localOnly: true });
    window.location.href = '/onboarding/basic-information';
  };

  return (
    <WelcomeAboardWrapper>
      <Container>
        <Row>
          <Completed width={200} height={198} />
        </Row>
        <Row marginBottom='10px'>
          <PageTitle>Welcome!</PageTitle>
        </Row>
        <Row marginBottom='30px'>
          <HelperText varient='pl3'>
            Thank you for verifying your email and welcome aboard!
          </HelperText>
        </Row>
        <Row>
          <Button onClick={onContinue} fullWidth>
            Continue
          </Button>
        </Row>
      </Container>
    </WelcomeAboardWrapper>
  );
}

export default WelcomeAboard;
