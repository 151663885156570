const styles = {
  timeline: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  icon: {
    width: 40,
    height: 40,
  },
  label: {
    paddingLeft: '10px',
  },
  labelWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};
export default styles;
