import { Box } from '@mui/system';
import { ErrorMessage } from './styles';
import { ReactComponent as InformationIcon } from 'images/components/information.svg';

export const ErrorWrapper = ({ children, manualMessage, ...rest }) => {
  let message = manualMessage ? manualMessage : 'This field is required';
  if (children?.props?.haserrors?.type === 'custom') {
    message = children.props.haserrors.message;
  }
  if (children?.props?.hasErrors?.type === 'custom') {
    message = children.props.hasErrors.message;
  }
  return (
    <Box {...rest}>
      {children}
      {(children?.props?.haserrors || children?.props?.hasErrors) && (
        <ErrorMessage>
          <InformationIcon />
          {message}
        </ErrorMessage>
      )}
    </Box>
  );
};
