export const wizard = {
  form: `
    <div class="main-container">
      <div class="wizard-header">
        <div class="wizard-header-content">
          <div id="wizard-back-btn" class="wizard-back-btn"></div>
          <div class="wizard-title">
            <span>{{ t(form.title) }}</span>
          </div>
          <div class="wizard-details">
            <span class="wizard-details-type">
              <i class="wizard-details-type-icon"></i>
              {{ form.type }}
            </span>
            
            <span class="wizard-details-required-time">
              <i class="wizard-details-required-time-icon"></i>
              {% if (form.estimatedTime) { %}
                {{ form.estimatedTime  }} min
              {% } else { %}
                N/A
              {% } %}
            </span>

            {% if (form.timeLimit) { %}
              <span class="wizard-details-allowed-time">
                <i class="wizard-details-allowed-time-icon"></i>
                {% if (form.timeLimit) { %}
                  {{ form.timeLimit  }} min
                {% } else { %}
                  N/A
                {% } %}
              </span>
            {% } %}
            
            <span class="wizard-details-reward">
              <i class="wizard-details-reward-icon"></i>
              {{ form.rewardPoints }}
            </span>
          </div>
        </div>
        <div class="wizard-progress">
          <div
            class="wizard-completed"
            style="width: {{ ((currentPage + 1) * 100) / panels.length }}%"
            ></div>
        </div>
      </div>
      <div class="wizard-content">
        {% { %}
          {% const {title, description} = panels[currentPage] %}
          {% if (title || description) { %}
            <div class="wizard-page-header">
              {% if (title) { %}
                <div class="wizard-page-title">
                  <span>{{ title }}</span>
                </div>
              {% } %}
              {% if (description) { %}
                <div class="wizard-page-description">
                  <span>{{ description }}</span>
                </div>
              {% } %}
            </div>
          {% } %}
        {% } %}
        <div class="wizard-page" ref="{{ wizardKey }}">
          {{ components }}
        </div>
      </div>
      <div class="wizard-footer">
        
      {% if (form.isMobile) { %}  
        {% if (buttons.next) { %}
          <button id="btn-wizard-nav-custom-next"
            class="btn btn-primary btn-wizard-nav-next-only"
            ref="{{ wizardKey }}-next"
          >
            {% if (currentPage === 0 && panels.length === 1) { %}
              Submit
            {% } else { %}  
              Next
              <div class="btn-wizard-icon btn-wizard-nav-next-icon"></div>
            {% } %}
          </button>
        {% } %}

        {% if (buttons.submit) { %}
          <button
            class="btn btn-primary btn-wizard-nav-submit btn-wizard-nav-next-only"
            ref="{{ wizardKey }}-submit"
          >
            Submit
          </button>
        {% } %}
        {% if (buttons.previous) { %}
          <button
            class="btn btn-secondary btn-wizard-nav-previous"
            ref="{{ wizardKey }}-previous"
          >
            <div class="btn-wizard-icon btn-wizard-nav-previous-icon"></div>
            {% if (currentPage === 0) { %}
              Back
            {% } else { %}  
              Back
            {% } %}
          </button>
        {% } %}
        <button
          id="btn-wizard-nav-cancel"
          class="btn btn-secondary btn-wizard-nav-cancel"
          >
          Cancel
        </button> 
      {% } %}

      {% if (!form.isMobile) { %}
        <button
          id="btn-wizard-nav-cancel"
          class="btn btn-secondary btn-wizard-nav-cancel"
        >
          Cancel
        </button>
        {% if (buttons.previous) { %}
          <button
            class="btn btn-secondary btn-wizard-nav-previous"
            ref="{{ wizardKey }}-previous"
          >
            <div class="btn-wizard-icon btn-wizard-nav-previous-icon"></div>
            {% if (currentPage === 0) { %}
              Back
            {% } else { %}  
              Back
            {% } %}
          </button>
        {% } %}
        {% if (buttons.next) { %}
          <button id="btn-wizard-nav-custom-next"
            class="btn btn-primary btn-wizard-nav-next"
            ref="{{ wizardKey }}-next"
          >
            {% if (currentPage === 0 && panels.length === 1) { %}
              Submit
            {% } else { %}  
              Next
              <div class="btn-wizard-icon btn-wizard-nav-next-icon"></div>
            {% } %}
          </button>
        {% } %}
        {% if (buttons.submit) { %}
          <button
            class="btn btn-primary btn-wizard-nav-submit"
            ref="{{ wizardKey }}-submit"
          >
            Submit
            
          </button>
        {% } %}
      {% } %}
      </div>
    </div>
  </div>
`,
};
