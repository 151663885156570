import {
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Header } from 'components/Header';
import {
  ListContainer,
  ListContent,
  SearchBox,
  DesktopWrapper,
  HeaderSection,
} from './styles';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import Logo1upHealth from 'images/providers/1up-logo.svg';
import LogoEpic from 'images/providers/epic-logo.svg';
import LogoCerner from 'images/providers/cerner-logo.svg';

import { ListItem } from './ListItem';
import { useNavigate } from 'react-router-dom';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { getRewardsInfo } from 'components/Payment/Rewards/util';

const dataProviders = [
  {
    title: '1up Health',
    description: 'Sapien, tempus facilisi tellus aliquam eu, sed.',
    icon: Logo1upHealth,
    alt: '1up health logo',
  },
  {
    title: 'Epic Medical Center',
    description: 'Viverra laoreet massa aliquet quis pharetra eu sit.',
    icon: LogoEpic,
    alt: 'epic medical center logo',
  },
  {
    title: 'Cerner Health Systems',
    description: 'Sapien, tempus facilisi tellus aliquam eu, sed.',
    icon: LogoCerner,
    alt: 'cerner health systems',
  },
];

export function List() {
  const navigate = useNavigate();
  const theme = useTheme();
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'));

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const { data } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const rewardsData = getRewardsInfo(data?.data);

  const handleItemClick = () => {
    navigate('/connect/complete');
  };

  return (
    <ListContainer>
      {!notMobile && <Header points={rewardsData?.balance} />}
      {!notMobile && (
        <SearchBox
          fullWidth
          placeholder='Search'
          startAdornment={
            <InputAdornment position='end' size='large'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      )}
      <ListContent>
        {notMobile && (
          <HeaderSection>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography variant='h2' align='center'>
                Select your data provider
              </Typography>
              <DesktopWrapper>
                <SearchBox
                  fullWidth
                  placeholder='Search'
                  startAdornment={
                    <InputAdornment position='end' size='large'>
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </DesktopWrapper>
            </Stack>
          </HeaderSection>
        )}
        {dataProviders.map((provider) => {
          return (
            <ListItem
              title={provider.title}
              description={provider.description}
              icon={provider.icon}
              handleClick={handleItemClick}
            />
          );
        })}
      </ListContent>
    </ListContainer>
  );
}

export default List;
