import { StaticDatePicker as MUIDatePicker } from '@mui/lab';
import { TextField } from '@mui/material';

import { DatepickerStyleWrapper } from './styles';

export const StaticDatePicker = ({
  width = '100%',
  padding = '0 22px',
  iconProps = { width: '35px', height: '35px' },
  customWeekdays = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  ...rest
}) => (
  <DatepickerStyleWrapper
    width={width}
    padding={padding}
    iconProps={iconProps}
    customWeekdays={customWeekdays}
  >
    <MUIDatePicker renderInput={props => <TextField {...props} />} {...rest} />
  </DatepickerStyleWrapper>
);
