import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';

import { InviteModel } from 'components/Invitation/InviteModel';
// import { Invitation } from 'components/Invitation';
import { InvitationCard } from 'components/Invitation/InvitationCard';

import Activities from './Activities';
import ExternalResearchOpportunity from './ExternalResearchOpportunity';
import EducationalMaterials from './EducationalMaterials';

import { STUDY_ARM_CODES } from 'constants/global';
import { eligibleCountries } from 'constants/country';

import { Container, CardContainer, UserPlusIcon, EnvelopeIcon } from './styles';
import RegistryStatisticsWidget from '../../components/RegistryStatistics/RegistryStatisticsWidget';
import { routes } from 'utils/navigator';

import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { withPersonAttributes } from 'hocs/withPersonAttributes';
import { withPerson } from 'authentication/withPerson';
// import { bestDescribeOptions } from 'pages/User/Create';
import NavHeader from 'pages/Home/NavHeader';
import { FamilyLinkageCard } from 'pages/Home/FamilyLinkageCard';
import { useIsActiveProfileContactRole } from 'features/profile/hooks/useIsActiveProfileContactRole';
import {ENROLL_ANOTHER_PARTICIPANTS} from 'constants/invitationCard';
import { HomePageWrapper } from './HomePageWrapper';

// const bestDescribeAttributes = Object.keys(bestDescribeOptions);

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isActiveProfileContactRole } = useIsActiveProfileContactRole();

  const [openSpreadTheWordModel, setOpenSpreadTheWordModel] = useState(false);

  const { activeAccountProfile, personAttributes } = useSelector(
    (state) => state.user
  );

  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;

  const personCountry =
    activeAccountProfile?.attributes?.person?.attributes?.country;

  const researchAllftdStudyArm =
    studyArmCode === STUDY_ARM_CODES.RESEARCH ||
    studyArmCode === STUDY_ARM_CODES.ALLFTD;

  // const contactStudyArm = studyArmCode === STUDY_ARM_CODES.CONTACT;

  const onClose = () => {
    setOpenSpreadTheWordModel(false);
  };

  const handleEnrollButton = () => {
    if (!eligibleCountries.includes(personCountry)) {
      navigate(routes['NOT-ELIGIBLE']);
    } else {
      dispatch(setValues({ isEnrollingNewPerson: true }));
      navigate('/onboarding/research-eligibility/role');
    }
  };

  // const preEligibilityAttributes = Object.keys(personAttributes).filter((key) =>
  //   bestDescribeAttributes.includes(key)
  // );

  return (
    <Container>
      <NavHeader />
      {/* {contactStudyArm && (
        <Invitation
          icon={<UserPlusIcon />}
          title='Join Research Registry'
          description='Check your eligibility for the SV Disorders Registry research'
          buttonText='Join'
          onClick={handleEnrollButton}
        />
      )} */}
      {researchAllftdStudyArm && (
        <CardContainer>
          {/* SING-38 */}
          {/* <InvitationCard
            icon={<EnvelopeIcon />}
            title='Spread the Word'
            description='Do you know any eligible patients or family members of a patient who would be interested in contributing their information to fuel single ventricle research? Invite them to join SOURCE!'
            buttonText='Invite'
            onClick={() => setOpenSpreadTheWordModel(true)}
          />
          <InvitationCard
            icon={<UserPlusIcon />}
            title='Enroll another participant under your account'
            description='You can enroll more than one eligible participant on your account. This can include yourself and any of your minor children or dependents.'
            buttonText='Begin'
            onClick={handleEnrollButton}
          /> */}
          <InvitationCard
            icon={<UserPlusIcon />}
            title={ENROLL_ANOTHER_PARTICIPANTS.title}
            description={ENROLL_ANOTHER_PARTICIPANTS.description}
            buttonText='Begin'
            onClick={handleEnrollButton}
            shouldBeFocussed
          />
          {!isActiveProfileContactRole && <FamilyLinkageCard />}
          <InvitationCard
            icon={<EnvelopeIcon />}
            title='Spread the Word'
            description='Do you know any eligible patients or family members of a patient outside of your immediate family who would be interested in contributing their information to fuel single ventricle research? Invite them to join SOURCE!'
            buttonText='Invite'
            onClick={() => setOpenSpreadTheWordModel(true)}
          />
        </CardContainer>
      )}
      <Activities />
      <RegistryStatisticsWidget />
      <ExternalResearchOpportunity />
      <EducationalMaterials />
      {openSpreadTheWordModel && (
        <InviteModel open={openSpreadTheWordModel} onClose={onClose} />
      )}
    </Container>
  );
}

// SING-38
// export default withPerson(withPersonAttributes(withSelectedProfile(Home)));

export default withPerson(
  withPersonAttributes(
    withSelectedProfile(() => (
      <HomePageWrapper>
        <Home />
      </HomePageWrapper>
    ))
  )
);
