import { TextButton } from './TextButton';

export const OutlinedButton = ({ children, ...rest }) => {
  return <TextButton variant='outlined' {...rest}>{children}</TextButton>;
};

/*
  Example Usage

  <OutlinedButton
    color="primary"
    size="large"
  >
    Button
  </OutlinedButton>

  <OutlinedButton
    color="primary"
    size="large"
    endIcon={<ArrowForwardIcon />}
  >
    Button
  </OutlinedButton>

  <OutlinedButton
    color="primary"
    size="large"
    startIcon={<ArrowForwardIcon />}
  >
    Button
  </OutlinedButton>

  color: primary | secondary | tertiary
*/
