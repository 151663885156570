export function formatBytes(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function isImgValidToRender(files, validDimensions) {
  if (files?.file?.name && validDimensions) return true;
}

export function isImage(file) {
  return file?.type.includes('image/');
}
