import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const FormBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray2,
  border: `1px solid ${theme.palette.primary.gray25}`,
  boxSizing: 'border-box',
  borderRadius: '8px',

  display: 'flex',
  flexDirection: 'row',
  gap: '15px',
  alignItems: 'center',
  padding: '0 15px',
  height: '50px',

  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.gray50
  },
  '&:focus': {
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    borderColor: theme.palette.primary.fuchsia75
  },
  outline: 'none'
}));
