import { styled } from '@mui/material/styles';
import { FormControl } from '@mui/material';
import { SIZE_STYLES } from '../config';

const sizeColorIndicatorSizeMap = {
  lg: '32px',
  md: '30px',
  sm: '28px',
  xs: '26px',
};

export const StyledFormControl = styled(FormControl)(({ fullWidth, size }) => ({
  width: !fullWidth ? SIZE_STYLES[size].width : '100%',
}));

export const ColorPickerWrapper = styled(({ fullWidth, size, ...rest }) => (
  <div {...rest} />
))(({ fullWidth, size }) => ({
  position: 'relative',
  width: !fullWidth ? SIZE_STYLES[size].width : '100%',
}));

export const ColorPickerBox = styled('div')(({ size }) => ({
  position: 'absolute',
  zIndex: 1,
  right: 0,
  top: `calc(${SIZE_STYLES[size].height} + 10px)`,
}));

export const SelectedColorIndicator = styled(
  ({ size, selectedColor, ...rest }) => <div {...rest} />
)(({ size, selectedColor }) => ({
  width: sizeColorIndicatorSizeMap[size],
  height: sizeColorIndicatorSizeMap[size],
  border: `1px solid ${selectedColor}`,
  borderRadius: '4px',
  boxSizing: 'border-box',
  backgroundColor: selectedColor,
}));
