import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const healthApiSlice = createApi({
  name: 'healthApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.1up.health/',
    tagTypes: ['User'],
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints: (build) => ({
    addUser: build.mutation({
      query: (body) => ({
        url: `/user-management/v1/user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useAddUserMutation } = healthApiSlice;
