import { createSlice } from '@reduxjs/toolkit';

import getNormalizedActions from '../normalizedActions';

const createQuestionSlice = ({
  name,
  initialState,
  getters,
  extraReducers = {},
}) => {
  const normalizedActions = getNormalizedActions(getters);
  return createSlice({
    name: `${name}Question`,
    initialState,
    reducers: {
      enableQuestion: normalizedActions.enableAction,
      reorderQuestion: normalizedActions.reorderAction,
      addQuestion: normalizedActions.addAction,
      removeQuestion: normalizedActions.removeAction,
      updateQuestion: normalizedActions.updateAction,
    },
    extraReducers,
  });
};

export default createQuestionSlice;
