import { styled } from '@mui/material/styles';
import { Radio } from '@mui/material';

const sizeMap = {
  'md': '50px',
  'sm': '46',
  'xs': '42px',
};

export const StyledRadio = styled(Radio)(
  ({ theme, size = 'md', disabled }) => ({
    padding: '0 0 0 0',
    '& .StyledLabel': {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 16px 10px 16px',
      minHeight: sizeMap[size] || sizeMap.md,
      gap: '10px',

      background: theme.palette.primary.white,

      color: theme.palette.primary.darkGray50,
      textAlign: 'center',
      wordWrap: 'normal',

      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.primary.lightGray100,
      borderRadius: '6px',
      position: 'relative',

      boxSizing: 'border-box',
      resize: 'none',
      width: '100%',
    },
    '& .MuiTypography-root': {
      color: theme.palette.primary.darkGray50,
    },
    '&:hover': {
      '& .StyledLabel:not(.StyledLabel-checked)': {
        borderColor: theme.palette.primary.darkGray50,
      },
    },
    '& .StyledLabel-checked': {
      '& .MuiTypography-root': {
        color: !disabled && theme.palette.primary.darkBlue90,
        fontWeight: 600,
      },

      border: !disabled && `1px solid ${theme.palette.primary.darkBlue90}`,
    },
  })
);
