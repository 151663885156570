import { getBaseURL, getHealthClientId } from 'config/API';

import { ONE_UP_TOKEN_EXPIRATION_MINUTES } from 'constants/oneUpHealth';
import { minutesBetweenDates } from 'utils/date';

const ONE_UP_API = 'https://api.1up.health/connect/system/clinical';
const IHP_ONEUP_API = getBaseURL();

export const getConnectToProviderLink = (id, token) => {
  return `${ONE_UP_API}/${id}?client_id=${getHealthClientId()}&access_token=${token}`;
};

export const getHealthProviders = async (token, personId) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${IHP_ONEUP_API}/v3/people/${personId}/providers`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const deleteProvider = async (token, personId, providerId) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${IHP_ONEUP_API}/v3/people/${personId}/providers/${providerId}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));
};

export const getProviderDetails = async (token, providerIds) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  let formattedIds = '';
  providerIds?.forEach((id) => (formattedIds += `${id},`));

  if (providerIds?.length > 0) {
    formattedIds = '?id=' + formattedIds;
  }

  if (formattedIds?.charAt(formattedIds?.length - 1) === ',') {
    formattedIds = formattedIds.substring(0, formattedIds.length - 1);
  }

  if (formattedIds === '') {
    return {};
  }

  const response = await fetch(
    `${IHP_ONEUP_API}/v2/oneup/providers-list${formattedIds}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const getFilteredProviders = async (token, query) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${IHP_ONEUP_API}/v2/oneup/providers-list?q=${query}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const createUser = async (
  token,
  participant_id,
  health_system_id,
  provider_name,
  participant_task_id
) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify({
    participant_id,
    // TODO: check if we need to provide another projects
    project: 'ftddr',
    health_system_id,
    provider_name,
    participant_task_id,
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${IHP_ONEUP_API}/v2/oneup/create`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const fetchSyncedData = async (token, participant_id) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'text/plain');

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  // TODO: check if we need to provide another projects
  const response = await fetch(
    `${IHP_ONEUP_API}/v2/oneup/resource-data-fetch-status?participant_id=${participant_id}&project=ftddr`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  let resources = null;
  try {
    resources = JSON.parse(response);
  } catch (e) {
    console.error(e);
  }
  if (resources) {
    return Object.values(resources);
  }
  return {};
};

export const initiateDataFetch = async (token, participant_id) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');

  var raw = JSON.stringify({
    participant_id,
    // TODO: check if we need to provide another projects
    project: 'ftddr',
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  const response = await fetch(
    `${IHP_ONEUP_API}/v2/oneup/initiate-data-fetch`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const fetchSMProviders = async (
  token,
  participant_id,
  participant_task_id
) => {
  var myHeaders = new Headers();
  myHeaders.append('Accept', 'application/vnd.api+json');
  myHeaders.append('Content-Type', 'application/vnd.api+json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const url_sufix = participant_task_id
    ? `?filter[participant_task_id]=${participant_task_id}`
    : '';

  const response = await fetch(
    `${IHP_ONEUP_API}/v3/people/${participant_id}/providers${url_sufix}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const parseHealthProviderAddress = (address) => {
  let result = '';
  let street;
  if (address?.line?.length === 1) street = address?.line[0];
  if (street) result += `${street}, `;
  if (address?.city) result += `${address.city}, `;
  if (address?.postalCode) result += `${address.postalCode}, `;
  if (address?.state) result += `${address.state}`;
  if (result === '') return 'Unknown address';
  return result;
};

export const hasOneUpTokenExpired = (lastTokenTime) => {
  if (!lastTokenTime) {
    return true;
  }
  const minutesDifference = minutesBetweenDates(
    new Date(lastTokenTime),
    new Date()
  );
  return minutesDifference > ONE_UP_TOKEN_EXPIRATION_MINUTES;
};

export const getPatientNames = async (
  token,
  participant_id,
  health_system_ids,
  combine = true
) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const result = {};

  const urls = health_system_ids.map(
    (id) =>
      `${IHP_ONEUP_API}/v2/emr/health-system/${id}/person/${participant_id}`
  );

  await Promise.all(
    urls.map(async (url) => {
      const resp = await fetch(url, requestOptions);
      let response = await resp.text();
      const items = JSON.parse(response).data?.subject_names?.items;

      // For testing reasons
      // const items = [
      //       {
      //         "row_number": 1,
      //         "subject_id": "17257",
      //         "site_id": "8364708",
      //         "patient_relationship": "Self",
      //         "use_code": "official",
      //         "name_text": "Camila Maria Lopez"
      //     },
      //     {
      //         "row_number": 2,
      //         "subject_id": "17257",
      //         "site_id": "8364708",
      //         "patient_relationship": "Self",
      //         "use_code": "usual",
      //         "name_text": "Camila Maria Lopez"
      //     }
      // ];

      items.forEach((data) => {
        const providerId = parseInt(data?.site_id);
        if (data.patient_relationship === 'Family Member') {
          result[providerId] =
            combine && result?.[providerId]
              ? `${result[providerId]}; ${data.name_text}`
              : data.name_text;
        } else if (data.patient_relationship === 'Self') {
          result[providerId] =
            combine && result?.[providerId]
              ? `${result[providerId]}; ${data.name_text}`
              : data.name_text;
        }
      });
    })
  );

  return result;
};
