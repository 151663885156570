import { Box, styled } from '@mui/system';
import { SearchInput } from 'configUI/components/Shared';

export const PageWrapper = styled(Box)(({ theme }) => ({
  paddingTop: '60px',
  minHeight: '90vh',
  backgroundColor: theme.palette.primary.gray5,
}));

export const SearchWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  width: '100%',
  borderRadius: 0,
  border: 'none',
  '&:hover': { borderColor: 'none' },
  '&.Mui-focused': {
    boxShadow: `none`,
    borderColor: 'none',
  },
}));
