import { useRef, useState, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { isMobile as isMobileDevice } from 'utils/isMobile';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { participantTaskApiSlice } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useGetStudyRolesQuery } from 'ihp-bloom-redux/features/study/studyApiV3';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { setParticipantTaskCategory } from 'ihp-bloom-redux/features/tasks/participantTaskSlice';

import ActivityItems from './ActivityItems';
import { updateLastOpenedConsent } from 'ihp-bloom-redux/features/consent/consentSlice';
import { setActivities } from 'ihp-bloom-redux/features/filters/filtersSlice';
import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { setConsentContext } from 'ihp-bloom-redux/features/user/userSlice';
import { setSelectedParticipantTaskId } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { Alert } from '@mui/material';
import { useHasMultipleProfiles } from 'hooks/useHasMultipleProfiles';

function ActivitiesList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigger, result] =
    participantTaskApiSlice.endpoints.getProfileParticipantTask.useLazyQuery();
  const [isRedirecting, setRedirecting] = useState(false);
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const [consent, setConsent] = useState(null);
  const { taskCategory } = useSelector((state) => state.participantTask);
  const filtersConfig = useSelector((state) => state.filters);
  const participantTaskConfig = useSelector((state) => state.participantTask);

  const activeProfile = activeAccountProfile?.attributes?.profile;
  const activeProfileName = `${activeProfile.first_name} ${activeProfile.last_name}`;

  const [updateParticipantTaskMutation, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();

  const { studyRoleId, isLoading: isLoadingStudyRoles } = useGetStudyRolesQuery(
    undefined,
    {
      selectFromResult: ({ data, ...rest }) => ({
        studyRoleId: data?.data?.[0]?.id,
        ...rest,
      }),
    }
  );

  const handleProfileActivity = async (id) => {
    const requestBody = {
      participantTaskId: id,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(id),
          attributes: {
            status: 'completed',
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody)
      .then((response) => {
        if (!response.error) {
          // SING-676
          // navigate('/profile/accountholder-profile');
          navigate('/profile/accountholder-profile', {
            state: {
              redirectedFromUpdateProfileActivity: true,
            },
          });
        }
      })
      .catch(console.error);
  };

  const handleSignConsent = (consent) => {
    setConsent(consent);
    trigger({
      accountProfileId: activeAccountProfile?.id,
      participantTaskId: consent.participantTaskId,
    });
  };

  const handleViewMore = (type) => {
    if (!type) {
      dispatch(setParticipantTaskCategory(null));
      return;
    }

    dispatch(setParticipantTaskCategory(type));
  };
  useEffect(() => {
    const envelopeUrl =
      result?.data?.data?.attributes?.['participant-task']?.['envelope_url'];

    const participantTaskId = result?.data?.data?.id;
    // Prevent to use cached envelope url while refetching
    if (result.status !== 'pending' && envelopeUrl && participantTaskId) {
      setRedirecting(true);
      dispatch(
        setSelectedParticipantTaskId({
          taskId: participantTaskId,
        })
      );
      dispatch(updateLastOpenedConsent(consent));
      dispatch(setConsentContext('profile'));
      window.location.href = envelopeUrl;
    }
  }, [consent, dispatch, result]);

  useEffect(() => {
    if (isMobileDevice()) {
      dispatch(setParticipantTaskCategory(null));
    }

    if (!isMobileDevice() && !participantTaskConfig?.taskCategory) {
      dispatch(setParticipantTaskCategory('CURRENT'));
    }
  }, [participantTaskConfig.taskCategory]);

  useEffect(() => {
    dispatch(
      setActivities({
        startDate: '',
        endDate: '',
        selectedTypes: ['show_all'],
      })
    );
  }, [taskCategory]);

  const hasMultipleProfiles = useHasMultipleProfiles();
  return (
    <>
      {hasMultipleProfiles && (
        <Alert severity='info'>
          Multiple profiles are associated with your account. Each account
          profile may have its own activities.
          <br />
          You are currently viewing the profile for{' '}
          <strong>{activeProfileName}</strong>.
          <br />
          <Link to='/switch-profile'>Click here</Link> to change profile or use
          the dropdown in the bottom left corner.
        </Alert>
      )}
      <ActivityItems
        personId={person?.id}
        studyRoleId={studyRoleId}
        filtersConfig={filtersConfig.activities}
        selectedTaskCategory={participantTaskConfig.taskCategory}
        handleProfileActivity={handleProfileActivity}
        handleSignConsent={handleSignConsent}
        isFetchingConsentDetails={result?.isFetching || isRedirecting}
        isUpdatingTask={isUpdatingTask}
        viewMoreType={participantTaskConfig?.taskCategory}
        onViewMore={handleViewMore}
        maxVisible={participantTaskConfig?.taskCategory ? null : 1000}
        timezone={''}
      />
    </>
  );
}

export default withPerson(withSelectedProfile(ActivitiesList));
