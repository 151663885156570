import { FamilyLinkageInvitation } from 'pages/Profile/FamilyLinkage/components/FamilyLinkageInvitation';
import { List, Section, Title } from 'pages/Profile/FamilyLinkage/styles';

export function SentInvitations({ invitations }) {
  return (
    <Section>
      <Title>Sent invitations</Title>

      <List>
        {invitations.map((fi) => (
          <FamilyLinkageInvitation
            firstName={fi.invitee_first_name}
            lastName={fi.invitee_last_name}
          />
        ))}
      </List>
    </Section>
  );
}
