import { Score } from './Score.js';
import { Container } from './ScorePanel.styles';

export const ScorePanel = ({
  payments,
  months,
  totalPointsEarned,
  availablePoints,
  pointsAlreadyRedeemed,
  pointsBalance,
  totalRewardsValueReceived,
  ...rest
}) => (
  <Container {...rest}>
    <Score
      label='Total Points Earned'
      description='The amount of points that you have earned by completing activities in the study.'
    >
      {totalPointsEarned}
    </Score>
    <Score
      label='Points Already Redeemed'
      description='The amount of points you have redeemed for rewards.'
    >
      {pointsAlreadyRedeemed}
    </Score>
    <Score
      label='Points Balance'
      description='The amount of unredeemed points that you still have available to redeem.'
    >
      {pointsBalance}
    </Score>
    <Score
      label='Total Rewards Value Received'
      description='The rewards value of points that you have earned through the course of the study.'
    >
      ${totalRewardsValueReceived}
    </Score>
  </Container>
);
