import { Typography, Stack } from '@mui/material';
import { ReactComponent as DownloadIcon } from 'images/shared/download-icon.svg';
import { ReactComponent as MailboxIcon } from 'images/shared/mailbox-icon.svg';
import {
  Container,
  // DownloadLink,
  // Section,
  Subtitle,
  // Text,
  TextIconContainer,
  Title,
} from './styles';
// import { ADDITIONAL_VENTURE_LINK } from 'constants/global';
import useScrollToTop from 'hooks/useScrollToTop';
import { cliniciansContent } from './constants';

const Clinicians = () => {
  const { documentTitle, pageTitle, helpDescription, getAccessDescription, footnote } = cliniciansContent;
  document.title = documentTitle;
  useScrollToTop();
  return (
    <Container>
      <Stack spacing='20px'>
        <Title variant='h1'>{pageTitle}</Title>
        <Subtitle>
          {helpDescription.subTitle}
        </Subtitle>
        <Typography variant='pl1'>
          {helpDescription.description}
        </Typography>
      </Stack>

      <Stack spacing='20px'>
        <Subtitle>
          {getAccessDescription.subTitle}
        </Subtitle>

        <TextIconContainer>
          <MailboxIcon />
          {getAccessDescription.requestPrintedMaterials}
        </TextIconContainer>
        <TextIconContainer>
          <DownloadIcon />
          {getAccessDescription.downloadBrochure}
        </TextIconContainer>
        <Typography variant='pl1'>
          {footnote}
        </Typography>
      </Stack>
    </Container>
  );
};
export default Clinicians;
