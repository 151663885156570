import { StaticTimePicker as MUITimePicker } from '@mui/lab';
import { TextField } from '@mui/material';

import { TimepickerStyleWrapper } from './styles';

export const StaticTimePicker = ({ ...props }) => (
  <TimepickerStyleWrapper>
    <MUITimePicker renderInput={props => <TextField {...props} />} {...props} />
  </TimepickerStyleWrapper>
);
