import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { TYPES, VARIANTS, SIZES_STYLES, getDisabledStyles } from './config';

const setColor = ({ color, background }) => ({
  ...(color ? { color } : {}),
  ...(background ? {
    background,
    '&: hover': { background },
  } : {})
});

const getConfig = (
  theme, 
  {
    size = 'md',
    variant = 'contained',
    type = 'primary',
    color,
    background,
    width,
  }
) => {
  const TYPE_COLOR = {
    [TYPES.primary]: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.white,
      borderWidth: '2px',
      '&: hover': {
        boxShadow: 'none',
        background: theme.palette.primary.darkBlue90,
        borderColor: theme.palette.primary.darkBlue90,
        color: theme.palette.primary.white,
        borderWidth: '2px',
      },
      '&: disabled': {
        opacity: '0.5',
        background: theme.palette.primary.main,
        color: theme.palette.primary.white,
        borderWidth: '2px',
      },
    },
    [TYPES.secondary]: {
      background: theme.palette.primary.darkGray50,
      color: theme.palette.primary.white,
      borderWidth: '2px',
      '&: hover': {
        boxShadow: 'none',
        background:theme.palette.primary.darkGray70,
        borderColor: theme.palette.primary.darkGray70,
        color: theme.palette.primary.white,
      },
      '&: disabled': {
        opacity: '0.5',
        background: theme.palette.primary.darkGray50,
        color: theme.palette.primary.white,
        borderWidth: '2px',
      },
    },
    [TYPES.info]: {
      background: theme.palette.primary.lightGray70,
      color: theme.palette.primary.darkGray70,
      borderWidth: '2px',
      '&: hover': {
        boxShadow: 'none',
        background:theme.palette.primary.darkGray20,
        borderColor: theme.palette.primary.darkGray20,
        color: theme.palette.primary.darkGray70,
      },
      '&: disabled': {
        opacity: '0.5',
        background: theme.palette.primary.lightGray70,
        color: theme.palette.primary.darkGray70,
        borderWidth: '2px',
      },
    },
    [TYPES.info2]: {
      background: theme.palette.primary.darkBlue70,
      color: theme.palette.primary.white,
      borderWidth: '2px',
      '&: hover': {
        boxShadow: 'none',
        background:theme.palette.primary.darkBlue50,
        borderColor: theme.palette.primary.darkBlue50,
        color: theme.palette.primary.white,
        border: '2px solid',
      },
      '&: disabled': {
        opacity: '0.5',
        border: '2px solid',
        background: theme.palette.primary.darkBlue70,
        color: theme.palette.primary.white,
        borderWidth: '2px',
      },
    }
  };
  
  const COLOR_VARIANT_MAP = {
    [VARIANTS.contained]: {
      [TYPES.primary]: {
        ...TYPE_COLOR[TYPES.primary],
      },
      [TYPES.secondary]: {
        ...TYPE_COLOR[TYPES.secondary],
        
      },
      [TYPES.info]: {
        ...TYPE_COLOR[TYPES.info],
      },
      [TYPES.info2]: {
        ...TYPE_COLOR[TYPES.info2],
      }
    },
    [VARIANTS.outlined]: {
      [TYPES.primary]: {
        background: theme.palette.primary.transparent,
        borderColor: TYPE_COLOR[TYPES.primary].background,
        "&: disabled": {
          ...TYPE_COLOR[TYPES.primary]['&: disabled'],
          color: TYPE_COLOR[TYPES.primary].background,
          border: '2px solid',
          background: theme.palette.primary.transparent,
          borderColor: TYPE_COLOR[TYPES.primary].background,
        }
      },
      [TYPES.secondary]: {
        background: theme.palette.primary.white,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.secondary].background,
        borderColor: TYPE_COLOR[TYPES.secondary].background,
        "&: disabled": {
          ...TYPE_COLOR[TYPES.info]['&: disabled'],
          background: theme.palette.primary.white,
          border: '2px solid',
          color: TYPE_COLOR[TYPES.secondary].background,
          borderColor: TYPE_COLOR[TYPES.secondary].background,
        }
      },
      [TYPES.info]: {
        ...TYPE_COLOR[TYPES.info],
        background: theme.palette.primary.white,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.info].background,
        borderColor: TYPE_COLOR[TYPES.info].background,
        "&: disabled": {
          ...TYPE_COLOR[TYPES.info]['&: disabled'],
          background: theme.palette.primary.white,
          border: '2px solid',
          color: TYPE_COLOR[TYPES.info].background,
          borderColor: TYPE_COLOR[TYPES.info].background,
        }
      },
      [TYPES.info2]: {
        ...TYPE_COLOR[TYPES.info2],
        background: theme.palette.primary.white,
        border: '2px solid',
        color: TYPE_COLOR[TYPES.info2].background,
        borderColor: theme.palette.primary.lightGray100,
        "&: disabled": {
          ...TYPE_COLOR[TYPES.info]['&: disabled'],
          background: theme.palette.primary.white,
          border: '2px solid',
          color: TYPE_COLOR[TYPES.info2].background,
          borderColor: theme.palette.primary.lightGray100,
        },
        "&: hover": {
          ...TYPE_COLOR[TYPES.info]['&: hover'],
          background: theme.palette.primary.white,
          border: `2px solid ${theme.palette.primary.lightGray100}`,
          filter: 'brightness(98%)',
          color: TYPE_COLOR[TYPES.info2].background,
          borderColor: theme.palette.primary.lightGray100,
        }
      }
    },
    [VARIANTS.link]: {
      [TYPES.primary]: {
        ...TYPE_COLOR[TYPES.primary],
        border: 'none',
        background: 'transparent',
        cursor: 'pointer',
        color: TYPE_COLOR[TYPES.primary].background,
        '&: hover': {
          boxShadow: 'none',
          background: 'transparent',
          borderColor: 'transparent',
          color: TYPE_COLOR[TYPES.primary]['&: hover'].background,
        },
        "&: disabled": {
          background: 'transparent',
          border: 'none',
          color: TYPE_COLOR[TYPES.primary].background,
          opacity: 0.5,
        }
      },
      [TYPES.secondary]: {
        ...TYPE_COLOR[TYPES.secondary],
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        color: TYPE_COLOR[TYPES.secondary].background,
        '&: hover': {
          boxShadow: 'none',
          background: 'transparent',
          borderColor: 'transparent',
          color: TYPE_COLOR[TYPES.secondary]['&: hover'].background,
        },
        "&: disabled": {
          background: 'transparent',
          border: 'none',
          color: TYPE_COLOR[TYPES.secondary].background,
          opacity: 0.5,
        }
      },
      [TYPES.info]: {
        ... TYPE_COLOR[TYPES.info],
        border: 'none',
        cursor: 'pointer',
        background: 'transparent',
        color: TYPE_COLOR[TYPES.info].background,
        '&: hover': {
          boxShadow: 'none',
          background: 'transparent',
          borderColor: 'transparent',
          color: TYPE_COLOR[TYPES.info]['&: hover'].background,
        },
        "&: disabled": {
          background: 'transparent',
          border: 'none',
          color: TYPE_COLOR[TYPES.info].background,
          opacity: 0.5,
        }
      },
    },
  };

  const variantStyles = COLOR_VARIANT_MAP[variant] || COLOR_VARIANT_MAP[VARIANTS.contained];

  return {
    ...(SIZES_STYLES[size] || SIZES_STYLES.lg),
    ...(variantStyles[type] || variantStyles[TYPES.primary]),
    borderRadius: '8px',
    display: 'flex',
    flexDireaction: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    boxShadow: 'none',
    ...setColor({ color, background }),
    ...(width ? { width } : {})
  };
}

const StyledButton = styled(MUIButton)(
  ({
    theme,
    width,
    background,
    color,
    variant,
    type,
    size,
  }) => {
    return getConfig(theme, { variant, type, size, width, background, color });
  }
);

const Button = ({ children, loading, onClick, startIcon, endIcon, to, ...props }) => {
  return (
    <StyledButton
      {...props}
      onClick={(e) => !loading ? (onClick ? onClick(e) : null) : null}
      startIcon={!loading && startIcon}
      endIcon={!loading && endIcon}
      to={to}
    >
      {loading ? <CircularProgress color='inherit' size={25} /> : children}
    </StyledButton>
  )
};

Button.propTypes = {
  loading: PropTypes.bool,
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  type: PropTypes.oneOf(Object.keys(TYPES)),
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  onClick: PropTypes.func,
  to: PropTypes.string,
};

export default Button;
