export const VIRTUAL_SITE = {
  id: 1,
  code: 'virtual-site',
};

export const STUDY_ARM_CODE = {
  CONTACT: 'contact',
  RESEARCH: 'research',
  ALLFTD: 'all',
};

export const STUDY_ARM = {
  [STUDY_ARM_CODE.CONTACT]: {
    id: 1,
    code: 'contact',
    title: 'Contact Registry',
  },
  [STUDY_ARM_CODE.RESEARCH]: {
    id: 2,
    code: 'research',
    title: 'Research Registry',
  },
  [STUDY_ARM_CODE.ALLFTD]: {
    id: 3,
    code: 'all',
    title: 'All',
  },
};

export const STUDY_ARM_ID_CODE_MAPPING = {
  1: [STUDY_ARM_CODE.CONTACT],
  2: [STUDY_ARM_CODE.RESEARCH],
  3: [STUDY_ARM_CODE.ALLFTD],
};

export const STUDY_ARM_ROLE_NAME = {
  AdultSV: 'AdultSV',
  AdultNonSV: 'AdultNonSV',
  LARSV: 'LARSV',
  LARSVChildUnder7: 'LARSVChildUnder7',
  LARSVChild7ToMajority: 'LARSVChild7ToMajority',
  LARNonSV: 'LARNonSV',
  LARNonSVChildUnder7: 'LARNonSVChildUnder7',
  LARNonSVChild7ToMajority: 'LARNonSVChild7ToMajority',
  Participant: 'Participant',
  DependentSV: 'DependentSV',
  DependentNonSV: 'DependentNonSV',
  ParentSVChild7ToMajority: 'ParentSVChild7ToMajority',
  ParentNonSVChild7ToMajority: 'ParentNonSVChild7ToMajority',
  SVChild7ToMajority: 'SVChild7ToMajority',
  NonSVChild7ToMajority: 'NonSVChild7ToMajority',
  ParentSVChildUnder7: 'ParentSVChildUnder7',
  ParentNonSVChildUnder7: 'ParentNonSVChildUnder7',
  SVChildUnder7: 'SVChildUnder7',
  NonSVChildUnder7: 'NonSVChildUnder7',
  NO_RELATIONSHIPS: 'no_relationships',
};

/*
 * The following mapping consists key, value pair
 * key: study arm id
 * value: mapping of study arm role name with study arm role id, {[study_arm_role_name]: study_arm_role_id}
 */

export const STUDY_ARM_ROLE = {
  1: {
    [STUDY_ARM_ROLE_NAME.PARTICIPANT]: 1,
  },
  2: {
    [STUDY_ARM_ROLE_NAME.PARTICIPANT]: 2,
    [STUDY_ARM_ROLE_NAME.LAR]: 3,
    [STUDY_ARM_ROLE_NAME.REPORTER]: 4,
    [STUDY_ARM_ROLE_NAME.UNASSIGNED]: 5,
  },
  3: {
    [STUDY_ARM_ROLE_NAME.PARTICIPANT]: 6,
  },
};

export const STUDY_ARM_SUB_ROLE_NAME = {
  AFFECTED: 'affected',
  CAREGIVER: 'caregiver',
  BIOLOGICAL_FAMILY_MEMBER: 'biological_family_member',
};

/*
 * The following mapping consists key, value pair
 * key: study arm role id
 * value: mapping of study arm sub role name with study arm sub role id, {[study_arm_sub_role_name]: study_arm_sub_role_id}
 */

export const STUDY_ARM_SUB_ROLE = {
  2: {
    [STUDY_ARM_SUB_ROLE_NAME.AFFECTED]: 1,
    [STUDY_ARM_SUB_ROLE_NAME.CAREGIVER]: 2,
    [STUDY_ARM_SUB_ROLE_NAME.BIOLOGICAL_FAMILY_MEMBER]: 3,
  },
};

export const STUDY_ARM_STATUS_NAMES = {
  'ENROLLMENT-TO-BE-VERIFIED': 'enrollment-to-be-verified',
  'ENROLLMENT-VERIFIED': 'enrollment-verified',
  'ENROLLMENT-ELIGIBLE': 'enrollment-eligible',
  'ENROLLMENT-NOT-ELIGIBLE': 'enrollment-not-eligible',
  'ENROLLMENT-ACCEPTED': 'enrollment-accepted',
  'ENROLLMENT-DECLINED': 'enrollment-declined',
  'ENROLLMENT-INTEREST-1': 'enrollment-interest-1',
  'ENROLLMENT-PERSONAL-INFO-SUBMITTED': 'enrollment-personal-info-submitted',
  'ENROLLMENT-INTEREST-2': 'enrollment-interest-2',
  'ENROLLMENT-INTEREST-3': 'enrollment-interest-3',
  'ENROLLMENT-INTEREST-4': 'enrollment-interest-4',
  'ENROLLMENT-DIAGNOSED-PROFILE-SUBMITTED':
    'enrollment-diagnosed-profile-submitted',
  'ENROLLMENT-ACC-CREATED': 'enrollment-acc-created',
  'ENROLLMENT-ROLE-SELECTED': 'enrollment-role-selected',
  'ENROLLMENT-ROLE-ELIGIBLE': 'enrollment-role-eligible',
  'ENROLLMENT-ROLE-NOT-ELIGIBLE': 'enrollment-role-not-eligible',
  'ENROLLMENT-REPORTER-INVITED': 'enrollment-reporter-invited',
  'ENROLLMENT-SUBJECT-IDENTIFIED': 'enrollment-subject-identified',
  'ENROLLMENT-ACC-HOLDER-IDENTIFIED': 'enrollment-acc-holder-identified',
  'ENROLLMENT-CONSENTED': 'enrollment-consented',
  WITHDRAWN: 'withdrawn',
  'ENROLLMENT-CONVERTED': 'enrollment-converted',
  ENROLLED: 'enrolled',
  DECEASED: 'deceased',
  'REPORTER-REVOKED': 'reporter-revoked',
  'ENROLLED-SITE': 'enrolled-site',
  'ABOUT-REGISTRY': 'about-registry',
  'ENROLLMENT-CONFIRMED': 'enrollment-confirmed',
  'ENROLLMENT-REPORTERS': 'enrollment-reporters',
};

/*
The following is a key value pair
  key: study arm id
  value: key value pair again
    key: study arm status name
    value: study arm status id
*/

export const STUDY_ARM_STATUS = {
  1: {
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-TO-BE-VERIFIED']]: 1,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED']]: 2,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-ELIGIBLE']]: 3,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-NOT-ELIGIBLE']]: 4,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-1']]: 7,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-2']]: 8,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-3']]: 9,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-4']]: 10,
    [STUDY_ARM_STATUS_NAMES.ENROLLED]: 20,
    [STUDY_ARM_STATUS_NAMES.WITHDRAWN]: 24,
    [STUDY_ARM_STATUS_NAMES['ABOUT-REGISTRY']]: 28,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONFIRMED']]: 29,
    [STUDY_ARM_STATUS_NAMES.DECEASED]: 31,
  },
  2: {
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACCEPTED']]: 5,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-DECLINED']]: 6,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-DIAGNOSED']]: 11,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-ELIGIBLE']]: 12,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-NOT-ELIGIBLE']]: 13,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTER-INVITED']]: 14,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-SUBJECT-IDENTIFIED']]: 15,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-HOLDER-IDENTIFIED']]: 16,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED']]: 17,
    [STUDY_ARM_STATUS_NAMES.WITHDRAWN]: 18,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONVERTED']]: 19,
    [STUDY_ARM_STATUS_NAMES.ENROLLED]: 21,
    [STUDY_ARM_STATUS_NAMES.DECEASED]: 22,
    [STUDY_ARM_STATUS_NAMES['REPORTER-REVOKED']]: 23,
    [STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTERS']]: 30,
  },
  3: {
    [STUDY_ARM_STATUS_NAMES['ENROLLED-SITE']]: 25,
    [STUDY_ARM_STATUS_NAMES.DECEASED]: 26,
    [STUDY_ARM_STATUS_NAMES.WITHDRAWN]: 27,
  },
};

/**
 * The following is a mapping of study arm status id with study arm id
 */

export const STUDY_ARM_STATUS_REVERSE_MAPPING = {
  1: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-TO-BE-VERIFIED'],
  },
  2: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED'],
  },
  3: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-ELIGIBLE'],
  },
  4: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-NOT-ELIGIBLE'],
  },
  5: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACCEPTED'],
  },
  6: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-DECLINED'],
  },
  7: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-1'],
  },
  8: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-2'],
  },
  9: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-3'],
  },
  10: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-INTEREST-4'],
  },
  11: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-DIAGNOSED'],
  },
  12: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-ELIGIBLE'],
  },
  13: {
    study_arm_id: 2,
    study_arm_status_name:
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ROLE-NOT-ELIGIBLE'],
  },
  14: {
    study_arm_id: 2,
    study_arm_status_name:
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTER-INVITED'],
  },
  15: {
    study_arm_id: 2,
    study_arm_status_name:
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-SUBJECT-IDENTIFIED'],
  },
  16: {
    study_arm_id: 2,
    study_arm_status_name:
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-ACC-HOLDER-IDENTIFIED'],
  },
  17: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONSENTED'],
  },
  18: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.WITHDRAWN,
  },
  19: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONVERTED'],
  },
  20: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.ENROLLED,
  },
  21: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.ENROLLED,
  },
  22: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.DECEASED,
  },
  23: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['REPORTER-REVOKED'],
  },
  24: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.WITHDRAWN,
  },
  25: {
    study_arm_id: 3,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLED-SITE'],
  },
  26: {
    study_arm_id: 3,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.DECEASED,
  },
  27: {
    study_arm_id: 3,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.WITHDRAWN,
  },
  28: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ABOUT-REGISTRY'],
  },
  29: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-CONFIRMED'],
  },
  30: {
    study_arm_id: 2,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES['ENROLLMENT-REPORTERS'],
  },
  31: {
    study_arm_id: 1,
    study_arm_status_name: STUDY_ARM_STATUS_NAMES.DECEASED,
  },
};

export const STUDY_ARM_CONFIG_VARIABLES = {
  STUDY_ARMS: 'study-arms',
  STUDY_ARM_ROLES: 'study-arm-roles',
  STUDY_ARM_SUB_ROLES: 'study-arm-sub-roles',
  STUDY_ARM_STATUSES: 'study-arm-statuses',
};

export const SITE_CODES = {
  VIRTUAL_SITE: 'virtual-site',
};

export const STUDY_ARM_CODES = {
  CONTACT: 'contact',
  RESEARCH: 'research',
  ALLFTD: 'all',
};

export const STUDY_ARM_ROLE_CODES = {
  Pending: 'r_pending',
  AdultSV: 'r_adult_sv',
  AdultNonSV: 'r_adult_nsv',
  ParentSVChildUnder7: 'r_parent_sv_under_7',
  ParentNonSVChildUnder7: 'r_parent_nsv_under_7',
  ParentSVChild7ToMajority: 'r_parent_sv_7_to_majority',
  ParentNonSVChild7ToMajority: 'r_parent_nsv_7_to_majority',
  LARSVADULT: 'r_lar_sv_adult',
  LARSVChildUnder7: 'r_lar_sv_under_7',
  LARSVChild7ToMajority: 'r_lar_sv_7_to_majority',
  LARNonSVAdult: 'r_lar_nsv_adult',
  LARNonSVChildUnder7: 'r_lar_nsv_under_7',
  LARNonSVChild7ToMajority: 'r_lar_nsv_7_to_majority',
  SVChildUnder7: 'r_child_sv_under_7',
  NonSVChildUnder7: 'r_child_nsv_under_7',
  SVChild7ToMajority: 'r_child_sv_7_to_majority',
  NonSVChild7ToMajority: 'r_child_nsv_7_to_majority',
  DependentSVAdult: 'r_dependent_sv_adult',
  DependentNonSVAdult: 'r_dependent_nsv_adult',
  Participant: 'c_participant',
};

export const DEPENDENT_MAPPING = {
  [STUDY_ARM_ROLE_CODES.LARSVChildUnder7]: STUDY_ARM_ROLE_CODES.SVChildUnder7,
  [STUDY_ARM_ROLE_CODES.LARSVChild7ToMajority]:
    STUDY_ARM_ROLE_CODES.SVChild7ToMajority,
  [STUDY_ARM_ROLE_CODES.LARSVADULT]: STUDY_ARM_ROLE_CODES.DependentSVAdult,

  [STUDY_ARM_ROLE_CODES.LARNonSVChildUnder7]:
    STUDY_ARM_ROLE_CODES.NonSVChildUnder7,

  [STUDY_ARM_ROLE_CODES.LARNonSVChild7ToMajority]:
    STUDY_ARM_ROLE_CODES.NonSVChild7ToMajority,

  [STUDY_ARM_ROLE_CODES.LARNonSVAdult]:
    STUDY_ARM_ROLE_CODES.DependentNonSVAdult,

  [STUDY_ARM_ROLE_CODES.ParentSVChildUnder7]:
    STUDY_ARM_ROLE_CODES.SVChildUnder7,

  [STUDY_ARM_ROLE_CODES.ParentSVChild7ToMajority]:
    STUDY_ARM_ROLE_CODES.SVChild7ToMajority,
    

  [STUDY_ARM_ROLE_CODES.ParentNonSVChild7ToMajority]:
    STUDY_ARM_ROLE_CODES.SVChild7ToMajority,

  [STUDY_ARM_ROLE_CODES.ParentNonSVChildUnder7]:
    STUDY_ARM_ROLE_CODES.NonSVChildUnder7,

  [STUDY_ARM_ROLE_CODES.ParentNonSVChild7ToMajority]:
    STUDY_ARM_ROLE_CODES.NonSVChild7ToMajority,
};

// export const STUDY_ARM_ROLE_CODES = {
//   C_PARTICIPANT: 'c_participant',
//   R_PARTICIPANT: 'r_participant',
//   // R_LAR: 'r_lar',
//   R_LARSV: 'r_lar_sv',
//   R_LARNONSV: 'r_lar_nsv',
//   R_REPORTER: 'r_reporter',
//   R_UNASSIGNED: 'r_unassigned',
//   A_PARTICIPANT: 'a_participant',
//   I_PARTICIPANT: 'i_participant',
// };

export const STUDY_ARM_SUB_ROLE_CODES = {
  R_PARTICIPANT_AFFECTED: 'r_participant_affected',
  I_PARTICIPANT_CAREGIVER: 'i_participant_caregiver',
  I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER:
    'i_participant_biological_family_member',
};

export const STUDY_ARM_STATUS_CODES = {
  CONTACT_ENROLLMENT_TO_BE_VERIFIED: 'contact_enrollment_to_be_verified',
  CONTACT_ENROLLMENT_VERIFIED: 'contact_enrollment_verified',
  CONTACT_ENROLLMENT_ELIGIBLE: 'contact_enrollment_eligible',
  CONTACT_ENROLLMENT_NOT_ELIGIBLE: 'contact_enrollment_not_eligible',
  RESEARCH_ENROLLMENT_ACCEPTED: 'research_enrollment_accepted',
  RESEARCH_ENROLLMENT_DECLINED: 'research_enrollment_declined',
  CONTACT_ENROLLMENT_INTEREST_1: 'contact_enrollment_interest_1',
  CONTACT_ENROLLMENT_INTEREST_2: 'contact_enrollment_interest_2',
  CONTACT_ENROLLMENT_INTEREST_3: 'contact_enrollment_interest_3',
  CONTACT_ENROLLMENT_INTEREST_4: 'contact_enrollment_interest_4',
  RESEARCH_ENROLLMENT_DIAGNOSED: 'research_enrollment_diagnosed',
  RESEARCH_ENROLLMENT_DIAGNOSED_PROFILE_SUBMITTED:
    'research_enrollment_diagnosed_profile_submitted',
  RESEARCH_ENROLLMENT_PERSONAL_INFO_SUBMITTED:
    'research_enrollment_personal_info_submitted',
  RESEARCH_ENROLLMENT_ROLE_SELECTED: 'research_enrollment_role_selected',
  RESEARCH_ENROLLMENT_ROLE_ELIGIBLE: 'research_enrollment_role_eligible',
  RESEARCH_ENROLLMENT_ROLE_NOT_ELIGIBLE:
    'research_enrollment_role_not_eligible',
  RESEARCH_ENROLLMENT_REPORTER_INVITED: 'research_enrollment_reporter_invited',
  RESEARCH_ENROLLMENT_SUBJECT_IDENTIFIED:
    'research_enrollment_subject_identified',
  RESEARCH_ENROLLMENT_ACC_HOLDER_IDENTIFIED:
    'research_enrollment_acc_holder_identified',
  RESEARCH_ENROLLMENT_CONSENTED: 'research_enrollment_consented',
  RESEARCH_WITHDRAWN: 'research_withdrawn',
  RESEARCH_ENROLLMENT_CONVERTED: 'research_enrollment_converted',
  CONTACT_ENROLLED: 'contact_enrolled',
  RESEARCH_ENROLLED: 'research_enrolled',
  RESEARCH_DECEASED: 'research_deceased',
  RESEARCH_REPORTER_REVOKED: 'research_reporter_revoked',
  CONTACT_WITHDRAWN: 'contact_withdrawn',
  ALLFTD_ENROLLED_SITE: 'allftd_enrolled_site',
  ALLFTD_DECEASED: 'allftd_deceased',
  ALLFTD_WITHDRAWN: 'allftd_withdrawn',
  CONTACT_ABOUT_REGISTRY: 'contact_about_registry',
  CONTACT_ENROLLMENT_CONFIRMED: 'contact_enrollment_confirmed',
  RESEARCH_ENROLLMENT_REPORTERS: 'research_enrollment_reporters',
  CONTACT_DECEASED: 'contact_deceased',
  ALLFTD_ENROLLED: 'allftd_enrolled',
};

export const NOTIFICATION_TYPES = {
  EMAIL: 'Email',
};

export const ADDITIONAL_VENTURE_LINK =
  'https://www.additionalventures.org/initiatives/biomedical-research/foundational-resources/single-ventricle-source/';
