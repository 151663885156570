import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const styles = {
  boxItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  icon: {
    width: '21px',
    height: '21px',
  },
};

export const BoxItem = ({ icon, label }) => (
  <Box sx={styles.boxItem}>
    <img className={styles.icon} src={icon} alt="avatar" />
    <Typography variant='pl3'>{label}</Typography>
  </Box>
);

BoxItem.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};
