import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
  StyledAction,
  StyledContent,
  StyledDescription,
  StyledIcon,
  StyledProfileCard,
  StyledTitle,
} from './ProfileCard.styles';

export const ProfileCard = ({
  icon,
  title,
  description,
  children,
  linkedRoute,
  ...rest
}) => {
  const { pathname } = useLocation();
  const active = pathname === linkedRoute;
  return (
    <StyledProfileCard active={active} {...rest}>
      <StyledIcon active={active}>{icon}</StyledIcon>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
      </StyledContent>
      <StyledAction>{children}</StyledAction>
    </StyledProfileCard>
  );
};

ProfileCard.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
};
