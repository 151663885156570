import { Box, IconButton, styled } from '@mui/material';

export const getStyles = (theme) => {
  return {
    button: {
      width: 171,
      heigth: 46,
      background: '#005587',
      borderRadius: '8px',
      textTransform: 'capitalize',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      padding: '10px',
      lineHeight: '20px',
      color: theme.palette.primary.white,
      marginLeft: 'auto',
      '&:hover': {
        background: '#005587',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '15px',
      },
      '&:disabled': {
        background: '#D4D4D4',
        color: theme.palette.primary.gray4,
      },
    },
    icon: {
      width: 16,
      height: 16,
      color: theme.palette.primary.white,
      fontSize: 14,
      lineHeight: 20,
      marginLeft: 7,
    },
  };
};

export const Card = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme, showBorder }) => ({
  backgroundColor: theme.palette.primary.white,
  borderRadius: '8px',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  // maxHeight: '230px',
  boxSizing: 'border-box',
  ...(showBorder && { border: `1px solid #CCDDE7` }),
  [theme.breakpoints.down('sm')]: {
    height: 'unset',
    // maxHeight: '343px',
    ...(showBorder && { border: `1px solid ${theme.palette.primary.white}` }),
  },
}));

export const Divider = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'inverted',
})(({ theme, inverted }) => {
  const border = `1px solid ${theme.palette.primary.gray10}`;
  return {
    [theme.breakpoints.up('sm')]: {
      ...(inverted ? { borderLeft: border } : { borderTop: border }),
    },
    [theme.breakpoints.down('sm')]: {
      ...(inverted ? { borderTop: border } : { borderLeft: border }),
    },
  };
});

export const GoToSignedConsent = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.fuchsia50,
  margin: 'auto 20px auto 65px',
  [theme.breakpoints.down('sm')]: {
    margin: 'auto 15px auto 30px',
  },
}));
