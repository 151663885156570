import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Typography } from '@mui/material';
import ArrowBackwardIcon from 'images/activity/arrow-backward-icon.svg';
import { Container, StyledButton } from './BaseHeader.styles';

export const BaseHeader = ({
  to = '/',
  onClickBack,
  children,
  rightMenu,
  forceShowClickBack = false,
  ...props
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleOnClick = () => {
    if (onClickBack) onClickBack();
    else navigate(to);
  };

  return (
    <Container {...props}>
      <StyledButton
        forceShowClickBack={forceShowClickBack}
        onClick={handleOnClick}
      >
        <img src={ArrowBackwardIcon} alt='Back arrow' />
      </StyledButton>
      <Typography
        variant={upSm ? 'h2' : 'h6'}
        color={upSm ? 'primary.gray100' : 'secondary.gray4'}
        sx={{ flex: '1 1 auto' }}
      >
        {children}
      </Typography>
      {rightMenu}
    </Container>
  );
};
