import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';

import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {
  useGetParticipantTaskQuery,
  useUpdateParticipantTaskMutation,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import { FormCheckbox } from 'components/Checkbox';
import { SectionTitle } from 'components/InformedConsent';
import { RejectConsentConfirmationModal } from 'components/Profile/RejectConsentConfirmationModal';
import { Loader } from 'components/Loader';
import { SignButton } from 'pages/Profile/Consents/List/styles';
import { isMobile } from 'utils/isMobile';
import Button from 'ihp-components/components/v2/Button';
import { mapTaskRequiredStatus } from 'utils/tasks';
import ParticipantConsentDetailsMobile from './ConsentDetails.mobile';
import { ReactComponent as CheckIcon } from 'images/profile/check-circle-solid-green.svg';
import { ReactComponent as Clock } from 'images/profile/clock.svg';
import { ReactComponent as ExclamationIcon } from 'images/profile/exclamation-circle.svg';
import { ReactComponent as Sign } from 'images/profile/sign.svg';
import { ReactComponent as VersionIcon } from 'images/profile/version.svg';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import {
  AcmeLogo,
  Body,
  ConsentCheckMark,
  ConsentDetailsContainer,
  Content,
  Footer,
  Header,
  PageSubTitle,
  PageTitle,
  Row,
  SignDate,
  Stats,
  StatusChip,
  Version,
} from './styles';

function ParticipantConsentDetails() {
  const { participantTaskId, consentVersionId } = useParams();
  const participant_task_id = parseInt(participantTaskId);
  const consent_version_id = parseInt(consentVersionId);
  const [consentAgreed, setConsentAgreed] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const navigate = useNavigate();

  const [updateParticipantTask, { isLoading: isAcknowledging }] =
    useUpdateParticipantTaskMutation();

  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });

  const { consentDetails, consentIncluded, isLoading } =
    useGetParticipantTaskQuery(
      {
        participantId: person_id,
        participantTaskId: participant_task_id,
      },
      {
        skip: !person_id,
        selectFromResult: ({ data, ...rest }) => ({
          consentDetails: data?.data?.attributes,
          consentIncluded: data?.included?.[0]?.attributes,
          ...rest,
        }),
      }
    );

  const onToggle = () => setConsentAgreed(!consentAgreed);

  const handleSignConsent = async () => {
    const requestBody = {
      participantTaskId: participant_task_id,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participant_task_id),
          attributes: {
            status: 'completed',
          },
        },
      },
    };

    updateParticipantTask(requestBody)
      .then(() => {
        navigate(-1);
      })
      .catch(console.error);
  };

  const handleCancel = () => navigate('/onboarding/participant-consents');

  const handleReject = () => {
    if (!consentDetails?.mandatory) {
      handleCancel();
      return;
    }
    setShowRejectConfirmation(true);
  };

  const handleConfirmRejection = (confirm) => {
    setShowRejectConfirmation(false);
    if (confirm) {
      handleCancel();
    }
  };

  const isSigned = consentDetails?.status === 'completed';

  if (isLoading) return <Loader />;

  if (isMobile()) {
    return (
      <ParticipantConsentDetailsMobile
        details={consentDetails}
        included={consentIncluded}
        isSigned={isSigned}
        consentVersionId={consent_version_id}
        handleSign={handleSignConsent}
        handleReject={handleReject}
        handleConfirmRejection={handleConfirmRejection}
      />
    );
  }

  return (
    <ConsentDetailsContainer>
      <Content sx={{ position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            zIndex: 9,
          }}
          onClick={() => navigate(-1)}
        >
          <XIcon color='#959CA0' />
        </span>
        <Header>
          <Row marginBottom='20px'>
            <AcmeLogo />
          </Row>
          <Row marginBottom='10px'>
            <PageSubTitle variant='h4'>INFORMED CONSENT</PageSubTitle>
          </Row>
          <Row marginBottom='10px'>
            <PageTitle variant='h2'>{consentIncluded?.title}</PageTitle>
          </Row>
          <Stats>
            <StatusChip
              isRequired={
                mapTaskRequiredStatus(consentIncluded?.required_behavior) ===
                'REQUIRED'
                  ? true
                  : false
              }
            >
              <ExclamationIcon />
              <span>
                {mapTaskRequiredStatus(consentIncluded?.required_behavior) ===
                'REQUIRED'
                  ? 'REQUIRED'
                  : 'OPTIONAL'}
              </span>
            </StatusChip>
            <SignDate>
              {isSigned ? <CheckIcon /> : <Clock />}
              <span>
                {!isLoading &&
                  format(
                    new Date(consentDetails?.start_date ?? Date.now()),
                    'yyy-MM-dd'
                  )}
              </span>
            </SignDate>
            <Version>
              <VersionIcon />
              <span>{consent_version_id}</span>
            </Version>
          </Stats>
        </Header>
        <Body>
          <Box marginBottom='20px'>
            <SectionTitle>{consentIncluded?.title}</SectionTitle>
            <Box>
              <Typography variant='pl3' color='primary.gray75'>
                <div>{consentIncluded?.description}</div>
              </Typography>
            </Box>
          </Box>
          {!isSigned && (
            <>
              <ConsentCheckMark>
                <FormCheckbox
                  label='I agree to sign this consent'
                  checked={consentAgreed}
                  onChange={onToggle}
                />
              </ConsentCheckMark>
              <Footer>
                <Button size='lg' variant='link' onClick={handleReject}>
                  Reject
                </Button>
                <SignButton
                  variant='contained'
                  size='large'
                  disabled={!consentAgreed || isAcknowledging}
                  startIcon={<Sign width={19} height={15} />}
                  onClick={handleSignConsent}
                >
                  {isAcknowledging ? 'Loading...' : 'Sign consent'}
                </SignButton>
              </Footer>
            </>
          )}
        </Body>
      </Content>
      <RejectConsentConfirmationModal
        open={showRejectConfirmation}
        onClose={() => setShowRejectConfirmation(false)}
        onConfirmation={handleConfirmRejection}
      />
    </ConsentDetailsContainer>
  );
}

export default ParticipantConsentDetails;
