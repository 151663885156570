import { Modal } from '@mui/material';
import { CancelButton } from 'configUI/pages/StaticPages/index.styles';
import {
  ContinueButton,
  ModalContainer,
  Title,
} from 'pages/SyncData/SearchProvidersV2/styles';
import { Logo } from 'components/shared';

export const CancelModal = (props) => (
  <Modal
    open={props.isOpenCancelModal}
    onClose={props.onCloseCancelModal}
    aria-labelledby='modal-modal-title'
    aria-describedby='modal-modal-description'
    sx={{ zIndex: 9999999 }}
  >
    <ModalContainer>
      <Logo width='85px' />
      <Title>Unsaved Data</Title>
      <ContinueButton variant='contained' onClick={props.onCloseCancelModal}>
        Continue sharing
      </ContinueButton>
      <CancelButton variant='link' onClick={props.navigateBack}>
        Leave this page
      </CancelButton>
    </ModalContainer>
  </Modal>
);
