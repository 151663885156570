import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://reactnative.dev",
    prepareHeaders(headers) {
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchMovies: builder.query({
        query() {
          return `/movies.json`;
        },
      }),
    };
  },
});

export const { useFetchMoviesQuery } = apiSlice;
