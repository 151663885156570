import { STUDY_ARM_SUB_ROLE_CODES } from 'constants/global';

export const formIOkeyMap = {
  // Role
  participant: 'personDiagnosed',
  lar: 'lar',
  independent_participant: 'biologicalRelativeOrCaregiver',
  reporter: `reporter`,

  // Sub role
  [STUDY_ARM_SUB_ROLE_CODES.R_PARTICIPANT_AFFECTED]: 'affected',
  [STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_CAREGIVER]: 'caregiver',
  [STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER]:
    'biologicalRelative',

  // Deceased
  deceased: 'deceased',
};

export const DiagnosisKeyMap = {
//   diagnosis_ftd: 'ftd',
//   diagnosis_bvftd: 'bvFtd',
//   diagnosis_psp: 'psp',
//   diagnosis_ftd_als: 'ftdAls',
//   diagnosis_ppa: 'ppa',
//   diagnosis_svppa: 'svPpa',
//   diagnosis_lvppa: 'lvPpa',
//   diagnosis_nfvppa: 'nvfPpa',
//   diagnosis_cbd: 'cbdOrCbs',
//   diagnosis_richardson_syndrome: 'richardsonSyndrome',
//   diagnosis_picks_disease: 'picksDisease',
//   diagnosis_unsure_ftd: 'unsure',
//   no_formally_diagnosis: 'suspected',
};

export const getFormattedDate = (inputDate) => {
  const date = new Date(inputDate);
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; 
  let dd = date.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return mm + '-' + dd + '-' + yyyy;
};
