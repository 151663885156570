import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'row',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '16px',
  borderRadius: '10px',
  background: theme.palette.primary.white,
  flex: '1 0 0',
  padding: '30px 60px',
  flexWrap: 'wrap'
}));


export const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontSize: '30px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '145%'
}));

export const StyledSubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '145%',
  marginTop: '2px',
  maxWidth: '130px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%'
  }
}));

export const StyledStat = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'start',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const Loader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

