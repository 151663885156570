import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import { ReactComponent as Logo } from 'images/shared/pencil.svg';
import { LinkButton, FilledButton } from 'components/Buttons';

export const Title = (props) => (
  <Typography
    color='#6B757A'
    fontWeight={600}
    fontSize={18}
    marginBottom='15px'
    {...props}
  />
);

export const CommunicationPreferenceWrapper = styled(Box)(({ theme }) => ({
  background: '#F8F8F8',
  padding: '20px 0',
}));

export const CategoryWrapper = styled(Box)(({ theme }) => ({
  marginTop: '30px',
}));

export const styles = {
  activeCheckboxStyle: { background: '#F4F4F4' },
  uncheckedCheckboxStyle: { background: '#FFF' },
};

export const PencilLogo = styled(Logo)(({ sx = {} }) => ({
  width: '20px',
  height: '20px',
  display: 'flex',
  order: '2',
  cursor: 'pointer',
  alignItems: 'flex-end',
  ...sx,
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '20px',
  gap: '30px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '50px',
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: '50px',
  },
}));

export const StyledFilledButton = styled(FilledButton)(({ theme }) => ({
  minWidth: '102px',
  gap: '7px',
  fontSize: '14px',
}));

//communication preference Modal style
export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflowY: 'auto',
  width: '96%',
  maxWidth: '710px',
  borderRadius: '10px',
  boxShadow: 24,
  backgroundColor: `#FFF`,
  maxHeight: '95%',
}));

export const ModalContentWrapper = styled('Box')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  alignSelf: 'center',
  padding: '20px 40px',
  '& button': {
    marginTop: '20px',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  textAlign: 'left',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  //lineHeight: '140%',
  padding: '35px 40px',
  '& svg': {
    right: '20px',
    cursor: 'pointer',
    width: '13px',
    height: '20px',
    position: 'absolute',
  },
}));

export const TitleSeparator = styled(Box)(({ theme }) => ({
  borderBottom: '2px solid #D9D9D9',
  width: '100%',
  alignSelf: 'center',
}));

export const StyledLinkButton = styled(LinkButton)(({ theme }) => ({
  height: '56px',
  padding: '17px 0px',
  marginTop: '20px',
}));
