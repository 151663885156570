import { styled } from '@mui/material/styles';
import { TextField } from 'components/Input/TextField';
import Button from 'ihp-components/components/v2/Button';
import { Box, Stack, Typography } from '@mui/material';

/* Connect screen styles  */

export const ConnectContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray2,
  color: theme.palette.secondary.gray4,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
  },
}));

export const ConnectContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  maxWidth: '295px',
  height: 'calc(100vh - 120px)',
  margin: 'auto',
  paddingTop: '59px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '325px',
    height: '100vh',
    paddingTop: '0px',
  },
}));

export const getStyles = (theme) => ({
  h4: {
    lineHeight: '29px',
  },
  text: {
    lineHeight: '20px',
  },
});

/* Providers list screen styles  */

export const ListContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray5,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
  },
}));

export const ListContent = styled('div')(({ theme }) => ({
  minHeight: 'calc(100vh - 181px)',
  margin: 'auto',
  padding: '20px',
  boxSizing: 'border-box',
  [theme.breakpoints.up('sm')]: {
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.gray20,
    maxWidth: '820px',
    margin: 'auto',
    paddingTop: '50px',
  },
}));

export const SearchBox = styled(TextField)(({ theme }) => ({
  borderRadius: '0px',
  height: '60px',
  paddingLeft: '20px',
  paddingRight: '20px',
  marginTop: '59px',
  borderColor: theme.palette.primary.fuchsia25,
  [theme.breakpoints.up('sm')]: {
    borderColor: theme.palette.primary.gray25,
    borderRadius: '8px',
    height: '50px',
    paddingLeft: '8px',
    paddingRight: '17px',
    marginTop: '0px',
  },
}));

export const DesktopWrapper = styled('div')(({ theme }) => ({
  maxWidth: '200px',
  width: '100%',
}));

export const HeaderSection = styled('div')(({ theme }) => ({
  marginBottom: '30px',
  color: theme.palette.primary.gray100,
}));

/* List item component styles */

export const StyledListItem = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: '15px 20px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > div:first-of-type': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const ListItemLogo = styled('div')(({ theme }) => ({
  marginRight: '16px',
}));

export const ListItemTextSection = styled('div')(({ theme }) => ({
  maxWidth: '200px',
  '.title': {
    color: theme.palette.primary.gray100,
  },
  '.description': {
    color: theme.palette.primary.gray50,
  },
}));

/* Final connection complete screen styles */

export const FinalScreenContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: theme.palette.primary.gray2,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
  },
}));

export const FinalScreenContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '325px',
  margin: 'auto',
  height: '100vh',
}));

export const DoneButton = styled(Button)(() => ({
  fontSize: '16px',
  fontWeight: '700',
  marginBottom: '24px',
}));

export const BottomContainer = styled(Stack)(() => ({
  alignSelf: 'center',
  marginBottom: '30px',
  maxWidth: '325px',
  width: '100%',
  position: 'fixed',
  bottom: '0px',
}));

export const ThanksMessage = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '29px',
  textAlign: 'center',
  alignSelf: 'center',
  marginBottom: '15px',
  maxWidth: '295px',
}));

export const FinalMessage = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
  alignSelf: 'center',
  marginBottom: '30px',
  maxWidth: '295px',
}));

export const FinalIconContainer = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  marginBottom: '50px',
}));

export const RewardBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.green100,
  backgroundColor: theme.palette.primary.green5,
  border: `1px solid ${theme.palette.primary.green25}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '63px',
  width: '100%',
}));

export const RewardPoints = styled(Typography)({
  fontSize: '16px',
  fontWeight: '700',
});
