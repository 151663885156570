import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
  padding: '30px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 20px',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    flexDirection: 'column',
  },
}));
