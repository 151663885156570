import React, {useEffect, useState} from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Content, StyledNavigator } from './styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useDispatch, useSelector} from "ihp-bloom-redux/app/redux";
import {setNavbarExpanded} from "ihp-bloom-redux/features/uiComponents/navbarSlice";

const Main = ({ navigator = true }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up('xs'));
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const navbar = useSelector((state) => state.navbar);

  useEffect(() => {
    setIsExpanded(navbar.isNavbarExpanded);
  }, [navbar.isNavbarExpanded]);

  return (
    <Container>
      <Content id='page-main-content'>
        <Outlet />
      </Content>
      {isMobile && navigator && (
        <StyledNavigator
          isExpanded={isExpanded}
          toggleMenu={() => dispatch(setNavbarExpanded(!isExpanded))}
        />
      )}
    </Container>
  );
};

export default Main;
