import React, { useEffect, useRef } from 'react';

export const radioButtonOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

export const useDynamicRefs = (availableFields) => {
  const refMap = useRef({});
  useEffect(() => {
    availableFields?.forEach((field) => {
      refMap.current[field] = refMap?.current[field] || React.createRef();
    });
  }, []);

  return refMap?.current;
};
