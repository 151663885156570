import { useNavigate, useLocation } from 'react-router-dom';

import Button from 'ihp-components/components/v2/Button';

import OnboardingLayout from '../components/Layout';
import { ReactComponent as FTDDRLogo } from 'images/av-logo-vertical.svg';
import { Text, Title } from '../components/Text';

import { STUDY_ARM_ROLE_NAME } from 'constants/global';

import { Row, ButtonContainer } from './styles';

function NoRoleApplicable() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleContinue = () => {
    if (location?.state?.type === STUDY_ARM_ROLE_NAME.NO_RELATIONSHIPS) {
      navigate('/home');
    } else {
      navigate('/onboarding/create-profile');
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <OnboardingLayout maxWidth='415px' backHandler={goBack}>
      <Row>
        <FTDDRLogo />
      </Row>

      <Title color='primary.gray100' marginTop='10px'>
        We are sorry
      </Title>

      <Text fontWeight={400} marginTop='20px' lineHeight='140%'>
        You cannot enroll someone in this study if none of these relationships
        apply to you, so unfortunately you cannot enroll this individual in SOURCE.
        However, you may continue to receive educational materials, research opportunity notifications, 
        and updates on the progress of the study.
      </Text>

      <ButtonContainer>
        <Button fullWidth onClick={handleContinue}>
          Continue
        </Button>
      </ButtonContainer>
    </OnboardingLayout>
  );
}

export default NoRoleApplicable;
