import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const RadioContainer = styled(Box)({
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: '8px',
});

export const RadioOptionContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected'
})(({ theme, selected }) => ({
  padding: '0 15px',
  height: '36px',
  boxSizing: 'border-box',
  borderRadius: '8px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...selected ? {
    background: theme.palette.primary.fuchsia75,
  } : {
    background: theme.palette.primary.white,
    border: `1px solid ${theme.palette.primary.gray15}`,    
  },
}));
