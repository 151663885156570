import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  milestones: [
    {
      id: '001',
      name: 'Take a brain health survey',
      description: 'Take a brain health survey desc',
      effectiveFrom: '03/21/2022',
      effectiveTo: '06/20/2022',
      badge: 'Pellentesque',
      template: '',
      studyArm: '',
      cohort: '',
      type: 'Number of points',
      ruleConfiguration: {},
      notifications: {},
      celebrationScreen: {},
    },
    {
      id: '002',
      name: 'Complete your Medical History',
      description:
        'Complete your Medical History Complete your Medical History desc',
      effectiveFrom: '05/30/2022',
      effectiveTo: '06/29/2022',
      badge: 'Pellentesque',
      template: '',
      studyArm: '',
      cohort: '',
      type: 'Flow completed',
      ruleConfiguration: {},
      notifications: {},
      celebrationScreen: {},
    },
  ],
};

export const milestonesSlice = createSlice({
  name: 'milestone',
  initialState,
  reducers: {
    addMileStone: (state, { payload }) => {
      const newId = String(state.milestones.length).padStart('3', '0');
      state.milestones.push({ id: newId, ...payload });
    },
    deleteMilestone: (state, { payload }) => {
      state.milestones = state.milestones.filter(
        (milestone) => milestone.id !== payload.id
      );
    },
  },
});

export const selectMilestones = () => (state) => state.milestone.milestones;

export const { addMileStone, deleteMilestone } = milestonesSlice.actions;

export default milestonesSlice;
