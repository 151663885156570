import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as RemoteIcon } from 'images/icons/remote.svg';
import { HelpIconWrapper, HelpTitle } from './style';
import { Grid } from '@mui/material';
import HelpPopupContent from './HelpPopupContent/HelpPopupContent';
import HelpModel from './HelpModel/HelpModel';

const enabledRoutes = ['/my-activities', '/home', '/research-opportunities', '/education-materials', '/profile/accountholder-profile', '/profile/participant-profile', '/profile/consents', '/profile/my-providers', '/profile/change-status'];
const mobileEnabledRoutes = ['/home', '/education-materials', '/profile'];

const HelpWidget = () => {
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleShouldShowPopup = useCallback(() => {
    setShouldShowPopup((prev) => !prev);
  }, []);

  const location = useLocation();

  useEffect(() => {
    setShouldShowPopup(false);
  }, [location]);

  // For mobile phones contact us is overlapping a lot of other components
  // const shouldHide = !enabledRoutes.includes(location.pathname);
  const shouldHide = downSm
    ? !mobileEnabledRoutes.includes(location.pathname)
    : !enabledRoutes.includes(location.pathname);

  if (shouldHide) {
    return <></>;
  }

  return (
    <>
      {shouldShowPopup ? (
        <>
          {downSm && (
            <HelpModel toggleShouldShowPopup={toggleShouldShowPopup} />
          )}
          {!downSm && (
            <HelpPopupContent toggleShouldShowPopup={toggleShouldShowPopup} />
          )}
        </>
      ) : (
        <HelpIconWrapper onClick={toggleShouldShowPopup}>
          <Grid container spacing={1}>
            <Grid item>
              <RemoteIcon
                fill={downSm ? theme.palette.primary.helpBlue : 'white'}
                width='24px'
                height='24px'
              />
            </Grid>
            <Grid item>
              <HelpTitle>Contact Us</HelpTitle>
            </Grid>
          </Grid>
        </HelpIconWrapper>
      )}
    </>
  );
};

export default HelpWidget;
