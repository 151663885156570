import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import { TextField } from './TextField';

const CustomPickersDay = styled(PickersDay)(() => ({
  borderRadius: 0,
}));

const renderWeekPickerDay = (...[, , pickersDayProps]) => (
  <CustomPickersDay {...pickersDayProps} />
);

export const DatepickerInput = ({ defaultDate, ...rest }) => {
  const [date, setDate] = useState(null);
  const onChangeHandler = (value) => {
    setDate(value);
  };

  return (
    <StaticDatePicker
      onChange={onChangeHandler}
      ref={rest.inputRef}
      displayStaticWrapperAs='desktop'
      renderDay={renderWeekPickerDay}
      renderInput={(params) => <TextField {...params} />}
      {...rest}
      value={date}
    />
  );
};
