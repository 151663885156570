import { OverlayModal } from 'components/Modal';
import { SEND_INVITE_SUCCESS_MODAL } from 'features/familyLinkage/constants';
import { Title } from 'features/familyLinkage/styles';
import { ReactComponent as Completed } from 'images/shared/done-checkmark.svg';

export function FamilyLinkageInviteMemberSuccessModal({ onClose, open }) {
  const modalContent = (
    <>
      <Completed width={200} height={198} />
      <Title mb='20px'>{SEND_INVITE_SUCCESS_MODAL.title}</Title>
    </>
  );

  const modalButtonsConfig = [
    {
      text: SEND_INVITE_SUCCESS_MODAL.buttonText.close,
      handleClick: onClose,
    },
  ];

  return (
    <OverlayModal
      open={open}
      onClose={onClose}
      modalContent={modalContent}
      modalButtonsConfig={modalButtonsConfig}
    />
  );
}
