import patientHcp from 'images/welcome/patient-hcp.jpg';
import whoCanJoinImage from 'images/welcome/family-2-stock.jpg';
import howToJoinImage from 'images/welcome/family-1-stock.jpg';
import whatToExpect from 'images/welcome/family-3-stock.jpg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useCards = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const cards = [
    {
      image: patientHcp,
      title: 'Why Join SOURCE',
      color: 'primary.navy',
      backgroundColor: 'primary.tealTint',
      list: [
        'You’ll be part of the largest single ventricle heart defect research community',
        'It’s simple and free',
        'SOURCE is a long-term platform that will fuel not just one, but many research studies that will provide answers to research questions for years to come',
        'Together we can advance the understanding of single ventricle heart defects to improve care and outcomes',
      ],
      imageFirst: matches,
    },
    {
      image: whoCanJoinImage,
      title: 'Who Can Join',
      color: 'white',
      backgroundColor: 'primary.navy',
      list: [
        'Anyone living in the U.S. with a single ventricle heart defect and their immediate biological family members',
        'All ages and stages are welcome, from pre-Fontan to post-transplant',
      ],
      imageFirst: false,
    },
    {
      image: howToJoinImage,
      title: 'How to Join',
      color: 'primary.navy',
      backgroundColor: 'primary.mint',
      list: [
        'Create an account online',
        'Agree to share data and genetic information',
        'Answer questions about your health',
        'Share medical records about your heart if you have single ventricle',
        'Registration is simple and should take less than 30 minutes',
      ],
      imageFirst: matches,
    },
    {
      image: whatToExpect,
      title: 'What to Expect',
      color: 'primary.navy',
      backgroundColor: 'primary.green',
      list: [
        'Participation is online and free',
        'Your information will be kept private and secure',
        'Swab the inside of your cheek at home and return it in the mail to participate in genetic research',
        'We will keep you updated about research progress',
      ],
      imageFirst: false,
    },
  ];
  return cards;
};
