import { useNavigate } from 'react-router-dom';
import { Auth0Provider, Auth0Context } from '@auth0/auth0-react';

import { deferred } from 'ihp-bloom-redux/features/__base';

import { getAudience } from 'config/API';
import { AUTH0_SCOPES } from 'config';

export const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const AUDIENCE = getAudience();

  const onRedirectCallback = async (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname, {
      state: { context: appState?.context }, // was crashing app on first login,
    });
  };

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      {...props}
      scope={AUTH0_SCOPES}
      useRefreshTokens={true}
      audience={AUDIENCE}
    >
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently }) => {
          deferred.resolve(getAccessTokenSilently);
          return children;
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>
  );
};
