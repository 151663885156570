import React from 'react';
import { Grid, Box } from '@mui/material';
import {
  EmailContainer,
  Title,
  Description,
  TermsOfUseContainer,
} from 'components/Login';
import Button from 'ihp-components/components/v2/Button';
import { FooterNote } from 'components/shared';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';
import { useNavigate } from 'react-router-dom';

const styles = {
  formContainer: {
    paddingTop: '25px',
    width: '95%',
  },
  logoContainer: {
    padding: '140px 0',
  },
};

function ViewConsentMobile() {
  let navigate = useNavigate();

  const navigateToInformedConsent = () => {
    navigate('/informedconsent');
  };

  return (
    <Grid container sx={styles.container}>
      <Grid item xs={12}>
        <EmailContainer>
          <Box sx={styles.logoContainer}>
            <Logo width='100px' />
          </Box>
          <Title>You’re almost finished</Title>
          <Description>
            Now we will ask you to review and sign the consent to participate in
            the study. This includes an explanation of the data that will be
            collected in this study.
          </Description>
          <Box sx={styles.formContainer} component='div'>
            <Button fullWidth={true} onClick={navigateToInformedConsent}>
              View Consent
            </Button>
          </Box>
          <TermsOfUseContainer>
            By signing in you accept our
            <FooterNote text=' Terms of Use' /> and
            <FooterNote text=' Privacy Policy' />
          </TermsOfUseContainer>
        </EmailContainer>
      </Grid>
    </Grid>
  );
}
export default ViewConsentMobile;
