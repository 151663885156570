import { Link, useLocation, useNavigate } from 'react-router-dom';
import OnboardingLayout from '../components/Layout';
import Button from 'ihp-components/components/v2/Button';
import {
  ButtonContainer,
  MoreDetail,
  PageTitle,
  Row,
  TitleContainer,
  Content,
  HandshakeLogo,
} from './style';
import { isUnderAge } from 'pages/Onboarding/NotEligible/helpers';
import { useCallback } from 'react';

function NotEligiblePage() {
  document.title = 'Single Ventricle SOURCE Not Eligible';
  const navigate = useNavigate();
  const { state } = useLocation();
  const ineligibilityReasons = state?.ineligibilityReasons || [];

  const backToSignUpHandler = useCallback(() => {
    navigate('/onboarding/welcome-to-study', {
      replace: true,
    });
  }, [navigate]);

  return (
    <OnboardingLayout maxWidth='500px' backHandler={backToSignUpHandler}>
      <Row>
        <HandshakeLogo />
      </Row>

      <TitleContainer>
        <PageTitle variant='h2'>Thank you for your interest</PageTitle>
      </TitleContainer>

      <MoreDetail>
        <Content>
          {isUnderAge(ineligibilityReasons) ? (
            <>
              Thank you for your interest in joining SOURCE. Unfortunately, you
              are currently unable to enroll yourself because you are too young.
              Please share our website and study information with your parent or
              guardian, and they can complete your enrollment for you.
            </>
          ) : ineligibilityReasons.length === 0 ? (
            <>
              Thank you for your interest in joining SOURCE. Unfortunately, we
              are unable to enroll you because you do not meet the study
              enrollment criteria at this time.
            </>
          ) : (
            <>
              If you would like to check in on the progress of SOURCE on your
              own in the future, please visit us at{' '}
              <Link to='https://svsource.org' replace>
                www.svsource.org
              </Link>{' '}
              ** or follow SOURCE on social media.
            </>
          )}
        </Content>
      </MoreDetail>

      <ButtonContainer>
        <Button fullWidth onClick={backToSignUpHandler}>
          Back to sign up
        </Button>
      </ButtonContainer>
    </OnboardingLayout>
  );
}

export default NotEligiblePage;
