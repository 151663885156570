import React from 'react';
import { Box } from '@mui/material';
import { LoginContainer, EmailContainer, FormLabel } from 'components/Login';
import Button from 'ihp-components/components/v2/Button';
import { useNavigate } from 'react-router-dom';
import { Input } from 'ihp-components/components/v2/Input';

const styles = {
  icon: {
    marginRight: 1,
    width: 18,
    height: 20,
  },
  formContainer: {
    paddingTop: '25px',
    width: '100%',
  },
};

function LoginWithEmailMobile() {
  let navigate = useNavigate();

  const navigateToPersonalInformation = () => {
    navigate('/personalinformation');
  };

  return (
    <LoginContainer>
      <EmailContainer>
        <Box sx={styles.formContainer} component="div">
          <FormLabel>Enter your email address</FormLabel>
        </Box>
        <Box sx={styles.formContainer} component="div">
          <Input fullWidth={true} placeholder="Email" />
        </Box>
        <Box sx={styles.formContainer} component="div">
          <Button
            fullWidth={true}
            onClick={navigateToPersonalInformation}
          >
            Continue
          </Button>
        </Box>
      </EmailContainer>
    </LoginContainer>
  );
}
export default LoginWithEmailMobile;
