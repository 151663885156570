import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedPaymentMethod: '',
  tokenId: '',
  api: '',
  url: '',
  campaignId: '',
  products: [],
};

export const tremendousSlice = createSlice({
  name: 'tremendous',
  initialState,
  reducers: {
    setValues: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    },
  },
});

export const { setValues, decrement } = tremendousSlice.actions;

export default tremendousSlice.reducer;
