import { Box, Modal, Typography, useTheme } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { useNavigate } from 'react-router-dom';
import { getStyles } from './styles';

export const TimeSlotsErrorModal = ({ open, onClose }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalContainer}>
        <Box sx={styles.infoBox}>
          <Typography variant='h4' sx={styles.info}>
            This timeslot is unavailable
          </Typography>
          <Typography variant='pl3' sx={styles.subInfo}>
            Somebody just booked this timeslot, please select a different time
          </Typography>
        </Box>
        <Button variant='contained' fullWidth onClick={() => onClose()}>
          Restart booking
        </Button>
        <Button
          variant='link'
          onClick={() => navigate('/my-activities')}
          width='100%'
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
