import { ArrowForwardIos } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetAllResourcesQuery } from 'ihp-bloom-redux/features/resource/resourceApiSlice';

import EducationalMaterialsList from '../../../components/EducationalMaterials';
import { Loader } from 'components/Loader';

import {
  Container,
  getStyles,
  Header,
  Title,
  ViewAllButton,
  ViewAllLink,
} from './style';

function EducationalMaterials() {
  const navigate = useNavigate();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { person } = useSelector((state) => state.user);

  const { isLoading, items } = useGetAllResourcesQuery(
    {
      'filter[person]': person?.id,
      include: 'person-resource',
      'page[page]': 1,
      'page[limit]': 2,
      sort: '-createdAt',
    },
    {
      skip: person?.id ? false : true,
      selectFromResult: ({ data, isLoading, isUninitialized, isFetching }) => {
        return {
          isLoading: isLoading || isUninitialized || isFetching,
          items: data?.data?.map((item) => {
            const id = item.id;
            return {
              id,
              title: item?.attributes['title'],
              subtitle: item?.attributes['description'],
              description: item?.attributes['summary'],
              resourceStatus: item?.attributes['status'],
              link: item?.attributes['link'],
              status: data?.included
                ? data?.included.find(
                    (includedItem) =>
                      includedItem.attributes.resource_id === parseInt(item?.id)
                  )?.attributes?.status
                : null,
            };
          }),
        };
      },
    }
  );

  return (
    <>
      {items && items.length > 0 && (
        <Container>
          <Header>
            <Title>Information for you</Title>
            <ViewAllLink
              onClick={() => {
                navigate(`/education-materials`);
              }}
            >
              View all <ArrowForwardIos style={styles.icon} />
            </ViewAllLink>
          </Header>
          {isLoading ? (
            <Loader />
          ) : (
            items &&
            items.length > 0 && <EducationalMaterialsList resources={items} />
          )}

          <ViewAllButton
            endIcon={<ArrowForwardIos style={styles.icon} />}
            onClick={() => navigate(`/education-materials`)}
          >
            View All
          </ViewAllButton>
        </Container>
      )}
    </>
  );
}

export default EducationalMaterials;
