import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { studyApiSlice } from 'ihp-bloom-redux/features/study/studyApi';
import { studyArmConfigApiSlice } from 'ihp-bloom-redux/features/studyArms/studyArmConfigApiSlice';
import { setValues } from 'ihp-bloom-redux/features/settings/configurationSlice';

import { Loader } from 'components/Loader';
import { VIRTUAL_SITE } from 'constants/global';

function StudyConfigProvider({ children }) {
  const dispatch = useDispatch();

  const { configuration, studyConfiguration } = useSelector(
    (state) => state.configuration
  );

  const hasStudyAttributes = Boolean(
    Object.keys(studyConfiguration || {})?.length
  );
  let hasStudyArmConfigurations = true;
  Object.keys(configuration || {})?.forEach((item) => {
    if (!Object.keys(configuration[item] || {})?.length) {
      hasStudyArmConfigurations = false;
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    /**
     * This function is responsible to fetch and save both study attributes and study arm configurations
     */
    async function fetchConfigurations() {
      setIsLoading(true);
      try {
        const [studyAttributesResponse, studyArmConfigResponse] =
          await Promise.all([
            dispatch(
              studyApiSlice.endpoints.getStudyAttributes.initiate(
                VIRTUAL_SITE.id
              )
            ),
            dispatch(
              studyArmConfigApiSlice.endpoints.getStudyArmConfig.initiate()
            ),
          ]);
        /**
         * In case of error in any api add proper handling here
         */
        if (
          studyAttributesResponse?.isError ||
          studyArmConfigResponse?.isError
        ) {
          console.log(
            'Something went wrong while fetching study attributes with study arm configurations'
          );
        }

        setIsLoading(false);

        /**
         * Save response to state for later use
         */
        if (
          studyAttributesResponse?.data?.data &&
          studyArmConfigResponse?.data
        ) {
          dispatch(
            setValues({
              studyConfiguration: studyAttributesResponse?.data?.data,
              configuration: studyArmConfigResponse?.data,
            })
          );
        }
      } catch (error) {
        console.log(
          'Something went wrong while fetching study attributes with study arm configurations'
        );
        setIsLoading(false);
      }
    }

    fetchConfigurations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStudyAttributes, hasStudyArmConfigurations]);

  if (isLoading) {
    return <Loader />;
  }

  return <>{children}</>;
}

export default StudyConfigProvider;
