import { Pagination } from '@mui/material';
import { styled } from '@mui/system';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    justifyContent: 'center',
    marginTop: 10,
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.white,
      '&: hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));
