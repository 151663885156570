import { Box, Button, styled, Typography } from '@mui/material';
import { LinkButton } from '../../../../../components/Buttons';


export const ModalContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',
  width: '100%',
  maxWidth: '720px',
  height: '90%',
  borderRadius: '10px',
  boxShadow: 24,
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '40px',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '0px 0px 30px 0px rgba(0, 84, 135, 0.05)',
}));

export const PhoneNumberVerificationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '30px',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '5px',
  // width: 295px;
}));

export const LogoAndTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '20px',
  // width: 295px;
}));

export const Title = styled(Typography)(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray100,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
}));

export const SMSNotice = styled(Typography)(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray70,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
}));

export const VerificationCodeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '25px',
}));

export const CodeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
}));

export const CodeItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '50px',
  padding: '15px 20px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.primary.gray25}`,
  background: theme.palette.primary.white,
}));

export const VerificationLinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.darkBlue100,
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
  letterSpacing: '0.28px',
  display: 'flex',
  padding: '12px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  textTransform: 'none',
}));

export const VerificationButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '15px',
}));

export const getStyles = (theme) => {
  return {
    verifyButton: {
      padding: '17px 30px',
    },
    resendCodeButton: {
      color: theme.palette.primary.darkBlue100,
      background: theme.palette.primary.white,
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '140%',
      letterSpacing: '0.28px',
      display: 'flex',
      padding: '12px 20px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '12px',
      alignSelf: 'stretch',
      borderRadius: '8px',
      textTransform: 'none',
      '&:hover': {
        background: 'none',
        color: theme.palette.primary.darkBlue100,
        cursor: 'pointer',
      },
    },
    otpInput: {
        width: '50px',
        height: '50px',
        padding: '15px 20px',
        borderRadius: '8px',
        border: `1px solid ${theme.palette.primary.gray25}`,
        background: theme.palette.primary.white,
        color: theme.palette.primary.darkblue100,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '140%'
    },
  };
};
