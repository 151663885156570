import { useEffect, useState } from 'react';
import { CircularProgress, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Box, Stack } from '@mui/material';
import { SkipLink } from 'components/Onboarding';
import VideoPlayer from 'components/VideoPlayer';
import { useSearchParams } from 'react-router-dom';
import { ProviderCardV2 } from '../../ProviderCardV2';
import { ManualProviderModal } from '../Modal/manualProviderModal';
import { EmptyProviders } from './EmptyProviders';
import {
  AddProviderLinkWrapper,
  Container,
  MainContainer,
  NoProvidersSubtitle,
  SearchResultContainer,
  EmptyProvidersWrapper,
} from './styles';
import { EMR_VIDEO_URL } from 'config/API';
import { ReactComponent as NoResults } from 'images/data-sync/no-results.svg';

const ProvidersList = ({
  providers,
  connectedProviders,
  loadingProviders,
  setLoadingProviders,
  setIsRedirecting,
  hasConnectedProviders,
  isLoading,
  hasSearchTerm,
  setModalOpen,
  addManualProvider,
  deleteManualProvider,
  taskPersonId,
  namesMap,
  personName,
  isChildTask,
}) => {
  const navigate = useNavigate();

  const [connectingProvider, setConnectingProvider] = useState(null);
  let loader = <></>;
  const connectedIds = connectedProviders.map((cp) =>
    parseInt(cp?.attributes?.health_system_id)
  );
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const participantTaskId = searchParams.get('id');

  useEffect(() => {
    if (selectedProvider?.id) {
      setIsOpenModal(true);
    }
  }, [selectedProvider]);

  const videoElement = (
    <Box>
      <VideoPlayer
        src={EMR_VIDEO_URL}
        height={isMobile ? 'auto' : '230px'}
        width={isMobile ? '95%' : '409px'}
        poster={''}
        controls
        containerStyle={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      />
    </Box>
  );

  if (loadingProviders) {
    let text = connectingProvider
      ? `Connecting to ${connectingProvider} health provider`
      : 'Loading Medical Providers';
    loader = (
      <Box sx={{ margin: '0 0 -15px 0' }}>
        <Stack sx={{ display: 'flex', alignItems: 'center' }}>
          <EmptyProvidersWrapper>
            <EmptyProviders
              loadingProviders={loadingProviders}
              loader={hasConnectedProviders && loader}
              isLoading={isLoading}
              hasConnectedProviders={hasConnectedProviders}
            />
          </EmptyProvidersWrapper>
          <CircularProgress
            sx={{ marginTop: hasConnectedProviders ? '50px' : '50px' }}
            color='secondary'
          />
          <NoProvidersSubtitle>{text}</NoProvidersSubtitle>
          <NoProvidersSubtitle>
            Please wait, this can take up to 5 seconds
          </NoProvidersSubtitle>
        </Stack>
      </Box>
    );

    return loader;
  }

  const providersToShow =
    providers?.length === 0 ? connectedProviders : providers;

  if (providersToShow.length === 0 && !hasSearchTerm) {
    return (
      <EmptyProvidersWrapper>
        {!hasConnectedProviders && videoElement}
        <EmptyProviders
          loadingProviders={loadingProviders}
          loader={hasConnectedProviders && loader}
          hasConnectedProviders={hasConnectedProviders}
          isLoading={isLoading}
        />
      </EmptyProvidersWrapper>
    );
  }

  if (providers.length === 0 && hasSearchTerm) {
    return (
      <MainContainer>
        <Stack sx={{ alignItems: 'center', justifyItems: 'center' }}>
          {/* {addManualProviderLink} */}
          <Box sx={{ marginTop: '20px' }}>
            <NoResults />
          </Box>
          <Box
            sx={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '140%',
              margin: '37px',
              color: 'primary.darkBlue70',
              marginTop: '10px',
            }}
          >
            No results
          </Box>
        </Stack>
      </MainContainer>
    );
  }

  return (
    <Container sx={isMobile ? { maxHeight: 700, overflowY: 'auto' } : {}}>
      {hasSearchTerm && (
        <SearchResultContainer>SEARCH RESULTS</SearchResultContainer>
      )}
      {[
        ...providersToShow.map((provider, index) => {
          return (
            <ProviderCardV2
              isLast={providersToShow.length - 1 === index}
              key={provider.id}
              provider={provider}
              onManualProviderSelect={() => {
                setSelectedProvider(provider);
                setIsOpenModal(true);
              }}
              onConnectedProviderSelect={() => {}}
              noRadius={true}
              setLoadingProviders={setLoadingProviders}
              setIsRedirecting={setIsRedirecting}
              setConnectingProvider={setConnectingProvider}
              connected={connectedIds.includes(
                parseInt(provider?.attributes?.health_system_id ?? provider?.id)
              )}
              addManualProvider={addManualProvider}
              hasSearchTerm={hasSearchTerm}
              taskPersonId={taskPersonId}
              patientName={namesMap?.[parseInt(provider?.health_system_id)]}
              personName={personName}
              isChildTask={isChildTask}
            />
          );
        }),
      ]}
      {selectedProvider && (
        <ManualProviderModal
          open={isOpenModal}
          onClose={() => {
            setIsOpenModal(false);
            setSelectedProvider(null);
          }}
          onCancel={() => {
            setIsOpenModal(false);
            setSelectedProvider(null);
          }}
          participantTaskId={participantTaskId}
          addManualProvider={addManualProvider}
          removeManualProvider={deleteManualProvider}
          provider={{
            id: selectedProvider?.id,
            provider_name: selectedProvider?.name,
            phone_number: selectedProvider?.phone_number,
            city: selectedProvider?.locations[0]?.address.city,
            state: selectedProvider?.locations[0]?.address.state,
          }}
        />
      )}
    </Container>
  );
};

export default ProvidersList;
