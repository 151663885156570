import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Title, Line, SectionTitle } from 'components/InformedConsent';
import { FormCheckbox } from 'components/Checkbox';
import ImageRow from './ImageRow';
import getStyles from './BodyContainer.style';
import gamesIcon from 'images/study/games.svg';
import surveysIcon from 'images/study/surveys.svg';
import healthDataIcon from 'images/study/healthData.svg';
import videosIcon from 'images/study/videos.svg';
import followupsIcon from 'images/study/followUps.svg';
import labTestsIcon from 'images/study/labTests.svg';
import pollsIcon from 'images/study/polls.svg';

function BodyContainer({ title, data, consentAgreed, onToggle }) {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Grid item xl={7} xs={12} sx={styles.mainContainer}>
      <Box sx={styles.mainBox}>
        <Box sx={styles.titleContainer}>
          <Title>{title}</Title>
          <Line />
        </Box>
        {title === 'STUDY OVERVIEW' && (
          <Typography variant='h5' color='secondary.gray4' pb='30px'>
            BLOOM is a virtual research study sponsored by IQVIA, in
            collaboration with Apple, for participants between the ages of 21
            and 86. By joining us, you can help researchers investigate the role
            an Apple Watch and iPhone.
          </Typography>
        )}
        {data.map((row) => (
          <Box key={row.id}>
            <SectionTitle>{row.section}</SectionTitle>
            <Box sx={styles.sectionBox}>
              <Typography
                variant='pl3'
                color='primary.gray75'
                dangerouslySetInnerHTML={row.body}
              ></Typography>
            </Box>
          </Box>
        ))}
        {title === 'STUDY OVERVIEW' && (
          <>
            <ImageRow icon={gamesIcon} label='Games' time='10 / week' />
            <ImageRow icon={surveysIcon} label='Surveys' time='5 / week' />
            <ImageRow
              icon={healthDataIcon}
              label='Health data'
              time='1 / week'
            />
            <ImageRow icon={videosIcon} label='Videos' time='1 / month' />
            <ImageRow
              icon={followupsIcon}
              label='Follow-ups'
              time='1 / month'
            />
            <ImageRow icon={labTestsIcon} label='Lab tests' time='5 / week' />
            <ImageRow icon={pollsIcon} label='Polls' time='10 / week' />
          </>
        )}
        {title === 'INFORMED CONSENT' && (
          <FormCheckbox
            label='I Agree with the Consent'
            containerStyle={styles.checkboxContainer}
            sx={styles.checkbox}
            checked={consentAgreed}
            onChange={onToggle}
          />
        )}
      </Box>
    </Grid>
  );
}
BodyContainer.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onToggle: PropTypes.func,
  consentAgreed: PropTypes.bool,
};
export default BodyContainer;
