import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetProfileParticipantTasksQuery } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { pipe } from 'utils/misc';
import {
  filterParticipantTasks,
  formatParticipantTasks,
  mapTaskRequiredStatus,
} from 'utils/tasks';

export function useActiveConsents(
  { enabled } = {
    enabled: true,
  }
) {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const {
    data: response,
    isLoading,
    isError,
    isSuccess,
    refetch,
  } = useGetProfileParticipantTasksQuery(
    {
      accountProfileId: accountProfileId,
      params: {
        task_type: 'consent',
        display_status: 'active,overdue',
      },
    },
    {
      skip: typeof accountProfileId === 'undefined' || !enabled,
    }
  );

  let consents = [];

  if (response?.data?.length && consents.length === 0) {
    consents = pipe(
      filterParticipantTasks,
      formatParticipantTasks,
      (consents) =>
        consents
          .filter((consent) => {
            return (
              consent.status !== 'completed' ||
              mapTaskRequiredStatus(consent.required) !== 'REQUIRED'
            );
          })
          .map((item) => ({
            ...item,
            statusDate: item.consentStatusDate ?? item.statusDate,
          }))
    )(response.data);
  }

  return {
    data: consents,
    isError,
    isLoading,
    isSuccess,
    refetch,
  };
}

export function usePastConsents() {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const {
    data: response,
    isLoading,
    isError,
  } = useGetProfileParticipantTasksQuery(
    {
      accountProfileId: accountProfileId,
      params: {
        task_type: 'consent',
        display_status: 'past',
      },
    },
    {
      skip: typeof accountProfileId === 'undefined',
    }
  );

  let consents = [];

  if (response?.data?.length && consents.length === 0) {
    consents = pipe(
      filterParticipantTasks,
      formatParticipantTasks,
      (consents) =>
        consents.map((item) => ({
          ...item,
          statusDate: item.consentStatusDate ?? item.statusDate,
        }))
    )(response.data);
  }

  return {
    data: consents,
    isError,
    isLoading,
  };
}
