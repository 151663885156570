import { getStyles, LearnMoreButton } from './style';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ReactComponent as HospitalIcon } from 'images/shared/institute.svg';
import { useTheme } from '@emotion/react';
import { ArrowForwardIos } from '@mui/icons-material';
import { Card } from '../../Cards';
import Chip from 'ihp-components/components/v2/Chip';
import { statusMapping } from './statusMapping';
import { OpportunityStatusModel } from '../OpportunityStatusModel';
import { useNavigate } from 'react-router-dom';
import ResearchOpportunityStatus from '../../../constants/researchOpportunityStatus';

function ExternalResearchOpportunityList({ opportunities, refetch = null }) {
  const navigate = useNavigate();
  const [localOpportunities, setLocalOpportunities] = useState(opportunities);
  const theme = useTheme();
  const styles = getStyles(theme);
  const statusMap = statusMapping(theme);
  const [
    showSelectOpportunityStatusModal,
    setShowSelectOpportunityStatusModal,
  ] = useState(false);
  const [selectOpportunityStatus, setSelectOpportunityStatus] = useState(null);

  const truncateTextStyle = (lineSize) => ({
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: lineSize,
  });

  const toggleSelectOpportunityStatusModal = (status) => {
    if (status && !refetch) {
      const updatedOpportunities = [];
      localOpportunities.map((opportunity, key) => {
        if (+opportunity?.id === +selectOpportunityStatus?.id) {
          if (status !== ResearchOpportunityStatus.UNSUBSCRIBE?.key) {
            opportunity.status = status;
            updatedOpportunities.push(opportunity);
          }
        } else {
          updatedOpportunities.push(opportunity);
        }
      });
      setLocalOpportunities([...updatedOpportunities]);
    }
    if (refetch) {
      refetch?.();
    }
    setShowSelectOpportunityStatusModal(!showSelectOpportunityStatusModal);
  };

  const handleClick = (opportunity) => {
    setSelectOpportunityStatus(opportunity);
    setShowSelectOpportunityStatusModal(true);
  };
  useEffect(() => {
    setLocalOpportunities(opportunities);
  }, [opportunities]);

  return (
    <>
      {localOpportunities.map((opportunity) => {
        let tag = statusMap[opportunity?.status];
        if (!tag) {
          tag = statusMap['status'];
        }
        const customStyle = { backgroundColor: tag.color, cursor: 'pointer' };
        const customStatusStyle = {
          borderColor: theme.palette.primary.darkGray70,
          color: theme.palette.primary.darkGray70,
        };
        return (
          <Card
            key={opportunity.id}
            title={opportunity.title}
            subtitle={opportunity.study_sponsors_name}
            description={opportunity.description}
            mainIcon={<HospitalIcon style={styles.hospitalIcon}></HospitalIcon>}
            styles={{
              cardContentStyle: { ...truncateTextStyle(4) },
              cardTitleStyle: truncateTextStyle(2),
            }}
            tags={
              <Chip
                icon={tag?.icon}
                variant={tag?.variant}
                type={tag?.type}
                sx={tag?.text === 'Status' ? customStatusStyle : customStyle}
                onClick={() => handleClick(opportunity)}
              >
                {tag?.text}
              </Chip>
            }
            actionButton={
              <LearnMoreButton
                disableRipple
                endIcon={<ArrowForwardIos style={styles.icon} />}
                onClick={() =>
                  navigate(`/research-opportunities/${opportunity.id}`)
                }
              >
                Learn more
              </LearnMoreButton>
            }
          />
        );
      })}
      {showSelectOpportunityStatusModal && (
        <OpportunityStatusModel
          isOpen={showSelectOpportunityStatusModal}
          onClose={toggleSelectOpportunityStatusModal}
          selectOpportunityStatus={selectOpportunityStatus}
        />
      )}
    </>
  );
}

ExternalResearchOpportunityList.propTypes = {
  opportunities: PropTypes.array.isRequired,
};

export default ExternalResearchOpportunityList;
