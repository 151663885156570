import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useEffect } from 'react';

export const useGetCurrentUser = () => {
  const { isAuthenticated } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  const {
    person,
    userId,
    authenticationStatus,
    isLoading,
    isSuccess,
    ...rest
  } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated,
    selectFromResult: ({ data, ...rest }) => ({
      person: data?.data?.attributes.person_id
        ? { id: data?.data?.attributes.person_id }
        : null,
      userId: parseInt(data?.data?.id),
      authenticationStatus: data?.data?.attributes.authentication_status,
      ...rest,
    }),
  });

  useEffect(() => {
    if (isSuccess) {
      const userPayload = {
        person: person,
        userId: userId,
        authenticationStatus: authenticationStatus,
      };
      dispatch(setValues(userPayload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return {
    person,
    userId,
    authenticationStatus,
    isLoading,
    isSuccess,
    ...rest,
  };
};
