import PropTypes from 'prop-types';
import { Box, Tab as MUITab, Tabs as MUITabs } from "@mui/material";
import { styled } from '@mui/material/styles';

export const Tabs = styled(MUITabs)({
  '& .MuiTabs-indicator': {
    height: '5px',
    borderRadius: '2px'
  }
});

export const Tab = styled(MUITab)(({ theme }) => ({
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: theme.typography.pxToRem(16),
  lineHeight: '140%',
  color: theme.palette.primary.gray50,
  padding: 0,
  margin: '10px 30px 10px 0px',
  minWidth: 'unset',
  '&:hover': {
    color: theme.palette.primary.gray100,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.gray100,
    fontWeight: 600,
  },
}));

export const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    role='tabpanel'
    hidden={value !== index}
    id={`settings-tabpanel-${index}`}
    aria-labelledby={`settings-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box>
        {children}
      </Box>
    )}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};