import { DatepickerInput } from './DatePickerInput';
import { CustomDatePicker } from './CustomDatePicker';
import { TextField } from './TextField';
import { SelectInput } from './Select';
import PropTypes from 'prop-types';
import { ColorPicker } from './ColorPicker';
import { Textarea } from './TextArea';
import { RadioInputGroup } from './RadioInput';
import { SelectAutoComplete } from './SelectAutoComplete';
import { FormCheckbox } from './Checkbox';

const INPUT_TYPES = {
  text: 'text',
  number: 'number',
  datePicker: 'datePicker',
  dateTimePicker: 'dateTimePicker',
  timePicker: 'timePicker',
  checkbox: 'checkbox',
};

const Input = ({ type = 'text', ...rest }) => {
  switch (type) {
    case 'text':
      return <TextField type={type} {...rest} />;
    case 'number':
      return <TextField type={type} {...rest} />;
    case 'radioInputGroup':
      return <RadioInputGroup {...rest} />;
    case 'textArea':
      return <Textarea {...rest} />;
    case 'datePicker':
      return <CustomDatePicker {...rest} />;
    case 'staticDatePicker':
      return <DatepickerInput {...rest} />;
    case 'colorPicker':
      return <ColorPicker {...rest} />;
    case 'select':
      return <SelectInput {...rest} />;
    case 'selectAutocomplete':
      return <SelectAutoComplete {...rest} />;
    case 'checkbox':
      return <FormCheckbox {...rest} />;
    default:
      return null;
  }
};

Input.propTypes = {
  type: PropTypes.oneOf(Object.keys(INPUT_TYPES)),
  id: PropTypes.string,
  error: PropTypes.bool,
  errorLabel: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  icon: PropTypes.node,
  startIcon: PropTypes.node,
  label: PropTypes.node,
  defaultDate: PropTypes.any,
};

export { Input };
