import * as React from 'react';
import Box from '@mui/material/Box';
import Progress from './progress';
import Timeline from './timeline';
import StudyMobile from './index.mobile';
import { useTheme } from '@mui/material/styles';
import { TimelineTitle } from 'components/Study/overview';
import {
  OverviewTitle,
  LeftStudyContainer,
  OverviewContainer,
  OverviewMainTitleContainer,
  StudyContainer,
  TimelineContainer,
} from 'components/Study';
import { useState } from 'react';
import { OverviewMainTitle } from 'mocks/study';
import StudyOverviewModal from './overviewModal';
import { useMediaQuery } from '@mui/material';
import { useParticipantsTasksData } from './useParticipantsTasksData';

export default function Study() {
  document.title = 'Single Ventricle SOURCE Overview';
  const [overviewModalOpen, setOverviewModalOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  let [taskOccurrencies] = useState({});

  const {
    availableTasks,
    upcomingTasks,
    isLoadingResults,
    completedTasks,
    totalTasks,
  } = useParticipantsTasksData();

  if (isMobile) {
    return (
      <StudyMobile
        selectedPanel={0}
        taskOccurrencies={taskOccurrencies}
        totalTasks={totalTasks}
        tasks={{ completedTasks, availableTasks, upcomingTasks }}
        isLoadingTasks={isLoadingResults}
      />
    );
  }

  return (
    <StudyContainer sx={{ backgroundColor: theme.palette.primary.gray2 }}>
      <LeftStudyContainer>
        <Progress taskOccurrencies={taskOccurrencies} totalTasks={totalTasks} />
        <Box>
          <OverviewTitle>Study overview</OverviewTitle>
          <OverviewContainer
            sx={{ backgroundColor: theme.palette.primary.white }}
          >
            <img
              alt='overview'
              src={''}
              height='250px'
              width='150px'
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <OverviewMainTitleContainer>
                <div dangerouslySetInnerHTML={{ __html: OverviewMainTitle }} />
              </OverviewMainTitleContainer>
            </Box>
          </OverviewContainer>
        </Box>
      </LeftStudyContainer>
      <TimelineContainer>
        <TimelineTitle></TimelineTitle>
        <Timeline
          tasks={{ completedTasks, availableTasks, upcomingTasks }}
          isLoadingTasks={isLoadingResults}
        />
      </TimelineContainer>
      <StudyOverviewModal
        isOpen={overviewModalOpen}
        onClose={() => setOverviewModalOpen(false)}
      />
    </StudyContainer>
  );
}
