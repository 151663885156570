import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledLabel = styled(Typography)(({ theme, color, disabled }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  color: color || theme.palette.primary.main,
  cursor: 'pointer',
  opacity: disabled ? 0.6 : 1,
}));

const StyledLabelBox = styled(Box)(({ disabled, height }) => ({
  display: 'flex',
  height: height || '56px',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  pointerEvents: disabled ? 'none' : 'initial',
}));

export const LinkButton = ({ children, onClick, color, disabled, ...rest }) => (
  <StyledLabelBox {...rest} disabled={disabled}>
    <StyledLabel onClick={onClick} color={color} disabled={disabled}>
      {children}
    </StyledLabel>
  </StyledLabelBox>
);
