import { useFetchAllPatientTasksQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useMemo } from 'react';

export const useParticipantsTasksData = () => {
  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const {
    data,
    isLoading: isLoadingResults,
    isUninitialized,
  } = useFetchAllPatientTasksQuery(person?.id, {
    skip: !person?.id,
  });

  // gets the total points in an array of tasks
  const getTasksPoints = (tasksArray) => {
    tasksArray.reduce((prevVal, currentVal) => {
      prevVal += currentVal.task.points;
      return prevVal;
    }, 0);
  };

  const results = useMemo(() => {
    if (!data) return {};
    const allTasks = data?.data || [];
    const allTasksPoints = getTasksPoints(allTasks);
    const totalTasks = allTasks.length;
    const currentDate = Date.now();
    const completedTasks = allTasks?.filter(
      (task) => task.status === 'completed'
    );
    const incompleteTasks = allTasks?.filter(
      (task) => task.status === 'incomplete'
    );

    const getAllTasksByType = (type) => {
      return allTasks?.filter((task) => task.task.type === type) ?? [];
    };

    const getCompletedTasksByType = (type) => {
      return completedTasks?.filter((task) => task.task.type === type) ?? [];
    };

    const completedTasksCount = completedTasks.length;
    const availableTasks = allTasks?.filter(
      (task) =>
        new Date(task?.start_date) < currentDate && task.status !== 'completed'
    );
    const upcomingTasks = allTasks?.filter(
      (task) => new Date(task?.start_date) > currentDate
    );

    // grouped by completion type
    const surveyTasks = getAllTasksByType('survey');
    const completedSurveyTasks = getCompletedTasksByType('survey');

    const gameTasks = getAllTasksByType('game');
    const completedGameTasks = getCompletedTasksByType('game');

    const videoTasks = getAllTasksByType('video');
    const completedVideoTasks = getCompletedTasksByType('video');

    const callTasks = getAllTasksByType('call');
    const completedCallTasks = getCompletedTasksByType('call');

    const dataSharingTasks = getAllTasksByType('data_sharing');
    const completedDataSharingTasks = getCompletedTasksByType('data_sharing');

    const callScheduleTasks = getAllTasksByType('call_schedule');
    const completedCallScheduleTasks = getCompletedTasksByType('call_schedule');

    const labTestTasks = getAllTasksByType('lab_test');
    const completedLabTestTasks = getCompletedTasksByType('lab_test');

    const devicesTasks = getAllTasksByType('devices');
    const completedDevicesTasks = getCompletedTasksByType('devices');

    return {
      // main data
      allTasks,
      completedTasks,
      incompleteTasks,
      availableTasks,
      upcomingTasks,
      // numbers
      totalTasks,
      completedTasksCount,
      allTasksPoints,
      // grouped by task type
      surveyTasks,
      callTasks,
      dataSharingTasks,
      callScheduleTasks,
      gameTasks,
      videoTasks,
      labTestTasks,
      devicesTasks,
      // grouped by type completion
      completedSurveyTasks,
      completedGameTasks,
      completedVideoTasks,
      completedCallTasks,
      completedDataSharingTasks,
      completedCallScheduleTasks,
      completedLabTestTasks,
      completedDevicesTasks,
    };
  }, [data]);

  return { ...results, isLoadingResults: isUninitialized || isLoadingResults };
};
