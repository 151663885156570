import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { useGetPatientTasksQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import { BaseHeader } from 'components/BaseLayout';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import { PregnancyJournalItemCard } from './PregnancyJournalItemCard';
import { AddButton, Content, Header, StyledBaseLayout } from './styles';

const PregnancyJournalDesktop = () => {
  const { data } = useGetPatientTasksQuery({
    paginate: false,
  });
  const taskEntries =
    data?.data?.find?.(({ task: { id } }) => id === 562)?.task_entries || [];

  return (
    <StyledBaseLayout
      header={
        <Header>
          <BaseHeader to='/profile'>Pregnancy journal</BaseHeader>
          <Stack direction='row' spacing='15px'>
            <AddButton component={Link} to='create' startIcon={<PlusIcon />}>
              Add
            </AddButton>
          </Stack>
        </Header>
      }
      content={
        <Content>
          {taskEntries.map(({ id, created_at, data }) => (
            <PregnancyJournalItemCard
              key={id}
              id={id}
              createdAt={created_at}
              data={data}
            />
          ))}
        </Content>
      }
    />
  );
};

export default PregnancyJournalDesktop;
