import { createSlice } from '@reduxjs/toolkit';

export const TASK_TYPES = {
  current: 'CURRENT',
  upcoming: 'UPCOMING',
  past: 'PAST',
}

const initialState = {
  taskCategory: null,
};
export const participantTaskSlice = createSlice({
  name: 'participantTask',
  initialState,
  reducers: {
    setParticipantTaskCategory: (state, action) => {
      state.taskCategory = action.payload
    },
  },
});

export const { setParticipantTaskCategory } = participantTaskSlice.actions;
export default participantTaskSlice.reducer;
