import React from 'react';
import Button from 'ihp-components/components/v2/Button';
import {
  VerifyEmailWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
} from './styles';
import { ReactComponent as Completed } from 'images/shared/done-checkmark.svg';
import { useAuth0 } from '@auth0/auth0-react';

function EmailVerified() {
  document.title = 'Single Ventricle SOURCE Email Verified';

  const { logout } = useAuth0();

  const onContinue = () => {
    logout({ localOnly: true });
    window.location.href = '/auth0-callback';
  };

  return (
    <VerifyEmailWrapper>
      <Container>
        <Row>
          <Completed width={200} height={198} />
        </Row>
        <Row>
          <PageTitle varient='h2'>Welcome!</PageTitle>
        </Row>
        <Row>
          <HelperText varient='pl3'>
            Thank you for verifying your email.
          </HelperText>
        </Row>
        <Row>
          <Button onClick={onContinue} fullWidth>
            Continue
          </Button>
        </Row>
      </Container>
    </VerifyEmailWrapper>
  );
}

export default EmailVerified;
