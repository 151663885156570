import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import DoneIcon from '@mui/icons-material/Done';
import Button from 'ihp-components/components/v2/Button';
import { FTDDRLogo } from '../../Onboarding/components/FTDDRLogo';
import { Input } from 'ihp-components/components/v2/Input';

import { ButtonText } from './styles';
import getStyles, {
  ModalContainer,
  SectionContainer,
  DateOfDeathModalContent,
  DateOfDeathModalFooter,
} from './components';
import { Title, Text } from '../../Onboarding/components/Text';
import { useResearchEligibilityRoleCareGiver } from 'pages/Onboarding/ResearchEligibilityRoleCareGiver/useResearchEligibilityRoleCareGiver';
import { FormCheckbox } from 'ihp-components/components/v2/Input/Checkbox';
import { useDeceasedStatus } from './useDeceasedStatus';
import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
} from 'constants/global';
import { useIsValidDate } from 'hooks/useIsValidDate';

const DateOfDeathCondolencesModal = ({ onCancel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getStyles(theme);

  const [provideInfo, setProvideInfo] = useState(null);
  const [shareAutopsy, setShareAutopsy] = useState(false);
  const [dateOfDeath, setDateOfDeath] = useState(null);
  const [dateError, setDateError] = useState(false);
  const {
    updatePersonAttribute,
    updatePersonStudyArmRecord,
    createPersonStudyArmAttributeRecord,
    isLoading,
  } = useDeceasedStatus();
  const { isValidDate } = useIsValidDate();
  const user = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);

  const { activeAccountProfile } = user;
  const researchPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;
  const researchDeceasedStudyArmStatus =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_DECEASED
    ];
  const subjectPrimaryPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;
  const dateOfBirthDate =
    activeAccountProfile?.attributes?.profile?.date_of_birth;

  const handleUpdateStatus = async () => {
    const createPersonStudyArmAttributeResponse =
      await createPersonStudyArmAttributeRecord(
        researchPersonStudyArmId,
        dateOfDeath
      );

    const updatePersonAttributesResponse = await updatePersonAttribute(
      subjectPrimaryPersonId,
      shareAutopsy,
      provideInfo
    );

    if (
      !updatePersonAttributesResponse?.error &&
      !createPersonStudyArmAttributeResponse?.error
    ) {
      await updatePersonStudyArmRecord(
        accountPersonStudyArmId,
        researchDeceasedStudyArmStatus?.id
      );

      const activeAccountProfilePayload = {
        ...user,
        activeAccountProfile: {
          ...user.activeAccountProfile,
          attributes: {
            ...user.activeAccountProfile.attributes,
            profile: {
              ...user.activeAccountProfile.attributes.profile,
              study_arm_status_id: researchDeceasedStudyArmStatus?.id,
              study_arm_status_title: researchDeceasedStudyArmStatus?.title,
            },
          },
        },
      };

      await dispatch(setValues(activeAccountProfilePayload));
      navigate('/home');
    }
  };

  const handleOnCancel = () => {
    onCancel();
  };

  const handleNotNow = () => {
    setProvideInfo(false);
    setDateOfDeath(null);
    setShareAutopsy(false);
  };

  const handleOnChange = (e) => {
    const selectedDate = e;
    const currentDate = new Date();
    const inputDate = new Date(selectedDate);
    const isFutureDate = inputDate >= currentDate;
    const isPastFromDateOfBirthDate = inputDate < new Date(dateOfBirthDate);
    setDateError(
      !isValidDate(selectedDate) ||
        isFutureDate ||
        isPastFromDateOfBirthDate ||
        String(inputDate) === 'Invalid Date'
        ? true
        : false
    );
    setDateOfDeath(e);
  };

  const handleOnChangeAutopsy = (e) => {
    setShareAutopsy((prevdata) => !prevdata);
  };

  return (
    <ModalContainer
      sx={{ backgroundColor: (theme) => theme.palette.primary.white }}
      modal={'dateOfDeath'}
    >
      <DateOfDeathModalContent>
        <Box display='flex' justifyContent='center' position='relative'>
          <FTDDRLogo />
        </Box>
        <Title marginTop='20px'>Our condolences</Title>

        <SectionContainer marginTop='5px'>
          <Text lineHeight='140%'>
            On behalf of the Registry staff, please accept our deepest
            sympathies. We understand this is a difficult time. Thank you for
            providing a status update.
          </Text>
        </SectionContainer>
        <SectionContainer marginTop='30px'>
          <Text lineHeight='140%' textAlign='left'>
            Are you willing to provide additional information?
          </Text>
        </SectionContainer>
        <SectionContainer>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                fullWidth
                sx={{
                  height: '50px',
                  borderRadius: '8px',
                  padding: '10px',
                  borderColor: provideInfo
                    ? theme.palette.primary.helpBorderBlue
                    : theme.palette.primary.lightGray100,
                }}
                onClick={() => setProvideInfo(true)}
              >
                {provideInfo && <DoneIcon style={styles.saveIcon} />}
                <ButtonText isActive={provideInfo}>Yes</ButtonText>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='outlined'
                fullWidth
                sx={{
                  height: '50px',
                  borderRadius: '8px',
                  padding: '10px',
                  borderColor:
                    provideInfo === false
                      ? theme.palette.primary.helpBorderBlue
                      : theme.palette.primary.lightGray100,
                }}
                onClick={() => handleNotNow()}
              >
                {provideInfo === false && <DoneIcon style={styles.saveIcon} />}
                <ButtonText
                  isActive={provideInfo === false}
                  sx={{ paddingLeft: '15px' }}
                >
                  No, not at this time
                </ButtonText>
              </Button>
            </Grid>
          </Grid>
        </SectionContainer>
        {provideInfo && (
          <SectionContainer>
            <SectionContainer marginTop='30px'>
              <Input
                type='datePicker'
                label='Date of death (Optional)'
                placeholder='Pick a date'
                fullWidth
                disableFuture
                error={dateError}
                errorLabel={dateError && 'Please enter a valid date of death'}
                value={dateOfDeath}
                onChange={(e) => handleOnChange(e)}
              />
            </SectionContainer>
            <SectionContainer>
              <FormCheckbox
                containerStyle={styles.checkboxContainer}
                value='share_autopsy'
                label='I am willing to complete an autopsy survey.'
                checked={shareAutopsy}
                onChange={handleOnChangeAutopsy}
              />
            </SectionContainer>
          </SectionContainer>
        )}
      </DateOfDeathModalContent>
      <DateOfDeathModalFooter>
        <Button
          onClick={handleUpdateStatus}
          width='100%'
          disabled={provideInfo === null || isLoading || dateError}
          loading={isLoading}
        >
          Update Status
        </Button>
        <Button
          variant='link'
          sx={styles.cancelBtn}
          onClick={handleOnCancel}
          width='100%'
        >
          Cancel
        </Button>
      </DateOfDeathModalFooter>
    </ModalContainer>
  );
};

export default DateOfDeathCondolencesModal;
