import { styled } from '@mui/material/styles';

export const VideoPlayerContainer = styled("div")(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  position: 'relative',
}));

export const VideoPlayerPlayButton = styled("div")(({ theme }) => ({
  width: '70px',
  height: '70px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.primary.white,
  textShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  boxSizing: 'border-box',
  borderRadius: '50%',
  "svg": {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}));

