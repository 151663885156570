import { Box, styled } from '@mui/system';

export const ProviderCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  padding: '15px',
  borderTop: `1px solid ${theme.palette.primary.lightGray100}`,
}));

export const ProviderName = styled('div')(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: 14,
  fontWeight: 600,
  marginTop: '10px',
}));

export const ProviderAddress = styled('div')(({ theme }) => ({
  color: theme.palette.primary.gray50,
  fontSize: 12,
  fontWeight: 400,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  marginTop: '5px',
}));

export const TitleContainer = styled(Box)(() => ({
  marginLeft: '20px',
}));

export const IconWrapper = styled(Box)(() => ({
  alignSelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const IconContainer = styled(Box)(() => ({
  padding: '3px',
}));

export const CircleWrapper = styled(Box)(() => ({
  marginTop: '2px',
  marginLeft: '12px',
  marginRight: '8px',
}));

export const NameAddressContainer = styled(Box)(() => ({
  display: 'flex',
  marginLeft: '-5px',
  marginTop: '0px',
}));
