import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import StepCounter from 'components/Activities/StepCounter';

export const ActivityHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '25px',

  backgroundColor: theme.palette.primary.white,
  img: {
    marginRight: '17px',
  },
  '> div': {
    width: '90%',
  },
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.lightGray5,
    flexDirection: 'row',
    alignItems: 'center',
    '> div': {
      width: 'auto',
    },
  },

  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.lightGray5,
    flexDirection: 'column',
    alignItems: 'flex-start',
    '> div': {
      width: 'auto',
    },
    paddingBottom: '0px',
  },
}));

export const ActivityDetails = styled((props) => (
  <Typography variant='pl4' color='secondary.gray4' {...props} />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginTop: '12px',
  marginLeft: '35px',
  [theme.breakpoints.up('sm')]: {
    margin: 0,
  },
}));

export const Reward = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.orange70,
  fontWeight: '600',
  fontSize: '13px',
  svg: {
    marginRight: '8px',
  },
}));

export const AllowedTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.gray4,
  fontWeight: '400',
  fontSize: '13px',
  svg: {
    marginRight: '8px',
  },
}));

export const RequiredTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.gray4,
  fontWeight: '400',
  fontSize: '13px',
  svg: {
    marginRight: '8px',
  },
}));

export const TaskType = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.secondary.gray4,
  fontWeight: '400',
  fontSize: '13px',
  svg: {
    marginRight: '8px',
    fill: theme.palette.primary.gray40,
  },
}));

export const StyledCircleIcon = styled(CircleIcon)(({ theme }) => ({
  color: theme.palette.primary.darkBlue50,
  fontSize: '4px',
}));

export const StyledStepCounter = styled(({ show, ...rest }) => (
  <StepCounter {...rest} />
))(({ theme, show }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  ...(show || { display: 'none' }),
}));

export const SubtitleMobile = styled('span')(({ theme }) => ({
  fontWeight: '400',
  fontSize: '14px',
}));
