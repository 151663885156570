import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const studyArmConfigApiSlice = createApi({
  reducerPath: 'studyArmConfigApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['StudyArmConfig'],
  endpoints: (build) => ({
    getStudyArmConfig: build.query({
      query: () => ({
        url: '/app/conf/study-arm',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ', // this api is public so no token is needed
        },
      }),
      providesTags: ['StudyArmConfig'],
    }),
    getStudyArmRolePermissions: build.query({
      query: ({ params }) => ({
        url: '/study-arm-role-permissions',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetStudyArmConfigQuery, useGetStudyArmRolePermissionsQuery } =
  studyArmConfigApiSlice;
