import { Box, RadioGroup, Stack, Typography } from '@mui/material';
import { StyledRadio } from './styles';
import { StyledFormControl, StyledFormLabel } from '../TextField.styles';

import { ReactComponent as Check } from '../../assets/svg/check.svg';
import { ReactComponent as CircleCheck } from '../../assets/svg/circle-check.svg';
import { ErrorWrapper } from '../ErrorWrapper';

export const RadioInput = (props) => {
  const { label, hideRadioIcon = true, disabled, ...rest } = props;
  return (
    <StyledRadio
      disableRipple
      color='default'
      checkedIcon={
        <Box className='StyledLabel StyledLabel-checked'>
          <Stack alignItems={'center'} display={'flex'}>
            <Check color={!disabled && '#1A6693'} />
          </Stack>
          <Typography variant='pn3'>{label}</Typography>
        </Box>
      }
      icon={
        <Box className='StyledLabel'>
          {!hideRadioIcon && (
            <Stack alignItems={'center'} display={'flex'}>
              <CircleCheck />
            </Stack>
          )}
          <Typography variant='pn3'>{label}</Typography>
        </Box>
      }
      disabled={disabled}
      {...rest}
    />
  );
};

export const RadioInputGroup = ({
  children,
  direction = 'column',
  optionsStyles,
  fullWidth,
  error,
  id,
  errorLabel,
  size,
  label,
  ...rest
}) => (
  <StyledFormControl fullWidth={fullWidth} size={size}>
    {label && <StyledFormLabel>{label}</StyledFormLabel>}
    <ErrorWrapper error={error} errorLabel={errorLabel}>
      <RadioGroup {...rest} ref={rest.inputRef}>
        <Stack spacing='15px' direction={direction} sx={optionsStyles}>
          {children}
        </Stack>
      </RadioGroup>
    </ErrorWrapper>
  </StyledFormControl>
);
