import { styled } from '@mui/material';

export const getStyles = (theme) => {
  return {
    formStyle: {
      backgroundColor: theme.palette.primary.white,
      padding: '5px',
      marginTop: '10px',
      borderColor: theme.palette.primary.lightGray100,
    },
    linkButton: {
      padding: '0px',
      marginTop: '10px',
    },
    outlineButton: {
      borderWidth: '2px',
      marginTop: '15px',
    },
  };
};

export const ButtonText = styled('span')(({ theme, isActive }) => ({
  fontSize: '14px',
  color: isActive
    ? theme.palette.primary.lightShadegreen
    : theme.palette.primary.darkGray100,
}));
