import { useNavigate } from 'react-router-dom';
import {
  PageWrapper,
  AcmeLogo,
  Container,
  Row,
  TitleContainer,
  PageTitle,
  Text,
  ButtonContainer,
} from '../WelcomeToStudy/styles';
import Button from 'ihp-components/components/v2/Button';

function WelcomeOnboard() {
  const navigate = useNavigate();
  const navigateUser = () => navigate('/my-activities');

  return (
    <PageWrapper>
      <Container>
        <Row>
          <AcmeLogo />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>Welcome to SV Source Study!</PageTitle>
        </TitleContainer>
        <div>
          <Text varient='pl3' align='center'>
            You have successfully created your account
          </Text>
        </div>

        <ButtonContainer>
          <Button fullWidth onClick={navigateUser}>
            Continue to participate
          </Button>
        </ButtonContainer>
      </Container>
    </PageWrapper>
  );
}

export default WelcomeOnboard;
