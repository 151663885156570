const getStyles = (theme) => {
  return {
    formControlLabel: {
      marginLeft: 0,
      '& .MuiCheckbox-root:hover': {
        background: 'transparent',
      },
    },
    errResponseText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.primary.red75,
      marginTop: 0,
      marginLeft: '10px',

      '& svg': {
        marginRight: '5px',
      },
    },
  };
};

export default getStyles;
