import { Box, Stack } from '@mui/material';
import { styled } from '@mui/system';

export const SpinnerContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '65vh',
});

export const Container = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  boxShadow: '0px 4px 9px 0px #0000000A',
  borderRadius: '8px',
}));

export const EmptyProvidersWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: '50px',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    marginTop: '15px',
    background: 'transparent',
  },
}));

export const NoProvidersSubtitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.gray50,
  fontWeight: 400,
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  paddingTop: '5px',
  alignContent: 'center',
  justifyContent: 'center',
}));

export const SearchResultContainer = styled(Box)(({ theme }) => ({
  background: 'white',
  color: theme.palette.primary.darkGray50,
  fontWeight: 500,
  fontSize: '12px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  padding: '20px 10px 10px 15px',
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  background: 'white',
  height: '300px',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '10px',
}));

export const StackContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  background: 'white',
  borderRadius: '0px 8px 8px 0px',
  [theme.breakpoints.down('sm')]: {
    background: 'transparent',
  },
}));

export const NoProviderFoundText = styled(Box)(({ theme }) => ({
  color: '#2B3A41',
  fontWeight: 600,
  fontSize: '18px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  lineHeight: '140%',
  marginBottom: '20px',
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '400px',
}));

export const AddProviderLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: 'white',
  borderTop: '1px solid #D9D9D9',
  textAlign: 'center',
  height: '60px',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0px 0px 8px 8px',
}));
