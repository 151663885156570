import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cohorts: [
    {
      id: '001',
      name: 'Dui suspendisse at sit tellus odio nunc nunc',
      people: 15,
      description: 'Sagittis dictum convallis tortor convallis.',
      studyArm: 'Mi at odio et aliquet dignissim dictum eu, in',
    },
    {
      id: '002',
      name: 'Sagittis dictum convallis tortor convallis.',
      people: 29,
      description: 'Dui suspendisse at sit tellus odio nunc nunc',
      studyArm: 'Name 1',
    },
    {
      id: '003',
      name: 'Cras nulla urna pellentesque sagittis ac nisl ',
      people: 242,
      description: 'Pulvinar amet justo, cursus massa platea ',
      studyArm: '',
    },
    {
      id: '004',
      name: 'Pulvinar amet justo, cursus massa platea ',
      people: 1894,
      description: 'Cras nulla urna pellentesque sagittis ac nisl ',
      studyArm: 'Mi at odio et aliquet dignissim dictum eu, in',
    },
    {
      id: '005',
      name: 'Lectus sollicitudin sagittis laoreet ',
      people: 56,
      description: 'Dui suspendisse at sit tellus odio nunc nunc',
      studyArm: '',
    },
  ],
};

const updateCohortAction = (cohortState, action) => {
  cohortState.cohorts = cohortState.cohorts.map((cohort) =>
    cohort.id === action.payload.id ? { ...cohort, ...action.payload } : cohort
  );
};

const deleteCohortAction = (cohortState, action) => {
  const cohortIndex = cohortState.cohorts.findIndex(
    (cohort) => cohort.id === action.payload.id
  );

  cohortState.cohorts.splice(cohortIndex, 1);
};

const addCohortAction = (cohortState, action) => {
  cohortState.cohorts.push(action.payload);
};

export const cohortSlice = createSlice({
  name: 'cohort',
  initialState,
  reducers: {
    addCohort: addCohortAction,
    updateCohort: updateCohortAction,
    deleteCohort: deleteCohortAction,
  },
});

export const { addCohort, updateCohort, deleteCohort } = cohortSlice.actions;

export default cohortSlice.reducer;
