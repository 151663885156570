import { useOutlet } from 'react-router-dom';
import { Modal, useMediaQuery, useTheme } from '@mui/material';

import AllMedicationsDesktop from './index.desktop';
import AllMedicationsMobile from './index.mobile';

const AllMedications = () => {
  const outlet = useOutlet();
  if (useMediaQuery(useTheme().breakpoints.up('sm')))
    return (
      <>
        <AllMedicationsDesktop />
        <Modal open={!!outlet.props.children}>{outlet}</Modal>
      </>
    );
  if (!!outlet.props.children) return outlet;
  return <AllMedicationsMobile />;
};

export default AllMedications;
