import { getBaseURL } from 'config/API';

export const getPointsInfo = async (participantId, token) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${getBaseURL()}/v1/persons/${participantId}/participant-goal-stats?include=goal`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const getThresholdInfo = async ({ studyId, token }) => {
  const study_id = studyId ?? 1;
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${getBaseURL()}/v2/study/${study_id}/attributes`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};

export const getPayments = async ({ participantId, token }) => {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', 'Bearer ' + token);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${getBaseURL()}/v1/persons/${participantId}/participant-rewards`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log('error', error));

  return JSON.parse(response);
};
