import { styled } from '@mui/system';

import { BaseLayout } from 'components/BaseLayout';
import { FormCardList } from 'components/FormCard';
import StepCounter from 'components/Activities/StepCounter';

export const StyledStepCounter = styled(StepCounter)(({ theme }) => ({
  marginTop: '35px',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const StyledFormCardList = styled(FormCardList)({
  margin: '0 15px 0 15px',
});

export const StyledBaseLayout = styled(({ lastStep = false, ...rest }) => (
  <BaseLayout {...rest} />
))(({ theme, lastStep }) => ({
  height: '100vh',
  '& > .BaseLayout-scrollable': {
    maxWidth: '720px',
    margin: 'auto',
    backgroundColor: 'transparent',
    ...(lastStep && { justifyContent: 'center' }),
    '& > .BaseLayout-content': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        flex: 'none',
      },
    },
  },
}));

export const TimeLimitPopup = styled('div')(({ theme }) => ({
  width: '600px',
  height: '80px',
  padding: '20px',
  position: 'fixed',
  top: '20px',
  left: '50%',
  transform: 'translate(-50%)',
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
}));
