import { styled, Typography } from '@mui/material';


export const getStyles = () => {
    return {
        button: {
            padding: '17px 30px'
        }
    };
};

export const SelectProfileWrapper = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '72px 0px',
}));

export const Container = styled('div')(({ theme }) => ({
    maxWidth: '420px',
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    padding: '40px',
    boxSizing: 'border-box',
}));

export const Row = styled('div')(({ marginBottom }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: marginBottom || '20px',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray100,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '140%',
    textAlign: 'center'
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray70,
    fontSize: '14px',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%'
}));