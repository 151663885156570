import { Link } from 'react-router-dom';
import { styled, Box, Typography } from '@mui/material';
import MuiPhoneIcon from '@mui/icons-material/Phone';
import MuiEmailIcon from '@mui/icons-material/Email';
import { WelcomePageWrapper } from 'pages/Welcome/styles';

export const Container = styled(WelcomePageWrapper)({
  gap: '20px',
});

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '36px',
  fontWeight: 900,
  color: theme.palette.primary.navy,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  padding: 0,
  fontWeight: 800,
  color: theme.palette.primary.navy,
}));

export const TextIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  padding: 0,
  fontWeight: 600,
  color: theme.palette.primary.navy,
}));

export const ContactBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '30px',
  gap: '30px',
  borderRadius: '10px',
  border: '1px solid',
  borderColor: theme.palette.primary.gray15,
}));

export const PhoneIcon = styled(MuiPhoneIcon)(({ theme }) => ({
  width: '48px',
  height: '48px',
  color: theme.palette.primary.green,
}));

export const EmailIcon = styled(MuiEmailIcon)(({ theme }) => ({
  width: '48px',
  height: '48px',
  color: theme.palette.primary.green,
}));

export const DownloadLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.green,
  textDecoration: 'underline',
  fontWeight: 800,
}));
