const getStyles = (theme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 80px 50px 50px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  title: {
    color: '#2B3A41',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34.8px',
  },
  showHelp: {
    marginTop: '5px',
  },
  providersContainer: {
    marginTop: '15px',
    width: '100%',
  },
  emptyProviders: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '70vh',
  },
});

export default getStyles;
