import { Box, styled } from '@mui/system';

export const TitleContainer = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
}));

export const OverviewSubtitleContainer = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  marginTop: '15px',
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  padding: '20px',
  bgcolor: theme.palette.primary.gray100,
}));

export const TimelineTitleContainer = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  color: theme.palette.secondary.gray4,
  fontWeight: 600,
  fontSize: '16px',
}));

export const OverviewListItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const LeftContainer = styled(Box)(() => ({
  display: 'flex',
}));

export const RightContainer = styled(Box)(() => ({
  display: 'flex',
  marginRight: '20px',
  marginTop: '18px',
}));

export const IconWrapper = styled(Box)(({ height = '40px' }) => ({
  width: '40px',
  height: height,
  paddingBottom: '25px',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.gray100,
  fontSize: 13,
  fontWeight: 400,
  marginTop: '18px',
  marginLeft: '25px',
}));

export const PointsPerWeek = styled('div')(({ theme }) => ({
  color: theme.palette.primary.gray75,
  fontSize: 13,
  fontWeight: 400,
}));

export const TimelineTitle = styled('div')(({ theme }) => ({
  color: theme.palette.primary.gray50,
  fontSize: 18,
  fontWeight: 600,
  backgroundColor: theme.palette.primary.gray20,
  padding: '46px 0px 0px 45px',
}));
