import { styled } from '@mui/system';

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    top: 0,
    zIndex: '100',
    width: '100%',
    maxWidth: '820px',
    paddingTop: '40px',
    backgroundColor: theme.palette.primary.gray20,
  },
}));
