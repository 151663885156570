import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { StepperContainer } from 'components/StepperContainer';
import StepCounter from 'components/Activities/StepCounter';
import ActivityHeader from 'components/Activities/Header';
import { ProgressButtons } from 'components/Buttons';

export const StyledStepCounter = styled(StepCounter)(({ theme }) => ({
  marginTop: '35px',
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    position: 'absolute',
    top: '0px',
    left: '50%,',
    zIndex: '10',
  },
}));

export const StyledStepperContainer = styled(StepperContainer)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray20,
  '& > .BaseLayout-main': {
    height: 'auto',
  },
  '& > .BaseLayout-header': {
    backgroundColor: theme.palette.primary.gray20,
    zIndex: '10',
    borderBottom: '0px',
  },
  '& > .BaseLayout-scrollable': {
    width: 325,
    [theme.breakpoints.up('sm')]: {
      width: 700,
      backgroundColor: theme.palette.primary.gray20,
      padding: '60px 50px 40px',
      flex: '0 0 auto !important',
      margin: '0 auto 36px',
      borderRadius: '8px',
    },
  },
  '& * > .ProgressButtons-root': {
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row-reverse !important',
      justifyContent: 'flex-start',
      margin: '20px 0px 10px',
    },
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.gray2,
  },
}));
export const DetailsWrapper = styled(Box)({
  width: '100%',
  textAlign: 'center',
  h2: {
    marginBottom: '10px',
  },
});

export const DetailsHeaderSection = styled('div')({
  maxWidth: '400px',
  margin: 'auto',
});

export const CallDetailsBox = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: theme.palette.primary.gray100,
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  margin: '30px auto 0px',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
  },
}));

export const CallDay = styled(Box)({
  paddingTop: '25px',
  paddingBottom: '14px',
  textTransform: 'capitalize',
});

export const CallTime = styled(Box)(({ theme }) => ({
  paddingTop: '25px',
  paddingBottom: '25px',
  borderTop: `1px solid ${theme.palette.primary.gray10}`,
}));

export const CallDate = styled(Box)({
  paddingBottom: '25px',
});

export const CallManipulationActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',

  button: {
    margin: 'auto',
  },

  '& > div': {
    height: 'auto',
    boxSizing: 'border-box',
    flex: 1,
    padding: '8px 0px',
    backgroundColor: theme.palette.primary.white,
    [theme.breakpoints.down('sm')]: {
      border: '0px',
      backgroundColor: 'transparent',
    },
    '&:first-of-type': {
      borderBottomLeftRadius: '8px',
      borderTop: `1px solid ${theme.palette.primary.gray10}`,
      borderRight: `1px solid ${theme.palette.primary.gray10}`,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingLeft: '16px',
      },
    },
    '&:last-of-type': {
      borderBottomRightRadius: '8px',
      borderTop: `1px solid ${theme.palette.primary.gray10}`,
      borderLeft: `1px solid ${theme.palette.primary.gray10}`,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '16px',
      },
    },
  },
}));

export const StyledIframe = styled('iframe')(({ theme }) => ({
  width: '100vw',
  height: 'calc(100vh - 200px)',
  boxSizing: 'border-box',
  [theme.breakpoints.up('sm')]: {
    width: '325px',
  },
}));

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
}));

export const StyledProgressButtons = styled(
  ({ isLastStep = false, ...rest }) => <ProgressButtons {...rest} />
)(({ isLastStep, theme }) => ({
  width: '100%',
  margin: '25px 0px',
  alignItems: 'center',
  justifyContent: `center ${isLastStep ? '!important' : ''}`,

  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    padding: '0 25px',
  },
}));

export const StepperWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '700px',
  padding: '0 25px',
  margin: 'auto',
  '& .MuiPickerStaticWrapper-root': {
    boxShadow: '0px 0px 30px rgb(0 84 135 / 5%)',
    borderRadius: '13px',
    padding: '20px 30px 30px',
    '& > div > div': {
      width: '100% !important',
    },
    '& .MuiCalendarPicker-root': {
      width: '100% !important',
      '& > div:first-child': {
        paddingLeft: '16px',
        paddingRight: '4px',
      },
    },
    '& .MuiCalendarPicker-viewTransitionContainer': {
      '& > div > div': {
        justifyContent: 'space-between !important',
      },
      '& div[role=row]': {
        margin: '13px 0',
        justifyContent: 'space-between !important',
      },
    },
    '& .PrivatePickersSlideTransition-root': {},
  },
}));

export const MakeCallPageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

export const StyledActivityHeader = styled(ActivityHeader)(({ theme }) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.lightGray5,
  '& > :first-child': {
    position: 'absolute',
    left: '25px',
    top: '25px',
  },
  '& > :last-child': {
    position: 'absolute',
    right: '25px',
    top: '25px',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.lightGray5,
    '& > :first-child': {
      position: 'static',
    },
    '& > :last-child': {
      position: 'static',
    },
  },
}));

export const celebrationScreenContainerStyle = {
  margin: 'auto',
};
