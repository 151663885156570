export const statusoptions = [
  { 
    value: 1, 
    label: 'Active' 
  },
  {
    value: 2,
    label: 'Deceased',
  },
  {
    value: 3,
    label: 'Withdrawn',
  },
];
