import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { BaseHeader } from 'components/BaseLayout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'utils/isMobile';
import { CardList } from '../styles';
import { Line } from '../Payment/styles';
import { SetupAccount, SetupTransfer, VerifyAccount } from './Card/cards';

const HyperwalletMain = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();

  if (!isMobile()) {
    return <></>;
  }

  return (
    <Box>
      <BaseHeader to='/profile'>Rewards settings</BaseHeader>
      <Line />
      <Box
        sx={{
          backgroundColor: theme.palette.primary.gray2,
          height: '80vh',
          paddingTop: '25px',
        }}
      >
        <CardList>
          <SetupAccount
            onClick={() => navigate('/profile/payment/hyperwallet/account')}
          />
          <SetupTransfer
            onClick={() => navigate('/profile/payment/hyperwallet/transfer')}
          />
          <VerifyAccount
            onClick={() => navigate('/profile/payment/hyperwallet/verify')}
          />
        </CardList>
      </Box>
    </Box>
  );
};

export default HyperwalletMain;
