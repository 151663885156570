import { Box, styled } from '@mui/material';

import { FilledButton } from 'components/Buttons';
import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '50px',
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '60px 40px 40px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Badge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#7C7C7C',
  backgroundColor: theme.palette.primary.gray70,
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '63px',
  width: '100%',
  fontSize: '18px',
  fontWeight: '700',
  fontFamily: 'Inter',
  lineHeight: '20px',
}));

export const SignIn = styled(FilledButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.deepBlue,
  '&:hover': {
    backgroundColor: theme.palette.primary.deepBlue,
  },
}));
