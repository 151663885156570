import { Link } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material';
import Background from 'images/background.svg';
import Mobilebackground from 'images/background-mobile.svg';

export const VerifyEmailWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    background: `url(${Mobilebackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '375px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  padding: '60px 40px 20px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export const Row = styled('div')(({ marginBottom, marginTop }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '10px',
  marginTop: marginTop,
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '15px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.secondary.gray4,
}));

export const FormContainer = styled('div')({
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
});

export const BackLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.fuchsia75,
  textDecoration: 'none',
}));

export const ContactBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.gray75,
  backgroundColor: theme.palette.primary.gray10,
  border: `1px solid ${theme.palette.primary.gray10}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  height: '63px',
  width: '100%',
  fontSize: '16px',
  fontWeight: '500',
}));

export const ContactNumber = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '20px',
  color: theme.palette.primary.gray75,
}));

export const styles = {
  helperText: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Inter',
    color: '#606B71',
    lineHeight: '19px',
  },
};
