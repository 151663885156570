import React from 'react';
import { GoBack, LayoutContainer, LayoutContent } from './styles';

const OnboardingLayout = (props) => {
  const { children, maxWidth, backHandler } = props;

  return (
    <LayoutContainer>
      <LayoutContent maxWidth={maxWidth}>
        {backHandler && <GoBack onClick={backHandler} />}
        {children}
      </LayoutContent>
    </LayoutContainer>
  );
};

export default OnboardingLayout;
