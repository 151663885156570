import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTitle = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '145%',
  color: theme.palette.secondary.gray4,
  cursor: 'pointer',
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.primary.gray50,
  cursor: 'pointer',
}));

export const StyledProfileCard = styled(Box)(({ theme, active }) => ({
  width: '100%',
  minWidth: '300px',
  gap: '15px',
  boxSizing: 'border-box',
  padding: '15px',
  backgroundColor: active ? theme.palette.primary.white : 'transparent',
  borderRadius: '5px',
  boxShadow: active ? '0px 0px 9px rgba(0, 0, 0, 0.04)' : 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '5px',
  },
}));

export const StyledIcon = styled(Box)(({ theme, active }) => ({
  flex: '0 0 50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: active
    ? theme.palette.primary.magenta70
    : theme.palette.primary.gray50,
  color: theme.palette.primary.white,
  borderRadius: '25px',
}));

export const StyledContent = styled((props) => (
  <Stack {...props} spacing='2px' />
))({ flex: '1 1 auto' });

export const StyledAction = styled(Box)(({ theme }) => ({
  flex: '0 0 40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    height: '15px',
    color: theme.palette.primary.gray25,
  },
}));
