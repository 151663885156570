export const getDiagnosisTypes = () => [
  {
    label: 'Hypoplastic Left Heart Syndrome (HLHS)',
    value: 'diagnosis_hlhs',
    checked: false,
  },
  {
    label: 'Hypoplastic Right Heart (sometimes called HRHS)',
    value: 'diagnosis_hrhs',
    checked: false,
  },
  {
    label: 'Tricuspid Atresia',
    value: 'diagnosis_ta',
    checked: false,
  },
  {
    label: 'Double Inlet Left Ventricle (DILV)',
    value: 'diagnosis_dilv',
    checked: false,
  },
  {
    label: 'Double Outlet Right Ventricle (DORV) - some types',
    value: 'diagnosis_dorv',
    checked: false,
  },
  {
    label: 'Ebstein’s Anomaly',
    value: 'diagnosis_ea',
    checked: false,
  },
  {
    label: 'Single Left Ventricle',
    value: 'diagnosis_slv',
    checked: false,
  },
  {
    label: 'Pulmonary Atresia with Intact Ventricular Septum (PA-IVS)',
    value: 'diagnosis_paivs',
    checked: false,
  },
  {
    label: 'Unbalanced Atrioventricular (AV) Canal',
    value: 'diagnosis_av',
    checked: false,
  },
  {
    label:
      'Other complex heart defects leading to a Fontan and/or Glenn operation and a single functioning pumping chamber',
    value: 'diagnosis_other',
    checked: false,
  },
];
