import { Box, styled } from '@mui/material';

export const WelcomePageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 100px',
  maxWidth: '1440px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
}));
