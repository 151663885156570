import { MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import {
  PageWrapper,
  AcmeLogo,
  Container,
  Row,
  TitleContainer,
  PageTitle,
  Text,
  ButtonContainer,
  StyledLink,
} from './styles';
import { findStudyAttribute } from 'utils/study';
import { getAuth0LoginPageConfig } from 'utils/auth0';
import { Input } from 'ihp-components/components/v2/Input';

function LanguageOnboard() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const { loginWithRedirect } = useAuth0();

  const languages =
    findStudyAttribute(studyConfiguration || [], 'languages') || [];

  const onChangeLanguage = (value) => {
    dispatch(setValues({ ...user, language: value }));
  };
  const openSignupPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig({ context: 'signup' }));
  };

  return (
    <PageWrapper>
      <Container>
        <Row>
          <AcmeLogo />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>Language</PageTitle>
        </TitleContainer>
        <div>
          <Text varient='pl3' align='center'>
            Let us know your preferred language
          </Text>
        </div>
        <Input
          type='select'
          label='Select language'
          placeholder='Select language'
          fullWidth
          required
          value={user.language}
          onChange={(e) => onChangeLanguage(e.target.value)}
        >
          {languages?.map((language) => (
            <MenuItem key={language.language} value={language.language}>
              {language.display_name}
            </MenuItem>
          ))}
        </Input>

        <ButtonContainer>
          <Button
            fullWidth
            onClick={openSignupPage}
            disabled={user.language === ''}
          >
            Continue
          </Button>
          <StyledLink to='/onboarding/welcome-to-study'>
            Back to home
          </StyledLink>
        </ButtonContainer>
      </Container>
    </PageWrapper>
  );
}

export default LanguageOnboard;
