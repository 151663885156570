import React from 'react';

import { OnboardingCompleteCard } from 'components/Profile/Achievements/OnboardingCompleteCard';
import { PointsEarnedCard } from 'components/Profile/Achievements/PointsEarnedCard';
import { SyncDataCard } from 'components/Profile/Achievements/SyncDataCard';
import { SurveyCompletedCard } from 'components/Profile/Achievements/SurveyCompletedCard';
import { ScheduleFollowUpCard } from 'components/Profile/Achievements/ScheduleFollowUpCard';
import { ArticleReadCard } from 'components/Profile/Achievements/ArticleReadCard';

import { AchievementsPageWrapper, PageTitle, AchievementsGrid } from './styles';

const Achievements = () => {
  return (
    <AchievementsPageWrapper>
      <PageTitle variant='h2'>My achievements</PageTitle>
      <AchievementsGrid>
        <OnboardingCompleteCard inProgress={false} isCompleted={false} />
        <PointsEarnedCard inProgress={true} isCompleted={false} />
        <SyncDataCard inProgress={true} isCompleted={true} />
        <SurveyCompletedCard inProgress={true} isCompleted={true} />
        <ScheduleFollowUpCard inProgress={true} isCompleted={true} />
        <ArticleReadCard inProgress={true} isCompleted={true} />
      </AchievementsGrid>
    </AchievementsPageWrapper>
  );
};

export default Achievements;
