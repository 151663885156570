import { styled } from '@mui/material/styles';
import { Box, Button, InputLabel } from '@mui/material';
import { FilledButton } from 'ihp-components/components/Buttons';
import { TextInput } from 'components/Input';
import CircularProgress from '@mui/material/CircularProgress';
import { LinkButton } from '../../components/Buttons';

export const getStyles = (theme) => {
  return {
    filterContainer: {
      p: '40px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      minWidth: '380px',
      gap: '25px',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    marginBottom30: {
      marginBottom: '30px',
    },
    footerStyle: {
      display: 'flex',
      gap: '15px',
      width: '100%',
      justifyContent: 'flex-end',
    },
    px0: {
      px: 0,
    },
  };
};
export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '145%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '145%',
  },
}));

export const Link = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'stretch',
  alignItems: 'center',
  color: theme.palette.primary.main,
  padding: '10px 16px',
  justifyContent: 'center',
  gap: '12px',
  cursor: 'pointer',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '140%',
  letterSpacing: '0.26px',
  textAlight: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const EmptyListWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '80vh',
  padding: '40px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.white,
}));

export const ViewAllButton = styled(FilledButton)(({ theme }) => ({
  display: 'none',
  padding: '15px 25px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '15px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  background: theme.palette.primary.main,
  textAlign: 'center',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '135%',
  letterSpacing: '0.3px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
  },
}));

export const LearnMoreButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.28px',
  fontSize: '14px',
  display: 'flex',
  padding: '13px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'end',
  gap: '12px',
  borderRadius: '8px',
  border: '1px solid ' + theme.palette.primary.lightGray100,
  background: theme.palette.primary.white,
  color: theme.palette.primary.darkBlue70,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const SearchWrapper = styled(Box)(({ theme }) => ({
  padding: '0',
  border: `1px solid ${theme.palette.primary.gray25}`,
  borderRadius: '8px',
}));

export const SearchInput = styled(TextInput)(({ theme }) => ({
  border: 'none',
  '&:hover': {
    border: 'none',
  },
}));

export const LoadMoreButton = styled(Button)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  fontStyle: 'Inter',
  textTransform: 'none',
}));

export const FilterButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '600',
  fontStyle: 'Inter',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const FilterMobileButton = styled(Button)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '820px',
  margin: '40px auto',
  backgroundColor: theme.palette.primary.gray20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: '24px',
  },
}));

export const MobileContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.primary.gray5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Loader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
export const ScrolledBox = styled(Box)({
  height: '200px',
  overflowY: 'scroll',
});

export const FilterContainer = styled(Box)({
  p: '40px !important',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  minWidth: '380px',
  gap: '25px',
});

export const ResearchCircularProgress = styled(CircularProgress)(
  ({ theme }) => ({
    marginTop: '20px',
  })
);

export const LnkButton = styled(LinkButton)(({ theme }) => ({
  color: theme.palette.primary.darkBlue70,
  height: '50px',
  minWidth: 'fit-content',
}));

export const fieldContainer = {
  paddingTop: '25px',
  width: '300px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
};

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '13px',
  marginBottom: '20px',
  color: theme.palette.primary.darkGray50,
  fontWeight: '600',
}));

export const checkboxContainer = {
  backgroundColor: 'transparent',
  border: '0px',
  '& .MuiFormControlLabel-root': {
    alignItems: 'start',
    '& .MuiCheckbox-root': {
      padding: '0px',
      '& span': {
        width: '20px',
        height: '20px',
      },
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
  },
};
