import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import VideoPlayer from 'components/VideoPlayer';
import ActivityInstructions from 'components/Activities/Instructions';
import { ProgressBar } from 'components/StepperContainer/progressBar';
import { CenteredFullPageWrapper } from 'components/CenteredFullPageWrapper';
import { Loader } from 'components/Loader';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { convertUTCStringToLocalDateObject, dateFormatter } from 'utils/date';
import { videoScreensMap } from './utils';
import { taskTypeLabelMapping } from '../utils';
import {
  VideoPageWrapper,
  StepperWrapper,
  StyledActivityHeader,
  StyledProgressButtons,
  VideoContainer,
  VideoDescription,
} from './styles';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import { ReactComponent as ReplayIcon } from 'images/player/replay.svg';
import { useGetProfileParticipantTask } from '../hooks';
import { useMediaQuery } from '@mui/material';

function Video() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  document.title = 'Single Ventricle SOURCE Video';
  const participantTaskId = parseInt(useParams().id);
  const navigate = useNavigate();
  const [benefitAttributes, setBenefitAttributes] = useState({});

  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const { refetch } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const participantTaskDetails = useGetProfileParticipantTask(
    participantTaskId,
    accountProfileId
  );

  const [updateParticipantTaskMutation, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();

  const handleFinish = async () => {
    const start_time = dateFormatter(
      convertUTCStringToLocalDateObject(participantTaskDetails?.startDate)
    );
    const end_time = dateFormatter(new Date());

    /* This payload will be sent to API */
    const requestBody = {
      participantTaskId: participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participantTaskId),
          attributes: {
            status: 'completed',
            start_time,
            end_time,
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody)
      .then((response) => {
        refetch();
        if (participantTaskDetails?.attributes?.celebrationScreen?.display) {
          setBenefitAttributes(response?.data?.data?.attributes);
          setCurrentStep(currentStep + 1); // Move to celebration screen
        } else {
          navigate('/my-activities');
        }
      })
      .catch(console.error);
  };

  const [currentStep, setCurrentStep] = useState(videoScreensMap.howTo);

  const hasHowTo = participantTaskDetails?.attributes?.howTo?.display;
  if (currentStep === 0 && !hasHowTo) {
    setCurrentStep(videoScreensMap.video);
  }

  const next = () => {
    if (currentStep === videoScreensMap.video) {
      handleFinish(); // submit task
    } else if (currentStep === videoScreensMap.celebration) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const back = () => {
    if (
      currentStep - 1 === videoScreensMap.howTo &&
      !participantTaskDetails?.attributes?.howTo?.display
    ) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  let estimatedTime = 'N/A';
  if (participantTaskDetails?.estimatedTime) {
    estimatedTime = `${participantTaskDetails.estimatedTime} min`;
  }
  let timeLimit = undefined;
  if (participantTaskDetails?.timeLimit) {
    timeLimit = `${participantTaskDetails?.timeLimit} min`;
  }
  const startButtonText =
    participantTaskDetails?.attributes?.meta?.button_name || 'Finish video';

  const stepsMap = {
    [videoScreensMap.howTo]: (
      <CenteredFullPageWrapper>
        <ActivityInstructions
          hideMetaData={isMobile}
          hideInstructionsTitle={!isMobile}
          title={participantTaskDetails?.title}
          type={taskTypeLabelMapping[participantTaskDetails?.type]}
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          points={participantTaskDetails?.point}
          description={participantTaskDetails?.attributes?.howTo?.instructions}
          startButtonText='Continue'
          onClickContinue={next}
          onCancel={() => navigate('/my-activities')}
        />
      </CenteredFullPageWrapper>
    ),
    [videoScreensMap.video]: (
      <VideoContainer>
        <VideoPlayer
          src={participantTaskDetails?.attributes?.video}
          controls={false}
          overridePlayIcon={ReplayIcon}
        />
        <VideoDescription>
          <div
            dangerouslySetInnerHTML={{
              __html: participantTaskDetails?.attributes?.content,
            }}
          />
        </VideoDescription>
      </VideoContainer>
    ),
    [videoScreensMap.celebration]: (
      <CelebrationScreen
        attributes={{
          points: participantTaskDetails?.point || 10,
          thankyouMsg:
            participantTaskDetails?.attributes?.celebrationScreen?.title,
          description:
            participantTaskDetails?.attributes?.celebrationScreen?.description,
          ...benefitAttributes,
        }}
        maxHeight={false}
        sx={{ margin: 'auto' }}
      />
    ),
  };

  const getButtonLabel = () => {
    if (currentStep === videoScreensMap.celebration) {
      return 'Done';
    }
    if (
      currentStep === videoScreensMap.howTo ||
      currentStep === videoScreensMap.video
    ) {
      return startButtonText;
    }
    return 'Continue';
  };

  const isCelebrationScreen = currentStep === videoScreensMap.celebration;
  const showProgressBar = false; // currentStep === videoScreensMap.video; // Hiding it for now
  const isCompleted = participantTaskDetails?.status === 'completed';

  if (isCompleted) {
    navigate('/my-activities');
  }

  if (participantTaskDetails?.loadingParticipantTask) {
    return <Loader />;
  }

  if (isUpdatingTask) {
    return <Loader />;
  }

  return (
    <VideoPageWrapper>
      {showProgressBar && (
        <ProgressBar currentStep={currentStep} stepsLength={2} />
      )}
      {!isCelebrationScreen && (
        <StyledActivityHeader
          title='My Activities'
          points={participantTaskDetails?.point}
          type={taskTypeLabelMapping[participantTaskDetails?.type]}
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          currentStep={currentStep}
          lastStep={2}
          showStepCounter={false}
        />
      )}
      <StepperWrapper>
        {stepsMap[currentStep]}
        {currentStep > 0 && (
          <StyledProgressButtons
            isLastStep={currentStep === videoScreensMap.celebration}
            buttonProps={{
              startIcon: <CheckIcon />,
              label: getButtonLabel(),
              onClick: next,
            }}
            linkProps={{
              label: 'Back',
              disabled: currentStep === videoScreensMap.celebration,
              onClick: back,
              hide: currentStep === videoScreensMap.celebration,
            }}
          />
        )}
      </StepperWrapper>
    </VideoPageWrapper>
  );
}
export default Video;
