import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import { useNavigate } from 'react-router-dom';
import { useEffect, useReducer, useRef, useState } from 'react';
import { ACTION_TYPES, INITIAL_STATE, isCaregiver, reducerFn } from './helper';
import { ReactComponent as EQ5D5LLogo } from './images/eq-5d-5l.svg';
import NoAnswerModal from './no-answer-modal';
import { Question } from './question';
import {
  ButtonWrapper,
  ContentWrapper,
  QuestionTitle,
  SurveyPage,
  SurveyWrapper,
} from './styles.js';
import Button from 'ihp-components/components/v2/Button';
import { SurveyProgressBar } from './survey-progress-bar';
import { surveyDataEN } from './surveyDataEN';
import { VerticalSlider } from './vertical-slider';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { StyledArrowRIght } from 'components/Activities/Instructions/styles';
import { ChevronLeft } from '@mui/icons-material';
import { STUDY_ARM_ROLE_NAME, STUDY_ARM_SUB_ROLE_NAME } from 'constants/global';
import { useGetAllPersonStudyArmAttributesQuery } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';
import { Loader } from 'components/Loader';
import { FinalStep } from 'pages/Activities/Survey/FinalStep';

export const HealthQuestionnaire = ({ activeActivity, setIsInstructions }) => {
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const roleName =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;
  const subRoleName =
    activeAccountProfile?.attributes?.profile?.study_arm_sub_role_name;
  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;

  const containerRef = useRef(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showCelebrationScreen, setShowCelebrationScreen] = useState(false);
  const [surveyData, setSurveyData] = useState([]);

  const {
    data: reporterAttributesData,
    isLoading: isReporterAttributesLoading,
  } = useGetAllPersonStudyArmAttributesQuery(
    {
      personStudyArmId: subjectPrimaryPersonStudyArmId,
      'page[limit]': 10000,
    },
    {}
  );

  const conditionsMap = {};
  reporterAttributesData?.data?.map((e) => {
    conditionsMap[e?.attributes?.attribute] = e?.attributes?.value === 'true';
  });

  useEffect(() => {
    const result = [];
    let index = 0;
    surveyDataEN.forEach((data) => result.push({ ...data, id: index++ }));
    setSurveyData(result);
  }, []);

  const [
    updateParticipantTask,
    { isLoading: createParticipantTaskEntryLoading },
  ] = useUpdateParticipantTaskMutation();

  const isLoading = isSubmiting || createParticipantTaskEntryLoading;

  const navigate = useNavigate();

  const [state, localDispatch] = useReducer(reducerFn, INITIAL_STATE);
  const { answers } = state;

  const [currentStep, setCurrentStep] = useState(0);
  const [healthValue, setHealthValue] = useState(50);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const participantTaskId = activeActivity.id;

  const handleSubmit = async (data) => {
    try {
      setIsSubmiting(true);
      await updateParticipantTask({
        participantTaskId,
        payload: {
          data: {
            type: 'participant-tasks',
            id: String(participantTaskId),
            attributes: {
              status: 'completed',
              entry: data,
            },
          },
        },
      });
      setIsSubmiting(false);
    } catch (error) {}
    setShowCelebrationScreen(true);
  };

  const onNext = () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      return;
    }

    const isIntro = surveyData[currentStep].type === 'intro';
    if (answers.hasOwnProperty(currentStep) || isIntro) {
      setCurrentStep(currentStep + 1);
      return;
    } else {
      handleOpenModal();
    }
  };

  const onPrevious = () => {
    if (currentStep === 0) {
      setIsInstructions(true);
    }

    setCurrentStep(currentStep - 1);
  };

  const onAnswer = (payload) => {
    localDispatch({
      type: ACTION_TYPES.QUESTION_UPDATE,
      payload,
    });
  };

  const onFinalize = () => {
    const values = Object.values(answers);

    let index = 0;
    let submitionJSON = {
      State: '',
    };

    surveyDataEN.forEach((e) => {
      if (e.key) {
        submitionJSON[e.key] = values[index];
        submitionJSON['State'] += values[index++];
      }
    });

    handleSubmit(submitionJSON);
  };

  const theme = useTheme();

  useEffect(() => {
    containerRef?.current?.scrollTo(0, 0);
  }, [currentStep]);

  if (isLoading || isReporterAttributesLoading) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flex={1}
        height='100vh'
        bgcolor='primary.gray20'
        width='100%'
      >
        <CircularProgress />
      </Box>
    );
  }

  const getTitle = (q) => {
    let title = '';
    if (isCaregiver(roleName) && q.caregiverTitle1) {
      title = (
        <>
          <>{q.caregiverTitle1}</>
          <br />
          <>{q.caregiverTitle2}</>
        </>
      );
    } else {
      return q.title;
    }
    return title;
  };

  if (showCelebrationScreen) {
    if (activeActivity?.attributes?.celebrationScreen?.display) {
      return (
        <FinalStep
          attributes={{
            points: activeActivity?.compensationValue,
            thankyouMsg: activeActivity?.attributes?.celebrationScreen?.title,
            description:
              activeActivity?.attributes?.celebrationScreen?.description,
          }}
        />
      );
    } else {
      navigate('/my-activities');
    }
  }

  return (
    <SurveyWrapper ref={containerRef}>
      <NoAnswerModal open={openModal} handleClose={handleCloseModal} />

      <SurveyProgressBar
        numberOfSteps={Object.keys(surveyData).length}
        current={
          currentStep >= Object.keys(surveyData).length
            ? Object.keys(surveyData).length
            : currentStep
        }
      />

      <ContentWrapper>
        <>
          <Box>
            {Object.values(surveyData).map((q, i) => {
              if (q.type === 'intro')
                return (
                  i === currentStep && (
                    <Box key={q.id} textAlign='center' marginY='30px'>
                      <Box margin={5}>
                        <EQ5D5LLogo></EQ5D5LLogo>
                      </Box>
                      <Typography margin={2} variant='h2'>
                        {getTitle(q)}
                      </Typography>
                      <Typography margin={2} variant='h2'>
                        {q.subtitle}
                      </Typography>
                      {isCaregiver(roleName) && (
                        <>
                          <Typography margin={2} variant='h2'>
                            {q.proxy}
                          </Typography>
                          <Typography margin={2} variant='h4' fontWeight={400}>
                            {q.proxySubtitle}
                          </Typography>
                        </>
                      )}
                    </Box>
                  )
                );

              if (q.type === 'radio')
                return (
                  i === currentStep && (
                    <Box key={q.id}>
                      <QuestionTitle>
                        {getTitle(q)} <sup>*</sup>
                      </QuestionTitle>
                      <SurveyPage>
                        <Question
                          key={i}
                          components={q}
                          answer={answers[i]}
                          onAnswer={onAnswer}
                        ></Question>
                      </SurveyPage>
                    </Box>
                  )
                );

              if (q.type === 'vertical-slider')
                return (
                  i === currentStep && (
                    <Box key={q.id}>
                      {getTitle(q)}
                      <SurveyPage>
                        <VerticalSlider
                          q={q}
                          healthValue={healthValue}
                          setHealthValue={setHealthValue}
                          onAnswer={onAnswer}
                        ></VerticalSlider>
                      </SurveyPage>
                    </Box>
                  )
                );

              return <></>;
            })}
          </Box>

          {/* {FINALIZE STEP - if there are [0,1,2,3,4,5] pages this is currentStep 6} */}
          {currentStep === Object.keys(surveyData).length && (
            <Box>
              <Typography
                marginBottom={'30px'}
                textAlign='center'
                color='primary.gray7'
                variant='h2'
              >
                You have now completed this questionnaire.
              </Typography>
              <Box marginBottom={'30px'}>
                <Typography color='primary.gray75' variant='pl3'>
                  If you would like to change any of your answers, you may do so
                  by pressing the "Previous" button prior to finalizing the
                  questionnaire.
                </Typography>
              </Box>
              <Box marginBottom={'30px'}>
                <Typography
                  marginBottom={'30px'}
                  color='primary.gray75'
                  variant='pl3'
                >
                  Please finalize the questionnaire by pressing the "Finalize"
                  button. Once you press "Finalize", you will not be able to go
                  back to review or change your answers.
                </Typography>
              </Box>
            </Box>
          )}
        </>

        {currentStep < Object.keys(surveyData).length && (
          <Typography textAlign='center' color={theme.palette.primary.gray75}>
            © EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol
            Research Foundation. USA (English) v1.1
          </Typography>
        )}

        <ButtonWrapper>
          <Button
            onClick={onPrevious}
            disabled={isLoading}
            startIcon={<ChevronLeft />}
          >
            Previous
          </Button>

          {currentStep !== Object.keys(surveyData).length && (
            <Button
              onClick={onNext}
              disabled={
                currentStep === Object.keys(surveyData).length || isLoading
              }
            >
              Next <StyledArrowRIght />
            </Button>
          )}

          {currentStep === Object.keys(surveyData).length && (
            <Button
              disabled={isLoading}
              sx={{}}
              onClick={onFinalize}
              startIcon={<CheckIcon />}
            >
              Finalize
            </Button>
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </SurveyWrapper>
  );
};
