import { formatISO } from 'date-fns';

export const getMyProfileConfig = (data) => {
  const { data: attrList = [] } = data;
  const targetAttr = attrList.find(
    (attr) =>
      attr?.data.attributes.attribute === 'personal_details_configuration'
  );

  if (!targetAttr) return {};
  const { value = {} } = targetAttr?.data?.attributes;

  return value?.my_profile;
};

// This function returns all values for the passed fields
const getFieldValues = (fields, personAttributes) => {
  if (!fields) return;
  let requiredAttributes = null;
  if (personAttributes) {
    fields.forEach((field) => {
      const attribute = personAttributes.find(
        (item) => item.attributes.attribute === field
      );
      requiredAttributes = {
        ...requiredAttributes,
        [field]: attribute?.attributes.value || '',
      };
    });
  }
  return requiredAttributes;
};

// This function returns all required form fields from config
export const getFormFields = (config, personAttributes) => {
  if (!config) return {};
  const fieldsKeys = Object.keys(config);
  const fields = fieldsKeys.reduce((acc, currVal) => {
    if (config[currVal]) {
      if (currVal === 'language') {
        acc.push('languages');
      } else {
        acc.push(currVal);
      }
    }
    return acc;
  }, []);
  const formFields = getFieldValues(fields, personAttributes);
  return formFields;
};

export const getPayload = (data) => {
  if (data?.date_of_birth) {
    let date = new Date(data.date_of_birth);
    date.setHours(0, 0, 0, 0);
    data.date_of_birth = formatISO(date, {
      representation: 'complete',
    });
  }
  const formKeys = Object.keys(data);
  const form = formKeys.map((item) => ({
    data: {
      type: 'person-attributes',
      attributes: {
        attribute: item === 'language' ? 'languages' : item,
        value: data[item],
      },
    },
  }));

  return form;
};

export const getMyProfileReasons = (reasons, targetReason) => {
  const reasonsArray = reasons[targetReason];
  let result = [];
  if (reasonsArray?.length > 0) {
    let index = 1;
    result = reasonsArray.map((r) => ({
      value: index++,
      label: r.labels[0].label,
    }));
  }
  return result;
};

export const getMyProfileReasonsForStudyArm = (reasons, targetReason, studyArm) => {
  const reasonsArray = reasons[targetReason][studyArm];
  let result = [];
  if (reasonsArray?.length > 0) {
    let index = 1;
    result = reasonsArray.map((r) => ({
      key: index++,
      label: r.labels[0].label,
      value: r.key
    }));
  }

  return result;
};

export const validatePhone = () => (phone) =>
  !phone.includes('_') || 'This phone number is invalid';

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
