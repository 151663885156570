import { format } from 'date-fns';
import { Box } from '@mui/material';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  selectMedicationById,
  selectReminderById,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';
import {
  setDoseTaken,
  resetDoseTaken,
  selectIsDoseTaken,
} from 'ihp-bloom-redux/features/medicationTracker/doseTakenSlice';

import { ListItemSubtitle, ListItemTitle, ListItemWrapper } from './styles';
import { Input } from 'ihp-components/components/v2/Input';

export const ReminderCard = ({ id, date }) => {
  const strDate = format(date, 'yyyy/MM/dd');
  const dispatch = useDispatch();

  const { medicationId, time, pills, dosage } = useSelector(
    selectReminderById(id)
  );

  const dosageUnit = useSelector(selectMedicationById(medicationId)).drug
    .dosageUnit;
  const taken = useSelector(selectIsDoseTaken(id, strDate));

  const handleCheck = (_, checked) => {
    if (checked) dispatch(setDoseTaken({ id, date: strDate }));
    else dispatch(resetDoseTaken({ id, date: strDate }));
  };

  const title = format(new Date(`1970-01-02T${time}`), 'h:mm a');
  const subtitle = `${pills} Pill(s), ${dosage} ${dosageUnit}`;

  return (
    <ListItemWrapper>
      <Input
        type='checkbox'
        label=''
        checked={taken}
        onChange={handleCheck}
      />
      <Box flex={1} margin='0 15px'>
        <ListItemTitle>{title}</ListItemTitle>
        <ListItemSubtitle>{subtitle}</ListItemSubtitle>
      </Box>
    </ListItemWrapper>
  );
};
