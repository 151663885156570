import { Typography, useMediaQuery } from "@mui/material";

import { Container, PageWrapper } from "./styles";
import { anyQuestionsContent } from "./constants";

const AnyQuestions = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const { title, description } = anyQuestionsContent;

    return (
        <Container isMobile={isMobile}>
            <PageWrapper>
                <Typography
                    variant='h1'
                    padding='0'
                    marginBottom={'20px'}
                    color={'primary.navy'}
                >
                    {title}
                </Typography>
                <Typography variant='pl1' color={'primary.navy'} component={'p'}>
                    {description}
                </Typography>
            </PageWrapper>
        </Container>
    );
};

export default AnyQuestions;