import { v4 as uuid } from 'uuid';

const getNormalizedActions = (
  getters = {
    getIds: (state) => state.ids,
    getEntities: (state) => state.entities,
  }
) => {
  const { getIds, getEntities } = getters;
  return {
    reorderAction: (state, { payload }) => {
      const { from, to } = payload;
      const [removed] = getIds(state, payload).splice(from, 1);
      getIds(state, payload).splice(to, 0, removed);
    },

    addAction: (state, { payload }) => {
      const id = payload.id ? payload.id : uuid();
      getIds(state, payload).push(id);
      getEntities(state, payload)[id] = { id, ...payload };
    },

    removeAction: (state, { payload }) => {
      const { id } = payload;
      delete getEntities(state, payload)[id];
      const index = getIds(state, payload).indexOf(id);
      getIds(state, payload).splice(index, 1);
    },

    updateAction: (state, { payload }) => {
      getEntities(state, payload)[payload.id] = payload;
    },

    enableAction: (state, { payload }) => {
      const { id, enabled } = payload;
      getEntities(state, payload)[id].enabled = enabled;
    },
  };
};

export default getNormalizedActions;
