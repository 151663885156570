// I couldn't find any lib to translate it but More than 10 reminders a day for
// the same drug is too much.
export const cardToOrdMap = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eight',
  'Ninth',
  'Tenth',
];
