import { Container } from './Header.styles';
import { ProgressBar } from './progressBar';

export const Header = ({
  subHeader,
  currentStep,
  stepsLength,
  showProgressBar,
}) => (
  <Container>
    {subHeader instanceof Function ? subHeader(currentStep) : subHeader}
    {showProgressBar && (
      <ProgressBar
        currentStep={currentStep}
        stepsLength={stepsLength}
      />
    )}
  </Container>
);
