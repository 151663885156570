import { Box, Stack, styled, Typography } from '@mui/material';
import {
  ButtonRow,
  StyledArrowRIght,
} from 'components/Activities/Instructions/styles';
import Button from 'ihp-components/components/v2/Button';
import { CancelButton } from 'configUI/pages/StaticPages/index.styles';
import { useNavigate } from 'react-router-dom';

export default function HealthInstructions({ setIsInstructions, description }) {
  const navigate = useNavigate();

  return (
    <Container>
      <Wrapper>
        <Typography variant='pb4' color='primary.main' mb='7px'>
          INSTRUCTIONS
        </Typography>
        <Typography variant='h2' color='#444444' mb='15px'>
          How to complete this task successfully?
        </Typography>
        <>
          <Typography variant='pl3' color='primary.gray75' mb='50px'>
            {description}
          </Typography>
        </>
        <Stack direction='row' justifyContent='flex-end' spacing='5px'>
          <ButtonRow>
            <CancelButton
              variant='link'
              onClick={() => navigate('/my-activities')}
            >
              Cancel
            </CancelButton>
            <Button onClick={() => setIsInstructions(false)}>
              Start <StyledArrowRIght />
            </Button>
          </ButtonRow>
        </Stack>
      </Wrapper>
    </Container>
  );
}

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '70px',
  width: '100%',
  paddingLeft: '40px',
}));

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: '50px',
  flex: 1,
  position: 'relative',
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  height: 'calc(100dvh - 90px)',
}));

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '700px',
  padding: '60px 50px 40px 50px',
  borderRadius: '8px',
  borderBottom: `1px solid ${theme.palette.primary.gray10}`,
  backgroundColor: 'white',
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  [theme.breakpoints.down('md')]: {
    margin: '10px',
  },
}));
