import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { useGetStudyAttributesQuery } from 'ihp-bloom-redux/features/study/studyApi';
import { useCreateUserMutation } from 'ihp-bloom-redux/features/user/userApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const participantStatus = {
  INITIALIZED: 'ini',
  ONBOARDED: 'onboarded',
  CONSENTED: 'consented',
  AUTHENTICATED: 'authenticated',
  REGISTERED: 'registered',
  ENROLLED: 'enrolled',
  WITHDRAWN: 'withdrawn',
  COMPLETED: 'completed',
};

export const statusUrl = {
  [participantStatus.AUTHENTICATED]: '/onboarding/participant-consents',
  [participantStatus.CONSENTED]: '/onboarding/additionalinformation',
  [participantStatus.ONBOARDED]: '/my-activities',
  [participantStatus.ENROLLED]: '/my-activities',
};

export const isPathSame = (current, target) => {
  return current?.includes(target);
};

export const getNavigationUrlByStatus = (status, currentPathname) => {
  if (isPathSame(currentPathname, statusUrl[status])) {
    return '';
  }
  return statusUrl[status];
};

export const getUserPayload = (user, studyRoleId) => {
  const currentDate = new Date();
  return {
    data: {
      attributes: {
        first_name: user.given_name,
        last_name: user.family_name,
      },
      relationships: {
        user_attribute: [
          {
            data: {
              type: 'user_attribute',
              attributes: {
                name: 'email',
                value: user.email,
              },
            },
          },
        ],
        study: {
          data: {
            type: 'study',
            id: 1,
          },
        },
        person: {
          data: {
            type: 'person',
            attributes: {
              status: !user?.email_verified ? 'ini' : 'authenticated',
              type: 'PARTICIPANT',
              source: '123',
              state: 'enrollment',
              sex_at_birth: 'unknown',
              gender: 'NA',
              status_date: currentDate,
            },
          },
        },
        study_role: [
          {
            data: {
              type: 'study_role',
              id: studyRoleId,
            },
          },
        ],
      },
    },
  };
};

export const useCreateUser = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [createUserMutation] = useCreateUserMutation();

  return (studyRoleId) => {
    createUserMutation(getUserPayload(user, studyRoleId))
      .then(({ data }) => {
        const person = data?.data?.included?.[0]?.[0]?.data;
        dispatch(
          setValues({
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email,
            active_person_id: person.id,
          })
        );
      })
      .catch(console.error);
  };
};

export const useGuidedNavigationByStatus = (
  person,
  personAttributes,
  studyAttributes,
  logout
) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (person && personAttributes && studyAttributes) {
      const url = statusUrl[person?.attributes?.status];
      const alternativeContact = personAttributes?.find(
        (item) => item.attributes.attribute === 'alternative_contact'
      );
      const doctor = personAttributes?.find(
        (item) => item.attributes.attribute === 'doctor'
      );

      const phone = personAttributes?.find(
        (item) => item.attributes.attribute === 'phone'
      );

      const timezone = personAttributes?.find(
        (item) => item.attributes.attribute === 'timezone'
      );

      if (url && !isPathSame(location.pathname, url)) {
        const status = person?.attributes?.status;
        const isStatusValidated =
          status === 'onboarded' || status === 'enrolled';
        const isDocusignRejected = location.search.includes(
          'docusignStatus=rejected'
        );

        // Since docusign is rejected and status is enrolled or onboarded this action came from /profile/consents
        // That is why we are redirecting there
        if (isDocusignRejected && isStatusValidated) {
          navigate('/profile/consents?docusignStatus=rejected');
          return;
        }

        /* If status is authenticated and study roles has any role `Call Center Representative`
          then first display communication screen before consents
        */
        if (
          status === participantStatus.AUTHENTICATED &&
          studyAttributes.shouldShowCommunicationScreen &&
          (!phone || !timezone)
        ) {
          navigate('/onboarding/communication');
          return;
        }

        navigate(url);
      } else {
        if (
          studyAttributes?.alternative_contacts &&
          person?.attributes?.status === participantStatus.REGISTERED
        ) {
          navigate('/onboarding/alternativecontact');
        } else if (
          studyAttributes?.doctor_information &&
          person?.attributes?.status === participantStatus.REGISTERED
        ) {
          navigate('/onboarding/doctors');
        } else if (person?.attributes?.status === participantStatus.WITHDRAWN) {
          setLoggingOut(true);
          logout({
            returnTo: `${window.location.origin}/welcome?open-auth0=signin&person-status=${participantStatus.WITHDRAWN}&allow-re-enroll=${studyAttributes?.allowReEnroll}`,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person, personAttributes, studyAttributes]);
  return {
    loggingOut,
  };
};
