import { Box, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  color: theme.palette.primary.white,
  padding: 0,
  textAlign: 'start',
  fontStyle: 'normal',
  textTransform: 'none',
  '&:disabled': {
    color: theme.palette.primary.white,
  },
}));

export const Icon = styled(Box)({
  display: 'flex',
});

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  background: theme.palette.primary.fuchsia75,
  padding: '6px 15px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  height: '48px',
}));

const FontBase = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '1.5rem',
});

export const Title = styled(FontBase)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.secondary.gray100,
}));

export const Count = styled(FontBase)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.primary.fuchsia15,
}));

export const Value = styled(FontBase)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.primary.white,
}));

export const Loader = styled(CircularProgress)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.white,
}));
