import { Stack, Typography } from '@mui/material';

import { ControllerForm } from 'configUI/components/Shared/ControllerForm';
import { Loader } from 'components/Loader';
import { Input } from 'ihp-components/components/v2/Input';

export const InitialQuestion = ({ questions }) => {
  if (!questions) return <Loader />;

  const introQuestions = questions.filter(
    (question) => question.category === 'intro'
  );

  if (!introQuestions[0]) return null;

  const { id, questionTitle, questionType } = introQuestions[0];

  if (questionType !== 'text') {
    console.error('Invalid question type', questionType);
    return null;
  }

  return (
    <Stack spacing='20px' alignItems='stretch'>
      <Typography variant='h5' color='secondary.gray4' align='center'>
        {questionTitle}
      </Typography>
      <ControllerForm name={id}>
        <Input placeholder={questionTitle} sx={{ width: '100%' }} />
      </ControllerForm>
    </Stack>
  );
};
