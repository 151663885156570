import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const TimepickerStyleWrapper = styled(Box)(({ theme }) => ({
  '& .PrivatePickersToolbar-dateTitleContainer': {
    '& .Mui-selected': {
      color: theme.palette.primary.fuchsia75
    }
  },
}));
