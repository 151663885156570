import { styled } from '@mui/system';
import { Box } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';

export const Container = styled(Box)({
  height: '80px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  boxSizing: 'border-box',
});

const commonButtonProp = {
  padding: '0 30px 0 25px',
  height: '50px',
  fontWeight: 600,
};

export const StyledFilledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 auto',
  }
}));

export const StyledLinkButton = styled(Button)(({ theme }) => ({
  ...commonButtonProp,
  [theme.breakpoints.down('sm')]: {
    flex: '1 1 auto',
  }
}));
