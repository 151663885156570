import { useSearchParams } from 'react-router-dom';
import { matchSorter } from 'match-sorter';
import { isValid, format, parse } from 'date-fns';

const filter = (values, query) => {
  // return all if no input value
  if (!query || !query.length) return values;

  // Get all terms
  const terms = query.trim().split(' ');
  if (!terms) return values;

  // Items where all terms matched
  const fullMatches = terms.reduceRight(
    (results, term) => matchSorter(results, term),
    values
  );

  // Items matching only one term
  const partialMatches = terms.reduce(
    (results, term) => matchSorter(values, term).concat(results),
    []
  );

  // Remove duplicated results keeping full matches at the beginning since
  // these are more relevant.
  return fullMatches
    .concat(partialMatches)
    .filter((value, index, self) => self.indexOf(value) === index);
};

export const useDosageUnits = () => {
  const dosageUnits = [
    'IU',
    'Ampoule(s)',
    'Application(s)',
    'Capsule(s)',
    'Drop(s)',
    'Gram(s)',
    'Injection(s)',
    'Milligram(s)',
    'Milliliter(s)',
    'Packet(s)',
    'Patch(s)',
    'Pessary(s)',
    'Piece(s)',
    'Oral Pills(s)',
    'Oral Liquid',
    'Puff(s)',
    'Spray(s)',
    'Suppository(s)',
    'Tablespoon(s)',
    'Teaspoon(s)',
    'Unit(s)',
    'Vaginal capsule(s)',
    'Vaginal insert(s)',
    'Vaginal tablet(s)',
  ];

  const extractDosageUnit = (medicationName) => {
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(medicationName);

    // So we have a limited list of dosage units. First we try to match any
    // item of this list. Then, we just match the content of parenthesis.
    // Finally we return empty string.
    return (
      (matches?.[1] && filter(dosageUnits, matches[1])[0]) || matches?.[1] || ''
    );
  };

  return { dosageUnits, extractDosageUnit };
};

export const useFilterOptions = () => (options, inputValues) =>
  filter(options, inputValues);

export const useSearchParamDate = (pattern) => {
  const parseDate = (dateStr) => {
    const date = parse(dateStr, pattern, new Date());
    return isValid(date) ? date : new Date();
  };

  const formatDate = (date) => format(date, pattern);

  const [searchParams, setSearchParams] = useSearchParams();
  const setDate = (date) => setSearchParams({ date: formatDate(date) });
  const date = parseDate(searchParams.get('date'));

  return [date, setDate];
};
