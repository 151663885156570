import { createApi } from '@reduxjs/toolkit/query/react';
import { baseSmpGqlQuery } from '../__base';

export const registryStatisticsApi = createApi({
  reducerPath: 'registryStatisticsApi',
  baseQuery: baseSmpGqlQuery(),
  tagTypes: ['registryStatistics'],
  endpoints: (build) => ({
    getOverview: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/dtp_registry_home_page',
        method: 'POST',
        body: {
          query: `query { dtp_registry_home_page { edges { node { id stats1 stats2 stats3 stats4 lastRefreshedAt } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getTerritoriesContinent: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/dtp_heatmap_territories_continent',
        method: 'POST',
        body: {
          query: `query { dtp_heatmap_territories_continent { edges { node { continent, lastRefreshedAt, distributionLimit, percent } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getTerritoriesStates: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/dtp_heatmap_territories_state',
        method: 'POST',
        body: {
          query: `query { dtp_heatmap_territories_state { edges { node { state lastRefreshedAt percent distributionLimit } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getAgeStats: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/vw_smp_registry_participants_age',
        method: 'POST',
        body: {
          query: `query { vw_smp_registry_participants_age { edges {node { study_arm_code site_name age_limit last_refreshed_at count_participant } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getDistributionOfFtdDisorders: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/dtp_distribution_ftd_disorders',
        method: 'POST',
        body: {
          query: `query { dtp_distribution_ftd_disorders(filter:"educationCategoryTitle=outi=('Unknown')") { edges { node { educationCategoryTitle countParticipant lastRefreshedAt } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getDistributionOfGeneInheritance: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/vw_smp_registry_participants_conditions',
        method: 'POST',
        body: {
          query: `query { vw_smp_registry_participants_conditions { edges { node { study_arm_name
              site_name
              reported_condition
              count_participant
              last_refreshed_at } } } }`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),
    getParticipantsRace: build.mutation({
      query: (/* any parameters here */) => ({
        url: 'graphql/api/v1/smp_av_registry_participants_race',
        method: 'POST',
        body: {
          query: `{"query":"query {  smp_av_registry_participants_race  {    edges {      node {          count_participant          study_arm_name          site_name          race_ethnicity               }    }  }}"}`
        },
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
        }
      }),
    }),


  })
});
export const {
  useGetOverviewMutation,
  useGetTerritoriesContinentMutation,
  useGetTerritoriesStatesMutation,
  useGetAgeStatsMutation,
  useGetDistributionOfFtdDisordersMutation,
  useGetDistributionOfGeneInheritanceMutation,
  useGetParticipantsRaceMutation
} = registryStatisticsApi;


