import { styled } from '@mui/material/styles';
import { Box, CardActions, Typography } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';
import { isMobile } from 'utils/isMobile';

export const ActionContainer = styled(Box)({
  minWidth: '50%',
});

export const Reward = styled(Typography)({
  maxWidth: '50%',
  display: 'flex',
  alignItems: isMobile() ? 'center' : 'row-reverse',
  gap: '8px',
});

export const TotalPoints = styled('div')({
  fontSize: '12px',
  fontWeight: '500',
  marginRight: '25px',
  float: 'right',
  textTransform: 'uppercase',
});

export const activityCardStyle = {

};

export const ActivityCardActions = styled(({ ...rest }) => (
  <CardActions {...rest} />
))(() => ({
  padding: '0',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ActivityAction = styled(
  ({ actionBgColor, actionColor, ...rest }) => <Button {...rest} />
)(({ actionBgColor, actionColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '100%',
  margin: '0px auto',
  background: actionBgColor,
  border: `1px solid #D9D9D9`,
  color: actionColor,
  '&: hover': {
    background: actionBgColor,
  },
}));

export const ActivityCardActionsMobile = styled(
  ({ rewardBgColor, ...rest }) => <CardActions {...rest} />
)(({ rewardBgColor }) => ({
  position: 'relative',
  padding: '0',
  height: '50px',
  backgroundColor: rewardBgColor,
  borderRadius: '8px',
  justifyContent: 'space-between',
}));

export const ActivityActionMobile = styled(
  ({ actionBgColor, actionColor, ...rest }) => <Button {...rest} />
)(({ actionBgColor, actionColor }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  width: '100%',
  height: '50px',
  background: actionBgColor,
  color: actionColor,
  paddingLeft: '22px',
  paddingRight: '22px',
  '&: hover': {
    background: actionBgColor,
  },
}));

export const styles = {
  estimatedTime: {
    fontType: 'Inter',
    display: 'flex',
    flexDirection: 'row-reverse',
    fontSize: '10px',
    fontWeight: '500',
  },
};
