import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { userApiSlice } from 'ihp-bloom-redux/features/user/userApiSlice';
import { authenticationApiSlice } from 'ihp-bloom-redux/features/authentication/authenticationApiSlice';

import { Loader } from 'components/Loader';

function Login() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const context =
    queryParams.get('context') && queryParams.get('context') !== null
      ? queryParams.get('context')
      : undefined;

  const [{ isLoading }] = authenticationApiSlice.useSocialLoginMutation();

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const [checkUser, { isLoading: checkUserLoader }] =
    userApiSlice.useCheckUserMutation();

  useEffect(() => {
    (async () => {
      const userEnrolled = await onCheckUser();
      if (isAuthenticated && userEnrolled) {
        return navigate('/my-activities');
      } else {
        // return loginWithRedirect({
        //   context,
        //   appState: { context },
        //   prompt: 'select_account',
        // });
        return navigate('/welcome');
      }
    })();
    // eslint-disable-next-line
  }, [navigate, isAuthenticated]);

  const onCheckUser = async () => {
    if (!isAuthenticated) return;
    const { error: data } = await checkUser();
    if (data.data.error !== 'Patient does not exist!') {
      return true;
    }
    return false;
  };

  if (isLoading || checkUserLoader) {
    return <Loader />;
  }

  return <Loader />;
}
export default Login;
