export const scheduleCallScreensMap = {
  howTo: 0,
  selectDay: 1,
  celebration: 2,
};

export const dateStepDataFormat = {
  header: 'Select a day',
  subHeader:
    'Please check our Study Agents availability and select the most suitable date for your for the TeleHealth call',
  type: 'datePicker',
  content: {
    defaultValue: '19-01-1994',
  },
};

export const timeStepDataFormat = {
  header: 'Select a time for ',
  subHeader: 'Please select the most suitable time for your follow-up call',
  type: 'radio',
  content: {
    morning: {
      options: [],
    },
    afternoon: {
      options: [],
    },
  },
};

export const selectFromPatientTaskResult = ({ data, ...rest }) => {
  const attributes = {};
  data?.data.task.attributes.forEach((attribute) => {
    if (
      attribute.attribute === 'duration' ||
      attribute.attribute === 'howTo' ||
      attribute.attribute === 'meta' ||
      attribute.attribute === 'celebrationScreen' ||
      attribute.attribute === 'full_schedule_call_description'
    ) {
      attributes[attribute.attribute] = {
        ...attribute,
        value: attribute.value ? JSON.parse(attribute.value) : attribute.value,
      };
    } else {
      attributes[attribute.attribute] = { ...attribute };
    }
  });

  return {
    participantTaskDetails: {
      ...data?.data,
      task: {
        ...data?.data?.task,
        ...attributes,
      },
    },
    ...rest,
  };
};
