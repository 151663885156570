import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@emotion/react';

//TODO: Import components from ihp-components
import { ActivityCard } from 'components/Cards';
import { ActivityCardMobile } from 'components/Cards/ActivityCardMobile';
import { Loader } from 'components/Loader';
import { getColors, iconBgColorMap } from 'pages/Activities/utils';
import { mapTaskRequiredStatus } from 'utils/tasks';
import {
  activityTypeNamesMapping,
  ActivityTypesIconMap,
  ActivityTypesLinksMap,
} from 'utils';
import UpcomingActivities from './Upcoming';
import PastActivities from './Past';
import CurrentActivities from './Current';

import { ActivitiesContainer, getStyles, MobileListContainer } from './styles';
import { Box } from '@mui/material';
import { EmptyList } from './Empty';
import { isHealthSurvey } from './util';
import { sortActivities } from 'utils/activities';

function ActivityItems({
  personId,
  studyRoleId,
  selectedTaskCategory,
  handleProfileActivity,
  handleSignConsent,
  isFetchingConsentDetails,
  isUpdatingTask,
  filtersConfig,
  onViewMore,
  maxVisible,
  viewMoreType,
  timezone,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getActivityCard = (activity, index, isTaskGroup, isChild, isLast) => {
    const type = activity?.type;
    const colors = getColors(false, false);
    const icon = ActivityTypesIconMap[type];

    let showLoader = false;

    if (type === 'profile' && isUpdatingTask) {
      showLoader = true;
    }
    if (type === 'consent' && isFetchingConsentDetails) {
      showLoader = true;
    }

    let taskLink = ActivityTypesLinksMap(activity.participantTaskId, type);
    if (isHealthSurvey(activity?.code)) {
      taskLink = `/health-survey/${activity?.id}`;
    }

    if (isMobile) {
      return (
        <ActivityCardMobile
          sx={getStyles(
            theme,
            activity,
            isTaskGroup,
            isChild,
            isLast,
            activity.isOverdue
          )}
          key={activity.id}
          type={type}
          icon={icon}
          link={taskLink}
          onProfileActivity={() => handleProfileActivity(activity.id)}
          onConsentActivity={() => handleSignConsent(activity)}
          showLoader={showLoader}
          iconBgColor={iconBgColorMap[type]}
          title={activity?.title}
          description={activity?.description}
          currentIndex={index + 1}
          actionText={activity?.actionText}
          rewardPoints={activity?.points}
          actionBgColor={colors.actionButtonBGColor}
          disabled={activity?.isActionDisabled || showLoader}
          actionColor={colors.actionButtonColor}
          rewardColor={colors.rewardColor}
          rewardBgColor={colors.rewardBGColor}
          detailsColor={colors.detailsColor}
          status={activity?.status}
          statusColor={colors.statusColor}
          details={{
            type: { label: activityTypeNamesMapping[type] },
            duration: { label: activity.duration },
            remaining: activity.remaining,
            consentVersion: activity.consentVersion,
            consentVersionDate: activity.consentVersionDate,
            isOverdue: activity.isOverdue,
            isLocked: activity.isLocked,
            required: activity.required,
            date: activity.date,
            isPast: activity.isPast,
            isAwaitingVerification:
              activity.displayStatus === 'awaiting_verification',
            status: activity.status,
            statusDate: activity.statusDate,
            statusDateFull: activity.statusDateFull,
            activityPersonId: activity.personId,
          }}
          metaData={[
            {
              label:
                mapTaskRequiredStatus(activity.required) === 'REQUIRED'
                  ? 'Required'
                  : '',
            },
            {
              label: activityTypeNamesMapping[type],
            },
            activity.remaining,
          ]}
          hideDetails={activity.hideDetails}
          hideNumbers={true}
          isTaskGroup={isTaskGroup}
          subtasksLength={activity?.subtasks?.length}
          completedSubtasks={activity?.completedSubtasks}
          selectedTaskCategory={selectedTaskCategory}
        />
      );
    }

    // TODO: Refactor this to use "ActivityCard" component from "ihp-components"
    return (
      <ActivityCard
        sx={getStyles(
          theme,
          activity,
          isTaskGroup,
          isChild,
          isLast,
          activity.isOverdue
        )}
        key={activity.id}
        type={type}
        icon={icon}
        link={taskLink}
        onProfileActivity={() => handleProfileActivity(activity.id)}
        onConsentActivity={() => handleSignConsent(activity)}
        showLoader={showLoader}
        iconBgColor={iconBgColorMap[type]}
        title={activity?.title}
        description={activity?.description}
        currentIndex={index + 1}
        actionText={activity?.actionText}
        rewardPoints={activity?.points}
        actionBgColor={colors.actionButtonBGColor}
        disabled={activity?.isActionDisabled || showLoader}
        actionColor={colors.actionButtonColor}
        rewardColor={colors.rewardColor}
        rewardBgColor={colors.rewardBGColor}
        status={activity?.status}
        statusColor={colors.statusColor}
        details={{
          type: { label: activityTypeNamesMapping[type] },
          consentVersion: activity.consentVersion,
          consentVersionDate: activity.consentVersionDate,
          duration: { label: activity.duration },
          remaining: activity.remaining,
          isOverdue: activity.isOverdue,
          isDueTomorrow: activity.isDueTomorrow,
          isLocked: activity.isLocked,
          isPast: activity.isPast,
          required: activity.required,
          date: activity.date,
          status: activity.status,
          statusDate: activity.statusDate,
          statusDateFull: activity.statusDateFull,
          daysUntilDueDate: activity.daysUntilDueDate,
          isAwaitingVerification:
            activity.displayStatus === 'awaiting_verification',
          timezone,
          activityPersonId: activity.personId,
        }}
        hideDetails={activity.hideDetails}
        hideNumbers={true}
        isTaskGroup={isTaskGroup}
        subtasksLength={activity?.subtasks?.length}
        completedSubtasks={activity?.completedSubtasks}
      />
    );
  };

  const renderActivities = (activities = [], isLoading, maxVisible) => {
    // REFACTOR: This should be done in the backend
    // Doing here just in case we do not have time for this release
    activities = sortActivities(activities);

    if (isLoading) {
      return <EmptyList isLoading={isLoading} />;
    }

    if (activities?.length === 0) {
      return <></>;
    }

    if (isMobile) {
      return isLoading ? (
        <Loader />
      ) : (
        <MobileListContainer>
          {(maxVisible ? activities.slice(0, maxVisible) : activities).map(
            (activity, index) =>
              activity.type === 'group' ? (
                <React.Fragment key={activity.id}>
                  {getActivityCard(activity, index, true)}
                  {activity.subtasks?.map((item, index) => {
                    const isLast = index === activity.subtasks?.length - 1;
                    return getActivityCard(item, index, false, true, isLast);
                  })}
                </React.Fragment>
              ) : (
                getActivityCard(activity, index)
              )
          )}
        </MobileListContainer>
      );
    }

    return (
      <ActivitiesContainer>
        {activities.slice(0, maxVisible).map((activity, index) =>
          activity.type === 'group' ? (
            <React.Fragment key={activity.id}>
              {getActivityCard(activity, index, true)}
              {activity.subtasks?.map((item, index) => {
                const isLast = index === activity.subtasks?.length - 1;
                return getActivityCard(item, index, false, true, isLast);
              })}
            </React.Fragment>
          ) : (
            getActivityCard(activity, index)
          )
        )}
      </ActivitiesContainer>
    );
  };

  return (
    <>
      {isMobile && (
        <Box
          display={'flex'}
          flexDirection={'column'}
          flex={1}
          gap={'20px'}
          p={'20px'}
        >
          {(viewMoreType === 'CURRENT' || !viewMoreType) && (
            <CurrentActivities
              maxVisible={maxVisible}
              isMobile={isMobile}
              personId={personId}
              studyRoleId={studyRoleId}
              renderActivities={renderActivities}
              filtersConfig={filtersConfig}
              onViewMore={onViewMore}
              disableLoadMore={selectedTaskCategory !== 'CURRENT'}
            />
          )}

          {(viewMoreType === 'UPCOMING' || !viewMoreType) && (
            <UpcomingActivities
              maxVisible={maxVisible}
              isMobile={isMobile}
              personId={personId}
              studyRoleId={studyRoleId}
              renderActivities={renderActivities}
              filtersConfig={filtersConfig}
              onViewMore={onViewMore}
              disableLoadMore={selectedTaskCategory !== 'UPCOMING'}
            />
          )}

          {(viewMoreType === 'PAST' || !viewMoreType) && (
            <PastActivities
              maxVisible={maxVisible}
              isMobile={isMobile}
              personId={personId}
              studyRoleId={studyRoleId}
              renderActivities={renderActivities}
              filtersConfig={filtersConfig}
              onViewMore={onViewMore}
              disableLoadMore={selectedTaskCategory !== 'PAST'}
            />
          )}
        </Box>
      )}
      {!isMobile && selectedTaskCategory === 'CURRENT' && (
        <CurrentActivities
          personId={personId}
          studyRoleId={studyRoleId}
          renderActivities={renderActivities}
          filtersConfig={filtersConfig}
        />
      )}
      {!isMobile && selectedTaskCategory === 'UPCOMING' && (
        <UpcomingActivities
          personId={personId}
          studyRoleId={studyRoleId}
          renderActivities={renderActivities}
          filtersConfig={filtersConfig}
        />
      )}
      {!isMobile && selectedTaskCategory === 'PAST' && (
        <PastActivities
          personId={personId}
          studyRoleId={studyRoleId}
          renderActivities={renderActivities}
          filtersConfig={filtersConfig}
        />
      )}
    </>
  );
}

ActivityItems.propTypes = {
  personId: PropTypes.number,
  studyRoleId: PropTypes.number,
  selectedTaskCategory: PropTypes.string,
  handleProfileActivity: PropTypes.func,
  handleSignConsent: PropTypes.func,
  isFetchingConsentDetails: PropTypes.bool,
  isUpdatingTask: PropTypes.bool,
  onViewMore: PropTypes.func,
};

export default ActivityItems;
