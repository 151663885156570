import { FamilyLinkageInvitation } from 'pages/Profile/FamilyLinkage/components/FamilyLinkageInvitation';
import { List, Section, Title } from 'pages/Profile/FamilyLinkage/styles';

export function Members({ familyMembers }) {
  return (
    <Section>
      <Title>Linked Family Members</Title>
      <MembersList familyMembers={familyMembers} />
    </Section>
  );
}

function MembersList({ familyMembers }) {
  return (
    <List>
      {familyMembers.map((fi) => (
        <FamilyLinkageInvitation
          firstName={fi.person_first_name}
          lastName={fi.person_last_name}
        />
      ))}
    </List>
  );
}
