import { useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import PregnancyJournalDesktop from './index.desktop';
import PregnancyJournalMobile from './index.mobile';

const PregnancyJournal = () => {
  document.title = 'Single Ventricle SOURCE Pregnancy Journal';
  const theme = useTheme();

  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  return upSm ? <PregnancyJournalDesktop /> : <PregnancyJournalMobile />;
};

export default PregnancyJournal;
