import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useUpdateCurrentUserMutation } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';

import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
  STUDY_ARM_STATUS_NAMES,
} from 'constants/global';
import getRoute from 'utils/navigator';

/**
 * This custom hook is responsible to check query params for email verification
 *
 * @param {Boolean} authenticationStatus - Authentication status from cached user state
 * @param {String} userId - User Id from cached user state
 * @param {String} activeAccountProfile - Active account profile details cached in user state
 *
 * @returns {
 *  verifyingEnrollmentFlag - Loading state for api processing
 * }
 */

export function useVerifyEmail(
  authenticationStatus,
  userId,
  activeAccountProfile,
  isLoadingAccountProfileDetails
) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { user } = useAuth0();

  const { configuration } = useSelector((state) => state.configuration);

  const [verifyingEnrollmentFlag, setVerifyingEnrollmentFlag] = useState(false);

  /**
   * For regular users enrollment verifying an email contact person study arm id is saved as "account_person_study_arm_id"
   */
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;
  /**
   * For reporter user verifying an email contact person study arm id is saved as "subject_secondary_person_study_arm_id"
   */
  const secondarySubjectPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_secondary_person_study_arm_id;
  const secondarySubjectPersonStudyArmStatusTitle =
    activeAccountProfile?.attributes?.profile
      ?.subject_secondary_study_arm_status_title;
  const isReporterVerifyingEmail =
    secondarySubjectPersonStudyArmId !== null &&
    secondarySubjectPersonStudyArmStatusTitle ===
      STUDY_ARM_STATUS_NAMES['ENROLLMENT-TO-BE-VERIFIED'];

  const contactEnrollmentVerified =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_VERIFIED
    ];

  /**
   * API calls are listed below
   */

  const [updateCurrentUser] = useUpdateCurrentUserMutation();
  const [updatePersonStudyArm] = useUpdatePersonStudyArmMutation();

  const updateCurrentUserAPI = () => {
    const attributesObject = {
      authentication_status: true,
      name: `${user.given_name} ${user.family_name}`,
    };
    const payload = {
      data: {
        type: 'users',
        id: `${userId}`,
        attributes: attributesObject,
      },
    };
    return updateCurrentUser({ userId, payload });
  };

  const updateCurrentStudyArmAPI = () => {
    /**
     * In order to verify an email we need to always send "contact_person_study_arm_id"
     * In case of regular new users enrollment verify email "contact_person_study_arm_id" => "account_person_study_arm_id" in account profile
     * In case of invited reporter verify an email "contact_person_study_arm_id" => "subject_secondary_person_study_arm_id" in account profile
     */
    const personStudyArmId = isReporterVerifyingEmail
      ? secondarySubjectPersonStudyArmId
      : accountPersonStudyArmId;

    const { 'study-arm-statuses': studyArmStatusesConfig } = configuration;

    // Find enrollment-acc-created status for the study arm
    let studyArmStatusId = Object.values(studyArmStatusesConfig).find(
      (status) =>
        status.title === STUDY_ARM_STATUS_NAMES['ENROLLMENT-VERIFIED'] &&
        status.study_arm_id ===
          activeAccountProfile?.attributes?.profile.study_arm_id
    )?.id;

    console.log(
      'configuration: ',
      configuration,
      'activeAccountProfile: ',
      activeAccountProfile,
      ', personStudyArmId: ',
      personStudyArmId
    );

    const payload = {
      data: {
        type: 'person-study-arms',
        id: `${personStudyArmId}`,
        attributes: {
          // study_arm_status_id: contactEnrollmentVerified?.id,
          study_arm_status_id: studyArmStatusId,
        },
      },
    };
    return updatePersonStudyArm({
      personStudyArmId: personStudyArmId,
      payload,
    });
  };

  /**
   * We want to mark email verification in DB
   */

  const verifyEnrollment = async () => {
    try {
      setVerifyingEnrollmentFlag(true);
      console.log('verifying enrollment...');
      const userResponse = await updateCurrentUserAPI();
      if (userResponse?.error) {
        console.log('error', userResponse?.error);
      } else {
        let updateCurrentStudyResponse = await updateCurrentStudyArmAPI();
        console.log('updateCurrentStudyResponse', updateCurrentStudyResponse);
        if (updateCurrentStudyResponse?.data) {
          const activeAccountProfilePayload = {
            authenticationStatus: true,
            activeAccountProfile: {
              ...activeAccountProfile,
              attributes: {
                ...activeAccountProfile.attributes,
                profile: {
                  ...activeAccountProfile.attributes.profile,
                  ...(isReporterVerifyingEmail
                    ? {
                        subject_secondary_study_arm_status_id:
                          contactEnrollmentVerified?.id,
                        subject_secondary_study_arm_status_title:
                          contactEnrollmentVerified?.title,
                      }
                    : {
                        study_arm_status_id: contactEnrollmentVerified?.id,
                        study_arm_status_title:
                          contactEnrollmentVerified?.title,
                      }),
                },
                user: {
                  ...activeAccountProfile.attributes.user,
                  authentication_status: true,
                },
              },
            },
          };
          dispatch(setValues(activeAccountProfilePayload));
          setVerifyingEnrollmentFlag(false);

          navigate(getRoute(activeAccountProfilePayload));
        }
      }
    } catch (error) {
      setVerifyingEnrollmentFlag(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    const success = JSON.parse(searchParams.get('success'));
    const emailVerified = JSON.parse(searchParams.get('email_verified'));
    const isExpired = searchParams.get('message')?.includes('expired');

    console.log('verifying enrollment', {
      emailVerified,
      success,
      accountPersonStudyArmId,
      isExpired,
      verifyingEnrollmentFlag,
      authenticationStatus,
      isLoadingAccountProfileDetails,
    });

    if (
      // emailVerified &&
      // success &&
      // !isExpired &&
      user.email_verified &&
      accountPersonStudyArmId &&
      !verifyingEnrollmentFlag &&
      !authenticationStatus &&
      !isLoadingAccountProfileDetails
    ) {
      verifyEnrollment();
    } else if (emailVerified && !success && isExpired) {
      navigate('/verification-failed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchParams,
    accountPersonStudyArmId,
    navigate,
    isLoadingAccountProfileDetails,
  ]);

  return {
    verifyingEnrollmentFlag,
  };
}
