import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const idFormatter = (id) => String(id).padStart(3, '0');

export const notificationInitialState = {
  isActive: false,
  type: [],
  title: '',
  scheduleNr: 1,
  scheduleTimeUnit: '',
  reminderType: '',
};

export const triggerInitialState = [
  {
    triggerType: '',
    startOnNr: 1,
    startOnUnit: '',
    startOnType: '',
  },
];

const taskInitialState = {
  notifications: [],
  triggers: triggerInitialState,
};

const initialState = {
  tasks: [
    {
      id: '001',
      title: 'Take a brain health survey',
      taskType: 'survey',
      studyArm: '',
      taskGroup: '001',
      requiredBehavior: 'could',
      triggers: [
        {
          triggerType: 'Periodic',
          startOnNr: 1,
          startOnUnit: 'days',
          startOnType: 'After onboarding',
          count: 2,
          period: 1,
          periodUnit: 'week(s)',
        },
      ],
      notifications: [
        {
          id: '001',
          isActive: false,
          title: 'Nascetur duis',
          scheduleNr: 1,
          scheduleTimeUnit: 'Days',
          reminderType: 'Before Task start date',
          type: [
            {
              name: 'email',
              template: 'Study overview',
            },
          ],
        },
        {
          id: '002',
          isActive: true,
          title: 'Nascetur duis dignissim',
          scheduleNr: 1,
          scheduleTimeUnit: '',
          reminderType: '',
          type: [
            {
              name: 'sms message',
              template: 'Reminder to enroll',
            },
            {
              name: 'notification',
              template: 'Reminder',
            },
          ],
        },
        {
          id: '003',
          isActive: true,
          title: 'Nascetur duis dignissim maecenas',
          scheduleNr: 2,
          scheduleTimeUnit: '',
          reminderType: '',
          type: [
            {
              name: 'email',
              template: 'Study overview',
            },
            {
              name: 'SMS Message',
              template: 'Reminder to enroll',
            },
            {
              name: 'notification',
              template: 'Reminder',
            },
          ],
        },
      ],
    },
    {
      id: '002',
      title: 'Complete your Medical History',
      taskType: 'dataSharing',
      studyArm: '',
      taskGroup: '001',
      requiredBehavior: 'could',
    },
    {
      id: '003',
      title: 'Connect your device',
      taskType: 'dataSharing',
      studyArm: '',
      taskGroup: '002',
      requiredBehavior: 'could',
    },
    {
      id: '004',
      title: 'Watch a short educational video ab...',
      taskType: 'teleResearch',
      studyArm: '',
      taskGroup: '002',
      requiredBehavior: 'could',
    },
    {
      id: '005',
      title: 'Schedule a follow-up call',
      taskType: 'teleResearch',
      studyArm: '',
      taskGroup: '003',
      requiredBehavior: 'could',
    },
    {
      id: '006',
      title: 'Complete your follow-up call',
      taskType: 'teleResearch',
      studyArm: '',
      taskGroup: '003',
      requiredBehavior: 'could',
    },
  ],
  taskGroups: [
    {
      id: '001',
      name: 'Health surveys',
      points: 100,
      completionDays: 4,
    },
    {
      id: '002',
      name: 'Medical history',
      points: 400,
      completionDays: 5,
    },
    {
      id: '003',
      name: 'Devices',
      points: 50,
      completionDays: 3,
    },
  ],
  studyArms: [],
  task: taskInitialState,
  videoTasks: [],
  taskGroup: {},
  participantTasks: [],
  selectedParticipantTaskId: null,
  selectedParticipantTaskAttributes: {},
};

const addTaskReducer = (state, { payload }) => {
  const data = payload && payload.data ? payload.data : {};
  const id = idFormatter(state.tasks.length + 1);
  state.tasks.push({
    ...initialState.task,
    id,
    ...data,
  });
  state.task.id = id;
};

const addVideoTaskReducer = (state, action) => {
  state.videoTasks.push(action.payload);
};

const updateTaskReducer = (state, action) => {
  const { id, ...rest } = action.payload.data;
  const targetIndex = state.tasks.findIndex((task) => task.id === id);
  if (targetIndex > -1) {
    state.tasks[targetIndex] = {
      ...state.tasks[targetIndex],
      ...rest,
    };
  }
};

const deleteTaskReducer = (state, action) => {
  state.tasks = state.tasks.filter((task) => task.id !== action.payload.id);
};

const addTaskGroupReducer = (state, action) => {
  const id = idFormatter(state.taskGroups.length + 1);
  state.taskGroups.push({ id, ...action.payload });
};

const setParticipantTasksReducer = (state, action) => {
  state.participantTasks = [...action.payload];
};

const setParticipantTaskReducer = (state, action) => {
  state.participantTask = action.payload;
};

const updateTaskGroupReducer = (state, action) => {
  const { id, ...rest } = action.payload;
  const targetIndex = state.taskGroups.findIndex(
    (taskGroup) => taskGroup.id === id
  );
  if (targetIndex > -1) {
    state.taskGroups[targetIndex] = {
      ...state.taskGroups[targetIndex],
      ...rest,
    };
  }
};
const deleteTaskGroupReducer = (state, action) => {
  state.taskGroups = state.taskGroups.filter(
    (taskGroup) => taskGroup.id !== action.payload.id
  );
};

const toggleNotificationReducer = (state, action) => {
  const { id, taskId } = action.payload;
  const targetTask = state.tasks.find((task) => task.id === taskId);
  if (!targetTask) return;
  const targetNotification = targetTask.notifications.find(
    (notification) => notification.id === id
  );
  if (!targetNotification) return;
  targetNotification.isActive = !targetNotification.isActive;
};

const deleteNotificationReducer = (state, action) => {
  const { id, taskId } = action.payload;
  const targetTask = state.tasks.find((task) => task.id === taskId);
  if (!targetTask) return;
  const notificationIndex = targetTask.notifications.findIndex(
    (notification) => notification.id === id
  );
  targetTask.notifications.splice(notificationIndex, 1);
};

const addNotificationReducer = (state, action) => {
  const { taskId, data } = action.payload;
  const targetTask = state.tasks.find((task) => task.id === taskId);
  let notificationCount = 0;
  if (state.task.notifications) {
    notificationCount = state.task.notifications.length;
  }
  if (!targetTask) {
    const id = idFormatter(notificationCount + 1);
    if (!state.task.notifications) {
      state.task.notifications = [];
    }
    state.task.notifications.push({ id, ...data });
  } else {
    const id = idFormatter(notificationCount + 1);
    if (!targetTask.notifications) {
      targetTask.notifications = [];
    }
    targetTask.notifications.push({ id, ...data });
  }
};

const editNotificationReducer = (state, action) => {
  const { taskId, data } = action.payload;
  const { id, ...rest } = data;
  const targetTask = state.tasks.find((task) => task.id === taskId);
  if (!targetTask) return;

  const targetNotificationIndex = targetTask.notifications.findIndex(
    (notification) => notification.id === id
  );
  if (targetNotificationIndex < 0) return;

  targetTask.notifications[targetNotificationIndex] = {
    ...targetTask.notifications[targetNotificationIndex],
    ...rest,
  };
};

const updateTriggersReducer = (state, action) => {
  console.log(action.payload);
  const { taskId, data } = action.payload;
  const targetTask = state.tasks.find((task) => task.id === taskId);
  if (!targetTask) {
    state.task.triggers = data;
  } else {
    targetTask.triggers = data;
  }
};

const selectTaskGroupReducer = (state, action) => {
  const { taskGroupId } = action.payload;
  const taskGroup = state.taskGroups.find((item) => item.id === taskGroupId);
  return {
    ...state,
    taskGroup: taskGroup !== undefined ? taskGroup : {},
  };
};

const setSelectedParticipantTaskIdReducer = (state, action) => {
  const { taskId } = action.payload;
  return {
    ...state,
    selectedParticipantTaskId: taskId,
  };
};

const setSelectedParticipantTaskAttributesReducer = (state, action) => {
  const { attributes } = action.payload;
  return {
    ...state,
    selectedParticipantTaskAttributes: attributes,
  };
};

const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTask: addTaskReducer,
    updateTask: updateTaskReducer,
    deleteTask: deleteTaskReducer,
    setParticipantTasks: setParticipantTasksReducer,
    setParticipantTask: setParticipantTaskReducer,
    addTaskGroup: addTaskGroupReducer,
    updateTaskGroup: updateTaskGroupReducer,
    deleteTaskGroup: deleteTaskGroupReducer,
    toggleNotification: toggleNotificationReducer,
    deleteNotification: deleteNotificationReducer,
    addNotification: addNotificationReducer,
    editNotification: editNotificationReducer,
    updateTriggers: updateTriggersReducer,
    addVideoTask: addVideoTaskReducer,
    selectTaskGroup: selectTaskGroupReducer,
    setSelectedParticipantTaskId: setSelectedParticipantTaskIdReducer,
    setSelectedParticipantTaskAttributes:
      setSelectedParticipantTaskAttributesReducer,
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  addTask,
  updateTask,
  deleteTask,
  setParticipantTasks,
  setParticipantTask,
  setTaskType,
  setTaskId,
  clearTask,
  deleteTaskGroup,
  addTaskGroup,
  updateTaskGroup,
  toggleNotification,
  deleteNotification,
  addNotification,
  editNotification,
  addVideoTask,
  selectTaskGroup,
  updateTriggers,
  setSelectedParticipantTaskId,
  setSelectedParticipantTaskAttributes,
} = taskSlice.actions;

export const selectTasks = () => (state) => state.task.tasks;
export const selectTask = (taskId) => (state) =>
  state.task.tasks.find(({ id }) => id === taskId) || {};
export const participantTask = () => (state) => state.task.participantTask;

export const selectParticipantTask = (taskId) => (state) => {
  let found = state.task.participantTasks.filter((pt) => {
    return pt.participantTaskId === taskId;
  });
  if (found.length === 1) {
    return found[0];
  }
  return null;
};

export const selectParticipantTaskV3 = (taskId) => (state) => {
let found = state.task.participantTasks.filter((pt) => {
    return pt?.data?.id === taskId;
});
if (found.length === 1) {
    return found[0];
}
return null;
};

export const selectLastTaskId = () => (state) => {
  const tasks = state.task.tasks;
  return tasks.length > 0 ? tasks[tasks.length - 1].id : null;
};

export const selectNotification = (taskId, notificationId) => (state) => {
  if (!taskId || !notificationId) return;
  const targetTask = state.task.tasks.find((task) => task.id === taskId);
  if (!targetTask) return;
  const targetNotification = targetTask.notifications.find(
    (notification) => notification.id === notificationId
  );
  return targetNotification || {};
};

export const selectTaskGroups = () => (state) => state.task.taskGroups;
export const selectStudyArms = () => (state) => state.task.studyArms;
export const selectSelectedParticipanttaskId = () => (state) =>
  state.task.selectedParticipantTaskId;
export const selectSelectedParticipanttaskAttributes = () => (state) =>
  state.task.selectedParticipantTaskAttributes;

export default taskSlice.reducer;
