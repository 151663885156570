import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

function RequireAuth({ children }) {
  const { isAuthenticated } = useAuth0();

  const { person } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated,
    selectFromResult: ({ data, ...rest }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
      ...rest,
    }),
  });

  if (!isAuthenticated) {
    return <Navigate to='/welcome' />;
  }

  return children;
}

export default RequireAuth;
