import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { logout as localLogout } from 'ihp-bloom-redux/features/authentication/authenticationSlice';
import { updateLastOpenedConsent } from 'ihp-bloom-redux/features/consent/consentSlice';
import { useSubmitWithdrawalMutation } from 'ihp-bloom-redux/features/study/studyApi';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSlice';
import {
  participantTaskApiSlice,
  useGetProfileParticipantTasksQuery,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';
import {
  selectSelectedParticipanttaskId,
  setSelectedParticipantTaskId,
} from 'ihp-bloom-redux/features/tasks/tasksSlice';

import Button from 'ihp-components/components/v2/Button';

import { withPerson } from 'authentication/withPerson';
import { withSelectedProfile } from 'hocs/withSelectedProfile';
import { Loader } from 'components/Loader';
import { ConsentItem } from 'components/Onboarding/ConsentItem';
import { EmptyConsents } from 'components/Profile';
import { Title } from 'pages/Onboarding/components/Text';
import RejectDocusignModal from 'pages/Profile/MyStudy/rejectDocusignModal';
import { useRejectConsent } from 'utils/consents';
import {
  filterParticipantTasks,
  formatParticipantTasks,
  mapTaskRequiredStatus,
} from 'utils/tasks';

import { useGetAccountProfileQuery } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';
import { ReactComponent as Logo } from 'images/svs-logo.svg';
import getRoute from 'utils/navigator';
import OnboardingLayout from '../components/Layout';
import { Content, Row } from './styles';

function ParticipantConsents({ person }) {
  document.title = 'Single Ventricle SOURCE My Consents';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, logout } = useAuth0();
  const [searchParams] = useSearchParams();
  const [rejectConsent, isRejecting] = useRejectConsent();

  const user = useSelector((state) => state.user);
  const { isConsented } = useSelector((state) => state.authentication);
  const consentData = useSelector((state) => state.consentSlice);
  const { activeAccountProfile, name } = useSelector((state) => state.user);
  const selectedTaskId = useSelector(selectSelectedParticipanttaskId());

  const [consent, setConsent] = useState(null);
  const [isRedirecting, setRedirecting] = useState(false);

  let isRejectedFromDocusign =
    searchParams.get('docusignStatus') === 'rejected';
  const isRejectedConsentIsRequired =
    mapTaskRequiredStatus(consentData.lastOpenedConsent?.required) ===
    'REQUIRED';

  const [showModal, setShowModal] = useState(
    isRejectedFromDocusign && isRejectedConsentIsRequired
  );
  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  // API CALLS - Queries
  const [submitWithdrawal, { isLoading: processingWithdrawl }] =
    useSubmitWithdrawalMutation();
  const [updateParticipantTaskMutation] = useUpdateParticipantTaskMutation();
  const {
    data: response,
    isLoading: areConsentsLoading,
    error,
  } = useGetProfileParticipantTasksQuery(
    {
      accountProfileId: accountProfileId,
      params: {
        task_type: 'consent',
      },
    },
    {
      skip: accountProfileId === undefined,
    }
  );
  const [trigger, result] =
    participantTaskApiSlice.endpoints.getProfileParticipantTask.useLazyQuery();

  const activeAccountProfileId = Number(user.activeAccountProfile?.id);

  const { data: accountProfile, isFetching: isLoadingAccountProfile } =
    useGetAccountProfileQuery(activeAccountProfileId, {
      skip: user.consentContext === 'profile' || !user.activeAccountProfile?.id,
      forceRefetch: true,
    });

  let consents = [];

  const isLar =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name === 'lar';

  const fullName = `${activeAccountProfile?.attributes?.profile?.first_name} ${activeAccountProfile?.attributes?.profile?.last_name}`;
  const isFetchingConsentDetails = result.isFetching;

  const isLoading =
    (!isAuthenticated && isConsented) ||
    areConsentsLoading ||
    isRedirecting ||
    isFetchingConsentDetails ||
    isRejecting ||
    isLoadingAccountProfile;

  const isLastOpenedConsentMandatory =
    consentData?.lastOpenedConsent?.required === 'must';

  if (isRejectedFromDocusign && selectedTaskId != null) {
    const requestBody = {
      participantTaskId: selectedTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(selectedTaskId),
          attributes: {
            status: 'rejected',
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody).catch(console.error);
    dispatch(setSelectedParticipantTaskId({ taskId: null }));
  }

  // useEffect to open DocuSign
  useEffect(() => {
    if (result?.data) {
      setRedirecting(true);
      const consentURL =
        result?.data?.data?.attributes['participant-task']?.envelope_url;
      window.location.href = consentURL;
    }
  }, [result]);

  const setConsentHandler = (consent) => {
    setConsent(consent);
    dispatch(updateLastOpenedConsent(consent));
    trigger({
      accountProfileId: accountProfileId,
      participantTaskId: consent?.id,
    });
  };

  const handleBackToHome = async () => {
    logout({
      returnTo: `${window.location.origin}/onboarding/welcome-to-study`,
    });
    dispatch(localLogout());
    sessionStorage.clear();
  };

  const handleDocusignReject = async (value) => {
    if (processingWithdrawl) {
      return;
    }

    if (isLastOpenedConsentMandatory) {
      const requestBody = {
        personId: person?.id,
        payload: {
          study_id: 1,
          reason: value,
        },
      };
      const response = await submitWithdrawal(requestBody);
      if (response.errors) return;
      navigate('/rejected');
    } else {
      setShowModal(false);
      navigate('/onboarding/participant-consents');
    }
  };

  if (response?.data?.length) {
    if (consents.length === 0) {
      consents = filterParticipantTasks(response.data);
      consents = formatParticipantTasks(consents);
      consents = consents.map((item) => ({
        ...item,
        statusDate: item.consentStatusDate ?? item.statusDate,
      }));
    }
  }

  const sortedConsents = (consents) => {
    const sortOrder = [
        "consent form",
        "assent form",
        "medical release form",
    ];
    return _.sortBy(consents, (consent) => {
        return sortOrder.findIndex(t => consent?.title?.toLowerCase().includes(t));
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  const goBack = () => {
    navigate(-1);
  };

  const handleBackToConsent = () => {
    setShowModal(false);
    navigate('/onboarding/participant-consents');
  };

  const handleSkipOptionalConsents = () => {
    navigate(
      getRoute({
        ...user,
        activeAccountProfile: accountProfile.data,
      })
    );
  };

  const hasPendingRequiredConsents = consents.some(
    (c) => c.required === 'must' && c.status !== 'completed'
  );

  const hasPendingOptionalConsents = consents.some(
    (c) => c.required === 'should' && c.status !== 'completed'
  );

  const getEnrollmentText = () => {
    const activeProfileStudyArmRoleName = activeAccountProfile?.attributes?.profile?.study_arm_role_name?.toLowerCase();
    
    if (activeProfileStudyArmRoleName?.includes('parent')) {
      return 'enroll your child in';
    } else if (activeProfileStudyArmRoleName?.includes('lar')) { 
      return 'enroll your dependent in';
    } else {
      return 'enroll yourself in';
    }
  };

  return (
    <OnboardingLayout maxWidth='700px' backHandler={goBack}>
      <RejectDocusignModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        backToConsent={handleBackToConsent}
        isSubmitting={processingWithdrawl}
        onSubmit={(value) => handleDocusignReject(value)}
      />
      <Row marginBottom='30px'>
        <Logo />
      </Row>
      <Title marginBottom='15px' marginTop='0px'>
        Please review and sign the consent form(s) below to begin to {getEnrollmentText()} SOURCE
      </Title>
      <Content>
        {(!consents?.length || error) && <EmptyConsents />}
        {sortedConsents(consents).map?.((consent) => (
          <ConsentItem
            key={`${consent?.id}${consent?.consentVersion}`}
            consent={consent}
            setConsent={setConsentHandler}
            onRejectConsent={rejectConsent}
            isLar={isLar}
            name={fullName}
            rendererPage='onboarding-consents'
          />
        ))}
        {!hasPendingRequiredConsents && hasPendingOptionalConsents && (
          <Button
            onClick={handleSkipOptionalConsents}
            sx={{ marginLeft: 'auto' }}
            variant='link'
          >
            Skip
          </Button>
        )}
      </Content>
      {!isLoading && consents?.length === 0 && (
        <Row>
          <Button size='lg' variant='link' onClick={handleBackToHome}>
            Back to home
          </Button>
        </Row>
      )}
    </OnboardingLayout>
  );
}

export default withPerson(withSelectedProfile(ParticipantConsents));
