import { useEffect } from 'react';
import { useForm, useWatch, FormProvider } from 'react-hook-form';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { FormCheckbox } from 'components/Checkbox';
import { ModalDatePicker } from 'components/DatePicker';
import { ModalTimePicker } from 'components/TimePicker';
import { Textarea } from 'configUI/components';
import { OutlinedButton } from 'configUI/components/Buttons';
import { ControllerForm, Divider } from 'configUI/components/Shared';
import { Reminders } from './Reminders';
import { cardToOrdMap } from './utils';
import { Input } from 'ihp-components/components/v2/Input';

const DateField = ({ name, label, disabled = false, minDate, maxDate }) => (
  <ControllerForm name={name} required={!disabled}>
    <ModalDatePicker
      minDate={minDate}
      maxDate={maxDate}
      disabled={disabled}
      renderInput={({ inputProps, inputRef }) => {
        return (
          <Input
            ref={inputRef}
            label={label}
            placeholder='Enter date'
            fullWidth={true}
            width='100%'
            {...inputProps}
          />
        );
      }}
    />
  </ControllerForm>
);

const TimeField = ({ name, label, disabled = false }) => (
  <ControllerForm name={name} required={!disabled}>
    <ModalTimePicker
      disabled={disabled}
      renderInput={({ inputProps, inputRef }) => {
        return (
          <Input
            ref={inputRef}
            label={label}
            placeholder='Enter time'
            fullWidth={true}
            {...inputProps}
          />
        );
      }}
    />
  </ControllerForm>
);

const defaultValues = {
  time: '',
  pills: 0,
  dosage: 0,
  startDate: new Date(),
  endDate: new Date(),
  never: false,
  note: '',
};

export const AddReminderForm = ({
  reminders,
  onAddReminder,
  onRemoveReminder,
  dosageUnit,
}) => {
  const methods = useForm({ defaultValues });
  const { handleSubmit, reset, control, setValue } = methods;
  const never = useWatch({ name: 'never', control });
  const startDate = useWatch({ name: 'startDate', control });
  const endDate = useWatch({ name: 'endDate', control });

  useEffect(() => {
    if (!never && startDate > endDate) {
      setValue('endDate', startDate);
    }
  }, [endDate, never, setValue, startDate]);

  const handleAddReminder = (reminder) => {
    onAddReminder(reminder);
    reset(defaultValues);
  };

  const handleEditReminder = (index) => {
    const reminder = reminders[index];
    reset(reminder);
    onRemoveReminder(index);
  };

  const nextIndex = reminders.length;

  return (
    <FormProvider {...methods}>
      <Stack divider={<Divider />}>
        {nextIndex > 0 && (
          <Reminders
            onClick={handleEditReminder}
            reminders={reminders}
            dosageUnit={dosageUnit}
          />
        )}
        <Grid
          container
          p='20px'
          columnSpacing='15px'
          rowSpacing='20px'
          component='form'
          onSubmit={handleSubmit(handleAddReminder)}
          alignItems='flex-start'
        >
          <Grid item xs={12}>
            <TimeField
              name='time'
              label={`${cardToOrdMap[nextIndex]} reminder`}
            />
          </Grid>
          <Grid item xs={4}>
            <ControllerForm name='pills' required={true}>
              <Input
                type='number'
                label='Unit(s)'
                fullWidth
                endIonc='unit(s)'
              />
            </ControllerForm>
          </Grid>
          <Grid item xs={8}>
            <ControllerForm name='dosage' required={true}>
              <Input
                type='number'
                label='Dosage'
                fullWidth
                endIcon={<Typography noWrap>{dosageUnit}</Typography>}
              />
            </ControllerForm>
          </Grid>
          <Grid item xs={12}>
            <DateField
              name='startDate'
              label='Start date'
              maxDate={never ? null : endDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction='row' spacing='15px' alignItems='flex-end'>
              <Box flex={1}>
                <DateField
                  name='endDate'
                  label='End date'
                  disabled={never}
                  minDate={startDate}
                />
              </Box>
              <ControllerForm name='never'>
                <FormCheckbox label='Never' />
              </ControllerForm>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ControllerForm name='note'>
              <Textarea maxRows={2} label='Note' placeholder='Enter note' />
            </ControllerForm>
          </Grid>
          <Grid item xs={12}>
            <OutlinedButton type='submit' size='medium' fullWidth>
              Add reminder
            </OutlinedButton>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
};
