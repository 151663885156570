import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Stack } from '@mui/material';

import { BaseHeader } from 'components/BaseLayout';
import { DropDownCalendar } from 'pages/NotificationCenter/MedicationTracker/DropDownCalendar';
import {
  AddButton,
  Content,
  Header,
  StyledBaseLayout,
} from 'pages/NotificationCenter/MedicationTracker/styles';
import { ReminderCard } from './ReminderCard';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import {
  getMedicationTrackerPath,
  getMedicationTrackerEditPath,
} from 'pages/NotificationCenter/paths';
import { useDetailedData } from './hooks';

const MedicationTrackerDetailedDesktop = ({ date, onDateChange }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [medicationName, reminderIds] = useDetailedData(id, date);

  return (
    <StyledBaseLayout
      header={
        <Header>
          <BaseHeader
            forceShowClickBack
            onClickBack={() =>
              navigate(
                getMedicationTrackerPath({
                  date: format(date, 'yyyy-MM-dd'),
                })
              )
            }
          >
            {medicationName}
          </BaseHeader>
          <Stack direction='row' spacing='15px'>
            <DropDownCalendar date={date} onDateChange={onDateChange} />
            <AddButton
              component={Link}
              to={getMedicationTrackerEditPath(id)}
              startIcon={<PlusIcon />}
            >
              Add
            </AddButton>
          </Stack>
        </Header>
      }
      content={
        <Content>
          {reminderIds.map((id) => (
            <ReminderCard key={id} id={id} date={date} />
          ))}
        </Content>
      }
    />
  );
};

export default MedicationTrackerDetailedDesktop;
