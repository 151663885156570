import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contacts: [
    {
      id: '001',
      name: 'Kakashi Hatake',
      role: 'Admin',
      info: [
        {
          id: '001',
          type: 'email', // phone |email| other
          value: 'hatake.kakashi@konoha.com',
          use: 'work', // work |home
          isPrimary: true,
        },
        {
          id: '002',
          type: 'phone',
          value: '+234-704-154-6361',
          use: 'home', // work |home
          isPrimary: false,
        },
      ],
    },
    {
      id: '002',
      name: 'Lionel Messi',
      role: 'GOAT',
      info: [
        {
          id: '001',
          type: 'email', // phone |email| other
          value: 'lionel.messi@paris.com',
          use: 'work', // work |home
          isPrimary: false,
        },
        {
          id: '002',
          type: 'phone',
          value: '+234-704-154-6361',
          use: 'home', // work |home
          isPrimary: true,
        },
      ],
    },
  ],
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    addContact: (state, { payload }) => {
      if (state.contacts.some((contact) => contact.id === payload.id)) {
        return false;
      }
      const contactsLength = state.contacts.length;
      const newId = `${contactsLength + 1}`.padStart(3, '0');
      state.contacts.push({ id: newId, ...payload });
    },
    updateContact: (state, { payload }) => {
      state.contacts = state.contacts.map((contact) =>
        payload.id === contact.id ? { ...contact, ...payload } : contact
      );
    },
    deleteContact: (state, { payload }) => {
      state.contacts = state.contacts.filter(
        (contact) => contact.id !== payload.id
      );
    },
  },
});

export const { addContact, deleteContact, updateContact } =
  contactSlice.actions;

export default contactSlice;
