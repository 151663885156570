import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const participantTaskApiSlice = createApi({
  reducerPath: 'participantTaskApi',
  baseQuery: baseQuery('v1'),
  tagTypes: ['ParticipantTasks', 'ParticipantTaskDetails'],
  endpoints: (build) => ({
    getAllParticipantTasks: build.query({
      query: (participantId) => ({
        url: `/persons/${participantId}/participant-tasks?status=incomplete&assignee=participant`,
        method: 'GET',
      }),
      providesTags: ['ParticipantTasks'],
    }),
    getParticipantTask: build.query({
      query: ({ participantId, participantTaskId }) => ({
        url: `/persons/${participantId}/participant-tasks/${participantTaskId}`,
        method: 'GET',
      }),
      providesTags: ['ParticipantTaskDetails'],
    }),
    submitParticipantTask: build.mutation({
      query: ({ personId, participantTaskId, payload }) => ({
        url: `/persons/${personId}/participant-tasks/${participantTaskId}`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['ParticipantTasks', 'ParticipantTaskDetails'],
    }),
    updateParticipantTask: build.mutation({
      query: ({ personId, participantTaskId, payload }) => ({
        url: `/persons/${personId}/participant-tasks/${participantTaskId}`,
        method: 'PATCH',
        body: payload,
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['ParticipantTasks', 'ParticipantTaskDetails'],
    }),
  }),
});

export const {
  useGetAllParticipantTasksQuery,
  useGetParticipantTaskQuery,
  useSubmitParticipantTaskMutation,
  useUpdateParticipantTaskMutation,
} = participantTaskApiSlice;
