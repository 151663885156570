import React from 'react';
import Button from 'ihp-components/components/v2/Button';
import {
  VerifyEmailWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
} from './styles';
import { ReactComponent as Completed } from 'images/av-logo-vertical.svg';
import { isMobile } from 'utils/isMobile';
import { useLogout } from 'utils/useLogout';
import WithdrawnFromStudyMobile from './index.mobile';

function WithdrawnFromStudy() {
  document.title = 'Single Ventricle SOURCE withdrawn from study';
  const logout = useLogout('login?context=signin');

  if (isMobile()) {
    return <WithdrawnFromStudyMobile onContinue={logout} />;
  }

  return (
    <VerifyEmailWrapper>
      <Container>
        <Row>
          <Completed width={200} height={198} />
        </Row>
        <Row>
          <PageTitle variant='h2'>
            You have successfully withdrawn your participation from the SV Source
            Study.
          </PageTitle>
        </Row>
        <Row>
          <HelperText variant='pl3'>
            You can re-enroll in the study at any time and start earning
            rewards.
          </HelperText>
        </Row>
        <Row>
          <Button onClick={logout} fullWidth>
            Re-enroll
          </Button>
        </Row>
      </Container>
    </VerifyEmailWrapper>
  );
}

export default WithdrawnFromStudy;
