import { useRef, useState } from 'react';
import { addDays, format } from 'date-fns';
import { useMediaQuery, useTheme, Typography } from '@mui/material';

import { Divider } from 'configUI/components/Shared';
import { StaticDatePicker } from 'components/DatePicker';
import {
  Container,
  ControlContainer,
  StyledIconButton,
  StyledPopover
} from './styles';

import { ReactComponent as ChevronLeft } from 'images/shared/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'images/shared/chevron-right.svg';
import { ReactComponent as Close } from 'images/shared/times.svg';

export const Control = ({ open, onPrevious, onNext, onClose, ...rest }) =>
  open
    ? (
      <ControlContainer {...rest}>
        <StyledIconButton onClick={onClose}>
          <Close width='14px' height='13px' />
        </StyledIconButton>
      </ControlContainer>
    ) : (
      <ControlContainer {...rest}>
        <StyledIconButton onClick={onPrevious}>
          <ChevronLeft width='8px' height='14px' />
        </StyledIconButton>
        <StyledIconButton onClick={onNext}>
          <ChevronRight width='8px' height='14px' />
        </StyledIconButton>
      </ControlContainer >
    );

export const DropDownCalendar = ({
  onDateChange,
  date,
  dateFormat = 'cccc, MM.dd.yyyy',
}) => {
  const anchorEl = useRef(null);
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = date => {
    handleClose();
    onDateChange(date);
  };

  const handleNext = () => handleChange(addDays(date, 1));
  const handlePrevious = () => handleChange(addDays(date, -1));

  return (
    <>
      <Container ref={upSm ? anchorEl : null}>
        <Typography onClick={handleClick} variant='pb3' color='primary.gray75'>
          {format(date, dateFormat)}
        </Typography>
        <Control
          open={open}
          onPrevious={handlePrevious}
          onNext={handleNext}
          onClose={handleClose}
        />
      </Container>
      {!upSm && <Divider ref={anchorEl} />}
      <StyledPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
      >
        {upSm ?
          <StaticDatePicker
            value={date}
            onChange={handleChange}
            padding='0 50px 0 0'
            width='290px'
            iconProps={{ width: '30px', height: '30px' }}
          />
          :
          <>
            <StaticDatePicker value={date} onChange={handleChange} />
            <Divider />
          </>
        }
      </StyledPopover>
    </>
  );
};
