import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { format, parse } from 'date-fns';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import {
  addMedication,
  updateMedication,
  addReminders,
  updateReminders,
  removeReminders,
  selectMedicationById,
  selectReminderIds,
  selectRemindersByIds,
} from 'ihp-bloom-redux/features/medicationTracker/medicationTrackerSlice';

const timePattern = 'kk:mm';
const datePattern = 'yyyy/MM/dd';

const timeCompare = ({ time: lhsTime }, { time: rhsTime }) =>
  lhsTime < rhsTime ? -1 : lhsTime > rhsTime ? 1 : 0;

const getDefaultMedication = ({ name, dosage }) => ({
  drug: {
    name: name || '',
    dosageUnit: dosage || '',
  },
  repeat: {
    type: 'weekly'
  },
});

const fromReduxToFormReminder = ({
  time,
  startDate,
  endDate,
  ...rest
}) => ({
  time: parse(time, timePattern, new Date()),
  startDate: parse(startDate, datePattern, new Date()),
  endDate: (endDate ? parse(endDate, datePattern, new Date()) : 'Never'),
  never: !Boolean(endDate),
  ...rest
});

const fromFormToReduxReminder = ({
  time,
  startDate,
  endDate,
  never,
  ...rest
}) => ({
  time: format(time, timePattern),
  startDate: format(startDate, datePattern),
  endDate: never ? null : format(endDate, datePattern),
  ...rest
});

const useCreateMedication = (callback) => {
  const dispatch = useDispatch();

  return (medication, reminders) => {
    const transformedReminders = reminders.map(fromFormToReduxReminder);

    dispatch(addMedication({
      medication,
      reminders: transformedReminders,
    }));
    callback();
  };
};

const useUpdateMedication = (previousReminders, callback) => {
  const dispatch = useDispatch();

  return (medication, reminders) => {
    const transformedReminders = reminders.map(fromFormToReduxReminder);

    const remindersToAdd = transformedReminders.filter(({ id }) => !Boolean(id));
    const remindersToUpdate = transformedReminders.filter(({ id }) => Boolean(id));
    const ids = remindersToUpdate.map(({ id }) => id);
    const remindersIdsToRemove = previousReminders.filter(({ id }) => !ids.includes(id)).map(({ id }) => id);

    dispatch(updateMedication(medication));
    dispatch(addReminders({
      medicationId: medication.id,
      reminders: remindersToAdd,
    }));
    dispatch(updateReminders(remindersToUpdate));
    dispatch(removeReminders(remindersIdsToRemove));
    callback();
  };
};

const useReminderState = (defaultReminders) => {
  const [reminders, setReminders] = useState(defaultReminders);
  const onAddReminder = reminder =>
    setReminders([...reminders, reminder].sort(timeCompare));
  const onRemoveReminder = index =>
    setReminders(reminders.filter((_, i) => (i !== index)));

  return [reminders, onAddReminder, onRemoveReminder];
};

export const useCreateMedicationTracker = ({ id, name, dosage, onCreate, onUpdate }) => {
  const medication = useSelector(selectMedicationById(id));
  const reminderIds = useSelector(selectReminderIds({ medicationId: id }));
  const oldReminders = useSelector(selectRemindersByIds(reminderIds));

  const createMedication = useCreateMedication(onCreate);
  const updateMedication = useUpdateMedication(oldReminders, onUpdate);

  const edit = Boolean(id);

  const defaultMedication = edit ? medication : getDefaultMedication({ name, dosage });
  const defaultReminders = edit ? oldReminders.map(fromReduxToFormReminder) : [];

  const [reminders, onAddReminder, onRemoveReminder] = useReminderState(defaultReminders);

  const handleSave = edit ? updateMedication : createMedication;

  const formProviderProps = useForm({ defaultValues: defaultMedication });
  const { handleSubmit, control } = formProviderProps;
  const dosageUnit = useWatch({ name: 'drug.dosageUnit', control });

  const onSave = handleSubmit(medication => handleSave(medication, reminders));

  const reminderFormProps = {
    reminders,
    onAddReminder,
    onRemoveReminder,
    dosageUnit,
  };

  return [formProviderProps, reminderFormProps, onSave];
};

