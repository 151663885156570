import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { BaseHeader } from 'components/BaseLayout';
import Button from 'ihp-components/components/v2/Button';
import { FormLabel } from 'components/Login';
import { ErrorWrapper } from 'configUI/components';
import { ReactComponent as AvatarIcon } from 'images/personalinformation/greyAvatar.svg';
import { ReactComponent as CalendarIcon } from 'images/personalinformation/greyCalendar.svg';
import { ReactComponent as LocationIcon } from 'images/personalinformation/greyLocation.svg';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import React from 'react';
import {
  setValues,
  updateHyperwalletToken,
  updateHyperwalletProgramToken,
} from 'ihp-bloom-redux/features/user/userSlice';
import { createUser, getEncodedCredentials } from 'services/hyperwalletService';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Line } from '../../Payment/styles';
import { ButtonsContainer } from './index.styles';
import getStyles from './index.styles';
import { isMobile } from 'utils/isMobile';
import { Input } from 'ihp-components/components/v2/Input';

const HyperWalletAccount = () => {
  document.title = 'Single Ventricle SOURCE My Rewards';
  const theme = useTheme();
  const styles = getStyles(theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, hyperwallet } = useSelector((state) => state);

  const onSubmit = async (data) => {
    dispatch(setValues(data));

    const credentials = getEncodedCredentials(
      hyperwallet.username,
      hyperwallet.password
    );
    const userResponse = await createUser({
      endpoint: hyperwallet.url,
      programToken: hyperwallet.token,
      credentials,
      user: data,
    });

    const response = JSON.parse(userResponse);
    const token = response?.token;
    const programToken = response?.programToken;
    if (token) {
      dispatch(updateHyperwalletToken(token));
    }
    if (programToken) {
      dispatch(updateHyperwalletProgramToken(programToken));
    }
    let errorMessage;

    if (response?.errors && response?.errors[0]) {
      errorMessage = response?.errors[0].message;
      setError('mainError', { type: 'custom', message: errorMessage });
    } else {
      clearErrors();
      errorMessage = null;
    }

    if (!errorMessage) {
      navigate('/profile/payment/hyperwallet');
    }
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: user,
  });

  const title = (
    <Box sx={styles.formContainer} component='div'>
      <FormLabel>Enter your information</FormLabel>
      <Box sx={styles.subtitle}>Lorem ipsum dolor sit amet</Box>
    </Box>
  );

  const header = (
    <>
      <BaseHeader to='/profile/payment/hyperwallet'>
        Setup an account
      </BaseHeader>
      <Line />
    </>
  );
  const height = errors.mainError !== null ? '70vh' : '60vh';

  return (
    <Box>
      {isMobile() && header}
      <Box container sx={styles.container}>
        {isMobile() && title}
        <Box
          sx={{ ...styles.mainContainer, maxHeight: height }}
          component='div'
        >
          <form style={{ width: '85%', paddingBottom: '30px' }}>
            <Box>
              <Box sx={styles.formContainer} component='div'>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  haserrors={errors.firstName}
                  render={({ field }) => (
                    <Input
                      name='firstName'
                      error={errors.firstName}
                      errorLabel={errors.firstName?.message}
                      label='First name'
                      value={user.firstName}
                      fullWidth={true}
                      placeholder='First Name'
                      onInput={() => clearErrors()}
                      endIcon={<AvatarIcon />}
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='lastName'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='lastName'
                    error={errors.lastName}
                    errorLabel={errors?.lastName?.message}
                    label='Last name'
                    value={user.lastName}
                    fullWidth={true}
                    placeholder='Last Name'
                    onInput={() => clearErrors()}
                    endIcon={<AvatarIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    label='Email'
                    error={errors.email}
                    errorLabel={errors?.email?.message}
                    value={user.email}
                    fullWidth={true}
                    placeholder='Email'
                    onInput={() => clearErrors()}
                    endIonc={<AvatarIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='dob'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.dob}
                render={({ field }) => (
                  <Input
                    name='dob'
                    error={errors.dob}
                    errorLabel={errors?.dob?.message}
                    label='Date of birth'
                    value={user.dob}
                    fullWidth={true}
                    placeholder='Date of Birth'
                    onInput={() => clearErrors()}
                    endIcon={<CalendarIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='address'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='address'
                    label='Address'
                    error={errors.address}
                    errorLabel={errors?.address?.message}
                    value={user.address}
                    fullWidth={true}
                    placeholder='Address'
                    onInput={() => clearErrors()}
                    endIco={<LocationIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='city'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='city'
                    label='City'
                    error={errors.city}
                    errorLabel={errors?.city?.message}
                    value={user.city}
                    fullWidth={true}
                    placeholder='City'
                    onInput={() => clearErrors()}
                    endIcon={<LocationIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='country'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='country'
                    label='Country'
                    error={errors.country}
                    errorLabel={errors?.country?.message}
                    value={user.country}
                    fullWidth={true}
                    placeholder='Country'
                    onInput={() => clearErrors()}
                    endIcon={<LocationIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='stateProvince'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='stateProvince'
                    label='State'
                    error={errors.stateProvince}
                    errorLabel={errors?.state?.stateProvince}
                    value={user.stateProvince}
                    fullWidth={true}
                    placeholder='State province'
                    onInput={() => clearErrors()}
                    endIcon={<LocationIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
            <Box sx={styles.formContainer} component='div'>
              <Controller
                name='zipCode'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='zipCode'
                    label='Postal code'
                    error={errors.zipCode}
                    errorLabel={errors?.zipCode?.message}
                    value={user.zipCode}
                    fullWidth={true}
                    placeholder='Postal Code'
                    onInput={() => clearErrors()}
                    endIcon={<LocationIcon />}
                    {...field}
                  />
                )}
              />
            </Box>
          </form>
        </Box>
      </Box>
      <Stack>
        <Box sx={{ margin: '5px 25px 5px 25px' }}>
          <ErrorWrapper>
            <div haserrors={errors.mainError}></div>
          </ErrorWrapper>
        </Box>
        <ButtonsContainer>
          <Button
            size='lg'
            variant='link'
            sx={{ minWidth: '170px' }}
            onClick={() => navigate('/profile/payment/hyperwallet')}
          >
            Cancel
          </Button>
          <Button
            startIcon={<CheckIcon width={14} height={14} />}
            type='submit'
            size='large'
            sx={{ minWidth: '203px' }}
            onClick={handleSubmit(onSubmit)}
          >
            Save account
          </Button>
        </ButtonsContainer>
      </Stack>
    </Box>
  );
};

export default HyperWalletAccount;
