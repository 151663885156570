import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { BasicCard } from './BasicCard';
import { Details } from './ActivityDetails';
import { getColor } from 'pages/Activities/utils';
import { ActionContainer } from './ActivityCard.styles';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import Button from 'ihp-components/components/v2/Button';

//TODO: Move this component into ihp-components
export const ActivityCardMobile = ({
  rewardPoints,
  rewardBgColor,
  rewardColor,
  actionText,
  actionColor,
  actionBgColor,
  metaData,
  detailsDividerColor,
  detailsColor,
  hideDetails,
  type,
  link,
  isTaskGroup,
  onProfileActivity,
  onConsentActivity,
  showLoader,
  disabled,
  details,
  selectedTaskCategory,
  ...rest
}) => {
  const color = getColor(type);

  const label = details?.duration?.label?.toUpperCase().trim();
  const points = rewardPoints ?? 0;

  const showActionButton = !isTaskGroup && details?.isPast === false;
  let activityHandler = null;
  if (type === 'profile') {
    activityHandler = onProfileActivity;
  }
  if (type === 'consent') {
    activityHandler = onConsentActivity;
  }

  const renderComponent =
    type === 'profile' || type === 'consent' ? null : Link;

  return (
    <BasicCard
      {...rest}
      label={label}
      points={points}
      isTaskGroup={isTaskGroup}
      color={color}
      details={details}
    >
      {metaData && !isTaskGroup && (
        <Details
          metaData={metaData}
          details={details}
          detailsDividerColor={detailsDividerColor}
          detailsColor={detailsColor}
          selectedTaskCategory={selectedTaskCategory}
        />
      )}

      {showActionButton && (
        <ActionContainer>
          <Button
            size='sm'
            type='info2'
            variant='outlined'
            sx={{
              margin: 'auto',
              width: '100%'
            }}
            disabled={disabled}
            onClick={activityHandler}
            loading={showLoader}
            to={link || '/'}
            component={renderComponent}
            endIcon={<ArrowForwardIcon width={14} height={14} />}
          >
            {actionText ?? 'Details'}
          </Button>
        </ActionContainer>
      )}
    </BasicCard>
  );
};

ActivityCardMobile.propTypes = {
  icon: PropTypes.node.isRequired,
  iconBgColor: PropTypes.string,
  iconRadius: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  currentIndex: PropTypes.number,
  hideNumbers: PropTypes.bool,
  actionBgColor: PropTypes.string,
  actionText: PropTypes.string,
  actionColor: PropTypes.string,
  rewardPoints: PropTypes.number,
  rewardColor: PropTypes.string,
  rewardBgColor: PropTypes.string,
  metaData: PropTypes.arrayOf(PropTypes.object),
  detailsDividerColor: PropTypes.string,
  hideDetails: PropTypes.bool,
  type: PropTypes.string,
  link: PropTypes.string,
  isTaskGroup: PropTypes.bool,
};
