import { styled } from '@mui/system';
import { Modal, Typography, Box } from '@mui/material';
import { ReactComponent as ChevronDown } from 'images/shared/chevron-down-2.svg';
import { Input } from 'ihp-components/components/v2/Input';
import { ReactComponent as InfoCircle } from 'images/shared/info-circle-blue.svg';

const getStyles = ({ palette, breakpoints }) => ({
  title: {
    color: palette.primary.darkGray100,
    fontWeight: 600,
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    lineHeight: '140%',
  },

  flexContainer: {
    display: 'flex',
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  fieldContainer: {
    paddingTop: '20px',
    width: '100%',
    position: 'relative',
    fontStyle: 'normal',
    lineHeight: 'normal',
    '& .MuiFormLabel-root': {
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiInputBase-input': {
      fontWeight: '400',
      lineHeight: '140%',
    },
  },
  firstNameContainer: {
    paddingTop: '25px',
    width: '50%',
    position: 'relative',
    [breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '24px',
    },
    '& .MuiFormLabel-root': {
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiInputBase-input': {
      fontWeight: '400',
      lineHeight: '140%',
    },
  },
  lastNameContainer: {
    paddingTop: '25px',
    width: '50%',
    position: 'relative',
    marginLeft: '20px',
    [breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0px',
      paddingTop: '20px',
    },
    '& .MuiFormLabel-root': {
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    '& .MuiInputBase-input': {
      fontWeight: '400',
      lineHeight: '140%',
    },
  },
  checkboxContainer: {
    backgroundColor: 'transparent',
    paddingTop: '20px',
    marginTop: '0px',
    border: '0px',
    '& .MuiFormControlLabel-root': {
      alignItems: 'start',
      margin: '0px',
      '& .MuiCheckbox-root': {
        padding: '0px',
        bottom: '2px',
        [breakpoints.down('sm')]: {
          top: '10px',
        },
        '& span': {
          width: '24px',
          height: '24px',
        },
        '& svg': {
          width: '24px',
          height: '24px',
        },
      },
      '& .MuiTypography-root': {
        color: palette.primary.gray100,
        fontStyle: 'normal',
        lineHeight: '140%',
      },
    },
  },

  modalWrapper: {
    height: 'auto',
    maxHeight: '90vh',
    width: '56%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '8px',
    overflowY: 'auto',
    [breakpoints.down('sm')]: {
      width: '95%',
      height: '95%',
    },
  },
  titleWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '35px 40px',
    borderBottom: `1px solid ${palette.primary.pattensBlue}`,
    [breakpoints.down('sm')]: {
      padding: '24px 24px 0px 24px',
      borderBottom: '0px',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      alignSelf: 'stretch',
    },
  },
  iconWrapper: {
    marginTop: '8px',
    cursor: 'pointer',
    color: palette.primary.darkGray50,
    width: '13px',
    height: '20px',
    lineHeight: 'normal',
    fontStyle: 'normal',
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '0px 40px 40px',
    [breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px 24px 24px',
    },
  },
  linkContainer: {
    color: palette.primary.darkblue70,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '135%',
    textDecoration: 'underline',
    padding: '0px',
    paddingTop: '20px',
    height: 'max-content',
  },

  formWrapper: {
    padding: '15px 40px 20px',
    [breakpoints.down('sm')]: {
      padding: '0px 24px 24px',
    },
  },
});

export const DtpModal = styled(Modal)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    background: theme.palette.primary.gray5,
    opacity: '0.9 !important',
  },
}));

export const DropDownIcon = styled(ChevronDown)(({ theme }) => ({
  '&.MuiSelect-icon': {
    width: '16px',
    height: '16px',
    marginTop: '2px',
    color: theme.palette.primary.lightGray100,
    right: '20px!important',
  },
}));

export const InfoWrapper = styled('div')({
  display: 'flex',
  gap: 7,
  flexDirection: 'row',
  marginTop: '20px',
});

export const InfoIcon = styled(InfoCircle)(({ theme }) => ({
  width: '16px',
  height: '16px',
  path: {
    width: '16px',
    height: '16px',
  },
}));

export const InfoDetail = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray60,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '16px',
}));

export const SelectField = styled(Box)(({ theme }) => ({
  paddingTop: '25px',
  width: '100%',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export default getStyles;
