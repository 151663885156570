import { Stack, Typography } from '@mui/material';

import { DateCard, OptionCard, TextCard, TimeCard } from 'components/Input';
import { ControllerForm } from 'configUI/components/Shared';
import { Loader } from 'components/Loader';

export const PageForm = ({ title, category, questions, ...rest }) => {
  if (!questions) return <Loader />;

  const categorizedQuestion = questions.filter(
    (question) => question.category === category
  );

  return (
    <Stack spacing='20px' alignItems='stretch' {...rest}>
      <Typography variant='h5' color='secondary.gray4' align='center'>
        {title}
      </Typography>
      <Stack spacing='10px'>
        {categorizedQuestion.map(
          ({ id, questionType, questionTitle, description, ...rest }) => {
            switch (questionType) {
              case 'select': {
                const options = rest.items.map(({ value, id }) => ({
                  value: id,
                  label: value,
                }));
                return (
                  <ControllerForm key={id} name={id}>
                    <OptionCard
                      title={questionTitle}
                      subtitle={description}
                      options={options}
                    />
                  </ControllerForm>
                );
              }
              case 'text': {
                return (
                  <ControllerForm key={id} name={id}>
                    <TextCard
                      title={questionTitle}
                      subtitle={description}
                      placeholder={questionTitle}
                    />
                  </ControllerForm>
                );
              }
              case 'textArea': {
                return (
                  <ControllerForm key={id} name={id}>
                    <TextCard
                      title={questionTitle}
                      subtitle={description}
                      placeholder={questionTitle}
                      multiline
                      rows={3}
                    />
                  </ControllerForm>
                );
              }
              case 'datePicker': {
                return (
                  <ControllerForm key={id} name={id}>
                    <DateCard
                      title={questionTitle}
                      subtitle={description}
                      placeholder={questionTitle}
                    />
                  </ControllerForm>
                );
              }
              case 'timePicker': {
                return (
                  <ControllerForm key={id} name={id}>
                    <TimeCard
                      title={questionTitle}
                      subtitle={description}
                      placeholder={questionTitle}
                    />
                  </ControllerForm>
                );
              }
              default: {
                console.error('Invalid question type', questionType);
                return null;
              }
            }
          }
        )}
      </Stack>
    </Stack>
  );
};
