import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  studyName: '',
  description: '',
  metaTitle: '',
  metaDescription: '',
  protocolNumber: 0,
  numberOfParticipants: 0,
  file: null,
  url: '',
};

export const slice = createSlice({
  name: 'basicInformation',
  initialState,
  reducers: {
    updateInformation: (state, { payload }) => {
      const {
        name,
        studyName,
        description,
        metaTitle,
        metaDescription,
        protocolNumber,
        numberOfParticipants,
        file,
        url,
      } = payload;
      state.name = name;
      state.studyName = studyName;
      state.description = description;
      state.metaTitle = metaTitle;
      state.metaDescription = metaDescription;
      state.protocolNumber = protocolNumber;
      state.numberOfParticipants = numberOfParticipants;
      state.file = file;
      state.url = url;
    },
  },
});

export const { updateInformation } = slice.actions;

export default slice.reducer;
