import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';

import { BaseLayout } from 'components/BaseLayout';
import Button from 'ihp-components/components/v2/Button';

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const AddButton = styled(Button)({
  boxSizing: 'border-box',
  height: '50px',
  width: '127px',
});

export const FloatingAddButton = styled(AddButton)({
  height: '56px',
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  zIndex: '10',
  padding: '0 30px',
});

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  // Use 96px avoid content behind add button
}));

export const StyledBaseLayout = styled(BaseLayout)(({ theme }) => ({
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',

  '& > .BaseLayout-scrollable': {
    backgroundColor: theme.palette.primary.gray20,
    padding: '20px 20px 96px 20px',
  },
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
    padding: '50px',
    '& > .BaseLayout-header': {
      position: 'initial',
      paddingBottom: '37px',
    },
    '& > .BaseLayout-scrollable': {
      padding: 0,
    },
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18px',
  height: '18px',
  color: theme.palette.primary.gray50,
  padding: 0,
}));

export const ListButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  color: theme.palette.primary.gray50,
  border: `1px solid ${theme.palette.primary.gray10}`,
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.white,
}));
