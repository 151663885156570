import { createSlice } from '@reduxjs/toolkit';

const studies = [
  {
    id: '001',
    organizationId: '001',
    name: 'Bloom',
    status: 'Active',
  },
];

const currentStudyId = studies[0].id;

const initialState = {
  studies,
  currentStudyId,
};

const updateStudyAction = (studyState, action) => {
  let studyForUpdate = studyState.studies.find((study) => study.id === action.payload.id);

  if (studyForUpdate) {
    studyForUpdate.name = action.payload.name;
  }
};

const addStudyAction = (studyState, action) => {
  studyState.studies.push(action.payload);
};

const setCurrentStudyIdAction = (studyState, action) => {
  studyState.currentStudyId = action.payload;
};

export const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    addStudy: addStudyAction,
    updateStudy: updateStudyAction,
    setCurrentStudyId: setCurrentStudyIdAction,
  },
});

export const { addStudy, updateStudy, setCurrentStudyId } = studySlice.actions;

export const selectAllStudies = (state) => state.studyState.studies;
export const selectStudyById = (state, id) => selectAllStudies(state).find((study) => study.id === id);
export const selectStudiesByOrganizationId = (state, organizationId) =>
  selectAllStudies(state).filter((study) => study.organizationId === organizationId);
export const selectCurrentStudyId = (state) => state.studyState.currentStudyId;

export default studySlice.reducer;
