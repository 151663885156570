import { createSlice } from '@reduxjs/toolkit';

const languages = [
  {
    id: '001',
    name: 'English',
    isActive: false,
  },
  {
    id: '002',
    name: 'Spanish',
    isActive: false,
  },
  {
    id: '003',
    name: 'French',
    isActive: false,
  },
  {
    id: '004',
    name: 'German',
    isActive: false,
  },
  {
    id: '005',
    name: 'Japanese',
    isActive: false,
  },
  {
    id: '006',
    name: 'Mandarin',
    isActive: false,
  },
  {
    id: '007',
    name: 'Portuguese',
    isActive: false,
  },
];

const initialState = {
  allLanguages: languages, // comes from API
  selectedLanguages: languages.slice(0, 3), // comes from API
};
const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    toggleLanguage: (state, action) => {
      state.selectedLanguages = state.selectedLanguages.map((language) => {
        return {
          ...language,
          isActive:
            language.id === action.payload
              ? !language.isActive
              : language.isActive,
        };
      });
    },
    addLanguage: (state, action) => {
      const selectedLanguage = state.allLanguages.find(
        (language) => language.id === action.payload
      );
      if (
        state.selectedLanguages.some(
          (language) => language.id === action.payload
        )
      )
        return;
      state.selectedLanguages.push(selectedLanguage);
    },
    deleteLanguage: (state, action) => {
      state.selectedLanguages = state.selectedLanguages.filter(
        (language) => language.id !== action.payload.id
      );
    },
  },
});

export const { toggleLanguage, deleteLanguage, addLanguage } =
  languageSlice.actions;

export default languageSlice.reducer;
