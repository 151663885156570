import { styled } from '@mui/material/styles';
import { StyledButton } from './Button';

export const sizePaddingMap = {
  large: '17px 30px',
  medium: '15px 25px',
  small: '12px 20px'
};

export const sizeHeightMap = {
  large: '56px',
  medium: '50px',
  small: '44px'
};

export const sizeFontSizeMap = {
  large: '16px',
  medium: '15px',
  small: '14px'
};

export const sizeLineHeightMap = {
  large: '140%',
  medium: '135%',
  small: '140%'
};

export const sizeIconTextMarginMap = {
  large: '15px',
  medium: '15px',
  small: '12px'
}; 


export const TextButton = styled(StyledButton)(({ size }) => ({
  padding: sizePaddingMap[size],
  height: sizeHeightMap[size],
  fontSize: sizeFontSizeMap[size],
  lineHeight: sizeLineHeightMap[size],
  fontWeight: '600',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  letterSpacing: '0.02em',
  'svg': {
    width: '14px',
    height: '15px'
  },
  '.MuiButton-endIcon': {
    marginLeft: sizeIconTextMarginMap[size]
  },
  '.MuiButton-startIcon': {
    marginRight: sizeIconTextMarginMap[size]
  }
}));

/*
  Example Usage

  <TextButton
    color="primary"
    size="large"
  >
    Button
  </Button>

  <TextButton
    color="primary"
    size="large"
    endIcon={<ArrowForwardIcon />}
  >
    Button
  </TextButton>

  <TextButton
    color="primary"
    size="large"
    startIcon={<ArrowForwardIcon />}
  >
    Button
  </TextButton>

  color: primary | secondary | tertiary
*/

