import { cloneElement } from 'react';
import { LocalizationProvider, PickersDay } from '@mui/lab';
import DateIOAdapter from '@mui/lab/AdapterDateFns';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as ChevronLeft } from 'images/shared/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'images/shared/chevron-right.svg';

const StyledIconButton = styled(({ children, ...rest }) => (
  <IconButton {...rest} >
    {cloneElement(children, { width: '8px', height: '14px' })}
  </IconButton>
))(({
  theme
}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18px',
  height: '16px',
  color: theme.palette.primary.gray50,
  padding: 0,
}));

export const StyledPickersDay = styled(PickersDay)(({
  theme,
  width,
  height
}) => ({
  borderRadius: 4,
  width,
  height,
  ...theme.typography.pl1
}));

const getWeekAdapter = (customWeekdays) => (
  function WeekAdapter(options) {
    const adapter = new DateIOAdapter(options);
    const constructDayObject = day => ({ charAt: () => day });
    return {
      ...adapter,
      getWeekdays: () => customWeekdays.map(constructDayObject)
    };
  }
);

const getHeaderStyle = ({ width, height }) => theme => ({
  ...theme.typography.pb4,
  width,
  height,
  color: 'rgba(60, 60, 67, 0.3)',
});

const getDatePickerStyle = ({
  width,
  padding,
  headerStyle
}) => ({
  fontFamily: theme => theme.typography.fontFamily,
  fontStyle: theme => theme.typography.fontStyle,
  lineHeight: theme => theme.typography.lineHeight,
  color: theme => theme.typography.color,
  '& > .MuiPickerStaticWrapper-root': {
    width,
    '& > div > div': { width }
  },
  '& * > .MuiCalendarPicker-root': {
    width,
    '& > div': { padding }
  },
  '& * > .PrivatePickersSlideTransition-root': {
    '& > div': {
      position: 'unset',
      '& > div': {
        justifyContent: 'space-between',
      }
    },
  },
  '& * > .PrivatePickersFadeTransitionGroup-root > div > div': {
    justifyContent: 'space-between'
  },
  '& * > .MuiTypography-root': theme => headerStyle(theme),
});

export const DatepickerStyleWrapper = ({
  width,
  padding,
  iconProps,
  customWeekdays,
  children,
  ...rest
}) => (
  <Box
    sx={
      getDatePickerStyle({
        width,
        padding,
        headerStyle: getHeaderStyle({
          ...iconProps
        }),
      })
    }
    {...rest}
  >
    <LocalizationProvider dateAdapter={getWeekAdapter(customWeekdays)}>
      {cloneElement(children, {

        renderDay: (...[, , props]) => <StyledPickersDay {...iconProps} {...props} />,
        displayStaticWrapperAs: 'desktop',
        components: {
          LeftArrowButton: props =>
            <StyledIconButton {...props}>
              <ChevronLeft />
            </StyledIconButton>,
          RightArrowButton: props =>
            <StyledIconButton {...props}>
              <ChevronRight />
            </StyledIconButton>
        }
      })}
    </LocalizationProvider>
  </Box>
);
