import React, { useCallback, useState, useEffect } from 'react';
import { InputAdornment } from '@mui/material';
import { useDebounce } from 'hooks/useDebounce';

import { useGetAllResourcesQuery } from 'ihp-bloom-redux/features/resource/resourceApiSlice';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useTheme } from '@mui/material';

import EducationalMaterialsList from 'components/EducationalMaterial/List';
import { FeedbackModal } from './Modals/feedbackModal';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import { WarningModal } from './Modals/warningModal';
import EducationalMaterialFilterDrawer from './FilterDrawer';
import { EmptyList } from './Empty';

import {
  Container,
  Header,
  Title,
  SearchWrapper,
  SearchInput,
  LoadMoreButton,
  Label,
} from './style';
import { Loader } from 'components/Loader';
import { FiltersToggleButton } from '../../components/FiltersToggleButton';
import { useGetYourInterestsQuery } from 'ihp-bloom-redux/features/educationCategories/educationCategoriesApiSlice';

export const EducationalMaterials = () => {
  const theme = useTheme();
  const { person } = useSelector((state) => state.user);
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({
    'educational-category': 'ALL',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [educationalMaterials, setEducationalMaterials] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [targetAddress, setTargetAddress] = useState(null);
  const [currentResourceId, setCurrentResourceId] = useState(null);
  const [status, setStatus] = useState('');
  const [myInterests, setMyInterests] = useState([]);
  const [isReset, setIsReset] = useState(false);

  const debouncedSearch = useDebounce(searchTerm, 500);

  const handleOnSearchTermUpdate = (item) => {
    setSearchTerm(item);
  };

  const toggleFilters = useCallback(() => {
    setOpenFilters((state) => !state);
  }, []);

  const loadMore = useCallback(() => {
    setPageNum((prev) => prev + 1);
  }, [setPageNum]);

  const {
    data: yourInterests,
    preSelectedInterestResponse,
    isLoading: loadingYourInterests,
    isFetching,
    isUninitialized,
    refetch,
  } = useGetYourInterestsQuery(
    {
      personId: person?.id,
      params: {
        'page[limit]': 100000,
      },
    },
    {
      skip: !person?.id,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        data,
        preSelectedInterestResponse: data?.data.map((interest) => {
          return interest?.attributes?.education_category_code;
        }),
      }),
    }
  );

  useEffect(() => {
    if (preSelectedInterestResponse) {
      setMyInterests(preSelectedInterestResponse);
    }
  }, [yourInterests]);

  const { personResources, data, isLoading, items, totalPages, perPage } =
    useGetAllResourcesQuery(
      {
        'filter[person]': person?.id,
        include: 'person-resource',
        'page[page]': pageNum,
        'page[limit]': 10,
        sort: '-createdAt',
        ...(debouncedSearch && { 'filter[title]': debouncedSearch }),
        ...(filters['status'] && { 'filter[status]': filters['status'] }),
        ...(filters['is-read'] && { 'filter[is-read]': filters['is-read'] }),
        ...(filters['educational-category'] && {
          'filter[educational-category]': filters['educational-category'],
        }),
        ...(filters['keyword'] && { 'filter[keyword]': filters['keyword'] }),
      },
      {
        skip:
          !loadingYourInterests &&
          person?.id &&
          !(debouncedSearch.length < 3 && debouncedSearch.length > 0)
            ? false
            : true,
        refetchOnMountOrArgChange: true,
        selectFromResult: ({
          data,
          included,
          isLoading,
          isUninitialized,
          isFetching,
          error,
        }) => {
          const personReourcesMap = data?.included?.reduce((maps, item) => {
            return { ...maps, [item.attributes.resource_id]: item.attributes };
          }, {});

          return {
            personResources: data?.data,
            data: data,
            totalPages: data?.meta?.page?.lastPage,
            perPage: data?.meta?.page?.perPage,
            error: error,
            isLoading: isLoading || isUninitialized || isFetching,
            items: data?.data?.map((item) => {
              const id = item.id;
              return {
                id,
                title: item?.attributes['title'],
                subtitle: item?.attributes['description'],
                description: item?.attributes['summary'],
                resourceStatus: item?.attributes['status'],
                link: item?.attributes['link'],
                read: personReourcesMap && personReourcesMap.hasOwnProperty(id),
                status:
                  personReourcesMap &&
                  ['helpful', 'unhelpful'].includes(
                    personReourcesMap[id]?.status
                  )
                    ? personReourcesMap[id].status
                    : 'feedback',
                personAttribute:
                  personReourcesMap && personReourcesMap[id]
                    ? personReourcesMap[id]
                    : null,
              };
            }),
          };
        },
      }
    );

  useEffect(() => {
    if (pageNum > 1) {
      setEducationalMaterials((prevData) => [...prevData, ...items]);
    } else {
      setEducationalMaterials(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Update page on wheneven search term changes
  useEffect(() => {
    if (searchTerm.length > 2) {
      setPageNum(1);
    }
  }, [searchTerm]);

  const closeDrawer = (filters) => {
    if (filters) {
      // Always add the educational category to the filters
      setFilters({ ...filters, 'educational-category': 'ALL' });
      setPageNum(1);
    }
    setOpenFilters(false);
  };

  const hasMoreData =
    pageNum < totalPages &&
    educationalMaterials &&
    educationalMaterials.length !== 0 &&
    items?.length === perPage;

  const updateStatus = (id, status) => {
    setEducationalMaterials((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            personAttribute: { ...item.personAttribute, status },
            read: true,
          };
        } else {
          return item;
        }
      });
    });
  };
  function isFilterApplied() {
    if (isReset) {
      return Object.keys(filters).length > 0;
    } else {
      //   if (
      //     filters['educational-category'] &&
      //     filters['educational-category'] !== ''
      //   ) {
      //     let appliedEducationalCategory =
      //       filters['educational-category']?.split(',');
      //     let difference = appliedEducationalCategory.filter(
      //       (x) => !myInterests.includes(x)
      //     );
      //     if (difference.length > 0) {
      //       return true;
      //     }
      //   }
      return filters['is-read'] || filters['status'] === 'helpful';
    }
  }

  return (
    <Container>
      <EducationalMaterialFilterDrawer
        setIsReset={setIsReset}
        myInterests={myInterests}
        open={openFilters}
        anchor={'right'}
        onClose={closeDrawer}
      />
      <WarningModal
        isOpen={isOpenWarningModal}
        setIsOpenModal={setIsOpenWarningModal}
        targetAddress={targetAddress}
      />
      <FeedbackModal
        isOpen={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        currentResourceId={currentResourceId}
        status={status}
        personResources={personResources}
        updateResource={updateStatus}
      />
      <Header>
        <Title>Information for You</Title>
        <FiltersToggleButton
          label={'FILTERS'}
          handleOnClick={toggleFilters}
          hasAppliedFilters={isFilterApplied()}
        />
      </Header>
      <Label>
        This list has been created based on the interests you selected in your
        profile. To see the full list of options, please click the filter tool.
      </Label>
      <SearchWrapper>
        <SearchInput
          fullWidth
          placeholder='Search'
          border='none'
          onChange={(e) => handleOnSearchTermUpdate(e.target.value)}
          startAdornment={
            <InputAdornment position='end' size='large'>
              <SearchIcon />
            </InputAdornment>
          }
        />
      </SearchWrapper>
      {educationalMaterials && educationalMaterials.length > 0 ? (
        <EducationalMaterialsList
          educationalMaterials={educationalMaterials}
          openFeedbackModal={(educationalMaterial) => {
            setCurrentResourceId(educationalMaterial.id);
            setStatus(educationalMaterial?.personAttribute?.status);
            return setIsOpenModal(true);
          }}
          openWarningModal={() => setIsOpenWarningModal(true)}
          setTargetAddress={setTargetAddress}
        />
      ) : (
        <EmptyList isLoading={data === undefined} />
      )}
      {isLoading && educationalMaterials && educationalMaterials.length > 0 ? (
        <Loader />
      ) : (
        hasMoreData && (
          <LoadMoreButton variant='text' color='primary' onClick={loadMore}>
            Load more
          </LoadMoreButton>
        )
      )}
    </Container>
  );
};
