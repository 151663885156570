import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';

export const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  border: `1px solid ${theme.palette.primary.gray25}`,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: '70px',
  boxSizing: 'border-box',
  padding: '0 15px',
}));

export const TextContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  width: '15px',
  height: '15px',
  color: theme.palette.primary.fuchsia50,
}));

export const Container = styled(Box)({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});
