import PropTypes from 'prop-types';
import {
  CheckContainer,
  Container,
  Options,
  BlueTick,
  RadioOption,
  TickOption,
  Circle,
} from './styles';
import { ReactComponent as Tick } from './tick.svg';
import { Typography } from '@mui/material';

export function SelectGroup({
  checkType = 'tick',
  label = '',
  nonCombinableOptions = [],
  options = [],
  selectedOptions = [],
  setSelectedOptions,
  type = 'single',
}) {
  const single = type === 'single';

  function toggleOption(opt) {
    const selected = selectedOptions.includes(opt);

    if (nonCombinableOptions.includes(opt)) {
      return selected ? setSelectedOptions([]) : setSelectedOptions([opt]);
    } else {
      nonCombinableOptions.forEach((nco) => {
        setSelectedOptions((prev) => prev.filter((o) => o !== nco));
      });
    }

    if (selected) {
      setSelectedOptions((prev) => prev.filter((o) => o !== opt));
    } else if (single && selectedOptions.length === 1) {
      setSelectedOptions([opt]);
    } else {
      setSelectedOptions((prev) => prev.concat(opt));
    }
  }

  return (
    <Container>
      {label && (
        <Typography variant='pn3' color='primary.darkGray70'>
          {label}
        </Typography>
      )}

      <Options>
        {options.map((o) => {
          const selected = selectedOptions.includes(o);

          const props = {
            key: o,
            onClick: () => toggleOption(o),
            selected,
          };

          if (checkType === 'tick') {
            return (
              <TickOption {...props}>
                <CheckContainer selected={selected}>
                  <Tick />
                </CheckContainer>
                {o}
              </TickOption>
            );
          } else {
            return (
              <RadioOption {...props}>
                {selected ? <BlueTick /> : <Circle />}
                {o}
              </RadioOption>
            );
          }
        })}
      </Options>
    </Container>
  );
}

SelectGroup.propTypes = {
  checkType: PropTypes.oneOf(['radio', 'tick']),
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  setSelectedOptions: PropTypes.func,
  type: PropTypes.oneOf(['multi', 'single']),
};
