import { NOTIFICATION_TYPES } from '../constants/global';
import { useCreateNotificationMutation } from 'ihp-bloom-redux/features/notification';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useState } from 'react';

const useSendNotification = () => {
  const { person, activeAccountProfile } = useSelector((state) => state.user);
  const [createNotification] = useCreateNotificationMutation();
  const sendNotification = (
    firstName,
    lastName,
    email,
    deliveryMethod = NOTIFICATION_TYPES.EMAIL,
    notificationTypeCode = 'SAW',
    type = 'participant-notifications',
    { ...rest } = {}
  ) => {
    const payload = {
      data: {
        type: type,
        attributes: {
          notification_type_code: notificationTypeCode,
          from_person_id: person.id,
          account_profile_id: parseInt(activeAccountProfile.id),
          delivery_method: deliveryMethod,
          email_id: email,
          first_name: firstName,
          last_name: lastName,
          ...rest,
        },
      },
    };

    return createNotification({ payload });
  };

  return {
    sendNotification,
  };
};

export default useSendNotification;
