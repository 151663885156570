import React, { useMemo } from 'react';
import { useSelector } from 'ihp-bloom-redux/app/redux';

import { STUDY_ARM_STATUS_CODES } from 'constants/global';
import { useConfiguration } from 'pages/Onboarding/ResearchEligibilityRole/hooks/useConfiguration';
export const useHasMultipleProfiles = () => {
  const { accountProfiles } = useSelector((state) => state.user);
  const { getStudyArmStatusByCode } = useConfiguration();

  const isMultipleResearchEnrolled = useMemo(() => {
    const researchEnrolledStatus = getStudyArmStatusByCode(
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLED
    );
    const researchEnrolledProfiles = accountProfiles.filter(
      (profile) =>
        profile.attributes.study_arm_status_id === researchEnrolledStatus.id
    );
    return researchEnrolledProfiles.length > 1;
  }, [accountProfiles, getStudyArmStatusByCode]);

  return isMultipleResearchEnrolled;
};
