import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

import { StyledFilledButton } from './styles';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';

export const FinalStep = ({ attributes }) => (
  <Stack
    direction='row'
    justifyContent='center'
    alignItems='center'
    height='100%'
  >
    <div>
      <CelebrationScreen attributes={attributes} />
      <StyledFilledButton component={Link} to='/my-activities'>
        <CheckIcon width='15px' />
        Done
      </StyledFilledButton>
    </div>
  </Stack>
);
