import { useGetPatientTasksQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import { formatDate } from './utils';

export const useGetTaskEntry = (id) => {
  const { data, isSuccess, isLoading, isError } = useGetPatientTasksQuery({
    paginate: false,
  });
  const patientTask = data?.data?.find?.(({ task: { id } }) => id === 562);
  const questions = patientTask?.task?.temp?.content;
  const entry = patientTask?.task_entries?.find?.((entry) => entry.id === id);
  const header = entry
    ? formatDate(entry.created_at, 'eeee, MM / dd / yyyy')
    : '';
  const answers = entry ? JSON.parse(entry.data)?.content : [];
  const mappedAnswers = answers.reduce(
    (obj, answer) => ({ ...obj, [answer.id]: answer.values[0].value }),
    {}
  );
  const forms = questions
    ?.map?.(({ id, questionTitle, questionType, category, items }) => {
      const mappedAnswer = mappedAnswers[id];
      const answer =
        items?.find?.((item) => item.id === mappedAnswer)?.value ||
        mappedAnswer;
      return {
        id,
        questionTitle,
        questionType,
        category,
        ...(answer && { answer }),
      };
    })
    ?.reduce?.(
      (obj, form) =>
        !!form.category
          ? {
              ...obj,
              [form.category]: [...obj[form.category], form],
            }
          : obj,
      { intro: [], mood: [], physical: [] }
    );

  return {
    data: { header, forms },
    isError: isError || (isSuccess && !forms),
    isLoading: isLoading || !forms,
  };
};
