import { useState } from 'react';
import { Typography } from '@mui/material';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useSubmitWithdrawalMutation } from 'ihp-bloom-redux/features/study/studyApi';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';

import { NextPayout } from 'components/Payment';
import { getRewardsInfo } from 'components/Payment/Rewards/util';
import { findStudyAttribute } from 'utils/study';
import WithdrawFromStudyModal from '../MyStudy/withdrawFromStudyModal';

import {
  StudyWithdrawalPageWrapper,
  PageTitle,
  SectionTitle,
  StyledBox,
  Line,
  WithdrawButton,
  CurrentRewardsPointsSummay,
  CurrentRewardsProgressBox,
} from './styles';
import { Score } from 'components/Payment/Score';
import { getMyProfileReasons } from '../MyProfile/utils';
import { Loader } from 'components/Loader';

const StudyWithdrawal = () => {
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const toggleWithdrawModal = () => setShowWithdrawModal(!showWithdrawModal);

  // Api calls

  const [
    submitWithdrawal,
    { isLoading: submittingWithdrawal, isSuccess: widthdrawSuccessfully },
  ] = useSubmitWithdrawalMutation();

  const { activeAccountProfile } = useSelector((state) => state.user);
  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;

  const { person, isLoading: isLoadingCurrentUser } = useGetCurrentUserQuery(
    undefined,
    {
      selectFromResult: ({ data, ...rest }) => ({
        person: data?.data?.included?.[0]?.[0]?.data,
        ...rest,
      }),
    }
  );

  const { data: goalData, isLoading: loadingGoalStats } =
    useGetParticipantGoalStatsQuery(person?.id, {
      skip: person?.id ? false : true,
    });

  const totalRewardAmount =
    findStudyAttribute(studyConfiguration || [], 'total_reward_amount') || 1000; // 1000 is default value until api start sending this

  const payoutMethod =
    findStudyAttribute(studyConfiguration || [], 'payout_method') || [];

  const callCentrePhone =
    findStudyAttribute(studyConfiguration || [], 'call_centre_phone') ||
    '866-267-4479';

  const allowReEnroll =
    findStudyAttribute(studyConfiguration || [], 'allow_re_enroll') || false;

  const withdrawalReasons = getMyProfileReasons(
    findStudyAttribute(studyConfiguration || [], 'withdrawal_reasons'),
    'self-withdrawal'
  );

  const thresholdMinAmount = payoutMethod?.[0]?.threshold?.min || 0;
  const thresholdIncrement = payoutMethod?.[0]?.threshold?.increment || 1;
  const rewardsData = getRewardsInfo(goalData?.data);

  const availablePoints = rewardsData?.balance || 0;

  // Formula for PAR
  // If ((balance div increment) x increment) - min) >= 0 -> PAR = (balance div increment) x increment
  // If ((balance div increment) x increment) - min) < 0 -> PAR = 0

  let redeemablePoints =
    Math.floor(availablePoints / thresholdIncrement) * thresholdIncrement;
  if (redeemablePoints - thresholdMinAmount < 0) {
    redeemablePoints = 0;
  }

  const handleSubmission = async (value) => {
    const requestBody = {
      personId: person?.id,
      payload: {
        study_id: 1,
        reason: value,
      },
    };

    await submitWithdrawal(requestBody);
  };

  if (isLoadingCurrentUser || loadingGoalStats) {
    return <Loader />;
  }

  return (
    <StudyWithdrawalPageWrapper>
      <PageTitle variant='h2'>Study withdrawal</PageTitle>
      <StyledBox margin='20px 0px'>
        <SectionTitle color='primary.gray75' variant='h5' margin='0px 0px 5px'>
          We hate to see you go
        </SectionTitle>
        <Typography color='primary.gray75' variant='pl3'>
          If you leave now, you will forfeit any points that are not redeemable
          yet and any future rewards opportunities.
        </Typography>
      </StyledBox>
      <SectionTitle variant='h5'>Current rewards summary</SectionTitle>
      <StyledBox margin='20px 0px 0px' padding='0px'>
        <CurrentRewardsPointsSummay>
          <Score
            label='Points Available'
            description='Points Available'
            showInfo={false}
          >
            {availablePoints}
          </Score>
          <Score
            label='Points Available to Redeem'
            description='The amount of unredeemed points that you still have available to redeem.'
          >
            {redeemablePoints}
          </Score>
          <Score
            label='Total Points Earned-to-Date'
            description='The amount of points that you have earned by completing activities in the study.'
          >
            {rewardsData?.total_no_points}
          </Score>
          <Score
            label='Future Rewards Opportunity'
            description='The rewards value of points you could earn by participating in the entire study.'
          >
            ${totalRewardAmount - (rewardsData?.converted_amout || 0)}
          </Score>
        </CurrentRewardsPointsSummay>
        <Line />
        <CurrentRewardsProgressBox>
          <NextPayout
            availablePoints={availablePoints}
            remainingPoints={thresholdMinAmount - availablePoints}
            thresholdMinAmount={thresholdMinAmount}
            progress={(availablePoints / thresholdMinAmount) * 100}
            isComplete={availablePoints >= thresholdMinAmount}
            claimReward={() => null}
            readOnly={true}
          />
        </CurrentRewardsProgressBox>
      </StyledBox>
      <StyledBox margin='20px 0px'>
        <div>
          <SectionTitle variant='h5' margin='0px 0px 30px'>
            Your participation is helping to move healthcare forward
          </SectionTitle>

          <Typography variant='pl3'>
            If you would like to withdraw from the study, click the button
            below. You will receive an email with instructions to claim the
            rewards later and another email to download your journal entries.
          </Typography>
        </div>

        <WithdrawButton width='100%' onClick={() => setShowWithdrawModal(true)}>
          Withdraw from study
        </WithdrawButton>
      </StyledBox>
      <WithdrawFromStudyModal
        isOpen={showWithdrawModal}
        onClose={toggleWithdrawModal}
        callCentrePhone={callCentrePhone}
        withdrawalReasons={withdrawalReasons}
        submittingWithdrawal={submittingWithdrawal}
        widthdrawSuccessfully={widthdrawSuccessfully}
        allowReEnroll={allowReEnroll}
        onSubmit={handleSubmission}
        studyArmCode={studyArmCode}
      />
    </StudyWithdrawalPageWrapper>
  );
};

export default StudyWithdrawal;
