import React from 'react';

import { ReactComponent as ArticleBadge } from 'images/profile/achievements/article.svg';
import { ReactComponent as ArticleFilledBadge } from 'images/profile/achievements/article-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';
import { MobileArticleReadCard } from './index.mobile';
import { isMobile } from 'utils/isMobile';

import {
  CardWrapper,
  RewardContainer,
  StyledChip,
  Title,
  StyledSlider,
  Info,
  MyPoints,
  IsCompletedMark,
} from '../styles';

export const ArticleReadCard = (props) => {
  const { inProgress, isCompleted } = props;

  if (isMobile()) {
    return (
      <MobileArticleReadCard
        inProgress={inProgress}
        isCompleted={isCompleted}
      />
    );
  }

  return (
    <CardWrapper backgroundColor={isCompleted ? '#E0EAF9' : '#ffffff'}>
      {isCompleted && <IsCompletedMark backgroundColor='#2F80ED' />}
      {!inProgress ? <ArticleBadge /> : <ArticleFilledBadge />}
      <Info>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 5 Read articles
        </Title>
        <StyledSlider
          value={100}
          isCompleted={isCompleted}
          backgroundColor='#2F80ED'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Dolor sit amet'
            isCompleted={isCompleted}
          />
        </RewardContainer>
      </Info>
    </CardWrapper>
  );
};
