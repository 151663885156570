import { styled } from '@mui/material/styles';

export const UnCheckedboxIcon = styled('span')(({ theme, width, height }) => ({
  borderRadius: 5,
  width: width || 28,
  height: height || 28,
  border: `1px solid ${theme.palette.primary.gray25}`,
  backgroundColor: theme.palette.primary.white,
  boxSizing: 'border-box',

  'input:hover ~ &': {
    border: `1px solid ${theme.palette.primary.fuchsia75}`,
  },
  'input:disabled ~ &': {
    border: `1px solid ${theme.palette.primary.gray25}`,
  },
}));
