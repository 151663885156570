import { Box, Typography, useTheme } from '@mui/material';
import {
  FormBox,
  getStyles,
  NotificationSubTitle,
  NotificationTitle,
} from './styles';
import ToggleSwitch from 'ihp-components/components/v2/ToggleSwitch';

const NotificationPreference = (props) => {
  const { value, defaultValue, onChange, options, disabled } = props;
  const theme = useTheme();
  const styles = getStyles(theme);

  const handleChange = (selectedValue) => {
    const values = value?.split(',');
    const length = values?.length;
    if (length === 1 && values.includes(selectedValue)) {
      onChange(defaultValue);
    } else if (length > 1 && values.includes(selectedValue)) {
      values.splice(values.indexOf(selectedValue), 1);
      onChange(values.join(','));
    } else if (length === 0) {
      onChange(`${selectedValue}`);
    } else if (length === 1 && !values.includes(selectedValue)) {
      values.push(selectedValue);
      onChange(values.join(','));
    }
  };

  return (
    <Box sx={styles.inputWrapper} component='div'>
      <NotificationTitle>Notification preferences</NotificationTitle>
      <NotificationSubTitle>
        Email notifications must remain active. You may opt into SMS
        notifications.
      </NotificationSubTitle>
      {options?.map((option) => {
        const isChecked = value?.includes(option.value);
        return (
          <FormBox tabIndex={0} onClick={() => handleChange(option.value)}>
            <Typography
              color={isChecked ? 'primary.gray100' : 'primary.darkGray50'}
              width={'100%'}
              variant='pl3'
            >
              {option.label}
            </Typography>
            <ToggleSwitch checked={isChecked} disabled={disabled} />
          </FormBox>
        );
      })}
    </Box>
  );
};

export default NotificationPreference;
