import { createSlice } from '@reduxjs/toolkit';
import { removeReminders } from './medicationTrackerSlice';

const initialState = {
  '2022/03/09': ['001', '004'],
  '2022/03/10': ['004'],
  '2022/03/11': ['002', '004'],
  '2022/03/12': ['004'],
  '2022/03/13': ['002', '004'],
  '2022/03/14': ['003'],
  '2022/03/15': ['004'],
  '2022/03/16': ['004'],
};

const setDoseTakenAction = (state, { payload }) => {
  const { id, date } = payload;
  if (!state[date]) {
    state[date] = [];
  }
  if (!state[date].includes(date)) {
    state[date].push(id);
  }
};

const resetDoseTakenAction = (state, { payload }) => {
  const { id, date } = payload;
  if (state[date]) {
    state[date] = state[date].filter(doseId => doseId !== id);
  }
  if (state[date].length === 0) {
    delete state[date];
  }
};

const slice = createSlice({
  name: 'doseTaken',
  initialState,
  reducers: {
    setDoseTaken: setDoseTakenAction,
    resetDoseTaken: resetDoseTakenAction,
  },
  extraReducers: builder => builder
    .addCase(removeReminders.type, (state, { payload }) => {
      Object.keys(state).forEach(date => {
        state[date] = state[date].filter(id => !payload.includes(id));
        if (state[date].length === 0) {
          delete state[date];
        }
      });
    })
});

export const { setDoseTaken, resetDoseTaken } = slice.actions;

export const selectIsDoseTaken = (doseId, date) => state => {
  const ids = state[slice.name][date];
  return Boolean(ids && ids.includes(doseId));
}

export const selectDoseTaken = (doseIds, date) => state => {
  const ids = state[slice.name][date] || [];
  return doseIds.filter(doseId => ids.includes(doseId));
}

export default slice;
