const parseParam = (param) => (!!param ? `/${param}` : '');

const parseSearchParams = (searchParamsObj = {}) => {
  const searchParams = Object.entries(searchParamsObj)
    .map(([key, entry]) => `${key}=${entry}`)
    .join('&');
  return !!searchParams ? `?${searchParams}` : '';
};

export const getNotificationCenterPath = (searchParams) =>
  `/tools${parseSearchParams(searchParams)}`;

export const getMedicationTrackerPath = (searchParams) =>
  `/tools/medication-tracker${parseSearchParams(searchParams)}`;
export const getMedicationTrackerRemindersPath = (id, searchParams) =>
  `/tools/medication-tracker/reminders${parseParam(
    id
  )}${parseSearchParams(searchParams)}`;
export const getMedicationTrackerAllPath = (searchParams) =>
  `/tools/medication-tracker/all${parseSearchParams(
    searchParams
  )}`;
export const getMedicationTrackerEditPath = (id, searchParams) =>
  `/tools/medication-tracker/reminders${parseParam(
    id
  )}/edit${parseSearchParams(searchParams)}`;
export const getMedicationTrackerCreatePath = (searchParams) =>
  `/tools/medication-tracker/create${parseSearchParams(
    searchParams
  )}`;
export const getMedicationTrackerSearchPath = (searchParams) =>
  `/tools/medication-tracker/search${parseSearchParams(
    searchParams
  )}`;
