import { Typography } from '@mui/material';

import { Loader } from 'components/Loader';
import { Card } from './Card';
import { Content } from './CardList.styles';

export const CardList = ({ forms, isLoading }) => {
  if (isLoading) return <Loader />;
  return (
    <Content>
      <Typography variant='h4' color='primary.fuchsia75' align='center'>
        {forms?.intro[0]?.answer}
      </Typography>
      <Card title='Mood' form={forms.mood} />
      <Card title='Physical symptoms' form={forms.physical} />
    </Content>
  );
};
