import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Main = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const Header = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  top: 0,
  position: 'sticky',
  borderBottom: `1px solid ${theme.palette.primary.gray10}`,
  [theme.breakpoints.up('sm')]: {
    marginBottom: '20px',
  },
}));

export const Footer = styled(Box)({
  flex: '0 0 auto',
  bottom: 0,
  position: 'sticky',
});

export const Scrollable = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.white,
  overflow: 'auto',
}));

export const Content = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
}));
