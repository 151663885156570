import { useCreatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { useCreateNewAccountProfileMutation } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

export function useNoActiveProfile() {
  const [
    createNewAccountProfile,
    { isLoading: isLoadingCreateNewAccountProfile },
  ] = useCreateNewAccountProfileMutation();
  const [createPersonStudyArm, { isLoading: isLoadingCreatePersonStudyArm }] =
    useCreatePersonStudyArmMutation();

  const createPersonStudyArmRecord = (
    studyArmId,
    studyArmStatusId,
    siteId,
    studyArmRoleId,
    participantId
  ) => {
    const payload = {
      data: {
        type: 'person-study-arm',
        attributes: {
          person_id: Number(participantId),
          study_arm_id: studyArmId,
          study_arm_status_id: studyArmStatusId,
          site_id: siteId,
          study_arm_role_id: studyArmRoleId,
        },
      },
    };
    return createPersonStudyArm({
      personId: participantId,
      payload,
    });
  };

  const createNewAccountProfileRecord = (
    accountPersonStudyArmId,
    subjectPrimaryPersonStudyArmId
  ) => {
    const payload = {
      data: {
        type: 'account-profiles',
        attributes: {
          account_person_study_arm_id: Number(accountPersonStudyArmId),
          subject_primary_person_study_arm_id: Number(
            subjectPrimaryPersonStudyArmId
          ),
          subject_secondary_person_study_arm_id: null,
        },
      },
    };
    return createNewAccountProfile(payload);
  };

  return {
    createPersonStudyArmRecord,
    createNewAccountProfileRecord,
    isLoading:
      isLoadingCreatePersonStudyArm || isLoadingCreateNewAccountProfile,
  };
}
