import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { ReactComponent as ArrowLeft } from 'images/shared/chevron-left-2.svg';


export const StatusChangePageWrapper = styled('div')(({ theme }) => ({
  padding: '50px',
  [theme.breakpoints.down('sm')]: {
    padding:'0px'
  },
}));

export const MyProfilePageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '15px',
  padding: '0px',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.white,
    padding: '25px',
  },
}));


export const StyledBox = styled(Box)(({ theme, margin, padding }) => ({
  padding: padding ? padding : '40px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  margin,
  [theme.breakpoints.down('sm')]: {
    padding: padding ? padding : '20px',
    backgroundColor: theme.palette.primary.gray20
  },
}));

export const Title = styled(Typography)(({ theme, margin }) => ({
  color: theme.palette.primary.darkGray70,
  lineHeight: '145%' ,
  margin: '0px 0px 10px'
}));

export const Button = styled(FilledButton)({
  width: '100%',
  marginTop: '40px',
  padding: '15px 50px',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '0.28px'
});

export const StyledBackArrow = styled(ArrowLeft)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '18px',
    height: '20px',
    color: theme.palette.primary.darkBlue70
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
    fontWeight: '600',
    lineHeight: '145%'
  },
}));



