import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ihp-components/components/v2/Button';

import { ReactComponent as FilterIcon } from 'images/shared/filter-1.svg';
import { ReactComponent as AppliedFilterIcon } from 'images/shared/applied-filter.svg';
import { FilterMobileButton } from '../../pages/EducationalMaterials/style';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const FiltersToggleButton = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { label, handleOnClick, hasAppliedFilters } = props;
  const iconSize = isMobile ? {height: 26.5, width: 27} : {height: 14, width: 14};
  const icon = hasAppliedFilters
    ? <AppliedFilterIcon height={26.5} width={27}/>
    : <FilterIcon height={iconSize.height} width={iconSize.width}/>;
  return (
    <>
      {
        isMobile && <FilterMobileButton onClick={handleOnClick}>
          {icon}
      </FilterMobileButton>
      }
      {
        !isMobile && <Button size='sm' variant='link' onClick={handleOnClick}>
          {icon}
          {label}
      </Button>
    }
  </>
  );
};

FiltersToggleButton.defaultProps = {
  label: 'FILTERS',
  hasAppliedFilters: false,
  handleOnClick: () => {},
};

FiltersToggleButton.propTypes = {
  label: PropTypes.string,
  hasAppliedFilters: PropTypes.bool,
  handleOnClick: PropTypes.func,
};
