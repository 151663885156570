import * as React from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, useTheme } from '@mui/material';

import { ErrorWrapper } from 'configUI/components';
import { OptionItem } from './OptionItem';
import { StyledPopper } from './styles';
import getStyles from './index.styles';
import { ReactComponent as ChevronDown } from '../../assets/svg/chevron-down.svg';

export const SelectAutoComplete = ({
  hasErrors,
  error,
  errorLabel,
  placeholder,
  options,
  inputRef,
}) => {
  const theme = useTheme();

  return (
    <ErrorWrapper error={error} errorLabel={errorLabel}>
      <Autocomplete
        disablePortal
        id='autocompleteId'
        haserrors={hasErrors?.toString()}
        PopperComponent={StyledPopper}
        options={options}
        popupIcon={<ChevronDown />}
        renderInput={(params) => (
          <TextField
            sx={getStyles(theme, hasErrors || error)}
            placeholder={placeholder}
            variant='outlined'
            {...params}
          />
        )}
        renderOption={OptionItem}
        ref={inputRef}
      />
    </ErrorWrapper>
  );
};

SelectAutoComplete.propTypes = {
  hasErrors: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

/* Example usage

const mockOptions = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
];

<SelectAutoComplete
  hasErrors={false}
  placeholder={'Movie'}
  options={mockOptions}
/>;
*/
