import { Box, styled } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as Logo } from 'images/shared/trash-3.svg';

export const FieldContainer = styled(Box)({
  paddingTop: '20px',
  position: 'relative',
  '& .MuiButton-startIcon': {
    marginRight: '0px',
  },
});

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: '-webkit-box',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: 24,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  '& .MuiButton-startIcon': {
    marginRight: '0px',
  },
}));

export const ReporterContainer = styled(Box)(({ theme }) => ({
  padding: '16px',
  borderRadius: '5px',
  marginTop: '15px',
  marginBottom: '15px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px',
  },
}));

export const NameHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '16px',
  color: theme.palette.primary.darkGray100,
  fontfamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
}));

export const AlternativeReporterFullNameContainer = styled(Box)(
  ({ theme }) => ({
    fontFamily: 'Inter',
    color: theme.palette.primary.darkGray100,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '140%',
    height: 'max-content',
  })
);
export const FlexedBox = styled(Box)(() => ({
  display: 'flex',
}));

export const RelationContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.8px',
  marginLeft: '8px',
  fontStyle: 'normal',
  height: 'max-content',
}));

export const EmailContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.8px',
  fontStyle: 'normal',
  marginTop: '12px',
  marginLeft: '8px',
  height: 'max-content',
}));

export const PhoneContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  color: theme.palette.primary.darkGray100,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16.8px',
  fontStyle: 'normal',
  marginTop: '12px',
  marginLeft: '8px',
  height: 'max-content',
}));

export const EditDeleteButton = styled(Button)(({ theme, styles }) => ({
  border: '2px solid',
  padding: '12px 20px',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '140%',
  letterSpacing: '0.28px',
  ...styles,
}));

export const TrashIcon = styled(Logo)(({ theme }) => ({
  width: '14px',
  height: '14px',
  path: {
    fill: theme.palette.primary.main,
  },
}));
