import { Box, styled, Typography } from '@mui/material';

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontSize: '14px',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
}));

export const RadioWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
  '& .MuiRadio-root': {
    width: '100%',
    '& .StyledLabel': {
      textAlign: 'left',
    },
  },
});

export const FieldContainer = styled(Box)(({ theme }) => ({
  width: '340px',
  position: 'relative',
  '& .MuiInputBase-input': {
    paddingRight: '40px !important',
  },
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: 25,
});
