import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: '',
};

const setHipaaPageContentReducer = (state, action) => {
  const { content } = action.payload;
  state.content = content;
};

export const slice = createSlice({
  name: 'hipaaPage',
  initialState,
  reducers: {
    setHipaaPageContent: setHipaaPageContentReducer
  },
});

export const { setHipaaPageContent } = slice.actions;

export default slice;
