import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { updateLastOpenedConsent } from 'ihp-bloom-redux/features/consent/consentSlice';
import { useSubmitWithdrawalMutation } from 'ihp-bloom-redux/features/study/studyApi';
import { participantTaskApiSlice } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import { ErrorPage } from 'components/ErrorPage';
import { Loader } from 'components/Loader';
import { ConsentItem, EmptyConsents } from 'components/Profile';
import {
  useActiveConsents,
  usePastConsents,
} from 'pages/Profile/Consents/List/hooks';
import { useOpenSignedConsent, useRejectConsent } from 'utils/consents';
import { mapTaskRequiredStatus } from 'utils/tasks';
import { Header } from './Header';
import RejectConsentModal from './RejectConsentModal';
import { Content, PageWrapper } from './styles';
import { setConsentContext } from 'ihp-bloom-redux/features/user/userSlice';
import { setSelectedParticipantTaskId } from 'ihp-bloom-redux/features/tasks/tasksSlice';

const ConsentsList = () => {
  document.title = 'Single Ventricle SOURCE My Consents';
  const dispatch = useDispatch();
  const { lastOpenedConsent } = useSelector((state) => state.consentSlice);
  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const [consent, setConsent] = useState(null);
  const [isRedirecting, setRedirecting] = useState(false);
  const [selectedConsentCategory, setSelectedConsentCategory] = useState('');
  const navigate = useNavigate();
  const [trigger, result] =
    participantTaskApiSlice.endpoints.getProfileParticipantTask.useLazyQuery();
  const [rejectConsent, isRejecting] = useRejectConsent();
  const [openSignedConsent, isOpening, documentNotFound] =
    useOpenSignedConsent();
  const [submitWithdrawal, { isLoading: processingWithdrawl }] =
    useSubmitWithdrawalMutation();
  const [searchParams] = useSearchParams();

  const isRejectedConsentIsRequired =
    mapTaskRequiredStatus(lastOpenedConsent?.required) === 'REQUIRED';
  let isRejectedFromDocusign =
    searchParams.get('docusignStatus') === 'rejected';
  const [showModal, setShowModal] = useState(
    isRejectedFromDocusign && isRejectedConsentIsRequired
  );

  const activeConsents = useActiveConsents();
  const pastConsents = usePastConsents();

  const areConsentsLoading = activeConsents.isLoading || pastConsents.isLoading;
  const isFetchingConsentDetails = result.isFetching;

  const isLoading =
    areConsentsLoading ||
    isRedirecting ||
    isRejecting ||
    isOpening ||
    isFetchingConsentDetails;

  const setConsentHandler = (consent) => {
    setConsent(consent);
    trigger({
      accountProfileId: activeAccountProfile?.id,
      participantTaskId: consent.participantTaskId,
    });
  };

  const handlePastConsent = () => {
    setSelectedConsentCategory('PAST');
  };

  // const navigateToConsentDetails = useCallback(() => {
  //   setRedirecting(true);
  //   const consentDetails = result?.data?.data;
  //   const isDocusign = !!consentDetails?.attributes?.docusign_url;
  //   const redirectURl = consentDetails?.attributes?.docusign_url;
  //   const baseURL = '/onboarding/participant-consents';

  //   if (isDocusign && redirectURl) {
  //     window.location.replace(redirectURl);
  //   } else {
  //     navigate(`${baseURL}/${consent.id}/${consent.consentVersion}`);
  //   }
  // }, [consent, result, navigate]);

  useEffect(() => {
    if (!areConsentsLoading) {
      setSelectedConsentCategory(
        activeConsents.data.length > 0 ? 'ACTIONABLE' : 'PAST'
      );
    }
  }, [activeConsents.data.length, areConsentsLoading]);

  // useEffect to open DocuSign
  useEffect(() => {
    const envelopeUrl =
      result?.data?.data?.attributes?.['participant-task']?.['envelope_url'];

    const participantTaskId = result?.data?.data?.id;
    // Prevent to use cached envelope url while refetching
    if (result.status !== 'pending' && envelopeUrl && participantTaskId) {
      setRedirecting(true);
      dispatch(
        setSelectedParticipantTaskId({
          taskId: participantTaskId,
        })
      );
      dispatch(updateLastOpenedConsent(consent));
      dispatch(setConsentContext('profile'));
      window.location.href = envelopeUrl;
    }
  }, [consent, dispatch, result]);

  if (isLoading) {
    return <Loader />;
  }

  const handleDocusignReject = async (value) => {
    if (processingWithdrawl) {
      return;
    }
    const requestBody = {
      personId: person?.id,
      payload: {
        study_id: 1,
        reason: value,
      },
    };

    const response = await submitWithdrawal(requestBody);
    if (response.errors) return;
    navigate('/rejected', { state: 'profile-consents', replace: true }); // adding replace because on browsers back user should not come back to this reject modal screen
  };

  const sortedConsents = (consents) => {
    return consents.sort((consent) => {
      return consent.mandatory ? 1 : -1;
    });
  };

  const renderConsents = (consents, error, loading) => {
    return (
      <Content>
        {error && !loading && (
          <ErrorPage
            errorMessage={error?.data?.error}
            errorCode={error?.status}
            hideBackToHomeBtn={true}
          />
        )}
        {loading && !error && <Loader />}
        {!consents?.length && !loading && !error && (
          <EmptyConsents
            tab={selectedConsentCategory}
            handlePastConsent={handlePastConsent}
          />
        )}
        {!loading &&
          !error &&
          sortedConsents(consents)?.map((consent) => (
            <Fragment key={`${consent?.id}${consent?.consentVersion}`}>
              <ConsentItem
                consent={consent}
                setConsent={setConsentHandler}
                onRejectConsent={() => rejectConsent(consent?.consentVersion)}
                onOpenSignedConsent={(...args) =>
                  openSignedConsent(consent.personId, ...args)
                }
                tab={selectedConsentCategory}
                handlePastConsent={handlePastConsent}
              />
              {documentNotFound && (
                <div>
                  The consent preview is not available. Please try again later.
                </div>
              )}
            </Fragment>
          ))}
      </Content>
    );
  };

  return (
    <PageWrapper>
      <RejectConsentModal
        person={person}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        backToConsent={setConsentHandler}
        isSubmitting={processingWithdrawl}
        onSubmit={(value) => handleDocusignReject(value)}
      />
      {!isLoading && (
        <Header
          selectedConsentCategory={selectedConsentCategory}
          setSelectedConsentCategory={setSelectedConsentCategory}
        />
      )}
      {selectedConsentCategory === 'ACTIONABLE' &&
        renderConsents(
          activeConsents.data,
          activeConsents.isError,
          activeConsents.isLoading
        )}

      {selectedConsentCategory === 'PAST' &&
        renderConsents(
          pastConsents.data,
          pastConsents.isError,
          pastConsents.isLoading
        )}
    </PageWrapper>
  );
};

export default ConsentsList;
