import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  borderRadius: '8px',
  boxShadow: 'none',
  boxSizing: 'border-box',

  '&.MuiButton-containedPrimary': {
    '&: hover': {
      backgroundColor: theme.palette.primary.fuchsia90,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.fuchsia25,
    },
  },

  '&.MuiButton-outlinedPrimary': {
    border: `2px solid ${theme.palette.primary.main}`,
    '&: hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.fuchsia90,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
      borderColor: theme.palette.primary.fuchsia90,
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.fuchsia25,
      borderColor: theme.palette.primary.fuchsia25,
    },
  },

  '&.MuiButton-textPrimary': {
    '&: hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.fuchsia90,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.fuchsia25,
    },
  },

  '&.MuiButton-containedSecondary': {
    color: theme.palette.primary.white,
    '&: hover': {
      backgroundColor: theme.palette.primary.gray75,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.gray25,
    },
  },

  '&.MuiButton-outlinedSecondary': {
    color: theme.palette.primary.gray75,
    border: `2px solid ${theme.palette.primary.gray75}`,
    '&: hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.gray75,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
      borderColor: theme.palette.primary.gray75,
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.gray25,
      borderColor: theme.palette.primary.gray25,
    },
  },

  '&.MuiButton-textSecondary': {
    color: theme.palette.primary.gray75,
    '&: hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.gray75,
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.gray25,
    },
  },

  '&.MuiButton-containedTertiary': {
    color: theme.palette.primary.gray75,
    '&: hover': {
      color: theme.palette.primary.gray100,
      backgroundColor: theme.palette.primary.gray15,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.gray25,
      backgroundColor: theme.palette.primary.gray10,
    },
  },

  '&.MuiButton-outlinedTertiary': {
    color: theme.palette.primary.gray100,
    border: `1px solid ${theme.palette.primary.gray25}`,
    '&: hover': {
      color: theme.palette.primary.gray100,
      backgroundColor: theme.palette.primary.gray15,
      boxShadow: 'none',
      borderColor: theme.palette.primary.gray15,
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.gray25,
      backgroundColor: theme.palette.primary.gray10,
      borderColor: theme.palette.primary.gray10,
    },
  },

  '&.MuiButton-textTertiary': {
    color: theme.palette.primary.gray100,
    '&: hover': {
      color: theme.palette.primary.gray100,
      backgroundColor: theme.palette.primary.gray15,
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.primary.gray25,
      backgroundColor: theme.palette.primary.gray10,
    },
  },
}));
