import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const consentApi = createApi({
  reducerPath: 'consentApi',
  baseQuery: baseQuery('v2'),
  tagTypes: ['Consents'],
  endpoints: (build) => ({
    getConsentsListV1: build.query({
      query: (params) => ({
        url: '/entity/list',
        method: 'POST',
        body: {
          entity_name: 'consents',
          params,
        },
      }),
      providesTags: ['Consents'],
    }),
    getConsentDetails: build.query({
      query: (params) => ({
        url: '/entity/details',
        method: 'POST',
        body: {
          entity_name: 'consents',
          params,
        },
      }),
      providesTags: ['Consents'],
    }),
    fetchConsentDetails: build.mutation({
      query: (params) => ({
        url: '/entity/details',
        method: 'POST',
        body: {
          entity_name: 'consents',
          params,
        },
      }),
    }),
    submitConsent: build.mutation({
      query: (params) => ({
        url: '/entity/store',
        method: 'POST',
        body: {
          entity_name: 'consents',
          params,
        },
      }),
      invalidatesTags: ['Consents', 'CurrentUser'],
    }),
    getConsentDocument: build.mutation({
      query: ({ person_id, consent_version_id }) => ({
        url: `person/${person_id}/documents/consent_version/${consent_version_id}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useGetConsentsListQuery,
  useGetConsentsListV1Query,
  useGetConsentDetailsQuery,
  useFetchConsentDetailsMutation,
  useFetchConsentDetailsV3Query,
  useSubmitConsentMutation,
  useGetConsentDocumentMutation,
} = consentApi;
