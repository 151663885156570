import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activities: {
    ids: ['001', '002', '003','004', '005'],
    entities: {
      '001': {
        id: '001',
        type: 'survey',
        title: 'Quality of Life Questionnaire',
        description: `This survey helps us understand the impact of underactive
                  thyroid on your quality of life.`,
        rewardPoints: 100,
        howTo: `<div style="font-family: Inter; font-style: normal; line-height: 140%; color: #444444">
        <p style="font-size: 13px; font-weight: 700; color: #C080B1; margin: 0;">
          SURVEY OVERVIEW
        </p>
        <p style='font-size: 20px; font-weight: 600; margin: 7px 0'>
          How to complete this task successfully?
        </p>
        <p style='font-size: 14px; font-weight: 400; margin: 15px 0'>
          Please give honest answers on the questions, with that we will know
          how to continue your journey in the BLOOM Study.
        </p>
        </div>`,
        celebrationTitle: 'Thank you for completing the Quality of Life Questionnaire.',
        payload: {
          questions: [
            [
              {
                id: '0',
                header: 'How many meals you have per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '2 meals per day',
                    option_b: '3 meals per day',
                    option_c: 'More than 3 meals per day',
                  },
                  defaultValue: 'option_a',
                },
              },
              {
                id: '1',
                header: 'How much water do you drink per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '1 liter',
                    option_b: '2 liters',
                    option_c: 'More than 2 liters',
                  },
                  defaultValue: 'option_a',
                },
              },
            ],
            [
              {
                id: '2',
                header: 'How many steps do you make per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '2,000 steps',
                    option_b: '5,000 steps',
                    option_c: 'More than 10,000 steps',
                  },
                  defaultValue: 'option_a',
                },
              },
            ],
            [
              {
                id: '3',
                header: `Do you have any chronic conditions that will stop you to have
                       a better Quality of Life?`,
                type: 'TextArea',
                content: {
                  rows: 7,
                  placeholder: 'Type...',
                },
              },
            ]
          ]
        },
        icon: '',
        iconBGColor: '#A2D697',
        durationText: '10 mins',
        remainingTime: '2 days left',
        rewardColor: '#A0438B',
        rewardBGColor: '#F8F2F7',
        actionButtonText: 'Take a survey',
        actionButtonBGColor: '#A0438B',
        bgColor: '#ffffff',
      },
      '002': {
        id: '002',
        type: 'game',
        title: 'How to protect yourself from COVID-19',
        description: `Do you know how to protect yourself from COVID-19? Play this
                  game to test your knowledge and earn points`,
        rewardPoints: 50,
        howTo: `<div style="font-family: Inter; font-style: normal; line-height: 140%; color: #444444">
        <p style="font-size: 13px; font-weight: 700; color: #C080B1; margin: 0;">
          SURVEY OVERVIEW
        </p>
        <p style='font-size: 20px; font-weight: 600; margin: 7px 0'>
          How to complete this task successfully?
        </p>
        <p style='font-size: 14px; font-weight: 400; margin: 15px 0'>
          Please give honest answers on the questions, with that we will know
          how to continue your journey in the BLOOM Study.
        </p>
        </div>`,
        celebrationTitle: 'Thank you for completing the Quality of Life Questionnaire.',
        payload: {
          questions: [
            [
              {
                id: '0',
                header: 'How many meals you have per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '2 meals per day',
                    option_b: '3 meals per day',
                    option_c: 'More than 3 meals per day',
                  },
                  defaultValue: 'option_a',
                },
              },
              {
                id: '1',
                header: 'How much water do you drink per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '1 liter',
                    option_b: '2 liters',
                    option_c: 'More than 2 liters',
                  },
                  defaultValue: 'option_a',
                },
              },
            ],
            [
              {
                id: '2',
                header: 'How many steps do you make per day?',
                type: 'Radio',
                content: {
                  options: {
                    option_a: '2,000 steps',
                    option_b: '5,000 steps',
                    option_c: 'More than 10,000 steps',
                  },
                  defaultValue: 'option_a',
                },
              },
            ],
            [
              {
                id: '3',
                header: `Do you have any chronic conditions that will stop you to have
                       a better Quality of Life?`,
                type: 'TextArea',
                content: {
                  rows: 7,
                  placeholder: 'Type...',
                },
              },
            ]
          ]
        },
        icon: '',
        iconBGColor: '#FDC48C',
        durationText: '5 mins',
        remainingTime: '1 days left',
        rewardPoints: 50,
        rewardColor: '#A0438B',
        rewardBGColor: '#F8F2F7',
        actionButtonText: 'Play game',
        actionButtonBGColor: '#A0438B',
        bgColor: '#ffffff',
      },
      '003': {
        id: '003',
        type: 'video',
        title: 'Watch a short educational video about maintaining brain health',
        description: `This video from the World Health Organization shows how to
          find a mask to fit your face best.`,
        videoSrc: 'https://www.w3schools.com/html/mov_bbb.mp4',
        rewardPoints: 100,
        howTo: `<div style="font-family: Inter; font-style: normal; line-height: 140%; color: #444444">
          <p style="font-size: 13px; font-weight: 700; color: #C080B1; margin: 0;">
            INSTRUCTIONS
          </p>
          <p style='font-size: 20px; font-weight: 600; margin: 7px 0'>
            How to complete this task successfully?
          </p>
          <p style='font-size: 14px; font-weight: 400; margin: 15px 0'>
            This is an educational video task that will share more information
            about the BLOOM Study so please watch it carefully.
          </p>
        </div>`,
        celebrationTitle: 'Well done! You answered the question correctly',
        payload: {
          questions: [
            {
              id: '0',
              header: 'What did you saw on 35th second of video?',
              type: 'Radio',
              content: {
                options: {
                  option_a: 'Vaccine',
                  option_b: 'Set of medications',
                  option_c: 'Laboratory',
                },
                defaultValue: 'option_a',
              },
            },
          ]
        },
        icon: '',
        iconBGColor: '#F6D895',
        durationText: '5 mins',
        remainingTime: '1 days left',
        rewardColor: '#A0438B',
        rewardBGColor: '#F8F2F7',
        actionButtonText: 'Watch video',
        actionButtonColor: '#ffffff',
        actionButtonBGColor: '#A0438B',
        bgColor: '#ffffff'
      },
      '004': {
        id: '004',
        type: 'teleResearch',
        title: 'Schedule a follow-up call',
        description: `Please schedule your next call with one of our Nurse
          Practitioners`,
        celebrationTitle: 'You successfully scheduled a follow up call',
        payload: {
          questions: [
            {
              header: 'Select a day',
              subHeader:
                'Select the day you would like to have your follow-up call.',
              type: 'Datepicker',
              content: {
                defaultValue: '19-01-1994',
              },
            },
            {
              header: 'Select a time for',
              subHeader:
                'Select the time of day for your follow-up call. Calls will last approximately 30 minutes.',
              type: "Radio",
              content: {
                options: {
                  time_10: "10:00 AM",
                  time_11: "11:00 AM",
                  time_12: "12:00 AM",
                  time_13: "01:00 PM",
                  time_14: "02:00 PM",
                  time_15: "03:00 PM",
                  time_16: "04:00 PM",
                },
                defaultValue: "time_10",
              },
            },
          ]
        },
        icon: '',
        iconBGColor: '#80AAC2',
        durationText: '5 mins',
        remainingTime: '1 days left',
        rewardPoints: 20,
        rewardColor: '#A0438B',
        rewardBGColor: '#F8F2F7',
        actionButtonText: 'Schedule call',
        actionButtonColor: '#ffffff',
        actionButtonBGColor: '#A0438B',
        bgColor: '#ffffff'
      },
      '005': {
        id: '005',
        type: 'makeCall',
        title: 'TeleHealth call: Friday, 1PM',
        description: `Complete your scheduled call with our Nurse Practitioner and
          collect your reward`,
        rewardPoints: 20,
        icon: '',
        iconBGColor: '#80AAC2',
        durationText: '10 mins',
        remainingTime: '1 days left',
        rewardColor: '#A0438B',
        rewardBGColor: '#F8F2F7',
        actionButtonText: 'Make a call',
        actionButtonColor: '#ffffff',
        actionButtonBGColor: '#A0438B',
        bgColor: '#ffffff'
      }
    }
  },
  completedActivities: {
    '001': {
      id: '001',
      answers: {
        '01': 'option_a',
        '02': 'This is a text'
      }
    }
  }
}

export const slice = createSlice({
  name: 'activities',
  initialState,
  reducers: {}
});

export const { } = slice.actions;

export const selectAllActivities = () => (state) =>  Object.values(state[slice.name].activities.entities);
export const selectActivitybyId = (id) => (state) => state[slice.name].activities.entities[id];

export default slice;