import {
  useCreatePersonStudyArmSubRoleMutation,
  useDeletePersonStudyArmSubRoleMutation,
} from 'ihp-bloom-redux/features/personStudyArmSubRole/personStudyArmSubRoleApiSlice';

export const useStudyContributors = () => {
  const [
    createPersonStudyArmSubRole,
    { isLoading: isLoadingCreatePersonStudyArmSubRole },
  ] = useCreatePersonStudyArmSubRoleMutation();

  const [
    deletePersonStudyArmSubRole,
    { isLoading: isLoadingDeletePersonStudyArmSubRole },
  ] = useDeletePersonStudyArmSubRoleMutation();

  // Create sub role record
  const createPersonStudyArmSubRoleRecord = async (
    personStudyArmId,
    studyArmSubRoleId
  ) => {
    try {
      const payload = {
        data: {
          type: 'person-study-arm-sub-roles',
          attributes: {
            study_arm_sub_role_id: studyArmSubRoleId,
            person_study_arm_id: personStudyArmId,
          },
        },
      };
      const response = await createPersonStudyArmSubRole(payload);
      if (response?.error) {
        console.log('Error: ', response);
        return;
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  // Delete sub role record
  const deletePersonStudyArmSubRoleRecord = async ({
    personStudyArmId,
    selectedIds,
  }) => {
    try {
      const payload = {
        ids: selectedIds,
      };
      const response = await deletePersonStudyArmSubRole({
        personStudyArmId,
        payload,
      });

      if (response?.error) {
        console.log('Error: ', response);
        return;
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    createPersonStudyArmSubRoleRecord,
    deletePersonStudyArmSubRoleRecord,
    isLoading:
      isLoadingCreatePersonStudyArmSubRole ||
      isLoadingDeletePersonStudyArmSubRole,
  };
};
