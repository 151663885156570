import { Box, styled } from '@mui/material';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as CircleIcon } from './circle.svg';

export const BlueTick = styled(Tick)(({ selected, theme }) => ({
  minWidth: '15px',
  width: '15px',
  '> path': {
    fill: theme.palette.primary.darkBlue90,
  },
}));

export const CheckContainer = styled(Box)(({ selected, theme }) => ({
  alignItems: 'center',
  backgroundColor: selected ? theme.palette.primary.darkblue70 : 'transparent',
  border: `1px solid ${theme.palette.primary.gray3}`,
  borderRadius: '8px',
  display: 'flex',
  height: '30px',
  justifyContent: 'center',
  minHeight: '30px',
  minWidth: '30px',
  width: '30px',
}));

export const Circle = styled(CircleIcon)(() => ({
  minWidth: '15px',
  width: '15px',
}));

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const Options = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

const Option = styled(Box)(({ disabled, theme }) => ({
  ...theme.typography.pl3,
  alignItems: 'center',
  border: `1px solid`,
  borderRadius: '8px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  padding: '10px 15px',
  textWrap: 'pretty',
}));

export const RadioOption = styled(Option)(({ selected, theme }) => ({
  borderColor: selected
    ? theme.palette.primary.darkblue70
    : theme.palette.primary.gray3,
  color: selected ? theme.palette.primary.darkBlue90 : 'rgba(68, 68, 68, .8)',
  fontWeight: selected ? 700 : 500,
}));

export const TickOption = styled(Option)(({ selected, theme }) => ({
  backgroundColor: selected ? theme.palette.primary.gray5 : 'transparent',
  borderColor: theme.palette.primary.gray3,
  color: `rgba(68, 68, 68, .8) !important`,
}));
