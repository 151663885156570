import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import {
  OnboardingContainer,
  Logo,
  ContentWrapper,
  ContinueButton,
  BottomContainer,
  FinalMessage,
  ThanksMessage,
  FinalIconContainer,
} from 'components/Onboarding';
import { ReactComponent as Completed } from 'images/shared/done-checkmark.svg';
import { ReactComponent as ArrowForwardIcon } from 'images/shared/arrow-right-2.svg';

export default function Final() {
  document.title = 'Single Ventricle SOURCE You Are All Set';
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/my-activities');
  };

  return (
    <OnboardingContainer>
      <Logo />
      <ContentWrapper>
        <Stack>
          <FinalIconContainer>
            <Completed width={200} height={198} />
          </FinalIconContainer>
          <ThanksMessage>You are all set!</ThanksMessage>
          <FinalMessage>
            Continue to the app and begin your study journey!
          </FinalMessage>
        </Stack>
        <BottomContainer>
          <ContinueButton
            variant='contained'
            onClick={redirect}
            endIcon={<ArrowForwardIcon />}
          >
            Go to app
          </ContinueButton>
        </BottomContainer>
      </ContentWrapper>
    </OnboardingContainer>
  );
}
