import React from 'react';

import { ReactComponent as SurveyBadge } from 'images/profile/achievements/survey.svg';
import { ReactComponent as SurveyFilledBadge } from 'images/profile/achievements/survey-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';

import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
  MyPoints,
} from '../styles.mobile.js';

export const MobileSurveyCompletedCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#F7E4E4' : '#ffffff'}>
      {!inProgress ? <SurveyBadge /> : <SurveyFilledBadge />}
      <MobileCardInfoBox>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 3 Surveys completed
        </Title>
        <StyledSlider
          value={50}
          isCompleted={isCompleted}
          backgroundColor='#EB5757'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Wellness champion'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
