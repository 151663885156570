import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Modal } from '@mui/material';

import {
  useSendOtpMutation,
  useVerifyOtpMutation,
} from 'ihp-bloom-redux/features/notification';

import Button from 'ihp-components/components/v2/Button';
import { ModalContainer } from 'ihp-components/components/Study/modal';

import { ReactComponent as VerifyPhoneNumber } from 'images/profile/communication/verify-phone.svg';
import { ReactComponent as PhoneNumberVerified } from 'images/profile/communication/phone-verified.svg';
import { ReactComponent as InvalidCode } from 'images/profile/communication/invalid-code.svg';

import {
  CodeContainer,
  getStyles,
  HeaderContainer,
  LogoAndTitleContainer,
  MainContainer,
  PhoneNumberVerificationContainer,
  SMSNotice,
  Title,
  VerificationButtonsContainer,
  VerificationCodeContainer,
  VerificationLinkButton,
} from './style';

function PhoneNumberVerificationModal({ isOpen, setIsOpenModal, onClose }) {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [showForm, setShowForm] = useState(true);
  const [showVerifiedScreen, setShowVerifiedScreen] = useState(false);
  const [showInvalidScreen, setShowInvalidScreen] = useState(false);
  const [showMaxAttempt, setShowMaxAttempt] = useState(false);

  const inputRefs = useRef([]);

  const theme = useTheme();
  const styles = getStyles(theme);

  const [sendOtp, { isLoading: isSendingOTP }] = useSendOtpMutation();
  const [verifyOtp, { isLoading: isVerifyingOTP }] = useVerifyOtpMutation();

  const handleInputChange = (index, value) => {
    if (isNaN(value)) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    if (index < otp.length - 1 && value !== '') {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleInputBackspace = (index, event) => {
    if (index > 0 && event.key === 'Backspace' && otp[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const isSubmitDisabled = otp.some((digit) => digit === '');

  const handleResendCode = async () => {
    const response = await sendOtp();
    if (
      response?.error?.data?.error.includes('many attempts') ||
      response?.error?.data?.error.includes('Phone number already verified')
    ) {
      setShowForm(false);
      setShowMaxAttempt(true);
    }
  };

  const handleVerifyOTP = async () => {
    const otpDigitsOnly = otp.filter((digit) => !isNaN(digit)).join('');

    const response = await verifyOtp({
      payload: {
        otp_code: otpDigitsOnly,
      },
    });

    if (response?.error) {
      setShowForm(false);
      setShowInvalidScreen(true);
    } else {
      setShowForm(false);
      setShowVerifiedScreen(true);
    }
  };

  const handleTryAgain = () => {
    setOtp(['', '', '', '', '', '']);
    setShowInvalidScreen(false);
    setShowForm(true);
  };

  const handleGoBack = () => {
    onClose(showVerifiedScreen);
  };

  useEffect(() => {
    async function callSendOTP() {
      await sendOtp();
    }
    if (showForm) {
      callSendOTP();
    }
  }, [showForm]);

  const phoneNumberVerificationScreen = (
    <>
      <HeaderContainer>
        <LogoAndTitleContainer>
          <VerifyPhoneNumber />
          <Title>Verify your phone number</Title>
        </LogoAndTitleContainer>
        <SMSNotice>
          We sent you an SMS with a code. <br />
          Please enter the code below.
        </SMSNotice>
      </HeaderContainer>
      <VerificationCodeContainer>
        <CodeContainer>
          {otp.map((digit, index) => (
            <input
              style={styles.otpInput}
              key={index}
              type='text'
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyUp={(e) => handleInputBackspace(index, e)}
              maxLength='1'
              ref={(ref) => (inputRefs.current[index] = ref)}
            />
          ))}
        </CodeContainer>
        <Button
          sx={styles.resendCodeButton}
          onClick={handleResendCode}
          loading={isSendingOTP}
          fullWidth
        >
          Resend code
        </Button>
      </VerificationCodeContainer>
      <VerificationButtonsContainer>
        <Button
          sx={styles.verifyButton}
          onClick={handleVerifyOTP}
          disabled={isSubmitDisabled}
          loading={isVerifyingOTP}
          fullWidth
        >
          Verify
        </Button>
        <VerificationLinkButton variant='link' onClick={handleGoBack}>
          Go back
        </VerificationLinkButton>
      </VerificationButtonsContainer>
    </>
  );

  const phoneNumberVerifiedScreen = (
    <>
      <HeaderContainer>
        <LogoAndTitleContainer>
          <PhoneNumberVerified />
          <Title>Phone number verified</Title>
        </LogoAndTitleContainer>
        <SMSNotice>
          You can opt out of SMS notifications at any time from your profile
          settings.
        </SMSNotice>
      </HeaderContainer>
      <VerificationButtonsContainer>
        <Button sx={styles.verifyButton} onClick={handleGoBack} fullWidth>
          Done
        </Button>
      </VerificationButtonsContainer>
    </>
  );

  const phoneVerificationInvalidScreen = (
    <>
      <HeaderContainer>
        <LogoAndTitleContainer>
          <InvalidCode />
          <Title>Invalid code!</Title>
        </LogoAndTitleContainer>
      </HeaderContainer>
      <VerificationButtonsContainer>
        <Button sx={styles.verifyButton} onClick={handleTryAgain} fullWidth>
          Try again
        </Button>
      </VerificationButtonsContainer>
    </>
  );

  const phoneVerificationMaxAttemptScreen = (
    <>
      <HeaderContainer>
        <LogoAndTitleContainer>
          <InvalidCode />
          <Title>Max attempts exceeded</Title>
        </LogoAndTitleContainer>
        <SMSNotice>
          Sorry, you have exceeded the maximum number of attempts to resend the
          code for phone verification. Please wait for a few minutes before
          trying again.
        </SMSNotice>
      </HeaderContainer>
      <VerificationButtonsContainer>
        <Button sx={styles.verifyButton} onClick={handleGoBack} fullWidth>
          Done
        </Button>
      </VerificationButtonsContainer>
    </>
  );

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
        onClose(showVerifiedScreen);
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContainer>
        <MainContainer>
          <PhoneNumberVerificationContainer>
            {showForm && phoneNumberVerificationScreen}
            {showVerifiedScreen && phoneNumberVerifiedScreen}
            {showInvalidScreen && phoneVerificationInvalidScreen}
            {showMaxAttempt && phoneVerificationMaxAttemptScreen}
          </PhoneNumberVerificationContainer>
        </MainContainer>
      </ModalContainer>
    </Modal>
  );
}

export default PhoneNumberVerificationModal;
