import { Stack } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';

import { EditToolbarContainer, MenuButton, TextArea } from './styles';
import { stringifyJson, useHandleCopy, useHandleSaveJson } from './utils';

const SubmitToolbar = ({ onSubmit }) => (
  <Stack direction='row' justifyContent='flex-end' spacing='10px'>
    <Button width='250px' onClick={() => onSubmit(true)}>
      Start over with default values
    </Button>
    <Button width='130px' onClick={() => onSubmit(false)}>
      Start over
    </Button>
  </Stack>
);

const EditToolbar = ({ value }) => {
  const handleSave = useHandleSaveJson('result', value);
  const handleCopy = useHandleCopy(value);
  return (
    <EditToolbarContainer>
      <MenuButton onClick={handleSave}>Save</MenuButton>
      <MenuButton onClick={handleCopy}>Copy</MenuButton>
    </EditToolbarContainer>
  );
};

const Result = ({ value, onSubmit }) => {
  return (
    <Stack padding='20px' spacing='20px'>
      <Stack>
        <EditToolbar value={value} />
        <TextArea
          placeholder='Submission result...'
          defaultValue={stringifyJson(value)}
        />
        <EditToolbar value={value} />
      </Stack>
      <SubmitToolbar onSubmit={onSubmit} />
    </Stack>
  );
};

export default Result;
