import { styled, Card, Chip, Slider } from '@mui/material';
import CheckIcon from 'images/profile/check-white.svg';

export const CardWrapper = styled(Card)(({ theme, backgroundColor }) => ({
  width: '270px',
  height: '272px',
  background: backgroundColor,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '40px 30px 30px',
  position: 'relative',
}));

export const StyledChip = styled(Chip)(({ theme, isCompleted }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
  background: !isCompleted
    ? theme.palette.primary.gray5
    : theme.palette.primary.white,
  borderRadius: '4px',
  margin: '0 2.5px',
}));

export const RewardContainer = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.primary.gray75,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '5px',
}));

export const Title = styled('div')(({ theme, inProgress }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '24px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: !inProgress
    ? theme.palette.primary.gray75
    : theme.palette.primary.gray100,
  margin: '0px',
  textAlign: 'center',
}));

export const StyledSlider = styled(Slider)(
  ({ theme, isCompleted, backgroundColor }) => ({
    '& .MuiSlider-thumb': {
      height: '18px',
      width: '18px',
      color: '#ffffff',
      backgroundColor: backgroundColor,
      backgroundImage: `url(${CheckIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '10px 13px',
      display: `${isCompleted ? 'flex' : 'none'}`,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      backgroundColor: theme.palette.primary.gray10,
      height: '8px',
    },
    '& .MuiSlider-track': {
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
      height: '8px',
    },
  })
);

export const Info = styled('div')({
  width: '100%',
  textAlign: 'center',
});

export const MyPoints = styled('span')(({ theme }) => ({
  color: theme.palette.primary.gray100,
}));

export const IsCompletedMark = styled('div')(({ theme, backgroundColor }) => ({
  width: '27px',
  height: '27px',
  backgroundColor: backgroundColor,
  backgroundImage: `url(${CheckIcon})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '15px 19px',
  borderRadius: '60px',
  position: 'absolute',
  top: '16px',
  left: '16px',
  boxShadow: '0px 1.875px 5.625px rgba(255, 255, 255, 0.15)',
  opacity: '0.5',
}));
