import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { BaseHeader, BaseLayout } from 'components/BaseLayout';
import { IconButton } from '@mui/material';
import { isMobile } from 'utils/isMobile';

export const StyledBaseLayout = styled(BaseLayout)(
  ({ theme, data, boxShadow = true, background }) => {
    return {
      height: '100%',
      width: '100%',
      maxWidth: '850px',
      margin: 'auto',
      boxSizing: 'border-box',

      [theme.breakpoints.up('sm')]: {
        padding: '50px',
        backgroundColor: theme.palette.primary.gray20,
        // Let BaseLayout grow
        '& > .BaseLayout-scrollable': {
          maxHeight: data.length === 0 ? '40%' : '100%',
          backgroundColor: background ?? theme.palette.primary.white,
          boxShadow: boxShadow ? '0px 0px 9px rgba(0, 0, 0, 0.04)' : '',
          borderRadius: '8px',
        },
        // Remove sticky header behavior
        '& > .BaseLayout-header': {
          position: 'initial',
        },
      },
    };
  }
);

export const Line = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '1px',
    backgroundColor: theme.palette.primary.gray10,
  },
}));

export const Separator = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: '1px',
    backgroundColor: theme.palette.primary.fuchsia5,
  },
}));

export const Spacer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    height: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    height: '5px',
    backgroundColor: theme.palette.primary.gray2,
  },
}));

export const OverviewContainer = styled((props) => (
  <Stack spacing='25px' {...props} />
))(({ theme }) => ({
  padding: '20px 0px 25px 0px',
}));

export const SubHeaderContainer = styled(Box)(({ theme }) => ({
  padding: '24px 24px 0px',
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.white,
    boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
    borderRadius: '8px',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    color: theme.palette.primary.gray50,
    padding: isMobile() ? 0 : 10,
    border: isMobile() ? '' : '1px solid ' + theme.palette.primary.gray25,
    borderRadius: isMobile() ? '0px' : '8px',
    backgroundColor: isMobile() ? '' : theme.palette.primary.white,
  };
});

export const PaymentContentContainer = styled(Box)(({ theme }) => ({
  background: '#F8F8F8',
  overflow: 'auto',
  alignItems: 'center',
  height: '100%',
  marginTop: '15px',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}));

export const PaymentListWrapper = styled(Box)({
  backgroundColor: '#ffffff',
  borderRadius: '10px',
});

export const Icon = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});

export const FlexedBox = styled(Box)(
  ({
    gap = '12px',
    justifyContent = 'center',
    padding = '0px',
    flexDirection = 'row',
  }) => ({
    display: 'flex',
    gap,
    justifyContent,
    flexDirection,
    alignItems: 'center',
    padding,
  })
);

export const IconContainer = styled(Box)({
  marginLeft: '10px',
});

export const PaymentItemWrapper = styled(Box)(({ backgroundColor }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  padding: '15px 20px',
  backgroundColor,
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  background: 'transparent',
  paddingLeft: '20px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    padding: '0',
  },
}));

const FontBase = styled(Typography)({
  fontStyle: 'normal',
  fontWeight: 'normal',
  lineHeight: '1.5rem',
});

export const Title = styled(FontBase)(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  color: theme.palette.primary.gray100,
  lineHeight: '20px',
}));

export const Count = styled(FontBase)(({ theme }) => ({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  marginTop: '-3px',
  color: theme.palette.primary.gray50,
}));

export const Value = styled(FontBase)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.primary.gray75,
}));

export const TransactionValue = styled('div')(
  ({ theme, color, backgroundColor }) => ({
    minWidth: '54px',
    width: 'auto',
    height: '30px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color,
    backgroundColor,
    display: 'flex',
    padding: '5px 7px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '7px',
    borderRadius: '5px',
    svg: {
      width: '14px',
      height: '20px',
    },
  })
);

export const Balance = styled('div')(
  ({ theme, backgroundColor = theme.palette.primary.gray5 }) => ({
    minWidth: '60px',
    height: '30px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.palette.primary.orange100,
    backgroundColor,
    display: 'flex',
    padding: '5px 7px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '7px',
    borderRadius: '5px',
    svg: {
      width: '14px',
      height: '20px',
    },
  })
);

export const Dot = styled('div')(({ theme }) => ({
  width: '4px',
  height: '4px',
  fontSize: '12px',
  display: 'block',
  backgroundColor: theme.palette.primary.gray50,
  borderRadius: '100px',
}));

export const PaymentPageWrapper = styled('div')(({ theme }) => ({
  maxWidth: '850px',
  width: '100%',
  margin: '50px auto',
  [theme.breakpoints.down('sm')]: {
    wudth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
  },
}));

export const PaymentHistoryTitle = styled(FontBase)(({ theme }) => ({
  fontSize: '24px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  color: theme.palette.primary.gray100,
}));

export const RewardsLink = styled('a')(({ theme }) => ({
  fontSize: '12px',
  fontWeight: '400',
  color: theme.palette.primary.gray75,
}));

export const PaymentHeaderContent = styled(Box)(({ theme }) => ({
  padding: '0px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px 24px',
  },
}));

export const PaymentPageTitle = styled(BaseHeader)({
  width: '100%',
  padding: '0px',
});
