import { EnrollParticipantModal } from 'features/enrollParticipantModal';
import { FamilyLinkageGuard } from 'features/familyLinkage';
import { useState } from 'react';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { STUDY_ARM_CODES } from 'constants/global';

export function HomePageWrapper({ children }) {
  const [isFamilyModalClosed, setIsFamilyModalClosed] = useState(false);

  const { activeAccountProfile } = useSelector(
    (state) => state.user
  );

  const studyArmCode =
    activeAccountProfile?.attributes?.profile?.study_arm_code;
    
  const researchAllftdStudyArm =
    studyArmCode === STUDY_ARM_CODES.RESEARCH ||
    studyArmCode === STUDY_ARM_CODES.ALLFTD;

  return (
    <>
      {children}
      <FamilyLinkageGuard setIsFamilyModalClosed={setIsFamilyModalClosed} />
      {researchAllftdStudyArm && (
        <EnrollParticipantModal isFamilyModalClosed={isFamilyModalClosed} />
      )}
    </>
  );
}
