export const getStyles = (theme) => ({
  modalContainer: {
    width: '405px',
    background: theme.palette.primary.white,
    boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
    borderRadius: '8px',
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -40%)',
    boxSizing: 'border-box',
    textAlign: 'center',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '327px',
      margin: 'auto',
      padding: '0px',
    },
  },
  infoBox: {
    padding: '0px 15px 30px',
  },
  info: {
    color: theme.palette.secondary.gray4,
    textAlign: 'center',
    marginBottom: '15px',
  },
  subInfo: {
    color: theme.palette.secondary.gray4,
    textAlign: 'center',
  },
  celebrationScreenContainer: {
    margin: 'auto',
  },
});
