import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import getStyles from './FormCheckbox.styles';
import { UnCheckedboxIcon } from './styles';
import { ReactComponent as CheckedboxIcon } from '../../assets/svg/checkedBox.svg';
import { ErrorWrapper } from '../ErrorWrapper';

export const FormCheckbox = forwardRef(
  ({ fieldLabel, label, containerStyle, error, errorLabel, ...rest }, ref) => {
    const theme = useTheme();
    const styles = getStyles(theme, error);

    return (
      <ErrorWrapper error={error} errorLabel={errorLabel}>
        {fieldLabel && (
          <FormLabel sx={styles.formLabel}>{fieldLabel}</FormLabel>
        )}
        <FormGroup sx={[styles.formGroup, containerStyle]}>
          <FormControlLabel
            sx={styles.formControlLabel}
            size='large'
            label={label}
            control={
              <Checkbox
                ref={rest.inputRef ?? ref}
                icon={<UnCheckedboxIcon fill='primary.darkBlue90'/>}
                checkedIcon={<CheckedboxIcon fill='primary.darkBlue90' width="30px" height="30px"/>}
                {...rest}
              />
            }
          />
        </FormGroup>
      </ErrorWrapper>
    );
  }
);

FormCheckbox.propTypes = {
  fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
