import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { useNavigate } from 'react-router-dom';
import BodyContainer from 'components/InformedConsent/BodyContainer';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Logo } from 'images/av-logo-vertical.svg';
import getStyles from './styles/index.styles';
import { data } from 'mocks/informedconsent';

function InformedConsentMobile() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [consentAgreed, setConsentAgreed] = useState(false);

  let navigate = useNavigate();

  const navigateToStudyOverview = () => {
    navigate('/final');
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='center'
      sx={{
        backgroundColor: theme.palette.primary.fuchsia,
      }}
    >
      <Grid item xs={12} sx={styles.logoContainer}>
        <Logo width='85px' />
      </Grid>
      <Grid item xs={12} sx={styles.mainContainerMob}>
        <BodyContainer
          title='INFORMED CONSENT'
          data={data}
          consentAgreed={consentAgreed}
          onToggle={() => setConsentAgreed(!consentAgreed)}
        />
      </Grid>
      <Grid item xs={12} sx={styles.stickyFooter}>
        <Button
          fullWidth={true}
          onClick={navigateToStudyOverview}
          disabled={!consentAgreed}
        >
          Accept Consent
        </Button>
      </Grid>
    </Grid>
  );
}
export default InformedConsentMobile;
