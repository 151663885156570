import React from 'react';

import { ReactComponent as PointsBadge } from 'images/profile/achievements/points.svg';
import { ReactComponent as PointsFilledBadge } from 'images/profile/achievements/points-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';

import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
  MyPoints,
} from '../styles.mobile.js';

export const MobilePointsEarnedCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#D7F5E4' : '#ffffff'}>
      {!inProgress ? <PointsBadge /> : <PointsFilledBadge />}
      <MobileCardInfoBox>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 1,000 Points earned
        </Title>
        <StyledSlider
          value={100}
          isCompleted={isCompleted}
          backgroundColor='#6FCF97'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Happy camper'
            isCompleted={isCompleted}
          />
          <StyledChip label='$10' isCompleted={isCompleted} />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
