import PropTypes from 'prop-types';
import { StyledListItem, ListItemLogo, ListItemTextSection } from './styles';
import { Typography } from '@mui/material';
import { ReactComponent as ArrowForward } from 'images/providers/arrow-forward.svg';


export const ListItem = ({ title, description, icon, alt, handleClick }) => {
  return (
    <StyledListItem onClick={handleClick}>
      <div>
        <ListItemLogo>
          <img src={icon} alt={alt} />
        </ListItemLogo>
        <ListItemTextSection>
          <div className='title'>
            <Typography variant='pb3' align='center'>
              {title}
            </Typography>
          </div>
          <div className='description'>
            <Typography variant='pl5' align='center'>
              {description}
            </Typography>
          </div>
        </ListItemTextSection>
      </div>
      <div>
        <ArrowForward />
      </div>
    </StyledListItem>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  alt: PropTypes.string,
  handleClick: PropTypes.func
};

