export const videoScreensMap = {
  howTo: 0,
  video: 1,
  celebration: 2,
};

export const selectFromPatientTaskResult = ({ data, ...rest }) => {
  const attributes = {};
  data?.data.task.attributes.forEach((attribute) => {
    if (
      attribute.attribute === 'duration' ||
      attribute.attribute === 'howTo' ||
      attribute.attribute === 'meta' ||
      attribute.attribute === 'celebrationScreen'
    ) {
      attributes[attribute.attribute] = {
        ...attribute,
        value: attribute.value ? JSON.parse(attribute.value) : attribute.value,
      };
    } else {
      attributes[attribute.attribute] = { ...attribute };
    }
  });
  // mocking following two fields will remove when api will send response
  attributes.content = {
    attribute: 'content',
    value: `
      <p><span style="color:#444444"><span style="font-size:14px">Magna non feugiat fermentum non. Porta elit orci, varius nunc elementum lectus. Gravida fermentum risus et arcu, aliquet accumsan tempor.</span></span></p>
      <ul>
        <li><span style="color:#444444"><span style="font-size:14px">Porta elit orci, varius nunc elementum lectus;</span></span></li>
        <li><span style="color:#444444"><span style="font-size:14px">Gravida fermentum risus et arcu;</span></span></li>
        <li><span style="color:#444444"><span style="font-size:14px">Aliquet accumsan tempor.</span></span></li>
      </ul>
      <p><span style="color:#444444"><span style="font-size:14px">Purus, pellentesque cursus phasellus eget pretium porttitor maecenas.</span></span></p>
    `,
  };

  attributes.video = {
    attribute: 'video',
    value: 'https://www.w3schools.com/html/mov_bbb.mp4',
  };

  return {
    participantTaskDetails: {
      ...data?.data,
      task: {
        ...data?.data?.task,
        ...attributes,
      },
    },
    ...rest,
  };
};
