import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray10,
  borderRadius: '8px',
  height: '24px',
  boxSizing: 'border-box',
  padding: '0 12px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));
