import {
  Box,
  MenuItem,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import MaskedInput from 'react-input-mask';
import getStyles from './styles';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { Loader } from 'components/Loader';
import { USStates } from 'pages/User/AlternativeContact/mocks/us-states';
import { useValidatePhone } from 'pages/User/hooks';
import {
  useCreateManualProviderMutation,
  useUpdateManualProviderMutation,
  useDeleteManualProviderMutation,
} from 'ihp-bloom-redux/features/oneUpHealth/manualProvidersApi';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { ReactComponent as PhoneIcon } from 'images/profile/phone.svg';
import {
  addInProgressProvider,
  updateLastConnectedProvider,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { participantTask } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

export const ManualProviderModal = ({
  open,
  onClose,
  onCancel,
  participantTaskId,
  addManualProvider,
  removeManualProvider,
  provider,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const [createManualProvider] = useCreateManualProviderMutation();
  const [updateManualProvider] = useUpdateManualProviderMutation();
  const [deleteManualProvider] = useDeleteManualProviderMutation();
  const selectedParticipantTask = useSelector(participantTask());
  const [isLoading, setIsLoading] = useState(false);
  const isUpdate = provider != null;
  const dispatch = useDispatch();

  const { activeAccountProfile, person } = useSelector((state) => state.user);
  const person_id =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id ||
    selectedParticipantTask?.personId;

  const [currentManualProvider] = useState({
    provider_name: provider?.provider_name ?? '',
    phone_number: provider?.phone_number ?? '',
    city: provider?.city ?? '',
    state: provider?.state ?? '',
  });

  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      provider_name: currentManualProvider?.provider_name ?? '',
      phone_number: currentManualProvider?.phone_number ?? '',
      city: currentManualProvider?.city ?? '',
      state: currentManualProvider?.state ?? '',
    },
  });
  const onRemoveProvider = async (id) => {
    setIsLoading(true);

    await deleteManualProvider({
      participantId: person_id,
      providerId: provider.id,
    })
      .then((response) => {
        removeManualProvider(provider);
        return onClose();
      })
      .catch(console.error);

    setIsLoading(false);
    return onClose();
  };

  const onSubmit = async (data) => {
    let taskId = participantTaskId ?? selectedParticipantTask?.id;
    if (taskId === undefined || taskId === null) {
      alert('Provider cannot be added without participant task id.');
      return;
    }
    setIsLoading(true);
    let payload = {
      data: {
        type: 'person-providers',
        attributes: {
          participant_task_id: Number(taskId),
          provider_info: {
            hc_organization_name: data.provider_name,
            city: data.city,
            state: data.state,
            phone_number: data.phone_number,
          },
        },
      },
    };
    if (isUpdate) {
      payload = { data: { ...payload.data, id: provider.id.toString() } };
      await updateManualProvider({
        participantId: person_id,
        providerId: provider.id,
        payload,
      })
        .then((response) => {
          const data = response?.data?.data;
          let result = {
            id: Number(data?.id),
            name: data.attributes.provider_info.hc_organization_name,
            phone_number: data.attributes.provider_info.phone_number,
            logo: 'manualProvider',
            locations: [
              {
                address: {
                  city: data.attributes?.provider_info.city,
                  state: data.attributes?.provider_info.state,
                },
              },
            ],
            participant_id: person_id,
          };
          addManualProvider(result, provider.id);
          return onClose();
        })
        .catch(console.error);
      return onClose();
    } else {
      // CREATE
      await createManualProvider({ participantId: person_id, payload })
        .then((response) => {
          const data = response?.data?.data;
          let result = {
            id: Number(data?.id),
            name: data.attributes.provider_info.hc_organization_name,
            phone_number: data.attributes.provider_info.phone_number,
            logo: 'manualProvider',
            locations: [
              {
                address: {
                  city: data.attributes?.provider_info.city,
                  state: data.attributes?.provider_info.state,
                },
              },
            ],
            participant_id: person_id,
          };
          addManualProvider(result);
          // to enable finish data button
          dispatch(
            addInProgressProvider({ ...result, logo: 'manualProvider' })
          );
          dispatch(
            updateLastConnectedProvider({
              ...result,
              connectedAt: new Date(),
              isCheckStateTransitioned: false,
            })
          );
          return onClose();
        })
        .catch(console.error);
    }

    onClose();
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={styles.modalContainer}
      >
        <Box sx={{ ...styles.modalWrapper, height: '618px' }}>
          <Loader />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        reset();
        onClose && onClose();
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={styles.modalContainer}
    >
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.titleWrapper}>
          <Typography variant='h2'>Healthcare provider details</Typography>
          <Box sx={styles.iconWrapper}>
            <XIcon
              width='12px'
              height='18px'
              color={theme.palette.primary.white}
              onClick={(e) => onCancel()}
            />
          </Box>
        </Box>
        <form>
          <Stack display={'flex'} gap='25px' p={'30px'}>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='provider_name'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    size='sm'
                    label='Healthcare organization name'
                    type='text'
                    error={errors.provider_name}
                    errorLabel={errors.provider_name?.message}
                    value={currentManualProvider.provider_name}
                    fullWidth={true}
                    placeholder='Enter healthcare organization name'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phone_number'
                control={control}
                rules={{
                  validate: validatePhone,
                  required: true,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='Enter phone number'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        size='sm'
                        name='phone_number'
                        label='Phone number'
                        error={errors.phone_number}
                        errorLabel={
                          errors?.phone_number?.message ||
                          'This field is required'
                        }
                        fullWidth
                        endIcon={<PhoneIcon />}
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>

            <Box sx={{ display: 'flex', width: '100%', gap: '20px' }}>
              <Box sx={styles.cityFieldContainer}>
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      size='sm'
                      name='city'
                      error={errors.city}
                      label='City'
                      fullWidth={true}
                      placeholder='Enter city'
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={styles.stateFieldContainer}>
              <Controller
                name='state'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type='select'
                    error={errors.state}
                    errorLabel={errors.state?.message}
                    label='Select state'
                    fieldLabel='State'
                    placeholder='Select state'
                    fullWidth
                    sx={styles.selectInput}
                    {...field}
                  >
                    {USStates?.map(({ name, abbreviation }) => (
                      <MenuItem key={abbreviation} value={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>
          </Stack>
        </form>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.continueButton}>
            <Button sx={{ width: '119px' }} onClick={handleSubmit(onSubmit)}>
              {isUpdate ? 'Save' : 'Continue'}
            </Button>
          </Box>
          <Box sx={{ marginTop: '10px' }}>
            <Button
              variant='link'
              onClick={() => {
                reset({
                  provider_name: '',
                  phone_number: '',
                  city: '',
                  state: '',
                });
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Box>
          {isUpdate && (
            <Box sx={{ marginTop: '10px', marginRight: '-30px' }}>
              <Button variant='link' onClick={onRemoveProvider}>
                Remove
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
