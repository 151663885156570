import { Box, MenuItem, Modal, useTheme } from '@mui/material';
import { ContinueButton } from 'components/Onboarding';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { USStates } from 'pages/User/AlternativeContact/mocks/us-states';
import { useValidatePhone } from 'pages/User/hooks';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import getStyles from './styles';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

export const EditDoctorsModal = ({
  open,
  onClose,
  provider,
  onUpdateProvider,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: provider?.id ?? 0,
      provider_name: provider?.provider_name ?? '',
      phone_number: provider?.phone_number ?? '',
      city: provider?.city ?? '',
      state: provider?.state ?? '',
    },
  });

  const onSubmit = async (data) => {
    onUpdateProvider(data);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>Edit healthcare provider</Box>
          <Box sx={styles.iconWrapper}>
            <XIcon
              width='12px'
              height='18px'
              color='#888888'
              onClick={(e) => onClose()}
            />
          </Box>
        </Box>
        <Box sx={styles.formWrapper}>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='provider_name'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.provider_name}
                render={({ field }) => (
                  <Input
                    name='provider_name'
                    label="Healthcare provider's name"
                    error={errors.provider_name}
                    errorLabel={errors.provider_name?.message}
                    value={provider.provider_name}
                    fullWidth={true}
                    placeholder={"Enter healthcare organization's name"}
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phone_number'
                control={control}
                rules={{
                  validate: validatePhone,
                  required: true,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='Enter phone number'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        name='phone_number'
                        error={errors.phone_number}
                        errorLabel={
                          errors?.phone_number?.message ||
                          'This field is required'
                        }
                        label='Phone number'
                        fullWidth
                        sx={styles.input}
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='city'
                control={control}
                rules={{
                  required: true,
                }}
                haserrors={errors.city}
                render={({ field }) => (
                  <Input
                    name='city'
                    label='City'
                    error={errors.city}
                    errorLabel={errors.city?.message}
                    fullWidth={true}
                    placeholder='Enter city'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='state'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    type='select'
                    error={errors.state}
                    errorLabel={errors.state?.message}
                    label='Select state'
                    fieldLabel='State'
                    placeholder='Select state'
                    fullWidth
                    sx={styles.selectInput}
                    {...field}
                  >
                    {USStates?.map(({ name, abbreviation }) => (
                      <MenuItem key={abbreviation} value={abbreviation}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>
          </form>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.continueButton}>
            <ContinueButton
              sx={styles.continueButton}
              onClick={handleSubmit(onSubmit)}
            >
              Save Contact
            </ContinueButton>
            <Button
              variant='link'
              sx={styles.cancelLink}
              onClick={(e) => onClose()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
