import {styled} from "@mui/material/styles";
import { Typography} from "@mui/material";

export const LeadText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray100,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '140%',
    textAlign: 'left'
}));

export const HelperText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray50,
    fontSize: '12px',
    textAlign: 'left',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%'
}));

export const StudyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.darkGray50,
    fontSize: '12px',
    textAlign: 'left',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%'
}));