import { ReactComponent as AlertDiamondIcon } from 'images/shared/alert-diamond.svg';

import { InCompleteProfileWrapper, InCompleteProfileText } from './styles';

function InCompleteProfile() {
  return (
    <InCompleteProfileWrapper>
      <AlertDiamondIcon color='#C42EA5' />
      <InCompleteProfileText>profile incomplete</InCompleteProfileText>
    </InCompleteProfileWrapper>
  );
}

export default InCompleteProfile;
