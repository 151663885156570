import { isValidElement, useEffect, useRef, useState } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

import { Loader } from 'components/Loader';
import { useLogout } from 'utils/useLogout';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_STATUS_CODES,
} from 'constants/global';
import { ProfileNotFoundModal } from 'components/ProfileNotFoundModal';

export const withSelectedProfile =
  (Component, should_fetch_profiles = true) =>
  (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logout = useLogout();
    const [searchParams] = useSearchParams();
    const profileId = searchParams.get('profile_id');

    const { activeAccountProfile, accountProfiles } = useSelector(
      (state) => state.user
    );
    const { configuration } = useSelector((state) => state.configuration);

    const [isLoadingAccountProfiles, setIsLoadingAccountProfiles] =
      useState(false);
    const [isLoadingAccountProfile, setIsLoadingAccountProfile] =
      useState(false);
    const [error, setError] = useState(false);

    const contactWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.CONTACT_WITHDRAWN
      ]?.id;
    const researchWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_WITHDRAWN
      ]?.id;
    const allftdWithdrawn =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.ALLFTD_WITHDRAWN
      ]?.id;
    const researchReporterRevoked =
      configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
        STUDY_ARM_STATUS_CODES.RESEARCH_REPORTER_REVOKED
      ]?.id;

    const inActiveStatuses = [
      contactWithdrawn,
      researchWithdrawn,
      allftdWithdrawn,
      researchReporterRevoked,
    ];

    const fetchAccountProfiles = async () => {
      const personId = props?.person?.id;
      try {
        setIsLoadingAccountProfiles(true);
        const accountProfilesResponse = await dispatch(
          accountProfileApiSlice.endpoints.getAccountProfiles.initiate(
            personId,
            {
              forceRefetch: true,
            }
          )
        );

        if (accountProfilesResponse?.data?.data && !accountProfiles?.length) {
          const activeProfiles = accountProfilesResponse?.data?.data?.filter(
            (profile) =>
              !inActiveStatuses?.includes(
                profile.attributes.study_arm_status_id
              )
          );
          const accountProfilesPayload = {
            accountProfiles: activeProfiles,
          };
          await dispatch(setValues(accountProfilesPayload));

          /* Make sure if `profile_id` query param is missing then in that case at least navigate user to select profile page */
          if (!profileId) {
            navigate('/select-profile');
          }
        }

        setIsLoadingAccountProfiles(false);

        if (accountProfilesResponse?.error) {
          setError(true);
        }
      } catch (error) {
        setIsLoadingAccountProfile(false);
        console.log('Error on fetching new profile');
      }
    };

    const fetchAccountProfileData = async (profileId) => {
      const id = Number(profileId);
      try {
        setIsLoadingAccountProfile(true);
        const accountProfileResponse = await dispatch(
          accountProfileApiSlice.endpoints.getAccountProfile.initiate(id, {
            forceRefetch: true,
          })
        );

        if (accountProfileResponse?.data?.data) {
          const { profile } = accountProfileResponse?.data?.data?.attributes;
          const statusId = profile?.study_arm_status_id;
          if (inActiveStatuses?.includes(statusId)) {
            navigate('/select-profile');
            return;
          }
          const activeAccountProfilePayload = {
            ...activeAccountProfile,
            activeAccountProfile: accountProfileResponse?.data?.data,
          };
          await dispatch(setValues(activeAccountProfilePayload));
        }

        setIsLoadingAccountProfile(false);

        if (accountProfileResponse?.error) {
          setError(true);
        }
      } catch (error) {
        setIsLoadingAccountProfile(false);
        console.log('Error on fetching new profile');
      }
    };

    useEffect(() => {
      /* Make sure to fetch profiles only if data is not already there */
      if (!accountProfiles?.length && should_fetch_profiles) {
        fetchAccountProfiles();
      }
      /* Make sure to fetch profile details only if profile id exists in query params */
      if (profileId) {
        fetchAccountProfileData(profileId);
      }
    }, []);

    if (isLoadingAccountProfile || isLoadingAccountProfiles) {
      return <Loader />;
    }

    if (error) {
      return (
        <ProfileNotFoundModal open={error} onClose={() => setError(false)} />
      );
    }

    const componentToRender = <Component {...props} />;
    return isValidElement(componentToRender) ? componentToRender : null;
  };
