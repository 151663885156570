import { Button } from '@mui/material';
import { Box, styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';
import { theme } from 'theme';
import { isMobile } from 'utils/isMobile';

export const ModalContainer = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    width: isMobile() ? '327px' : '704px',
    height: isMobile() ? '471px' : '416px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '0px 0px 30px 0px',
  };
});

export const Title = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: isMobile() ? '24px' : '20px',
  lineHeight: '29px',
  color: theme.palette.primary.darkGray100,
  margin: '34px 0px',
  padding: '0px 34px',
  width: isMobile() ? '300px' : '570px',
  marginLeft: '0px',
  textAlign: isMobile() ? 'center' : 'left',
}));

export const IconWrapper = styled(Box)(() => ({
  cursor: 'pointer',
  marginTop: '35px',
  marginRight: '30px',
}));

export const LineBorder = styled(Box)(({ theme }) => ({
  display: 'absolute',
  width: '700px',
  borderBottom: '1px solid ' + theme.palette.primary.lightGray100,
}));

export const Description = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '14px',
  lineHeight: '19px',
  marginTop: isMobile() ? '0px' : '30px',
  marginLeft: isMobile() ? '-20px' : '-320px',
  color: theme.palette.secondary.gray4,
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '0px',
  marginRight: isMobile() ? '0px' : '-320px',
  flexDirection: isMobile() ? 'column' : 'row',
}));

export const ContinueButton = styled(FilledButton)(() => ({
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '20px',
  width: '325px',
  height: '56px',
  width: isMobile() ? '260px' : '99px',
  marginTop: isMobile() ? '30px' : '20px',
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  minWidth: 134,
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: 600,
  letterSpacing: '2%',
  color: theme.palette.primary.darkBlue100,
  height: '55px',
  marginTop: '20px',
  marginRight: isMobile() ? '-3px' : '15px',
}));

export const WarningDescription = styled(Box)(({ theme }) => ({
  margin: '30px 40px',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '19.6px',
  color: theme.palette.primary.gray100,
}));

export const WarningTitle = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '33px',
  color: theme.palette.primary.darkGray100,
  padding: '10px 34px',
  width: isMobile() ? '300px' : '570px',
  marginLeft: '-20px',
  marginTop: isMobile() ? '20px' : '0px',
  textAlign: isMobile() ? 'center' : 'left',
}));

export const WarningIconWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  margin: '5px 20px 30px 10px',
  color: theme.palette.primary.darkGray50,
  marginLeft: '70px',
  paddingTop: '10px',
}));

export const WarningText = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '19.6px',
  margin: '7px 0px 0px -14px',
  cursor: 'pointer',
  color: theme.palette.primary.gray100,
}));

export const CheckBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: isMobile() ? '0px' : '-370px',
  marginTop: '-15px',
}));
