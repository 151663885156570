import React from 'react';
import MUIDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';

const Drawer = ({ children, onClose, open, anchor = 'left' }) => {
  return (
    <MUIDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
    >
      {children}
    </MUIDrawer>
  )
};

Drawer.propTypes = {
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default Drawer;
