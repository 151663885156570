import { styled } from '@mui/system';
import { Typography } from '@mui/material';

export const StyledAccountInfoDiv = styled('div')(
  ({ theme, hideAccountInfo }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px 16px 4px 16px',
    borderRadius: '50px',
    border: `1px solid ${theme.palette.primary.black}`,
    visibility: hideAccountInfo && 'hidden',
    height: hideAccountInfo && 0,
    width: hideAccountInfo && 0,
  })
);

export const StyledTypography = styled(Typography)(({ theme, fontWeight }) => ({
  color: theme.palette.primary.black,
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: fontWeight || 400,
  lineHeight: '24px',
  letterSpacing: '2%',
}));

export const StyledStrong = styled('strong')({
  fontWeight: 600,
});
