import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  firstName: '', // 'John'
  lastName: '', // 'Doe'
  avatar: '',
  email: '', //'john.doe@test.com',
  dob: '', //'10.02.2000',
  gender: '',
  address: '', //'575 Market St',
  city: '', //'San Francisco',
  country: '', //'US',
  stateProvince: '', //'CA',
  zipCode: '', //'94105',
  timezone: '',
  language: '',
  studyIdentifier: 'study4',
  oneUpHealthUserId: '',
  oneUpHealthCode: '',
  oneUpHealthToken: '',
  oneUpHealthTokenExpTime: 0,
  hyperwalletToken: '',
  hyperwalletProgramToken: '',
  patientId: '',
  totalScore: 0,
  active_person_id: '',
  onboardingReward: [],
  consentContext: '',
  accountProfiles: [],
  person: {},
  personAttributes: {},
  authenticationStatus: false,
  userId: '',
  reporterInviteToken: null,
  isEnrollingNewPerson: false,
  activeAccountProfile: {
    type: 'profiles',
    id: null,
    attributes: {
      person: {},
      user: {},
      profile: {},
    },
  },
  selectedInterests: [], // We need to save users selected interests across pages
};

export const userSlice = createSlice({
  name: 'personalInformation',
  initialState,
  reducers: {
    setValues: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    },
    setName: (state, action) => {
      const { payload } = action;
      state.email = payload.email;
      state.firstName = payload.family_name;
      state.lastName = payload.given_name;
      state.avatar = payload.picture;
    },
    setBasicInformation: (state, action) => {
      action.payload.forEach((attribute) => {
        if (attribute.attributes.attribute === 'first_name') {
          state.firstName = attribute.attributes.value;
        }
        if (attribute.attributes.attribute === 'last_name') {
          state.lastName = attribute.attributes.value;
        }
      });
    },
    saveBasicInformation: (state, action) => {
      const { data } = action.payload;
      data.forEach((attribute) => {
        if (attribute.data.attributes.attribute === 'first_name') {
          state.firstName = attribute.data.attributes.value;
        }
        if (attribute.data.attributes.attribute === 'last_name') {
          state.lastName = attribute.data.attributes.value;
        }
      });
    },
    updateOneUpHealthUserId: (state, action) => {
      state.oneUpHealthUserId = action.payload;
    },
    updateOneUpHealthCode: (state, action) => {
      state.oneUpHealthCode = action.payload;
    },
    updateOneUpHealthToken: (state, action) => {
      state.oneUpHealthToken = action.payload;
    },
    updateOneUpHealthTokenExpTime: (state, action) => {
      state.oneUpHealthTokenExpTime = action.payload;
    },
    updateHyperwalletToken: (state, action) => {
      state.hyperwalletToken = action.payload;
    },
    updateHyperwalletProgramToken: (state, action) => {
      state.hyperwalletProgramToken = action.payload;
    },
    updatePatientId: (state, action) => {
      state.patientId = action.payload;
    },
    setTotalScore: (state, action) => {
      state.totalScore = action.payload;
    },
    updateTotalScore: (state, action) => {
      state.totalScore += action.payload;
    },
    setOnboardingReward: (state, action) => {
      state.onboardingReward = action.payload;
    },
    setConsentContext: (state, action) => {
      state.consentContext = action.payload;
    },
    setActiveProfileAttributes: (state, action) => {
      const { payload } = action;
      state.activeAccountProfile.attributes.profile = {
        ...state.activeAccountProfile.attributes.profile,
        ...payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setValues,
  setName,
  setBasicInformation,
  saveBasicInformation,
  updateOneUpHealthUserId,
  updateOneUpHealthCode,
  updateOneUpHealthToken,
  updateOneUpHealthTokenExpTime,
  updateHyperwalletToken,
  updateHyperwalletProgramToken,
  updatePatientId,
  setTotalScore,
  updateTotalScore,
  setOnboardingReward,
  setConsentContext,
  setActiveProfileAttributes,
} = userSlice.actions;

export default userSlice.reducer;
