import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const consentVersionApi = createApi({
  reducerPath: 'consentVersionApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Consents'],
  endpoints: (build) => ({
    updateConsentStatus: build.mutation({
      query: (params) => {
        return {
          url: `/people/${params.personId}/consent-versions/${params.consentVersionId}`,
          method: 'PATCH',
          body: JSON.stringify({
            data: {
              type: 'person-consent-versions',
              id: params.id,
              attributes: {
                consent_version_id: params.consentVersionId,
                signed_on_behalf_person_id: params.personId,
                consenting_party_person_id: params.personId,
                status: params.status,
                consented_at: params.date,
              },
            },
          }),
          headers: {
            'Content-type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
        };
      },
      invalidatesTags: ['Consents', 'CurrentUser'],
    }),
    getConsentVersions: build.mutation({
      query: ({ person_id }) => ({
        url: `people/${person_id}/consent-versions`,
        method: 'GET',
        // responseHandler: (response) => response.blob(),
        cache: 'no-cache',
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
    }),
  }),
});

export const { useUpdateConsentStatusMutation, useGetConsentVersionsMutation } =
  consentVersionApi;
