import { useState } from 'react';
import { CircularProgress } from '@mui/material';

import { StyledBox, StyledIframe, LoaderWrapper } from './styles';

export const FollowupVideoCall = ({ src }) => {
  const [loading, setLoading] = useState(true);
  const hideSpinner = () => setLoading(false);

  return (
    <StyledBox>
      {loading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
      <StyledIframe
        id='followup-video-call'
        title='Follow up video call'
        src={src}
        onLoad={hideSpinner}
      />
    </StyledBox>
  );
};
