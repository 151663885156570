import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import { ReactComponent as XIcon } from 'images/shared/x.svg';

export const FiltersLayoutWrapper = styled('div')({
  width: '380px',
  height: '100vh',
  padding: '40px',
  position: 'relative',
});

export const XButton = styled(IconButton)({
  padding: '0px',
});

export const StyledXIcon = styled(XIcon)({
  color: '#959CA0',
});

export const FiltersContent = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 200px)',
  marginTop: '30px',
  overflow: 'auto',

  /**
   * We have to do this to adjust height for mobile iPhone specific to adjust browsers url box height as well
   */
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 300px)',
  },
}));

export const Footer = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: '40px',
  bottom: '40px',
  width: '300px;',
  backgroundColor: theme.palette.primary.white,

  '& .MuiButton-link': {
    padding: '0px',
  },
}));
