import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as Alert } from 'images/profile/my-consents/alert-diamond.svg';
import { ReactComponent as Notification } from 'images/notification.svg';
import { ReactComponent as Circle } from 'images/shared/circle-dot.svg';

const Tag = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '10px',
  lineHeight: '140%',
  display: 'flex',
  alignItems: 'center',
  gap: '7px',
  boxSizing: 'border-box',
  padding: '0 9px',
  height: '24px',
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    height: '10px',
    padding: '0px',
  },
}));

export const TagStatus = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  color: '#7C7C7C',
  border: `1px solid #7C7C7C`,
  backgroundColor: theme.palette.primary.white,
}));

export const CircleDot = styled(Circle)({
  width: '4px',
  height: '6px',
  bottom: 2,
});

export const TextWrapper = styled('div')({
  marginTop: '10px',
  width: 'inherit',
  justifyContent: 'center',
});

export const ConsentInfo = styled('h2')(({ theme }) => ({
  fontWeight: 500,
  fontFamily: 'Inter',
  fontSize: 14,
  color: theme.palette.primary.darkGray90,
}));

export const TagRequired = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 10,
  color: '#4C88AB',
  border: `1px solid #4C88AB`,
  backgroundColor: theme.palette.primary.white,
}));

export const TagArchived = styled(Tag)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.gray75,
  backgroundColor: theme.palette.primary.white,
  border: '1px solid #D9D9D9',
}));

export const AlertIcon = styled(Alert)({
  '& path': {
    fill: '#4C88AB',
  },
});

export const NotificationIcon = styled(Notification)({
  '& path': {
    fill: '#4C88AB',
  },
});

export const getStyles = (theme) => {
  return {
    chipButton: {
      borderColor: theme.palette.primary.darkBlue70,
      color: theme.palette.primary.darkBlue70,
      height: '24px',
      top: '5px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    declineButton: {
      top: '5px',
      height: '24px',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  };
};
