import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const studyApiSlice = createApi({
  reducerPath: 'studyApi',
  baseQuery: baseQuery('v2'),
  tagTypes: ['StudyWithdrawal', 'StudyAttributes'],
  endpoints: (build) => ({
    submitWithdrawal: build.mutation({
      query: ({ personId, payload }) => ({
        url: `/persons/${personId}/withdraw`,
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json',
        },
      }),
      invalidatesTags: ['StudyWithdrawal'],
    }),
    getStudyAttributes: build.query({
      query: (studyId = 1) => ({
        url: `/study/${studyId}/attributes`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ', // this api is public so no token is needed
        },
      }),
      providesTags: ['StudyAttributes'],
    }),
    getStudyRoles: build.query({
      query: ({ studyId, studyVersionId }) => ({
        url: `/study/${studyId}/version/${studyVersionId}/study_roles`,
        method: 'GET',
      }),
      providesTags: ['StudyAttributes'],
    }),
  }),
});

export const {
  useSubmitWithdrawalMutation,
  useGetStudyAttributesQuery,
  useGetStudyRolesQuery,
} = studyApiSlice;
