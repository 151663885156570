import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  content: '',
};

const setConsentPageContentReducer = (state, action) => {
  const { content } = action.payload;
  state.content = content;
};

export const slice = createSlice({
  name: 'consentPage',
  initialState,
  reducers: {
    setConsentPageContent: setConsentPageContentReducer
  },
});

export const { setConsentPageContent } = slice.actions;

export default slice;
