import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const entityApiSlice = createApi({
  reducerPath: 'entityApiSlice',
  baseQuery: baseQuery(),
  tagTypes: ['Entity'],
  endpoints: (build) => ({
    studyRolesList: build.mutation({
      query: (data) => ({
        url: `/entity/list`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Entity'],
    }),
  }),
});

export const { useStudyRolesListMutation } = entityApiSlice;
