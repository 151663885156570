import { OverlayModal } from 'components/Modal';
import { Text } from './styles';
import { useEffect, useState } from 'react';
import { enrollModal } from './constants';
import { useEditPersonAttributesMutation } from 'ihp-bloom-redux/features/profile/profileAttributesApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils/navigator';

export const EnrollParticipantModal = ({ isFamilyModalClosed }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { person, personAttributes } = useSelector((state) => state.user);
  const [editPersonAttributes, { isLoading: isEditingPersonAttributes }] =
    useEditPersonAttributesMutation();

  const modalContent = <Text mb='20px'>{enrollModal.description}</Text>;

  const modalButtonsConfig = [
    {
      variant: '',
      text: enrollModal.buttonsText.enrollNow,
      handleClick: handleEnrollNow,
      disabled: isEditingPersonAttributes,
    },
    {
      variant: 'outlined',
      text: enrollModal.buttonsText.allAreEnrolled,
      handleClick: handleAllAreEnrolled,
      loading: isEditingPersonAttributes,
    },
    {
      variant: 'link',
      text: enrollModal.buttonsText.enrollLater,
      handleClick: handleEnrollLater,
      disabled: isEditingPersonAttributes,
    },
  ];

  useEffect(() => {
    if (
      personAttributes &&
      personAttributes?.participant_and_dependant_enrollment !== 'true'
    ) {
      setOpen(true);
    }
  }, [personAttributes]);

  function handleEnrollNow() {
    setOpen(false);
    dispatch(setValues({ isEnrollingNewPerson: true }));
    navigate(routes['ROLE-SELECTION']);
  }

  async function handleAllAreEnrolled() {
    try {
      await editPersonAttributes({
        personId: person?.id,
        payload: [
          {
            data: {
              type: 'person-attributes',
              attributes: {
                attribute: 'participant_and_dependant_enrollment',
                value: 'true',
              },
            },
          },
        ],
      });

      setOpen(false);
    } catch (error) {
      setOpen(false);
      console.error('All are enrolled', error);
    }
  }

  function handleEnrollLater() {
    setOpen(false);
  }

  return (
    isFamilyModalClosed && (
      <OverlayModal
        open={open}
        modalTitle={enrollModal.title}
        modalContent={modalContent}
        modalButtonsConfig={modalButtonsConfig}
      />
    )
  );
};
