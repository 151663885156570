import background from 'images/background.svg';

const getStyles = (theme) => {
  return {
    leftBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gridGap: 200,
      padding: '35px',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      height: '100vh',
    },
    leftContainer: {
      width: 400,
      height: 102,
      alignItems: 'center',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    logo: {
      alignSelf: 'flex-start',
    },
  };
};
export default getStyles;
