export const formatLabelText = (value, smallScreenCharCount,largeScreenCChartCount,lineBreak) => {
  const maxCharactersPerLine = window.screen.width < 600 ? smallScreenCharCount : largeScreenCChartCount;
  const words = value?.split(' ');
  let currentLine = '';
  const lines = [];
  for (const word of words) {
    if ((currentLine + word).length <= maxCharactersPerLine) {
      currentLine += (currentLine === '' ? '' : ' ') + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }
  if (currentLine !== '') {
    lines.push(currentLine);
  }
  return lines.join(`${lineBreak}`);
};
