import { Divider, Stack } from '@mui/material';
import { styled } from '@mui/system';

import { LinkButton } from 'components/Buttons';

import { Textarea } from 'configUI/components';

export const EditToolbarContainer = (props) => (
  <Stack
    direction='row'
    justifyContent='flex-end'
    spacing='15px'
    padding='5px'
    divider={<Divider orientation='vertical' flexItem />}
    {...props}
  />
);

export const MenuButton = styled(LinkButton)({
  height: '15px',
  '.MuiTypography-root': {
    fontSize: '11px',
  },
});

export const TextArea = (props) => (
  <Textarea
    sx={{
      fontFamily: 'monospace',
    }}
    wrapperStyle={{
      marginTop: '-8px',
    }}
    {...props}
  />
);
