import React from 'react';
import { styled } from '@mui/material';
import PropTypes from 'prop-types';

const TYPES = {
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
  info2: 'info2',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
};

const VARIANTS = {
  filled: 'filled',
  outlined: 'outlined',
  bordered: 'bordered',
  contained: 'contained',
};
const VARIANT_STYLES = {
  [VARIANTS.filled]: {
    border: 'none',
  },
  [VARIANTS.bordered]: {
    borderWidth: 1,
    borderStyle: 'solid',
    background: 'white',
  },
  [VARIANTS.outlined]: {
    borderWidth: 1,
    borderStyle: 'solid',
    background: 'transparent',
  },
  [VARIANTS.contained]: {
    border: 'none',
  },
};

const StyledChip = styled('div')(({ theme, variant, type, sx = {} }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '7px 10px 7px 10px',
  gap: '7px',
  borderRadius: '50px',
  fontWeight: 600,
  fontSize: '10px',
  textTransform: 'uppercase',
  lineHeight: '14px',
  letter: '1%',
  '& svg': {
    fill: 'currentColor',
  },

  // primary
  ...(type === TYPES.primary &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.darkBlue100,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),
  ...(type === TYPES.primary &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.darkBlue100,
    color: theme.palette.primary.darkBlue100,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),
  ...(type === TYPES.primary &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.darkBlue100,
    color: theme.palette.primary.darkBlue100,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),
  ...(type === TYPES.primary &&
    variant === VARIANTS.contained && {
    background: theme.palette.primary.darkBlue5,
    color: theme.palette.primary.darkBlue70,
    ...VARIANT_STYLES.contained,
    ...sx,
  }),

  // secondary
  ...(type === TYPES.secondary &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.darkBlue5,
    color: theme.palette.primary.magenta100,
    ...VARIANT_STYLES.filled,
    ...sx,
  }),

  ...(type === TYPES.secondary &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.magenta100,
    color: theme.palette.primary.magenta100,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),
  ...(type === TYPES.secondary &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.magenta100,
    color: theme.palette.primary.magenta100,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),

  // info
  ...(type === TYPES.info &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.darkGray70,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),
  ...(type === TYPES.info &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.darkGray70,
    color: theme.palette.primary.darkGray70,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),
  ...(type === TYPES.info &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.darkGray70,
    color: theme.palette.primary.darkGray70,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),

  // info2
  ...(type === TYPES.info2 &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.darkGray50,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),

  ...(type === TYPES.info2 &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.lightGray50,
    color: theme.palette.primary.lightGray50,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),

  ...(type === TYPES.info2 &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.lightGray50,
    color: theme.palette.primary.lightGray50,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),

  // success
  ...(type === TYPES.success &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.green100,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),

  ...(type === TYPES.success &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.green100,
    color: theme.palette.primary.green100,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),

  ...(type === TYPES.success &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.green100,
    color: theme.palette.primary.green100,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),

  // warning
  ...(type === TYPES.warning &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.orange100,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),

  ...(type === TYPES.warning &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.orange100,
    color: theme.palette.primary.orange100,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),

  ...(type === TYPES.warning &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.orange100,
    color: theme.palette.primary.orange100,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),

  // danger
  ...(type === TYPES.danger &&
    variant === VARIANTS.filled && {
    background: theme.palette.primary.red80,
    color: 'white',
    ...VARIANT_STYLES.filled,
    ...sx,
  }),

  ...(type === TYPES.danger &&
    variant === VARIANTS.outlined && {
    borderColor: theme.palette.primary.red80,
    color: theme.palette.primary.red80,
    ...VARIANT_STYLES.outlined,
    ...sx,
  }),

  ...(type === TYPES.danger &&
    variant === VARIANTS.bordered && {
    borderColor: theme.palette.primary.red80,
    color: theme.palette.primary.red80,
    ...VARIANT_STYLES.bordered,
    ...sx,
  }),
}));

const Chip = ({ theme, variant, type, text, icon, children, sx, ...rest }) => {
  return (
    <StyledChip theme={theme} variant={variant} type={type} sx={sx} {...rest}>
      {icon}
      <div>{children}</div>
    </StyledChip>
  );
};

Chip.propTypes = {
  variant: PropTypes.oneOf(Object.keys(VARIANTS)),
  type: PropTypes.oneOf(Object.keys(TYPES)),
  icon: PropTypes.node,
  title: PropTypes.string,
};

export default Chip;
