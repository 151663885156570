import { Box, InputLabel, styled, Typography } from '@mui/material';

export const getStyles = (theme, isMobile) => {
  return {
    helperTextContainer: {
      textAlign: 'center',
    },
    icon: {
      width: 90,
      height: 90,
    },
    saveIcon: {
      fontSize: 15,
    },
    formCheckBox: {
      backgroundColor: theme.palette.primary.white,
      color: theme.palette.primary.darkGray100,
      borderColor: theme.palette.primary.lightGray100,
    },
    formCheckBoxChecked: {
      backgroundColor: theme.palette.primary.gray5,
    },
    headingStyle: {
      fontSize: '18px',
      fontFamily: 'Inter',
      color: theme.palette.primary.gray60,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '140%',
      marginTop: isMobile ? '10px' : '20px',
      marginBottom: '10px',
    },
  };
};

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  gap: '30px',
  paddingTop: '15px',
}));
