import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import {
  OnboardingContainer,
  Logo,
  ContentWrapper,
  ContinueButton,
  BottomContainer,
  FinalMessage,
  ThanksMessage,
  FinalIconContainer,
} from 'components/Onboarding';
import { ReactComponent as Failed } from 'images/shared/fail-checkmark.svg';
import { ReactComponent as XMark } from 'images/health/hexagon-xmark.svg';

export const NotEligible = () => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate('/onboarding/welcome-to-study');
  };

  return (
    <OnboardingContainer>
      <Logo />
      <ContentWrapper>
        <Stack>
          <FinalIconContainer>
            <Failed width={200} height={198} />
          </FinalIconContainer>
          <ThanksMessage>Thank you for answering the questions.</ThanksMessage>
          <FinalMessage>
            We’ve determined that you are not eligible to continue with the
            onboarding for this study
          </FinalMessage>
        </Stack>
        <BottomContainer>
          <ContinueButton
            variant='contained'
            onClick={redirect}
            startIcon={<XMark />}
          >
            Exit
          </ContinueButton>
        </BottomContainer>
      </ContentWrapper>
    </OnboardingContainer>
  );
};
