import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { BaseHeader } from 'components/BaseLayout';
import Button from 'ihp-components/components/v2/Button';
import useScript from 'hooks/useScript';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import React, { useEffect, useState } from 'react';
import {
  getAuthToken,
  getEncodedCredentials,
  getTransferData,
  getTransferUrl,
} from 'services/hyperwalletService';
import { Line } from '../../Payment/styles';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import {
  ButtonsContainer,
  SpinnerContainer,
  TransferContainer,
} from './index.styles';
import { isMobile } from 'utils/isMobile';

const HyperWalletTransfer = () => {
  document.title = 'Single Ventricle SOURCE My Rewards';
  const { user, hyperwallet } = useSelector((state) => state);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const addStyle = (url) => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;
    document.head.appendChild(style);
  };

  let scriptStatus = useScript(
    `https://uat.hyperwallet.com/rest/widgets/transfer-methods/${user.hyperwalletToken}/en.min.js`
  );
  useScript(
    'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js'
  );
  addStyle(
    'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css'
  );
  useScript(
    'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/js/bootstrap.min.js'
  );

  useEffect(() => {
    const getAuthenticationToken = async (callback) => {
      const creds = getEncodedCredentials(
        hyperwallet.username,
        hyperwallet.password
      );
      const authTokenResponse = await getAuthToken(
        user.hyperwalletToken,
        creds
      );
      callback(JSON.parse(authTokenResponse));
    };

    const initializeUI = async () => {
      if (scriptStatus !== 'ready') {
        return;
      }

      await window.HWWidgets?.initialize((onSuccess, onFailure) => {
        getAuthenticationToken((response) => {
          onSuccess(response.value);
        });
      });

      await window.HWWidgets?.transferMethods
        .configure({
          template: 'bootstrap3',
          el: document.getElementById('TransferMethodUI'),
          data: getTransferData(),
          onComplete: function (trmObject, completionResult) {
            // redirect payee to another page with the result and token
            window.location = getTransferUrl(trmObject, completionResult);
          },
        })
        .display(function () {
          //this is a callback event called when display is done
          setIsLoading(false);
        });
    };
    initializeUI();
  });

  const spinner = (
    <SpinnerContainer>
      <CircularProgress color='primary' />
    </SpinnerContainer>
  );
  const transferDiv = (
    <TransferContainer id='TransferMethodUI'></TransferContainer>
  );
  const header = (
    <>
      <BaseHeader to='/profile/payment/hyperwallet'>
        Setup transfer method
      </BaseHeader>
      <Line />
    </>
  );
  const footer = (
    <ButtonsContainer>
      <Button
        size='lg'
        variant='link'
        sx={{ minWidth: '170px' }}
        onClick={() => navigate('/my-activities')}
      >
        Cancel
      </Button>
      <Button
        startIcon={<CheckIcon width={14} height={14} />}
        type='submit'
        size='large'
        sx={{ minWidth: '203px' }}
        onClick={() => navigate('/profile/payment/hyperwallet')}
      >
        Confirm transfer
      </Button>
    </ButtonsContainer>
  );

  return (
    <Box>
      {isMobile() && header}
      {isLoading ? spinner : transferDiv}
      {isMobile() && footer}
    </Box>
  );
};

export default HyperWalletTransfer;
