import { Box, Typography, styled } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as BackIcon } from 'images/shared/chevron-left-2.svg';

export const PageWrapper = styled('div')(({ theme }) => ({
  padding: '50px',
  [theme.breakpoints.down('sm')]: {
    padding: '0px',
  },
}));

export const PageHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '15px',
  padding: '0px',
  [theme.breakpoints.down('sm')]: {
    padding: '25px 15px',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  textWrap: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));

export const StyledBackArrow = styled(BackIcon)(({ theme }) => ({
  display: 'none',
  color: theme.palette.primary.darkBlue70,
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '18px',
    height: '20px',
  },
}));

export const Header = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const Content = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    padding: '25px',
  },
}));

export const SignButton = styled(Button)(({ theme }) => ({
  svg: {
    width: '20px',
  },
}));

export const MainHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
    backgroundColor: theme.palette.primary.white,
  },
}));

export const MainHeaderButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: '5px',
  [theme.breakpoints.down('sm')]: {
    display:'none',
    padding: '0px 10px',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '10px',
  [theme.breakpoints.down('sm')]: {
    gap: '0px',
  },
}));

export const Divider = styled(Box)({
  borderLeft: '2px solid #D9D9D9',
  height: '24px',
  marginLeft: '15px',
});

export const FiltersWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginLeft: '20px',
  pointerEvents: 'none',
});

export const FiltersTitle = styled(Box)({
  color: '#005587',
  marginLeft: '10px',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '17px',
});
