export const findStudyAttribute = (studyAttributes, targetAttribute) => {
  const attribute =
    studyAttributes?.find((attribute) => {
      if (attribute?.data.attributes.attribute === targetAttribute) {
        return true;
      }
      return false;
    }) || {};
  const { value } = attribute?.data?.attributes || {};
  return value;
};
