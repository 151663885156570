import { Link } from 'react-router-dom';

import { useGetPatientTasksQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';

import { BaseHeader } from 'components/BaseLayout';
import { Divider } from 'configUI/components/Shared';
import {
  FloatingAddButton,
  Content,
  StyledBaseLayout,
  StyledIconButton,
} from './styles';
import { ReactComponent as PlusIcon } from 'images/payment/plus.svg';
import { ReactComponent as ListIcon } from 'images/shared/list-ul.svg';
import { PregnancyJournalItemCard } from './PregnancyJournalItemCard';

const PregnancyJournalList = () => {
  const { data } = useGetPatientTasksQuery({
    paginate: false,
  });
  const taskEntries =
    data?.data?.find?.(({ task: { id } }) => id === 562)?.task_entries || [];

  return (
    <Content>
      {taskEntries.map(({ id, created_at, data }) => (
        <PregnancyJournalItemCard
          key={id}
          id={id}
          createdAt={created_at}
          data={data}
        />
      ))}
      <FloatingAddButton component={Link} to='create' startIcon={<PlusIcon />}>
        Add
      </FloatingAddButton>
    </Content>
  );
};

const PregnancyJournalMobile = () => (
  <StyledBaseLayout
    header={
      <>
        <BaseHeader
          to='/tools'
          rightMenu={
            <StyledIconButton component={Link} to='all'>
              <ListIcon height='18px' />
            </StyledIconButton>
          }
        >
          Pregnancy journal
        </BaseHeader>
        <Divider />
      </>
    }
    content={<PregnancyJournalList />}
  />
);

export default PregnancyJournalMobile;
