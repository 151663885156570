import { Navigate, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Box, InputLabel, Typography, useTheme } from '@mui/material';

import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import {
  useGetCurrentUserQuery,
  useGetStudyRolesQuery,
  useEditPersonMutation,
  userApiSlice,
} from 'ihp-bloom-redux/features/user/userApiSlice';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';

import Button from 'ihp-components/components/v2/Button';
import { Logo } from 'components/shared';
import { FormLabel } from 'components/Login';
import { Loader } from 'components/Loader';
import PersonalInformationMobile from './index.mobile';
import getStyles from './styles/index.styles';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as LocationIcon } from 'images/personalinformation/location.svg';
import { useValidateDate, useValidateZipCode } from './hooks';
import { getPersonPayloadWithoutDuplicates } from './personalInfoPayloadWithoutDuplicates';
import { Input } from 'ihp-components/components/v2/Input';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';

function PersonalInformation() {
  document.title = 'Single Ventricle SOURCE Personal Information';
  const theme = useTheme();
  const styles = getStyles(theme);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [editPerson, { isLoading: isEditingPerson }] = useEditPersonMutation();
  const { isAuthenticated, user: auth0User } = useAuth0();
  const { isConsented } = useSelector((state) => state.authentication);
  const { studyRoleId } = useGetStudyRolesQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      studyRoleId: data?.data?.[0]?.id,
      ...rest,
    }),
  });
  const { person_id } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person_id: data?.data?.included?.[0]?.[0]?.data?.id,
    }),
  });

  const validateDate = useValidateDate();
  const validateZipCode = useValidateZipCode();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...user,
      dob: '',
      gender: '',
      postal_code: '',
    },
  });

  const onSubmit = (data) => {
    const payload = getPersonPayloadWithoutDuplicates(
      {
        ...data,
        email: auth0User?.email,
        firstName: auth0User?.given_name,
        lastName: auth0User?.family_name,
      },
      studyRoleId
    );
    editPerson({ personId: person_id, payload })
      .then(() => {
        dispatch(setValues(data));
        dispatch({
          type: `${userApiSlice.reducerPath}/invalidateTags`,
          payload: ['CurrentUser'],
        });
        navigate('/enrolled');
      })
      .catch(console.error);
  };

  if (isAuthenticated && isConsented) {
    return <Navigate to='/my-activities' />;
  }

  if (!isAuthenticated && isConsented) {
    return <Loader />;
  }

  if (isMobile()) {
    return <PersonalInformationMobile />;
  }

  return (
    <Grid container sx={styles.container}>
      <Box sx={styles.formContainer}>
        <Box sx={styles.formHeader}>
          <Logo />
          <FormLabel fontSize='24px' marginTop='15px'>
            Personal Information
          </FormLabel>
          <Typography
            color='primary.gray75'
            fontSize='14px'
            textAlign='center'
            width='295px'
          >
            Please enter your personal information to finalize your profile
            creation
          </Typography>
        </Box>
        <Box padding='0px 25px'>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='dob'
                control={control}
                rules={{
                  validate: validateDate,
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    type='datePicker'
                    value={user.dob}
                    error={errors.dob}
                    label='Date of birth'
                    errorLabel={
                      errors?.dob?.message || 'This field is required'
                    }
                    fullWidth
                    placeholder='Select date'
                    {...field}
                    inputStyles={styles.datePicker}
                  />
                )}
              />
            </Box>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='gender'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    label='Sex at birth'
                    error={errors.gender}
                    errorLabel={errors.gender?.message}
                    type='radioInputGroup'
                    id='gender'
                    name='gender'
                    value={user.gender}
                    {...field}
                  >
                    <RadioInput value='male' label='Male' />
                    <RadioInput value='female' label='Female' />
                  </Input>
                )}
              />
            </Box>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='postal_code'
                control={control}
                rules={{
                  validate: validateZipCode,
                  required: true,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='Enter zip code'
                    mask='99999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        name='postal_code'
                        label={'ZIP code'}
                        error={errors.postal_code}
                        errorLabel={
                          errors?.postal_code?.message ||
                          'This field is required'
                        }
                        endIcon={<LocationIcon color='#DFE2E3' />}
                        fullWidth
                        sx={styles.input}
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>
            <Box sx={styles.fieldContainer}>
              <Button
                fullWidth
                onClick={handleSubmit(onSubmit)}
                sx={styles.submitButton}
                loading={isEditingPerson}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Grid>
  );
}
export default PersonalInformation;
