import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';

import { useSearchMedicationQuery } from 'ihp-bloom-redux/features/medicationSlice/medicationSlice';

import { Header as MobileHeader } from 'components/Header';
import { useDebounce } from 'hooks/useDebounce';
import EmptyState from './EmptyState';
import SearchResultListItem from './SearchResultListItem';
import {
  PageWrapper,
  SearchWrapper,
  StyledSearchInput,
} from '../Detailed/style';
import { useDosageUnits } from 'pages/NotificationCenter/MedicationTracker/hooks.js';
import { getMedicationTrackerCreatePath } from 'pages/NotificationCenter/paths';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { getRewardsInfo } from 'components/Payment/Rewards/util';

const MedicationSearchContent = ({ searchString }) => {
  const debouncedSearchString = useDebounce(searchString, 500);

  const { data: searchResults, isLoading: isSearching } =
    useSearchMedicationQuery(debouncedSearchString);

  const { extractDosageUnit } = useDosageUnits();

  const renderResults = useCallback(
    () =>
      searchResults?.expansion.contains?.map((result) => (
        <SearchResultListItem
          key={result.code}
          title={result.display}
          dosage={extractDosageUnit(result.display)}
        />
      )),
    [searchResults, extractDosageUnit]
  );

  const showEmptyState =
    (!isSearching && !searchResults?.expansion.contains?.length) ||
    !debouncedSearchString;

  if (showEmptyState)
    return (
      <EmptyState
        title={
          debouncedSearchString
            ? 'No result for your search'
            : 'Type in the input to search'
        }
      />
    );
  if (isSearching) return <EmptyState title='Searching...' />;
  return <Box padding='16px'>{renderResults()}</Box>;
};

const MedicationSearch = () => {
  const [searchString, setSearchString] = useState('');

  const navigate = useNavigate();

  const theme = useTheme();
  const upSM = useMediaQuery(theme.breakpoints.up('sm'));

  const { person } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
    }),
  });

  const { data } = useGetParticipantGoalStatsQuery(person?.id, {
    skip: person?.id ? false : true,
  });

  const rewardsData = getRewardsInfo(data?.data);

  useEffect(() => {
    if (upSM) navigate(getMedicationTrackerCreatePath());
  });

  return (
    <Box>
      <MobileHeader points={rewardsData?.balance} />
      <PageWrapper>
        <SearchWrapper>
          <StyledSearchInput
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </SearchWrapper>
        <MedicationSearchContent searchString={searchString} />
      </PageWrapper>
    </Box>
  );
};

export default MedicationSearch;
