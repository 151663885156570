import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

export const SurveyProgress = styled(Box)(({ theme, width }) => ({
  height: '5px',
  backgroundColor: theme.palette.primary.main,
  minWidth: '10px',
  maxWidth: '100%',
  width: width + '%',
}));

export const SurveyStep = styled(Typography)(({ theme, width }) => ({
  color: theme.palette.primary.blue,
  fontWeight: '700',
  fontSize: '13px',
}));

export const SurveyProgressBar = ({ numberOfSteps, current }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(parseInt((current / numberOfSteps) * 100));
  }, [current, numberOfSteps]);

  return (
    <Box textAlign='center'>
      <SurveyProgress width={progress}></SurveyProgress>
      <Box paddingY={'25px'}>
        {/* {Hiding the stepper text (6/6) when we are on Finalize page (page 7)} */}
        {current <= numberOfSteps && (
          <SurveyStep>
            Step {current} / {numberOfSteps}
          </SurveyStep>
        )}
      </Box>
    </Box>
  );
};

SurveyProgressBar.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
};
