import { Box } from '@mui/system';
import { ReactComponent as CoinsIcon } from 'images/payment/coinsIcon.svg';
import { ReactComponent as GreenCoinsIcon } from 'images/payment/greenCoins.svg';
import {
  LeftProgress,
  RightProgress,
  MainTextContainer,
  PointsContainer,
  ClaimButton,
} from './nextPayout.styles';
import { ChevronRight } from '@mui/icons-material';
import { isMobile } from 'utils/isMobile';
import { NextPayoutMobile } from './nextPayout.mobile';
import { useTheme } from '@mui/material';

export const NextPayout = (props) => {
  const {
    availablePoints,
    remainingPoints,
    progress,
    isComplete,
    claimReward,
    readOnly,
    thresholdMinAmount, // min amount
  } = props;
  const theme = useTheme();
  const isEmpty = availablePoints === 0;
  const colorComplete = isComplete ? { background: '#48AE34' } : {};

  // const message =
  //   isComplete && !readOnly
  //     ? 'Payment ready'
  //     : `${availablePoints} points available`;

  let message = '';
  let pointsMessage = '';
  if (availablePoints < thresholdMinAmount) {
    message = `${availablePoints} unredeemed points`;
    pointsMessage = `${
      thresholdMinAmount - availablePoints
    } points to your next reward`;
  } else if (availablePoints >= thresholdMinAmount) {
    message = 'Payment ready to be reedemed';
    pointsMessage = `${availablePoints} points available`;
  }

  // const pointsMessage =
  //   isComplete && !readOnly
  //     ? `${availablePoints} points available`
  //     : `${remainingPoints} points to your next reward`;

  const leftProgressRadius =
    isComplete || isEmpty
      ? { borderRadius: '8px' }
      : { borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' };

  const leftBackground = isEmpty
    ? { background: theme.palette.primary.lightBlue70 }
    : { background: theme.palette.primary.lightBlue100 };
  const width = isEmpty ? 100 : progress;
  const showRedeem = availablePoints >= thresholdMinAmount;

  if (isMobile() && showRedeem && !readOnly) {
    return <NextPayoutMobile {...props} />;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <LeftProgress
          sx={{
            width: `${width}%`,
            ...leftProgressRadius,
            ...leftBackground,
            ...colorComplete,
          }}
        >
          <Box sx={{ margin: '23px 0 0 18px' }}>
            {isComplete ? <GreenCoinsIcon /> : <CoinsIcon />}
          </Box>
        </LeftProgress>
        <RightProgress sx={{ width: `${100 - width}%`, ...colorComplete }} />
      </Box>
      <MainTextContainer>
        <PointsContainer>
          <Box>{message}</Box>
          <Box sx={{ fontWeight: '400', fontSize: '12px' }}>
            {pointsMessage}
          </Box>
        </PointsContainer>
        {showRedeem && !readOnly && (
          <ClaimButton onClick={claimReward}>
            Redeem
            <ChevronRight sx={{ marginTop: '-2px' }} />
          </ClaimButton>
        )}
      </MainTextContainer>
    </Box>
  );
};
