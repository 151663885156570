import {
  Box,
  CircularProgress,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import Button from 'ihp-components/components/v2/Button';
import ProvidersList from './ProvidersList';
import {
  LeftContainer,
  SearchBox,
  SearchProvidersTitle,
  ContentContainer,
  BottomContainer,
  HeaderContainer,
  HeaderWrapper,
  RightHeaderItemContainer,
  RightHeaderTextWrapper,
  AddNewProviderText,
  AddNewProviderContainer,
} from './styles';
import { ReactComponent as SearchIcon } from 'images/providers/search.svg';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import { ReactComponent as BackArrowIcon } from 'images/shared/chevron-left-1.svg';
import { ReactComponent as MedicalIcon } from 'images/data-sync/medical.svg';
import { ReactComponent as ClockIcon } from 'images/data-sync/clock.svg';
import { ReactComponent as CheckIcon } from 'images/shared/check-1.svg';
import { useNavigate, useLocation, useBlocker } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { ReactComponent as PurplePlusIcon } from 'images/payment/purplePlus.svg';
import { ReactComponent as AddMoreLaterIcon } from 'images/data-sync/add-more-later.svg';
import { ManualProviderModal } from './Modal/manualProviderModal';
import { ConfirmationModal } from './ConfirmationModal';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { CancelModal } from './cancelModal';
import {
  resetInProgressProviders,
  updateConnectingProvider,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useDispatch } from 'ihp-bloom-redux/app/redux';
import { debounce } from 'hooks/useDebounce';
import { deleteProvider } from 'services/oneUpService';
import { useAuth0 } from '@auth0/auth0-react';

export const SearchProvidersDesktop = ({
  providers,
  loadingProviders,
  handleFinish,
  connectedProviders,
  hasSearchTerm,
  setHasSearchTerm,
  setFiltered,
  setLoadingProviders,
  setLoadingConnectedProviders,
  setIsRedirecting,
  finishDataShareEnabled,
  hasConnectedProviders,
  isLoading,
  duration,
  timeRemaining,
  points,
  updateSelectedTask,
  isUpdatingTask,
  handleAddMoreLater,
  participantTaskId,
  taskPersonId,
  addManualProvider,
  deleteManualProvider,
  namesMap,
  personName,
  isChildTask,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const centeredStyle = { justifyContent: 'center' };

  const debouncedFilter = debounce(setFiltered, 200);

  const oneUpHealthData = useSelector((state) => state.oneUpHealthSlice);
  const { inProgressProviders } = oneUpHealthData;
  const shouldShowPopUp =
    finishDataShareEnabled || inProgressProviders?.length > 0;
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const onCloseCancelModal = () => setIsOpenCancelModal(false);
  const { getAccessTokenSilently } = useAuth0();

  const navigateBack = async () => {
    const token = await getAccessTokenSilently();
    Promise.all(
      inProgressProviders.map(async (p) => {
        if (p?.providerId) {
          await deleteProvider(token, taskPersonId, p.providerId);
        }
      })
    );
    dispatch(resetInProgressProviders());
    dispatch(updateConnectingProvider(null));
    return navigate('/my-activities');
  };
  const location = useLocation();
  const isEMR = location.pathname === `/activity/sync-data`;

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return (
        shouldShowPopUp &&
        !isOpenCancelModal &&
        currentLocation.pathname !== nextLocation.pathname
      );
    },
    [shouldShowPopUp, isOpenCancelModal]
  );
  const blocker = useBlocker(shouldBlock);

  const title = (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SearchProvidersTitle>Share your medical records</SearchProvidersTitle>
    </Box>
  );

  const searchBar = (
    <SearchBox
      fullWidth
      placeholder='Search Medical Record Providers'
      startAdornment={
        <InputAdornment position='end' size='large'>
          <SearchIcon />
        </InputAdornment>
      }
      onChange={(e) => {
        const value = e.target.value;
        setHasSearchTerm(value.length >= 4);
        debouncedFilter(value);
      }}
    />
  );

  const AddNewProviderBar = (
    <AddNewProviderContainer onClick={() => setShowSearchBar(true)}>
      <PurplePlusIcon />
      <AddNewProviderText>Add new healthcare provider</AddNewProviderText>
    </AddNewProviderContainer>
  );

  const bar =
    !hasConnectedProviders || showSearchBar ? searchBar : AddNewProviderBar;

  return (
    <Stack
      sx={{
        bgcolor: theme.palette.primary.gray20,
        height: '100vh',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <HeaderContainer>
        {blocker?.state === 'blocked' || isOpenCancelModal ? (
          <CancelModal
            isOpenCancelModal={true}
            onCloseCancelModal={() => {
              !isOpenCancelModal ? blocker?.reset?.() : onCloseCancelModal();
            }}
            navigateBack={() => {
              blocker?.proceed?.();
              navigateBack();
            }}
          />
        ) : null}
        <Box
          sx={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => {
            if (isEMR && shouldShowPopUp) {
              setIsOpenCancelModal(true);
              return;
            }
            return navigate('/my-activities');
          }}
        >
          <BackArrowIcon
            width={10}
            height='16px'
            color='#A1A1A1'
            style={isMobile ? { marginTop: '6px' } : {}}
          />
          <Box sx={{ marginLeft: '15px', marginTop: '-3px' }}>
            <Typography noWrap={true} variant='h6' color='primary.darkGray100'>
              {isMobile ? 'Share Your Medical Records' : 'My Activities'}
            </Typography>
          </Box>
        </Box>
        <HeaderWrapper>
          <RightHeaderItemContainer>
            {!isMobile && <MedicalIcon />}
            <RightHeaderTextWrapper>Medical Records</RightHeaderTextWrapper>
          </RightHeaderItemContainer>
          <RightHeaderItemContainer>
            {isMobile ? ' - ' : <ClockIcon />}
            <RightHeaderTextWrapper>{duration}</RightHeaderTextWrapper>
          </RightHeaderItemContainer>
        </HeaderWrapper>
      </HeaderContainer>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          ...centeredStyle,
          paddingTop: isMobile ? '25px' : '125px',
        }}
      >
        <LeftContainer sx={{ minWidth: '100%' }}>
          <ContentContainer>
            {!isMobile && title}
            {bar}
            <Box sx={{ paddingTop: '5px' }}>
              <ProvidersList
                providers={providers}
                connectedProviders={connectedProviders}
                loadingProviders={loadingProviders}
                setLoadingProviders={setLoadingProviders}
                setLoadingConnectedProviders={setLoadingConnectedProviders}
                setIsRedirecting={setIsRedirecting}
                hasConnectedProviders={hasConnectedProviders}
                isLoading={isLoading}
                hasSearchTerm={hasSearchTerm}
                setModalOpen={() => setIsOpenModal(true)}
                addManualProvider={addManualProvider}
                deleteManualProvider={deleteManualProvider}
                taskPersonId={taskPersonId}
                namesMap={namesMap}
                personName={personName}
                isChildTask={isChildTask}
              />
            </Box>
          </ContentContainer>
        </LeftContainer>
      </Box>
      <ManualProviderModal
        open={isOpenModal}
        onClose={() => {
          setHasSearchTerm(false);
          setIsOpenModal(false);
          setFiltered('');
        }}
        onCancel={() => setIsOpenModal(false)}
        participantTaskId={participantTaskId}
        addManualProvider={addManualProvider}
      />
      <BottomContainer>
        <Button
          startIcon={isMobile && <CheckIcon width={14} height={14} />}
          endIcon={!isMobile && <ArrowForwardIcon width={14} height={14} />}
          type='submit'
          disabled={
            !(finishDataShareEnabled || connectedProviders?.length > 0) ||
            isUpdatingTask
          }
          sx={{ minWidth: '130px' }}
          onClick={() => setShowConfirmationModal(true)}
        >
          {isUpdatingTask ? (
            <CircularProgress sx={{ color: 'white' }} />
          ) : (
            'Finish'
          )}
        </Button>
        <Button
          width={!isMobile ? '220px' : '100%'}
          variant={isMobile ? 'link' : 'outlined'}
          onClick={handleAddMoreLater}
          startIcon={
            !isMobile ? <AddMoreLaterIcon width={20} height={20} /> : null
          }
          disabled={
            !(finishDataShareEnabled || connectedProviders?.length > 0) ||
            isUpdatingTask
          }
        >
          {isUpdatingTask ? <CircularProgress /> : 'Add more later'}
        </Button>
      </BottomContainer>
      <ConfirmationModal
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        handleConfirmation={handleFinish}
      />
    </Stack>
  );
};
