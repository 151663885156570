import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetFamilyMembersQuery } from 'ihp-bloom-redux/features/family/familyApiSlice';

export function useGetFamilyMembers() {
  const { person } = useSelector((state) => state.user);

  const { data, ...rest } = useGetFamilyMembersQuery(person.id, {
    skip: typeof person.id !== 'number',
  });

  const familyMembers = data?.data.map((member) => ({
    ...member.attributes,
  }));
  
  return {
    data: familyMembers || [],
    ...rest,
  };
}
