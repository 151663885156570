import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { setValues } from 'ihp-bloom-redux/features/user/userSlice';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { accountProfileApiSlice } from 'ihp-bloom-redux/features/profile/accountProfileApiSlice';

import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { useLogout } from 'utils/useLogout';

import { useNoActiveProfile } from './useNoActiveProfile';
import OnboardingLayout from '../components/Layout';

import {
  STUDY_ARM_CODE,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_CODES,
  STUDY_ARM_STATUS_CODES,
  VIRTUAL_SITE,
} from 'constants/global';
import { eligibleCountries } from 'constants/country';

import {
  Row,
  PageTitle,
  HelperText,
  FieldContainer,
  RadioWrapper,
  ButtonContainer,
} from './styles';

function NoActiveProfile() {
  document.title = 'Single Ventricle SOURCE No Active Profile';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = useLogout();

  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isLoadingAccountProfile, setIsLoadingAccountProfile] = useState(false);

  const user = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);

  const { activeAccountProfile } = user;
  const personCountry =
    activeAccountProfile?.attributes?.person?.attributes?.country;

  const {
    createPersonStudyArmRecord,
    createNewAccountProfileRecord,
    isLoading,
  } = useNoActiveProfile();

  // STATES
  const studyArmContact =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARMS][
      STUDY_ARM_CODE.CONTACT
    ];
  const contactEnrollmentConfirmed =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.CONTACT_ENROLLMENT_CONFIRMED
    ];
  const siteId = VIRTUAL_SITE.id;
  const studyArmRoleCParticipant =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
      STUDY_ARM_ROLE_CODES.C_PARTICIPANT
    ];

  const handleOptionSelection = (e) => {
    setSelectedProfile(e.target.value);
  };

  const radioOptions = [
    {
      value: 'participate-in-research',
      option:
        'Create a new profile to share my experience and participate in research',
    },
    {
      value: 'receive-new-information',
      option: 'Create a new profile to receive new information',
    },
  ];

  const refetchAccountProfileData = async (profileId) => {
    const id = Number(profileId);
    try {
      setIsLoadingAccountProfile(true);
      const accountProfileResponse = await dispatch(
        accountProfileApiSlice.endpoints.getAccountProfile.initiate(id, {
          forceRefetch: true,
        })
      );
      setIsLoadingAccountProfile(false);
      if (accountProfileResponse?.data?.data) {
        const activeAccountProfilePayload = {
          ...user,
          activeAccountProfile: accountProfileResponse?.data?.data,
        };
        const targetIndex =
          activeAccountProfilePayload?.accountProfiles?.findIndex(
            (profile) =>
              Number(profile.id) ===
              Number(accountProfileResponse?.data?.data?.id)
          );
        if (targetIndex < 0) {
          activeAccountProfilePayload.accountProfiles = [
            ...activeAccountProfilePayload.accountProfiles,
            {
              id: accountProfileResponse?.data?.data?.attributes?.profile?.id,
              type: 'Profiles',
              attributes: {
                ...accountProfileResponse?.data?.data?.attributes?.profile,
              },
            },
          ];
        } else {
          activeAccountProfilePayload.accountProfiles =
            activeAccountProfilePayload.accountProfiles?.map((profile) => {
              if (
                Number(profile.id) ===
                Number(accountProfileResponse?.data?.data?.id)
              ) {
                return {
                  id: accountProfileResponse?.data?.data?.id,
                  type: 'Profiles',
                  attributes: {
                    ...accountProfileResponse?.data?.data?.attributes?.profile,
                  },
                };
              }
              return profile;
            });
        }
        await dispatch(setValues(activeAccountProfilePayload));
      }
    } catch (error) {
      setIsLoadingAccountProfile(false);
      console.log('Error on fetching new profile');
    }
  };

  const receiveNewInformationProfileHandler = async () => {
    try {
      const createStudyArmResponse = await createPersonStudyArmRecord(
        studyArmContact?.id,
        contactEnrollmentConfirmed.id,
        siteId,
        studyArmRoleCParticipant?.id,
        user?.person?.id
      );

      if (!createStudyArmResponse.error) {
        const createNewProfileResponse = await createNewAccountProfileRecord(
          createStudyArmResponse?.data?.data?.id,
          createStudyArmResponse?.data?.data?.id
        );
        if (!createNewProfileResponse.error) {
          // set createNewProfileResponse?.data?.data?.id as activeAccountProfileId
          await refetchAccountProfileData(
            createNewProfileResponse?.data?.data?.id
          );
          navigate('/onboarding/create-profile');
        }
      }
    } catch (error) {
      console.error('Error in handleParticipantDiagnosed:', error);
    }
  };

  const handleSave = async () => {
    const selectedOption = selectedProfile?.toLowerCase();

    if (selectedOption === 'participate-in-research') {
      if (!eligibleCountries.includes(personCountry)) {
        // SING-545
        // navigate('/onboarding/research-registry-notEligible');
        navigate('/onboarding/not-eligible', {
          replace: true,
        });
      } else {
        dispatch(setValues({ isEnrollingNewPerson: true }));
        navigate('/onboarding/research-eligibility/role');
      }
    } else {
      receiveNewInformationProfileHandler();
    }
  };

  return (
    <OnboardingLayout maxWidth='420px'>
      <Row marginBottom={'5px'}>
        <PageTitle varient='h2'>No Active Profiles</PageTitle>
      </Row>
      <Row marginBottom={'30px'}>
        <HelperText varient='pl3'>
          You no longer have an active profile under this account. How would you
          like to proceed?
        </HelperText>
      </Row>
      <FieldContainer>
        <Input
          type='radioInputGroup'
          id='option'
          name='option'
          value={selectedProfile}
          onChange={handleOptionSelection}
        >
          <RadioWrapper>
            {radioOptions.map((item) => (
              <RadioInput
                hideRadioIcon={false}
                value={item.value}
                label={item.option}
                key={item.value}
              />
            ))}
          </RadioWrapper>
        </Input>
      </FieldContainer>

      <ButtonContainer>
        <Button
          fullWidth
          loading={isLoading}
          disabled={isLoading || !selectedProfile || isLoadingAccountProfile}
          onClick={() => handleSave()}
        >
          Begin
        </Button>
        <Button
          variant='link'
          fullWidth
          disabled={isLoading || isLoadingAccountProfile}
          onClick={logout}
        >
          Back to sign in
        </Button>
      </ButtonContainer>
    </OnboardingLayout>
  );
}

export default NoActiveProfile;
