import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box } from '@mui/system';
import { BasicCard } from './BasicCard';
import {
  ActivityCardActions,
  activityCardStyle,
  styles,
} from './ActivityCard.styles';
import { ReactComponent as ArrowForwardIcon } from 'images/Task/arrow-forward-icon.svg';
import { Stack } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { Details } from './ActivityDetails';
import { isMobile } from 'utils/isMobile';

export const ActivityCard = ({
  rewardPoints,
  rewardBgColor,
  rewardColor,
  actionText,
  actionColor,
  actionBgColor,
  hideDetails,
  disabled,
  type,
  link,
  isTaskGroup,
  details,
  onProfileActivity,
  onConsentActivity,
  showLoader,
  metaData,
  detailsDividerColor,
  detailsColor,
  selectedTaskCategory,
  ...rest
}) => {
  const label = details?.duration?.label?.toUpperCase().trim();
  const points = rewardPoints ?? 0;
  const showActionButton = details?.isPast ? false : !isTaskGroup;
  let activityHandler = null;
  if (type === 'profile') {
    activityHandler = onProfileActivity;
  }
  if (type === 'consent') {
    activityHandler = onConsentActivity;
  }

  const renderComponent =
    type === 'profile' || type === 'consent' ? null : Link;

  return (
    <BasicCard
      {...rest}
      details={details}
      type={type}
      isTaskGroup={isTaskGroup}
      color={rewardColor}
      points={points}
      label={label}
    >
      {isMobile() ? (
        metaData &&
        !isTaskGroup && (
          <Details
            metaData={metaData}
            details={details}
            detailsDividerColor={detailsDividerColor}
            detailsColor={detailsColor}
            selectedTaskCategory={selectedTaskCategory}
          />
        )
      ) : (
        <Stack gap='5px' alignItems={'flex-end'}>
          {!isTaskGroup && (
            <Box sx={{ ...styles.estimatedTime }}>
              {`ESTIMATED TIME: ${label !== '' ? label : '0 MINS'}`}
            </Box>
          )}
        </Stack>
      )}

      {showActionButton && (
        <ActivityCardActions
          rewardBgColor={rewardBgColor}
          sx={activityCardStyle}
        >
          <Button
            size='sm'
            type='info2'
            variant='outlined'
            disabled={disabled}
            onClick={activityHandler}
            loading={showLoader}
            to={link}
            component={renderComponent}
            endIcon={<ArrowForwardIcon width={14} height={14} />}
          >
            {actionText ?? 'Details'}
          </Button>
        </ActivityCardActions>
      )}
    </BasicCard>
  );
};

ActivityCard.propTypes = {
  icon: PropTypes.node.isRequired,
  iconBgColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  currentIndex: PropTypes.number,
  hideNumbers: PropTypes.bool,
  actionBgColor: PropTypes.string,
  actionText: PropTypes.string,
  actionColor: PropTypes.string,
  rewardPoints: PropTypes.number,
  rewardColor: PropTypes.string,
  rewardBgColor: PropTypes.string,
  details: PropTypes.object,
  detailsDividerColor: PropTypes.string,
  hideDetails: PropTypes.bool,
  type: PropTypes.string,
  link: PropTypes.string,
  isTaskGroup: PropTypes.bool,
};
