import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const authenticationApiSlice = createApi({
  reducerPath: 'authenticationApiSlice',
  baseQuery: baseQuery(),
  tagTypes: ['Authentication', 'Enrollment'],
  endpoints: (build) => ({
    socialLogin: build.mutation({
      query: (params) => ({
        url: `/social/login?provider_name=${params.providerName}&access_token=${params.accessToken}&study_id=${params.studyId}`,
        method: 'GET',
      }),
      invalidatesTags: ['Authentication'],
    }),
    userAuth0Check: build.mutation({
      query: (idToken) => ({
        url: `/users/auth/check`,
        method: 'POST',
        body: { idToken },
      }),
      invalidatesTags: ['Authentication'],
    }),
    enrollment: build.mutation({
      query: (data) => ({
        url: `/enrollments`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Enrollment'],
    }),
  }),
});

export const {
  useSocialLoginMutation,
  useEnrollmentMutation,
  useUserAuth0CheckMutation,
} = authenticationApiSlice;
