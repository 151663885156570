export const getAdditionalAttributes = (person) => {
  if (!person) {
    return {};
  }
  let additionalAttr = {
    phone: '',
    timezone: '',
  };
  person?.included.forEach(({ data }) => {
    if (
      data.attributes.name === 'phone' ||
      data.attributes.name === 'timezone'
    ) {
      additionalAttr = {
        ...additionalAttr,
        [data.attributes.name]: data.attributes.value || '',
      };
    }
  });
  return additionalAttr;
};

/*
  inputDate: Javascript local date object 
  outputDate: MM-DD-YY
*/

export function convertStatusDateFormat(inputDate) {
  let d = new Date(inputDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = '' + d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  year = year?.slice(-2);

  const formattedDate = `${month}-${day}-${year}`;
  return formattedDate;
}
