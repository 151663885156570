import { Box, styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 40px 32px',
  [theme.breakpoints.up('sm')]: {
    gap: '25px',
    backgroundColor: theme.palette.primary.white,
    borderRadius: '8px',
  },
}));
