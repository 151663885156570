import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  MainHeaderButtonsContainer,
  ButtonsWrapper,
  MainHeaderContainer,
  PageHeader,
  StyledBackArrow,
  PageTitle,
} from './styles';
import Button from 'ihp-components/components/v2/Button';

const labels = {
  ACTIONABLE: 'TO DO',
  PAST: 'COMPLETED',
};

export const Header = ({
  selectedConsentCategory,
  setSelectedConsentCategory,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const navigateToProfile = () => navigate('/profile');

  const consentCategory = (category) => (
    <Button
      size='xs'
      type={selectedConsentCategory === category ? 'primary' : 'secondary'}
      variant={selectedConsentCategory === category ? 'contained' : 'link'}
      onClick={() => setSelectedConsentCategory(category)}
    >
      {labels[category]}
    </Button>
  );

  return (
    <Stack display='flex' width='100%'>
      <MainHeaderContainer>
        <PageHeader onClick={upSm ? null : navigateToProfile}>
          <StyledBackArrow />
          <PageTitle variant='h2'>My Consents</PageTitle>
        </PageHeader>
        <MainHeaderButtonsContainer>
          <ButtonsWrapper>
            {consentCategory('ACTIONABLE')}
            {consentCategory('PAST')}
          </ButtonsWrapper>
        </MainHeaderButtonsContainer>
      </MainHeaderContainer>
    </Stack>
  );
};

Header.propTypes = {
  selectedConsentCategory: PropTypes.string,
  setSelectedConsentCategory: PropTypes.func,
};
