import { Alert } from '@mui/material';
import { FamilyLinkagePendingInvitation } from 'pages/Profile/FamilyLinkage/components/FamilyLinkagePendingInvitation';
import { useAcceptFamilyInvitation } from 'features/familyLinkage/hooks/useAcceptFamilyInvitation';
import { useDeclineFamilyInvitation } from 'features/familyLinkage/hooks/useDeclineFamilyInvitation';
import { List, Section, Title } from 'pages/Profile/FamilyLinkage/styles';

export function PendingInvitations({ invitations }) {
  const acceptFamilyInvitation = useAcceptFamilyInvitation();
  const declineFamilyInvitation = useDeclineFamilyInvitation();

  const isError =
    acceptFamilyInvitation.mutation.isError ||
    declineFamilyInvitation.mutation.isError;

  const isLoading =
    acceptFamilyInvitation.mutation.isLoading ||
    declineFamilyInvitation.mutation.isLoading;

  return (
    <Section>
      <Title>Pending invitations</Title>

      {isError && !isLoading && (
        <Alert severity='error'>
          An error occurred while updating the invitation. Please, try again
          later.
        </Alert>
      )}

      <List>
        {invitations.map((familyInvitation) => (
          <FamilyLinkagePendingInvitation
            isAccepting={acceptFamilyInvitation.mutation.isLoading}
            isDeclining={declineFamilyInvitation.mutation.isLoading}
            isLoading={isLoading}
            onAccept={() =>
              acceptFamilyInvitation.submit({
                familyInvitationId: familyInvitation.id,
              })
            }
            onDecline={() =>
              declineFamilyInvitation.submit({
                familyInvitationId: familyInvitation.id,
              })
            }
            {...familyInvitation}
          />
        ))}
      </List>
    </Section>
  );
}
