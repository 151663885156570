import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import { useTabs } from 'hooks/useTabs';
import GeneralInfo from './GeneralInfo';
import ContactInfo from './ContactInfo';
import CommunicationPreference from './CommunicationPreference';
import { getSortedTabs, LANG } from 'utils/configParseUtils';
import {
  MyProfilePageHeader,
  MyProfilePageWrapper,
  PageTitle,
  ProfileTab,
  ProfileTabs,
  StyledBackArrow,
  StyledGrid,
  TabPanel,
} from '../MyProfile/styles';

const AccountHolderProfile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { state } = useLocation();
  const {
    participantProfileConfig: { accountHolderProfile },
  } = useSelector((state) => state.configuration);
  const tab = state?.selectedTab ? state.selectedTab : 0;
  const { selectedTab, setSelectedTab } = useTabs(tab);
  const sortedTabs = getSortedTabs(accountHolderProfile?.tabs);

  const handleChange = (_, newSelectedTab) => {
    setSelectedTab(newSelectedTab);
  };

  const navigateToProfile = () => navigate('/profile');

  const renderTabPanel = () => {
    return sortedTabs?.map((tab, index) => {
      return (
        <React.Fragment>
          {tab?.meta?.label?.[LANG] === 'General Info' && (
            <TabPanel value={selectedTab} index={index}>
              <GeneralInfo />
            </TabPanel>
          )}
          {tab?.meta?.label?.[LANG] === 'Contact Info' && (
            <TabPanel value={selectedTab} index={index}>
              <ContactInfo />
            </TabPanel>
          )}
          {tab?.meta?.label?.[LANG] === 'Communication Preferences' && (
            <TabPanel value={selectedTab} index={index}>
              <CommunicationPreference />
            </TabPanel>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <MyProfilePageWrapper>
      <MyProfilePageHeader onClick={upSm ? null : navigateToProfile}>
        <StyledBackArrow />
        <PageTitle variant='h2'>Account Holder Profile</PageTitle>
      </MyProfilePageHeader>
      <Box display={'flex'} flexDirection={'column'}>
        <Box>
          <ProfileTabs
            value={selectedTab}
            onChange={handleChange}
            aria-label='Profile tabs'
          >
            {sortedTabs?.map((tab, index) => {
              return (
                <ProfileTab label={tab?.meta?.label?.['en-us']} value={index} />
              );
            })}
          </ProfileTabs>
        </Box>
        <StyledGrid>{renderTabPanel()}</StyledGrid>
      </Box>
    </MyProfilePageWrapper>
  );
};

export default AccountHolderProfile;
