import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useGetParticipantGoalStatsQuery } from 'ihp-bloom-redux/features/tasks/taskApiSlice';
import { useUpdateParticipantTaskMutation } from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import { formatCallDetails } from 'pages/Activities/utils';
import { CenteredFullPageWrapper } from 'components/CenteredFullPageWrapper';
import ActivityInstructions from 'components/Activities/Instructions';
import { Loader } from 'components/Loader';
import { makeCallScreensMap } from './utils';
import { taskTypeLabelMapping } from '../utils';
import { Details } from './Details';
import { FollowupVideoCall } from './FollowupVideoCall';
import { ReactComponent as ArrowRightIcon } from 'images/shared/arrow-right-2.svg';
import {
  StyledBox,
  MakeCallPageWrapper,
  StyledActivityHeader,
  StepperWrapper,
  StyledProgressButtons,
  celebrationScreenContainerStyle,
} from './styles';
import { useGetProfileParticipantTask } from '../hooks';
import { useGetAllPersonAttributesQuery } from 'ihp-bloom-redux/features/user/personAttributesApiSlice';
import { getFormFields } from 'pages/Profile/MyProfile/utils';
import { useMediaQuery } from '@mui/material';

export const MakeCall = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  document.title = 'Single Ventricle SOURCE Televisit';
  const participantTaskId = parseInt(useParams().id);

  const callDetails = useSelector((state) => state.followup.callDetails);

  const { activeAccountProfile, person } = useSelector((state) => state.user);

  const accountProfileId = activeAccountProfile?.attributes?.profile?.id;

  const { personAttributes, areAttrbsFetching } =
    useGetAllPersonAttributesQuery(person?.id, {
      skip: !person?.id,
      selectFromResult: ({ data, isLoading, isFetching }) => ({
        personAttributes: data?.included,
        areAttrbsLoading: isLoading,
        areAttrbsFetching: isFetching,
      }),
    });

  let fields = getFormFields({ timezone: 'timezone' }, personAttributes);

  const participantTaskDetails = useGetProfileParticipantTask(
    participantTaskId,
    accountProfileId
  );

  const navigate = useNavigate();

  const [updateParticipantTask, { isLoading: cancelingTask }] =
    useUpdateParticipantTaskMutation();

  const [currentStep, setCurrentStep] = useState(makeCallScreensMap.howTo);

  const hasHowTo = participantTaskDetails?.attributes?.howTo?.display;
  const formattedData = formatCallDetails(
    participantTaskDetails,
    fields?.timezone
  );

  if (currentStep === 0 && !hasHowTo) {
    setCurrentStep(makeCallScreensMap.callDetails);
  }

  const next = () => {
    if (
      currentStep === makeCallScreensMap.celebration ||
      currentStep === makeCallScreensMap.callDetails
    ) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const back = () => {
    if (
      currentStep - 1 === makeCallScreensMap.howTo &&
      !participantTaskDetails?.attributes?.howTo?.display
    ) {
      navigate('/my-activities');
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const cancelTask = async () => {
    /* This payload will be sent to API */
    const requestBody = {
      participantTaskId: participantTaskId,
      payload: {
        data: {
          type: 'participant-tasks',
          id: String(participantTaskId),
          attributes: {
            status: 'rejected',
          },
        },
      },
    };

    updateParticipantTask(requestBody)
      .then(() => {
        navigate('/my-activities');
      })
      .catch(console.error);
  };

  let estimatedTime = 'N/A';
  if (participantTaskDetails?.estimatedTime) {
    estimatedTime = `${participantTaskDetails.estimatedTime} min`;
  }
  let timeLimit = undefined;
  if (participantTaskDetails?.timeLimit) {
    timeLimit = `${participantTaskDetails?.timeLimit} min`;
  }
  const startButtonText =
    participantTaskDetails?.attributes?.meta?.button_name || 'Make Call';

  const stepsMap = {
    [makeCallScreensMap.howTo]: (
      <CenteredFullPageWrapper>
        <ActivityInstructions
          hideMetaData={isMobile}
          hideInstructionsTitle={!isMobile}
          title={participantTaskDetails?.title}
          type={taskTypeLabelMapping[participantTaskDetails?.type || 'call']}
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          points={participantTaskDetails?.point}
          description={participantTaskDetails?.attributes?.howTo?.instructions}
          startButtonText='Continue'
          onClickContinue={next}
          onCancel={() => navigate('/my-activities')}
        />
      </CenteredFullPageWrapper>
    ),
    [makeCallScreensMap.callDetails]: (
      <StyledBox>
        <Details
          data={{ ...formattedData, timezone: fields?.timezone }}
          onCancel={cancelTask}
        />
      </StyledBox>
    ),
    [makeCallScreensMap.videoCall]: (
      <StyledBox>
        <FollowupVideoCall src={callDetails.link} />
      </StyledBox>
    ),
    [makeCallScreensMap.celebration]: (
      <CelebrationScreen
        attributes={{
          points: participantTaskDetails?.point || 10,
          thankyouMsg:
            participantTaskDetails?.attributes?.celebrationScreen?.title,
          description:
            participantTaskDetails?.attributes?.celebrationScreen?.description,
        }}
        maxHeight={false}
        sx={celebrationScreenContainerStyle}
      />
    ),
  };

  const getButtonLabel = () => {
    if (currentStep === makeCallScreensMap.celebration) {
      return 'Done';
    }
    if (
      currentStep === makeCallScreensMap.howTo ||
      currentStep === makeCallScreensMap.selectDay
    ) {
      return startButtonText;
    }
    return 'Exit';
  };

  const isCelebrationScreen = currentStep === makeCallScreensMap.celebration;
  const isCompleted = participantTaskDetails?.status === 'completed';

  if (isCompleted) {
    navigate('/my-activities');
  }

  if (participantTaskDetails?.loadingParticipantTask || areAttrbsFetching) {
    return <Loader />;
  }

  if (cancelingTask) {
    return <Loader />;
  }

  return (
    <MakeCallPageWrapper>
      {!isCelebrationScreen && (
        <StyledActivityHeader
          title='My Activities'
          points={participantTaskDetails?.point}
          type={taskTypeLabelMapping[participantTaskDetails?.type || 'call']}
          estimatedTime={estimatedTime}
          timeLimit={timeLimit}
          currentStep={currentStep}
          lastStep={2}
          showStepCounter={false}
        />
      )}
      <StepperWrapper>
        {stepsMap[currentStep]}
        {(currentStep === makeCallScreensMap.howTo ||
          currentStep === makeCallScreensMap.celebration) && (
          <StyledProgressButtons
            isLastStep={currentStep === makeCallScreensMap.celebration}
            buttonProps={{
              endIcon: isCelebrationScreen ? null : <ArrowRightIcon />,
              label: getButtonLabel(),
              onClick: next,
            }}
            linkProps={{
              label: 'Back',
              disabled: currentStep === makeCallScreensMap.celebration,
              onClick: back,
              hide: currentStep === makeCallScreensMap.celebration,
            }}
          />
        )}
      </StepperWrapper>
    </MakeCallPageWrapper>
  );
};

export default MakeCall;
