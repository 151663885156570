import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import styles from './ImageRow.style';

function ImageRow({ icon, label, time }) {
  return (
    <Box sx={styles.timeline}>
      <Typography variant="pl4" sx={styles.labelWithIcon}>
        <img src={icon} alt="games" style={styles.icon} />
        <Box component="div" sx={styles.label}>
          {label}
        </Box>
      </Typography>
      <Typography variant="pl4" color='primary.gray75' sx={styles.label}>
        {time}
      </Typography>
    </Box>
  );
}

ImageRow.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default ImageRow;
