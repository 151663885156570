import { useLocation } from 'react-router-dom';

import OnboardingLayout from '../components/Layout';
import { ReactComponent as AvLogo } from 'images/svs-logo.svg';
import { Row } from './styles';
import { Text, Title } from '../components/Text';
import { Footer } from '../components/Footer';
import Button from 'ihp-components/components/v2/Button';
import { Box } from '@mui/material';
import { SelectGroup } from 'components/SelectGroup';
import { useEffect, useState } from 'react';
import { isInterestedOptions } from './constants';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { getAuth0LoginPageConfig } from 'utils/auth0';
import { INEGIBILITY_REASONS } from 'pages/Onboarding/BasicInformation/constants';

export function AdditionalResourcesPage() {
  document.title = 'SVS Additional Resources';
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const [isInterestedSelectedOptions, setIsInterestedSelectedOptions] =
    useState([]);

  const location = useLocation();
  const { ineligibilityReasons, prescreening_data: prescreeningData } =
    location?.state;

  useEffect(() => {
    /* If prescreeningData is undefined, it indicates that the user did not navigate from the basic-information page and/or may have refreshed the browser. */
    if (!prescreeningData) {
      return navigate('/onboarding/welcome-to-study');
    }
  }, [navigate, prescreeningData]);

  function handleOnSaveAndContinue() {
    if (
      isInterestedSelectedOptions.includes('I am not interested at this time')
    ) {
      navigate('/onboarding/not-eligible', {
        replace: true,
        state: {
          ineligibilityReasons,
        },
      });
    } else {
      return loginWithRedirect(
        getAuth0LoginPageConfig({
          context: 'signup',
          prescreening_data: JSON.stringify({
            ...prescreeningData,
            is_eligible: false,
          }),
        })
      );
    }
  }

  function redirectToSignUp() {
    navigate('/onboarding/welcome-to-study');
  }

  return (
    <OnboardingLayout maxWidth='375px' backHandler={redirectToSignUp}>
      <Row>
        <AvLogo />
      </Row>

      <Title marginBottom='10px'>Additional Resources</Title>

      <Text paddingBottom='30px'>
        Thank you for your interest in joining SOURCE. Unfortunately, we are
        unable to enroll you because you do not{' '}
        {ineligibilityReasons.includes(INEGIBILITY_REASONS.ELIGIBLE_COUNTRY)
          ? 'reside in the United States'
          : 'meet the study enrollment criteria at this time'}
        .
        <br />
        <br />
        We would, however, like to keep you
        <br />
        updated on the progress of SOURCE if you
        <br />
        would like to be contacted.
      </Text>

      <SelectGroup
        checkType='radio'
        options={isInterestedOptions}
        selectedOptions={isInterestedSelectedOptions}
        setSelectedOptions={setIsInterestedSelectedOptions}
      />

      <Footer padding='25px 0px 0px 0px'>
        <Box display='flex' flexDirection='column' gap='8px' width='100%'>
          <Button
            disabled={isInterestedSelectedOptions.length === 0}
            fontSize='14px'
            fontWeight={700}
            fullWidth
            onClick={handleOnSaveAndContinue}
          >
            Save & continue
          </Button>

          <Button
            fontSize='16px'
            fontWeight={700}
            variant='link'
            onClick={redirectToSignUp}
          >
            Back to sign up
          </Button>
        </Box>
      </Footer>
    </OnboardingLayout>
  );
}
