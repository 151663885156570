import { Alert, InputAdornment } from '@mui/material';
import { OverlayModal } from 'components/Modal';
import { useSendFamilyInvitation } from 'features/familyLinkage/hooks/useSendFamilyInvitation';
import {
  Buttons,
  SendInviteForm,
  Text,
  inputStyles,
} from 'features/familyLinkage/styles';
import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { ReactComponent as UserIcon } from 'images/profile/user-circle.svg';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useValidateEmail } from 'utils/formValidation';
import { SEND_INVITE_MODAL } from '../../constants';

export function FamilyLinkageInviteMemberModal({ onClose, onSuccess, open }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
  });

  const { mutation, submit } = useSendFamilyInvitation();
  const validateEmail = useValidateEmail();

  function handleOnClose() {
    reset();
    mutation.reset();
    onClose();
  }

  useEffect(() => {
    if (mutation.isSuccess) {
      reset();
      mutation.reset();
      onSuccess();
    }
  }, [mutation, onSuccess, reset]);

  const error = mutation.error?.data?.errors?.[0]?.detail;
  
  const modalContent = (
    <>
      {mutation.isError && (
        <Alert severity='error'>
          {error ?? SEND_INVITE_MODAL.errorMessage}
        </Alert>
      )}

      <Text>{SEND_INVITE_MODAL.description}</Text>

      <SendInviteForm onSubmit={handleSubmit(submit)}>
        <Controller
          name='firstName'
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Input
              endAdornment={
                <InputAdornment position='end'>
                  <UserIcon />
                </InputAdornment>
              }
              error={errors.firstName}
              errorLabel={errors.firstName?.message}
              fullWidth
              label={SEND_INVITE_MODAL.form.firstName.label}
              name='firstName'
              placeholder={SEND_INVITE_MODAL.form.firstName.placeholder}
              {...field}
              sx={inputStyles}
            />
          )}
        />

        <Controller
          name='lastName'
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Input
              endAdornment={
                <InputAdornment position='end'>
                  <UserIcon />
                </InputAdornment>
              }
              error={errors.lastName}
              errorLabel={errors.lastName?.message}
              fullWidth
              label={SEND_INVITE_MODAL.form.lastName.label}
              name='lastName'
              placeholder={SEND_INVITE_MODAL.form.lastName.placeholder}
              sx={inputStyles}
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name='email'
          rules={{
            required: true,
            validate: validateEmail,
          }}
          render={({ field }) => (
            <Input
              endAdornment={
                <InputAdornment position='end'>
                  <EmailIcon color='#D9D9D9' />
                </InputAdornment>
              }
              error={errors.email}
              errorLabel={errors.email?.message}
              fullWidth
              label={SEND_INVITE_MODAL.form.email.label}
              name='email'
              placeholder={SEND_INVITE_MODAL.form.email.placeholder}
              sx={inputStyles}
              {...field}
            />
          )}
        />

        <Buttons flexDirection='column' mt='10px' width='100%'>
          <Button
            disabled={mutation.isLoading}
            loading={mutation.isLoading}
            type='submit'
          >
            {SEND_INVITE_MODAL.buttonText.sendInvitation}
          </Button>

          <Button
            disabled={mutation.isLoading}
            onClick={handleOnClose}
            variant='link'
          >
            {SEND_INVITE_MODAL.buttonText.close}
          </Button>
        </Buttons>
      </SendInviteForm>
    </>
  );

  return (
    <OverlayModal
      open={open}
      modalTitle={SEND_INVITE_MODAL.title}
      modalContent={modalContent}
      onClose={!mutation.isLoading && handleOnClose}
    />
  );
}
