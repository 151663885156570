import { styled } from '@mui/material/styles';
import { Box, Menu } from '@mui/material';
import { NavigatorGroup } from './NavigatorGroup';
import { isMobile } from 'utils/isMobile';

export const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgb(0 0 0 / 4%)',
  zIndex: 100,

  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    borderTop: `1px solid ${theme.palette.primary.gray10}`,
    flexDirection: 'row',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledNavigatorGroup = styled(NavigatorGroup)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  marginLeft: '7px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

export const NavBar = styled(Box)(({ theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  justifyContent: 'center',
  gap: isMobile() ? '10px' : '40px',
  marginLeft: '-10px',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    maxWidth: '400px',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    maxHeight: '350px',
  },
}));

export const EdgeBadge = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  display: 'flex',
  width: '62px',
  margin: '15px auto 15px auto',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    boxShadow: '0px 2px 10px 0px #00000014',
    borderRadius: 8,
    width: '255px',
    transform: 'translate(-70px, -40px) !important',
    '& ul li': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const getStyles = (theme, isExpanded) => ({
  expand: {
    marginLeft: '90px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100dvh',
    marginTop: '-23px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  collapse: {
    marginLeft: '250px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100dvh',
    marginTop: '-23px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  initials: {
    fontStyle: 'Inter',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.palette.primary.white,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
  },
  nameProfileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
  },
  fullName: {
    fontStyle: 'Inter',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    color: theme.palette.primary.white,
    marginLeft: '20px',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  switchProfile: {
    fontStyle: 'Inter',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16.8px',
    color: theme.palette.primary.white,
    marginLeft: '20px',
    marginTop: '3px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fullNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: isExpanded ? '-30px' : '0px',
  },
  bottomContainer: {
    marginLeft: '0px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100dvh',
    marginTop: '-20px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
});
