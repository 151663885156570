import { Stack, Typography } from '@mui/material';
import {
  ActivityDoneContainer,
  RewardBadge,
  RewardPoints,
  PointsContainer,
  Goals,
  Goal,
  StyledSlider,
  GoalTitle,
} from './styles';
import { ReactComponent as DoneCheckmarkIcon } from 'images/shared/done-checkmark.svg';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';
import { ReactComponent as DollarIcon } from 'images/shared/dollar.svg';
import { ReactComponent as ColorPalleteIcon } from 'images/activity/types/color-pallete.svg';

const CelebrationScreen = ({
  maxHeight = true,
  maxWidth,
  sx = {},
  attributes: {
    thankyouMsg,
    description,
    points,
    affected_goals = [],
    participant_rewards = [],
  },
}) => {
  const style = maxHeight ? { ...sx, height: '100%' } : { ...sx };
  const thankYouStyle = maxWidth
    ? { maxWidth: '250px', marginBottom: '10px' }
    : { marginBottom: '10px' };
  return (
    <ActivityDoneContainer sx={{ ...style }}>
      <DoneCheckmarkIcon />
      <Typography variant='h4' align='center' sx={{ ...thankYouStyle }}>
        {thankyouMsg}
      </Typography>
      {description && (
        <Typography variant='h4' align='center' sx={{ ...thankYouStyle }}>
          {description}
        </Typography>
      )}
      {/* The following section represents affected goals */}
      <Goals>
        {affected_goals?.map((goal, index) => {
          const percentage =
            (parseInt(goal?.value_gained) / parseInt(goal?.target)) * 100;
          const isCompleted = percentage === 100;
          if (goal?.goal_type === null || goal?.goal_type === 'point_unbounded')
            return <></>;
          return (
            <Goal key={`${index}-${goal?.value_gained}`}>
              <GoalTitle variant='h2'>
                <b>
                  {goal?.value_gained} / {goal?.target}
                </b>{' '}
                <span className='goal-title'>{goal?.title}</span>
              </GoalTitle>
              <StyledSlider
                value={percentage}
                isCompleted={isCompleted}
                backgroundColor={goal.goal_color || '#EB5757'}
              />
            </Goal>
          );
        })}
      </Goals>
      {/* The following section represents earned reward */}
      {participant_rewards?.map((reward, index) => (
        <RewardBadge key={`${index}-${reward.reward_amount}`}>
          {reward?.reward_type === 'badge' ? (
            <>
              <PointsContainer
                noPadding={true}
                svgWidth='40px'
                svgHeight='40px'
              >
                <ColorPalleteIcon width={20} />
              </PointsContainer>
              <Stack direction='row' spacing='5px' alignItems='center'>
                <Typography variant='pn1'>{reward.reward_name}</Typography>
              </Stack>
            </>
          ) : (
            <>
              <PointsContainer>
                <DollarIcon width={20} />
              </PointsContainer>
              <Stack direction='row' spacing='5px' alignItems='center'>
                <RewardPoints>{reward.reward_amount}</RewardPoints>
                <Typography variant='pn1'>USD earned</Typography>
              </Stack>
            </>
          )}
        </RewardBadge>
      ))}

      {/* The following section represents earned points */}
      {points && (
        <RewardBadge>
          <PointsContainer>
            <PointsIcon width={18} />
          </PointsContainer>
          <Stack direction='row' spacing='5px' alignItems='center'>
            <RewardPoints>{points}</RewardPoints>
            <Typography variant='pn1'>Points earned</Typography>
          </Stack>
        </RewardBadge>
      )}
    </ActivityDoneContainer>
  );
};

export default CelebrationScreen;
