import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const reporterPageApiSlice = createApi({
  reducerPath: 'reporterPageApiSlice',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Reporters'],
  endpoints: (build) => ({
    createReporter: build.mutation({
      query: (payload) => ({
        url: `/app/reporters`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['Reporters'],
    }),

    getReporters: build.query({
      query: (params) => ({
        url: `/app/reporters`,
        method: 'GET',
        params,
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      providesTags: ['Reporters'],
    }),
  }),
});

export const { useCreateReporterMutation, useGetReportersQuery } =
  reporterPageApiSlice;
