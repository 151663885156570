import React from 'react';
import ConfirmPersonalInformationMobile from './confirm.mobile';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { FormLabel } from 'components/Login';
import { BoxItem } from 'components/PersonalInformation';
import Button from 'ihp-components/components/v2/Button';
import LeftContainer from 'components/Onboarding/LeftContainer';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import getStyles from './styles/confirm.styles';
import avatar from 'images/personalinformation/avatar.svg';
// import calendar from 'images/personalinformation/calendar.svg';
// import genderIcon from 'images/personalinformation/gender.svg';
// import location from 'images/personalinformation/location.svg';
// import barcode from 'images/personalinformation/barcode.svg';
import { isMobile } from 'utils/isMobile';

function ConfirmPersonalInformation() {
  const theme = useTheme();
  const styles = getStyles(theme);
  const {
    firstName,
    lastName,
    email /* dob, gender, zipCode, studyIdentifier */,
  } = useSelector((state) => state.user);

  let navigate = useNavigate();

  const navigateToInformedConsent = () => {
    navigate('/informedconsent');
  };
  const handleUpdate = () => {
    navigate('/personalinformation');
  };

  if (isMobile()) {
    return <ConfirmPersonalInformationMobile />;
  }

  return (
    <Grid container sx={styles.container}>
      <LeftContainer />
      <Grid item xl={6}>
        <Box component='div' sx={styles.rightContainer}>
          <Box sx={styles.formContainer} component='div'>
            <FormLabel>Confirm your information</FormLabel>
          </Box>
          <Box sx={styles.whiteBox}>
            <Box sx={styles.avatarContainer}>
              <Avatar sx={styles.avatar}>
                {firstName[0]}
                {lastName[0]}
              </Avatar>
            </Box>
            <Typography variant='pl3' color='primary.gray75' align='center'>
              Please confirm your personal information provided by your Gmail
              account.
            </Typography>
            <Box sx={styles.boxItems}>
              <BoxItem icon={avatar} label={`${firstName} ${lastName}`} />
              <BoxItem icon={avatar} label={email} />

              {/* <BoxItem icon={calendar} label={dob} />
              <BoxItem icon={genderIcon} label={gender} />
              <BoxItem icon={location} label={zipCode} />
              <BoxItem icon={barcode} label={studyIdentifier} /> */}
            </Box>
            <Box sx={styles.buttonContainer}>
              <Button variant='outlined' fullWidth onClick={handleUpdate}>Update</Button>
            </Box>
            <Box sx={styles.buttonContainer} component='div'>
              <Button
                fullWidth
                onClick={navigateToInformedConsent}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
export default ConfirmPersonalInformation;
