import { forwardRef } from 'react';
import { Box, IconButton, Popover } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxSizing: 'border-box',
  height: '60px',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 20px',
  backgroundColor: theme.palette.primary.white,
  [theme.breakpoints.up('sm')]: {
    border: `1px solid ${theme.palette.primary.gray10}`,
    borderRadius: '8px',
    width: '270px',
    height: '50px'
  }
}));

export const ControlContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px'
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '18px',
  color: theme.palette.primary.gray50,
  padding: 0,
}));

export const StyledPopover = styled(forwardRef((props, ref) =>
  <Popover
    ref={ref}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    elevation={0}
    marginThreshold={0}
    transitionDuration={500}
    {...props}
  />
))(({ theme }) => ({
  '& > .MuiPaper-root': {
    margin: 0,
    width: '100%',
    maxWidth: 'unset',
    borderRadius: 0,
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      width: '270px',
      border: `1px solid ${theme.palette.primary.gray25}`,
      borderRadius: '8px',
    }
  }
}));
