import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography, useTheme } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import {StyledDescription} from "./styles";

const EmptyState = ({ title, description, onAction, actionText, icon, actionIcon }) => {
  const theme =  useTheme();
  return (
    <Stack alignItems={'center'} gap={'30px'}>
      <Stack alignItems={'center'} textAlign={'center'} gap={'15px'}>
        {icon}
        <Typography variant='h5' color={`${theme.palette.primary.darkGray70}`}>{title}</Typography>
        <StyledDescription variant='pl5'>{description}</StyledDescription>
      </Stack>
      {onAction && actionText && (
        <Button startIcon={actionIcon} onClick={onAction}>{actionText}</Button>
      )}
    </Stack>
  )
};

EmptyState.propTypes = {
  type: PropTypes.oneOf(['medications', 'providers', 'journalEntries', 'tasks']),
  title: PropTypes.string,
  description: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  actionIcon: PropTypes.element
};

export { EmptyState };
