import { Typography } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as ArrowForwardOutlinedIcon } from 'images/shared/chevron-right.svg';
import { isMobile } from 'utils/isMobile';
import {
  EmptyConsentsWrapper,
  StyledIconContainer,
  StyledIconBackPlate,
  StyledDoctorIcon,
  getStyles,
} from './EmptyConsents.styles';

export const EmptyConsents = ({ tab, handlePastConsent }) => {
  const styles = getStyles();

  return (
    <EmptyConsentsWrapper>
      <StyledIconContainer>
        <StyledIconBackPlate />
        <StyledDoctorIcon />
      </StyledIconContainer>
      <Typography
        variant='h5'
        align='center'
        color='primary.darkGray70'
        lineHeight='145%'
      >
        No New Consents
      </Typography>
      <Typography
        fontSize='12px'
        marginTop='5px'
        align='center'
        color='primary.darkGray50'
      >
        Your consents are up to date.
      </Typography>
      {isMobile() && tab === 'ACTIONABLE' && (
        <Button
          variant='contained'
          sx={styles.pastConsentBtn}
          onClick={handlePastConsent}
        >
          Past Consents
          <ArrowForwardOutlinedIcon style={styles.icon} />
        </Button>
      )}
    </EmptyConsentsWrapper>
  );
};
