import { Loader } from 'components/Loader';
import { isValidElement, useEffect } from 'react';
import { useLogout } from 'utils/useLogout';
import { useGetCurrentUser } from './useGetCurrentUser';

export const withPerson = (Component) => (props) => {
  const {
    person,
    userId,
    authenticationStatus,
    isError,
    isSuccess,
    isLoading,
    isUninitialized,
  } = useGetCurrentUser();
  const logout = useLogout();
  // Logout if there is an error or if the person is not found
  useEffect(() => {
    if (isError || (isSuccess && !person)) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess, person]);

  if (isLoading || isUninitialized || isError || (isSuccess && !person)) {
    return <Loader />;
  }

  const componentToRender = (
    <Component
      {...props}
      person={person}
      userId={userId}
      authenticationStatus={authenticationStatus}
    />
  );
  return isValidElement(componentToRender) ? componentToRender : null;
};
