import { Button, styled, Typography } from '@mui/material';
import { isMobile } from '../../utils/isMobile';

export const ContainerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '40px',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0px',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  width: '700px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.05)',
  borderRadius: '8px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '0px',
    width: 'auto',
  },
}));

export const Header = styled('div')(({ theme }) => ({
  padding: '25px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  background: theme.palette.primary.lightGray5,
  [theme.breakpoints.down('sm')]: {
    padding: '12.5px',
  },
}));

export const SubHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  cursor: 'pointer',
}));

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '30px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '20px',
  alignSelf: 'stretch',
  [theme.breakpoints.down('sm')]: {
    gap: '24px',
    padding: '24px',
  },
}));

export const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '145%',
  alignSelf: 'stretch',
}));

export const CardDescription = styled('div')(({ theme, isExpanded }) => ({
  overflow: 'hidden',
}));

export const CardDescriptionContent = styled('div')(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '140%',
  alignSelf: 'stretch',
}));

export const CardDescriptionHide = styled('span')(() => ({
  color: '#33779F',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '140%',
  display: 'inline',
  cursor: 'pointer',
}));

export const CardTitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
  [theme.breakpoints.down('sm')]: {
    gap: '24px',
  },
}));

export const CardContentWapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  alignSelf: 'stretch',
  [theme.breakpoints.down('sm')]: {
    gap: '24px',
  },
}));

export const CardContentTitle = styled(Typography)(({ theme }) => ({
  color: '#696969',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '140%',
}));

export const CardContentDescription = styled(Typography)(({ theme }) => ({
  alignSelf: 'stretch',
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
  wordBreak: 'break-word',
}));

export const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  alignSelf: 'stretch',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '24px',
    alignItems: 'start',
    justifyContent: '',
  },
}));

export const ChipWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const ReadMoreButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '13px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  alignSelf: 'stretch',
  border: '1px solid' + theme.palette.primary.lightGray100,
  borderRadius: '8px',
  background: theme.palette.primary.white,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0.28px',
  color: theme.palette.primary.darkBlue70,
  textTransform: 'none',
  textAlign: 'center',
  fontFamily: 'Inter',
  '& .MuiButton-endIcon': {
    '& svg': {
      '& path': {
        fill: '#4C88AB',
      },
    },
  },
}));

export const FeedbackButton = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '7px 10px',
  alignItems: 'center',
  gap: '7px',
  borderRadius: '15px',
  border: '1px solid' + theme.palette.primary.darkGray70,
  color: theme.palette.primary.darkGray70,
  fontFamily: 'Inter',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '140%',
  letterSpacing: '0.1px',
  textTransform: 'uppercase',
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '140%',
  [theme.breakpoints.down('sm')]: {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    alignSelf: 'stretch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '145%',
  },
}));

export const getStyles = (theme) => {
  return {
    backArrowIcon: {
      cursor: 'pointer',
      ...(isMobile()
        ? {
            width: '10',
            height: '16px',
            color: theme.palette.primary.darkBlue70,
          }
        : {
            width: '10',
            height: '16px',
            color: theme.palette.primary.darkGray50,
          }),
    },
    plusIcon: {
      color: theme.palette.primary.darkGray70,
    },
    truncate: {
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: 2 /* number of lines to show */,
    },
    imageContainer: {
      height: 252,
      width: 640,
    },
    imageContainerMobile: {
      height: 157,
      width: 327,
    },
    imageContainerTablet: {
      height: 157,
      width: 640,
    },
  };
};
