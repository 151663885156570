import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const createPersonApiSlice = createApi({
  reducerPath: 'personApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['Person'],
  endpoints: (build) => ({
    createPerson: build.mutation({
      query: (payload) => ({
        url: `/people`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['Person']
    }),
  })
});

export const {
  useCreatePersonMutation,
} = createPersonApiSlice;
