import { Modal } from '@mui/material';
import {
  ContinueButton,
  ModalContainer,
  Description,
  Title,
  CancelButton,
} from './styles';
import { FTDDRLogo } from 'pages/Onboarding/components/FTDDRLogo';

export const CancelModal = ({
  isOpenModal,
  cancelCallback,
  proceedCallback,
}) => {
  const onCloseModal = () => {
    cancelCallback();
  };
  const navigateBack = () => {
    proceedCallback();
  };
  return (
    <Modal
      open={isOpenModal}
      onClose={onCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ zIndex: 9999999 }}
    >
      <ModalContainer
        sx={{ backgroundColor: (theme) => theme.palette.primary.white }}
      >
        <FTDDRLogo />
        <Title>Unsaved data</Title>
        <ContinueButton variant='contained' onClick={onCloseModal}>
          Continue sharing
        </ContinueButton>
        <CancelButton variant='link' onClick={navigateBack}>
          Leave this page
        </CancelButton>
      </ModalContainer>
    </Modal>
  );
};
