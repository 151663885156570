import {
  StyledContainer,
  StyledStat,
  StyledSubTitle,
  StyledTitle,
} from './styles';
import { useGetOverviewMutation } from 'ihp-bloom-redux/features/registryStatistics/registryStatisticsApi';
import { useEffect, useState } from 'react';
import { Loader } from 'components/Loader';

const RegistryStatisticsOverview = () => {
  const [stats, setStats] = useState([]);
  const [getOverview, result] = useGetOverviewMutation();
  useEffect(() => {
    getOverview();
  }, []);

  useEffect(() => {
    const data = result?.data?.data?.dtp_registry_home_page?.edges?.[0]?.node;
    const items = [];
    items.push({
      label: 'Persons joined SOURCE',
      value: parseInt(data?.stats1 || 0),
    });
    items.push({
      label: 'Persons with SV heart disease',
      value: parseInt(data?.stats2 || 0),
    });
    items.push({
      label: 'Families enrolled',
      value: parseInt(data?.stats3 || 0),
    });

    items.push({
      label: 'States represented',
      value: parseInt(data?.stats4 || 0),
    });

    setStats(items);
  }, [result]);

  return (
    <StyledContainer>
      {result.isLoading && <Loader />}
      {stats?.map((item, index) => (
        <StyledStat key={index}>
          <StyledTitle>{item.value.toLocaleString('en-US')}</StyledTitle>
          <StyledSubTitle>{item.label}</StyledSubTitle>
        </StyledStat>
      ))}
    </StyledContainer>
  );
};

export default RegistryStatisticsOverview;
