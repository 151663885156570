import { Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { LinkButton } from 'components/Buttons';
import { TextField } from 'components/Input/TextField';

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '50px 75px 30px',
  width: 480,
  background: theme.palette.primary.white,
  boxShadow: '0px 0px 30px rgba(0, 84, 135, 0.04)',
  borderRadius: 8,
  boxSizing: 'border-box',
  textAlign: 'center',
  'h2': {
    marginBottom: '15px'
  }
}));

export const StyledDisclaimerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'space-between',
  color: theme.palette.primary.gray75,
  marginTop: 20,
}));

export const StyledTextField = styled((props) => <TextField {...props} />)(
  () => ({
    marginTop: 30,
  })
);

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '145%',
  color: theme.palette.primary.gray75,
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  color: theme.palette.primary.gray15,
  marginTop: 30,
  marginLeft: -40,
  marginRight: -40,
}));

export const ActionsStyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.primary.gray75,
  marginTop: 20,
}));

export const StyledCancelButton = styled(LinkButton)(({ theme }) => ({
  minWidth: 134,
}));

export const StyledInfoCircleBox = styled(Box)(({ theme }) => ({
  marginRight: 10,
  paddingTop: 4,
}));

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '30px 0'
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textTransform: 'none',
  width: '120px',
  paddingTop: '23px',
  paddingBottom: '20px',
  backgroundColor: theme.palette.primary.white,
  border: `1px solid ${theme.palette.primary.gray15} !important`,
  borderRadius: '8px !important',
  boxSizing: 'border-box',
  marginRight: '15px',
  '&:last-of-type': {
    marginRight: '0px'
  },

  'svg': {
    marginBottom: '13px'
  },
  '&.Mui-selected': {
    fontWeight: '600 !important',
    backgroundColor: `${theme.palette.primary.white} !important`,
    color: theme.palette.primary.fuchsia100,
    border: `2px solid ${theme.palette.primary.fuchsia75} !important`

  }
}));
