import { Box, Modal, useTheme } from '@mui/material';
import Redeem from '../..';
import { getStyles } from './styles';

export const RedeemModal = ({
  open,
  onClose,
  availablePoints,
  pointsPerDollar,
  onSuccess,
  threshold,
  studyConfig,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const onClaimSuccess = () => {
    onSuccess && onSuccess();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalContainer}>
        <Redeem
          studyConfig={studyConfig}
          onCancel={onClose}
          availablePoints={availablePoints}
          pointsPerDollar={pointsPerDollar}
          onSuccess={onClaimSuccess}
        />
      </Box>
    </Modal>
  );
};
