import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { CustomSlider } from './styles';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { isCaregiver } from './helper';

export const VerticalSlider = ({
  q,
  healthValue,
  onAnswer,
  setHealthValue,
}) => {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const roleName =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;

  useEffect(() => onAnswer({ [q.id]: healthValue }), []);
  const listItems = isCaregiver(roleName)
    ? q.caregiverDescriptionItems
    : q.descriptionItems;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <Box sx={{ textAlign: 'left', flex: 3, paddingTop: '50px' }}>
          <Typography variant='pl3'>
            <ul>
              {listItems.map((item, i) => {
                return (
                  <li dangerouslySetInnerHTML={{ __html: item }} key={i}></li>
                );
              })}
            </ul>
          </Typography>

          <Typography
            variant='h6'
            sx={{ marginTop: '40px', marginLeft: '20px' }}
          >
            {q.valueText}
            <Typography
              sx={{ display: 'inline' }}
              fontWeight={600}
              color='primary.main'
            >
              {healthValue}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: 'center',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography marginBottom={6} variant='pb3' width={150}>
            {isCaregiver(roleName) ? q.topLabelCaregiver : q.topLabel}
          </Typography>
          <CustomSlider
            sx={{ marginRight: '0px' }}
            aria-label='Temperature'
            valueLabelDisplay='on'
            orientation='vertical'
            defaultValue={30}
            marks={q.marks}
            value={healthValue}
            onChange={(e) => {
              setHealthValue(e.target.value);
            }}
            onChangeCommitted={(e) => {
              onAnswer({ [q.id]: healthValue });
            }}
          />
          <Typography marginTop={5} variant='pb3'>
            {isCaregiver(roleName) ? q.bottomLabelCaregiver : q.bottomLabel}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
