import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const Banner = styled(Box)(({ src, theme }) => ({
  backgroundImage: `url(${src})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  height: '200px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: '130px',
  },
}));

export const Content = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.navy,
  a: {
    color: theme.palette.primary.green,
    fontWeight: 700,
  },
  b: {
    fontWeight: 800,
  },
  li: {
    marginLeft: '32px',
    marginTop: '16px',
  },
  ul: {
    listStyle: 'none',
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '800px',
  padding: '40px',
}));
