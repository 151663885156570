import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { useSelector } from 'ihp-bloom-redux/app/redux';
// import { logout as localLogout } from 'ihp-bloom-redux/features/authentication/authenticationSlice';
import { userApiSliceV3 } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import Button from 'ihp-components/components/v2/Button';
import { Title, Text } from 'pages/Onboarding/components/Text';
import Placeholder from 'images/verifyEmail/verify_email.png';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { participantStatus } from 'utils/authenticate';
import { Loader } from 'components/Loader';
import {
  VerifyEmailWrapper,
  Container,
  Row,
  StyledAlert,
  EmailButton,
  getStyles,
} from './styles';
import { useLogout } from 'utils/useLogout';

function VerifyEmail() {
  document.title = 'Single Ventricle SOURCE Verify Your Email Address';
  const [verification, setVerification] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoggingout, setIsLoggingout] = useState(false);
  const { user, logout } = useAuth0();
  const styles = getStyles();
  // const dispatch = useDispatch();
  const { userId, authenticationStatus } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleLogout = useLogout();

  const [trigger, result] =
    userApiSliceV3.endpoints.resendVerificationEmail.useLazyQuery();

  useEffect(() => {
    if (result.isSuccess) {
      setVerification(result.data);
    }
  }, [result]);

  useEffect(() => {
    const flag =
      window.localStorage.getItem('refreshed') ||
      (user?.email_verified && participantStatus.AUTHENTICATED);
    if (flag) {
      setShowAlert(true);
    }
  }, []);

  const handleRefresh = () => {
    window.localStorage.setItem('refreshed', true);
    logout({ localOnly: true });
    return navigate('/auth0-callback');
  };

  const handleBackToCreateAccount = async () => {
    setIsLoggingout(true);
    // SING-681
    // logout({ returnTo: `${window.location.origin}/welcome?open-auth0=signup` });
    // logout({
    //   returnTo: `${window.location.origin}/onboarding/welcome-to-study`,
    // });
    // dispatch(localLogout());
    // sessionStorage.clear();

    //SING-850
    handleLogout(undefined, 'onboarding/welcome-to-study');
  };

  const handleResendVerification = () => {
    trigger(userId);
    setShowAlert(false);
  };

  const isLoading = isLoggingout;

  if (user?.email_verified && authenticationStatus) {
    return <Navigate to='/auth0-callback' />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <VerifyEmailWrapper>
      <Container>
        <Row>
          <img src={Placeholder} alt='verify email placeholder' />
        </Row>
        <Title>Check your email</Title>
        <Row>
          <Text color='secondary.gray4' marginTop='5px'>
            We sent you an email with instructions on how to complete your sign
            up
          </Text>
        </Row>
        <Row>
          <Text color='secondary.gray4' marginBottom='10px'>
            If you do not see an email in your inbox, please check your spam or
            junk folder
          </Text>
        </Row>
        {showAlert && (
          <Row>
            <StyledAlert severity='warning'>
              Please check your inbox and verify your email before you can
              continue forward
            </StyledAlert>
          </Row>
        )}
        {verification && (
          <Row>
            <StyledAlert severity='success'>
              E-mail confirmation was sent, please check your inbox.
            </StyledAlert>
          </Row>
        )}
        <EmailButton fullWidth startIcon={<EmailIcon color='#7c7c7c' />}>
          <Text
            color='primary.darkGray70'
            fontWeight='600'
            fontSize='16px'
            sx={styles.emailText}
          >
            {user?.email}
          </Text>
        </EmailButton>
        <Row>
          <Button
            onClick={handleResendVerification}
            loading={result.isLoading}
            fullWidth
          >
            Resend email
          </Button>
        </Row>
        <Row marginBottom='0px'>
          <Button size='sm' variant='link' onClick={handleRefresh}>
            I've verified my email
          </Button>
        </Row>
        <Row marginBottom='0px'>
          <Button size='sm' variant='link' onClick={handleBackToCreateAccount}>
            Back to create your account
          </Button>
        </Row>
      </Container>
    </VerifyEmailWrapper>
  );
}

VerifyEmail.propTypes = {
  email: PropTypes.string,
  onRefresh: PropTypes.func,
};

export default VerifyEmail;
