import { useState } from 'react';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { useCreateReporterMutation } from 'ihp-bloom-redux/features/onboarding/reporterPageSlice';
import { useUpdatePersonStudyArmMutation } from 'ihp-bloom-redux/features/personStudyArm/personStudyArmApiSlice';
import { setValues } from 'ihp-bloom-redux/features/user/userSlice';

import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';

import OnboardingLayout from '../components/Layout';
import { FTDDRLogo } from '../components/FTDDRLogo';
import { ReactComponent as PlusIcon } from 'images/shared/plus-blue.svg';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { ReactComponent as TrashIcon } from 'images/shared/trash-3.svg';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-4.svg';
import { ReactComponent as MailIcon } from 'images/profile/add-reporter/email.svg';
import { ReactComponent as PersonIcon } from 'images/profile/add-reporter/user.svg';
import { ReactComponent as PenIcon } from 'images/shared/pen-4.svg';
import getRoute from 'utils/navigator';
import { getEmptyFields } from 'pages/User/utils';
import { Title } from 'pages/Onboarding/components/Text';
import {useValidateEmail, useRegexValidatePhone, removeSpecialCharactersFromPhone} from 'utils/formValidation';
import { relationships } from 'constants/reporters';
import { EditReporterModal } from './EditReporter/editReporterDetailsModal';
import {
  STUDY_ARM_CODES,
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_CODES,
  STUDY_ARM_STATUS_CODES,
  VIRTUAL_SITE,
} from '../../../constants/global';
import {
  AlternativeReporterFullNameContainer,
  AnswerButtonWrapper,
  ButtonContainer,
  CheckIcon,
  DropDownIcon,
  EmailContainer,
  FieldContainer,
  FlexedBox,
  InfoDetail,
  InfoIcon,
  InfoWrapper,
  NameHeaderContainer,
  PhoneContainer,
  RelationContainer,
  ReporterContainer,
  ReporterDetail,
  Row,
  StyledAnswerButton,
  StyledHR,
  getStyles,
  InputSelectWrapper,
} from './styles';
import { FIELD_TYPE_RENDERERS_MAP } from '../../../utils/configParseUtils';
import useScrollToTop from '../../../hooks/useScrollToTop';

function removeEmptyProperties(obj) {
  Object.keys(obj).forEach(
    (key) => (obj[key] === null || obj[key] === '') && delete obj[key]
  );
  return obj;
}

function AddReporterPage() {
  document.title = 'Single Ventricle SOURCE Add Reporter';
  const styles = getStyles();

  useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);
  const [reporterId, setReporterId] = useState(1);
  const [selectedReporter, setSelectedReporter] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(true);
  const [reporters, setReporters] = useState([]);
  const [currentReporter, setCurrentReporter] = useState({
    first_name: '',
    last_name: '',
    email: '',
    confirm_email: '',
    phone: '+',
    relationship: '',
    reporter_consent: false,
  });

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const isYesSelected = selectedOption === 'Yes';
  const isNoSelected = selectedOption === 'No';

  const [createReporter, { isLoading: isCreatingReporter }] =
    useCreateReporterMutation();
  const [updatePersonStudyArm, { isLoading: isUpdatingStudyArm }] =
    useUpdatePersonStudyArmMutation();
  const isLoading = isCreatingReporter || isUpdatingStudyArm;

  const { configuration } = useSelector((state) => state.configuration);
  const user = useSelector((state) => state.user);
  const { activeAccountProfile, person } = user;

  const siteId = VIRTUAL_SITE.id;
  const studyArmId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARMS][
      STUDY_ARM_CODES.RESEARCH
    ].id;
  const studyArmReporterRoleId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
      STUDY_ARM_ROLE_CODES.R_REPORTER
    ].id;
  const studyArmRoleIdForLar =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_ROLES][
      STUDY_ARM_ROLE_CODES.R_LAR
    ].id;
  const studyArmStatusId =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_REPORTER_INVITED
    ].id;
  const studyArmStatusForEnrollmentReporters =
    configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_STATUSES][
      STUDY_ARM_STATUS_CODES.RESEARCH_ENROLLMENT_REPORTERS
    ];

  const studyArmRoleId =
    activeAccountProfile?.attributes?.profile?.study_arm_role_id;
  const accountPersonStudyArmId =
    activeAccountProfile?.attributes?.profile?.account_person_study_arm_id;
  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;

  const country = activeAccountProfile?.attributes?.person?.attributes?.country;

  const customPhoneRenderer = FIELD_TYPE_RENDERERS_MAP['phone'];

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      confirm_email: '',
      phone: '+',
      relationship: '',
      reporter_consent: false,
    },
  });

  const setErrorsForFields = (emptyFields) => {
    emptyFields.forEach((field) =>
      setError(field, { message: 'This field is required!' })
    );
  };

  const onAddReporter = async (data) => {
    data.phone = removeSpecialCharactersFromPhone(data.phone);
    if (data.phone === '' || data.phone === '+') {
      delete data.phone;
    }

    const emptyFields = getEmptyFields(data);
    const filteredEmptyFields = emptyFields.filter(
      (field) => field !== 'phone'
    );

    if (filteredEmptyFields.length !== 0) {
      setErrorsForFields(filteredEmptyFields);
      return;
    }
    if (filteredEmptyFields.length == 0) {
      if (data.email != data.confirm_email) {
        setError('confirm_email', { message: 'Incorrect email' });
      } else {
        setReporters([...reporters, { id: reporterId, ...data }]);
        setCurrentReporter({
          first_name: '',
          last_name: '',
          email: '',
          confirm_email: '',
          phone: '',
          relationship: '',
          reporter_consent: false,
        });
        setReporterId(reporterId + 1);
        reset();
      }
    }
  };

  const getStudyArmPayload = (id) => {
    return {
      data: {
        type: 'person-study-arms',
        id: `${id}`,
        attributes: {
          study_arm_status_id: studyArmStatusForEnrollmentReporters?.id,
        },
      },
    };
  };

  const onUpdateReporter = (data) => {
    data.phone = removeSpecialCharactersFromPhone(data.phone);
    if (data.phone === '' || data.phone === '+') {
      delete data.phone;
    }

    let updatedReporters = reporters.map((reporter) => {
      if (reporter.id === data.id) {
        return data;
      }
      return reporter;
    });
    setReporters(updatedReporters);
  };

  const handleDelete = (id) => {
    const newReporters = reporters.filter((r) => r.id !== id);
    setReporters(newReporters);
  };

  const handleEdit = (id) => {
    const selectedReporter = reporters.filter((r) => r.id === id)[0];
    setSelectedReporter(selectedReporter);
    return setIsOpenModal(true);
  };

  const goBack = () => {
    navigate(-1);
  };

  const validatePhone = useRegexValidatePhone();
  const validateEmail = useValidateEmail();

  const handleCreateReporter = (reporter) => {
    const attributes = {
      first_name: reporter.first_name,
      last_name: reporter.last_name,
      email: reporter.email,
      phone: removeSpecialCharactersFromPhone(reporter.phone),
      relationship: reporter.relationship,
      secondary_contact: reporter.reporter_consent,
      subject:
        activeAccountProfile.attributes.profile.subject_primary_person_id,
      account_holder: person.id,
      account_profile: activeAccountProfile.attributes.profile.id,
      study_arm: studyArmId,
      study_arm_status: studyArmStatusId,
      study_arm_role: studyArmReporterRoleId,
      site: siteId,
      country: country,
    };
    try {
      const payload = {
        data: {
          type: 'reporters',
          attributes: removeEmptyProperties(attributes),
        },
      };

      // Return the promise created by createReporter
      return createReporter(payload);
    } catch (error) {
      console.error('Error creating reporter:', error);
      return Promise.resolve();
    }
  };

  const onSubmit = async (data) => {
    const reporter = getValues();
    if (reporter.first_name) {
      if (reporter.phone === '' || reporter.phone === '+') {
        delete reporter.phone;
      }
      if (reporter.email != reporter.confirm_email) {
        setError('confirm_email', { message: 'Incorrect email' });
        return;
      }
      await handleCreateReporter(reporter);
    }

    const reporterPromise = Promise.all(
      reporters.map(async (reporter) => {
        await handleCreateReporter(reporter);
      })
    );

    try {
      await reporterPromise;
      const updateStudyArmResponse = await updatePersonStudyArm({
        personStudyArmId: accountPersonStudyArmId,
        payload: getStudyArmPayload(accountPersonStudyArmId),
      });

      if (
        subjectPrimaryPersonStudyArmId !== accountPersonStudyArmId &&
        studyArmRoleId == studyArmRoleIdForLar
      ) {
        await updatePersonStudyArm({
          personStudyArmId: subjectPrimaryPersonStudyArmId,
          payload: getStudyArmPayload(subjectPrimaryPersonStudyArmId),
        });
      }

      if (!updateStudyArmResponse.error) {
        const newState = {
          ...user,
          activeAccountProfile: {
            ...user.activeAccountProfile,
            attributes: {
              ...user.activeAccountProfile.attributes,
              profile: {
                ...user.activeAccountProfile.attributes.profile,
                study_arm_status_id: studyArmStatusForEnrollmentReporters?.id,
                study_arm_status_title:
                  studyArmStatusForEnrollmentReporters?.title,
              },
            },
          },
        };
        await dispatch(setValues(newState));
        navigate(getRoute(newState));
      }
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  };

  return (
    <OnboardingLayout maxWidth='415px' backHandler={goBack}>
      <Row>
        <FTDDRLogo />
      </Row>
      <Title>Please consider adding a reporter</Title>
      <Typography
        color='secondary.gray4'
        fontSize='16px'
        align='left'
        marginTop='15px'
      >
        A reporter is someone that you invite to share information about you for
        the research study. It could be someone that you live with, or who
        spends several hours per week with you. Or it may be someone who is not
        your healthcare provider but who helps make decisions for your care and
        support.
      </Typography>
      <ReporterDetail>Would you like to add a Reporter now?</ReporterDetail>

      <AnswerButtonWrapper>
        <StyledAnswerButton
          variant={'outlined'}
          fullWidth
          onClick={() => handleOptionClick('Yes')}
          isSelected={isYesSelected}
          startIcon={isYesSelected && <CheckIcon />}
        >
          Yes
        </StyledAnswerButton>
        <StyledAnswerButton
          variant={'outlined'}
          fullWidth
          onClick={() => handleOptionClick('No')}
          isSelected={isNoSelected}
          startIcon={isNoSelected && <CheckIcon />}
        >
          No
        </StyledAnswerButton>
      </AnswerButtonWrapper>

      <StyledHR />
      {isYesSelected && (
        <>
          <ReporterDetail>
            Please provide the name and email address of anyone you would like
            to be a reporter.
          </ReporterDetail>
          <ul>
            {reporters.map((reporter) => (
              <ReporterContainer key={reporter.email}>
                <Stack>
                  <NameHeaderContainer>
                    <AlternativeReporterFullNameContainer>
                      {reporter.first_name} {reporter.last_name}
                    </AlternativeReporterFullNameContainer>
                    <FlexedBox>
                      <Box
                        sx={{ cursor: 'pointer', marginRight: '16px' }}
                        onClick={() => handleEdit(reporter.id)}
                      >
                        <PenIcon width='14px' height='14px' />
                      </Box>
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(reporter.id)}
                      >
                        <TrashIcon
                          width='14px'
                          height='14px'
                          sx={{ marginTop: '16px' }}
                        />
                      </Box>
                    </FlexedBox>
                  </NameHeaderContainer>
                  <FlexedBox>
                    <PersonIcon width='14px' height='14px' />
                    <RelationContainer>
                      {
                        relationships.find(
                          (r) => reporter.relationship === r.key
                        )?.name
                      }
                    </RelationContainer>
                  </FlexedBox>
                  <FlexedBox>
                    <Box marginTop='7px'>
                      <MailIcon width='14px' height='14px' />
                    </Box>
                    <EmailContainer>{reporter.email}</EmailContainer>
                  </FlexedBox>
                  {reporter?.phone && (
                    <FlexedBox>
                      <Box marginTop='7px'>
                        <PhoneIcon width='13px' height='13px' color='#606B71' />
                      </Box>
                      <PhoneContainer>{reporter.phone}</PhoneContainer>
                      {reporter?.reporter_consent && (
                        <PhoneContainer>(Alternative contact)</PhoneContainer>
                      )}
                    </FlexedBox>
                  )}
                </Stack>
              </ReporterContainer>
            ))}
          </ul>

          <Typography
            color='secondary.gray4'
            fontSize='16px'
            fontWeight='bold'
            width='335px'
          >
            Reporter {reporters.length + 1}
          </Typography>

          <Box>
            <form>
              <FieldContainer>
                <Controller
                  name='first_name'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='first_name'
                      value={currentReporter?.first_name}
                      error={errors.first_name}
                      errorLabel={errors.first_name?.message}
                      label='First name'
                      placeholder='Enter first name'
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Controller
                  name='last_name'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='last_name'
                      error={errors.last_name}
                      value={currentReporter?.last_name}
                      errorLabel={errors.last_name?.message}
                      label='Last name'
                      placeholder='Enter last name'
                      fullWidth
                      {...field}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    validate: validateEmail,
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='email'
                      errorLabel={
                        errors?.email?.message || 'This field is required'
                      }
                      error={errors.email}
                      label='Email'
                      value={currentReporter?.email}
                      placeholder='Enter email address'
                      fullWidth
                      endIcon={<EmailIcon color='#D9D9D9' />}
                      {...field}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Controller
                  name='confirm_email'
                  control={control}
                  rules={{
                    validate: validateEmail,
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='confirm_email'
                      errorLabel={
                        errors?.confirm_email?.message ||
                        'This field is required'
                      }
                      error={errors.confirm_email}
                      label='Confirm Email'
                      value={currentReporter?.confirm_email}
                      placeholder='Enter email address'
                      fullWidth
                      endIcon={<EmailIcon color='#D9D9D9' />}
                      {...field}
                    />
                  )}
                />
              </FieldContainer>
              <FieldContainer>
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: false,
                    validate: (value) => validatePhone(value, country)
                  }}
                  render={({ field }) =>
                    customPhoneRenderer({
                      errorLabel: errors?.phone?.message,
                      error: errors.phone,
                      type: 'text',
                      country,
                      name: 'phone',
                      label: 'Phone (Optional)',
                      placeholder: 'Enter phone',
                      ...field,
                    })
                  }
                />
              </FieldContainer>
              <FieldContainer>
                <Controller
                  name='relationship'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  haserrors={errors.relationship}
                  render={({ field }) => (
                    <InputSelectWrapper
                      error={errors.relationship}
                      errorLabel={errors?.relationship?.message}
                      value={currentReporter?.relationship}
                      type='select'
                      label='Select relationship'
                      fieldLabel='Relationship to participant'
                      placeholder='Select relation'
                      IconComponent={(props) => <DropDownIcon {...props} />}
                      fullWidth
                      {...field}
                    >
                      {relationships?.map(({ id, name, key }) => (
                        <MenuItem key={id} value={key}>
                          {name}
                        </MenuItem>
                      ))}
                    </InputSelectWrapper>
                  )}
                />
              </FieldContainer>

              <FieldContainer>
                <Controller
                  name='reporter_consent'
                  control={control}
                  haserrors={errors.reporter_consent}
                  render={({ field }) => (
                    <Input
                      error={errors.reporter_consent}
                      errorLabel={errors.reporter_consent?.message}
                      type='checkbox'
                      value={currentReporter?.reporter_consent}
                      label='Do you give permission for this person to also be a secondary contact for your account?'
                      checked={getValues('reporter_consent')}
                      containerStyle={styles.checkboxContainer}
                      size='medium'
                      {...field}
                    />
                  )}
                />
              </FieldContainer>

              <InfoWrapper>
                <InfoIcon />
                <InfoDetail>
                  A Secondary Contact is someone that the registry team can
                  reach out to for communication regarding this account.
                </InfoDetail>
              </InfoWrapper>

              <FieldContainer>
                <Button
                  variant='outlined'
                  fullWidth
                  style={{ border: '2px solid' }}
                  onClick={handleSubmit(onAddReporter)}
                  startIcon={<PlusIcon width={14} height={14} />}
                >
                  Add new reporter
                </Button>
              </FieldContainer>
            </form>
          </Box>
        </>
      )}

      <ButtonContainer>
        <Button
          fullWidth
          onClick={
            reporters.length === 0 && isYesSelected
              ? handleSubmit(onSubmit)
              : onSubmit
          }
          disabled={!selectedOption}
          loading={isLoading}
        >
          Save & continue
        </Button>
      </ButtonContainer>

      {selectedReporter != null && (
        <EditReporterModal
          open={isOpenModal}
          onClose={() => {
            setSelectedReporter(null);
            return setIsOpenModal(false);
          }}
          reporter={{
            id: selectedReporter.id,
            relationship: selectedReporter.relationship,
            first_name: selectedReporter.first_name,
            last_name: selectedReporter.last_name,
            email: selectedReporter.email,
            phone: selectedReporter.phone,
            reporter_consent: selectedReporter.reporter_consent,
          }}
          onUpdateReporter={onUpdateReporter}
        />
      )}
    </OnboardingLayout>
  );
}
export default AddReporterPage;
