import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useDispatch } from 'ihp-bloom-redux/app/redux';
import {
  updateConnectingProvider,
  updateLastConnectedProvider,
  updateUserCreationResponse,
} from 'ihp-bloom-redux/features/oneUpHealth/oneUpHealthSlice';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { participantTask } from 'ihp-bloom-redux/features/tasks/tasksSlice';
import { createUser, initiateDataFetch } from 'services/oneUpService';
import { isAndroid } from 'utils/isMobile';

export const useConnectHandler = (
  provider,
  setLoadingProviders,
  setIsRedirecting,
  setConnectingProvider,
  personId,
  doNotCreateUser = false
) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const connectedAt = new Date();
  const selectedParticipantTask = useSelector(participantTask());

  return async () => {
    if (setLoadingProviders) setLoadingProviders(true);
    if (setIsRedirecting) setIsRedirecting(true);
    if (setConnectingProvider) setConnectingProvider(provider.name);
    dispatch(updateConnectingProvider({ ...provider, connectedAt }));
    dispatch(
      updateLastConnectedProvider({
        ...provider,
        connectedAt,
        isCheckStateTransitioned: false,
      })
    );
    // TODO: fallback user id for testing reasons locally patientID can be null
    if (!doNotCreateUser) {
      const participant_id = `${personId}`;
      try {
        const token = await getAccessTokenSilently();
        const response = await createUser(
          token,
          participant_id,
          provider.id,
          provider.name,
          selectedParticipantTask.id
        );

        dispatch(updateUserCreationResponse(response));
        if (isAndroid()) {
          window.location.href = response?.redirect_url;
        } else {
          window.location.replace(response?.redirect_url);
        }
      } catch {
        alert('Something went wrong connecting with the health provider.');
      }
    }

    if (setLoadingProviders) await setLoadingProviders(false);
    if (setConnectingProvider) await setConnectingProvider(null);
  };
};

export const useInitiateDataFetch = (callback) => {
  const { getAccessTokenSilently } = useAuth0();

  const { activeAccountProfile } = useSelector((state) => state.user);
  const personId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;

  const [loading, setLoading] = useState(false);
  const participantId = `${personId}`;
  const initiate = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      await initiateDataFetch(token, participantId);
      setLoading(false);
      if (callback) callback();
    } catch (error) {
      console.log('Something went wrong while initiating data fetch');
      if (callback) callback();
    }
  };

  return { initiate, loading };
};
