import { useGetTerritoriesStatesMutation } from 'ihp-bloom-redux/features/registryStatistics/registryStatisticsApi';
import { useEffect, useState } from 'react';
import { theme } from '../../../theme';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import usaMapData from './usa.geo.json';
import { Loader } from 'components/Loader';
import { isMobile } from 'utils/isMobile';
import { USA_STATES_ISO } from 'utils/constants';

echarts.registerMap('USA', usaMapData, {
  'US-AK': {
    left: -131,
    top: 20,
    width: 18,
  },
  'US-HI': {
    left: -100,
    top: 20,
    width: 7,
  },
  'US-PR': {
    left: -76,
    top: 21,
    width: 4,
  },
});
const RegistryParticipantStatesChart = () => {
  const [getChartData, result] = useGetTerritoriesStatesMutation();
  const [chartOptions, setChartOptions] = useState({});

  const getColor = (distribution) => {
    if (distribution >= 10.01) {
      return theme.palette.primary.main;
    } else if (distribution >= 5.01 && distribution <= 10) {
      return theme.palette.primary.queenBlue;
    } else if (distribution >= 1 && distribution <= 5) {
      return theme.palette.primary.darkBlue50;
    } else if (distribution < 1 && distribution > 0) {
      return theme.palette.secondary.lightBlue4;
    } else {
      return theme.palette.primary.lightGray100;
    }
  };

  const getStateName = (isoCode) => {
    const state = USA_STATES_ISO.find((state) => state.iso_code === isoCode);
    return state.name || null;
  };

  const getDistribution = (distribution) => {
    if (distribution >= 10.01) {
      return '>=10.01%';
    } else if (distribution >= 5.01 && distribution <= 10) {
      return '5.01-10%';
    } else if (distribution >= 1 && distribution <= 5) {
      return '1-5%';
    } else if (distribution < 1 && distribution > 0) {
      return '<1%';
    } else {
      return '0%';
    }
  };

  const getChartOptions = (data) => {
    return {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `<span style='font-size: 12px; font-weight: 500'>${getStateName(
            params.name
          )}:</span><br> 
          <span style='width: 16px; height: 16px; display: inline-block; vertical-align: middle; background-color: ${getColor(
            parseFloat(params.value)
          )}; border-radius: 50%; margin-right: 5px;'></span>
          <span style='font-size: 14px; font-weight: 500; vertical-align: middle'>${getDistribution(
            parseFloat(params.value)
          )}</span>`;
        },
      },
      visualMap: {
        type: 'piecewise',
        orient: isMobile() ? 'vertical' : 'horizontal',
        itemSymbol: 'circle',
        selectedMode: false,
        itemWidth: '16px',
        itemHeight: '16px',
        pieces: [
          {
            value: '0%',
            label: '0%',
            color: theme.palette.primary.lightGray100,
          },
          {
            value: '1%',
            label: '<1%',
            color: theme.palette.secondary.lightBlue4,
          },
          {
            value: '1-5%',
            label: '1-5%',
            color: theme.palette.primary.darkBlue50,
          },
          {
            value: '5.01-10%',
            label: '5.01-10%',
            color: theme.palette.primary.queenBlue,
          },
          {
            value: '>=10.01%',
            label: '>=10.01%',
            color: theme.palette.primary.main,
          },
        ],
      },
      title: {
        text: 'Geographic Distribution of Participants',
        textStyle: {
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
      series: [
        {
          name: 'US States Heatmap',
          type: 'map',
          map: 'USA',
          geoJson: usaMapData,
          selectedMode: false,
          legend: false,
          roam: false,
          label: {
            show: false,
          },
          itemStyle: {
            normal: {
              borderColor: 'rgb(255,255,255)',
              areaColor: theme.palette.primary.lightGray100,
              borderWidth: 1,
            },
            emphasis: {
              areaColor: 'rgb(255,147,0)',
              label: {
                show: false,
              },
            },
          },
          data,
        },
      ],
    };
  };

  useEffect(() => {
    getChartData();
  }, []);

  useEffect(() => {
    if (result.data) {
      const chartResponseData =
        result?.data?.data?.dtp_heatmap_territories_state?.edges;

      const mapData = chartResponseData?.map((item) => {
        return {
          name: item?.node?.state,
          value: item?.node?.percent,
          itemStyle: {
            areaColor: getColor(item?.node?.percent),
          },
        };
      });
      const chartOptionsData = getChartOptions(mapData);
      setChartOptions(chartOptionsData);
    }
  }, [result]);

  if (result.isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ReactECharts
        option={chartOptions}
        style={{ height: '500px', width: '100%' }}
      />
    </>
  );
};

export default RegistryParticipantStatesChart;
