import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EmailIcon } from 'images/shared/email-verification-failed.svg';
import { useDispatch, useSelector } from 'ihp-bloom-redux/app/redux';
import { userApiSliceV3 } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import { logout as localLogout } from 'ihp-bloom-redux/features/authentication/authenticationSlice';
import Button from 'ihp-components/components/v2/Button';
import { Loader } from 'components/Loader';

import {
  Wrapper,
  Container,
  Row,
  TitleContainer,
  ButtonContainer,
  PageTitle,
  HelperText,
  getStyles,
} from './styles';

function Auth0EmailVerificationFailed() {
  document.title = 'Single Ventricle SOURCE Email Verification Failed';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = getStyles();
  const { user, logout } = useAuth0();
  const { userId } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  const [trigger, result] =
    userApiSliceV3.endpoints.resendVerificationEmail.useLazyQuery();

  const handleResendVerification = () => {
    trigger(userId);
  };

  const handleBackToCreateAccount = async () => {
    setIsLoading(true);
    logout({
      returnTo: `${window.location.origin}/onboarding/welcome-to-study?open-auth0=signup`,
    });
    dispatch(localLogout());
    sessionStorage.clear();
  };

  useEffect(() => {
    if (user?.email_verified) {
      return navigate('/auth0-callback');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (result.isSuccess) {
      navigate('/verify-email');
    }
  }, [result.isSuccess]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <Container>
        <Row>
          <EmailIcon width={200} height={181} />
        </Row>
        <TitleContainer>
          <PageTitle varient='h2'>Email verification expired</PageTitle>
        </TitleContainer>
        <Row>
          <HelperText varient='pl3'>
            Please click resend email to generate a new verification link.
          </HelperText>
        </Row>
        <ButtonContainer>
          <Button
            size='md'
            fullWidth
            sx={styles.resendButton}
            loading={result.isLoading}
            onClick={handleResendVerification}
          >
            Resend email
          </Button>
          <Button
            size='sm'
            variant='link'
            sx={styles.createAccountButton}
            fullWidth
            onClick={handleBackToCreateAccount}
          >
            Back to create your account
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
}

export default Auth0EmailVerificationFailed;
