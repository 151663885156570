import {
  getTaskAttribute,
  getTaskDuration,
  getTaskActionText,
  P_TASK_TYPES,
} from 'pages/Activities/utils';
import {
  daysBetweenDates,
  dateFormatter,
  convertUTCStringToLocalDateObject,
} from './date';
import { formatDate } from 'pages/NotificationCenter/PregnancyJournal/Detailed/utils';
import { convertStatusDateFormat } from 'pages/Onboarding/Consents/utils';

// This function filters all children tasks to their corresponding parent (group task).
// To the group task a property type="group" and subtasks with all children tasks are added.
export const filterParticipantTasks = (patientTasks, isPast) => {
  const formattedTasks = patientTasks.reduce((acc, currVal) => {
    if (currVal.data.relationship.length === 0 || isPast) {
      acc.push(currVal.data);
      return acc;
    }

    const isGroupTask = currVal.data.relationship.find(
      (item) => item.type === 'group'
    );

    const taskGroupObj = { ...currVal.data, type: '', subtasks: [] };

    if (isGroupTask) {
      taskGroupObj.type = 'group';
      taskGroupObj.subtasks = [];
      taskGroupObj.relationship.forEach((item) => {
        const child = patientTasks.find(
          (el) => el.data.id === item.associate_participant_task_id
        );
        if (!child) return;
        taskGroupObj.subtasks.push(child.data);
      });
      const areSubtasksCompleted = taskGroupObj.subtasks.every(
        (item) => item.status === 'completed'
      );
      if (!areSubtasksCompleted) {
        acc.push(taskGroupObj);
      }
    } else {
      acc.push(currVal.data);
    }
    return acc;
  }, []);
  return formattedTasks;
};

const countCompletedSubtasks = (subtasks) => {
  const completedTotal = subtasks.reduce((acc, currVal) => {
    if (currVal.status === 'completed') {
      acc += 1;
    }
    return acc;
  }, 0);
  return completedTotal;
};

export const formatParticipantTasks = (patientTasks, shouldNotReverse = false) => {
  const tasks = patientTasks.map((pt) => {
    const remaining = pt.due_date
      ? calculateRemainingDays(pt.due_date, pt)
      : pt.status_date
      ? calculateRemainingDays(pt.status_date, pt)
      : '';
    const duration = getTaskDuration(pt.duration) || '';
    const howTo = {};
    const celebration = {};
    let htmlPage = null;
    const currentDate = new Date();
    const due_date = new Date(pt?.due_date?.replace(/-/g, '/'));
    const diff = daysBetweenDates(currentDate, due_date);
    const isLockedStatuses = ['upcoming', 'locked', 'awaiting_verification'];
    const isActionDisabledStatuses = [
      'upcoming',
      'locked',
      'awaiting_verification',
      'past',
    ];

    return {
      id: pt.id,
      code: pt.code,
      participantTaskId: pt.id,
      personId: pt.person_id,
      personStudyArmId: pt.person_study_arm_id,
      title: pt.task_title,
      description: pt.description,
      type: getParticipantTaskType(pt.task_type),
      duration: duration != null ? duration : '',
      actionText: getTaskActionText(pt.meta),
      status: pt.status,
      statusDate: dateFormatter(pt.status_date),
      statusDateFull: pt.start_date,
      required: pt.required_behavior,
      date: pt.start_date,
      end_date: pt.end_date,
      daysUntilDueDate: diff,
      points: pt.point,
      remaining,
      due_date: pt.due_date,
      isOverdue: pt.display_status === 'overdue',
      isDueTomorrow: remaining?.label === '1 day left to complete',
      isPast: pt?.display_status === 'past',
      isActionDisabled: isActionDisabledStatuses.includes(pt.display_status),
      isLocked: isLockedStatuses.includes(pt.display_status),
      displayStatus: pt.display_status,
      required_behavior: pt.required_behavior,
      howTo,
      celebration,
      htmlPage,
      completedSubtasks: pt.subtasks
        ? countCompletedSubtasks(pt.subtasks)
        : null,
      subtasks: pt.subtasks ? formatParticipantTasks(pt.subtasks) : null,
      consentVersion: pt.consent_version_id || 1,
      consentVersionDate: pt.consent_version_date || pt.statusDate, // TODO: fix this when real consent tasks are fetched from backend
      consentStatus: pt.consent_status,
      consentStatusDate: pt.consent_status_date
        ? convertStatusDateFormat(
            convertUTCStringToLocalDateObject(pt.consent_status_date)
          )
        : null,
    };
  });

  return shouldNotReverse ? tasks : tasks.reverse();
};

export const getHowTo = (task) => {
  let display = true;
  let displayParam = getTaskAttribute(task, 'howTo', 'display');
  if (displayParam) {
    display = displayParam;
  }
  return {
    title: getTaskAttribute(task, 'howTo', 'title'),
    subtitle: getTaskAttribute(task, 'howTo', 'subtitle'),
    instructions: getTaskAttribute(task, 'howTo', 'instructions'),
    display,
  };
};

export const getCelebration = (task) => {
  let display = true;
  let displayParam = getTaskAttribute(task, 'celebrationScreen', 'display');
  if (displayParam) {
    display = displayParam;
  }
  return {
    title: getTaskAttribute(task, 'celebrationScreen', 'title'),
    description: getTaskAttribute(task, 'celebrationScreen', 'description'),
    display,
  };
};

export const mapTaskRequiredStatus = (status) => {
  if (status === 'must' || status === 'blocking') {
    return 'REQUIRED';
  }
};

const getParticipantTaskType = (type) => {
  if (type === 'data_sharing') {
    return P_TASK_TYPES.DATA_SHARING;
  }
  if (type === 'call_schedule') {
    return P_TASK_TYPES.SCHEDULE_TELEHVISIT;
  }
  if (type === 'info') {
    return P_TASK_TYPES.ARTICLE;
  }
  return type;
};

export const calculateRemainingDays = (date, pt) => {
  let result = {
    label: '',
  };
  const currentDate = new Date();
  const endDate = new Date(date?.replace(/-/g, '/'));
  const diff = daysBetweenDates(currentDate, endDate);

  if (pt.display_status === 'active') {
    if (diff > 1) {
      result.label = `${isNaN(diff) ? 0 : diff} days left to complete`;
    } else {
      result.label = `${isNaN(diff) ? 0 : diff} day left to complete`;
    }
  }

  if (pt.display_status === 'upcoming') {
    if (diff < 0) {
      result.label = `${diff * -1} ${diff === -1 ? 'day' : 'days'} overdue`;
      result.color = '#C080B1';
    } else if (diff > 1) {
      result.label = `Unlocks in ${diff} days`;
    } else {
      result.label = 'Unlocks in 1 day';
    }
  }

  const isCompleted = pt?.status === 'completed';

  if (pt.display_status === 'past' && isCompleted) {
    const statusDate = dateFormatter(pt.status_date);
    if (isCompleted) {
      result.label = `Completed ${formatDate(statusDate, 'MM-dd-yyyy')}`;
    }
  }

  if (pt.display_status === 'past' && !isCompleted) {
    result.label = 'Non compliant';
  }

  return result;
};

function isDueToday(date) {
  const currentDate = new Date();
  const inputDate = convertUTCStringToLocalDateObject(date);
  const timeDifference = inputDate - currentDate;
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  return timeDifference <= millisecondsPerDay;
}

export const categorizeCurrentActivities = (activities) => {
  const currentDueImmediatelyStatuses = ['blocking', 'reopen', 'overdue'];
  let dueImmediately = [];
  let dueThisWeek = [];
  let otherActivities = [];
  let overdue = [];

  activities
    .sort(
      (activity1, activity2) =>
        new Date(activity1.due_date?.replace(/-/g, '/')) -
        new Date(activity2.due_date?.replace(/-/g, '/'))
    )
    .forEach((activity) => {
      if (
        activity.displayStatus === 'locked' ||
        activity.displayStatus === 'active'
      ) {
        if (isDueToday(activity.due_date)) {
          dueImmediately.push(activity);
        } else if (activity.daysUntilDueDate <= 7) {
          dueThisWeek.push(activity);
        }
      }

      if (
        activity.displayStatus === 'awaiting_verification' ||
        activity.daysUntilDueDate > 7 ||
        isNaN(activity.daysUntilDueDate)
      ) {
        otherActivities.push(activity);
      }

      if (currentDueImmediatelyStatuses.includes(activity.displayStatus)) {
        dueImmediately.push(activity);
      }

      if (activity.isOverdue) {
        overdue.push(activity);
      }
    });

  return {
    dueImmediately,
    dueThisWeek,
    otherActivities,
    overdue,
  };
};

export function humanizeTaskType(str) {
  var i,
    frags = str?.split('_');
  for (i = 0; i < frags?.length || 0; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags?.join(' ');
}
