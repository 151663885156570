import { useState, useEffect } from 'react';
import _ from 'lodash';
import { theme } from 'theme';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetPersonStudyArmSubRoleQuery } from 'ihp-bloom-redux/features/personStudyArmSubRole/personStudyArmSubRoleApiSlice';
import { useGetAllPersonStudyArmAttributesQuery } from 'ihp-bloom-redux/features/personStudyArmAttribute/personStudyArmAttributeApiSlice';

import {
  getObjectBasedOnKey,
  ROLE_MAP,
  subRoleLists,
  getReporterSubroleSet,
  getIParticipantSubroleSet,
} from './helper';
import { useStudyContributors } from './useRelationships';

import { Loader } from 'components/Loader';
import { FormCheckbox } from 'components/Checkbox';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as CheckIcon } from 'images/shared/check-bold.svg';
import { getSortedTabs, LANG } from 'utils/configParseUtils';

import { Title } from '../../styles';
import {
  ContributorsWrapper,
  Text,
  FooterContainer,
  getStyles,
} from './styles';

import {
  STUDY_ARM_CONFIG_VARIABLES,
  STUDY_ARM_ROLE_NAME,
  STUDY_ARM_SUB_ROLE_CODES,
} from 'constants/global';

const Relationships = () => {
  // Styles
  const styles = getStyles();

  // Store
  const {
    participantProfileConfig: { participantProfile },
  } = useSelector((state) => state.configuration);

  const { activeAccountProfile } = useSelector((state) => state.user);
  const { configuration } = useSelector((state) => state.configuration);

  // Simplyfied values from store.
  const profileRole = activeAccountProfile?.attributes?.profile;
  const studyContributorsTab = getSortedTabs(participantProfile?.tabs).filter(
    (tab) => tab?.meta?.label?.[LANG] === 'Study Contributors'
  )[0];

  const personStudyArmId = profileRole?.account_person_study_arm_id;

  // API Slices
  // all users but Reporters
  const {
    data: subRoleData,
    isLoading: isGetSubRoleStudyLoading,
    refetch: reFetchSubRole,
    isFetching: isReFetchingSubRole,
  } = useGetPersonStudyArmSubRoleQuery(personStudyArmId, {
    skip:
      profileRole.study_arm_role_name !==
      STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT,
  });

  // Only reporters
  const {
    data: reporterAttributesData,
    isLoading: isReporterAttributesLoading,
  } = useGetAllPersonStudyArmAttributesQuery(
    {
      personStudyArmId,
    },
    {
      skip: profileRole.study_arm_role_name !== STUDY_ARM_ROLE_NAME.REPORTER,
    }
  );

  // useStudyContributors hooks
  const {
    createPersonStudyArmSubRoleRecord,
    deletePersonStudyArmSubRoleRecord,
    isLoading,
  } = useStudyContributors();

  // State
  const [activeSubRole, setActiveSubRole] = useState(new Set([]));

  const [selectedIds, setSelectedIds] = useState([]);

  // Fields relative objects from studyContrutorsTab
  const relationships = getObjectBasedOnKey(
    studyContributorsTab?.fields,
    'relationships'
  );

  const iParticipantSubrole = getObjectBasedOnKey(
    studyContributorsTab?.fields,
    'iparticipant_subrole'
  );

  const reportersRelationshipsSubRole = getObjectBasedOnKey(
    studyContributorsTab?.fields,
    'reporters_relationships'
  );

  // Inject preselected subrole or reporter relationships to
  // the activeSubRole state, and refetch while delete/create
  useEffect(() => {
    reFetchSubRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    injectPreSelectedRoleToState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReporterAttributesLoading, isGetSubRoleStudyLoading, subRoleData]);

  const injectPreSelectedRoleToState = () => {
    if (
      profileRole.study_arm_role_name === STUDY_ARM_ROLE_NAME.REPORTER &&
      !isReporterAttributesLoading
    ) {
      const reporterSubRole = getReporterSubroleSet(
        reporterAttributesData?.data
      );
      setActiveSubRole(reporterSubRole);
    }
    if (
      profileRole.study_arm_role_name ===
        STUDY_ARM_ROLE_NAME.INDEPENDENT_PARTICIPANT &&
      !isGetSubRoleStudyLoading
    ) {
      const iParticipantSubRole = getIParticipantSubroleSet(subRoleData);
      const ids = subRoleData?.data.map((role) => parseInt(role?.id));
      setSelectedIds(ids);
      setActiveSubRole(iParticipantSubRole);
    }
  };

  // Subrole onChange method
  const handleOnChange = (value) => {
    const subRoles = new Set(activeSubRole);
    if (subRoles.has(value)) subRoles.delete(value);
    else subRoles.add(value);
    setActiveSubRole(subRoles);
  };

  // Cancel
  const cancel = () => injectPreSelectedRoleToState();

  const callOnlyDeleteRequest = async (preSelectedIds, id) => {
    const filteredId = preSelectedIds.filter((pid) => pid !== id);
    if (filteredId.length) {
      const res = await deletePersonStudyArmSubRoleRecord({
        personStudyArmId,
        selectedIds: filteredId,
      });

      if (res?.data?.data.length) {
        res?.data?.data.map((item) => {
          const updatedSelectedIds = _.filter(
            selectedIds,
            (id) => id !== parseInt(item.id)
          );
          return setSelectedIds(updatedSelectedIds);
        });
      }
    }
  };

  // Save Subroles
  const handleSave = async () => {
    let preSelectedIds = _.uniq([...selectedIds]);

    const ids = [];

    subRoleLists(activeSubRole)
      .slice(0, 2)
      .forEach((role) => {
        if (role.checked) {
          switch (role.subRole) {
            case STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER:
              const biologicalFamilyMemberStudyArmSubRole =
                configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_SUB_ROLES][
                  STUDY_ARM_SUB_ROLE_CODES
                    .I_PARTICIPANT_BIOLOGICAL_FAMILY_MEMBER
                ];
              ids.push(biologicalFamilyMemberStudyArmSubRole?.id);

              break;
            case STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_CAREGIVER:
              const caregiverStudyArmSubRole =
                configuration[STUDY_ARM_CONFIG_VARIABLES.STUDY_ARM_SUB_ROLES][
                  STUDY_ARM_SUB_ROLE_CODES.I_PARTICIPANT_CAREGIVER
                ];
              ids.push(caregiverStudyArmSubRole?.id);

              break;
            default:
              break;
          }
        }
      });
    if (!ids.length) return;
    ids.map(async (id) => {
      if (!selectedIds.length) {
        await createPersonStudyArmSubRoleRecord(personStudyArmId, id);
        setSelectedIds([...selectedIds, id]);
        return;
      }
      if (
        ids.length === 1 &&
        preSelectedIds.length === 1 &&
        !preSelectedIds.includes(id)
      ) {
        await callOnlyDeleteRequest(preSelectedIds, id);

        await createPersonStudyArmSubRoleRecord(personStudyArmId, id);
        setSelectedIds([id]);
        return;
      }

      if (ids.length === 1 && preSelectedIds.length === 2) {
        await callOnlyDeleteRequest(preSelectedIds, id);
        return;
      }

      if (
        ids.length === 2 &&
        preSelectedIds.length === 1 &&
        !preSelectedIds.includes(id)
      ) {
        await createPersonStudyArmSubRoleRecord(personStudyArmId, id);
        if (ids.length > 1 && !preSelectedIds.includes(id)) {
          setSelectedIds([...selectedIds, id]);
        }
        return;
      }
    });
  };

  // i_participant Subrole section
  const renderIParticipantSubRole = () => {
    if (isGetSubRoleStudyLoading || isReFetchingSubRole) return <Loader />;

    return (
      <div>
        <Text>{iParticipantSubrole?.label?.[LANG]} (Check all that apply)</Text>

        {subRoleLists(activeSubRole)
          .slice(0, 2)
          .map((option) => (
            <FormCheckbox
              containerStyle={{
                ...styles.checkboxContainerSquare,
                ...(option.checked ? styles.checkboxActive : {}),
              }}
              key={option.value}
              value={option.value}
              label={option.label}
              checked={option.checked}
              onChange={() => handleOnChange(option.subRole)}
            />
          ))}

        <FooterContainer>
          <Button
            sx={{ fontSize: '16px' }}
            onClick={cancel}
            size='sm'
            variant='link'
          >
            Cancel
          </Button>
          <Button
            sx={{ fontSize: '16px', width: '105px' }}
            size='sm'
            onClick={() => handleSave()}
            loading={isLoading}
          >
            <CheckIcon /> Save
          </Button>
        </FooterContainer>
      </div>
    );
  };

  // reporters_relationships SubRole section
  const renderReportersRelationshipsSubRole = () => {
    if (isReporterAttributesLoading) return <Loader />;

    return (
      <div>
        <Text>
          {reportersRelationshipsSubRole?.label?.[LANG]} (Check all that apply)
        </Text>

        {subRoleLists(activeSubRole).map((option) => (
          <FormCheckbox
            containerStyle={{
              ...styles.checkboxContainerSquare,
              ...(option.checked ? styles.checkboxActive : {}),
            }}
            key={option.value}
            value={option.value}
            label={option.label}
            checked={option.checked}
            onChange={() => {}}
          />
        ))}
      </div>
    );
  };

  return (
    <ContributorsWrapper>
      {/* Relationship block */}
      <Title>{relationships?.label?.[LANG]}</Title>
      <FormCheckbox
        label={
          ROLE_MAP[
            activeAccountProfile?.attributes?.profile?.study_arm_role_name
          ]
        }
        disabled
        checked={true}
        containerStyle={{
          ...styles.checkboxContainerSquare,
          ...styles.checkboxActive,
        }}
      />
      {!_.isEmpty(iParticipantSubrole) && renderIParticipantSubRole()}
      {!_.isEmpty(reportersRelationshipsSubRole) &&
        renderReportersRelationshipsSubRole()}
    </ContributorsWrapper>
  );
};

export default Relationships;
