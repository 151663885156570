import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useSendFamilyInvitationMutation } from 'ihp-bloom-redux/features/family/familyApiSlice';

export function useSendFamilyInvitation() {
  const { person } = useSelector((state) => state.user);
  const [sendFamilyInvitation, mutation] = useSendFamilyInvitationMutation();

  function submit({ email, firstName, lastName }) {
    sendFamilyInvitation({
      personId: person.id,
      payload: {
        data: {
          type: 'family-invitations',
          attributes: {
            invitee_email: email,
            invitee_first_name: firstName,
            invitee_last_name: lastName,
          },
        },
      },
    });
  }

  return {
    mutation,
    submit,
  };
}
