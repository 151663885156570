import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const manualProvidersApiSlice = createApi({
  reducerPath: 'manualProvidersApi',
  baseQuery: baseQuery('v3'),
  tagTypes: ['ManualProviders'],
  endpoints: (build) => ({
    getManualProviders: build.query({
      query: (participantId, participant_task_id) => ({
        url: participant_task_id
          ? `/people/${participantId}/providers?filter[participant_task_id]=${participant_task_id}`
          : `/people/${participantId}/providers`,
        method: 'GET',
      }),
      providesTags: ['ManualProviders'],
    }),
    createManualProvider: build.mutation({
      query: ({ participantId, payload }) => ({
        url: `/people/${participantId}/providers`,
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ManualProviders'],
    }),
    updateManualProvider: build.mutation({
      query: ({ participantId, providerId, payload }) => ({
        url: `/people/${participantId}/providers/${providerId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ManualProviders'],
    }),
    deleteManualProvider: build.mutation({
      query: ({ participantId, providerId }) => ({
        url: `/people/${participantId}/providers/${providerId}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['ManualProviders'],
    }),
    updateStatus: build.mutation({
      query: ({ participantId, providerId, payload }) => ({
        url: `/people/${participantId}/providers/${providerId}`,
        method: 'PATCH',
        body: JSON.stringify(payload),
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
        },
      }),
      invalidatesTags: ['Providers'],
    }),
  }),
});

export const {
  useGetManualProvidersQuery,
  useCreateManualProviderMutation,
  useUpdateManualProviderMutation,
  useDeleteManualProviderMutation,
  useUpdateStatusMutation,
} = manualProvidersApiSlice;
