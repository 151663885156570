import React from 'react';

import { ReactComponent as ArticleBadge } from 'images/profile/achievements/article.svg';
import { ReactComponent as ArticleFilledBadge } from 'images/profile/achievements/article-filled.svg';
import { ReactComponent as Cup } from 'images/profile/achievements/cup.svg';

import {
  MobileCardWrapper,
  MobileCardInfoBox,
  StyledSlider,
  Title,
  RewardContainer,
  StyledChip,
  MyPoints,
} from '../styles.mobile.js';

export const MobileArticleReadCard = (props) => {
  const { inProgress, isCompleted } = props;
  return (
    <MobileCardWrapper backgroundColor={isCompleted ? '#E0EAF9' : '#ffffff'}>
      {!inProgress ? <ArticleBadge /> : <ArticleFilledBadge />}
      <MobileCardInfoBox>
        <Title>
          <MyPoints>0 &nbsp;</MyPoints> / 5 Read articles
        </Title>
        <StyledSlider
          value={100}
          isCompleted={isCompleted}
          backgroundColor='#2F80ED'
        />
        <RewardContainer>
          <StyledChip
            icon={<Cup />}
            label='Dolor sit amet'
            isCompleted={isCompleted}
          />
        </RewardContainer>
      </MobileCardInfoBox>
    </MobileCardWrapper>
  );
};
