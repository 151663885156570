const getStyles = (theme) => ({
  fileContainer: {
    border: `1px solid ${theme.palette.primary.gray25}`,
    padding: '10px 20px 10px 10px',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    background: 'white',
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.primary.gray50,
    },
  },
  img: {
    marginRight: '15px',
    'img': {
      borderRadius: '5px',
      width: '35px',
      height: '35px',
    }
  },
  details: {
    flex: 1
  },
  uploadInfoText: {
    textTransform: 'none',
    color: theme.palette.primary.gray50,
  },
  removeBtn: {
    width: '10px',
    height: '15px',
    cursor: 'pointer',
    color: theme.palette.primary.gray50,
  }
});

export default getStyles;