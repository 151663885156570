export const SIZE_STYLES = {
  lg: {
    height: '56px',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '15px',
    paddingBottom: '15px',
    width: '500px',
  },
  md: {
    height: '50px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
    gap: '10px',
    width: '500px',
  },
  sm: {
    height: '46px',
    paddingLeft: '18px',
    paddingRight: '18px',
    paddingTop: '12px',
    paddingBottom: '12px',
    width: '400px',
  },
  xs: {
    height: '42px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '300px',
  },
};

export const stylesColorMap = ({ theme }) => ({
  hover: {
    border: theme.palette.primary.darkGray50,
    placeholder: theme.palette.primary.darkGray50,
    color: theme.palette.primary.darkGray100,
  },
  focused: {
    border: theme.palette.primary.darkBlue70,
    placeholder: theme.palette.primary.darkGray50,
    color: theme.palette.primary.darkGray100,
  },
  idle: {
    border: theme.palette.primary.lightGray100,
    placeholder: theme.palette.primary.darkGray50,
    color: theme.palette.primary.darkGray100,
  },
  error: {
    border: theme.palette.primary.red100,
  }
});
