import { OverlayModal } from 'components/Modal';
import { RECEIVE_INVITE_ERROR_MODAL } from 'features/familyLinkage/constants';
import { Text, Title } from 'features/familyLinkage/styles';
import { ReactComponent as Fail } from 'images/shared/fail-checkmark.svg';
import { useNavigate } from 'react-router-dom';

export function FamilyLinkageErrorModal({ onClose, open }) {
  const navigate = useNavigate();

  const modalContent = (
    <>
      <Fail width={200} height={198} />
      <Title>{RECEIVE_INVITE_ERROR_MODAL.title}</Title>
      <Text>{RECEIVE_INVITE_ERROR_MODAL.description}</Text>
    </>
  );

  const modalButtonsConfig = [
    {
      text: RECEIVE_INVITE_ERROR_MODAL.buttonText.seeInvitation,
      handleClick: () => navigate('/profile/family'),
    },
    {
      variant: 'link',
      text: RECEIVE_INVITE_ERROR_MODAL.buttonText.close,
      handleClick: onClose,
    },
  ];

  return (
    <OverlayModal
      open={open}
      onClose={onClose}
      modalContent={modalContent}
      modalButtonsConfig={modalButtonsConfig}
    />
  );
}
