import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ErrorCode = styled(Typography)(({ theme }) => ({
  fontSize: '100px',
  lineHeight: '120px',
  fontFamily: 'Inter',
  textAlign: 'center',
  color: theme.palette.primary.fuchsia30,
  fontWeight: 'bold',
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontFamily: 'Inter',
  lineHeight: '32px',
  fontWeight: '600',
  color: theme.palette.primary.black1,
  textAlign: 'center',
}));
