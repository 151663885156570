import { Stack, Typography, Box, useTheme } from '@mui/material';
import Button from 'ihp-components/components/v2/Button';
import { isMobile } from 'utils/isMobile';
import { ReactComponent as ArrowForwardOutlinedIcon } from 'images/shared/chevron-right.svg';
import { ReactComponent as AwardIcon } from 'images/profile/my-consents/award.svg';
import { Tags } from '../Tags';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { STUDY_ARM_ROLE_NAME } from '../../../../constants/global';
import {
  ConsentDescription,
  ConsentTitle,
  Container,
  DescriptionWrapper,
  DurationText,
  StyledConsentIcon,
  TagsWrapper,
  TopRow,
} from './styles';
import { getStyles } from '../styles';

export const Main = ({
  title,
  description,
  consentVersion,
  consentVersionDate,
  handlePastConsent,
  isSigned,
  handleSignConsent,
  handleNavigateToConsentDetail,
  points,
  duration,
  tab,
  type,
  ...rest
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const { activeAccountProfile } = useSelector((state) => state.user);
  const fullName = `${activeAccountProfile?.attributes?.profile?.first_name} ${activeAccountProfile?.attributes?.profile?.last_name}`;
  const roleName =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;
  const isLar = roleName === STUDY_ARM_ROLE_NAME.LAR;

  if (isMobile()) {
    return (
      <>
        <Container flex={1} {...rest}>
          <TopRow>
            <StyledConsentIcon />
            <Stack direction='column'>
              {points && (
                <Stack
                  direction='row'
                  height='fit-content'
                  alignItems='center'
                  justifyContent='flex-end'
                  color='primary.darkGray70'
                  gap='10px'
                >
                  <AwardIcon />
                  <Typography fontSize='18px' fontWeight={700}>
                    {points || 0}
                  </Typography>
                </Stack>
              )}

              {duration && (
                <DurationText>
                  ESTIMATED TIME: {duration.toUpperCase()}
                </DurationText>
              )}
            </Stack>
          </TopRow>

          <DescriptionWrapper>
            {title && <ConsentTitle>{title}</ConsentTitle>}
            {isLar && isMobile() && (
              <Stack spacing='10px'>
                <ConsentDescription>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                  {isLar && ` | On behalf of ${fullName}`}
                </ConsentDescription>
              </Stack>
            )}
            {description && (
              <ConsentDescription>{description}</ConsentDescription>
            )}
          </DescriptionWrapper>
          <TagsWrapper>
            <Tags isSigned={isSigned} {...rest} />
          </TagsWrapper>
          <Box
            display='flex'
            flexWrap='wrap'
            justifyContent='center'
            alignItems='self-end'
          >
            {tab === 'ACTIONABLE' || rest.isRejected ? (
              <Button
                variant='outlined'
                onClick={handleSignConsent}
                sx={styles.mobileButton}
                fullWidth
              >
                Review & accept
                <ArrowForwardOutlinedIcon sx={styles.icon} />
              </Button>
            ) : (
              <Button
                variant='outlined'
                sx={styles.mobileButton}
                onClick={handleNavigateToConsentDetail}
                fullWidth
              >
                {rest?.required_behavior === 'should'
                  ? 'Review & accept'
                  : 'Review & download'}
                <ArrowForwardOutlinedIcon sx={styles.icon} />
              </Button>
            )}
          </Box>
        </Container>
        {tab === 'ACTIONABLE' && (
          <Button
            variant='contained'
            sx={styles.pastConsentBtn}
            onClick={handlePastConsent}
          >
            Past Consents
            <ArrowForwardOutlinedIcon color='primary.main' sx={styles.icon} />
          </Button>
        )}
      </>
    );
  }

  return (
    <Container flex={1} {...rest}>
      <Stack spacing='10px'>
        <Stack direction='row'>
          <StyledConsentIcon />
          <Stack width='100%' paddingLeft='20px'>
            <Stack direction='row' justifyContent='space-between'>
              <Stack spacing='7px'>
                <Stack direction='row' justifyContent='space-between'>
                  {title && <ConsentTitle>{title}</ConsentTitle>}
                  {duration && (
                    <Typography
                      fontSize='10px'
                      fontWeight={500}
                      marginTop='8px'
                      width='max-content'
                      minWidth='210px'
                    >
                      ESTIMATED TIME: {duration.toUpperCase()}
                    </Typography>
                  )}
                  {!duration && <Box minWidth='210px'></Box>}
                </Stack>
                {isLar ? (
                  <Stack spacing='10px'>
                    <ConsentDescription>
                      {type.charAt(0).toUpperCase() + type.slice(1)}
                      {isLar && ` | On behalf of ${fullName}`}
                    </ConsentDescription>
                  </Stack>
                ) : (
                  <Typography fontSize='13px' fontStyle='italic' mx={1}>
                    Consent
                  </Typography>
                )}
                {description && (
                  <ConsentDescription>{description}</ConsentDescription>
                )}
              </Stack>
              <Stack direction='column'>
                {points && (
                  <Stack
                    direction='row'
                    height='fit-content'
                    alignItems='center'
                    justifyContent='flex-end'
                    color='primary.darkGray70'
                    gap='10px'
                  >
                    <AwardIcon />
                    <Typography fontSize='18px' fontWeight={700}>
                      {points || 0}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Stack> 
            <Box
              width='100%'
              display='flex'
              flexWrap='wrap'
              justifyContent='space-between'
              alignItems='self-end'
            >
              <Tags isSigned={isSigned} {...rest} />
              {tab === 'ACTIONABLE' || rest.isRejected ? (
                <Button
                  variant='outlined'
                  sx={styles.desktopButton}
                  endIcon={<ArrowForwardOutlinedIcon />}
                  onClick={handleSignConsent}
                >
                  Review & accept
                </Button>
              ) : isSigned ? (
                <Button
                  variant='outlined'
                  sx={styles.desktopButton}
                  onClick={handleNavigateToConsentDetail}
                  endIcon={<ArrowForwardOutlinedIcon />}
                >
                  View & download
                </Button>
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};
