import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { Input } from 'ihp-components/components/v2/Input';
import { FilledButton } from 'components/Buttons';

export const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '95vh',
}));

export const InstructionsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray2,
  borderTop: '1px solid ' + theme.palette.primary.gray10,
  borderBottom: '1px solid ' + theme.palette.primary.gray10,
  flexGrow: 1,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  height: '8vh',
  display: 'flex',
  justifyContent: 'flex-end',
  paddingTop: '15px',
}));

export const MobileButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '15px',
  justifyContent: 'center',
}));

export const SpinnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '100px',
  margin: 'auto',
}));

export const LeftContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 25px 25px 25px',
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  maxWidth: '820px',
  width: '100%',
  margin: '0 auto',
}));

export const BottomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  background: 'white',
  gap: '5px',
  padding: '15px 35px 12px',
  [theme.breakpoints.down('sm')]: {
    background: theme.palette.primary.gray20,
    flexDirection: 'column',
  },
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '40%',
  padding: '50px',
  flexDirection: 'column',
  background: '#FBFBFB',
}));

export const ConnectContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.gray2,
  color: theme.palette.secondary.gray4,
  [theme.breakpoints.up('sm')]: {
    backgroundColor: theme.palette.primary.gray20,
  },
}));

export const ConnectContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '40px',
  maxWidth: '295px',
  height: 'calc(100vh - 120px)',
  margin: 'auto',
  paddingTop: '59px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '325px',
    height: '100vh',
    paddingTop: '0px',
  },
}));

export const getStyles = (theme) => ({
  h4: {
    lineHeight: '29px',
  },
  text: {
    lineHeight: '20px',
  },
});

export const SearchBox = styled(Input)(({ theme }) => ({
  // borderRadius: '0px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginTop: '10px',
  marginRight: 'auto',
  marginLeft: 'auto',
  minWidth: '320px',
  borderColor: theme.palette.primary.gray10,
  [theme.breakpoints.up('sm')]: {
    borderColor: theme.palette.primary.gray10,
    borderRadius: '8px',
    height: '50px',
    paddingLeft: '8px',
    paddingRight: '17px',
    marginTop: '0px',
  },
}));

export const SearchProvidersTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontWeight: 600,
  fontSize: '24px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  paddingBottom: '15px',
}));

export const SearchProvidersTitleMobile = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  fontWeight: 600,
  fontSize: '24px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  paddingLeft: '15px',
}));

export const CentralContainer = styled(Box)(({ theme }) => ({
  paddingTop: '35px',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: '60%',
}));

export const ShareMedicalRecords = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.darkGray100,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '145%',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  marginLeft: '20px',
  cursor: 'pointer',
}));

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '25px',
  gap: '10px',
  background: '#FEFEFE',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0px',
  },
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.primary.darkGray100,
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '18.9px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  [theme.breakpoints.down('sm')]: {
    alignSelf: 'left',
    paddingLeft: '10px',
  },
}));

export const RightHeaderItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginLeft: '10px',
  alignItems: 'center',
}));

export const RightHeaderTextWrapper = styled(Box)(({ theme }) => ({
  marginTop: '-1px',
  marginLeft: '5px',
}));

export const AddNewProviderText = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '17px',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  marginLeft: '19px',
}));

export const AddNewProviderContainer = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  height: '57px',
  paddingLeft: '20px',
  paddingRight: '20px',
  marginTop: '10px',
  marginRight: 'auto',
  marginLeft: 'auto',
  minWidth: '320px',
  background: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  border: `2px solid ${theme.palette.primary.main}`,
}));

export const ModalContainer = styled(Box)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.white,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    width: '405px',
    height: '366px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '10px 40px 0px 40px',
  };
});

export const ContinueButton = styled(FilledButton)(() => ({
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '20px',
  width: '325px',
  height: '56px',
  marginTop: '30px',
}));

export const Description = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '20px',
  color: theme.palette.primary.darkGray100,
  textAlign: 'center',
  marginTop: '15px',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '33.6px',
  color: theme.palette.primary.darkGray100,
}));
