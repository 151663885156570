import { Box, TextareaAutosize, InputLabel } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTextArea = styled(TextareaAutosize)(({ theme, isMouseIn, isActive, hasError }) => ({
  width: `100%`,
  maxWidth: `100%`,
  backgroundColor: 'transparent',
  fontFamily: theme.typography.fontFamily,
  '::placeholder': {
    color: theme.palette.primary.gray50,
    fontSize: 14,
  },
  '& :focus, :focus-visible': {
    outline: 'none',
  },
  border: `1px solid ${hasError
    ? theme.palette.primary.red75
    : isActive
      ? theme.palette.primary.fuchsia75
      : isMouseIn
        ? theme.palette.primary.gray50
        : theme.palette.primary.gray25
    }`,
  padding: 13,
  borderRadius: 8,
  backgroundColor: theme.palette.primary.white,
}));
