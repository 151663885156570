import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, useTheme } from '@mui/material';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as Completed } from 'images/shared/done-checkmark.svg';
import { ReactComponent as ColorPalleteIcon } from 'images/activity/types/color-pallete.svg';
import { ReactComponent as DollarIcon } from 'images/shared/dollar.svg';
import { ReactComponent as AwardIcon } from 'images/payment/award.svg';

import {
  PageWrapper,
  Container,
  Row,
  PageTitle,
  HelperText,
  RewardBadge,
  RewardPoints,
  PointsContainer,
} from './styles';

function Enrolled() {
  document.title = 'Single Ventricle SOURCE You Are Enrolled';
  const theme = useTheme();
  const navigate = useNavigate();
  const { onboardingReward } = useSelector((state) => state.user);

  const onContinue = () => {
    navigate('/my-activities');
  };

  return (
    <PageWrapper>
      <Container>
        <Row>
          <Completed width={200} height={198} />
        </Row>
        <Row marginBottom='10px'>
          <PageTitle varient='h2'>You are enrolled!</PageTitle>
        </Row>
        <Row marginBottom='15px'>
          <HelperText varient='pl3'>
            You successfully created your account and are ready to start
            participating in the study
          </HelperText>
        </Row>
        <Stack direction='column' spacing='8px' alignItems='center'>
          {onboardingReward?.map((reward) => (
            <RewardBadge>
              {reward?.reward_type === 'badge' && (
                <>
                  <PointsContainer
                    noPadding={true}
                    svgWidth='40px'
                    svgHeight='40px'
                  >
                    <ColorPalleteIcon width={20} />
                  </PointsContainer>
                  <Stack direction='row' spacing='5px' alignItems='center'>
                    <Typography variant='pn1'>{reward.reward_name}</Typography>
                  </Stack>
                </>
              )}
              {reward?.reward_type === 'points' && (
                <>
                  <Stack
                    direction='row'
                    spacing='5px'
                    alignItems={'center'}
                    sx={{ color: theme.palette.primary.green100 }}
                  >
                    <AwardIcon width={18} />
                    <RewardPoints
                      sx={{ color: theme.palette.primary.green100 }}
                    >
                      {reward.reward_amount}
                    </RewardPoints>
                  </Stack>
                  <span>Points earned</span>
                </>
              )}
              {reward?.reward_type?.includes('payment_') && (
                <>
                  <PointsContainer>
                    <DollarIcon width={20} />
                  </PointsContainer>
                  <Stack direction='row' spacing='5px' alignItems='center'>
                    <RewardPoints>{reward.reward_amount}</RewardPoints>
                    <Typography variant='pn1'>USD earned</Typography>
                  </Stack>
                </>
              )}
            </RewardBadge>
          ))}
        </Stack>
        <Row>
          <Button onClick={onContinue} fullWidth sx={{ marginTop: '15px' }}>
            Sign in
          </Button>
        </Row>
      </Container>
    </PageWrapper>
  );
}

export default Enrolled;
