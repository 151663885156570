import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import CelebrationScreen from 'pages/Activities/CelebrationScreen';
import Button from 'ihp-components/components/v2/Button';
import { useNavigate } from 'react-router-dom';
import { useInitiateDataFetch } from '../hooks';
import { buttonTextStyle, StyledCheckIcon } from './style';

function FinalPageMobile({ celebrationScreen }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const onSuccess = () => navigate('/my-activities');

  const { initiate, loading } = useInitiateDataFetch(onSuccess);

  return (
    <Box
      sx={{
        bgcolor: theme.palette.primary.gray20,
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <CelebrationScreen attributes={{ ...celebrationScreen }} />
        <Button
          type='submit'
          size='large'
          sx={{ minWidth: '303px', marginBottom: '20px', marginTop: '30px' }}
          onClick={async () => {
            await initiate();
          }}
        >
          {!loading ? (
            <>
              <StyledCheckIcon />
              <span style={buttonTextStyle}>Done</span>
            </>
          ) : (
            <CircularProgress sx={{ color: 'white' }} size={30} />
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default FinalPageMobile;
