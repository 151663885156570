import { BaseHeader } from 'components/BaseLayout';
import { StyledBaseLayout } from 'pages/NotificationCenter/MedicationTracker/styles';
import { DrugCardList } from './DrugCardList';
import { getMedicationTrackerPath } from 'pages/NotificationCenter/paths';

const AllMedicationsDesktop = () => (
  <StyledBaseLayout
    header={
      <BaseHeader forceShowClickBack to={getMedicationTrackerPath()}>
        All medications
      </BaseHeader>
    }
    content={<DrugCardList />}
  />
);

export default AllMedicationsDesktop;
