import { useState, useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useGetConsentDocumentMutation } from 'ihp-bloom-redux/features/consent/consentApi';
import { useGetConsentsListQuery } from 'ihp-bloom-redux/features/consent/consentApiV3';
import { useGetCurrentUserQuery } from 'ihp-bloom-redux/features/user/userApiSlice';
import {
  useGetConsentVersionsMutation,
  useUpdateConsentStatusMutation,
} from 'ihp-bloom-redux/features/consent/consentVersionApi';

const useGetPerson = () => {
  const { person, isLoading, isFetching } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => ({
      person: data?.data?.included?.[0]?.[0]?.data,
      ...rest,
    }),
  });

  return {
    person,
    isLoadingCurrentUser: isLoading,
    isFetchingCurrentUser: isFetching,
  };
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
  );
}

export const useRejectConsent = () => {
  const [updateConsentStatus, { isLoading: isRejecting }] =
    useUpdateConsentStatusMutation();
  const { person } = useGetPerson();
  const [getConsentStatus] = useGetConsentVersionsMutation();

  const rejectConsent = (consentVersionId) =>
    getConsentStatus({ person_id: person.id })
      .then((res) => {
        const selectedConsent = res?.data?.data?.find(
          (c) =>
            c?.attributes?.consent_version_id.toString() ===
            consentVersionId.toString()
        );
        return selectedConsent?.id;
      })
      .then((personConsentVersionId) => {
        updateConsentStatus({
          personId: person.id,
          consentVersionId: personConsentVersionId,
          status: 'REJECTED',
          date: formatDate(new Date()),
        });
      })
      .then(console.log)
      .catch(console.error);

  return [rejectConsent, isRejecting];
};

export const useAcknowledgeConsent = () => {
  const [updateConsentStatus, { isLoading: isAcknowledging }] =
    useUpdateConsentStatusMutation();
  const { person } = useGetPerson();
  const [getConsentStatus] = useGetConsentVersionsMutation();

  const acknowledgeConsent = (consentVersionId) =>
    getConsentStatus({ person_id: person.id })
      .then((res) => {
        const selectedConsent = res?.data?.data?.find(
          (c) =>
            c?.attributes?.consent_version_id.toString() ===
            consentVersionId.toString()
        );
        return selectedConsent?.id;
      })
      .then((personConsentVersionId) => {
        updateConsentStatus({
          personId: person.id,
          consentVersionId: personConsentVersionId,
          status: 'COMPLETE',
          date: formatDate(new Date()),
        });
      })
      .then(console.log)
      .catch(console.error);

  return [acknowledgeConsent, isAcknowledging];
};

export const useOpenSignedConsent = () => {
  const [isOpening, setOpening] = useState(false);
  const [documentNotFound, setDocumentNotFound] = useState(false);
  const [getConsentDocument] = useGetConsentDocumentMutation();

  const openSignedConsent = (personId, consent_version_id) => {
    setOpening(true);
    setDocumentNotFound(false);

    // Open a new window immediately and write initial HTML with a spinner
    const newWindow = window.open('', '_blank');
    newWindow.document.write(`
      <html>
        <head>
          <title>Loading...</title>
          <style>
            .spinner-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh; /* Full viewport height */
                position: relative;
            }

            .spinner {
                border: 16px solid #f3f3f3;
                border-top: 16px solid #3498db;
                border-radius: 50%;
                width: 120px;
                height: 120px;
                animation: spin 2s linear infinite;
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style>
        </head>
        <body>
            <div class="spinner-container">
                <div class="spinner"></div>
            </div>
        </body>
      </html>
    `);

    getConsentDocument({
      person_id: personId,
      consent_version_id,
    })
      .then(({ error, data }) => {
        if (error && error.status === 404) {
          setDocumentNotFound(true);
          newWindow.close();
          return;
        }
        if (data) {
          // Create a URL for the document and set it as the new window's location
          const url = window.URL.createObjectURL(data);
          newWindow.location.href = url;
          window.URL.revokeObjectURL(url);
        }
        setOpening(false);
      })
      .catch(() => {
        newWindow.close();
        setOpening(false);
      });
  };

  return [openSignedConsent, isOpening, documentNotFound];
};

export const useGetConsents = (person, studyRoleId) => {
  const { user } = useAuth0();
  const { consents, isLoading, isFetching, error } = useGetConsentsListQuery(
    {
      person_id: person?.id,
      study_role_id: studyRoleId,
      study_version_id: 1,
    },
    {
      skip: !person?.id || !user?.email_verified || !studyRoleId,
      selectFromResult: ({ data, ...rest }) => {
        // for some weird reasons, the data structure returned keeps changing
        // from data.consents to data.data.consents.

        const consents = data?.consents || data?.data?.consents;
        return {
          consents: consents?.length ? consents : [],
          ...rest,
        };
      },
    }
  );
  const filterConsentsByStatus = useCallback(
    (status) =>
      consents
        .filter((consent) => consent.participant_consent_status === status)
        .sort((a, b) => {
          return new Date(a.participant_consent_date).getTime() >
            new Date(b.participant_consent_date).getTime()
            ? -1
            : 1;
        }),
    [consents]
  );
  const sortedConsents = useMemo(() => {
    const noStatusConsents = filterConsentsByStatus();
    const incompleteConsents = filterConsentsByStatus('not_completed');
    const pendingConsents = filterConsentsByStatus('pending');
    const completedConsents = filterConsentsByStatus('completed');
    const rejectedConsents = filterConsentsByStatus('rejected');
    const archivedConsents = filterConsentsByStatus('archived');
    return [
      ...noStatusConsents, // new consents usually come without a status field
      ...incompleteConsents,
      ...pendingConsents,
      ...completedConsents,
      ...rejectedConsents,
      ...archivedConsents,
    ];
  }, [filterConsentsByStatus]);
  return {
    consents: sortedConsents,
    isConsentsLoading: isLoading,
    isConsentsFetching: isFetching,
    error,
    person,
  };
};
