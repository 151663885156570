import { styled, Typography } from '@mui/material';

export const ConsentSignedWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.white,
  alignItems: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100vw',
  height: '100vh',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.primary.white,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export const Container = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
  },
}));

export const Row = styled('div')(({ marginBottom }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '20px',
}));

export const ButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: 20,
}));

export const HelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkGray70,
  fontSize: '14px',
}));
