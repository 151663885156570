import { CircularProgress, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { TimelineCard, TimelineDayCard } from './timelineCard';
import { Box } from '@mui/system';
import { SpinnerContainer } from 'pages/SyncData/SearchProviders/styles';

export default function Timeline({ tasks, isLoadingTasks }) {
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const types = Object.keys(tasks);
  const padding = isMobile ? '0px' : '20px';
  const bgColor = isMobile
    ? theme.palette.primary.gray5
    : theme.palette.primary.gray20;

  const spinner = (
    <SpinnerContainer>
      <CircularProgress color='primary' />
    </SpinnerContainer>
  );
  if (isLoadingTasks) return spinner;

  return (
    <Stack sx={{ bgcolor: bgColor, padding: padding }}>
      {types.map((taskType, typeIndex) => {
        return (
          <Box key={taskType}>
            {tasks[taskType]?.length > 0 && (
              <TimelineDayCard
                title={taskType.replace('Tasks', '').toUpperCase()}
                count={tasks[taskType]?.length}
              />
            )}
            {tasks[taskType]?.map((timeline, tIndex) => {
              const isLastTimeline = tIndex === tasks[taskType]?.length - 1;
              const isLastDay = typeIndex === types.length - 1;
              return (
                <TimelineCard
                  key={tIndex + typeIndex + taskType}
                  showLine={!(isLastTimeline && isLastDay)}
                  name={timeline.title}
                  {...timeline}
                  category={taskType.replace('Tasks', '')}
                />
              );
            })}
          </Box>
        );
      })}
    </Stack>
  );
}
