import { Fields } from './Fields';
import { Header } from './Header';
import { Container, StyledAlert } from './styles';

export const Content = ({
  availablePoints,
  pointsPerDollar,
  value,
  onChange,
  error,
  studyConfig,
}) => (
  <Container>
    <Header availablePoints={availablePoints} />
    <Fields
      availablePoints={availablePoints}
      pointsPerDollar={pointsPerDollar}
      value={value}
      onChange={onChange}
      studyConfig={studyConfig}
    />
    {error && <StyledAlert severity='warning'>{error}</StyledAlert>}
  </Container>
);
