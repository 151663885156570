import Modal from '@mui/material/Modal';

import HelpPopupContent from '../HelpPopupContent/HelpPopupContent';

const HelpModel = ({ toggleShouldShowPopup }) => {
    return (
        <Modal
            open={true}
            onClose={toggleShouldShowPopup}
        >
            <HelpPopupContent toggleShouldShowPopup={toggleShouldShowPopup} />
        </Modal>
    );
};

export default HelpModel;
