import React from 'react';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useAppEventsMutation } from 'ihp-bloom-redux/features/user/userApiSliceV3';
import { useTheme } from '@mui/material/styles';
import { Footer, getStyles } from './styles';
import { STUDY_ARM_ROLE_NAME } from '../../../../constants/global';
import { useResearchEligibility } from '../../../Onboarding/ResearchEligibility/useResearchEligibility';
import { ReactComponent as CheckIcon } from 'images/shared/check-white-2.svg';
import {
  getParticipantProfileTabInfo,
  multiselect,
} from 'utils/configParseUtils';
import Button from 'ihp-components/components/v2/Button';
import { Loader } from 'components/Loader';
import useConditions from './useConditions';
import { Grid, useMediaQuery } from '@mui/material';

const Conditions = () => {
  document.title = 'Single Ventricle SOURCE Participant profile conditions';

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = getStyles(theme, isMobile);

  const { participantProfileConfig } = useSelector(
    (state) => state.configuration
  );
  const { activeAccountProfile } = useSelector((state) => state.user);
  const { fieldsMap } = getParticipantProfileTabInfo(
    participantProfileConfig,
    'Conditions'
  );

  // STATUSES
  const subjectPrimaryPersonStudyArmId =
    activeAccountProfile?.attributes?.profile
      ?.subject_primary_person_study_arm_id;
  const studyArmRole =
    activeAccountProfile?.attributes?.profile?.study_arm_role_name;

  const {
    options,
    setOptions,
    isLoading: isAttributesLoading,
    setIsCancelled,
    refetch,
  } = useConditions(subjectPrimaryPersonStudyArmId);

  const rolesCanEdit = [
    STUDY_ARM_ROLE_NAME.AdultSV,
    STUDY_ARM_ROLE_NAME.AdultNonSV,
    STUDY_ARM_ROLE_NAME.LARSV,
    STUDY_ARM_ROLE_NAME.LARSVChildUnder7,
    STUDY_ARM_ROLE_NAME.LARSVChild7ToMajority,
    STUDY_ARM_ROLE_NAME.LARNonSV,
    STUDY_ARM_ROLE_NAME.LARNonSVChildUnder7,
    STUDY_ARM_ROLE_NAME.LARNonSVChild7ToMajority,
    STUDY_ARM_ROLE_NAME.ParentNonSVChild7ToMajority,
    STUDY_ARM_ROLE_NAME.ParentNonSVChildUnder7,
    STUDY_ARM_ROLE_NAME.ParentSVChild7ToMajority,
    STUDY_ARM_ROLE_NAME.ParentSVChildUnder7,
  ];
  const rolesCanView = [STUDY_ARM_ROLE_NAME.REPORTER];

  const { createPersonStudyArmAttributeRecord, isLoading } =
    useResearchEligibility();

  const [appEvents, { isLoading: isLoadingEvents }] = useAppEventsMutation();

  const handleOnChange = (value) => {
    const updatedOptions = [...options];
    const itemIndex = updatedOptions.findIndex((item) => item.value === value);
    if (itemIndex !== -1) {
      updatedOptions[itemIndex].checked = !updatedOptions[itemIndex].checked;
    }
    setOptions(updatedOptions);
    setIsCancelled(false);
  };

  const getFormattedOptions = () => {
    return options.map((option, index) => ({
      [option.value]: `diagnosis_${index + 1}`,
      checked: option.checked,
    }));
  };

  const submitData = async (
    subjectPrimaryPersonStudyArmId,
    selectedOptions
  ) => {
    try {
      await createPersonStudyArmAttributeRecord(
        subjectPrimaryPersonStudyArmId,
        selectedOptions
      );
      refetch?.();
    } catch (error) {
      console.error('error', error);
    }
  };
  const isButtonDisabled = options?.some((item) => item.checked);

  const handleSave = async () => {
    const allOptions = getFormattedOptions();

    await submitData(subjectPrimaryPersonStudyArmId, allOptions);
    await updateConditionsEvents(subjectPrimaryPersonStudyArmId);
  };

  const updateConditionsEvents = async (subjectPrimaryPersonStudyArmId) => {
    // API is triggered after we click the save button after selecting conditions (my profile) and events are saved
    const payload = {
      data: {
        type: 'person_study_arm_status_change',
        attributes: {
          person_study_arm_id: subjectPrimaryPersonStudyArmId,
        },
      },
    };
    await appEvents({ payload });
  };

  if (isAttributesLoading) {
    return <Loader />;
  }
  return (
    <Grid
      xs={isMobile ? 12 : 6}
      sx={!isMobile && !isTablet ? { maxWidth: '578px' } : {}}
      key={'name'}
    >
      {fieldsMap?.map((field) => {
        const {
          type,
          name,
          label,
          placeholder,
          Component: Field,
          disabled,
          colSpan,
          validation,
          show,
          ...rest
        } = field;
        if (!show) return null;
        if (type === multiselect) {
          return (
            <Field
              title={label}
              options={options}
              styles={styles}
              key={field?.value}
              disabled={rolesCanView.includes(studyArmRole)}
              handleOnChange={(value) => handleOnChange(value)}
            />
          );
        } else {
          return (
            <Field
              containerStyle={styles?.formCheckBox}
              key={field?.value}
              value={field?.value}
              label={field?.label}
              checked={field?.checked}
              onChange={() => handleOnChange(field?.value)}
            />
          );
        }
      })}
      {rolesCanEdit.includes(studyArmRole) && (
        <Footer>
          <Button
            sx={{ px: 0, fontSize: '14px', fontWeight: '600' }}
            variant='link'
            onClick={() => setIsCancelled(true)}
          >
            Cancel
          </Button>
          <Button
            loading={isLoading || isLoadingEvents}
            disabled={!isButtonDisabled || isLoading || isLoadingEvents}
            height='50px'
            startIcon={<CheckIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Footer>
      )}
    </Grid>
  );
};

export default Conditions;
