export const taskTypes = [
  {
    label: 'Survey',
    value: 'survey',
  },
  {
    label: 'Data sharing',
    value: 'dataSharing',
  },
  {
    label: 'TeleResearch',
    value: 'teleResearch',
  },
  {
    label: 'Video',
    value: 'video',
  },
];

export const errorCodes = {
  unauthorized: 401,
};

export const LOGOUT_TIME_IN_MINUTES = 20;

export const PAGE_LIMIT = 1000000;


export const USA_STATES_ISO = [
  {
    'name': 'Alabama',
    'iso_code': 'US-AL'
  },
  {
    'name': 'Alaska',
    'iso_code': 'US-AK'
  },
  {
    'name': 'Arizona',
    'iso_code': 'US-AZ'
  },
  {
    'name': 'Arkansas',
    'iso_code': 'US-AR'
  },
  {
    'name': 'California',
    'iso_code': 'US-CA'
  },
  {
    'name': 'Colorado',
    'iso_code': 'US-CO'
  },
  {
    'name': 'Connecticut',
    'iso_code': 'US-CT'
  },
  {
    'name': 'Delaware',
    'iso_code': 'US-DE'
  },
  {
    'name': 'Florida',
    'iso_code': 'US-FL'
  },
  {
    'name': 'Georgia',
    'iso_code': 'US-GA'
  },
  {
    'name': 'Hawaii',
    'iso_code': 'US-HI'
  },
  {
    'name': 'Idaho',
    'iso_code': 'US-ID'
  },
  {
    'name': 'Illinois',
    'iso_code': 'US-IL'
  },
  {
    'name': 'Indiana',
    'iso_code': 'US-IN'
  },
  {
    'name': 'Iowa',
    'iso_code': 'US-IA'
  },
  {
    'name': 'Kansas',
    'iso_code': 'US-KS'
  },
  {
    'name': 'Kentucky',
    'iso_code': 'US-KY'
  },
  {
    'name': 'Louisiana',
    'iso_code': 'US-LA'
  },
  {
    'name': 'Maine',
    'iso_code': 'US-ME'
  },
  {
    'name': 'Maryland',
    'iso_code': 'US-MD'
  },
  {
    'name': 'Massachusetts',
    'iso_code': 'US-MA'
  },
  {
    'name': 'Michigan',
    'iso_code': 'US-MI'
  },
  {
    'name': 'Minnesota',
    'iso_code': 'US-MN'
  },
  {
    'name': 'Mississippi',
    'iso_code': 'US-MS'
  },
  {
    'name': 'Missouri',
    'iso_code': 'US-MO'
  },
  {
    'name': 'Montana',
    'iso_code': 'US-MT'
  },
  {
    'name': 'Nebraska',
    'iso_code': 'US-NE'
  },
  {
    'name': 'Nevada',
    'iso_code': 'US-NV'
  },
  {
    'name': 'New Hampshire',
    'iso_code': 'US-NH'
  },
  {
    'name': 'New Jersey',
    'iso_code': 'US-NJ'
  },
  {
    'name': 'New Mexico',
    'iso_code': 'US-NM'
  },
  {
    'name': 'New York',
    'iso_code': 'US-NY'
  },
  {
    'name': 'North Carolina',
    'iso_code': 'US-NC'
  },
  {
    'name': 'North Dakota',
    'iso_code': 'US-ND'
  },
  {
    'name': 'Ohio',
    'iso_code': 'US-OH'
  },
  {
    'name': 'Oklahoma',
    'iso_code': 'US-OK'
  },
  {
    'name': 'Oregon',
    'iso_code': 'US-OR'
  },
  {
    'name': 'Pennsylvania',
    'iso_code': 'US-PA'
  },
  {
    'name': 'Rhode Island',
    'iso_code': 'US-RI'
  },
  {
    'name': 'South Carolina',
    'iso_code': 'US-SC'
  },
  {
    'name': 'South Dakota',
    'iso_code': 'US-SD'
  },
  {
    'name': 'Tennessee',
    'iso_code': 'US-TN'
  },
  {
    'name': 'Texas',
    'iso_code': 'US-TX'
  },
  {
    'name': 'Utah',
    'iso_code': 'US-UT'
  },
  {
    'name': 'Vermont',
    'iso_code': 'US-VT'
  },
  {
    'name': 'Virginia',
    'iso_code': 'US-VA'
  },
  {
    'name': 'Washington',
    'iso_code': 'US-WA'
  },
  {
    'name': 'West Virginia',
    'iso_code': 'US-WV'
  },
  {
    'name': 'Wisconsin',
    'iso_code': 'US-WI'
  },
  {
    'name': 'Wyoming',
    'iso_code': 'US-WY'
  },
  {
    'name': 'Puerto Rico',
    'iso_code': 'US-PR'
  }
];

export const HOMEPAGE_ROUTE_PATHS = {
  WELCOME: 'welcome',
  GET_INVOLVED: 'get-involved',
  ABOUT: 'about',
  FOR_CLINICIANS: 'for-clinicians',
  FAQ: 'faq',
  RESOURCES: 'resources'
};

export const SVSOURCE_CONTACT = {
  EMAIL: 'contact@svsource.org',
  PHONE: '(650) 561-6750'
};

export const LABELS = {
  UNKNOWN: 'Unknown'
};