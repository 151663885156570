import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../__base';

export const walletApiSlice = createApi({
  reducerPath: 'walletApiSlice',
  baseQuery: baseQuery('v1'),
  endpoints: (build) => ({
    createWalletTransaction: build.mutation({
      query: ({ person_id, payload }) => ({
        url: `/person/${person_id}/wallet-transactions`,
        method: 'POST',
        body: payload,
      }),
    }),
    convertPoints: build.mutation({
      query: ({ person_id, payload }) => ({
        url: `/persons/${person_id}/convert-point`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Payment'],
    }),
    getWalletTransactions: build.query({
      query: ({ person_id, ...params }) => ({
        url: `/persons/${person_id}/wallet-transactions`,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const {
  useCreateWalletTransactionMutation,
  useConvertPointsMutation,
  useGetWalletTransactionsQuery,
} = walletApiSlice;
