import React from 'react';

import { useSelector } from 'ihp-bloom-redux/app/redux';

import ParticipantInformation from '.';

function ParticipantInformationPage() {
  const { activeAccountProfile } = useSelector((state) => state.user);
  const subjectPersonId =
    activeAccountProfile?.attributes?.profile?.subject_primary_person_id;
  return <ParticipantInformation subjectPersonId={subjectPersonId} />;
}

export default ParticipantInformationPage;
