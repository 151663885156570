function isLocalhost() {
  const hostname = window.location.hostname;
  return hostname === '127.0.0.1' || hostname === 'localhost';
}

export const getBaseURL = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_API_ROOT;
  }
  return window.__API_ROOT__;
};

export const getAppDomain = () => {
  console.log({ prs: process.env });
  if (isLocalhost()) {
    return process.env.REACT_APP_AUTH0_APP_DOMAIN;
  }
  return window.__AUTH0_APP_DOMAIN__;
};

export const getAuth0ClientId = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_AUTH0_CLIENT_ID;
  }
  return window.__AUTH0_CLIENT_ID__;
};

export const getAudience = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_AUTH0_AUDIENCE;
  }
  return window.__AUTH0_AUDIENCE__;
};

export const getHealthClientId = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_ONE_UP_HEALTH_CLIENT_ID;
  }
  return window.__ONE_UP_HEALTH_CLIENT_ID__;
};

export const getSmpBaseUrl = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_SMP_BASE_URL;
  }
  return window.__SMP_BASE_URL__;
};

export const getAssetDomain = () => {
  if (isLocalhost()) {
    return process.env.REACT_APP_ASSETS_DOMAIN;
  }
  return window.__ASSETS_DOMAIN__;
};

export const EMR_VIDEO_URL =
  'https://ihpstdmzv2alicedeveus2.blob.core.windows.net/video/EMR%20Share.mp4?sp=r&st=2023-08-17T13:44:58Z&se=2025-08-17T21:44:58Z&spr=https&sv=2022-11-02&sr=b&sig=JJIb%2FNIVcwn%2BQTJ9HhG%2BRkDqZ3gqLi9yiYVrLs52SqQ%3D';
