import { Box, styled } from '@mui/system';
import { Modal, RadioGroup } from '@mui/material';
import { isMobile } from 'utils/isMobile';
import Button from '../../../../../ihp-components/components/v2/Button';

const getStyles = (theme) => ({
  cancelBtn: {
    fontSize: '14px',
  },
  dateOfDeathModal: {
    maxWidth: '400px',
  },
  dateOfDeathConfirmationModal: {},
  saveIcon: {
    position: 'absolute',
    left: 10,
    fontSize: 20,
  },
  checkboxContainer: {
    background: theme.palette.primary.gray5,
  },
});

export default getStyles;

export const PageWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '20px' : '50px',
});

export const PageTitle = styled(Box)(({ theme }) => ({
  fontSize: '24px',
  color: theme.palette.primary.gray100,
  fontWeight: 600,
  marginBottom: 30,
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: 16,
  color: theme.palette.primary.darkBlue70,
  fontWeight: 600,
}));

export const SectionTitle = styled('div')(({ theme }) => {
  const color = theme.palette.primary.darkBlue70;

  return {
    fontSize: 16,
    color,
    fontWeight: 600,
    marginTop: 30,
  };
});

export const SectionSubTitle = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.darkGray70,
  fontWeight: 400,
  marginTop: 15,
}));

export const SectionContainer = styled('div')(({ marginTop }) => ({
  marginTop: marginTop ? marginTop : '20px',
  flexDirection: 'column',
  gap: '16px',
  display: 'flex',
  '& .StyledLabel': {
    textAlign: 'left !important',
  },
}));

export const DescriptionText = styled('div')(({ theme }) => ({
  marginTop: '5px',
  textAlign: 'center',
  lineHeight: '140%',
  color: theme.palette.primary.darkGray70,
}));

export const EmailButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.gray70,
  backgroundColor: theme.palette.primary.gray95,
  border: `1px solid ${theme.palette.primary.gray95}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '16px',
  fontWeight: '600',
  padding: '17px 30px',
  letterSpacing: '0.32px',
  lineHeight: '140%',
  height: '56px',
  '&:hover': {
    backgroundColor: theme.palette.primary.gray95,
  },
}));

export const AchievementsContainer = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  color: theme.palette.primary.gray5,
  flexDirection: isMobile() ? 'column' : 'row',
  display: 'flex',
  justifyContent: 'space-between',
}));

export const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
}));

export const CardContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '20px' : '40px',
});

export const CardFooter = styled(Box)({
  borderTop: '1px solid #DEE9F0',
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '20px' : '40px',
  paddingTop: isMobile() ? '20px' : '40px',
});

export const InfoMessage = styled(Box)(({ theme }) => ({
  padding: '15px 35px',
  background: theme.palette.primary.gray5,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  borderRadius: '5px',
  fontSize: 14,
}));

export const ModalContainer = styled(Box)(({ modal, step }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'scroll',
  width: '400px',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 0px 30px 0px rgba(0, 84, 135, 0.05)',
  maxHeight: '90%',
  borderRadius: '8px',

  ...(isMobile()
    ? {
        maxWidth: '327px',
      }
    : {
        maxWidth: '500px',
        boxShadow: 24,
      }),
}));

export const ModalContent = styled(Box)(({ paddingTop }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '20px 25px' : '40px 40px 25px 40px',
  paddingTop: paddingTop,
  justifyContent: 'center',
  height: '100%',
}));

export const WithdrawFromStudyStep2ModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '24px 24px 25px 24px' : '40px 40px 25px 40px',
  justifyContent: 'center',
  height: '100%',
});

export const DateOfDeathModalContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: isMobile() ? '24px 24px 25px 24px' : '40px 40px 25px 40px',
  justifyContent: 'center',
  height: '100%',
});

export const DateOfDeathModalFooter = styled(Box)(({ gap }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: gap ?? 5,
  width: '100%',
  padding: isMobile() ? '0px 24px 24px 24px' : '0px 40px 40px 40px',
}));

export const ModalFooter = styled(Box)(({ padding }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 5,
  width: '100%',
  padding: padding ?? '0px 40px 20px 40px',
}));

export const RadioInputGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '.MuiRadio-root': {
    '.MuiTypography-root': {
      textAlign: 'left',
    },
  },
});

export const ContactButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.gray95,
  color: theme.palette.primary.darkGray70,
  fontSize: '16px',
}));

export const HomeButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.darkBlue70,
  color: theme.palette.primary.white,
  fontSize: '16px',
}));

export const CustomStyledModal = styled(Modal)(({ modal }) => ({
  maxWidth:
    modal === 'dateOfDeath'
      ? '400px'
      : modal === 'dateOfDeathConfirmation'
      ? '375px'
      : 'initial',
}));
