import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FilledButton } from 'components/Buttons';

export const StudyWithdrawalPageWrapper = styled('div')(({ theme }) => ({
  padding: '60px 50px',
  [theme.breakpoints.down('sm')]: {
    padding: '24px',
  },
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  marginBottom: '20px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '0px',
  },
}));

export const StyledBox = styled(Box)(({ theme, margin, padding }) => ({
  padding: padding ? padding : '40px',
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
  margin,
  [theme.breakpoints.down('sm')]: {
    padding: padding ? padding : '20px',
  },
}));

export const Line = styled(Box)(({ theme }) => ({
  height: '1px',
  backgroundColor: theme.palette.primary.gray10,
}));

export const SectionTitle = styled(Typography)(({ theme, margin }) => ({
  color: theme.palette.primary.gray75,
  margin,
}));

export const WithdrawButton = styled(FilledButton)({
  width: '100%',
  marginTop: '20px',
});

export const CurrentRewardsPointsSummay = styled(Box)(({ theme }) => ({
  padding: '15px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.gray2,
  },
}));

export const CurrentRewardsProgressBox = styled(Box)(({ theme }) => ({
  padding: '30px',
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

export const ContactBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.primary.gray70,
  backgroundColor: theme.palette.primary.gray95,
  border: `1px solid ${theme.palette.primary.gray95}`,
  borderRadius: '8px',
  boxSizing: 'border-box',
  width: '100%',
  fontSize: '16px',
  fontWeight: '600',
  padding: '17px 30px',
  letterSpacing:'0.32px',
  
}));

export const ContactNumber = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '140%',
  color: theme.palette.primary.darkGray70,
  letterSpacing: '0.32px'
}));

export const StyledButtonText = styled(Typography)(({ theme, fontSize, color, letterSpacing}) => ({
  fontSize: fontSize? fontSize: '16px',
  fontWeight: '600',
  lineHeight: '140%',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  color: color? color: theme.palette.primary.white,
  letterSpacing: letterSpacing? letterSpacing: '0.32px'
}));

