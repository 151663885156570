import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';

export const CommandContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  height: '16px',
});

export const IconWrapper = styled(IconButton)({
  width: '14px',
  height: '14px',
  padding: 0,
});
