import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = {
  medication: {
    ids: ['001', '002', '003'],
    entities: {
      '001': {
        id: '001',
        drug: {
          name: 'Amaryl',
          dosageUnit: 'Milligram(s)',
        },
        repeat: {
          type: 'weekly',
          when: [0, 3, 5],
        },
        enabled: true,
      },
      '002': {
        id: '002',
        drug: {
          name: 'Duetact',
          dosageUnit: 'Milligram(s)',
        },
        repeat: {
          type: 'weekly',
          when: [1],
        },
        enabled: true,
      },
      '003': {
        id: '003',
        drug: {
          name: 'Metaglip',
          dosageUnit: 'Milligram(s)',
        },
        repeat: {
          type: 'daily',
        },
      },
      enabled: false,
    },
  },
  reminder: {
    ids: ['001', '002', '003', '004'],
    entities: {
      '001': {
        id: '001',
        medicationId: '001',
        time: '06:00',
        pills: 1,
        dosage: 15,
        startDate: '2022/01/01',
        endDate: '2022/03/10',
        note: '',
      },
      '002': {
        id: '002',
        medicationId: '001',
        time: '18:00',
        pills: 2,
        dosage: 10,
        startDate: '2022/02/01',
        endDate: '2023/12/15',
        note: '',
      },
      '003': {
        id: '003',
        medicationId: '002',
        time: '12:00',
        pills: 3,
        dosage: 50,
        startDate: '2021/06/29',
        endDate: '2022/04/18',
        note: '',
      },
      '004': {
        id: '004',
        medicationId: '003',
        time: '08:00',
        pills: 4,
        dosage: 100,
        startDate: '2022/01/01',
        endDate: null,
        note: '',
      },
    },
  }
};

const addMedicationAction = (state, { payload }) => {
  const { reminders, medication } = payload;
  const medicationId = uuid();
  state.medication.ids.push(medicationId);
  state.medication.entities[medicationId] = { id: medicationId, ...medication };

  reminders.forEach(reminder => {
    const reminderId = uuid();
    state.reminder.ids.push(reminderId);
    state.reminder.entities[reminderId] = {
      id: reminderId,
      medicationId,
      ...reminder
    };
  })
};

const updateMedicationAction = (state, { payload }) => {
  state.medication.entities[payload.id] = payload;
};

const removeMedicationAction = (state, { payload }) => {
  delete state.medication.entities[payload];
  state.medication.ids = state.medication.ids.filter((id) => id !== payload);
  const toRemove = state.reminder.ids.filter(
    (id) => state.reminder.entities[id].medicationId === payload);
  state.reminder.ids = state.reminder.ids.filter(id => !toRemove.includes(id));
  toRemove.forEach(id => delete state.reminder.entities[id]);
};

const enableMedicationAction = (state, { payload }) => {
  const { id, enabled } = payload;
  state.medication.entities[id].enabled = enabled;
};

const addRemindersAction = (state, { payload }) => {
  const { reminders, medicationId } = payload;
  reminders.forEach(reminder => {
    const reminderId = uuid();
    state.reminder.ids.push(reminderId);
    state.reminder.entities[reminderId] = {
      id: reminderId,
      medicationId,
      ...reminder
    };
  })
};

const updateRemindersAction = (state, { payload }) => {
  payload.forEach(reminder => {
    state.reminder.entities[reminder.id] = reminder;
  });
};

const removeRemindersAction = (state, { payload }) => {
  state.reminder.ids = state.reminder.ids.filter(id => !payload.includes(id));
  payload.forEach(id => { delete state.reminder.entities[id] });
};

const slice = createSlice({
  name: 'medicationTracker',
  initialState,
  reducers: {
    addMedication: addMedicationAction,
    updateMedication: updateMedicationAction,
    removeMedication: removeMedicationAction,
    enableMedication: enableMedicationAction, 
    addReminders: addRemindersAction,
    updateReminders: updateRemindersAction,
    removeReminders: removeRemindersAction,
  },
});

export const {
  addMedication,
  updateMedication,
  removeMedication,
  enableMedication,
  addReminders,
  updateReminders,
  removeReminders,
} = slice.actions;

export const selectMedicationById = id => state =>
  state[slice.name].medication.entities[id];
export const selectMedicationIds = (queryParams = {}) => state => {
  const { weekday } = queryParams;
  const { ids, entities } = state[slice.name].medication;

  const filterByWeekday = weekday => id =>
    entities[id].repeat.type === 'daily'
    || (entities[id].repeat.type === 'weekly'
      && entities[id].repeat.when.includes(weekday));

  const filters = [];
  if (weekday) filters.push(filterByWeekday(weekday));
  if (filters.length === 0) filters.push(id => id);

  return filters.reduce((ids, func) => ids.filter(func), ids);
}

export const selectReminderById = id => state =>
  state[slice.name].reminder.entities[id];
export const selectRemindersByIds = ids => state =>
  ids.map(id => state[slice.name].reminder.entities[id]);
export const selectReminderIds = ({ medicationId, date }) => state => {
  const { ids, entities } = state[slice.name].reminder;

  const filterByMedicationId = medicationId => id =>
    entities[id].medicationId === medicationId;

  const filterByDate = date => id =>
  ((!entities[id].startDate || entities[id].startDate <= date)
    && (!entities[id].endDate || date <= entities[id].endDate));

  const sortByTime = (lhsId, rhsId) =>
    entities[lhsId].time - entities[rhsId].time;

  const filters = [];
  if (medicationId) filters.push(filterByMedicationId(medicationId));
  if (date) filters.push(filterByDate(date));
  if (filters.length === 0) filters.push(id => id);

  return filters
    .reduce((ids, func) => ids.filter(func), ids)
    .sort(sortByTime);
};

export default slice;
