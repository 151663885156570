import { styled, Box } from '@mui/system';

export const RadioButtonsToggleCardWrapper = styled('Box')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '10px',
  marginBottom: '30px',
  background: '#F8F8F8',
}));

export const StyledCard = styled('Box')(({ theme }) => ({
  width: '100%',
  '& .MuiCard-root': {
    borderRadius: '5px',
    background: '#F3F3F3',
    boxShadow: 'none',
  },
  '& .MuiCardHeader-root': {
    padding: '20px 20px 0px 20px',
  },
  '& .MuiCardContent-root ': {
    padding: '20px',
  },
}));

export const RadioButtonsContainer = styled(Box)(() => ({
  marginBottom: '5px',
  alignSelf: 'center',
  width: '100%',
}));
