import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  conversionRate: null,
  currency: 'USD',
  thresholdMin: null,
  thresholdMax: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setValues: (state, action) => {
      const { payload } = action;
      Object.assign(state, payload);
    },
  },
});

export const { setValues } = paymentSlice.actions;

export default paymentSlice.reducer;
