import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Modal, Typography } from '@mui/material';

import Button from 'ihp-components/components/v2/Button';
import { ReactComponent as EmailIcon } from 'images/profile/email.svg';
import { RadioInput } from 'ihp-components/components/v2/Input/RadioInput';
import { Input } from 'ihp-components/components/v2/Input';
import { FTDDRLogo } from '../../Onboarding/components/FTDDRLogo';

import {
  ModalContainer,
  ModalContent,
  SectionContainer,
  EmailButton,
  ModalFooter,
  WithdrawFromStudyStep2ModalContent,
} from './components';
import { StyledButtonText } from '../StudyWithdrawal/styles';
import { Title, Text } from 'pages/Onboarding/components/Text';
import { useLogout } from 'utils/useLogout';
import { useNavigate } from 'react-router-dom';
import { STUDY_ARM_CODE } from 'constants/global';
import { findStudyAttribute } from 'utils/study';
import { ReactComponent as PhoneIcon } from 'images/shared/phone-3.svg';
import { useSelector } from 'ihp-bloom-redux/app/redux';
import { SelectGroup } from 'components/SelectGroup';

const WithdrawFromStudyModal = ({
  isOpen,
  onClose,
  onSubmit,
  callCentrePhone,
  withdrawalReasons,
  widthdrawSuccessfully,
  allowReEnroll,
  submittingWithdrawal,
  noActiveProfiles,
  studyArmCode,
  isAccountProfileIdNotPresent,
}) => {
  const { studyConfiguration } = useSelector((state) => state.configuration);

  const callCenterEmail = findStudyAttribute(
    studyConfiguration || [],
    'call_centre_email'
  );

  const callCenterPhone = findStudyAttribute(
    studyConfiguration || [],
    'call_centre_phone'
  );

  const logout = useLogout(
    `welcome${allowReEnroll ? '?open-auth0=signup' : ''}`
  );

  const [step, setStep] = useState(1);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const redirectUrl = useRef('');

  const navigate = useNavigate();

  useEffect(() => {
    if (noActiveProfiles || isAccountProfileIdNotPresent) {
      setStep(2);
      redirectUrl.current = '/no-active-profile';
    } else {
      redirectUrl.current = '/select-profile';
    }
  }, [noActiveProfiles, isAccountProfileIdNotPresent]);

  const handleOnClose = () => {
    onClose();
    setStep(1);
  };

  const handleSubmission = () => {
    const selectedReasonsValues = withdrawalReasons
      .filter((sr) => selectedReasons.includes(sr.label))
      .map((sr) => sr.value);

    if (typeof onSubmit === 'function' && selectedReasonsValues.length > 0) {
      onSubmit(selectedReasonsValues);
    }
  };

  const redirectToSelectProfile = () => {
    if (redirectUrl.current) {
      navigate(redirectUrl.current);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (step === 2 && reason === 'backdropClick') {
          return;
        }
        onClose();
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <ModalContainer
        sx={{
          backgroundColor: (theme) => theme.palette.primary.white,
          width: step === 1 ? '500px' : '400px',
        }}
        step={2}
        modal={'withdrawFromStudy'}
      >
        {step === 1 && (
          <>
            <ModalContent paddingTop='40px'>
              <Box display='flex' justifyContent='center' position='relative'>
                <FTDDRLogo />
              </Box>

              <Title marginTop='20px'>
                Are you sure you want to withdraw from the study?
              </Title>

              <SectionContainer marginTop='5px'>
                <Text lineHeight='140%'>
                  By withdrawing from the study you will not be eligible to
                  participate in the study.
                </Text>

                <Text
                  mt={2}
                  textAlign='center'
                  variant='body2'
                  lineHeight='140%'
                >
                  Please provide a reason for withdrawing
                </Text>
              </SectionContainer>

              <SectionContainer>
                <SelectGroup
                  options={withdrawalReasons.map((wr) => wr.label)}
                  selectedOptions={selectedReasons}
                  setSelectedOptions={setSelectedReasons}
                  type='multi'
                />
              </SectionContainer>
            </ModalContent>

            <ModalFooter padding='0px 40px 40px 40px'>
              <Button
                onClick={handleSubmission}
                width='100%'
                disabled={selectedReasons.length === 0 || submittingWithdrawal}
                loading={submittingWithdrawal}
              >
                <StyledButtonText>Withdraw</StyledButtonText>
              </Button>
              <Button variant='link' onClick={handleOnClose} width='100%'>
                <StyledButtonText
                  fontSize='14px'
                  color='#005587'
                  letterSpacing='0.28px'
                >
                  Cancel
                </StyledButtonText>
              </Button>
            </ModalFooter>
          </>
        )}

        {step === 2 && (
          <>
            <WithdrawFromStudyStep2ModalContent paddingTop='40px'>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                position='relative'
              >
                <FTDDRLogo />
              </Box>
              <Typography
                textAlign='center'
                variant='h2'
                marginTop={'20px'}
                fontWeight={700}
              >
                Withdrawal Complete
              </Typography>

              <SectionContainer marginTop='0px'>
                <Typography textAlign='center' variant='body2' marginTop='5px'>
                  Your withdrawal from the study is complete.
                </Typography>
                <Typography marginTop='20px' textAlign='center' variant='body2'>
                  We are sorry to see you go, but we thank you for your
                  participation in SOURCE and for contributing to single
                  ventricle research.
                </Typography>
                <Typography textAlign='center' variant='body2'>
                  For questions, please contact us:
                </Typography>
              </SectionContainer>
              <SectionContainer marginTop='25px'>
                <EmailButton
                  fullWidth
                  startIcon={<EmailIcon color='#7c7c7c' />}
                >
                  <Text
                    color='primary.darkGray70'
                    fontWeight='600'
                    fontSize='16px'
                  >
                    {callCenterEmail}
                  </Text>
                </EmailButton>

                <EmailButton fullWidth startIcon={<PhoneIcon />}>
                  <Text
                    color='primary.darkGray70'
                    fontWeight='600'
                    fontSize='16px'
                  >
                    {callCenterPhone}
                  </Text>
                </EmailButton>
              </SectionContainer>
            </WithdrawFromStudyStep2ModalContent>

            <ModalFooter padding='0px 40px 40px 40px'>
              <Button
                width='100%'
                onClick={redirectToSelectProfile}
                sx={{ fontSize: '16px' }}
              >
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContainer>
    </Modal>
  );
};

WithdrawFromStudyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  noActiveProfiles: PropTypes.bool,
  isAccountProfileIdNotPresent: PropTypes.bool,
};

export default WithdrawFromStudyModal;
