import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AcmeLogo } from 'images/av-logo-vertical.svg';
import { HeaderContainer, Logo, Reward } from './styles';
import { ReactComponent as PointsIcon } from 'images/shared/points.svg';
import { Box, Typography } from '@mui/material';
import { ReactComponent as ChevronLeft } from 'images/shared/chevron-left-1.svg';

import { ReactComponent as FilterIcon } from 'images/shared/filter-1.svg';
import Button from 'ihp-components/components/v2/Button';

export const Header = ({ points, title, onBack, viewType, onFilters, ...rest }) => (
  <HeaderContainer {...rest}>
    {viewType && (
      <>
        <Box onClick={onBack} display='flex' flexDirection='row' alignItems='center' gap='20px'>
          <ChevronLeft color='#4C88AB' width={'10px'} />
          <Typography variant='h6'>{title}</Typography>
        </Box>

        <Box  sx={{margin: '0 120px 0 10px'}} p={'10px 0px'} onClick={onFilters}>
          <FilterIcon width={18} height={18} />
        </Box>
      </>
    )}
    {!viewType && (
      <>
        <Logo>
          <AcmeLogo width={'80px'} height={'35px'} />
        </Logo>
      </>
    )}
  </HeaderContainer>
);

Header.propTypes = {
  points: PropTypes.number.isRequired,
  title: PropTypes.string,
  onBack: PropTypes.func,
  viewType: PropTypes.string,
  onFilters: PropTypes.func
};
