import { Box, MenuItem, Modal, useTheme } from '@mui/material';
import { ContinueButton } from 'components/Onboarding';
import { ReactComponent as XIcon } from 'images/shared/x.svg';
import { useValidatePhone } from 'pages/User/hooks';
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import getStyles from './styles';
import { relations } from '../AlternativeContact/relations';
import { Input } from 'ihp-components/components/v2/Input';
import Button from 'ihp-components/components/v2/Button';

export const EditAlternateContactsModal = ({
  open,
  onClose,
  contact,
  onUpdateContact,
}) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  const validatePhone = useValidatePhone();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      id: contact?.id ?? 0,
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      relation: contact?.relation ?? '',
      phoneNumber: contact?.phoneNumber ?? '',
      email: contact?.email ?? '',
    },
  });

  const onSubmit = async (data) => {
    onUpdateContact(data);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.modalWrapper}>
        <Box sx={styles.titleWrapper}>
          <Box sx={styles.title}>Edit alternate contact</Box>
          <Box sx={styles.iconWrapper}>
            <XIcon
              width='12px'
              height='18px'
              color='#888888'
              onClick={(e) => onClose()}
            />
          </Box>
        </Box>
        <Box sx={styles.formWrapper}>
          <form>
            <Box sx={styles.fieldContainer}>
              <Controller
                name='relation'
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    type='select'
                    error={errors.relation}
                    errorLabel={errors.relation?.message}
                    label='Select relation to you'
                    fieldLabel='Relation to you'
                    placeholder='Select relation to you'
                    fullWidth
                    {...field}
                  >
                    {relations?.map(({ id, name }) => (
                      <MenuItem key={id} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Input>
                )}
              />
            </Box>

            <Box sx={styles.fieldsRow}>
              <Box sx={styles.firstNameFieldContainer}>
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='firstName'
                      label='First name'
                      error={errors.firstName}
                      errorLabel={errors.firstName?.message}
                      fullWidth={true}
                      placeholder='Enter first name'
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box sx={styles.lastNameFieldContainer}>
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Input
                      name='lastName'
                      label='Last name'
                      error={errors.lastName}
                      errorLabel={errors.lastName?.message}
                      fullWidth={true}
                      placeholder='Enter last name'
                      {...field}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <Input
                    name='email'
                    label='Email'
                    error={errors.email}
                    errorLabel={errors.email?.message}
                    fullWidth={true}
                    placeholder='Enter email'
                    {...field}
                  />
                )}
              />
            </Box>

            <Box sx={styles.fieldContainer}>
              <Controller
                name='phoneNumber'
                control={control}
                rules={{
                  validate: validatePhone,
                  required: true,
                }}
                render={({ field }) => (
                  <MaskedInput
                    placeholder='Enter phone number'
                    mask='+1 (999) 999-9999'
                    {...field}
                  >
                    {(inputProps) => (
                      <Input
                        name='phoneNumber'
                        label='Phone number'
                        error={errors.phoneNumber}
                        errorLabel={
                          errors?.phoneNumber?.message ||
                          'This field is required'
                        }
                        fullWidth
                        {...inputProps}
                      />
                    )}
                  </MaskedInput>
                )}
              />
            </Box>
          </form>
        </Box>
        <Box sx={styles.buttonsContainer}>
          <Box sx={styles.continueButton}>
            <ContinueButton
              sx={styles.continueButton}
              onClick={handleSubmit(onSubmit)}
            >
              Save Contact
            </ContinueButton>
            <Button
              variant='link'
              sx={styles.cancelLink}
              onClick={(e) => onClose()}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
