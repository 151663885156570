import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { isMobile } from 'utils/isMobile';

export const ActivityDetailsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ActivityDetails = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '5px',
  marginBottom: '10px'
});

export const ActivityDetailsItems = styled((props) => (
  <Typography variant='pn2' color='primary.gray100' {...props} />
))({
  margin: '0px 8px',
  fontSize: isMobile() ? '16px' : '13px',
  opacity: '0.8',
});

export const StyledCircleIcon = styled(CircleIcon)(({ theme, color }) => ({
  color: color || theme.palette.primary.gray25,
  fontSize: '4px',
  '&:last-child': {
    display: 'none',
  },
}));
