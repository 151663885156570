import { FormProvider } from 'react-hook-form';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Stack } from '@mui/material';

import { Divider } from 'configUI/components/Shared';
import { BaseHeader } from 'components/BaseLayout';
import { MedicationForm } from './MedicationForm';
import { AddReminderForm } from './AddReminderForm';
import { Layout } from './mobile.styles';
import { ReactComponent as CheckIcon } from 'images/shared/check-2.svg';

import { useCreateMedicationTracker } from './hooks';
import {
  getMedicationTrackerAllPath,
  getMedicationTrackerRemindersPath,
} from 'pages/NotificationCenter/paths';
import Button from 'ihp-components/components/v2/Button';

const CreateMedicationTrackerMobile = () => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const dosage = searchParams.get('dosage');
  const { id } = useParams();
  const edit = !!id;

  const header = edit ? 'Edit medication' : 'Add medication';
  const parentURL = edit
    ? getMedicationTrackerRemindersPath(id)
    : getMedicationTrackerAllPath();

  const navigate = useNavigate();

  const [formProviderProps, reminderFormProps, onSave] =
    useCreateMedicationTracker({
      id,
      name,
      dosage,
      onCreate: () => navigate(parentURL),
      onUpdate: () => navigate(parentURL),
    });

  return (
    <Layout
      header={
        <>
          <BaseHeader to={parentURL}>{header}</BaseHeader>
          <Divider />
        </>
      }
      content={
        <Stack divider={<Divider />}>
          <FormProvider {...formProviderProps}>
            <MedicationForm />
          </FormProvider>
          <AddReminderForm {...reminderFormProps} />
        </Stack>
      }
      fixedFooter
      footer={
        <>
          <Divider />
          <Stack
            direction='row'
            justifyContent='flex-end'
            spacing='5px'
            py='15px'
            px='20px'
          >
            <Button variant='link' size='lg' width='140px' component={Link} to={parentURL}>
              Cancel
            </Button>
            <Button size='lg' width='300px' startIcon={<CheckIcon />} onClick={onSave}>
              Save medication
            </Button>
          </Stack>
        </>
      }
    />
  );
};

export default CreateMedicationTrackerMobile;
