import { Box, Typography } from '@mui/material';
import { ReactComponent as GreyCoinsIcon } from 'images/payment/greyCoins.svg';
import { PaymentListWrapper, Icon } from './styles';
import { PaymentItem } from './PaymentItem';
import { EmptyState } from 'ihp-components/components/v2/EmptyState';

export const PaymentList = (props) => {
  const { list } = props;
  return (
    <PaymentListWrapper>
      {list?.length === 0 && (
        <Box p={2}>
          <EmptyState
            icon={<GreyCoinsIcon />}
            title={'You have no rewards'}
            description='Complete your daily tasks to earn points towards your first payment'
          />
        </Box>
      )}

      {list?.map((item) => (
        <PaymentItem
          key={item?.id}
          {...item.attributes}
          relationships={item.relationships}
        />
      ))}
    </PaymentListWrapper>
  );
};
