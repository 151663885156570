import { ReactComponent as Correct } from 'images/activity/correct.svg';
import { ReactComponent as Like } from 'images/activity/like.svg';
import { ReactComponent as Unlike } from 'images/activity/unlike.svg';
import { ReactComponent as Add } from 'images/activity/add.svg';

export const statusMapping = (theme) => {
  return {
    'read': {
      icon: <Correct />,
      color: null,
      backgroundColor: theme.palette.primary.greenREAD,
      text: 'READ',
      type: 'success',
      variant: 'filled',
    },
    'helpful': {
      icon: <Like />,
      color: theme.palette.primary.greenREAD,
      text: 'HELPFUL',
      type: 'primary',
      variant: 'bordered',
      borderColor: theme.palette.primary.greenREAD,
    },
    'unhelpful': {
      icon: <Unlike />,
      color: theme.palette.primary.red75,
      type: 'primary',
      text: 'NOT HELPFUL',
      variant: 'bordered',
      borderColor: theme.palette.primary.red75,
    },
    'feedback': {
      icon: <Add />,
      text: 'feedback',
      color: theme.palette.primary.darkGray70,
      type: 'primary',
      variant: 'bordered',
      borderColor: theme.palette.primary.darkGray70,
    },
    'not_helpful': {
      icon: <Unlike />,
      color: theme.palette.primary.red75,
      type: 'primary',
      text: 'NOT HELPFUL',
      variant: 'bordered',
      borderColor: theme.palette.primary.red75,
    },
  }
};
