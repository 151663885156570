import { createSlice } from '@reduxjs/toolkit';

const emails = [
  {
    id: '001',
    name: 'Invitation to enroll',
    active: true,
  },
  {
    id: '002',
    name: 'Welcome to the Bloom study',
    active: true,
  },
  {
    id: '003',
    name: 'Study overview',
    active: false,
  },
  {
    id: '004',
    name: 'Reminder to enroll',
    active: false,
  },
  {
    id: '005',
    name: 'Schedule TeleVisit call',
    active: false,
  },
  {
    id: '006',
    name: 'Reminder',
    active: false,
  },
  {
    id: '007',
    name: 'Schedule TeleVisit call Follow up',
    active: false,
  },
];

const initialState = {
  allEmails: emails,
  selectedEmails: emails,
};

const slice = createSlice({
  name: 'emails',
  initialState,
  reducers: {
    toggleEmail: (state, { payload }) => {
      state.selectedEmails = state.selectedEmails.map((email) => ({
        ...email,
        active: email.id === payload ? !email.active : email.active,
      }));
    },
  },
});

export const { toggleEmail } = slice.actions;

export default slice;
