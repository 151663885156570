import { InvitationCard } from 'components/Invitation/InvitationCard';
import { FamilyLinkageInviteMemberModal } from 'features/familyLinkage/modals/sendInvite/FamilyLinkageInviteMemberModal';
import { FamilyLinkageInviteMemberSuccessModal } from 'features/familyLinkage/modals/sendInvite/FamilyLinkageInviteMemberSuccessModal';
import { useCallback, useState } from 'react';
import { INVITE_FAMILY_MEMBERS } from '../../../features/familyLinkage/constants';
import { EnvelopeIcon } from '../styles';

export function FamilyLinkageCard() {
  const [modalView, setModalView] = useState(null);

  const onClose = useCallback(() => {
    setModalView(null);
  }, []);

  return (
    <>
      <InvitationCard
        icon={<EnvelopeIcon />}
        title={INVITE_FAMILY_MEMBERS.title}
        description={INVITE_FAMILY_MEMBERS.description}
        buttonText='Invite'
        onClick={() => setModalView('INVITATION_MODAL')}
      />

      <FamilyLinkageInviteMemberModal
        onClose={onClose}
        onSuccess={() => setModalView('SUCCESS_MODAL')}
        open={modalView === 'INVITATION_MODAL'}
      />

      <FamilyLinkageInviteMemberSuccessModal
        onClose={onClose}
        open={modalView === 'SUCCESS_MODAL'}
      />
    </>
  );
}