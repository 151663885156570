import { Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'images/Icon Group.svg';

export const TitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const Row = styled('div')(({ marginBottom, marginTop }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  marginBottom: marginBottom || '10px',
  marginTop: marginTop,
}));

export const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.gray100,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  // marginBottom: '18px',
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '140%',
  textAlign: 'center',
}));

export const MoreDetail = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.gray4,
  textAlign: 'center',
  width: '420px',
  fontSize: '14px',
  fontWeight: '400',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    marginBottom: '10px',
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  paddingTop: 25,
}));

export const Content = styled('p')(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '-2px',
}));

export const HandshakeLogo = styled(Logo)(({ sx = {} }) => ({
  width: '420px',
  height: '180px',
  ...sx,
}));

export const PageLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.lightShadegreen,
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '135%',
  textDecorationLine: 'underline',
  display: 'inline-block',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  cursor: 'pointer',
}));
