import DatePicker from '@mui/lab/DatePicker';
import { StyledFormControl, StyledFormLabel } from './index.styles';
import { ReactComponent as CalendarIcon } from 'configUI/images/icons/calendar.svg';
import { TextField } from 'components/Input/TextField';

export const CustomDatePicker = ({
  label,
  fullWidth,
  inputStyles,
  ...rest
}) => {
  return (
    <StyledFormControl fullWidth={fullWidth}>
      {label && <StyledFormLabel>{label}</StyledFormLabel>}
      <DatePicker
        fullWidth
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
            // Set proper value from date picker prop when it is empty
            if (!rest.value || rest.value.length === 0) {
              inputProps.value = rest.value
            }
            return (
              <TextField
                fullWidth={fullWidth}
                ref={inputRef}
                endAdornment={InputProps?.endAdornment}
                {...inputProps}
                value={!rest.value || rest.value.length === 0 ? '' : inputProps.value}
                placeholder={rest.placeholder || inputProps.placeholder}
                sx={inputStyles}
              />
            )
          }}
        {...rest}
      />
    </StyledFormControl>
  );
};
