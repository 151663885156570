import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import {
  PageLink,
  JoinUsBtn,
  LoginBtn,
  Navbar,
  NavContainer,
  DesktopMenuContainer,
  MenuButton,
  MobileMenuContainer,
  CloseMenuButton,
} from './styles';
import { ReactComponent as CloseIcon } from 'images/shared/times-3.svg';
import { ReactComponent as MenuIcon } from 'images/shared/bars-3.svg';
import { getAuth0LoginPageConfig } from 'utils/auth0';

import { ReactComponent as AvLogo } from 'images/av-logo.svg';
import { ReactComponent as AvMobileLogo } from 'images/svs-logo.svg';
import { Banner, NewsletterLink } from 'pages/Welcome/Header/Banner';
import { useTheme } from '@emotion/react';

const DesktopLogo = (props) => (
  <AvLogo style={{ maxWidth: '100%' }} {...props} />
);
const MobileLogo = (props) => (
  <AvMobileLogo style={{ maxWidth: '100%' }} {...props} />
);

const Menu = ({ direction = 'column', gap = 0, closeMenu }) => {
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();

  const openLoginPage = () => {
    loginWithRedirect(getAuth0LoginPageConfig({ context: 'signin' }));
  };

  return (
    <Stack
      gap='20px'
      direction={direction}
      alignItems={direction === 'row' ? 'center' : 'stretch'}
    >
      <Stack direction={direction} gap={gap}>
        <PageLink to='/welcome/get-involved' onClick={closeMenu}>
          Get Involved
        </PageLink>
        <PageLink to='/welcome/about' onClick={closeMenu}>
          About
        </PageLink>
        <PageLink to='/welcome/for-clinicians' onClick={closeMenu}>
          For Clinicians
        </PageLink>
        <PageLink to='/welcome/faq' onClick={closeMenu}>
          FAQs
        </PageLink>
        <PageLink to='/welcome/resources' onClick={closeMenu}>
          Resources
        </PageLink>
        {/* <PageLink to='/welcome/stydy-updates' onClick={closeMenu}>
          Study Updates
        </PageLink> */}
      </Stack>
      <Stack
        spacing={'10px'}
        direction={direction}
        sx={{
          [theme.breakpoints.down('lg')]: {
            '*': {
              width: '100%',
            },
          },
        }}
      >
        {/* <NewsletterLink> */}
          <JoinUsBtn onClick={() => navigate('/onboarding/welcome-to-study')}>
            Sign up
          </JoinUsBtn>
        {/* </NewsletterLink> */}
        <LoginBtn onClick={openLoginPage}>Log in</LoginBtn>
      </Stack>
    </Stack>
  );
};

export const Nav = () => {
  const navigate = useNavigate();
  const [navExpanded, setNavExpanded] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  if (isSmallScreen) {
    return (
      <>
        {/* <Banner /> */}

        <Navbar>
          <NavContainer>
            <Box sx={{ cursor: 'pointer' }}>
              <DesktopLogo onClick={() => navigate('/welcome')} />
            </Box>
            <MenuButton onClick={() => setNavExpanded(!navExpanded)}>
              <MenuIcon width='30px' />
            </MenuButton>
            <MobileMenuContainer isOpen={navExpanded}>
              <CloseMenuButton onClick={() => setNavExpanded(!navExpanded)}>
                <CloseIcon width='30px' />
              </CloseMenuButton>
              <MobileLogo width='170px' />
              <Menu
                direction='column'
                closeMenu={() => setNavExpanded(false)}
              />
            </MobileMenuContainer>
          </NavContainer>
        </Navbar>
      </>
    );
  }

  return (
    <>
      {/* <Banner /> */}

      <Navbar>
        <NavContainer>
          <Box sx={{ cursor: 'pointer' }}>
            <DesktopLogo onClick={() => navigate('/welcome')} />
          </Box>
          <DesktopMenuContainer>
            <Menu direction='row' gap={'14px'} />
          </DesktopMenuContainer>
        </NavContainer>
      </Navbar>
    </>
  );
};
