import { useState } from 'react';

import { useMediaQuery } from '@mui/material';
import FormIO from '../FormIO';
import { formatSurveyTask } from '../hooks';
import { stringifyJson, useChangeHandler } from './utils';
import ErrorBoundary from './ErrorBoundary';
import Result from './Result';
import Setup from './Setup';
import { Formio } from '@formio/react';
import { message } from '../FormIO/message';
import { multiValueRow } from '../FormIO/multiValueRow';
import { multiValueTable } from '../FormIO/multiValueTable';
import { wizard } from '../FormIO/wizard';

const StandaloneSurvey = () => {
  document.title = 'Standalone Survey';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [jsons, setJsons] = useState({ task: '', defaultValues: '' });
  const [errors, setErrors] = useState({});

  const [task, setTask] = useState(null);
  const [result, setResult] = useState(null);

  // This needs to be at start of the component to make sure the template is correctly loaded
  Formio.Templates.current = {
    ...Formio.Templates.current,
    message,
    multiValueRow,
    multiValueTable,
    wizard: {
      ...Formio.Templates.current.wizard,
      form: wizard.form,
    },
  };

  const useJsonsChangeHandler = useChangeHandler(setJsons);
  const useErrorsChangeHandler = useChangeHandler(setErrors);

  const handleTaskSubmit = () => {
    try {
      const { form, attributes } = formatSurveyTask(JSON.parse(jsons.task));
      setTask({ form, attributes });
    } catch (task) {
      setErrors((prevErrors) => ({ ...prevErrors, task }));
    }
  };

  const handleRenderError = (task) => {
    setTask(null);
    setErrors((prevErrors) => ({ ...prevErrors, task }));
  };

  const handleSurveySubmit = ({ data }) => setResult(data);

  const handleSurveyCancel = () => {
    if (window.confirm('Do you really want to leave?')) setTask(null);
  };

  const handleStartOver = (useResultAsDefaultValues) => {
    if (useResultAsDefaultValues)
      setJsons((prevValue) => ({
        ...prevValue,
        defaultValues: stringifyJson(result),
      }));
    setTask(null);
    setResult(null);
  };

  if (result) return <Result value={result} onSubmit={handleStartOver} />;
  if (task) {
    return (
      <ErrorBoundary onError={handleRenderError}>
        <FormIO
          {...task}
          isMobile={isMobile}
          submission={{ data: JSON.parse(jsons.defaultValues) }}
          onSubmit={handleSurveySubmit}
          onCancel={handleSurveyCancel}
          onSave={handleSurveySubmit}
        />
      </ErrorBoundary>
    );
  }

  return (
    <Setup
      onChange={useJsonsChangeHandler}
      value={jsons}
      onErrors={useErrorsChangeHandler}
      errors={errors}
      onSubmit={handleTaskSubmit}
    />
  );
};

export default StandaloneSurvey;
