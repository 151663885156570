import { useState } from 'react';
import { Alert, Box, MenuItem } from '@mui/material';
import { useSelector } from 'ihp-bloom-redux/app/redux';

import Button from 'ihp-components/components/v2/Button';
import { Input } from 'ihp-components/components/v2/Input';

import { ReactComponent as AvLogo } from 'images/svs-logo.svg';
import { Title } from '../components/Text';
import { Body, ButtonContainer, InfoText, Row, InputWrapper, AccordionTitle } from './styles';
import {
  GENERAL_ROLE_OPTIONS,
  GENERAL_ROLE,
  RESEARCH_ELIGIBILITY_ACCORDION_DETAILS,
} from 'pages/Onboarding/ResearchEligibilityRole/constants';
import { STUDY_ARM_ROLE_NAME } from 'constants/global';
import {getLabelText} from './utils';
import { AccordionList } from 'ihp-components/components/Accordion';
import { ACCORDION_ICONS } from 'utils/configParseUtils';

const isAdultRole = (roleName) => {
  const lowerCaseRoleName = roleName.toLowerCase();
  return [STUDY_ARM_ROLE_NAME.AdultSV, STUDY_ARM_ROLE_NAME.AdultNonSV].some(
    (adultRole) => adultRole.toLowerCase() === lowerCaseRoleName
  );
};

function ResearchEligibilityRoleForm({ error, onSubmit }) {
  const [selectedGeneralRole, setSelectedGeneralRole] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showGeneralRoleModal, setShowGeneralRoleModal] = useState(true);

  const handleSubmit = async () => {
    setShowError(false);
    setIsSubmitting(true);
    const { study_arm_role_code } =
      GENERAL_ROLE_OPTIONS[selectedGeneralRole].options[selectedRoleIndex];
    await onSubmit(study_arm_role_code);
    setIsSubmitting(false);
    setShowError(true);
  };

  const { accountProfiles } = useSelector((state) => state.user);

  const filteredOptions = { ...GENERAL_ROLE_OPTIONS };

  const hasAdultProfile = accountProfiles.some((p) =>
    isAdultRole(p.attributes.study_arm_role_name)
  );

  if (hasAdultProfile) {
    delete filteredOptions[GENERAL_ROLE.MYSELF];
  }

  const labelText = getLabelText(selectedGeneralRole);

  const getAccordionItems = () => {
    return [{
      title: <AccordionTitle>{RESEARCH_ELIGIBILITY_ACCORDION_DETAILS.title}</AccordionTitle>,
      description: <InfoText fontWeight={'0'} dangerouslySetInnerHTML={{ __html: RESEARCH_ELIGIBILITY_ACCORDION_DETAILS.description }} />
    }];
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <AvLogo />
      </Row>

      <Title color='primary.darkGray100'>Who do you want to enroll?</Title>

      <Body>
        {showGeneralRoleModal ? (
          <>
            <Box textAlign='left' marginBottom='10px'>
              <InfoText margin='20px 0' fontWeight={'0'}>
                Please choose the person you want to enroll. Your answer will
                help us determine that person’s eligibility for research.
                Individuals who are eligible to participate in SOURCE are those
                who are living with a single ventricle heart defect and their
                biological mother, father, brothers, sisters, daughters, or
                sons.
              </InfoText>
              <AccordionList accordionItems={getAccordionItems()} accordionIcons={ACCORDION_ICONS} />
            </Box>

            <Box mt={4}>
              <Input
                type='select'
                fieldLabel='Who do you want to enroll?'
                label='Select an option'
                placeholder='Select an option'
                value={selectedGeneralRole}
                onChange={(e) => {
                  const role = e.target.value;

                  if (role === GENERAL_ROLE.NONE) {
                    setSelectedGeneralRole(e.target.value);
                    setSelectedRoleIndex(0);
                  } else {
                    setSelectedGeneralRole(e.target.value);
                    setShowGeneralRoleModal(false);
                    setSelectedRoleIndex(null);
                  }
                }}
                fullWidth
              >
                {Object.entries(filteredOptions).map(([key, value], index) => (
                  <MenuItem key={index.toString()} value={key}>
                    {value.label}
                  </MenuItem>
                ))}
              </Input>
            </Box>
          </>
        ) : (
          <>
            {error && showError && (
              <Alert severity='error' sx={{ mt: '16px' }}>
                {error}
              </Alert>
            )}

            {
              (
                (selectedGeneralRole === GENERAL_ROLE.BIOLOGICAL_MINOR_CHILD || selectedGeneralRole === GENERAL_ROLE.DEPENDENT)?
                (
              <Box mt={4}>
                <InputWrapper
                  type='select'
                  fieldLabel={labelText}
                  label='Select an option'
                  placeholder='Select an option'
                  value={selectedRoleIndex}
                  onChange={(e) => {
                    setSelectedRoleIndex(e.target.value);
                    setShowError(false);
                  }}
                  fullWidth
                >
                  {GENERAL_ROLE_OPTIONS[selectedGeneralRole].options.map(
                    (option, index) => (
                      <MenuItem key={index.toString()} value={index}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </InputWrapper>
              </Box>
                ):(
              <Box mt={4}>
                <Input
                  type='select'
                  fieldLabel={labelText}
                  label='Select an option'
                  placeholder='Select an option'
                  value={selectedRoleIndex}
                  onChange={(e) => {
                    setSelectedRoleIndex(e.target.value);
                    setShowError(false);
                  }}
                  fullWidth
                >
                  {GENERAL_ROLE_OPTIONS[selectedGeneralRole].options.map(
                    (option, index) => (
                      <MenuItem key={index.toString()} value={index}>
                        {option.label}
                      </MenuItem>
                    )
                  )}
                </Input>
              </Box>
                )
              )
            }
          </>
        )}

        <ButtonContainer>
          <Button
            loading={isSubmitting}
            fullWidth
            type='submit'
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            disabled={selectedRoleIndex === null || isSubmitting}
          >
            Save & continue
          </Button>

          {!showGeneralRoleModal && (
            <Button
              disabled={isSubmitting}
              fullWidth
              type='button'
              onClick={() => {
                setSelectedGeneralRole(null);
                setSelectedRoleIndex(null);
                setShowError(false);
                setShowGeneralRoleModal(true);
              }}
              variant='link'
            >
              Back
            </Button>
          )}
        </ButtonContainer>
      </Body>
    </form>
  );
}

export default ResearchEligibilityRoleForm;
