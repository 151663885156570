import { forwardRef } from 'react';
import { styled } from '@mui/system';
import { Divider as MUIDivider } from '@mui/material';

export const Divider = styled(forwardRef(({
  thickness,
  ...rest
}, ref) => <MUIDivider ref={ref} {...rest}/>))(({
  thickness = '1px',
  orientation,
  theme,
}) => ({
    border: 0,
    [orientation === 'vertical' ? 'borderLeft' : 'borderBottom']:
      `${thickness} solid ${theme.palette.primary.gray15}`,
}));
