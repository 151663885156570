import { HOMEPAGE_ROUTE_PATHS } from "utils/constants";

export const shouldRenderGetStarted = (routePathname) => {
    const validPathsForGetStarted = [
        HOMEPAGE_ROUTE_PATHS.GET_INVOLVED, 
        HOMEPAGE_ROUTE_PATHS.FAQ, 
        HOMEPAGE_ROUTE_PATHS.RESOURCES
    ];

    const nestedRoute = routePathname?.split("/")?.[2]; 

    return !nestedRoute || validPathsForGetStarted?.includes(nestedRoute);
};

export const shouldRenderAnyQuestions = (routePathname) => {
    return routePathname?.includes(HOMEPAGE_ROUTE_PATHS.GET_INVOLVED);
};

export const concatRoutePaths = (...args) => {
    return args.join('/');
};