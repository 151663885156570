import * as React from 'react';
import Box from '@mui/material/Box';
import {
  AchievementsContainer,
  AchievementsTitle,
  DaysInfo,
  LevelInfo,
  PointsInfo,
  StudyProgressBar,
  Title,
} from 'components/Study';
import AchievementCard from './achievementCard';
import { useTheme } from '@mui/material/styles';
import { useParticipantsTasksData } from './useParticipantsTasksData';
import { useMemo } from 'react';
import { Loader } from 'components/Loader';
import { isMobile } from 'utils/isMobile';

export default function Progress({
  taskOccurrencies,
  totalTasks,
  currentDay = 0,
  daysTotal = 0,
  points = 0,
  level = 0,
}) {
  // TODO: read hardcoded values from backend or state
  const theme = useTheme();
  const marginX = isMobile() ? '15px' : '0px';
  const {
    surveyTasks,
    completedSurveyTasks,
    dataSharingTasks,
    completedDataSharingTasks,
    gameTasks,
    completedGameTasks,
    videoTasks,
    completedVideoTasks,
    isLoadingResults,
    labTestTasks,
    completedLabTestTasks,
    devicesTasks,
    completedDevicesTasks,
    callScheduleTasks,
    completedCallScheduleTasks,
  } = useParticipantsTasksData();

  const gridData = useMemo(
    () => [
      {
        name: 'Surveys',
        type: 'survey',
        points: completedSurveyTasks?.length,
        totalPoints: surveyTasks?.length,
      },
      {
        name: 'Health Data',
        type: 'HealthData',
        points: completedDataSharingTasks?.length,
        totalPoints: dataSharingTasks?.length,
      },
      {
        name: 'Games',
        type: 'Games',
        points: completedGameTasks?.length,
        totalPoints: gameTasks?.length,
      },
      {
        name: 'Videos',
        type: 'Videos',
        points: completedVideoTasks?.length,
        totalPoints: videoTasks?.length,
      },
      {
        name: 'Follow-ups',
        type: 'FollowUps',
        points: completedCallScheduleTasks?.length,
        totalPoints: callScheduleTasks?.length,
      },
      {
        name: 'Blood samples',
        type: 'LabTests',
        points: completedLabTestTasks?.length,
        totalPoints: labTestTasks?.length,
      },
      {
        name: 'Devices',
        type: 'Devices',
        points: completedDevicesTasks?.length,
        totalPoints: devicesTasks?.length,
      },
    ],
    [
      completedSurveyTasks?.length,
      surveyTasks?.length,
      completedDataSharingTasks?.length,
      dataSharingTasks?.length,
      completedGameTasks?.length,
      gameTasks?.length,
      completedVideoTasks?.length,
      videoTasks?.length,
      completedCallScheduleTasks?.length,
      callScheduleTasks?.length,
      completedLabTestTasks?.length,
      labTestTasks?.length,
      completedDevicesTasks?.length,
      devicesTasks?.length,
    ]
  );

  if (isLoadingResults) return <Loader />;

  return (
    <Box sx={{ width: '100%', backgroundColor: theme.palette.primary.gray2 }}>
      <Box marginX={marginX}>
        <Title>SOURCE Study</Title>
        <DaysInfo>
          Day {currentDay} / {daysTotal}
        </DaysInfo>
        <StudyProgressBar
          variant='determinate'
          value={(currentDay / daysTotal) * 100}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <PointsInfo>{points} points to your second cash out!</PointsInfo>
          <LevelInfo>LVL {level}</LevelInfo>
        </Box>
        <AchievementsTitle>My achievements</AchievementsTitle>
      </Box>
      <AchievementsContainer>
        {gridData.map((achievement) => {
          if (!achievement.totalPoints) return null;
          return (
            <AchievementCard
              key={achievement.name}
              name={achievement.name}
              imageName={achievement.type}
              points={achievement.points}
              totalPoints={achievement.totalPoints}
            />
          );
        })}
      </AchievementsContainer>
    </Box>
  );
}
