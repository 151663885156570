import { Box, styled, Typography } from '@mui/material';

export const Section = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '10px',
}));

export const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.pb3,
  color: theme.palette.primary.gray60,
  fontFamily: 'Inter',
  fontStyle: 'normal',
  lineHeight: '140%',
}));

export const List = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));
