import { styled } from '@mui/system';

import { BaseLayout } from 'components/BaseLayout';

export const Layout = styled(BaseLayout)(({ theme }) => ({
  height: '100%',
  width: '100%',
  '& > .BaseLayout-scrollable': {
    backgroundColor: theme.palette.primary.gray5,
  },
}));
