import { styled } from '@mui/material/styles';
import ActivityHeader from 'components/Activities/Header';
import { ProgressButtons } from 'components/Buttons';

export const ArticlePageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

export const StepperWrapper = styled('div')({
  width: '100%',
  maxWidth: '700px',
  margin: 'auto',
});

export const StyledActivityHeader = styled(ActivityHeader)({
  justifyContent: 'center',
  '& > :first-of-type': {
    position: 'absolute',
    left: '24px',
  },
  '& > :last-child': {
    position: 'absolute',
    right: '24px',
  },
});

export const StyledProgressButtons = styled(
  ({ isLastStep = false, ...rest }) => <ProgressButtons {...rest} />
)(({ isLastStep }) => ({
  width: '100%',
  margin: '25px 0px',
  alignItems: 'center',
  justifyContent: `center ${isLastStep ? '!important' : ''}`,
}));

export const ArticleContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.white,
  boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.04)',
  borderRadius: '8px',
}));

export const ArticleDescription = styled('div')({
  padding: '14px 30px',
  p: {
    margin: '16px 0',
  },
  ul: {
    paddingLeft: '32px',
  },
});
