import { isValidElement, useEffect, useState } from 'react';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import { useGetYourInterestsQuery } from 'ihp-bloom-redux/features/educationCategories/educationCategoriesApiSlice';

import { Loader } from 'components/Loader';
import { interests } from 'constants/interests';

export const withCommunicationPreference = (Component) => (props) => {
  const user = useSelector((state) => state.user);
  const { person } = user;
  const [preSelectedInterests, setPreSelectedInterests] = useState([]);

  const {
    preSelectedInterestResponse,
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetYourInterestsQuery(
    {
      personId: person?.id,
      params: {
        'page[limit]': 100000,
      },
    },
    {
      skip: !person?.id,
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        preSelectedInterestResponse: data?.data,
      }),
    }
  );

  const groupedInterestByCategory = Object.entries(
    interests.reduce((acc, { label, code, category, checked }) => {
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push({ label, code, checked });

      return acc;
    }, {})
  ).map(([category, options]) => ({ category, options }));

  const getCheckedValue = (code) => {
    let i = 0;
    let value = false;
    while (i < preSelectedInterestResponse.length) {
      if (
        preSelectedInterestResponse[i].attributes.education_category_code ===
        code
      ) {
        value = true;
      }
      i++;
    }
    return value;
  };

  useEffect(() => {
    if (preSelectedInterestResponse) {
      const updatedInterestList = groupedInterestByCategory.map((interest) => {
        interest.options = interest.options.map((option) => {
          option.checked = getCheckedValue(option.code);
          return option;
        });
        return interest;
      });
      setPreSelectedInterests(updatedInterestList);
    }
  }, [preSelectedInterestResponse]);

  if (isLoading || isFetching || isUninitialized) {
    return <Loader />;
  }
  const newProps = {
    ...props,
    preSelectedInterestData: preSelectedInterests,
    preSelectedInterestResponse,
  };

  const componentToRender = <Component {...newProps} />;
  return isValidElement(componentToRender) ? componentToRender : null;
};
