import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';

const FixedBottomRight = styled(Box)(({ theme, pathName }) => ({
  position: 'fixed',
  right: '30px',
  zIndex: '100',
  bottom: '20px',
  [theme.breakpoints.down('sm')]: {
    bottom: 'initial',
    top: '20px',
    right: '10px',
  },
}));

export const PopupButtonsWrap = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const PopupWrapper = styled(FixedBottomRight)(({ theme }) => ({
  width: '345px',
  borderRadius: '8px',
  backgroundColor: theme.palette.primary.helpBlue,
  padding: '30px',
  [theme.breakpoints.down('sm')]: {
    bottom: undefined,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export const PopupContent = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Loader = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));


export const HelpCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: 'white',
  marginTop: '20px',
}));


export const PopupTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '135%',
  fontWeight: '700',
  color: theme.palette.primary.white,
  fontFamily: 'Inter',
  position: 'relative',
}));

export const ButtonText = styled('span')(() => ({
  marginLeft: '10px',
}));

export const PopupText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '135%',
  fontWeight: '500',
  color: theme.palette.primary.white,
  fontFamily: 'Inter',
  marginTop: '10px',
}));

export const PopupOperationText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '135%',
  fontWeight: '600',
  color: theme.palette.primary.white,
  fontFamily: 'Inter',
  marginTop: '10px',
  marginBottom: '10px',
}));

export const PopupButton = styled('a')(({ theme }) => ({
  width: '100%',
  height: '50px',
  marginTop: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.white,
  color: theme.palette.primary.helpBlue,
  fontWeight: 'bold',
  borderRadius: '8px',
  letterSpacing: '0.01em',
  textDecoration: 'none',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: theme.palette.primary.lightBlue,
  },
}));

export const Close = styled('a')(() => ({
  position: 'absolute',
  top: '-10px',
  right: '-10px',
  cursor: 'pointer',
}));
