import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function FooterNote({ text }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        color: theme.palette.primary.fuchsia75,
        fontSize: 12,
        cursor: 'pointer',
      }}
      component="span"
    >
      {text}
    </Box>
  );
}

FooterNote.protoTypes = {
  text: PropTypes.string.isRequired,
};
