import { Container } from '@mui/material';
import { AboutGrid, OurClinicalSites, OurNetworks } from './components';
import useScrollToTop from 'hooks/useScrollToTop';

const About = () => {
  document.title = 'Single Ventricle SOURCE';
  useScrollToTop();
  return (
    <>
      <AboutGrid />
      <Container>
        <OurNetworks />
      </Container>
      <OurClinicalSites />
    </>
  );
};
export default About;
