import { styled } from '@mui/system';
import { Box, IconButton } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '17px',
  [theme.breakpoints.down('sm')]: {
    height: '60px',
    padding: '0 24px 0 24px',
    backgroundColor: theme.palette.primary.white,
  },
  [theme.breakpoints.up('sm')]: {
    minHeight: '50px',
  },
}));

export const StyledButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'forceShowClickBack',
})(({ theme, forceShowClickBack }) => ({
  padding: '0px',
  [theme.breakpoints.up('sm')]: {
    ...(!forceShowClickBack && { display: 'none' }),
  },
}));
