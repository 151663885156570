import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formio } from '@formio/react';

import { useSelector } from 'ihp-bloom-redux/app/redux';
import {
  useGetProfileParticipantTaskQuery,
  useGetProfileParticipantTasksQuery,
  useUpdateParticipantTaskMutation,
} from 'ihp-bloom-redux/features/tasks/participantTaskApiSliceV3';

import { enrollmentFeedbackTemplate } from './template';
import OnboardingLayout from '../components/Layout';
import { Loader } from 'components/Loader';
import useScrollToTop from "../../../hooks/useScrollToTop";

const MemoizedForm = memo(
  ({
    formRef,
    components,
    handleSubmit,
    handleCancel,
    handleSave,
    isMobile,
    form,
  }) => {
    const formData = useRef(null);
    const handleChange = async ({ data, ...rest }, schema) => {
      formData.current = data;
    };

    const setupExtraActions = ({ onCancel, onSave }) => {
      const cancelBtn = document.getElementById('skip-enrollment-feedback');
      if (onCancel && cancelBtn) {
        cancelBtn?.addEventListener('click', onCancel);
      }
      const saveBtn = document.getElementById('submit-enrollment-feedback');
      if (onSave && saveBtn) {
        saveBtn?.addEventListener('click', onSave);
      }

      return {
        removeListeners: () => {
          if (cancelBtn) cancelBtn.removeEventListener('click', onCancel);
          if (saveBtn) saveBtn.removeEventListener('click', onSave);
        },
      };
    };

    useEffect(() => {
      const { removeListeners } = setupExtraActions({
        handleCancel,
        handleSave,
      });

      return () => {
        removeListeners();
      };
    }, [handleCancel, handleSave]);

    return (
      <Form
        formReady={(form) => {
          formRef.current = form;
          setTimeout(() => {
            setupExtraActions({
              onCancel: handleCancel,
              onSave: handleSave,
            });
          }, 1000);
        }}
        onRender={() => {
          setupExtraActions({ onCancel: handleCancel, onSave: handleSave });
        }}
        form={{
          ...form,
          isMobile: true,
        }}
        onChange={handleChange}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onSave={handleSave}
        isMobile={isMobile}
      />
    );
  }
);

function EnrollmentFeedback() {
  useScrollToTop();
  const navigate = useNavigate();
  const [updateParticipantTaskMutation, { isLoading: isUpdatingTask }] =
    useUpdateParticipantTaskMutation();

  const { activeAccountProfile } = useSelector((state) => state.user);

  const formRef = useRef(null);

  const accountProfileId = activeAccountProfile?.id;

  const [participantTaskId, setParticipantTaskId] = useState('');

  const goBack = () => {
    navigate(-1);
  };

  Formio.Templates.current = {
    ...Formio.Templates.current,
    wizard: {
      form: enrollmentFeedbackTemplate.form,
    },
  };

  const { item } = useGetProfileParticipantTasksQuery(
    {
      accountProfileId: accountProfileId,
      params: {
        task_type: 'survey',
        display_status: 'active',
      },
    },
    {
      skip: !accountProfileId,
      selectFromResult: ({ data }) => ({
        item: data?.data?.find((task) => {
          return task.data.code.startsWith('FEEDBACK');
        })?.data,
      }),
    }
  );

  useEffect(() => {
    item && setParticipantTaskId(item?.id);
  }, [item]);

  const { options, isLoading: isLoadingParticipantTasksContent } =
    useGetProfileParticipantTaskQuery(
      {
        accountProfileId: accountProfileId,
        participantTaskId: participantTaskId,
        params: {
          include: 'task-version',
        },
      },
      {
        skip: !accountProfileId || !item,
        selectFromResult: ({
          data,
          isLoading,
          isUninitialized,
          isFetching,
        }) => ({
          isLoading: isLoading || isUninitialized || isFetching,
          options: data?.included?.['task-version']?.instrument,
        }),
      }
    );

  const handleSubmit = (submission) => {
    const requestBody = {
      participantTaskId: participantTaskId + '',
      payload: {
        data: {
          type: 'participant-tasks',
          id: participantTaskId + '',
          attributes: {
            status: 'completed',
            entry: submission.data,
          },
        },
      },
    };

    updateParticipantTaskMutation(requestBody)
      .then((response) => {
        if (response?.error) {
          console.log('Error: ', response);
          return;
        } else {
          navigate('/home');
        }
      })
      .catch(console.error);
  };

  const handleCancel = useCallback(() => navigate('/home'), []);

  const handleSave = (submission) => {
    console.log('Form submission:', submission);
  };

  if (isLoadingParticipantTasksContent || isUpdatingTask) {
    return <Loader />;
  }

  return (
    <>
      <OnboardingLayout maxWidth='760px' backHandler={goBack}>
        <MemoizedForm
          formRef={formRef}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          form={options}
        />
      </OnboardingLayout>
    </>
  );
}

export default EnrollmentFeedback;
